import React, { useEffect ,useState} from 'react';
import { usePromptBuilder } from '../../Utility/ManagePromptContext';
import ManageQuestions from './ManageQuestions';
import ManageTaskAndSequence from './ManagePromptTask';
import Loader from '../../Reusable Components/Loader';
import PopUpComponent from '../../Reusable Components/YesOrNoPopup';
import ManagePromptCards from './ManagePromptCard';
import ButtonComponent from '../../Reusable Components/Button';
import { useNavigate,useLocation, useNavigationType } from 'react-router-dom';
import { useManagePromptCard } from '../../Utility/ManagePromptCardContext';
import { Interface } from 'readline';
import Toastr from '../../Reusable Components/Toastr';


interface ManagePromptBaseComponent{
  navOpen:any;
}

const ManagePrompt: React.FC<ManagePromptBaseComponent> = ({navOpen}:any) => {
  const {
    isLoading,
    showPage,
    promptMessagePage,
    practiceName,
    practiceID,
    promptCard,
    PF,
    promptData,
    promptIndex,
    showPopup,
    popUpText,
    HandlePromptHistory,
    disableEdit,
    editQuestion,
    isCopied,
    setShowPage,
    setPracticeID,
    setPopUpText,
    setShowPopup,
    setPromptIndex,
    handleCreatePrompt,
    setHandlePromptHistory,
    setPracticeName,
    setPromptMessagePage,
    setSubmitType,
    validate,
    deletePromptData,
    setPromptData,
    setPromptFieldData,
    setHiddenIndices,
    setPromptMessageData,
    setPF,
    setTasks,
    tasks,
    setPromptLogoValues,
    setKnowledgeData,
    setErrorData,
    setError,
    setIsLoading,
    setInputArray,
    setFewShotValues,
    setEditTaskIndex,
    setSelectedInputs,
    setModelError,
    promptObj,
    promptFieldObj,
    promptMessageObj,
    logoObj,
    errorObj,
    
  } = usePromptBuilder();
  let navigate=useNavigate()
  const {fetchPromptData, promptdataName,promptdataid} = useManagePromptCard();
   const [showToastr, setShowToastr] = useState(false)
  const handleCancel = () => {
    setShowPage("manage prompt");
    setPromptData((prevData:any) => ({
      ...promptObj,
      kbFiles: prevData.kbFiles,
      kbUrl: prevData.kbUrl
    }));
    setPromptFieldData(promptFieldObj);
    setSubmitType("");
    setPromptIndex(null);
    setHiddenIndices([]);
    setPromptMessageData(promptMessageObj);
    setPF([]);
    setTasks([]);
    setPromptMessagePage("");
    setPromptLogoValues(logoObj);
    setKnowledgeData([]);
    setErrorData(errorObj);
    setError("");
    setHandlePromptHistory(true);
    setInputArray([]);
    setFewShotValues([]);
    setPromptIndex(null);
    setEditTaskIndex(null);
    setSelectedInputs([]);
    setModelError("");
    fetchPromptData()
    setPracticeName("");
    sessionStorage.setItem('promptKbFile',"[]")
    navigate(`/asami-manage-prompt?practiceID=${promptdataid}`)
  };
    useEffect(()=>{
      if(tasks?.length> 0){
        setShowToastr(false);
      }
    },[tasks])
  

  const handleSubmit = async (e :any) => {
    if(tasks?.length <= 0){
      setShowToastr(true)
      return
    }
    setIsLoading(false);
    await validate(e.target.id);
    setPromptFieldData(promptFieldObj);
    setSubmitType("");
    setPromptIndex(null);
    setPromptMessageData(promptMessageObj);
    setPF([]);
    setTasks([]);
    setPromptMessagePage("");
    setPromptLogoValues(logoObj);
    setKnowledgeData([]);
    setModelError("");
    setPracticeName("");
    setShowPage("manage prompt");
    fetchPromptData()
    sessionStorage.setItem('promptKbFile',"[]")
    navigate(`/asami-manage-prompt?practiceID=${promptdataid}`)
    
  };
  const location = useLocation();
  const navigationType = useNavigationType();
  useEffect(() => {
    // If user refreshed the page while on '/asamimanagebase', then redirect
    if (location.pathname === '/asamimanagebase' && navigationType === 'POP') {
      navigate('/asami-manage-execution');
    }
  }, [location.pathname, navigationType, navigate]);
 
  const handleContinue = () => {
    if (PF.length !== 1 && promptData.promptName !== "" && promptData.promptLogoID !== "" && !editQuestion) {
      setPromptMessagePage("show");
      setShowPage("manage Task")
    } else {
      let obj:any = {
        promptNameError: "",
        promptLogoIDError: "",
        promptDescription:"",
      };
      if (promptData.promptName === "") {
        obj.promptNameError = "Enter the prompt name!";
      }
      if (promptData.promptLogoID === "") {
        obj.promptLogoIDError = "Select the prompt logo!";
      }
      if (promptData.promptDescription === "") {
        obj.promptDescriptionError = "Select the prompt logo!";
      }
      if (PF.length === 1) {
        obj.PromptField = "Please add at least one prompt field!";
        setError("Please add at least one prompt field!");
      }
      setErrorData(obj);
    }
  };


  return (
    <>
      {isLoading ? (
        <Loader isLoading={isLoading} navOpening={navOpen} />
      ) : (
        <div className="container-fluid">
          <div className="row justify-content-lg-end justify-content-center">
            <div className={navOpen == "Close" ? "col-12 col-lg-11 me-xl-3 px-3" : "col-lg-9 col-11 me-sm-0 me-md-3 cust-me-lg-4 me-xl-4"}>
              <div className="row justify-content-center">
                {"showManagePrompt"=== "showManagePrompt" && (
                  <>
                    <div className="">
                      <h1 className="mt-4 text-color-1 font-24 font-bold mt-5 mb-5 d-flex align-items-center">
                      <a  onClick={()=>{navigate(`/asami-manage-prompt?practiceID=${promptdataid}`)}}>
                          <img 
                            src="images-new/back-black-arrow.svg" 
                            className="me-3 mb-1 icon-filter" 
                            alt="back-arrow" 
                            title="Back"
                            onClick={handleCancel} 
                            style={{cursor:"pointer"}}
                          />
                        </a>
                        <span>{promptdataName}</span>
                      </h1>
                    </div>

                    <div className="d-flex justify-content-center my-5 position-relative" style={{ width: '90%' }}>
                      <span
                        className={`wizard-num${promptMessagePage === "" ? `-active` : ``} font-18 font-semibold d-block d-flex align-items-center justify-content-center me-2`}
                      >
                        1
                      </span>
                      <span
                        className="wizard-text-active wizard-line font-18 font-semibold d-block d-flex align-items-center justify-content-center me-5 position-relative cursor-pointer"
                        onClick={() => {
                          setPromptMessagePage("");setSubmitType("SD");   setShowPage("showManagePrompt");
                        }}
                      >
                        Questions
                      </span>
                      <span
                        className={`wizard-num${promptMessagePage !== "" ? `-active` : ``} font-18 font-semibold d-block d-flex align-items-center justify-content-center me-2 ms-5`}
                      >
                        2
                      </span>
                      <span
                        className={`wizard-text${promptMessagePage !== "" ? `-active` : ``} font-18 font-semibold d-block d-flex align-items-center justify-content-center cursor-pointer`}
                        onClick={handleContinue}
                      >
                        Prompts
                      </span>
                    </div>

                    {showPage== "showManagePrompt" ? (
                      <ManageQuestions />
                    ) : (
                      <ManageTaskAndSequence />
                    )}
                  </>
                )}
                 {showToastr&&<Toastr message='Fill the Tasks to Submit !' level='Failure' duration={5000} showCloseButton={false}/>}
                
          {true&& (
            <div className="row justify-content-center">
              <div className="col-md-11 py-3">
                <div className="d-flex justify-content-between">
                  <div className='justify-content-between'>
                  <ButtonComponent
                      text='Previous'
                      className="btn theme-outline-btn font-14 font-semibold theme-focus me-3"
                      onClick={() => {setModelError("");setPromptMessagePage("");setSubmitType("SD");   setShowPage("showManagePrompt");}}
                      hidden={showPage === "showManagePrompt"}
                      // style={{ marginLeft: "24%" }}
                      disabled={disableEdit}
                    />
                  <ButtonComponent
                      text='Cancel'
                      className="btn btn-outline-secondary font-14 font-semibold"
                      // style={{ marginRight: "47%" }}
                      onClick={handleCancel}
                    />
                    </div>
                  <div>
                    {HandlePromptHistory && (
                      <button
                        type="button"
                        className="btn custom-outline-btn font-14 font-semibold theme-focus me-3"
                        disabled={disableEdit}
                        id="SD"
                        onClick={async (e:any) => {
                          if (PF.length !== 1 && promptData.promptName !== "" && promptData.promptLogoID !== "") {
                            setIsLoading(false);
                            await validate(e.target.id);
                            setSubmitType("SD");
                            setPromptData(promptObj);
                            setPromptMessageData(promptMessageObj);
                            setPF([]);
                            setTasks([]);
                            setPromptFieldData(promptFieldObj);
                            setPromptLogoValues(logoObj);
                            setPromptMessagePage("");
                            setKnowledgeData([]);
                            setPracticeName("");
                            window.location.href = "";
                          } else {
                            let obj = {
                              promptNameError: "",
                              promptLogoIDError: "",
                            };
                            if (promptData.promptName === "") {
                              obj.promptNameError = "Enter the prompt name!";
                            }
                            if (promptData.promptLogoID === "") {
                              obj.promptLogoIDError = "Select the Prompt Logo!";
                            }
                            if (PF.length === 1) {
                              setError("Please add at least one prompt field!");
                            }
                            setErrorData(obj);
                          }
                          setModelError("");
                          window.location.href = "/asami-manage-prompt";
                          
                        }}
                      >
                        Save as Draft
                      </button>
                    )}
                    {showPage !== "showManagePrompt"  && HandlePromptHistory ? (
                         <ButtonComponent
                         text=' Submit'
                         className="btn theme-focus theme-color-bg font-14 font-semibold text-white"
                         onClick={(e)=>{handleSubmit(e)}}
                         disabled={disableEdit }
                       />
                    ) : showPage !== "showManagePrompt" ? (
                      <></>
                    ) : (
                        <ButtonComponent
                        text='Continue'
                        className="btn theme-focus theme-color-bg font-14 font-semibold text-white"
                        onClick={handleContinue}
                        disabled={editQuestion !== false}
                      />

                    )}
                  </div>
                </div>
                </div>
              </div>
          )}
              </div>
            </div>
          </div>


        </div>
      )}
      {isCopied && (
        <div className="notification-bar">
          <span className="check-icon">✓</span>
          Copied to Clipboard!
        </div>
      )}
    </>
  );
};

export default ManagePrompt;