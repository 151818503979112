
// ButtonComponent.tsx
import React, { useEffect } from 'react';
import { ButtonComponentProps } from '../Interfaces/Button';

const ButtonComponent: React.FC<ButtonComponentProps> = ({
  buttonType = 'text',
  text = '',
  iconSrc = '',
  altText = '',
  height = '40px',
  width = 'auto',
  className = '',
  imgClass="",
  disabled = false,
  style = {},
  ...props
}) => {
  useEffect(()=>{

  },[text])
  return (
    <button
      className={`btn ${className}`}
      disabled={disabled}
      style={{ height, width, ...style }}
      {...props}
    >
      {buttonType === 'icon' ? (
        <img src={iconSrc} className={imgClass} alt={altText} style={{ height: '100%', width: '100%' }} />
      ) : (
        
        <>
        
          {iconSrc && (
            <>
            <img
              src={iconSrc}
              alt={altText}
              className="me-2"
              style={{ height: '20px', width: '20px' }}
            /></>
          )}
          {text}
        </>
      )}
    </button>
  );
};

export default ButtonComponent;