import React, { useState, useContext, useEffect, useCallback, useMemo , useRef} from "react";
import MyContext from "../../Utility/MyContext";
import UserContext from "../../Auth/UserContext";
import { useNavigate as useHistory } from "react-router-dom";
import Select from 'react-select';
import { useFileUpload } from "../../Utility/FileUploadContext";
import { getSessionItem } from "../../Utility/encrypt-storage";
// import Loader from "../Other-Components/loader";
import TechStack from "../../Reusable Components/ManagePrompt Reusable Components/TechStack";
import axios from "axios";

// Import your form components
import { Text } from "../../Reusable Components/ManagePrompt Reusable Components/Text";
import { MediaUpload } from "../../Reusable Components/ManagePrompt Reusable Components/MediaUpload";
import ChoicesComponent from "../../Reusable Components/ManagePrompt Reusable Components/ChoicesComponent";
import Loader from "../../Reusable Components/Loader";



interface Field {
  inputValueOrder: string;
  inputTypeRefCode: string;
  labelName: string;
  placeHolder?: string;
  required: string;
  choiceName?: string[];
  promptFieldOrder: number;
  errorMessage?: string;
}

interface FieldValue {
  value: string | string[] | any;
}

interface PromptFormComponentProps {
  fields: any;
  filled_fields: { [key: string]: FieldValue };
  global_data: any;
  promptData: any;
  requestGptToken:any
  createChat:any
}

export const PromptFormComponent: React.FC<PromptFormComponentProps> = React.memo(function PromptFormComponent({ fields, filled_fields, global_data, promptData, requestGptToken, createChat }) {
  const contextData = useContext(MyContext);
  const userDetails = useContext(UserContext);
  const history = useHistory();
  let BLinksList = JSON.parse(sessionStorage.getItem("BLinksList") as any)
  const token = getSessionItem("access-token");
  const [userData, setUserData] = useState<any>({});
  const [fieldValues, setFieldValues] = useState<{ [key: string]: FieldValue }>(filled_fields || {});
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
  const [promptFieldJson, setPromptFieldJson] = useState<{ [key: string]: any }>({});
  const [error, setError] = useState<string>("");
  const [stackError, setStackError] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [bindChoicesValues, setBindChoicesValues] = useState<any[]>([]);
  const [isloading, setIsLoading] = useState( false);

  useEffect(() => {
    setUserData(userDetails);
  }, [userDetails]);

  useEffect(() => {
    if (filled_fields) {
      setFieldValues(filled_fields);
      setPromptFieldJson(filled_fields);
    }
  }, [filled_fields]);


  const handleFieldValues = useCallback((id: string, value: string | string[], inputValueOrder: string) => {
    setFieldValues((prev) => ({
      ...prev,
      [inputValueOrder]: { value }
    }));
  
    setValidationErrors(prev => {
      const newErrors = { ...prev };
      delete newErrors[inputValueOrder];
      return newErrors;
    });
  
    setPromptFieldJson(prev => ({
      ...prev,
      [id]: value
    }));
  }, []);

  const removeValidationError = useCallback((inputValueOrder: string) => {
    setValidationErrors(prev => {
      if (prev[inputValueOrder]) {
        const newErrors = { ...prev };
        delete newErrors[inputValueOrder];
        return newErrors;
      }
      return prev;
    });
  }, []);
  
  const editorRef = useRef<HTMLDivElement>(null);
  const [theme, setTheme] = useState<string>("Light");

    const updateThemeFromBackground = () => {
      if (editorRef.current) {
        const parent = editorRef.current.closest('.custom-dashboard-section');
        if (parent) {
          const bgColor = window.getComputedStyle(parent).backgroundColor;
          const rgb = bgColor.match(/\d+/g);
          if (rgb) {
            const brightness = (parseInt(rgb[0]) * 299 + parseInt(rgb[1]) * 587 + parseInt(rgb[2]) * 114) / 1000;
            setTheme(brightness > 128 ? "Light" : "Dark");
          }
        }
      }
    };
    
  
    useEffect(() => {
      const observer = new ResizeObserver(updateThemeFromBackground);
      if (editorRef.current) {
        const parent = editorRef.current.closest('.custom-dashboard-section');
        if (parent) {
          observer.observe(parent);
        }
      }
      return () => observer.disconnect();
    }, []);


    const handleFormSubmit = React.useCallback(async () => {
      // First, check for validation errors without modifying the state
      const errors: { [key: string]: string } = {};
      promptData?.promptFields.forEach((field: Field) => {
        const { inputValueOrder, required, errorMessage } = field;
        const fieldValue = fieldValues[inputValueOrder]?.value;
    
        if (required === "true" && (!fieldValue || fieldValue.length === 0)) {
          errors[inputValueOrder] = errorMessage || "This field is required";
        }
      });
    
      // If there are errors, update the state and return early
      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);
        return;
      }
    
      // Special case for Code Conversion
      if (promptData?.promptName === "Code Conversion") {
        if (fieldValues["input-4"]?.value === fieldValues["input-5"]?.value) {
          setStackError("Please select different Tech-Stack for both Source and Target");
          return;
        }
      }
    
      // Clear any existing errors only if we're sure we're going to submit
      setError("");
      setStackError("");
      setValidationErrors({});
    
      // Prepare the payload
      const promptFormJson: { [key: string]: string } = {};
      const simplifiedInputJsonValues: { [key: string]: string | string[] } = {};
    
      Object.entries(fieldValues).forEach(([key, fieldValue]) => {
        const field = promptData.promptFields.find((f: Field) => f.inputValueOrder === key);
        if (field) {
          promptFormJson[field.labelName] = fieldValue.value;
          simplifiedInputJsonValues[key] = fieldValue.value;
        }
      });
    
      let approverEmailID = "";
      try {
        const managerResponse = await axios.get(
          `https://graph.microsoft.com/v1.0/users/${userDetails?.email}/manager`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        approverEmailID = managerResponse?.data?.mail || "";
      } catch (error) {
        console.error("Error fetching manager data:", error);
      }
    
      const chatName = promptFieldJson["Chat Name"] || "New Chat";
    
      const payload = {
        approverEmailID,
        userEmailID: userDetails?.email,
        userName: userDetails?.userName,
        promptFormJson,
        inputJsonValues: simplifiedInputJsonValues,
        promptID: promptData.promptID,
        role: "user",
        codegenStatus: "ip",
        chatType: "Prompt",
        bLinks: BLinksList || ""
      };
    
      try {
        setIsLoading(true);
        await createChat(chatName, payload);
        await requestGptToken(promptData.promptMessages, simplifiedInputJsonValues, BLinksList);
        contextData?.setformHidden(false);
      } catch (error) {
        console.error("Error in handleFormSubmit:", error);
        setError("An error occurred. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }, [createChat, requestGptToken, promptData, fieldValues, promptFieldJson, contextData, userDetails, BLinksList, token]);

const renderField = useCallback((field: Field) => {
  const { inputValueOrder, inputTypeRefCode, labelName, placeHolder } = field;
  const fieldData = fieldValues[inputValueOrder] || { value: '' };
  const value = fieldData.value;
  const error = validationErrors[inputValueOrder];

  const record = {
    record: {
      ...field,
      fieldValues: fieldValues
    },
    handleFieldValues,
    validationErrors,
    fieldValues
  };

  switch (inputTypeRefCode) {
    case "TS":
      return (
        <div className="col-md-6 mb-4 mt-3" key={inputValueOrder}>
          <TechStack
            record={{
              record: field,
              techstackName: value,
              handleFieldValues,
              validationErrors,
              fieldValues
            }}
          />
        </div>
      );

      case "RB":
        case "CB":
        case "DD":
          if ((renderField as any).renderedLabels.has(labelName)) {
            return null;
          }
          (renderField as any).renderedLabels.add(labelName);
        
          const choices = promptData.promptFields
            .filter((f: Field) => f.labelName === labelName)
            .flatMap((f: Field) => f.choiceName || []);
        
          return (
            <ChoicesComponent
              choiceType={inputTypeRefCode === "RB" ? "radio" : inputTypeRefCode === "CB" ? "checkbox" : "dropdown"}
              choices={choices}
              selectedValue={value}
              onChangeChoice={(index, newValue) => handleFieldValues(labelName, newValue, inputValueOrder)}
              onBlurChoice={(e: React.FocusEvent<HTMLInputElement>) => {}}
              label={labelName}
              required={field.required === "true"}
              onSelect={(newValue) => handleFieldValues(labelName, newValue, inputValueOrder)}
            />
          );
                

    case "TXT":
    case "CS":
    case "LA":
      return (
        <Text
          record={record}
          isLongAnswer={inputTypeRefCode === "CS" || inputTypeRefCode === "LA"}
        />
      );

      case "UF":
      return (
        <MediaUpload
          key={field.inputValueOrder}
          record={{
            record: field,
            validationErrors,
            fieldValues: {
              [field.inputValueOrder]: fieldValues[field.inputValueOrder] || { value: [] }
            },
            handleFieldValues,
            removeValidationError
          }}
          setFieldValues={setFieldValues}
        />
      );

    default:
      return (
        <div>
          <label className="form-label font-15 font-semibold w-100 text-color-1">
            {labelName}
            {field.required === "true" && <span className="color-red">*</span>}
          </label>
          <input
            type="text"
            value={value}
            onChange={(e) => handleFieldValues(labelName, e.target.value, inputValueOrder)}
            placeholder={placeHolder}
          />
          {error && <span className="error">{error}</span>}
        </div>
      );
  }
}, [fieldValues, validationErrors, handleFieldValues, removeValidationError]);




const sortedPromptFields = useMemo(() => 
  promptData.promptFields.sort((a: Field, b: Field) => a.promptFieldOrder - b.promptFieldOrder),
  [promptData.promptFields]
);

const darkModeStyles = {
  control: (provided:any) => ({
    ...provided,
    backgroundColor: '#333',
    borderColor: '#555',
    color: '#fff',
  }),
  menu: (provided:any) => ({
    ...provided,
    backgroundColor: '#333',
    color: '#fff',
  }),
  singleValue: (provided:any) => ({
    ...provided,
    color: '#fff',
  }),
  option: (provided:any, state:any) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#555' : state.isFocused ? '#444' : '#333',
    color: '#fff',
  }),
  placeholder: (provided:any) => ({
    ...provided,
    color: '#aaa',
  }),
  input: (provided:any) => ({
    ...provided,
    color: '#fff',
  }),
};

return (
    <div className="container-fluid">
      <div className="modal fade show" style={{ display: "block" }}>
        <div className="modal-dialog modal-dialog-centered custom-codesnippet-width border-0">
        {isloading ?  <Loader isLoading={isloading} /> : (
          <div className="modal-content p-3 border-0 bg-color-2">
            <div className="modal-header border-0 pt-2 pb-0">
              <h5 className="modal-title text-color-1 font-22 font-bold">
                {promptData.promptName}
              </h5>
            </div>
            <div className="modal-body pt-2">
              <div className="row">
                <div className="common-scroll-popup">
                  {/* <p className="font-15 font-medium text-color-9">
                    {promptData.promptDescription}
                  </p> */}
  
                  {(() => {
                    (renderField as any).renderedLabels = new Set(); // Reset rendered labels
                    return sortedPromptFields.map((field : any)=> (
                      <div key={field.inputValueOrder} className="col-md-12 mb-4">
                        {renderField(field)}
                      </div>
                    ));
                  })()}
  
                  {(error || stackError) && (
                    <div className="error-messages">
                      {error && <p className="error">{error}</p>}
                      {stackError && <p className="error">{stackError}</p>}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <button
                type="button"
                className="btn custom-outline-btn font-14 px-4 py-2 me-3 font-medium"
                onClick={() =>{ contextData?.setformHidden(false);contextData?.setCancelButtonTriggered(true); }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn primary-btn text-white font-14 px-4 py-2 font-medium theme-focus"
                onClick={handleFormSubmit}
              >
                Submit
              </button>
            </div>
          </div>)}
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
  });