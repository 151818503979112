import React, { useState, useEffect, useRef } from 'react';
import { Rule, RuleData, GetRulesParams } from '../../Interfaces/Rules';
import { getAllRules, addRule, deleteRule } from '../../Service/API';
import ReusableTable from '../../Reusable Components/ReusableTable';
import NumberOfRecordsComponent from '../../Reusable Components/NoofRecords';
import TextInput from '../../Reusable Components/TextInput';
import ModuleHeading from '../../Reusable Components/ModuleHeading';
import { TableProvider, useTableContext } from '../../Utility/ReusableTableContext';
import { Header } from '../../Interfaces/ReusableTable';
import { useManagePromptCard } from '../../Utility/ManagePromptCardContext';
import { DropDownProvider } from '../../Utility/DropDownContext';
import DropDownComponent from '../../Reusable Components/DropDown';
import Tab from '../../Reusable Components/TabSwitch';
import Loader from '../../Reusable Components/Loader';

interface ManageRuleProps {
    navOpen: string;
    setPage?:any;
    page?:any
}

const ManageRule: React.FC<ManageRuleProps> = ({ navOpen, page,setPage}) => {
    return (
        <TableProvider initialData={[]}>
            <ManageRuleContent navOpen={navOpen} page={page} setPage={setPage}/>
        </TableProvider>
    );
};

const ManageRuleContent: React.FC<ManageRuleProps> = ({ navOpen , page,setPage }) => {
    const { data, setData, sortConfig, setSortConfig } = useTableContext<Rule>();
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [recordCount, setRecordCount] = useState<number>(0);
    const [shownRecords, setShownRecords] = useState<number>(0);
    const [offset, setOffset] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoad, setIsLoad] = useState<boolean>(false);
    const [newRuleName, setNewRuleName] = useState<string>("");
    const [newApplicationName, setNewApplicationName] = useState<any>("");
    const [errorData, setErrorData] = useState<{ ruleNameError: string; applicationNameError: string }>({ ruleNameError: "", applicationNameError: "" });
    const ref1 = useRef<HTMLInputElement>(null);
    const ref2 = useRef<HTMLInputElement>(null);
    const { promptdataid } = useManagePromptCard();
    const [dropdownOptions, setdropdownOptions] = useState([])
    const [ruleID,setruleID]=useState("")
    const ActionButtons = ({ row }: { row: Rule }) => (
        <div className="text-center">
            <button className="btn me-3 p-0" onClick={() => handleEditRule(row)}>
                <img src="images-new/edit-icons.svg" alt="Edit" />
            </button>
            <button className="btn p-0" onClick={() => handleDeleteRule(row.RuleID)}>
                <img src="images-new/delete-icons.svg" alt="Delete" />
            </button>
        </div>
    );

    const headers: Header<Rule>[] = [
        { key: "RuleName", label: "Requirement", sortable: true },
        { key: "ApplicationName", label: "Solution Executor", sortable: true },
        { key: "actions", label: "Action", component: ActionButtons },
    ];

    const handleSearch = async () => {
        await fetchRules();
    };

    const handleOrderChange = async () => {
        await fetchRules();
    };

    const fetchRules = async () => {
        try {
            setIsLoading(true);
            const params: GetRulesParams = {
                search: searchTerm,
                column: `${sortConfig.column=="RuleName"?'rule_name':'app_name'}`,
                order:`${sortConfig.order || ''}`,
                count: 10,
                offset: offset,
                practiceID: promptdataid
            };
            const response = await getAllRules(params);
            setData(response.data.responseData.data);
            setShownRecords(response.data.responseData.data.length);
            setRecordCount(response.data.responseData.totalCount);
            const transformedData = response.data.responseData.appdata.map(([value, label]: any) => ({ label, value }));
            setdropdownOptions(transformedData)
        } catch (error) {
            console.error('Error fetching rules:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleEditRule = (rule: Rule) => {
        setNewRuleName(rule.RuleName);
        setNewApplicationName({"label":rule.ApplicationName,"value":rule.ApplicationID});
        setruleID(rule.RuleID)
        
        // You might want to set a state to indicate that you're in edit mode
        // and store the current rule being edited
    };

    const handleDeleteRule = async (ruleId: string) => {
        try {
            setIsLoading(true);
            await deleteRule({
                "action": "delete",
                "rule_data": {
                    "RuleID": ruleId
                }

            });
            setData(prevRules => prevRules.filter(rule => rule.RuleID !== ruleId));
            setRecordCount(prev => prev - 1);
            setShownRecords(prev => prev - 1);
            setIsLoading(false);
        } catch (error) {
            console.error('Error deleting rule:', error);
        }
    };

    const handleAddRow = async () => {
        // Validate inputs
        let isError = false
        if (!newRuleName) {
            setErrorData(prev => ({ ...prev, ruleNameError: "Rule name is required" }));
            isError = true 
        }
        else {
            setErrorData(prev => ({ ...prev, ruleNameError: "" }));
        }

        if (!newApplicationName || (newApplicationName.label != 'Asami' && newApplicationName.label != "Valyria")) {
            setErrorData(prev => ({ ...prev, applicationNameError: "Application name is required" }));
            isError = true 
        }
        else{
            setErrorData(prev => ({ ...prev, applicationNameError: "" }));
        }
        if (isError){
            return
        }
        const userEmailID = String(localStorage.getItem("user-email"));
        try {
            setIsLoading(true);
            // Here, you would typically have a way to convert the application name to its ID
            // This could be done by maintaining a list of applications in your component's state
            // or by making an API call to get the ID based on the name
            if(ruleID==""){
            const newRule: RuleData = {
                RuleName: newRuleName,
                ApplicationID: newApplicationName.value,
                PracticeID: promptdataid, // You need to have this value available in your component
                CreatedBy: userEmailID, // You need to have this value available in your component
                ModifiedBy: userEmailID, // You need to have this value available in your component
            };
            const addedRule = await addRule(
                {
                    "action": "insert",
                    "rule_data": newRule,
                }
            );
        }
        else{

            const editeddata={
                "action":"update",
                "RuleID":ruleID,
                "rule_data":{
                        "rule_name":newRuleName,
                        "application_id":newApplicationName.value,
                        "modifiedBy":userEmailID
                }
            }
            const addedRule = await addRule(editeddata)
        }
            setruleID("")
            // Clear inputs
            setNewRuleName("");
            setNewApplicationName("");
            setErrorData({ ruleNameError: "", applicationNameError: "" });
            setIsLoading(false);
            fetchRules()
        } catch (error) {
            console.error('Error adding rule:', error);
            // Handle error (e.g., show error message to user)
        }
    };

    function handleDropdownChange(selectedOption: any) {
        setNewApplicationName(selectedOption)
    }

    const renderRuleFields = () => {
        return (
            
            <tr>
                <td>
                    <input
                        type="text"
                        className="form-control inline-table-input-w input-field font-14 font-regular border-radius-3 bg-color text-color-4 custom-border-3 cust-search-input me-2"
                        placeholder="Enter Rule"
                        ref={ref1}
                        value={newRuleName}
                        onChange={(e) => setNewRuleName(e.target.value)}
                    />
                    {errorData.ruleNameError && (
                        <div className="span-color">
                            <h6>{errorData.ruleNameError}</h6>
                        </div>
                    )}
                </td>
                <td >
                    <div style={{width: "54%",marginLeft: "-5%"}}>
                    <DropDownProvider isMulti={false}>
                        <DropDownComponent
                            hidelabel={true}
                            options={dropdownOptions}
                            value={newApplicationName.label!=null?[{ "label": newApplicationName.label, "value": newApplicationName.value }]:[{ "label": "Select", "value": "select" }]} // Set default value to 'task'
                            onChange={handleDropdownChange}
                            
                        />
                    </DropDownProvider>
                    </div>
                    {errorData.applicationNameError && (
                        <div className="span-color">
                            <h6>{errorData.applicationNameError}</h6>
                        </div>
                    )}
                </td>
                <td style={{marginRight:"33%",float:"right"}}>
                    <button
                        className="btn purple-plus-icon-decor"
                        onClick={(e) => {
                            e.preventDefault();
                            handleAddRow();
                        }}
                    >
                        <img className="purple-plus-icon" src="images/purple-plus-icon.svg" alt="Add" />
                    </button>
                </td>
            </tr>
        );
    };

    const loadMoreClick = () => {
        setOffset(offset + 1);
    };

    useEffect(() => {
        handleOrderChange();
    }, [ sortConfig, offset]);

    return (
        
        <div className="row">
            {isLoading?(<div style={navOpen!="Open"?{width: "64%",marginLeft: "13%",marginTop: "14%"}:{width: "64%",marginLeft: "9%",marginTop: "7%"}}><Loader isLoading={isLoading} navOpening={navOpen} /></div>):(
            <div className="col-md-12">
                <div className="mt-4 mb-3 ">
                    <div className="mt-3 mt-md-0 d-flex align-items-center" >
                            <Tab
                              activeTab={page}
                              tabContents={{ Prompt: 'Project content goes here', Rule: 'Practice content goes here' }}
                              onTabClick={setPage}
                            />

                    <div className="mt-3 mt-md-0 d-flex align-items-center"  style={{ "float": "right",width: "20%","marginLeft": "60%"}}>
                      <div className="input-group transparent-grp w-225 me-3 m-0 search-bg"  >
                        <TextInput 
                          type={'text'} 
                          className={'form-control custom-search-input text-color-4 bg-transparent border-0 focus-none font-14 font-medium pe-0 ps-3 text-nowrap'} 
                          placeholder={'Search'} 
                          value={searchTerm}
                          onChange={setSearchTerm}
                          onEnterPress={handleOrderChange}
                        />
                        <button className="btn focus-none search-bg" type="button" onClick={handleSearch}>
                          <img src="images-new/input-search.svg" alt="input-search-icon" />
                        </button>
                      </div>
                    </div>

                    </div>
                </div>
                <div className="tab-content mt-2">
                    {isLoad ? (
                        <div style={{ textAlign: 'center', flex: 1, display: 'flex', justifyContent: 'center', marginTop: '20%' }}>
                            {/* Add loading spinner here */}
                        </div>
                    ) : (
                        <div className="col-md-12">
                            <div className=" rounded-3 transparent-grp">
                                <ReusableTable
                                    headers={headers}
                                    inputFields={renderRuleFields()}
                                    llmKey="llm"
                                    onRowClick={(row) =>{}}
                                />
                            </div>
                            <NumberOfRecordsComponent shownRecords={shownRecords} totalRecords={recordCount} className="font-regular font-14 text-color-1 mt-3 mb-4" />
                            {(recordCount > shownRecords && recordCount > 10) && (
                                <div className="text-center mb-5">
                                    <button className="btn gradient-btn border-radius-4 font-14 font-semibold py-2 px-3" type="button" onClick={loadMoreClick}>
                                        <span className="color-gradient">Load More</span>
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            )}
        </div>
    );
};

export default ManageRule;