
import React, { useState } from 'react';
import SourcePopup from './SourcePopup'; // Adjust the import path as needed

// Define the allowed source types
type SourceType = "Knowledge Base" | "Web Source";

interface SourceButtonProps {
  sourceType: SourceType;
  content: string;
  chatIndex: number;
  style?:any;
}

const SourceButton: React.FC<SourceButtonProps> = ({ sourceType, content, chatIndex ,style}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  if (!content) return null;

  return (
    <div className="btn-group" style={{"marginLeft":style?"-2%":"","marginRight":style?"2%":"", "marginTop":style?"4%":""}}>
      <button
        type="button"
        className="btn font-10 px-2 py-1 color-black rounded-1 font-semibold custom-outline-btn-2"
        onClick={togglePopup}
      >
        {sourceType}
      </button>

      <SourcePopup
        isOpen={isPopupOpen}
        onClose={togglePopup}
        sourceType={sourceType}
        content={content}
      />
    </div>
  );
};

export default SourceButton;