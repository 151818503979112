import React, { useState } from "react";
import JSZip from "jszip";
import { downloadCode } from "../Service/API";

const downloadZipFromBase64 = async (base64String: string, fileName: string) => {
  try {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Uint8Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const fileBlob = new Blob([byteNumbers], { type: "application/octet-stream" });

    const url = URL.createObjectURL(fileBlob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error creating ZIP:", error);
  }
};

const DownloadZipButton: React.FC = () => {
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = async () => {
    setIsDownloading(true);
    try {
        let databody={
            "user_id":sessionStorage.getItem('user_id'),
            "project_id":sessionStorage.getItem('project_id')
        }
    
      const base64String = await downloadCode(databody);
      await downloadZipFromBase64(base64String["base64_zip"], databody["user_id"]+"_"+databody["project_id"]+".zip");
    } catch (error) {
      console.error("Download failed:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <div className="col-md-12">
    <div className="codespace-title d-flex align-items-center justify-content-between">
      <h2 className="codespace-file-list-title font-regular font-11 mb-0">Codespace</h2>
      <button onClick={handleDownload} disabled={isDownloading} className="download-btn btn focus-none search-bg">
        <img src={"../images/download-btn-icon.svg"} alt="download-icon" className="download-btn-icon" />
      </button>
    </div>
  </div>

  );
};

export default DownloadZipButton;