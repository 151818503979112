import React, { useContext, useRef, useState } from 'react';
import { useChat } from '../../Utility/ChatContext';
import UserContext from '../../Auth/UserContext';
import { Stringifier } from 'styled-components/dist/types';
import { useFileUpload } from '../../Utility/FileUploadContext';
import { useLocation } from 'react-router-dom';
import SourceButton from './SourceButton';
import { chatCreation, getPresignedURL } from '../../Service/API';

interface outgoingContentProps {
    mainContent: string;
    isLastUserMessage: any;
    Chat_index: number;
    kbContent: string;
    webContent: string;
    docLinks?:any;
    marginIndex?:boolean
    navOpen?:any;
    rightNavOpen?:any 
}
export interface UserDetails {
    email: string;
    role?: string;

    // Add other fields as necessary
}

const OutgoingContent: React.FC<outgoingContentProps> = ({ mainContent, isLastUserMessage, Chat_index, kbContent, webContent, docLinks, marginIndex , navOpen , rightNavOpen}) => {
    // : React.FC = () => {
    const {

        loading,
        hiddenObjValues,
        sethiddenObjValues,
        messageOrder,
        currentChatID,
        kbContents,
        sendMessageValue,
        setsendMessageValue,
        codeSnippetValue,
        CodeSnippet,
        setMessageOrder,
        pushChat,
        handleWebSearch,
        isAskAsami,
        isEditing,
        setIsEditing,
        postMessage,
        openWebIndex,
        setOpenWebIndex,
        openKbIndex,
        setOpenKbIndex,
        data,
        setData,
        setPromptIDMessages,
        fileURLs,
        setFileURLs,
        edit,
        profile,
        isPrompt,
        setPopulateMsg,
        isStopGeneration
        
        
    } = useChat()

    let {isWebSearch,webSearchResult , isEditMessage, setIsEditMessage,    currentChat,
        setcurrentChat} = useChat()

    const {
        setFileUploaded
    } = useFileUpload();
    let BLinksList = JSON.parse(sessionStorage.getItem("BLinksList") as any)
 


    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [minWidth, setMinWidth] = useState(0)
    const [minHeight, setMinHeight] = useState(0)
    //   const userDetails = useContext(UserContext);
    const userData = useContext(UserContext);
    const location = useLocation();
    const search = window.location.search;
    const newChat = new URLSearchParams(search).get("chatID");
    let profileImageUrl = ''
    if(profile){
        profileImageUrl=profile
        
    }
    else{
        if(userData?.profileImage){
            profileImageUrl=userData?.profileImage
            
        }
        else{
            profileImageUrl=''
            

        }
    }
    //   const [userData, setUserData] = useState<UserDetails | null>(null);
    //   const [profile, setProfile] = useState("")

    const handleEditIconClick = (content: any): void => {
        
        setsendMessageValue(content);
        setIsEditMessage(true);
        setIsEditing(true);
        sethiddenObjValues(prev => ({
            ...prev,
            sendMessage: false
        }));

        // if (messageOrder !== 0) {
        //   setRegenTextarea(false);
        // } else {
        //   setRegenTextarea(true);
        // }
    };
    const handleCancel = (): void => {
        setsendMessageValue('')
        setIsEditing(false);
        setIsEditMessage(false)
    };

    const isDuplicateFileArray = (currentIndex: number): boolean => {
        for (let i = 0; i < currentIndex; i++) {
            if (fileURLs[i] && arraysEqual(fileURLs[i], fileURLs[currentIndex])) {
                return true;
            }
        }
        return false;
    };
 
    const arraysEqual = (a: string[], b: string[]): boolean => {
        if (!a || !b) return false;
        if (a.length !== b.length) return false;
        for (let i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    };

    const renderFileGrid = () => {
        if (!fileURLs[Chat_index] || isDuplicateFileArray(Chat_index)) {
          return null;
        }

        const files:any = fileURLs[Chat_index];
        const fileCount = files.length;

   
        return (
         <div className={`file-container px-3 pt-3 ${fileCount > 1 ? 'file-grid' : ''}`} style={{display: fileCount>0 ? "block" : "none"}}>
            {files.map((url:any, index:any) => {
              const fileName = url.split('/').pop();
              const isImage = fileName.includes('jpg') || fileName.includes('png');
              return (
                <div key={index} className="file-attach-container text-color-15 px-3 py-2" >
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <span className="file-name">
                      <img src={isImage ? '../images/attach-img.svg' : '../images-new/single-file.svg'} alt="file-icon" className="me-2" />
                      <span className="file-name-text">{fileName}</span>
                    </span>
                    <div className="d-flex align-items-center gap-2">
                      <button className="border-0 bg-transparent d-flex" onClick={async () => {
                        const presignedURL = await getFilePresignedURL(url);
                        if (presignedURL) {
                          window.open(presignedURL, "_blank", "noopener,noreferrer");
                        }
                      }}>
                        <img className="filter-icons" src="../images/eye-img.svg" alt="eye-icon" />
                      </button>
                      {Chat_index === currentChat.length - 2 && (isEditing) && (
                        <button className="border-0 bg-transparent d-flex" onClick={() => handleFileRemove(index)}>
                          <img className="filter-icons" src="../images/close.svg" alt="close-icon" />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      };
 

    const getFilePresignedURL = async(url:string) =>{
        
        try{
          const response = await getPresignedURL({"url":url})
          if (response.data.status_code == 200 ){
            return response.data.S3URL
          }else{
            console.error("Error in getExcelPresignedURL")
          }
        }catch(e){
          console.error("Error in getExcelPresignedURL", e)
    
        }
        
      }
    
    
    

    const handleFileRemove = (index:any) => {
        
        // Remove the file from fileURLs using filter
        const updatedFileURLs = fileURLs[Chat_index].filter((_:any, fileIndex:any) => fileIndex !== index);
        // Update the state of fileURLs (assuming you have a state or context for it)
        setFileURLs({ ...fileURLs, [Chat_index]: updatedFileURLs });
      
        // Remove the file from BLinksList using filter
        const updatedBLinksList = BLinksList?.filter((_:any, linkIndex:any) => linkIndex !== index);
        // Update the state of BLinksList
        BLinksList = updatedBLinksList
        sessionStorage.setItem("BLinksList",JSON.stringify(updatedBLinksList))
      };
      const renderContent = () => {
        // First trim any trailing newlines from the mainContent
        const trimmedContent = mainContent.replace(/\n+$/, '');
        
        let splitRegex = /\/(CodeGen|Prompt)/g;
        let parts = trimmedContent.split(splitRegex);
       
        return (
            <>
                {parts.map((part, index) => {
                    if (part === "CodeGen" || part === "Prompt") {
                        return (
                            <React.Fragment key={`special-${index}`}>
                                {" "}
                                <span
                                    className="font-14 font-semibold m-0"
                                    style={{ color: "#5C91F7", whiteSpace: "pre-wrap", wordBreak: "break-word" }}
                                >
                                    /{part}
                                </span>
                                {" "}
                            </React.Fragment>
                        );
                    }
                    return (
                        <span
                            key={`text-${index}`}
                            className="font-14 font-semibold m-0 text-color-15"
                            style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
                        >
                            {part.trim()}
                        </span>
                    );
                })}
            </>
        );
    };
 
    

    return (
        <div className="outgoing-content d-flex mt-5 mb-1" style={{marginLeft: navOpen == "Open" && rightNavOpen ? "13vw" : "", width : navOpen == "Open" && rightNavOpen ? "30vw" : "100%"}}>
           <div className={marginIndex ? "chat-content position-relative" : "position-relative"} >
                <img
                    src={profileImageUrl}
                    alt="bot-happy"
                    className="chat-img position-absolute bottom-0 end-0 header-profile"
                />
                <div className="chat-box right position-relative" style={{"width":kbContent&&webContent?"110%":""}}>
                    {isEditing && isLastUserMessage ? (
                        <div style={{ position: 'relative' }}>
                            <textarea
                                ref={textareaRef}
                                value={sendMessageValue}
                                onChange={(e) => { setsendMessageValue(e.target.value); setPopulateMsg(e.target.value);}}
                                style={{ minWidth: `${minWidth}px`, minHeight: `${minHeight}px` }}
                                className="edit-textarea text-color-1"
                            />
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <button
                                    className="btn focus-none border-0"
                                    type="button"
                                    id="button-addon2"
                                    onClick={async (e: React.MouseEvent<HTMLButtonElement>) => {
                                        
                                        const lastUserChatIndex = currentChat.map(chat => chat.role).lastIndexOf("assistant");
                                        if (lastUserChatIndex !== -1) {
                                            // && popMsg
                                            currentChat.splice(lastUserChatIndex, 1);
                                        }
                                        // setPopMsg(false);

                                        handleCancel();

                                        if (sendMessageValue.trim() !== "") {
                                            if (hiddenObjValues.inputFix === "Fix your response here") {
                                                sethiddenObjValues({
                                                    ...hiddenObjValues,
                                                    inputFix: "Type / to choose options",
                                                    sendMessage: false,
                                                });
                                                setsendMessageValue("");
                                                pushChat(currentChat, "user", sendMessageValue);
                                                postMessage({
                                                    role: "user",
                                                    content: sendMessageValue,
                                                },null,null);
                                            } else {
                                                setMessageOrder(0);
                                                sethiddenObjValues({
                                                    ...hiddenObjValues,
                                                    inputFix: "Type / to choose options",
                                                    sendMessage: true,
                                                });
                                                setsendMessageValue("");
                                                if (codeSnippetValue && CodeSnippet)
                                                    setsendMessageValue(sendMessageValue + " Code:" + codeSnippetValue.toString() + " ");
                                                if (isEditMessage === true) {
                                                    const lastUserChatIndex = currentChat.map(chat => chat.role).lastIndexOf("user");
                                                    if (lastUserChatIndex !== -1) {
                                                        if (isWebSearch) {
                                                            currentChat.pop();
                                                        } else {
                                                            currentChat[lastUserChatIndex].content = sendMessageValue;
                                                        }
                                                    }
                                                }

                                                if (isWebSearch) {
                                                    const { formattedResponse, rawOutput } = await handleWebSearch(sendMessageValue);
                                                    // setsendMessageValue(formattedResponse);
                                                    webSearchResult = rawOutput;
                                                    // populateMsg=
                                                    pushChat(currentChat, "user", formattedResponse);
                                                    
                                                }

                                                if (newChat) {
                                                    if (BLinksList) {
                                                        await postMessage(
                                                          {
                                                            role: "user",
                                                            content: BLinksList + " " + sendMessageValue,
                                                          },
                                                          null,
                                                          null,
                                                          webSearchResult,
                                                          "",
                                                        "",
                                                        "",
                                                        BLinksList
                                                        );
                                                    }
                                                    else {
                                                        await postMessage({
                                                            role: "user",
                                                            content: sendMessageValue,
                                                        }, null, null, webSearchResult);
                                                    }
                                                    if (webSearchResult !== "") {
                                                        pushChat(currentChat, "user", "", "", webSearchResult);
                                                    }
                                                }
                                            }
                                        } else {
                                            // ("give you content");
                                        }
        
                                        setFileUploaded(false);
                                    }}
                                >
                                    <img src="images/Default/send-img.svg" alt="send-img" style={{ width: '20px', height: '20px' }} />
                                </button>
                                <button
                                    className="btn focus-none border-0"
                                    type="button"
                                    id="button-addon2"
                                    onClick={() => {
                                        
                                        if (isAskAsami || messageOrder === 0) {
                                            sethiddenObjValues({
                                                ...hiddenObjValues,
                                                sendMessage: false,
                                            });
                                        }
                                        handleCancel();
                                        
                                        // setIsEditMessage(false);
                                        setsendMessageValue("");
                                    }}
                                >
                                    <img src="images/Default/cancel-icon.png" alt="send-img" style={{ width: '15px', height: '15px' }} />
                                </button>
                            </div>
                        </div>
                    ) : (
                        <>
                            {renderContent()}
                        </>
                    )}
                    {renderFileGrid()}
                <div>

               {kbContent !=="" && (
                <>
                        <SourceButton
                        sourceType="Knowledge Base"
                        content={kbContent}
                        chatIndex={Chat_index}
                        style={kbContent&&webContent}
                        />
                        </>
                    )}
                    {webContent !=="" && (
                        <SourceButton
                        sourceType="Web Source"
                        content={webContent}
                        chatIndex={Chat_index}
                        />
                    )}
                    </div>
                    <div
                        style={{
                            // backgroundImage: `url(${userData?.profileImage})`,
                            objectFit: 'cover',
                            backgroundSize: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            width: '50px',
                            height: '50px',
                            right: '-25px',
                            bottom: '-20px',
                        }}
                    ></div>
                    {!data && !isStopGeneration&& (
                        <div className="chat-option-edit bg-transparent">
                            {isLastUserMessage && ((isPrompt && hiddenObjValues.inputFix == "Fix your response here" || !isPrompt && hiddenObjValues.inputFix !== "Fix your response here")) && edit    && (
                                <button
                                    type="button"
                                    className="btn border-0 filter-icons"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleEditIconClick(mainContent);
                                    }}
                                >
                                    <img
                                        src="/images-new/edit-icon.svg"
                                        alt="edit-icon"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleEditIconClick(mainContent);
                                        }}
                                    />
                                </button>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default OutgoingContent;