//SQ_BARG_1.1 Imports necessary modules and libraries
import React, { useEffect, useRef, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';
import { ApexOptions } from 'apexcharts';
import { PaletteMode } from '@mui/material';

//SQ_BARG_1.2 Uses the interface for tyesafety
interface AsamiUsageDataItem {
  Practice?: string;
  Project?: string;
  AsamiUsage: number;
}

interface BarGraphCompProps {
    AsamiUsageData: any[];
    themeMode: PaletteMode;
  }
  
  const BarGraphComp: React.FC<BarGraphCompProps> = ({ AsamiUsageData, themeMode }) => {
  //SQ_BARG_1.3 initializes state variables
  const theme = useTheme();
  const chartRef = useRef<HTMLDivElement>(null);
  const [textColor, setTextColor] = useState<string>('#000000');
  const [chartKey, setChartKey] = useState<number>(0);
  //SQ_LB_1.5 to 1.11 updates the theme color of graph according to light and dark theme of parent component
  useEffect(() => {
    const updateTextColor = () => {
      if (chartRef.current) {
        const card = chartRef.current.closest('.custom-dashboard-section');
        if (card) {
          const bgColor = window.getComputedStyle(card).backgroundColor;
          const rgb = bgColor.match(/\d+/g);
          if (rgb) {
            const brightness = (parseInt(rgb[0]) * 299 + parseInt(rgb[1]) * 587 + parseInt(rgb[2]) * 114) / 1000;
            setTextColor(brightness > 128 ? '#000000' : '#ffffff');
          }
        }
      }
    };

    const resizeObserver = new ResizeObserver(updateTextColor);

    if (chartRef.current) {
      const card = chartRef.current.closest('.custom-dashboard-section');
      if (card) {
        resizeObserver.observe(card);
      }
    }

    updateTextColor();
    setChartKey(prev => prev + 1);

    return () => resizeObserver.disconnect();
  }, [theme]);

  //SQ_BARG_1.12 to 1.22 data processing and formatting for user visual is done here
  const labels: string[] = [];
  const yAxis: number[] = [];


  AsamiUsageData?.forEach((ele) => {
    if (ele.Practice) {
      labels.push(ele.Practice);
      yAxis.push(ele.AsamiUsage);
    } else if (ele.Project) {
      labels.push(ele.Project);
      yAxis.push(ele.AsamiUsage);
    }
  });

  const formatNumber = (value: number): string => {
    if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + 'M';
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + 'K';
    }
    return value.toString();
  };
  //SQ_BARG_1.23 to 1.26 series for the graph is defined here
  const series: ApexAxisChartSeries = [
    {
      name: 'Asami Usage',
      data: yAxis,
    },
  ];
  //SQ_BARG_1.27 to 1.36 options are configured for the graph here
  const options: ApexOptions = {
    chart: {
      type: 'bar',
      toolbar: { show: false },
      background: 'transparent',
      foreColor: textColor,
    },
    plotOptions: {
      bar: {
        borderRadius: 7,
        borderRadiusApplication: 'end',
        columnWidth: '40%',
        dataLabels: { position: 'top' },
      }
    },
    dataLabels: {
      enabled: true,
      formatter: (val: number) => formatNumber(val),
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: [textColor]
      }
    },
    colors: [theme.palette.mode === 'dark' ? '#8c7ae6' : '#BEB1FF'],
    xaxis: {
      categories: labels,
      labels: {
        style: {
          colors: textColor
        },
        formatter: (val: string) => val.length > 5 ? val.substring(0, 5) + '...' : val,
      },

    },
    yaxis: {
      labels: {
        formatter: (val: number) => formatNumber(val),
        style: {
          colors: textColor
        }
      },
    },
    grid: {
      row: { colors: ['transparent'] },
      borderColor: textColor === '#ffffff' ? '#888888' : '#f1f1f1',
      strokeDashArray: [5] as any
    },
    states: {
      hover: { filter: { type: 'darken', value: 0.8 } as any }
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (val: number) => `${val} chats`
      },
      x: {
        formatter: (val: number, { series, seriesIndex, dataPointIndex, w }) => {
          return w.globals.labels[dataPointIndex];
        }
      },
      theme: textColor === '#ffffff' ? 'dark' : 'light',
    },
  };

  return (
    <div id="chart" ref={chartRef}>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={272}
        key={`${chartKey}-${textColor}`}
      />
    </div>
  );
};

export default BarGraphComp;