import React from 'react';
import { useChat } from '../../Utility/ChatContext';


interface JsonDisplayProps {
  jsonObj: any;
  userData: any;
}

const JsonDisplay: React.FC<JsonDisplayProps> = ({ jsonObj, userData }) => {
  const {profile} = useChat()
  if (!jsonObj) return null;

  let profileImageUrl = ''
    if(profile){
        profileImageUrl=profile
        
    }
    else{
        if(userData?.profileImage){
            profileImageUrl=userData?.profileImage
            
        }
        else{
            profileImageUrl=''
            

        }
    }

  const extractFilename = (value: string) => {
    if (typeof value === 'string' && value.includes('avaeusasamistgbsdev.blob.core.windows.net')) {
      try {
        const url = new URL(value);
        const fullFilename = decodeURIComponent(url.pathname.split('/').pop() || '');
        const baseFilename = fullFilename.split('_')[0]; // Extract the part before the first underscore
        return baseFilename;
      } catch (error) {
        console.error("Error processing URL:", error);
        return value;
      }
    }
    return value;
  };

  const renderValue = (value: any) => {
    if (Array.isArray(value)) {
      return value.join(",");
    }
    if(typeof value == 'object'){
      return JSON.stringify(value);
    }
    return extractFilename(value);
  };

  const renderKeyValuePair = (key: string, value: any) => {
    const trimmedKey = key.trim();
    
    
    
    if (trimmedKey === "Tech Stack" || trimmedKey === "Type Of Code") {
      return (
        <div className="col-md-6 mb-3" key={key}>
          <label className="font-14 font-semibold grey-blue">
            {key}:
          </label>
          <span className="font-14 font-semibold text-color-1">
            {renderValue(value)}
          </span>
        </div>
      );
    }
    
    if (trimmedKey === "Uploaded media/Doc") {
      return (
        <div className="col-md-12 mb-2" key={key}>
          <label className="font-14 font-semibold text-color-1">
            {key !== "" ? key : "User Request"}:
          </label>
          <span className="font-14 font-semibold text-color-1">
            {renderValue(value)}
          </span>
        </div>
      );
    }
    
    return (
      <div className="col-md-12 mb-2" key={key}>
        <label className="font-14 font-semibold grey-blue">
          {key !== "" ? key : "User Request"}:
        </label>
        <span className="font-14 font-semibold text-color-1">
          {renderValue(value)}
        </span>
      </div>
    );
  };

  return (
    <div className="outgoing-content d-flex w-100">
      <div className="chat-content position-relative">
        <div
          className="chat-box right position-relative"
          style={{ padding: "20px", marginTop: "12%" }}
        >
          <div className="row">
            {Object.entries(jsonObj).map(([key, value]) => renderKeyValuePair(key, value))}
          </div>
          <div
            style={{
              backgroundImage: `url(${profileImageUrl})`,
              objectFit: 'cover',
              backgroundSize: '100%',
              borderRadius: '50%',
              position: 'absolute',
              width: '50px',
              height: '50px',
              right: '-25px',
              bottom: '-20px',
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default JsonDisplay;