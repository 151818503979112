import React, { useState, useRef, useEffect, useCallback } from 'react';

interface HighlightedTextareaProps {
  isRecording: boolean;
  pauseButtonEnable: boolean;
  hiddenObjValues: { inputFix: string };
  isEditing: boolean;
  sendMessageValue: string;
  handleTextareaChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  navOpenHeader: string;
  rightNav: boolean;
  sendFunctionality: (event: React.KeyboardEvent<HTMLTextAreaElement>) => Promise<void>;
  handlePaste: (event: React.ClipboardEvent<HTMLTextAreaElement>) => void;
  caretCoords: any;
  setCaretCoords: any;
  showToastr:any;
}

const HighlightedTextarea: React.FC<HighlightedTextareaProps> = ({
  isRecording,
  pauseButtonEnable,
  hiddenObjValues,
  isEditing,
  sendMessageValue,
  handleTextareaChange,
  navOpenHeader,
  rightNav,
  sendFunctionality,
  handlePaste,
  caretCoords,
  setCaretCoords, 
  showToastr
}) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [highlightedText, setHighlightedText] = useState('');
  

  const getCaretCoordinates = (element: HTMLTextAreaElement, position: number) => {
    const div = document.createElement('div');
    const copyStyle = getComputedStyle(element);
    for (const prop of copyStyle) {
      div.style.setProperty(prop, (copyStyle as any)[prop], 'important');
    }

    div.textContent = element.value.substr(0, position);

    const span = document.createElement('span');
    span.textContent = element.value.substr(position) || '.';

    div.appendChild(span);
    document.body.appendChild(div);

    const { offsetTop: top, offsetLeft: left } = span;

    document.body.removeChild(div);

    return { top, left };
  };

  const updateCaretCoords = useCallback(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      const caretPos = textarea.selectionStart;
      const coords = getCaretCoordinates(textarea, caretPos);
      setCaretCoords(coords);
    }
  }, []);

  useEffect(() => {
    updateCaretCoords();
  }, [sendMessageValue, updateCaretCoords]);

  useEffect(() => {
    const highlightKeywords = (text: string) => {
      const keywords = [''];
      let result = text;
      keywords.forEach(keyword => {
        const regex = new RegExp(keyword, 'gi');
        result = result.replace(
          regex,
          `<span class="highlight">${keyword}</span>`
        );
      });
      return result;
    };

    setHighlightedText(highlightKeywords(sendMessageValue));
  }, [sendMessageValue]);

  const getWidth = () => {
    if (navOpenHeader === 'Close' && !rightNav) return '70vw';
    if (navOpenHeader === 'Close' && rightNav) return '38vw';
    if (navOpenHeader === 'Open' && !rightNav) return '58vw';
    if (navOpenHeader === 'Open' && rightNav) return '26vw';
    return '55vw';
  };

  useEffect(() => {
    const syncScroll = () => {
      const textarea = textareaRef.current;
      const backdrop = document.querySelector('.backdrop');
      if (textarea && backdrop) {
        backdrop.scrollTop = textarea.scrollTop;
        backdrop.scrollLeft = textarea.scrollLeft;
      }
    };

    const textarea = textareaRef.current;
    if (textarea) {
      textarea.addEventListener('scroll', syncScroll);
      return () => {
        textarea.removeEventListener('scroll', syncScroll);
      };
    }
  }, []);

  return (
    <div className="highlighted-textarea" style={{ width: getWidth() }}>
      <div
        className="backdrop"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          pointerEvents: 'none',
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
          overflow: 'hidden',
        }}
        dangerouslySetInnerHTML={{ __html: highlightedText }}
      />
      <textarea
        ref={textareaRef}
        disabled={isRecording || showToastr}
        className="form-control border-0 focus-none bg-transprent sendMessage font-16 focus-none text-color-2 chat-text-fix"
        placeholder={pauseButtonEnable ? 'Listening...' : hiddenObjValues.inputFix}
        value={isEditing ? '' : sendMessageValue}
        onChange={(e) => {
          handleTextareaChange(e)
          updateCaretCoords()
        }}
        onKeyDown={async (e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            await sendFunctionality(e);
            updateCaretCoords()
          }
        }}
        onClick={updateCaretCoords}
        onPaste={(e) => {
          handlePaste(e);
          updateCaretCoords();
        }}
        spellCheck={false}
        style={{
          position: 'relative',
          background: 'transparent',
          width: '110%',
          resize: 'none',
          overflow: 'auto',
        }}
      />
    </div>
  )
};

export default HighlightedTextarea;