import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { ButtonActionsContextProps, ButtonConfig } from './ButtonComponentInterface';
import { NavFooterProviderProps } from '../NavFooterComponent/NavFooterInterface';

// Create the context with default values
export const ButtonActionsContext = createContext<ButtonActionsContextProps>({
  getButtonConfig: (id: string) => undefined,
  handleNewChat: () => {},
  askAsami: () => {},
  handleToggle: () => {},
});

// Custom hook to use the context
export const useButtonActions = () => useContext(ButtonActionsContext);

// Provider component
export const ButtonActionsProvider: React.FC<NavFooterProviderProps> = ({ children }) => {
  const history = useNavigate();
  const [leftNav, setLeftNav] = useState<'Open' | 'Close'>('Open');

  // Function to handle starting a new chat
  const handleNewChat = useCallback(() => {
    localStorage.setItem("PastConversation", "False");
    history("/chat");
  }, [history]);

  // Function to handle the "Ask Asami" action
  const askAsami = useCallback(() => {
    ['issuesandtaskState', 'isModule', 'previousLocation', 'projectState', 'BLinksList'].forEach(item => sessionStorage.removeItem(item));  
    const newAskAsamiKbData  = {
      chatID: null,
      collectionName:  null,
      indexName: null,
      kbFiles: [], 
      kbUrl: [], 
      isKbRetriever: true
    };
    sessionStorage.setItem('askAsamiKbData', JSON.stringify(newAskAsamiKbData));
    sessionStorage.setItem('BLinksList', JSON.stringify([]));
    sessionStorage.setItem('promptKbFile','[]');
    sessionStorage.setItem('toasterDisplay','false')
    sessionStorage.setItem('isStreaming','false')
    // history('/chat', {
    //   state: {
    //     startConversation: true,
    //     hidePrompts: true,
    //     sendMessage: false,
    //     fixButton: true,
    //     yesFixButton: true,
    //     askAsamiChatID: true
    //   }
    // });
    window.location.href = '/chat';
  }, [history]);

  // Function to toggle the left navigation bar
  const handleToggle = useCallback(() => {
    setLeftNav(leftNav => leftNav === "Close" ? "Open" : "Close");
    sessionStorage.setItem('NavOpen', leftNav);
  }, [leftNav]);

  // Update button configurations dynamically based on the leftNav state
  useEffect(() => {
    setButtonConfigs(prevConfigs => 
      prevConfigs.map(config => 
        config.id === 'toggle' 
          ? {
              ...config,
              iconSrc: leftNav === 'Open' ? "images/Default/leftnav-open.svg" : "images/Default/leftnav-close.svg",
              className: leftNav === 'Open' ? "toggle-icon" : "toggle-icon-closed"
            }
          : config
      )
    );
  }, [leftNav]);

  // Define button configurations
  const [buttonConfigs, setButtonConfigs] = useState<ButtonConfig[]>([
    {
      id: 'ask-asami',
      className: "btn btn-gradient-outline border-radius-4 font-14 font-medium w-200 py-2 text-white d-flex align-items-center justify-content-center",
      iconSrc: "images/white-diamond.svg",
      iconAlt: "add-icon",
      text: "Ask Asami",
      textClassName: "",
      action: "askAsami"
    },
    {
      id: 'toggle',
      className: "position-absolute open-collapse-position handle-toggle-fix",
      iconSrc: leftNav === 'Open' ? "images/Default/leftnav-open.svg" : "images/Default/leftnav-close.svg",
      iconAlt: "toggle-icon",
      action: "handleToggle"
    },
  ]);

  // Function to retrieve a specific button configuration by id
  const getButtonConfig = useCallback((id: string) => {
    return buttonConfigs.find(button => button.id === id);
  }, [buttonConfigs]);

  // Provide the context value to children components
  return (
    <ButtonActionsContext.Provider value={{ getButtonConfig, handleNewChat, askAsami, handleToggle, leftNav, setLeftNav }}>
      {children}
    </ButtonActionsContext.Provider>
  );
};