import React, { createContext, useState, useContext, useEffect, useRef,useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as SignalR from "@microsoft/signalr";

import { getBindParams, getChatHistory, fetchChat, getValyriaChat } from "../Service/API";
import { _config } from "../Service/API-Constants";
import UserContext from "../Auth/UserContext";
import { useNavLink } from '../Header Components/NavLinkComponent/NavLinkContext';
import NoRecordsFound from '../Reusable Components/NoRecordsFound';
import axios from 'axios';

interface HeaderContextProps {
    userObj: any; setUserObj: React.Dispatch<React.SetStateAction<any>>;
    showRequestAndLogs: boolean; setShowRequestAndLogs: React.Dispatch<React.SetStateAction<boolean>>;
    showManagePrompt: boolean; setShowManagePrompt: React.Dispatch<React.SetStateAction<boolean>>;
    showLlmConfig: boolean; setShowLlmConfig: React.Dispatch<React.SetStateAction<boolean>>;
    showNotification: boolean; setShowNotification: React.Dispatch<React.SetStateAction<boolean>>;
    bindChatHistory: any; setBindChatHistory: React.Dispatch<React.SetStateAction<any>>;
    chatContent: any; setChatContent: React.Dispatch<React.SetStateAction<any>>;
    handleChatClick: (chatID: string, chatType: string, projectID: string) => void;
    handleNewChat: () => void;
    askAsami: () => void;
    fetchBindParams: () => Promise<void>;
    handleCopyClick: () => void;
    getChatMessages: (chatID: string, chatType: string) => Promise<void>;
    applyTheme: (theme: string) => void;
    chatType: string; setChatType: React.Dispatch<React.SetStateAction<string>>;
    fetchChatHistory: () => Promise<void>;
    bindChatContent: (chatContent: any[]) => JSX.Element[] | string | undefined;
    bindChat:any;
    isCopied: boolean; setIsCopied: React.Dispatch<React.SetStateAction<boolean>>;
    shareChatID: string; setShareChatID: React.Dispatch<React.SetStateAction<string>>;
    isChecked: boolean; setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
    tab: string; setTab: React.Dispatch<React.SetStateAction<string>>;
    chatHistoryHover: string | null; setChatHistoryHover: React.Dispatch<React.SetStateAction<string | null>>;
    chatIdHoverPurpose: string | null; setChatIdHoverPurpose: React.Dispatch<React.SetStateAction<string | null>>;
    sharePromptName: string; setSharePromptName: React.Dispatch<React.SetStateAction<string>>;
    isSharePopupOpen: boolean; setIsSharePopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
    profileImage: string; setProfileImage: React.Dispatch<React.SetStateAction<string>>;
    showProjects: boolean; setShowProjects: React.Dispatch<React.SetStateAction<boolean>>;
    isValyria: boolean; setIsValyria: React.Dispatch<React.SetStateAction<boolean>>;
    showFeedback: boolean; setShowFeedback: React.Dispatch<React.SetStateAction<boolean>>;
    showHub: boolean; setShowHub: React.Dispatch<React.SetStateAction<boolean>>;
    showAsamiUsage: boolean; setShowAsamiUsage: React.Dispatch<React.SetStateAction<boolean>>;
    activeComponent: string; setActiveComponent: React.Dispatch<React.SetStateAction<string>>;
    terminalData: any; setTerminalData: React.Dispatch<React.SetStateAction<any>>;
    loading: boolean; setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    projectId: string; setProjectId: React.Dispatch<React.SetStateAction<string>>;
}

const HeaderContext = createContext<HeaderContextProps | undefined>(undefined);

export const HeaderProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    // Grouped states and setters
    const [userObj, setUserObj] = useState<any>(null);
    const [showRequestAndLogs, setShowRequestAndLogs] = useState(true);
    const [showManagePrompt, setShowManagePrompt] = useState(true);
    const [showLlmConfig, setShowLlmConfig] = useState(true);
    const [showNotification, setShowNotification] = useState(false);
    const [bindChatHistory, setBindChatHistory] = useState<any>(null);
    const [chatContent, setChatContent] = useState([]);
    const [isCopied, setIsCopied] = useState(false);
    const [shareChatID, setShareChatID] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [tab, setTab] = useState("Asami");
    const [chatHistoryHover, setChatHistoryHover] = useState<string | null>(null);
    const [chatIdHoverPurpose, setChatIdHoverPurpose] = useState<string | null>(null);
    const [sharePromptName, setSharePromptName] = useState<string>("");
    const [isSharePopupOpen, setIsSharePopupOpen] = useState<boolean>(true);
    const [profileImage, setProfileImage] = useState<string>("");
    const [showProjects, setShowProjects] = useState<boolean>(true);
    const [showFeedback, setShowFeedback] = useState<boolean>(true);
    const [showHub, setShowHub] = useState<boolean>(true);
    const [showAsamiUsage, setShowAsamiUsage] = useState<boolean>(true);
    const [activeComponent, setActiveComponent] = useState<string>('');
    const [isValyria, setIsValyria] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [chatType, setChatType] = useState<string>("");
    const [terminalData, setTerminalData] = useState<any>({ data: [] });
    const [projectId, setProjectId] = useState<string>("");
      const { activeTab, setActiveTab, role } = useNavLink();
    // Refs and hooks
    const isValyriaRef = useRef(false);
    const navigate = useNavigate();
    const location = useLocation();
    const userData = useContext(UserContext);

    // SignalR connection variables
    let connection: SignalR.HubConnection;
    let connection_id: string;

    // Fetch chat history on component mount
    useEffect(() => {
      fetchChatHistory();
    }, []);

    // Apply saved theme on component mount
    useEffect(() => {
      const savedTheme = localStorage.getItem("selectedTheme");
      if (savedTheme) {
        setIsChecked(savedTheme === "dark");
        applyTheme(savedTheme);
      }
    }, []);

    // Effect to set the active tab based on the current path
    useEffect(() => {
      let path = window.location.pathname;
      if (path === "/chat") {
          localStorage.setItem("ViewChat", "False");
          localStorage.setItem("PastConversation", "True");
          setTab("Asami");
      } else if (path === "/dashboard") {
          setTab("hub");
      } else if (path === "/manageproject") {
          setTab("manage-project");
      } else if (path === "/asamiusagetable") {
          setTab("asami-usage");
      } else if (path === "/asami-manage-prompt") {
          setTab("manage-prompt");
      } else if (path === "/llm-config-grid") {
          setTab("llm-grid");
      } else if (path === "/asami-user-activity") {
          setTab("user-activity");
      } else if (path === "/feedbackcards") {
          setTab("feedback");
      } else {
          setTab("Asami");
      }
    }, [location]);

    // Effect to set user-related states based on user data
    useEffect(() => {
      setUserObj(userData);
      setProfileImage(userObj?.profileImage);
      if (userData) {
          if (userData?.role === "Lead") {
              setShowRequestAndLogs(false);
              setShowProjects(false);
              setShowFeedback(false);
          } else if (userData?.role === "Leadership") {
              setShowHub(false);
              setShowAsamiUsage(false);
              setShowManagePrompt(false);
              setShowLlmConfig(false);
              setShowProjects(false);
              setShowFeedback(false);
          } else if (userData?.role === "User") {
              setShowManagePrompt(true);
              setShowRequestAndLogs(true);
              setShowHub(true);
              setShowProjects(false);
              setShowAsamiUsage(true);
              setShowLlmConfig(true);
          }
      }
    }, [userObj, userData]);

    // Function to fetch bind parameters
    const fetchBindParams = async () => {
        try {
            const response = await getBindParams({});
            // setShowManagePrompt(response.data.manageRequest[0]["isActive"]);
        } catch (error) {
            console.error(error);
        }
    };

    // Function to copy chat URL to clipboard
    const handleCopyClick = () => {
      const chatUrl = `${window.location.origin}/chat?chatID=${shareChatID}&share=true`;
      navigator.clipboard.writeText(chatUrl).then(() => {
          setIsCopied(true);
          setTimeout(() => {
              setIsCopied(false);
          }, 2000);
      }).catch(err => {
          console.error('Failed to copy: ', err);
      });
    };

    // Function to fetch chat messages based on chat ID and type
    const getChatMessages = async (chatID: string, chatType: string) => {
      try {
          let id = localStorage.getItem('valyriaId');
          if (chatType === id) {
              let payload = { "asami_chat_id": chatID };
              setLoading(true);
              let response = await getValyriaChat(payload);
              setIsValyria(true);
              setChatContent(response.data);
              setLoading(false);
              sessionStorage.setItem('user_id', response.data[0].user_id);
              sessionStorage.setItem('project_id', response.data[0].project_id);
          } else {
              setLoading(true);
              let result = await fetchChat({ chatID: chatID });
              setLoading(false);
              setChatContent(result.data.ResponseData.currentChat);
          }
      } catch (error) {
          console.error(error);
      }
    };

    // Function to apply a theme to the application
    const applyTheme = (theme: string) => {
      document.documentElement.setAttribute("data-theme", `${theme}-theme`);
      const bodyClasses = document.body.classList;
      bodyClasses.remove('light-theme', 'dark-theme');
      bodyClasses.add(`${theme}-theme`);
    };

    // Function to fetch chat history for the user
    const fetchChatHistory = async () => {
      try {
          let chatHistoryObj = {
              userEmailID: userObj?.email,
              search: "",
              sort: "createdDateTime Desc",
              count: 10,
              fromDate: "",
              toDate: "",
              approvalStatusName: "",
              codegenStatusName: ""
          };
          let response = await getChatHistory(chatHistoryObj);
          if (response.status === 200) {
              setBindChatHistory(response.data.data.chats);
          }
      } catch (error) {
          console.error(error);
      }
    };

    // Function to bind chat content to JSX elements
    const bindChatContent = (chatContent: any[]): JSX.Element[] | string | undefined => {
      if (chatContent && Array.isArray(chatContent)) {
          return chatContent.map((value, index) => (
              <div key={index} className="col-md-12 py-3 px-3">
                  <span className="d-flex">
                      <span className="d-block">
                          <img
                              style={{ height: "44px", width: "44px", borderRadius: "50%" }}
                              src={value.role === "user" ? userObj?.profileImage : "images/Default/asami-bot.svg"}
                              alt="profile-pic"
                          />
                      </span>
                      <span className="d-block ms-3 mt-2">
                          <h5 className="font-18 font-bold text-color-1">
                              {value.role === "user" ? "You" : "Asami"}
                          </h5>
                          <p className="scroll-content font-medium font-16 text-color-10">
                              {value.content}
                          </p>
                      </span>
                  </span>
              </div>
          ));
      }
      return undefined;
    };
  

    // Function to bind chat history into grouped and interactive UI elements
    const bindChat = (bindChatHistory: any): JSX.Element | null => {
      if (bindChatHistory) {
          const today = new Date();
          const yesterday = new Date(today);
          yesterday.setDate(today.getDate() - 1);
          const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);


          // Group chats by date (Today, Yesterday, or Other)
          const groupedChats = bindChatHistory.reduce((groups: any, value: any) => {
              const chatDate = new Date(value.createdDateTime);
              const isToday = chatDate.toDateString() === today.toDateString();
              const isYesterday = chatDate.toDateString() === yesterday.toDateString();
              const isThisMonth = chatDate >= startOfMonth && chatDate < today;

              let groupKey = 'other';
              if (isToday) {
                  groupKey = 'today';
              } else if (isYesterday) {
                  groupKey = 'yesterday';
              }
              else if (isThisMonth) {
                groupKey = 'thisMonth';
              }

              if (!groups[groupKey]) {
                  groups[groupKey] = [];
              }
              groups[groupKey].push(value);
              return groups;
          }, {});

          return (
              <>
                  {/* Render Today's Chats */}
                  {groupedChats.today && (
                      <>
                          <label className="font-14 font-medium text-color-1 mb-2 custom-none">Today</label>
                          <ul className="nav nav-pills flex-column mb-3 custom-none">
                              {groupedChats.today.map((chat: any, index: number) => (
                                  <li key={`today-${index}`}>
                                      <a
                                          className="d-flex align-items-center justify-content-between gap-2 py-2 text-decoration-none"
                                          onClick={() => {
                                            if(!sessionStorage.getItem('isStreaming') || sessionStorage.getItem('isStreaming')  =='false'){
                                              ['issuesandtaskState', 'isModule', 'previousLocation', 'projectState'].forEach(item => sessionStorage.removeItem(item));
                                              const newAskAsamiKbData = {
                                                  chatID: null,
                                                  collectionName: null,
                                                  indexName: null,
                                                  kbFiles: [],
                                                  kbUrl: [],
                                                  isKbRetriever: true,
                                              };
                                              sessionStorage.setItem('askAsamiKbData', JSON.stringify(newAskAsamiKbData));
                                              sessionStorage.setItem('BLinksList', JSON.stringify([]));
                                              let id = localStorage.getItem('valyriaId');
                                              setIsValyria(chat.type === id);
                                              handleChatClick(chat.chatID, chat.type, chat.valyria_project_id);
                                              setChatIdHoverPurpose(chat?.chatID);
                                              localStorage.setItem("PastConversation", "True");}
                                              else{
                                                sessionStorage.setItem('toasterDisplay','true')
                                            }
                                          }}
                                          onMouseEnter={() => setChatHistoryHover(`t${index}`)}
                                          onMouseLeave={() => setChatHistoryHover(null)}
                                          style={{ cursor: "pointer" }}

                                      >
                                          <div className="d-flex align-items-center gap-2">
                                              <img src={chat.type === localStorage.getItem('valyriaId') ? '../images/frd-code.svg' : '../images/html-frd.svg'} alt="chat-icon" />
                                              <span className="text-decoration-none font-14 font-medium text-color-1">
                                                  {chat.chatName ? chat.chatName?.length > 25 ? `${chat.chatName.substring(0, 25)}...` : chat.chatName : "undefined"}
                                              </span>
                                          </div>
                                          <img
                                              className={`filter-icons share-icon share-button-chat-history ${chatHistoryHover === `t${index}` || 'd-none'}`}
                                              style={{ height: '5%', width: '6%' }}
                                              src="../images/share-grey.svg"
                                              alt="share-icon"
                                              data-bs-toggle="modal"
                                              data-bs-target="#share-convo-popup"
                                              onClick={(event) => {
                                             
                                                
                                                  event.stopPropagation();
                                                  setSharePromptName(chat.promptName);
                                                  setShareChatID(chat.chatID);
                                                  setIsSharePopupOpen(false);
                                                  sessionStorage.setItem('type', chat.type);
                                                  getChatMessages(chat.chatID, chat.type);
                                                  setChatType(chat.type==localStorage.getItem('valyriaId')? 'CodeGen': 'General')
                                                  setProjectId(chat.valyria_project_id);
                                              }}
                                              onMouseEnter={() => setChatHistoryHover(`t${index}`)}
                                              onMouseLeave={() => setChatHistoryHover(null)}
                                          />
                                      </a>
                                  </li>
                              ))}
                          </ul>
                      </>
                  )}

                  {/* Render Yesterday's Chats */}
                  {groupedChats.yesterday && (
                      <>
                          <label className="font-14 font-medium text-color-1 mb-2 custom-none">Yesterday</label>
                          <ul className="nav nav-pills flex-column mb-3 custom-none">
                              {groupedChats.yesterday.map((chat: any, index: number) => (
                                  <li key={`yesterday-${index}`}>
                                      <a
                                          className="d-flex align-items-center justify-content-between gap-2 py-2 text-decoration-none"
                                          onClick={() => {
                                            if(!sessionStorage.getItem('isStreaming') || sessionStorage.getItem('isStreaming')  =='false'){
                                                ['issuesandtaskState', 'isModule', 'previousLocation', 'projectState'].forEach(item => sessionStorage.removeItem(item));
                                                const newAskAsamiKbData = {
                                                    chatID: null,
                                                    collectionName: null,
                                                    indexName: null,
                                                    kbFiles: [],
                                                    kbUrl: [],
                                                    isKbRetriever: true,
                                                };
                                                sessionStorage.setItem('askAsamiKbData', JSON.stringify(newAskAsamiKbData));
                                                sessionStorage.setItem('BLinksList', JSON.stringify([]));
                                                let id = localStorage.getItem('valyriaId');
                                                setIsValyria(chat.type === id);
                                                handleChatClick(chat.chatID, chat.type, chat.valyria_project_id);
                                                setChatIdHoverPurpose(chat?.chatID);
                                                localStorage.setItem("PastConversation", "True");}
                                                else{
                                                  sessionStorage.setItem('toasterDisplay','true')
                                              }
                                          }}
                                          onMouseEnter={() => setChatHistoryHover(`y${index}`)}
                                          onMouseLeave={() => setChatHistoryHover(null)}
                                          style={{ cursor: "pointer" }}
                                      >
                                          <div className="d-flex align-items-center gap-2">
                                              <img src={chat.type === localStorage.getItem('valyriaId') ? '../images/frd-code.svg' : '../images/html-frd.svg'} alt="chat-icon" />
                                              <span className="text-decoration-none font-14 font-medium text-color-1">
                                                  {chat.chatName ? chat.chatName?.length > 25 ? `${chat.chatName.substring(0, 25)}...` : chat.chatName : "undefined"}
                                              </span>
                                          </div>
                                          <img
                                              className={`filter-icons share-icon share-button-chat-history ${chatHistoryHover === `y${index}` ||  'd-none'}`}
                                              style={{ height: '5%', width: '6%' }}
                                              src="../images/share-grey.svg"
                                              alt="share-icon"
                                              data-bs-toggle="modal"
                                              data-bs-target="#share-convo-popup"
                                              onClick={(event) => {
                                                  event.stopPropagation();
                                                  setSharePromptName(chat.promptName);
                                                  setShareChatID(chat.chatID);
                                                  setIsSharePopupOpen(false);
                                                  sessionStorage.setItem('type', chat.type);
                                                  getChatMessages(chat.chatID, chat.type);
                                                  setChatType(chat.type==localStorage.getItem('valyriaId')? 'CodeGen': 'General')
                                                  setProjectId(chat.valyria_project_id);
                                              }}
                                              onMouseEnter={() => setChatHistoryHover(`y${index}`)}
                                              onMouseLeave={() => setChatHistoryHover(null)}
                                          />
                                      </a>
                                  </li>
                              ))}
                          </ul>
                      </>
                  )}
                  {/* Render This Month Chats */}
                  {groupedChats.thisMonth && (
                      <>
                          <label className="font-14 font-medium text-color-1 mb-2 custom-none">This Month</label>
                          <ul className="nav nav-pills flex-column mb-3 custom-none">
                              {groupedChats.thisMonth.map((chat: any, index: number) => (
                                  <li key={`thisMonth-${index}`}>
                                      <a
                                          className="d-flex align-items-center justify-content-between gap-2 py-2 text-decoration-none"
                                          onClick={() => {
                                            if(!sessionStorage.getItem('isStreaming') || sessionStorage.getItem('isStreaming')  =='false'){
                                                ['issuesandtaskState', 'isModule', 'previousLocation', 'projectState'].forEach(item => sessionStorage.removeItem(item));
                                                const newAskAsamiKbData = {
                                                    chatID: null,
                                                    collectionName: null,
                                                    indexName: null,
                                                    kbFiles: [],
                                                    kbUrl: [],
                                                    isKbRetriever: true,
                                                };
                                                sessionStorage.setItem('askAsamiKbData', JSON.stringify(newAskAsamiKbData));
                                                sessionStorage.setItem('BLinksList', JSON.stringify([]));
                                                let id = localStorage.getItem('valyriaId');
                                                setIsValyria(chat.type === id);
                                                handleChatClick(chat.chatID, chat.type, chat.valyria_project_id);
                                                setChatIdHoverPurpose(chat?.chatID);
                                                localStorage.setItem("PastConversation", "True");}
                                                else{
                                                  sessionStorage.setItem('toasterDisplay','true')
                                              }
                                          }}
                                          onMouseEnter={() => setChatHistoryHover(`m${index}`)}
                                          onMouseLeave={() => setChatHistoryHover(null)}
                                          style={{ cursor: "pointer" }}
                                      >
                                          <div className="d-flex align-items-center gap-2">
                                              <img src={chat.type === localStorage.getItem('valyriaId') ? '../images/frd-code.svg' : '../images/html-frd.svg'} alt="chat-icon" />
                                              <span className="text-decoration-none font-14 font-medium text-color-1">
                                                  {chat.chatName ? chat.chatName?.length > 25 ? `${chat.chatName.substring(0, 25)}...` : chat.chatName : "undefined"}
                                              </span>
                                          </div>
                                          <img
                                              className={`filter-icons share-icon share-button-chat-history ${chatHistoryHover === `m${index}` || 'd-none'}`}
                                              style={{ height: '5%', width: '6%' }}
                                              src="../images/share-grey.svg"
                                              alt="share-icon"
                                              data-bs-toggle="modal"
                                              data-bs-target="#share-convo-popup"
                                              onClick={(event) => {
                                                  event.stopPropagation();
                                                  setSharePromptName(chat.promptName);
                                                  setShareChatID(chat.chatID);
                                                  setIsSharePopupOpen(false);
                                                  sessionStorage.setItem('type', chat.type);
                                                  getChatMessages(chat.chatID, chat.type);
                                                  setChatType(chat.type==localStorage.getItem('valyriaId')? 'CodeGen': 'General')
                                                  setProjectId(chat.valyria_project_id);
                                              }}
                                              onMouseEnter={() => setChatHistoryHover(`m${index}`)}
                                              onMouseLeave={() => setChatHistoryHover(null)}
                                          />
                                      </a>
                                  </li>
                              ))}
                          </ul>
                      </>
                  )}
                  {/* Render Older Chats */}
                  {groupedChats.other && (
                      <>
                          <label className="font-14 font-medium text-color-1 mb-2 custom-none">Previous Records</label>
                          <ul className="nav nav-pills flex-column mb-3 custom-none">
                              {groupedChats.other.map((chat: any, index: number) => (
                                  <li key={`other-${index}`}>
                                      <a
                                          className="d-flex align-items-center justify-content-between gap-2 py-2 text-decoration-none"
                                          onClick={() => {
                                            if(!sessionStorage.getItem('isStreaming') || sessionStorage.getItem('isStreaming')  =='false'){
                                                ['issuesandtaskState', 'isModule', 'previousLocation', 'projectState'].forEach(item => sessionStorage.removeItem(item));
                                                const newAskAsamiKbData = {
                                                    chatID: null,
                                                    collectionName: null,
                                                    indexName: null,
                                                    kbFiles: [],
                                                    kbUrl: [],
                                                    isKbRetriever: true,
                                                };
                                                sessionStorage.setItem('askAsamiKbData', JSON.stringify(newAskAsamiKbData));
                                                sessionStorage.setItem('BLinksList', JSON.stringify([]));
                                                let id = localStorage.getItem('valyriaId');
                                                setIsValyria(chat.type === id);
                                                handleChatClick(chat.chatID, chat.type, chat.valyria_project_id);
                                                setChatIdHoverPurpose(chat?.chatID);
                                                localStorage.setItem("PastConversation", "True");}
                                                else{
                                                  sessionStorage.setItem('toasterDisplay','true')
                                              }
                                          }}
                                          onMouseEnter={() => setChatHistoryHover(`o${index}`)}
                                          onMouseLeave={() => setChatHistoryHover(null)}
                                          style={{ cursor: "pointer" }}
                                      >
                                          <div className="d-flex align-items-center gap-2">
                                              <img src={chat.type === localStorage.getItem('valyriaId') ? '../images/frd-code.svg' : '../images/html-frd.svg'} alt="chat-icon" />
                                              <span className="text-decoration-none font-14 font-medium text-color-1">
                                                  {chat.chatName ? chat.chatName?.length > 25 ? `${chat.chatName.substring(0, 25)}...` : chat.chatName : "undefined"}
                                              </span>
                                          </div>
                                          <img
                                              className={`filter-icons share-icon share-button-chat-history ${chatHistoryHover === `o${index}` ||  'd-none'}`}
                                              style={{ height: '5%', width: '6%' }}
                                              src="../images/share-grey.svg"
                                              alt="share-icon"
                                              data-bs-toggle="modal"
                                              data-bs-target="#share-convo-popup"
                                              onClick={(event) => {
                                                  event.stopPropagation();
                                                  setSharePromptName(chat.promptName);
                                                  setShareChatID(chat.chatID);
                                                  setIsSharePopupOpen(false);
                                                  sessionStorage.setItem('type', chat.type);
                                                  getChatMessages(chat.chatID, chat.type);
                                                  setChatType(chat.type==localStorage.getItem('valyriaId')? 'CodeGen': 'General')
                                                  setProjectId(chat.valyria_project_id);
                                              }}
                                              onMouseEnter={() => setChatHistoryHover(`o${index}`)}
                                              onMouseLeave={() => setChatHistoryHover(null)}
                                          />
                                      </a>
                                  </li>
                              ))}
                          </ul>
                      </>
                  )}

                  {/* View All Conversations Link */}
                  {bindChatHistory.length>0?
                  <div className="d-flex align-items-center justify-content-between mt-4 mb-1" onClick={() => { navigate("/past-conversations") ;setActiveTab("") }} style={{ cursor: "pointer" }}>
                      <h5 className="font-16 font-semibold mb-0 align-items-center text-color-1"><span className="custom-none">View All</span></h5>
                      <a href="" className="custom-none"><img className="left-nav-icons" src="images-new/next-black-arrow.svg" alt="Next" /></a>
                  </div>
                  : <span className="text-center py-5 vh-50 custom-none" style={{ marginTop: "15%",height:"25%" }}>
                                        <NoRecordsFound message="No Records Found!" nodata="true"/>
                                          </span>}
              </>
          );
      }
      return null;
    };
  

    // Handle chat click event and update URL based on chat type and project ID
    const handleChatClick = (chatID: string, chatType: string, projectID: string) => {
        const newAskAsamiKbData = {
            chatID: null,
            collectionName: null,
            indexName: null,
            kbFiles: [],
            kbUrl: [],
            isKbRetriever: true,
            };
        sessionStorage.setItem('promptKbFile','[]')
        sessionStorage.setItem('askAsamiKbData', JSON.stringify(newAskAsamiKbData));
        sessionStorage.setItem('BLinksList', JSON.stringify([]));
      const pathname = `/chat`;
      const search = `?chatID=${chatID}`;

      // Update the ref value based on chat type
      isValyriaRef.current = chatType === localStorage.getItem('valyriaId');
      setIsValyria(isValyriaRef.current);

      const fullURL = `${pathname}${search}`;

      // Navigate to the appropriate URL based on chat type
      if (chatType !== localStorage.getItem('valyriaId')) {
        navigate(`${fullURL}`, { state: { chatID: chatID } });
      } else {
        navigate(`${fullURL}&projectID=${projectID}&type=CodeGen`, { state: { chatID: chatID } });
      }

      // Store chat type in session storage
      sessionStorage.setItem('type', chatType);
    };
    

    // Handle new chat creation and reset past conversation state
    const handleNewChat = () => {
      localStorage.setItem("PastConversation", "False");
      navigate("/chat");
    };

    // Reset Asami-related local storage and navigate to chat with initial state
    const askAsami = useCallback(() => {
            ['issuesandtaskState', 'isModule', 'previousLocation', 'projectState', 'BLinksList'].forEach(item => sessionStorage.removeItem(item));  
            const newAskAsamiKbData  = {
              chatID: null,
              collectionName:  null,
              indexName: null,
              kbFiles: [], 
              kbUrl: [], 
              isKbRetriever: true
            };
            sessionStorage.setItem('askAsamiKbData', JSON.stringify(newAskAsamiKbData));
            sessionStorage.setItem('BLinksList', JSON.stringify([]));
            // history('/chat', {
            //   state: {
            //     startConversation: true,
            //     hidePrompts: true,
            //     sendMessage: false,
            //     fixButton: true,
            //     yesFixButton: true,
            //     askAsamiChatID: true
            //   }
            // });
           
            window.location.href = '/chat';
          }, [navigate]);
  return (
    <HeaderContext.Provider
        value={{
            // Grouped states and setters
            terminalData, setTerminalData,
            sharePromptName, setSharePromptName,
            isSharePopupOpen, setIsSharePopupOpen,
            userObj, setUserObj,
            showRequestAndLogs, setShowRequestAndLogs,
            showManagePrompt, setShowManagePrompt,
            showLlmConfig, setShowLlmConfig,
            showNotification, setShowNotification,
            bindChatHistory, setBindChatHistory,
            chatContent, setChatContent,
            isCopied, setIsCopied,
            shareChatID, setShareChatID,
            isChecked, setIsChecked,
            tab, setTab,
            chatHistoryHover, setChatHistoryHover,
            chatIdHoverPurpose, setChatIdHoverPurpose,
            profileImage, setProfileImage,
            showProjects, setShowProjects,
            showFeedback, setShowFeedback,
            showHub, setShowHub,
            showAsamiUsage, setShowAsamiUsage,
            isValyria, setIsValyria,
            activeComponent, setActiveComponent,
            loading, setLoading,
            chatType, setChatType,
            projectId, setProjectId,

            // Grouped functions
            handleChatClick, handleNewChat, askAsami, fetchBindParams,
            handleCopyClick, getChatMessages, applyTheme, fetchChatHistory,
            bindChatContent, bindChat
        }}
    >
        {children}
    </HeaderContext.Provider>
);
};

export const useHeaderContext = () => {
  const context = useContext(HeaderContext);
  if (context === undefined) {
    throw new Error('useHeaderContext must be used within a HeaderProvider');
  }
  return context;
};