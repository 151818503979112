import { getSessionItem } from "../Utility/encrypt-storage";
import axios, { AxiosResponse, CancelToken, Method, AxiosHeaders } from "axios";

interface RequestConfig {
  method: Method;
  url: string;
  data?: any;
  headers: Record<string, string>;
  cancelToken?: CancelToken;
}

export async function Client(
  endpoint: string,
  body: any,
  requestType: Method,
  cancelToken?: CancelToken
): Promise<AxiosResponse> {
  try {
    const access_token = getSessionItem("access-token");
    const token = getSessionItem("api-token");
    const userEmailID = getSessionItem("user-email");
    // const userEmailID = String(localStorage.getItem("user-email"));

    let headers: Record<string, string> = {
      "access-token": access_token as string,
      authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };


    if (endpoint.includes('kroki.io')) {
      headers = {
        'Content-Type': 'text/plain',
      };
    } else {
      if (body instanceof FormData) {
        headers["Content-Type"] = "multipart/form-data";
        body.append("userEmailID", userEmailID);
      } else {
        headers["Content-Type"] = "application/json";
        body = { ...body, userEmailID };
      }
    }

    const url = `${endpoint}`;
    const configreq: RequestConfig = {
      method: requestType,
      url: url,
      data: body,
      headers: headers,
    };

    if (cancelToken && typeof cancelToken.throwIfRequested === 'function') {
      configreq.cancelToken = cancelToken;
    }
    const results = await axios(configreq);
    return results;
  } catch (error) {
    console.error("error in client.ts:",error)
    const dummyHeaders = new AxiosHeaders();
    dummyHeaders.set('Content-Type', 'application/json');

    // Create and return a dummy AxiosResponse
    const dummyResponse: AxiosResponse = {
      data: null,
      status: 500,
      statusText: 'Internal Server Error',
      headers: {},
      config: {
        url: endpoint,
        method: requestType,
        headers: dummyHeaders,
      },
    };

    return dummyResponse;
  }
}

