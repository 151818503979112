import React from "react";
import BouncingDotsLoader from "./BouncingDotsLoader";
const LoadingIndicator: React.FC = () => {
 
 
  return (
    <div className="d-flex flex-row mb-4 mt-5 loading-indicator-custom-padding">
      <img
        style={{ width: "60px", height: "100%" }}
        src="images/ai-images/Bot_profile.svg"
        alt="bot-happy"
        className="chat-bot"
      />
     
        <BouncingDotsLoader/>
    </div>
  );
};
 
export default LoadingIndicator;