import React, { useEffect, useContext } from 'react';
import { inherits } from 'util';

import { useButtonActions } from '../NavHeaderComponent/ButtonComponentActionContext';
import ButtonComponent from '../NavHeaderComponent/ButtonComponent';
import { useNavFooter } from '../NavFooterComponent/NavFooterContext';
import NavLink from '../NavLinkComponent/NavLinkComponent';

import UserContext from "../../Auth/UserContext";

const NavFooter: React.FC<any> = ({ setIsDarkMode }) => {

  const { footerButtons, userProfile, isChecked, handleLogout, toggleTheme } = useNavFooter();
  const userData = useContext(UserContext);
  var { leftNav } = useButtonActions();
  setIsDarkMode(!isChecked);

  return (
    // Main container for the NavFooter component with a fixed width and margin
    <div className="col-md-12 mt-4 px-3 nav-footer-bg" style={{ width: "18vw",position:"fixed","bottom":"2vh" }}>
      
      {/* Inner container with padding to hold the navigation buttons */}
      <div className="py-2">
        
        {/* List of navigation buttons displayed as pills */}
        <ul className="nav nav-pills">

          {/* Mapping through footerButtons to render each button */}
          {footerButtons.map((button) => (

            <>
              {/* Individual list item for each footer button */}
              <li key={button.id} className="mb-1 w-100">
                
                {/* Container for the button with dynamic styling based on leftNav state */}
                <div className="form-check form-switch d-flex justify-content-between align-items-center px-3 py-2 nav-link link-dark text-color-9 font-14 font-medium custom-left-padding position-relative pdgiving5" 
                     style={{ width: leftNav !== "Open" ? "25%" : "", marginLeft: "-7%", paddingRight: "4%" }}>

                  {/* Button component rendered for each footer button */}
                  <ButtonComponent id={button.id} />

                  {/* Dark mode icon and label */}
                  <div onClick={toggleTheme}>
                    <img src="images/darkTheme/dark-mode-icon.svg" alt="dark-mode-icon" className="icon-filter"    style={{  "paddingRight": leftNav !== "Open" ?"2%":"", "cursor":"pointer"}}/>
                    <label className="form-check-label ms-2 custom-none" htmlFor="check-5">Dark Mode</label>
                  </div>

                  {/* Checkbox for toggling dark mode */}
                  <div className="checkbox-wrapper-5 d-flex align-items-center custom-none">
                    <div className="check">
                      <input id="check-5"
                        className="form-check-input shadow-none"
                        type="checkbox"
                        checked={isChecked}
                        onClick={toggleTheme}>
                      </input>
                      <label htmlFor="check-5"></label>
                    </div>
                  </div>

                </div>
              </li>
            </>
          ))}

        </ul>

        {/* Container for the NavLink component with dynamic width based on leftNav state */}
        <div style={{ width: leftNav !== "Open" ? "25%" : "", marginLeft: "-6%" }}>
          <NavLink id="NavFooter" />
        </div>

      </div>

      {/* Bottom section of the NavFooter with user profile, support link, and logout button */}
      <div className="pb-2b d-flex align-items-center justify-content-between">

        {/* Left-aligned section for user profile information */}
        <div className="d-flex" style={{"marginLeft": "-5%"}}>
          
          {/* Dropdown for the user's profile image */}
          <div className="dropdown dropup">
            <img className="profile-img-style" src={userData?.profileImage} alt="profile-img"  />
          </div>

          {/* User details (name and job title) */}
          <span className="d-block ms-3 custom-none">
            <span className="font-14 font-semibold d-block text-color-4">{userData?.userName}</span>
            <span className="font-12 font-14 text-color-10 d-block">{userData?.jobTitle}</span>
          </span>
        </div>

        {/* Center-aligned section for the Asami support link */}
        <div className="custom-none" style={{ marginRight: "10%" }}>
          <a
            aria-current="page"
            href={"mailto:AsamiSupportCrew@avasoft.com?subject=Asami-Support-{REASON}-{USERNAME}"}
          >
            <img
              className="left-nav-icons  asami-support"
              src={'images-new/asami-support-icon.svg'}
              alt={`Asami Support`}
            />
          </a>
        </div>

        {/* Right-aligned section for the logout button */}
        <div className="dropdown dropup">
          <div className="custom-none">
            <a style={{ cursor: "pointer" }}
               onClick={() => {sessionStorage.setItem('isStreaming','false'); sessionStorage.setItem('toasterDisplay','false');handleLogout() }}>
              <img className="icon-filter" style={{ cursor: "pointer" }} src="images/Default/logout-icon.svg" alt="logout-icon" onClick={() => { handleLogout() }} />
            </a>
          </div>
        </div>

      </div>
    </div>
  );
};

export default NavFooter;