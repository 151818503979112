//PS_PC_01 : Necessary imports are made here
import React, { useEffect, useState, useContext, useCallback, ChangeEvent } from "react";
import { ClearActionMeta } from 'react-select';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { useMsal } from "@azure/msal-react";
import { useHeaderContext } from "../../Utility/HeaderContext";

import {
    getChatHistory,
    fetchChat,
    getPromptFieldJson,
    chatCreation,
    getValyriaChat
} from "../../Service/API";
import UserContext from "../../Auth/UserContext";

import Popup from "../../Reusable Components/SpecifiedPopup";
import FilterComponent from "../../Reusable Components/Filter";
import Loader from "../../Reusable Components/Loader";
import PopupBuilder from "../../Reusable Components/DynamicInput";
import { FormField } from "../../Reusable Components/DynamicForm";
import ShareConversationPopup from "../../Reusable Components/ShareConvoPopup";
import NumberOfRecordsComponent from "../../Reusable Components/NoofRecords";
import ButtonComponent from "../../Reusable Components/Button";
import InputComponent from "../../Reusable Components/TextInput";
import ModuleHeading from "../../Reusable Components/ModuleHeading";
import ReusableTable from "../../Reusable Components/ReusableTable";

import { ModelConfig } from "../../Interfaces/LLMConfigGrid";
import { Header } from "../../Interfaces/ReusableTable";
import { ChatHistoryProps, ChatHistoryObj, UserDetails, ChatMessage, ShareState, ChatHistoryItem } from "../../Interfaces/ChatHistory";

import { useTableContext } from "../../Utility/ReusableTableContext";
import { Option } from "../../Utility/FilterContext";
import { usePopup } from "../../Utility/PopupContext";
import { useDateContext } from "../../Utility/DateContext";
import { useFilter } from "../../Utility/FilterContext";
import { getSessionItem } from "../../Utility/encrypt-storage";


const ChatHistory: React.FC<ChatHistoryProps> = ({ navOpen }) => {

    interface Chat {
        promptName: string;
        chatID: string;
        chatType: string;
        valyria_project_id: string
    }

    // PS_PC_06: Necessary state variables are initialized here
    const [search, setSearch] = useState<string>("");
    const [sort, setSort] = useState<string>("createdDateTime Desc");
    const[projectId, setProjectId] = useState('')
    const [count, setCount] = useState<number>(10);
    const [approvalStatus, setApprovalStatus] = useState<string>("");
    const [codegenStatus, setCodegenStatus] = useState<string>("");
    const [shownRecords, setShownRecords] = useState<number>(count);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [approvalStatusData, setApprovalStatusData] = useState<any[]>([]);
    const [codegenStatusData, setCodegenStatusData] = useState<any[]>([]);
    const [bindChatHistory, setBindChatHistory] = useState<ChatHistoryItem[] | undefined>(undefined);
    const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isCopied, setIsCopied] = useState<boolean>(false);
    const [isPromptFieldPopupOpen, setIsPromptFieldPopupOpen] = useState<boolean>(true);
    const [editingRowIndex, setEditingRowIndex] = useState<number | null | undefined>(null);
    const [shareDate, setShareDate] = useState<string>("");
    const [sharePromptName, setSharePromptName] = useState<string>("");
    const [shareUrl, setShareUrl] = useState<string>("");
    const [shareChatID, setShareChatID] = useState<string>("");
    const [profileImage, setProfileImage] = useState<string>("");
    const [isSharePopupOpen, setIsSharePopupOpen] = useState<boolean>(true);
    const [showModal, setShowModal] = useState(false);
    const [initialFormData, setInitialFormData] = useState<Record<string, any>>({});
    const [chatContent, setChatContent] = useState<ChatMessage[]>([]);
    const [chatType, setChatType] = useState('')
    const [filterDates,setFilterDates]=useState({from:"",to:""})
    // PS_PC_06: Necessary other variables are initialized here
    const share = window.location.href;
    const token = getSessionItem("access-token");
    const url = new URL(share);
    const baseUrl = url.origin;

    // PS_PC_06: Necessary use context variables are initialized here
    const { dropdowns, dateRange, setDropdownValue } = useFilter();
    const userDetails = useContext(UserContext);
    const { openPopup } = usePopup();
    const { fromDate, toDate } = useDateContext();
    const {setData, sortConfig } = useTableContext<ModelConfig>();
    const {setIsValyria} = useHeaderContext()

    // PS_PC_06: Field for the data passed to getChatHistory
    const [fields, setFields] = useState<FormField[]>([
        {
            name: 'chatName',
            error: '',
            label: 'Chat Name',
            type: 'text',
            required: true,
            placeholder: 'Enter Chat Name',
            displayNameError: '',
            disabled: false,
            value: initialFormData.chatName || '',
            dropdownfieldfunc: () => { },
            change: () => { }, 
            dropdown: false,
            dropdownfunc: () => { },
            dropdownval: null
        }
    ]);

    //Use Effects of on-load are made here

    useEffect(() => {
        setFields([
            {
                name: 'chatName',
                error: '',
                label: 'Chat Name',
                type: 'text',
                required: true,
                placeholder: 'Enter Chat Name',
                displayNameError: '',
                disabled: false,
                value: initialFormData.chatName || '',
                dropdownfieldfunc: () => { },
                change: () => { }, 
                dropdown: false,
                dropdownfunc: () => { },
                dropdownval: null
            }
        ]);
    }, [initialFormData]);
    useEffect(() => {
        // Function to check for the presence of 'modal-backdrop' and update the body's overflow style
        const updateOverflowStyle = () => {
          const modalBackdrop = document.querySelector('.modal');
          if (modalBackdrop) {
            document.body.style.overflow = 'hidden';
          } else {
            document.body.style.overflow = '';
          }
        };
    
        // Create a MutationObserver to watch for changes in the body
        const observer = new MutationObserver(() => {
          updateOverflowStyle();
        });
    
        // Start observing the body for child list changes
        observer.observe(document.body, { childList: true, subtree: true });
    
        // Initial check
        updateOverflowStyle();
    
        // Clean up the observer on component unmount
        return () => {
          observer.disconnect();
          document.body.style.overflow = ''; // Restore scrolling on unmount
        };
      }, []);

    useEffect(() => {
        fetchChatHistory();
        getChatMessages(null,'');
    }, [sort, sortConfig]);

    useEffect(() => {
        const clearAction: ClearActionMeta<Option> = {
            action: 'clear',
            removedValues: [],
        };
        setDropdownValue('codegenStatus', null, clearAction);
    }, []);

    useEffect(() => {
        fields[0].value = initialFormData.chatName || '';
    }, [initialFormData]);

    //All the columns for reusable components are rendered here
    const ChatNameColumn = ({ row }: { row: ChatHistoryItem }) => {
        const handleEdit = () => {
            const index = bindChatHistory?.findIndex(item => item?.chatID === row?.chatID);
            if (index !== undefined && index !== -1) {
                handleEditClick(index, row?.chatName);
            }
    };
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{row?.chatName?.length > 30 ? `${row?.chatName?.substring(0, 30)}...` : row?.chatName}</span>
                {row?.chatType != 'CodeGen' && (<img
                    src="images-new/edit-icon.svg"
                    alt="bot-happy"
                    className="icon-filter ms-3 mb-1 cursor-pointer"
                    style={{ height: '15px' }}
                    onClick={handleEdit}
                    data-bs-toggle="modal"
                    data-bs-target="#edit-chat-name"
                />)}      
            </div>
        );
    };

    const PromptColumn = ({ row }: { row: ChatHistoryItem }) => (
        row?.chatType!=="CodeGen" ? ( <a
            className="text-decoration-none link-color"
            // data-bs-toggle="modal"
            // data-bs-target="#specifiedprompts-popup"
            style={{ cursor: 'pointer'}}
            onClick={() => {
                if(row?.chatType!='CodeGen'){
                    setIsPromptFieldPopupOpen(false);
                    fetchPromptFieldJson(row?.chatID);
 
                }
 
            }}
        >
            {row?.chatType === 'CodeGen' ? 'Valyria' : (row?.chatType === 'General' ? 'Ask Asami' : row?.promptName)}
        </a>): (<span>{row?.chatType === 'CodeGen' ? 'Valyria' : (row?.chatType === 'General' ? 'Ask Asami' : row?.promptName)}</span>)
       
    );
 
 

    const DateTimeColumn = ({ row }: { row: ChatHistoryItem }) => (
        <span>{formatDate(row?.createdDateTime)}</span>
    );

    const AsamiStatusColumn = ({ row }: { row: ChatHistoryItem }) => (
        <span
            className={`table-status ${row?.codegenStatusName === "Abort"
                    ? "danger-bg"
                    : row?.codegenStatusName === "Success"
                        ? "success-bg"
                        : "pending-bg"
                } my-2`}
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title={
                row?.codegenStatusName === "Success"
                    ? "Success"
                    : row?.codegenStatusName === "Abort"
                        ? "Abort"
                        : "In Progress"
            }
        />
    );

    const ActionButtons = ({ row }: { row: ChatHistoryItem }) => (
        <>
            <img
                src="images-new/eye-img.svg"
                title="View"
                style={{ marginRight: "29%", cursor: "pointer" }}
                onClick={() => handleViewClick(row)}
            />
            <img
                src="images/Default/share-icon-nav.svg"
                onClick={() => handleShareClick(row)}
                title="Share"
                style={{ cursor: "pointer" }}
            />
        </>
    );

    const headers: Header<ChatHistoryItem>[] = [
        { key: 'chatName', label: 'Chat Name', sortable: true, component: ChatNameColumn },
        { key: 'promptName', label: 'Prompt',  component: PromptColumn },
        { key: 'chatType', label: 'Type', sortable: true },
        { key: 'createdDateTime', label: 'Date & Time', sortable: true, component: DateTimeColumn },
        { key: 'actions', label: 'Actions', component: ActionButtons },
    ];
    const handleViewClick = (row: ChatHistoryItem) => {
        const pathname = `/chat`;
        let search = `?chatID=${row?.chatID}&type=${row?.chatType}`;
        if(row?.chatType==='CodeGen'){
            search = `?chatID=${row?.chatID}&type=${row?.chatType}&projectID=${row?.valyria_project_id}`;
        }
       
        const state = row?.chatType === 'General'
            ? {
                yesFixButton: true,
                getAskAsamiChat: true,
                hidePrompts: true,
                sendMessage: false,
            }
            : {
                getAskAsamiChat: true,
                yesFixButton: false
            };
       
        sessionStorage.setItem('chatState', JSON.stringify(state));
        const fullURL = `${window.location.origin}${pathname}${search}`;
 
        // Open the URL in a new tab
        window.open(fullURL);
        // , '_blank'
    };
 

    //PS_PC_14 - PS_PC-18 - getChatHistory call is made here
    const fetchChatHistory = useCallback(async (obj?: ChatHistoryObj) => {
        try {
            let column = 'createdDateTime'
            let order = 'Desc'
            if (sortConfig.column != '') {
                column = sortConfig.column
            }
            if (sortConfig.order == 'desc') {
                order = 'Desc'
            }
            else if (sortConfig.order == 'asc') {
                order = 'Asc'
            }
            let sort = column + " " + order
            let chatHistoryObj: ChatHistoryObj = obj || {
                userEmailID: userDetails?.email || "",
                search,
                sort,
                count,
                fromDate: dateRange.from ? formatDateToString(dateRange.from) : filterDates.from?filterDates.from:"",
                toDate: dateRange.to ? formatDateToString(dateRange.to) :filterDates.to?filterDates.to: "",
                approvalStatusName: approvalStatus,
                codegenStatusName: dropdowns.codegenStatus?.selectedOptions ?
                    (dropdowns.codegenStatus.selectedOptions as Option).value : "",
            };

            chatHistoryObj = obj || chatHistoryObj;

            setIsLoading(true);
            
            let response = await getChatHistory(obj ? obj : chatHistoryObj);

            if (response.status === 200) {
                let record = response.data.data.chats.length - shownRecords;
                setShownRecords(record + shownRecords);
                setBindChatHistory(response.data.data.chats);
                setData(response.data.data.chats)
                setIsLoading(false)
                setApprovalStatusData(response.data.data.approvalStatuses);
                setCodegenStatusData(response.data.data.codegenStatuses);
                setTotalRecords(response.data.data.chatCount);
            }
        } catch (error) {
            console.error(error);
        }
    }, [userDetails, search, sort, count, approvalStatus, codegenStatus, shownRecords, dropdowns, fromDate, toDate, sortConfig]);

    function formatDate(date: string): string {
        const d = new Date(date);
        let month = "" + (d.getMonth() + 1);
        let day = "" + d.getDate();
        const year = d.getFullYear();
        let hours = d.getHours();
        let minutes = d.getMinutes();
        const ampm = hours >= 12 ? "PM" : "AM";

        hours = hours % 12 || 12;
        month = month.padStart(2, '0');
        day = day.padStart(2, '0');
        const formattedHour = hours.toString().padStart(2, '0');
        const formattedMinute = minutes.toString().padStart(2, '0');

        return `${month}/${day}/${year} ${formattedHour}:${formattedMinute} ${ampm}`;
    }

    //PS_PC_46 - PS_PC_48 - Function called during load more
    const loadmoreClick = () => {
        const newCount = count + 10;
        setCount(newCount);
        let chatHistoryObj: ChatHistoryObj = {
            userEmailID: userDetails?.email || "",
            search,
            sort,
            count: newCount,
            fromDate: dateRange.from ? formatDateToString(dateRange.from) : "",
            toDate: dateRange.to ? formatDateToString(dateRange.to) : "",
            approvalStatusName: approvalStatus,
            codegenStatusName: dropdowns.codegenStatus?.selectedOptions ?
                (dropdowns.codegenStatus.selectedOptions as Option).value : "",
        };
        fetchChatHistory(chatHistoryObj);
        if (newCount >= totalRecords) {
            setIsLoadMoreDisabled(true);
        }
    };

    const fetchPromptFieldJson = async (id: string) => {
        
        try {
            setIsPromptFieldPopupOpen(false);
            const response = await getPromptFieldJson({ chatID: id });
            const parsedJson = JSON.parse(response.data.responseData.ResponseData.content);
            if (parsedJson) {
                openPopup("Specified Prompt", parsedJson);

            }
        } catch (error) {
            console.error(error);
        }
    };

    const getChatMessages = async (chatID: any, chatType:string) => {
        try {
              let id = localStorage.getItem('valyriaId')
              if(chatType=='CodeGen'){
                let payload={
                          "asami_chat_id" : chatID
                        }
                // setLoading(true)
                let response = await getValyriaChat(payload);
                setIsValyria(true)
                setChatContent(response.data);
                // setLoading(false)
                sessionStorage.setItem('user_id',response.data[0].user_id)
                sessionStorage.setItem('project_id',response.data[0].project_id)
              }
              else{
                // setLoading(true)
                let result = await fetchChat({ chatID: chatID });
                setChatContent(result.data.ResponseData.currentChat);
              }
            } catch (error) {
              console.error(error);
            }
    }

    const handleSaveClick = (data: any) => {
        if (bindChatHistory && editingRowIndex !== null) {
            const updatedChatHistory: any = bindChatHistory.map((chat, i) => {
                if (i === editingRowIndex) {
                    const updatedChat = { ...chat, chatName: data.chatName };
                    handlesubmit(updatedChat.chatID, data.chatName);
                    setShareChatID(updatedChat.chatID);
                    return updatedChat;
                } else {
                    return chat;
                }
            });

            setBindChatHistory(updatedChatHistory);
            setData(updatedChatHistory);
            setIsLoading(false)
        }
        setShowModal(false);
        setEditingRowIndex(null);
        setInitialFormData({});
    };

    const handlesubmit = async (chatID: string, chatName: string) => {
        try {
            setIsLoading(true)
            await chatCreation({
                editID: chatID,
                editName: chatName,
            });
        }
        catch (e) {
            console.error(e);
        }
    }

    const handleEditClick = (index: number, name: string) => {
        setEditingRowIndex(index);
        setInitialFormData({ chatName: name }); 
        setShowModal(true); 
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setEditingRowIndex(null);
        setInitialFormData({});
    };

    const handleShareClick = async (chat: Chat): Promise<void> => {
        setIsSharePopupOpen(false)
        setSharePromptName(chat.promptName);
        setChatType(chat.chatType)
        setShareChatID(chat.chatID);
        setProjectId(chat.valyria_project_id)
        await getChatMessages(chat.chatID, chat.chatType);
        await fetchProfileImage(); // Add this line
        ;
        // setChatType(chat.chatType);
    };
    
    const fetchProfileImage = async () => {
        try {
            if (!userDetails || !userDetails.email) {
                throw new Error("User details or email is missing");
            }
    
            const response = await axios.get(
                `https://graph.microsoft.com/v1.0/users/${userDetails.email}/photo/$value`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    responseType: "blob",
                }
            );
    
            const imageUrl = URL.createObjectURL(response.data);
            setProfileImage(imageUrl);
        } catch (error) {
            console.error("Error fetching profile image:", error);
            setProfileImage("images/Default/default-profile-image.png");
        }
    };

    //PS_PC_28 - PS_PC_34 - Filter functionality is defined here

    const formatDateToString = (date: Date | null): string => {
        if (!date) return '';
        return date.toISOString().split('T')[0]; // Returns YYYY-MM-DD
    };

    const handleApplyFilters = (filters: { [key: string]: string | undefined }) => {
        let column = sortConfig.column || 'createdDateTime';
        let order = sortConfig.order === 'asc' ? 'Asc' : 'Desc';
        let sort = `${column} ${order}`;
        const chatHistoryObj: ChatHistoryObj = {
            userEmailID: userDetails?.email || "",
            search,
            sort,
            count,
            fromDate: filters.fromDate ||filterDates.from|| "",
            toDate: filters.toDate ||filterDates.to|| "",
            codegenStatusName: filters.codegenStatus || "",
            approvalStatusName: filters.approvalStatus || "",
        };
        setFilterDates({from:filters.fromDate||filterDates.from,to:filters.toDate||filterDates.to})
        fetchChatHistory(chatHistoryObj);
    };

    const handleCancelFilters = () => {
        let column = 'createdDateTime'
        let order = 'Desc'
        if (sortConfig.column != '') {
            column = sortConfig.column
        }
        if (sortConfig.order == 'desc') {
            order = 'Desc'
        }
        else if (sortConfig.order == 'asc') {
            order = 'Asc'
        }
        let sort = column + " " + order
        const emptyFilterObj: ChatHistoryObj = {
            userEmailID: userDetails?.email || "",
            search,
            sort,
            count,
            fromDate: "",
            toDate: "",
            codegenStatusName: "",
            approvalStatusName: "",
        };

        fetchChatHistory(emptyFilterObj);
    };

    //PS_PC_23 - Search function is defined here

    const handleSearch = (value: string) => {
        setSearch(value);
    };

    const handleSearchSubmit = () => {
        fetchChatHistory();
    };

    return (
        <>
        {isLoading ? <Loader isLoading={isLoading} navOpening={navOpen} /> :
            <div className="row justify-content-lg-end justify-content-center" style={{ marginRight: "4%" }}>
                
                    <div className={navOpen === "Close" ? "col-md-11 ps-5" : "col-md-9"} id="chat-history-center" >
                        <div className="container-fluid px-0" >
                            <div className="row">
                                <div className="col-md-12" >
                                    <div className="mt-5 mb-3 d-flex justify-content-between align-items-center" >
                                        <ModuleHeading
                                            text="Past Conversation"
                                            tag="h3"
                                            className="font-20 font-semibold text-color-1"
                                        />
                                        <div className="table-header d-flex align-items-center">
                                            <div className="input-group transparent-grp">
                                                <InputComponent
                                                    type="text"
                                                    className="form-control border-0 focus-none font-14 font-medium search-bg text-color-4 input-group custom-search-input cust-search-input"
                                                    placeholder="Search"
                                                    value={search}
                                                    onChange={handleSearch}
                                                    onEnterPress={handleSearchSubmit}
                                                />
                                                <button
                                                    className="btn focus-none search-bg"
                                                    type="button"
                                                    onClick={(e) => {
                                                        fetchChatHistory()
                                                    }}
                                                >
                                                    <img
                                                        className="filter-icons"
                                                        src="images/Default/input-search.svg"
                                                        alt="input-search-icon"
                                                    />
                                                </button>
                                            </div>
                                            <div className="dropdown ms-3 custom-dashboard-section tbl-filter">
                                                <FilterComponent
                                                    config={{
                                                        showDateRange: true
                                                    }}
                                                    filterDates={filterDates}
                                                    setFilterDates={setFilterDates}
                                                    onApply={handleApplyFilters}
                                                    onCancel={handleCancelFilters}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 " >
                                    <div className="table-responsive rounded-3 transparent-grp">
                                        <ReusableTable<ChatHistoryItem>
                                            headers={headers}
                                            onRowClick={(row) => {
                                                setIsPromptFieldPopupOpen(false);
                                                fetchPromptFieldJson(row?.chatID);

                                            }}
                                        />
                                    </div>
                                    <NumberOfRecordsComponent
                                        shownRecords={shownRecords}
                                        totalRecords={totalRecords}
                                        className="mb-0"
                                    />
                                    <div className="text-center mb-5">
                                        <ButtonComponent
                                            buttonType="text"
                                            text={<span className="color-gradient">Load More</span>}
                                            className="gradient-btn border-radius-4 font-14 font-semibold py-2 px-3"
                                            style={{ color: "#865BE7" }}
                                            onClick={loadmoreClick}
                                            disabled={totalRecords <= 10 || isLoadMoreDisabled}
                                            hidden={totalRecords <= 10 || isLoadMoreDisabled}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>}

            <div hidden={isPromptFieldPopupOpen}>
                <Popup />
            </div>

            <div className="row justify-content-center">
                <ShareConversationPopup
                    isOpen={!isSharePopupOpen}
                    onClose={() => setIsSharePopupOpen(true)}
                    sharePromptName={sharePromptName}
                    shareDate={""}
                    shareChatID={shareChatID}
                    chatContent={chatContent}
                    profileImage={profileImage}
                    baseUrl={baseUrl}
                    chatType={chatType}
                    projectID={projectId}
                />
            </div>

            <PopupBuilder
                showModal={showModal}
                onClose={handleCloseModal}
                onSave={handleSaveClick}
                title="Edit Chat Name"
                fields={fields}
            />

            {isCopied && (
                <div className="notification-bar">
                    <span className="check-icon">✓</span>
                    Copied conversation URL to Clipboard!
                </div>
            )}

        </>
    );
}

export default ChatHistory;