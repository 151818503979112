import React from 'react';

interface ShimmerLoaderProps {
  isLoading: boolean;
  navOpening?: any;
}

const ChatShimmerLoader: React.FC<ShimmerLoaderProps> = ({ isLoading, navOpening }) => {
  return (
    <div>
      <div className="chat-shimmer-loader">
        {/* Conversation flow with varying heights and creative animations style={{width: navOpening=="Close"? "15%" : "21vw"}}*/}
        <div className="message-shimmer left fade-in">
          <div className="shimmer-avatar pulse" />
          <div className="shimmer-content wave">
            <div className="shimmer-line long" />
            <div className="shimmer-line medium" />
            <div className="shimmer-line short" />
          </div>
        </div>

        <div className="message-shimmer right slide-in">
          <div className="shimmer-content wave">
            <div className="shimmer-line medium" />
            <div className="shimmer-line short" />
          </div>
          <div className="shimmer-avatar pulse" />
        </div>

        <div className="message-shimmer left fade-in">
          <div className="shimmer-avatar pulse" />
          <div className="shimmer-content wave">
            <div className="shimmer-line short" />
          </div>
        </div>

        <div className="message-shimmer right slide-in" style={{ height: '110px' }}>
          <div className="shimmer-content wave">
            <div className="shimmer-line long" />
            <div className="shimmer-line medium" />
            <div className="shimmer-line long" />
          </div>
          <div className="shimmer-avatar pulse" />
        </div>

        <div className="message-shimmer left fade-in" style={{ height: '80px' }}>
          <div className="shimmer-avatar pulse" />
          <div className="shimmer-content wave">
            <div className="shimmer-line medium" />
          </div>
        </div>

        <div className="message-shimmer right slide-in" style={{ height: '150px' }}>
          <div className="shimmer-content wave">
            <div className="shimmer-line long" />
            <div className="shimmer-line short" />
            <div className="shimmer-line long" />
            <div className="shimmer-line medium" />
          </div>
          <div className="shimmer-avatar pulse" />
        </div>
      </div>
    </div>
  );
};

// CSS with creative animations
const styles = `
.chat-shimmer-loader {
  padding: 20px;
  max-width: 79%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.message-shimmer {
  display: flex;
  align-items: flex-end;
  max-width: 80%;
  opacity: 0;
  animation-fill-mode: forwards;
}

/* Avatar positioning */
.shimmer-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #e0e0e0;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.message-shimmer.left .shimmer-avatar {
  margin-right: 12px;
}

.message-shimmer.right .shimmer-avatar {
  margin-left: 12px;
}

.message-shimmer.right {
  align-self: end;
}

/* Message bubbles */
.shimmer-content {
  border-radius: 18px;
  padding: 16px;
  position: relative;
  overflow: hidden;
}

/* Left message (other user) */
.message-shimmer.left .shimmer-content {
  background: #f0f0f0;
  min-height: 80px;
  width: 45vw;
}

/* Right message (current user) */
.message-shimmer.right .shimmer-content {
  background: #e0e0e0;
  min-height: 80px;
  width: 33vw;
}

/* Message text lines */
.shimmer-line {
  height: 12px;
  margin-bottom: 10px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.5);
  position: relative;
  overflow: hidden;
}

.shimmer-line.short {
  width: 40%;
}
.shimmer-line.medium {
  width: 65%;
}
.shimmer-line.long {
  width: 85%;
}

/* Creative Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  50% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-20px);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes wave {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.fade-in {
  animation: fadeIn 2s ease-in-out infinite;
  animation-delay: 0.1s;
}

.slide-in {
  animation: slideIn 2s cubic-bezier(0.18, 0.89, 0.32, 1.28) infinite;
  animation-delay: 0.2s;
}

.pulse {
  animation: pulse 2s ease-in-out infinite;
}

.wave {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: wave 2s linear infinite;
}

.message-shimmer.right .wave {
  background: linear-gradient(90deg, #e0e0e0 25%, #d0d0d0 50%, #e0e0e0 75%);
}

/* Dark mode */
.dark-theme .shimmer-content {
  background: #3a3a3a !important;
}
.dark-theme .shimmer-avatar {
  background: #4a4a4a;
}
.dark-theme .shimmer-line {
  background: rgba(0, 0, 0, 0.2);
}
.dark-theme .wave {
  background: linear-gradient(90deg, #3a3a3a 25%, #4a4a4a 50%, #3a3a3a 75%);
}
.dark-theme .message-shimmer.right .wave {
  background: linear-gradient(90deg, #2a2a2a 25%, #3a3a3a 50%, #2a2a2a 75%);
}

/* Staggered animations */
.message-shimmer:nth-child(1) {
  animation-delay: 0.1s;
}
.message-shimmer:nth-child(2) {
  animation-delay: 0.3s;
}
.message-shimmer:nth-child(3) {
  animation-delay: 0.5s;
}
.message-shimmer:nth-child(4) {
  animation-delay: 0.7s;
}
.message-shimmer:nth-child(5) {
  animation-delay: 0.9s;
}
.message-shimmer:nth-child(6) {
  animation-delay: 1.1s;
}
`;

// Inject styles
const styleElement = document.createElement('style');
styleElement.innerHTML = styles;
document.head.appendChild(styleElement);

export default ChatShimmerLoader;