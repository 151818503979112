import React, { useState, useEffect } from 'react';

import NumberOfRecordsProps from '../Interfaces/NoofRecords'
const NumberOfRecordsComponent: React.FC<NumberOfRecordsProps> = ({
  shownRecords: initialShown = 0,
  totalRecords: initialTotal = 0,
  className = '',
  onUpdate,
  ...props
}) => {
  
  const [shownRecords, setShownRecords] = useState(initialShown);
  const [totalRecords, setTotalRecords] = useState(initialTotal);

  useEffect(() => {
    setShownRecords(initialShown);
    setTotalRecords(initialTotal);
  }, [initialShown, initialTotal]);

  useEffect(() => {
    if (onUpdate) {
      onUpdate(shownRecords, totalRecords);
    }
  }, [shownRecords, totalRecords, onUpdate]);

  const getDisplayText = () => {
    if (totalRecords === 0) {
      return 'No records available';
    } else if (shownRecords >= totalRecords) {
      return `All ${totalRecords} records shown`;
    } else {
      return `Showing ${shownRecords} out of ${totalRecords} records`;
    }
  };

  return (
    <p className={`font-regular font-14 text-color-1 mt-3 mb-4 ${className}`} {...props}>
      # of Records : <span className="font-bold font-14 text-color-9">{getDisplayText()}</span>
    </p>
  );
};

export default NumberOfRecordsComponent;