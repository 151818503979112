import React, { createContext, useContext, useState, ReactNode } from 'react';

// Interface defining the structure of the date context
interface DateContextType {
  fromDate: Date | null; setFromDate: (date: Date | null) => void;
  toDate: Date | null; setToDate: (date: Date | null) => void;
}

// Create the date context with an initial undefined value
const DateContext = createContext<DateContextType | undefined>(undefined);

// Custom hook to access the date context
export const useDateContext = () => {
  const context = useContext(DateContext);
  if (!context) {
    throw new Error('useDateContext must be used within a DateProvider');
  }
  return context;
};

// Date provider component to wrap around the application
export const DateProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // State for managing the "from" and "to" dates
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);

  // Provide the date context to children
  return (
    <DateContext.Provider value={{ fromDate, toDate, setFromDate, setToDate }}>
      {children}
    </DateContext.Provider>
  );
};