import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getSessionItem } from '../Utility/encrypt-storage';
import Tooltip from '../Reusable Components/ToolTip';

// PS_CARD_01SQ_CARD_0.0: Initialize state variables
interface PromptCard {
  promptName: string;
  draft: boolean;
  promptDescription: string;
  promptID: string;
  createdBy: string;
}

interface FeedBackCard {
  PromptName: string;
  UserFeedBack: string;
  Profile_url?: string;
  userName: string;
  Role: string;
  Emoji_Desc: string;
  userEmailID: string;
}

interface CardComponentProps {
  cardType: 'prompt' | 'feedback';
  promptCard?: PromptCard;
  feedBackCard?: FeedBackCard;
  showDelete?: boolean;
  userDetails?: any;
  onActivityClick?: (promptID: string) => void;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
}

const CardComponent: React.FC<CardComponentProps> = ({
  cardType,
  promptCard,
  feedBackCard,
  showDelete,
  userDetails,
  onActivityClick,
  onEditClick,
  onDeleteClick,
}) => {
  const token = getSessionItem("access-token");

  // PS_CARD_02SQ_CARD_1.0: Initialize state variables
  const [profileImageUrl, setProfileImageUrl] = useState('');


  // PS_CARD_03SQ_CARD_1.1: User interacts with UI
  // This logic would be handled in your parent component where the CardComponent is used

  useEffect(() => {
    // PS_CARD_04SQ_CARD_1.2: Render CardComponent with props
    if (feedBackCard && feedBackCard.userEmailID) {
      getProfileImageFromGraph(feedBackCard.userEmailID);
    }
  }, [feedBackCard]);


  // PS_CARD_13SQ_CARD_1.10: Update UI based on user actions (moved outside functions for better readability)
  // This can be implemented in your parent component to update the state based on user interactions with the CardComponent

  const getProfileImageFromGraph = async (email: string) => {
    try {
      const response = await axios.get(
        `https://graph.microsoft.com/v1.0/users/${encodeURIComponent(email)}/photo/$value`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'blob',
        }
      );

      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) { // Check if reader.result has a value before setting state
          setProfileImageUrl(reader.result as string); // Cast reader.result to string explicitly
        } else {
          console.error('Error: Failed to read profile image data');
          setProfileImageUrl('images/Default/default-profile-image.png'); // Set default image on error
        }
      };
      reader.readAsDataURL(response.data);
    } catch (error) {
      console.error('Error fetching profile image from Microsoft Graph:', error);
      setProfileImageUrl('images/Default/default-profile-image.png');
    }
  };



  const getEmoji = (val: string) => {
    if (val === "Sad") {
      return "images-new/sad-emoji.svg";
    } else if (val === "Neutral") {
      return "images-new/neutral-emoji.svg";
    } else {
      return "images-new/happy-emoji.svg";
    }
  };

  // PS_CARD_05SQ_CARD_1.3: If cardType is 'prompt', display prompt card
  if (cardType === 'prompt' && promptCard) {
    return (
      <div className="card h-100 shadow-sm custom-card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5 className="font-18 font-semibold text-color-1 mb-0">{promptCard.promptName}</h5>
            {promptCard.draft ? (
              <span className="batch-sty batch-green font-12 font-semibold d-flex align-items-center">
                <span className="pointer-green me-2" /> Live
              </span>
            ) : (
              <span className="batch-sty batch-orange font-12 font-semibold d-flex align-items-center">
                <span className="pointer-orange me-2" /> Drafted
              </span>
            )}
          </div>
          <p className="font-12 text-color-12 font-medium">{promptCard.promptDescription}</p>
        </div>
        <div className="custom-border-top py-3 mx-3 justify-content-between d-flex">
          <button
            type="button"
            className="btn font-14 font-medium primary-color custom-primary-hov shadow-none p-0 d-flex align-items-center"
            data-bs-toggle="modal"
            data-bs-target="#Helpus-popup"
            onClick={() => onActivityClick && onActivityClick(promptCard.promptID)}
          >
            <img src="images-new/activity-icon.svg" alt="activity-icon" className="me-2 pb-1" />
            Activity
          </button>
          <div className="d-flex">
            <button
              type="button"
              className="btn me-3 font-14 font-medium custom-blue-hov color-blue shadow-none p-0 d-flex align-items-center"
              onClick={onEditClick}
            >
              <img src="images-new/edit-icons.svg" alt="edit-icon" className="me-2" />
            </button>
            {userDetails?.email == promptCard?.createdBy ? (
              <button
                type="button"
                className="btn me-3 font-14 font-medium custom-red-hov color-red shadow-none p-0 d-flex align-items-center"
                onClick={onDeleteClick}
              >
                <img src="images-new/delete-icons.svg" alt="delete-icon" className="card-icon me-2" />
              </button>
            ) : (
              <>
              </>
            )}
          </div>
        </div>
      </div>
    );
  } else if (cardType === 'feedback' && feedBackCard) {
    // PS_CARD_06SQ_CARD_1.4: If cardType is 'feedback', display feedback card
    return (
      <div className="card h-100 shadow-sm custom-card feedback-cards border-0 rounded-3 p-4">
        <img className="custom-quotes-width mb-3" src="images-new/quote.svg" alt="quote" />
        {feedBackCard.PromptName.length > 25 ? <> <Tooltip text={feedBackCard.PromptName} moveleft={125}>
          <p className="font-medium font-18 text-color-1">

            {feedBackCard.PromptName.slice(0, 25) + "..."}

          </p>
        </Tooltip></> : <>
        <p className="font-medium font-18 text-color-1">
          {feedBackCard.PromptName}
          </p>
        </>}


        <p className="font-regular text-color-9 font-14 feedback-para-scroll">{feedBackCard.UserFeedBack}</p>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-lg-flex d-block align-items-center">
            <div className="d-block me-2">
              <img
                className="profile-img-style"
                src={profileImageUrl}
                alt="user"
              />
            </div>
            <div className="d-block">
              <span className="font-regular d-block w-100 font-14 text-color-9">{feedBackCard.userName}</span>
              <span className="font-regular d-block font-12 w-100 text-color-9">{feedBackCard.Role}</span>
            </div>
          </div>
          <div className="text-sm-center mt-sm-0 mt-3">
            <img src={getEmoji(feedBackCard.Emoji_Desc)} alt="emoji" style={{ width: '50px', height: '30px' }} />
            <span className="font-12 font-semibold text-color-15">{feedBackCard.Emoji_Desc}</span>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default CardComponent;