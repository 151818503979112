// PS_FDBKCARDS_01-04: Import required dependencies
import React, { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import { ActionMeta } from "react-select";
import { useFilter, Option } from "../../Utility/FilterContext";
import FilterComponent from "../../Reusable Components/Filter";
import CardComponent from "../../Reusable Components/Card";
import Loader from "../../Reusable Components/Loader";
import { fetchPromptEmj, insertGetFeedBack } from "../../Service/API";
import { FeedBackCardProps, ReactionData, Feedback } from "../../Interfaces/FeedbackCards";
import { getSessionItem } from "../../Utility/encrypt-storage";
import NoRecordsFound from "../../Reusable Components/NoRecordsFound";

const FeedbackCards: React.FC<FeedBackCardProps> = ({ navOpen }) => {
  // PS_FDBKCARDS_08-21: Initialize all required state variables
  const { dropdowns, setDropdownValue } = useFilter();
  const [getFeedback, setGetFeedback] = useState<Feedback[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [practiceOptions, setPracticeOptions] = useState<Option[]>([]);
  const [reactionOptions, setReactionOptions] = useState<Option[]>([]);
   const userEmailID = getSessionItem("user-email");

  // PS_FDBKCARDS_33-53: Get feedback data with filters
  const getFeedbackData = async () => {
    try {
      const reqBody = {
        ReactionID: dropdowns.reaction?.selectedOptions
          ? (dropdowns.reaction.selectedOptions as Option).value
          : "",
        Practice: dropdowns.practice?.selectedOptions
          ? (dropdowns.practice.selectedOptions as Option[]).map((o) => o.value)
          : [],
        userEmailID
      };
      const responseData = await insertGetFeedBack(reqBody);
      if (responseData.status_code === 200) {
        setGetFeedback(responseData.responseData[0].ResponseData);
      } else {
        setError(responseData.ErrorMessage);
      }
    } catch (error) {
      setError("Failed to fetch feedback data.");
    } finally {
      setIsLoading(false);
    }
  };

  // PS_FDBKCARDS_55-66: Initialize dropdown data on mount
  const initializeDropdowns = async () => {

    
    try {
      const [reactionData, feedbackData, practiceData] = await Promise.all([
        fetchPromptEmj({}),
        insertGetFeedBack({ ReactionID: "", Practice: [], userEmailID }), 
        insertGetFeedBack({ SearchDomain: "" })
      ]);

      // Set reaction options
      const newReactionOptions: Option[] = (reactionData.emoji_result as ReactionData[])
        .map((r) => ({
          value: r.emoji_id,
          label: r.emoji_desc,
        }));
      setReactionOptions(newReactionOptions);

      // Set practice options and initial feedback data
      if (feedbackData.status_code === 200) {
        const practices = practiceData.responseData[0].ResponseData || [];
        const newPracticeOptions: Option[] = practices.map((p: string) => ({
          value: p,
          label: p,
        }));
        setPracticeOptions(newPracticeOptions);
        
        // Set initial feedback data
        setGetFeedback(feedbackData.responseData[0].ResponseData || []);
      }
    } catch (error) {
      setError("Failed to initialize data");
    } finally {
      setIsLoading(false);
    }
  };

  // PS_FDBKCARDS_22: Initialize data on mount
  useEffect(() => {
    initializeDropdowns();
  }, []);

  // PS_FDBKCARDS_70-72: Handle filter apply
  const handleApply = (filters: { [key: string]: string | undefined }) => {
    setIsLoading(true);
    if (filters.practice) {
      const selectedPractices = filters.practice
        .split(",")
        .map((value) => practiceOptions.find((option) => option.value === value))
        .filter((option): option is Option => option !== undefined);
      setDropdownValue("practice", selectedPractices, {
        action: "select-option",
      } as ActionMeta<Option>);
    }
    if (filters.reaction) {
      const selectedReaction = reactionOptions.find(
        (option) => option.value === filters.reaction
      );
      if (selectedReaction) {
        setDropdownValue("reaction", selectedReaction, {
          action: "select-option",
        } as ActionMeta<Option>);
      }
    }
    getFeedbackData();
  };
  const getFeedbackDataClear = async () => {
    try {
      const reqBody = {
        ReactionID:"",
        Practice:  [],
        userEmailID
      };
      const responseData = await insertGetFeedBack(reqBody);
      if (responseData.status_code === 200) {
        setGetFeedback(responseData.responseData[0].ResponseData);
      } else {
        setError(responseData.ErrorMessage);
      }
    } catch (error) {
      setError("Failed to fetch feedback data.");
    } finally {
      setIsLoading(false);
    }
  };

  // PS_FDBKCARDS_73-77: Handle filter cancel
  const handleCancel = () => {
    setIsLoading(true);
    setDropdownValue("practice", null, { action: "clear" } as ActionMeta<Option>);
    setDropdownValue("reaction", null, { action: "clear" } as ActionMeta<Option>);
    getFeedbackDataClear();
  };

  const filterConfig = {
    dropdowns: {
      practice: {
        options: practiceOptions,
        isMulti: true,
        label: "Practice",
      },
      reaction: {
        options: reactionOptions,
        isMulti: false,
        label: "Reaction",
      },
    },
    showDateRange: false,
  };

  return (
    <>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <div className="container-fluid">
          <div className="row justify-content-lg-end ms-lg-3 ms-xl-0 justify-content-center">
            <div className={navOpen === "Close" ? `col-11` : `col-lg-9 me-lg-4 col-11`}>
              <div className="row">
                <div className="col-md-12">
                  <div className="px-0 py-4 mb-3 border-0 rounded-3">
                    <div className="row">
                      <div className="col-md-12 mb-4">
                        <div className="d-flex justify-content-between align-items-center">
                          <h5 className="font-bold font-20 mb-0 text-nowrap text-color-1">
                            Feedbacks
                          </h5>
                          <FilterComponent
                            onApply={handleApply}
                            onCancel={handleCancel}
                            config={filterConfig}
                          />
                        </div>
                      </div>
                      {getFeedback.length > 0 ? (
                        getFeedback.map((feedBackCard, index) => (
                          <div className="col-md-4 mb-4" key={index}>
                            <CardComponent
                              cardType="feedback"
                              feedBackCard={feedBackCard}
                            />
                          </div>
                        ))
                      ) : (
                        <span className="text-center py-5 vh-50" style={{ marginTop: "15%" }}>
                      <NoRecordsFound message="No Records Found!" />
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FeedbackCards;

// // PS_FDBKCARDS_01-04: Import statements
// import React, { useState, useEffect } from "react";
// import { ClipLoader } from "react-spinners";
// import { ActionMeta } from "react-select";
// import { useFilter, Option } from "../../Utility/FilterContext";
// import FilterComponent from "../../Reusable Components/Filter";
// import CardComponent from "../../Reusable Components/Card";
// import Loader from "../../Reusable Components/Loader";
// import { fetchPromptEmj, insertGetFeedBack } from "../../Service/API";
// import { FeedBackCardProps, ReactionData, Feedback } from "../../Interfaces/FeedbackCards";

// const FeedbackCards: React.FC<FeedBackCardProps> = ({ navOpen }) => {
//   // PS_FDBKCARDS_08-21: Initialize state variables
//   const { dropdowns, setDropdownValue } = useFilter();
//   const [getFeedback, setGetFeedback] = useState<Feedback[]>([]);
//   const [isLoading, setIsLoading] = useState<boolean>(false);
//   const [error, setError] = useState<string | null>(null);
//   const [isLoad, setIsLoad] = useState<boolean>(false);
//   const [practiceOptions, setPracticeOptions] = useState<Option[]>([]);
//   const [reactionOptions, setReactionOptions] = useState<Option[]>([]);

//   // PS_FDBKCARDS_22: Component mounts effect
//   useEffect(() => {
//     getReactionAndPracticeData();
//   }, []);

//   // PS_FDBKCARDS_23-31: Effect for fetching feedback data
//   useEffect(() => {
//     if (practiceOptions.length > 0 || reactionOptions.length > 0) {
//       getFeedbackData();
//     }
//   }, [reactionOptions, practiceOptions]);

//   // PS_FDBKCARDS_55-66: Get reaction and practice data
//   const getReactionAndPracticeData = async () => {
//     try {
//       setIsLoading(true)
//       const reactionData = await fetchPromptEmj({});
//       const practiceData = await insertGetFeedBack({ SearchDomain: "" });

//       const newReactionOptions: Option[] = (
//         reactionData.emoji_result as ReactionData[]
//       ).map((r) => ({
//         value: r.emoji_id,
//         label: r.emoji_desc,
//       }));
//       const newPracticeOptions: Option[] = (
//         practiceData.responseData[0].ResponseData as string[]
//       ).map((p) => ({
//         value: p,
//         label: p,
//       }));

//       setReactionOptions(newReactionOptions);
//       setPracticeOptions(newPracticeOptions);
//     } catch (error) {
//       setError("Failed to fetch dropdown data.");
//     }
//   };

//   // PS_FDBKCARDS_33-53: Get feedback data
//   const getFeedbackData = async () => {
//     try {
//       const reqBody = {
//         ReactionID: dropdowns.reaction?.selectedOptions
//           ? (dropdowns.reaction.selectedOptions as Option).value
//           : "",
//         Practice: dropdowns.practice?.selectedOptions
//           ? (dropdowns.practice.selectedOptions as Option[]).map((o) => o.value)
//           : [],
//       };
//       const responseData = await insertGetFeedBack(reqBody);
//       setIsLoading(false);
//       if (responseData.status_code === 200) {
//         setGetFeedback(responseData.responseData[0].ResponseData);
//       } else {
//         setError(responseData.ErrorMessage);
//       }
//     } catch (error) {
//       setIsLoading(false);
//       setError("Failed to fetch feedback data.");
//     }
//   };

//   // PS_FDBKCARDS_70-72: Handle apply filters
//   const handleApply = (filters: { [key: string]: string | undefined }) => {
//     if (filters.practice) {
//       const selectedPractices = filters.practice
//         .split(",")
//         .map((value) =>
//           practiceOptions.find((option) => option.value === value)
//         )
//         .filter((option): option is Option => option !== undefined);
//       setDropdownValue("practice", selectedPractices, {
//         action: "select-option",
//       } as ActionMeta<Option>);
//     }
//     if (filters.reaction) {
//       const selectedReaction = reactionOptions.find(
//         (option) => option.value === filters.reaction
//       );
//       if (selectedReaction) {
//         setDropdownValue("reaction", selectedReaction, {
//           action: "select-option",
//         } as ActionMeta<Option>);
//       }
//     }
//     getFeedbackData();
//   };

//   // PS_FDBKCARDS_73-77: Handle cancel filters
//   const handleCancel = () => {
//     setDropdownValue("practice", null, {
//       action: "clear",
//     } as ActionMeta<Option>);
//     setDropdownValue("reaction", null, {
//       action: "clear",
//     } as ActionMeta<Option>);
//     getFeedbackData();
//   };

//   const filterConfig = {
//     dropdowns: {
//       practice: {
//         options: practiceOptions,
//         isMulti: true,
//         label: "Practice",
//       },
//       reaction: {
//         options: reactionOptions,
//         isMulti: false,
//         label: "Reaction",
//       },
//     },
//     showDateRange: false,
//   };

//   return (
//     <>
//       {isLoading ? (
//         <Loader isLoading={isLoading} />
//       ) : (
//         <div className="container-fluid">
//           <div className="row justify-content-lg-end ms-lg-3 ms-xl-0 justify-content-center">
//             <div
//               className={
//                 navOpen === "Close" ? `col-11` : `col-lg-9 me-lg-4 col-11`
//               }
//             >
//               <div className="row">
//                 <div className="col-md-12">
//                   <div className="px-0 py-4 mb-3 border-0 rounded-3">
//                     <div className="row">
//                       <div className="col-md-12 mb-4">
//                         <div className="d-flex justify-content-between align-items-center">
//                           <h5 className="font-bold font-20 mb-0 text-nowrap text-color-1">
//                             Feedbacks
//                           </h5>
//                           <FilterComponent
//                             onApply={handleApply}
//                             onCancel={handleCancel}
//                             config={filterConfig}
//                           />
//                         </div>
//                       </div>
//                       {getFeedback.length !== 0 ? (
//                         getFeedback.map((feedBackCard, index) => (
//                           <div className="col-md-4 mb-4" key={index}>
//                             <CardComponent
//                               cardType="feedback"
//                               feedBackCard={feedBackCard}
//                             />
//                           </div>
//                         ))
//                       ) : isLoad ? (
//                         <div
//                           style={{
//                             textAlign: "center",
//                             flex: 1,
//                             display: "flex",
//                             justifyContent: "center",
//                             marginTop: "20%",
//                           }}
//                         >
//                           <ClipLoader
//                             color={"#865BE7"}
//                             loading={isLoad}
//                             size={50}
//                           />
//                         </div>
//                       ) : (
//                         <span
//                           className="text-center py-5 vh-50"
//                           style={{ marginTop: "15%" }}
//                         >
//                           <img
//                             src="images/Default/no-record-img.svg"
//                             alt="no-record-img"
//                             className="no-record-img mb-5"
//                           />
//                           <span className="font-20 font-medium w-100 d-block text-color-1">
//                             Sorry! No history of feedbacks.
//                           </span>
//                         </span>
//                       )}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default FeedbackCards;