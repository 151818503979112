import React, { createContext, useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { SingleValue, MultiValue } from 'react-select';

import { getTask, ExistData, getTaskConfig } from '../Service/API';
import { useTableContext } from "../Utility/ReusableTableContext";
import { useFilter, Option, DateRange } from '../Utility/FilterContext';
import { TaskType } from '../Interfaces/ManageTasks';
import ButtonComponent from '../Reusable Components/Button';

// Interface defining the structure of the ManageTaskContext
interface ManageTaskContectType {

  tasks: TaskType[]; setTasks: React.Dispatch<React.SetStateAction<TaskType[]>>;
  statusDropdown: string[]; setStatusDropdown: React.Dispatch<React.SetStateAction<string[]>>;
  isLoading: boolean; setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  totalRecords: number; setTotalRecords: React.Dispatch<React.SetStateAction<number>>;
  endOffset: number; setEndOffset: React.Dispatch<React.SetStateAction<number>>;
  showConfigPop: boolean; setShowConfigPop: React.Dispatch<React.SetStateAction<boolean>>;
  editScenario: string; setEditScenario: React.Dispatch<React.SetStateAction<string>>;
  url: string; setUrl: React.Dispatch<React.SetStateAction<string>>;
  PAT: string; setPAT: React.Dispatch<React.SetStateAction<string>>;
  taskValueID: string; setValueTaskID: React.Dispatch<React.SetStateAction<string>>;
  searchValue: string; setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  TaskIDs: { taskID: string | number }[]; setTasksIDs: React.Dispatch<React.SetStateAction<{ taskID: string | number }[]>>;
  configuredTasks: string; setConfiguredTasks: React.Dispatch<React.SetStateAction<string>>;
  taskData: any; setTaskData: any;
  showPopUp: any; setShowPopUp: any;
  loaddata: any; setloaddata: any;

  // Functions
  handleLoadMore: () => void;
  fetchConfigTasks: () => Promise<void>;
  fetchTasks: () => Promise<void>;
  fetchInitialData: () => Promise<void>;
  handleFilterApply: (newFilters: any) => void;
  handleFilterCancel: () => void;
  handleTaskClick: (task: TaskType) => void;

  // Data-related items
  tableHeaders: { key: string; label: string; sortable?: boolean; component?: React.FC<{ row: TaskType }> }[];
  askAsami: any;
}

const ManageTasksContext = createContext<ManageTaskContectType | undefined>(undefined);

export const ManageTasksProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

  const navigate = useNavigate();
  const location = useLocation();

  const { projectName = '' } = location.state || {};
  let projectState = JSON.parse(sessionStorage.getItem("projectState") || "{}");
  let projectIdFromMP = projectState['projectIdFromMP'] || "";
  let projectNameFromLocal = projectState['projectName'] || "";
  const { data, setData, sortConfig, setSortConfig } = useTableContext<TaskType>();
  const { dropdowns, dateRange, setDropdownValue, setDateRange, clearAllFilters } = useFilter();
  
  // State for managing tasks and their configuration
  const [tasks, setTasks] = useState<TaskType[]>([]); 
  const [taskData, setTaskData] = useState("");
  const [TaskIDs, setTasksIDs] = useState<{ taskID: string | number }[]>([]);
  const [configuredTasks, setConfiguredTasks] = useState('');
  
  // State for dropdowns and filters
  const [statusDropdown, setStatusDropdown] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState('');
  
  // State for pagination and loading
  const [isLoading, setIsLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [endOffset, setEndOffset] = useState(10);
  
  // State for managing configuration popup and scenarios
  const [showConfigPop, setShowConfigPop] = useState(false);
  const [editScenario, setEditScenario] = useState('CONFIG');
  const [url, setUrl] = useState('');
  const [PAT, setPAT] = useState('');
  const [taskValueID, setValueTaskID] = useState('');
  
  // State for repository and popup management
  const [repoName, setRepoName] = useState();
  const [repoUrl, setRepoUrl] = useState();
  const [showPopUp, setShowPopUp] = useState(false);
  const [loaddata, setloaddata] = useState<any>(false);
  
  /**
   * Fetches configured tasks and updates the tasks list with their configuration status.
   */
  const fetchConfigTasks = async () => {
    try {
      const data = { tasks: TaskIDs };
      const response = await getTaskConfig(data);
      const configuredTasks = response.data[0].tasks;
      setConfiguredTasks(configuredTasks);
  
      const configuredIssueIds = new Set(configuredTasks.tasks.map((task: any) => task.taskID));
  
      // Update tasks with their configuration status
      setTasks((prevTasks) => {
        const updatedTasks = prevTasks.map((task) => ({
          ...task,
          isConfigured: configuredIssueIds.has(task.project_task_key),
        }));
        setData(updatedTasks);
        setIsLoading(false);
        return updatedTasks;
      });
    } catch (error) {
      console.error('Error fetching configured tasks:', error);
    }
  };
  
  /**
   * Converts raw task data into a standardized format.
   */
  function convertTasks(tasks: any) {
    return tasks.map((task: any) => ({
      project_task_key: task.taskID,
      work_item: task.workItem,
      date_range: task.ProjectTimeline,
      assigned_to: task.AssignedTo,
      Status: task.Status,
      imageUrl: task.imageUrl,
    }));
  }
  
  /**
   * Fetches tasks based on filters, sorting, and pagination.
   */
  const fetchTasks = async () => {
    setIsLoading(true);
    try {
      const filters = {
        ...Object.entries(dropdowns).reduce((acc, [key, value]) => ({
          ...acc,
          [key]: value.selectedOptions
            ? Array.isArray(value.selectedOptions)
              ? value.selectedOptions.map((o: any) => o.value)
              : 'value' in value.selectedOptions
              ? value.selectedOptions.value
              : null
            : null,
        }), {}),
        fromDate: dateRange.from ? dateRange.from.toISOString() : null,
        toDate: dateRange.to ? dateRange.to.toISOString() : null,
      };
  
      const response = await getTask({
        projectID: projectIdFromMP,
        taskID: searchValue,
        filterStatus: (dropdowns?.status?.selectedOptions as SingleValue<Option>)?.value,
        sortColumn: sortConfig.column,
        sortOrder: sortConfig.order === "desc" ? "Desc" : "asc",
        workItemType: "task",
        ...filters,
        offsetValue: endOffset,
      });
  
      if (response.status === 200) {
        const convertedTasks = convertTasks(response.data[0].tasks);
        setTasks(convertedTasks || []);
        setTotalRecords(response.data[0].totalRecords || 0);
        setStatusDropdown(['open', 'proposed', 'Active', 'Resolved']);
  
        const responseData: { tasks: { taskID: string | number }[] }[] = response.data;
        const uniqueProjectIDs = Array.from(
          new Set(responseData[0].tasks.map((task) => task.taskID))
        ).map((taskID) => ({ taskID }));
  
        if (uniqueProjectIDs.length <= 0) {
          setIsLoading(false);
        }
        setTasksIDs(uniqueProjectIDs);
      }
    } catch (error) {
      console.error('Error fetching tasks:', error);
    } 
  };


/**
 * Fetches initial data such as repository name, URL, project URL, and PAT.
 * This function is memoized using `useCallback` to avoid unnecessary re-renders.
 */
const fetchInitialData = useCallback(async () => {
  try {
    const response = await ExistData({ projectID: projectIdFromMP });
    if (response.Responsedata && response.Responsedata[0]) {
      setRepoName(response.Responsedata[0].Repo_data[0].Repo_name);
      setRepoUrl(response.Responsedata[0].Repo_data[0].Repo_Url);
      setUrl(response.Responsedata[0].project_url || '');
      setPAT(response.Responsedata[0].PAT || '');
    } else {
      console.error('Unexpected response structure:', response);
    }
  } catch (error) {
    console.error('Error fetching initial data:', error);
  }
}, [projectIdFromMP]);

/**
 * Fetches initial data and tasks when the component mounts or when dependencies change.
 */
useEffect(() => {
  fetchInitialData();
  fetchTasks();
}, [fetchInitialData, endOffset, sortConfig, projectIdFromMP, searchValue, loaddata]);

/**
 * Fetches configured tasks whenever the `TaskIDs` array changes.
 * If `TaskIDs` is empty, it clears the task data.
 */
useEffect(() => {
  async function fetchData() {
    if (TaskIDs.length > 0) {
      await fetchConfigTasks();
    } else {
      setData([]);
    }
  }
  fetchData();
}, [TaskIDs]);

/**
 * Applies filters to the task list and refetches tasks.
 */
function processString(value: any) {
  if (typeof value !== "string") return "";

  return value.replace(/([A-Z])/g, " $1").trim();
}


const handleFilterApply = (newFilters: any) => {
  Object.entries(newFilters).forEach(([key, value]) => {
    if (key === 'dateRange') {
      setDateRange(value as DateRange);
    } else {
      setDropdownValue(
        key, 
        {
          value: String(value),
          label: String(value).charAt(0).toUpperCase() + processString(String(value).slice(1))
        } as SingleValue<Option> | MultiValue<Option>,
        { action: 'select-option', option: {} as Option }
      );
    }
  });
  fetchTasks();
};

/**
 * Clears all filters and refetches tasks.
 * This function is memoized using `useCallback` to avoid unnecessary re-renders.
 */
const fetchTasksClear = async () => {
    setIsLoading(true);
    try {
  
      const response = await getTask({
        projectID: projectIdFromMP,
        taskID: searchValue,
        filterStatus: "",
        sortColumn: sortConfig.column,
        sortOrder: sortConfig.order === "desc" ? "Desc" : "asc",
        workItemType: "task",
        fromDate: null,
        toDate: null,
        offsetValue: endOffset,
      });
  
      if (response.status === 200) {
        const convertedTasks = convertTasks(response.data[0].tasks);
        setTasks(convertedTasks || []);
        setTotalRecords(response.data[0].totalRecords || 0);
        setStatusDropdown(['open', 'proposed', 'Active', 'Resolved']);
  
        const responseData: { tasks: { taskID: string | number }[] }[] = response.data;
        const uniqueProjectIDs = Array.from(
          new Set(responseData[0].tasks.map((task) => task.taskID))
        ).map((taskID) => ({ taskID }));
  
        if (uniqueProjectIDs.length <= 0) {
          setIsLoading(false);
        }
        setTasksIDs(uniqueProjectIDs);
      }
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };
const handleFilterCancel = useCallback(() => {
  clearAllFilters();
  fetchTasksClear();
}, [clearAllFilters]);

/**
 * Loads more tasks by increasing the `endOffset` value.
 * This function is memoized using `useCallback` to avoid unnecessary re-renders.
 */
const handleLoadMore = useCallback(() => {
  setEndOffset((prevOffset) => prevOffset + 10);
}, []);

/**
 * Handles task click navigation to the chat page with task-specific state.
 * This function is memoized using `useCallback` to avoid unnecessary re-renders.
 */
const handleTaskClick = useCallback(
  (task: TaskType) => {
    navigate('/chat', {
      state: {
        startConversation: true,
        hidePrompts: true,
        sendMessage: false,
        fixButton: true,
        tfsCollectionName: task.repoName,
        projectName: projectName,
        workItem: task.workItem,
      },
    });
  },
  [navigate, projectName]
);

/**
 * Checks if a task is configured by its ID.
 */
const getIsConfigured = (issueID: string) => {
  const config = tasks.find((item: any) => item.project_task_key === issueID);
  return config ? config.isConfigured : null;
};

/**
 * Fetches the repository name for a given task ID.
 */
const getExistConfig = async (issueID: string) => {
  try {
    const req = { ['task' + 'ID']: issueID };
    const response = await ExistData(req);
    setRepoName(response.data.Responsedata[0].Repo_data.Repo_name);
    let repo_name = response.data.Responsedata[0].Repo_data.Repo_name;
    return repo_name;
  } catch (error) {
    console.error('Error fetching existing config:', error);
  }
};

/**
 * Handles the "Ask Asami" functionality for a specific task.
 * Sets session storage, fetches repository configuration, and navigates to the chat page.
 */
const askAsami = async (issueID: any, workitem: any, iskgsearch: boolean) => {
  sessionStorage.setItem('isModule', 'tasks');
  let repo_name = await getExistConfig(issueID);
  setTasksIDs(issueID);
  sessionStorage.removeItem('issuesandtaskState');

  const state = {
    yesFixButton: true,
    startConversation: true,
    hidePrompts: true,
    sendMessage: false,
    fixButton: true,
    tfsCollectionName: repoName,
    projectName: projectNameFromLocal,
    workItem: workitem,
    issueIDFromMI: issueID,
    collectionName_projects: repo_name,
    isModule: "tasks",
    workItemFromMI: workitem,
    iskgsearch: iskgsearch,
  };

  // Store the state in sessionStorage
  sessionStorage.setItem('issuesandtaskState', JSON.stringify(state));

  // Use useNavigate to navigate
  navigate('/chat', { state });
};

/**
 * Defines the table headers with custom rendering logic for each column.
 * Uses `useMemo` to optimize performance by memoizing the headers.
 */
const tableHeaders = useMemo(
  () => [
    {
      key: 'project_task_key',
      label: 'Task ID',
      component: ({ row }: { row: any }) => (
        <>
          {getIsConfigured(row.project_task_key) ? (
            <a
              className="text-decoration-none link-color link-click"
              onClick={() => {
                setShowPopUp(true);
                setTaskData(row);
              }}
            >
              {row.project_task_key}
            </a>
          ) : (
            <span>{row.project_task_key}</span>
          )}
        </>
      ),
      sortable: true,
    },
    { key: 'work_item', label: 'Work Item', sortable: true },
    { key: 'date_range', label: 'Project Timeline', sortable: true },
    {
      key: 'assigned_to',
      label: 'Assigned To',
      component: ({ row }: { row: any }) => (
        <>
          {row.imageUrl ? (
            <img
              src={`data:image/jpeg;base64,${row.imageUrl}`}
              alt="Profile"
              className="profile-img-sm me-2"
              style={{ cursor: 'pointer' }}
              title={`${row.assigned_to}`}
            />
          ) : (
            <img
              src="images-new/default-profile-image.png"
              alt="Profile"
              className="profile-img-sm me-2"
              style={{ cursor: 'pointer' }}
              title={`${row.assigned_to}`}
            />
          )}
        </>
      ),
      sortable: true,
    },
    {
      key: 'Status',
      label: 'Status',
      component: ({ row }: { row: TaskType }) => {
        const status = row.Status.toUpperCase().replace(/[-\s]/g, '');
        switch (status) {
          case 'INPROGRESS':
          case 'IN-PROGRESS':
          case 'IN PROGRESS':
            return (
              <div className="d-flex text-start align-items-center gap-2">
                <span className="status-dot in-progress-bg" />
                <span className="in-progress">In Progress</span>
              </div>
            );
          case 'NOTSTARTED':
          case 'NOT STARTED':
          case 'NOT-STARTED':
            return (
              <div className="d-flex text-start align-items-center gap-2">
                <span className="status-dot not-started-bg" />
                <span className="not-started">Not Started</span>
              </div>
            );
          case 'COMPLETED':
            return (
              <div className="d-flex text-start align-items-center gap-2">
                <span className="status-dot in-completed-bg" />
                <span className="completed">Completed</span>
              </div>
            );
          case 'INDELAYED':
          case 'IN DELAYED':
          case 'IN-DELAYED':
            return (
              <div className="d-flex text-start align-items-center gap-2">
                <span className="status-dot danger-bg" />
                <span className="in-delay">In Delay</span>
              </div>
            );
          default:
            return <span>Unknown status: {row.Status}</span>;
        }
      },
      sortable: true,
    },
    {
      key: 'actions',
      label: 'Actions',
      component: ({ row }: { row: TaskType }) => (
        <ButtonComponent
          buttonType="icon"
          iconSrc={row.isConfigured ? 'images-new/edit-icons.svg' : 'images-new/right-icon.svg'}
          altText={row.isConfigured ? 'Edit' : 'Configure'}
          onClick={() => {
            setEditScenario(row.isConfigured ? 'update' : 'config');
            setShowConfigPop(true);
            sessionStorage.setItem('uniqueIdFromLocal', row.project_task_key);
          }}
        />
      ),
    },
  ],
  [data]
);

  return (
    <ManageTasksContext.Provider value={{
      setloaddata,loaddata,
      tasks, setTasks,
      statusDropdown, setStatusDropdown,
      isLoading, setIsLoading,
      totalRecords, setTotalRecords,
      endOffset, setEndOffset,
      showConfigPop, setShowConfigPop,
      editScenario, setEditScenario,
      url, setUrl,
      PAT, setPAT,
      taskValueID, setValueTaskID,
      searchValue, setSearchValue,
      TaskIDs, setTasksIDs,
      configuredTasks, setConfiguredTasks,
      handleLoadMore,
      fetchConfigTasks,
      fetchTasks,
      fetchInitialData,
      handleFilterApply,
      handleFilterCancel,
      handleTaskClick,
      tableHeaders,taskData,setTaskData,askAsami,setShowPopUp,showPopUp
    }}>
      {children}
    </ManageTasksContext.Provider>
  );
};

export const useManageTasks = () => {
  const context = useContext(ManageTasksContext);
  if (context === undefined) {
    throw new Error('useManageTasks must be used within a ManageTasksProvider');
  }
  return context;
};