import React, { useEffect, useState, useCallback } from "react";
import { useFileUpload } from "../../Utility/FileUploadContext";

interface RecordData {
  inputValueOrder: string;
  labelName: string;
  required: string;
  placeHolder?: string;
}

interface Record {
  record: RecordData;
  validationErrors?: {
    [key: string]: string;
  };
  fieldValues: {
    [key: string]: {
      value: string | string[];
    };
  };
  handleFieldValues: (labelName: string, value: string | string[], inputValueOrder: string, required: string) => void;
  removeValidationError: (inputValueOrder: string) => void;
}

interface MediaUploadProps {
  record: Record;
  setFieldValues?: any
}

export const MediaUpload = ({ record, setFieldValues }: MediaUploadProps) => {
  const [localUploadedFileUrl, setLocalUploadedFileUrl] = useState<string[]>([]);
  const { uploadMedia, isUploading, alertMessage, alertType } = useFileUpload();

  const actualRecord = record.record;

  useEffect(() => {
    const storedBLinksList = sessionStorage.getItem("BLinksList");
    const BLinksList = storedBLinksList ? JSON.parse(storedBLinksList) : [];
    setLocalUploadedFileUrl(BLinksList);

    if (BLinksList.length > 0) {
      setFieldValues((prev:any) => ({
        ...prev,
        [actualRecord.inputValueOrder]: { value: BLinksList }
      }));
    }
  }, [actualRecord, setFieldValues]);

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    await uploadMedia(e, record, actualRecord);
    const storedBLinksList = sessionStorage.getItem("BLinksList");
    const BLinksList = storedBLinksList ? JSON.parse(storedBLinksList) : [];
    if (BLinksList && BLinksList.length > 0) {
      setLocalUploadedFileUrl(BLinksList);
      setFieldValues((prev:any) => ({
        ...prev,
        [actualRecord.inputValueOrder]: { value: BLinksList }
      }));
      record.removeValidationError(actualRecord.inputValueOrder);
    }
  };

  const handleFileRemove = useCallback((index: number) => {
    setLocalUploadedFileUrl(prevUrls => {
      const updatedUrls = prevUrls.filter((_, i) => i !== index);
      sessionStorage.setItem("BLinksList", JSON.stringify(updatedUrls));
      
      setFieldValues((prev:any) => ({
        ...prev,
        [actualRecord.inputValueOrder]: { value: updatedUrls }
      }));
      
      if (updatedUrls.length === 0 && actualRecord.required === "true") {
        record.handleFieldValues(
          actualRecord.labelName,
          [],
          actualRecord.inputValueOrder,
          actualRecord.required
        );
      } else {
        record.removeValidationError(actualRecord.inputValueOrder);
      }
      
      return updatedUrls;
    });
  }, [record, actualRecord, setFieldValues]);


  const getFileName = (url: string) => {
    const parts = url.split('/');
    return parts[parts.length - 1];
  };

  return (
    <>
      {actualRecord?.labelName && (
        <label className="text-color-14 font-15 font-semibold label-color" htmlFor={actualRecord.labelName}>
          {actualRecord.labelName}
          {actualRecord.required === "true" && <span className="color-red">*</span>}
        </label>
      )}

      <div className="col-md-12">
        <div className="upload-btn-wrapper">
          <button
            type="button"
            className="upload-btn font-13 font-medium px-4 py-2 bg-color text-color-5"
            disabled={isUploading}
          >
            <img src="images/ai-images/upload-icon.svg" alt="upload icon" className="me-2" />
            {isUploading ? "Uploading..." : ("Upload Media")}
          </button>

          <input
            disabled={isUploading}
            className="custom-cursor"
            type="file"
            name="myfile"
            multiple
            id={actualRecord?.labelName}
            onChange={handleUpload}
          />

          {alertMessage && (
            <p className={`alert-message ${alertType}`}>
              {alertMessage}
            </p>
          )}
        </div>

        {localUploadedFileUrl.length > 0 && (
          <div className="uploaded-file-container">
            {localUploadedFileUrl.map((url, index) => {
              const fileName = getFileName(url);
              return (
                <div key={index} id={`file-${index}`} className="doc-attach-container text-color-15 px-3 py-2 d-flex align-items-center justify-content-between">
                  <span className="d-flex align-items-center gap-2 file-name">
                    <img src={fileName.includes('jpg') || fileName.includes('png') ? '../images/attach-img.svg' : '../images-new/single-file.svg'} alt="file-icon" />
                    <span className="file-name-text">{fileName}</span>
                  </span>
                  <button className="border-0 bg-transparent d-flex" onClick={() => handleFileRemove(index)}>
                    <img className="filter-icons" src="../images/close.svg" alt="close-icon" />
                  </button>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {record.validationErrors && record.validationErrors[actualRecord.inputValueOrder] && (
        <span className="mandatory-star font-14 font-medium mb-0" style={{ color: "red" }}>
          {record.validationErrors[actualRecord.inputValueOrder]}
        </span>
      )}
    </>
  );
}