// DiagramButtonContext.tsx
import React, { createContext, useState, useContext, ReactNode, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

// Interface defining the structure of the diagram button context
interface DiagramButtonContextType {
    copiedCode: boolean; copiedImage: boolean;
    isDiagramFullscreen: boolean; isEditorFullscreen: boolean;
    zoom: number; layout: string;
    handleCopyCode: () => void; handleCopyImage: (diagramSvg: string | null) => void;
    toggleEditorFullscreen: () => void; toggleDiagramFullscreen: () => void;
    handleLayoutChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    handleZoomIn: () => void; handleZoomOut: () => void;
    handleGoBack: (chatId: string | null) => void; handleDownloadImage: (diagramSvg: string | null) => void;
}

// Create the diagram button context with an initial undefined value
const DiagramButtonContext = createContext<DiagramButtonContextType | undefined>(undefined);

// Diagram button provider component to wrap around the application
export const DiagramButtonProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

    // State for managing copied code, copied image, fullscreen modes, zoom, layout, and errors
    const [copiedCode, setCopiedCode] = useState(false);
    const [copiedImage, setCopiedImage] = useState(false);
    const [isDiagramFullscreen, setIsDiagramFullscreen] = useState(false);
    const [isEditorFullscreen, setIsEditorFullscreen] = useState(false);
    const [zoom, setZoom] = useState(1);
    const [layout, setLayout] = useState('right');
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    // Function to handle copying code to clipboard
    const handleCopyCode = useCallback(() => {
        setCopiedCode(true);
        setTimeout(() => setCopiedCode(false), 2000);
    }, []);

    // Function to handle copying diagram as an image
    const handleCopyImage = (diagramSvg: string | null) => {
        if (diagramSvg) {
            const blob = new Blob([diagramSvg], { type: 'image/svg+xml' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'diagram.svg';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
            setCopiedImage(true);
            setTimeout(() => setCopiedImage(false), 2000);
        } else {
            console.error('No diagram SVG available');
            alert('No diagram available to download. Please try again.');
        }
    };

    // Function to handle downloading the diagram as an image
    const handleDownloadImage = useCallback((diagramSvg: string | null) => {
        if (diagramSvg) {
            const blob = new Blob([diagramSvg], { type: 'image/svg+xml' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'diagram.svg';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
            setCopiedImage(true);
            setTimeout(() => setCopiedImage(false), 2000);
        } else {
            setError('No diagram available to download. Please try again.');
        }
    }, []);

    // Function to handle navigating back to the chat
    const handleGoBack = useCallback((chatId: string | null) => {
        if (chatId) {
            navigate(`/chat?chatID=${chatId}`);
        }
    }, [navigate]);

    // Function to handle zooming in
    const handleZoomIn = useCallback(() => {
        setZoom(prev => Math.min(prev + 0.1, 2)); // Increase zoom level, max 2x
    }, []);

    // Function to handle zooming out
    const handleZoomOut = useCallback(() => {
        setZoom(prev => Math.max(prev - 0.1, 0.5)); // Decrease zoom level, min 0.5x
    }, []);

    // Function to toggle diagram fullscreen mode
    const toggleDiagramFullscreen = useCallback(() => {
        setIsDiagramFullscreen(prev => !prev);
    }, []);

    // Function to toggle editor fullscreen mode
    const toggleEditorFullscreen = useCallback(() => {
        setIsEditorFullscreen(prev => !prev);
    }, []);

    // Function to handle layout change
    const handleLayoutChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
        setLayout(event.target.value);
    }, []);

    // Value object to be provided by the context
    const value = {
        copiedCode,
        copiedImage,
        isDiagramFullscreen,
        isEditorFullscreen,
        zoom,
        layout,
        handleCopyCode, handleCopyImage,
        toggleEditorFullscreen, toggleDiagramFullscreen,
        handleLayoutChange,
        handleZoomIn, handleZoomOut,
        handleGoBack, handleDownloadImage,
    };

    // Provide the context value to children
    return (
        <DiagramButtonContext.Provider value={value}>
            {children}
        </DiagramButtonContext.Provider>
    );
};

// Custom hook to access the diagram button context
export const useDiagramButtons = (): DiagramButtonContextType => {
    const context = useContext(DiagramButtonContext);
    if (context === undefined) {
        throw new Error('useDiagramButtons must be used within a DiagramButtonProvider');
    }
    return context;
};