import React, { useState,useEffect } from 'react';
import { ChatMessage } from '../Interfaces/ChatHistory';
import Toastr from './Toastr';
 
interface ShareConversationPopupProps {
  isOpen: boolean;
  sharePromptName: string;
  chatContent: any[];
  shareDate: string;
  shareChatID: string;
  profileImage: string;
  baseUrl: string;
  chatType: string;
  projectID: string;
  onClose: () => void;
}
 
const ShareConversationPopup: React.FC<ShareConversationPopupProps> = ({
  isOpen,

  sharePromptName,
  shareDate,
  shareChatID,
  chatContent,
  profileImage,
  baseUrl,
  chatType,
  projectID,
  onClose,
}) => {
  const [isCopied , setIsCopied] = useState<boolean>(false)

  useEffect(() => {
    if (isOpen) {
      // Prevent page from scrolling when the modal is open
      document.body.style.overflow = 'hidden';
    } else {
      // Allow page to scroll when the modal is closed
      document.body.style.overflow = 'auto';
    }
 
    // Clean up the effect when the component is unmounted
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);
  const handleCopyLink = () => {
 
    navigator.clipboard.writeText(`${baseUrl}/chat?chatID=${shareChatID}&type=${chatType}&projectID=${projectID}&share=true`)
      .then(() => {
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
    }, 2000);
  };
 
  function decodeBase64(base64String: string): string {
    try {
      return atob(base64String);
    } catch (e) {
      console.error("Failed to decode base64:", e);
      return base64String;
    }
  }
  
  function bindChatContent(chatContent: ChatMessage[]) {
    return chatContent?.map((value, index) => {
      const userRole = value.role || value.chat_type;
      const isUser = userRole === "user";
      const isSystem = userRole === "asami" || userRole === "system";
      const isUnknown = !isUser && !isSystem;
      const roleName = isUser ? "You" : (isSystem ? "Asami" : "Asami");
  
      const content = isUnknown ? decodeBase64(value.content) : value.content;
  
      return (
        <div className="col-md-12 py-3 px-3" key={index}>
          <span className="d-flex">
            <span className="d-block">
              <img
                style={{ height: "44px", width: "44px", borderRadius: "50%" }}
                src={isUser ? profileImage : "images/Default/asami-bot.svg"}
                alt="profile-pic"
              />
            </span>
            <span className="d-block ms-3 mt-2">
              <h5 className="font-18 font-bold text-color-1">{roleName}</h5>
              <p className="font-medium font-16 text-color-10">{content}</p>
            </span>
          </span>
        </div>
      );
    });
  }
 
  if (!isOpen) return null;
 
  return (
    <>
      {/* Backdrop */}
      <div
        className="modal-backdrop fade show"
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1040
        }}
        onClick={onClose}
      />
     
      <div
        className="modal fade show"
        id="share-convo-popup"
        style={{
          display: 'block',
          zIndex: 1050 
        }}
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered custom-popup-width border-0">
          <div style={{display:"flex",flexDirection:"column"}}>
          <div className="modal-content p-3 border-0 bg-color-4" style={{width: '40vw'}}>
            <div className="modal-header border-0 pt-2 px-1 pb-0">
              <h5 className="modal-title text-dark font-20 font-bold text-color-1">Share Conversation Link</h5>
              <button
                type="button"
                className="btn-close"
                onClick={onClose}
                aria-label="Close"
              />
            </div>
            <div className="modal-body pt-2">
              <div className="row border border-radius-2 custom-border-5">
                <div className="custom-convo-scroll">
                  {bindChatContent(chatContent)}
                </div>
                <div className="d-flex justify-content-between align-items-center custom-border-top py-2">
                  <div>
                    <h5 className="font-16 font-medium text-color-1 mb-0" id="promptNameDisplay">
                      {sharePromptName}
                    </h5>
                    <span className="font-14 font-medium text-color-10">{shareDate}</span>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn primary-btn font-14 font-semibold border-radius-5 color-white py-2 px-3"
                      onClick={handleCopyLink}
                    >
                      <img className="me-2" src="images/Default/link-icon.svg" alt="link-icon" />
                      Copy Link
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          { isCopied && 
            <Toastr message='Link Copied Successfully' level='Success' duration={2000} showCloseButton={false}></Toastr>
            }
          </div>
        </div>
      </div>
    </>
  );
};
 
export default ShareConversationPopup;