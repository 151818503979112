
import React from 'react';
import { DropDownProvider } from '../Utility/DropDownContext';
import DropDownComponent from './DropDown';
import TechStack from './TechStack';

export interface FormField {
  type: 'text' | 'textarea' | 'select' | 'checkbox' | 'radio' | 'number'|'button'|'dropdown'|'imagedropdown';
  isMulti?:boolean;
  required?: boolean;
  disabled?:boolean;
  dropdown:boolean;
  name: string;
  error?:string;
  label: string;
  placeholder?: string;
  displayNameError?:string;
  id?:string;
  getevalue?:any;
  options?: any;
  value?: any ;
  dropdownfieldfunc:any;
  change:any
  dropdownfunc:any;
  dropdownval:any;
  className?:any;
  hidelabel?:any;
}

interface DynamicFormProps {
  fields: FormField[];
}

const DynamicForm: React.FC<DynamicFormProps> = ({ fields }) => {

  const renderField = (field: FormField) => {
    switch (field.type) {
      case 'textarea':
        return (
          <textarea
            name={field.name}
            value={field.value || ''}
            rows={4}
            onChange={field.change}
            disabled={field.disabled}
            id={field.id}
            placeholder={field.placeholder}
            required={field.required}
            className= "form-control input-field font-14 font-regular border-radius-3  bg-color text-color-4 custom-border-3 cust-search-input resize-none"
          />
        );
      case 'select':
        return (
          <select
            name={field.name}
            value={field.value || ''}
            onChange={field.change}
            disabled={field.disabled}
            required={field.required}
            className="form-select"
          >
            <option value="">Select {field.label}</option>
            {field.options?.map((option:any, index:any) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        );
      case 'button':
        return(
            <>
            <button id="end-point-config" className="btn input-field font-14 font-regular border-radius-3  bg-color text-color-4 custom-border-3 cust-search-input w-100 d-flex justify-content-between" type="button" onClick={(e) => { field.dropdown ? field.dropdownfunc(false) : field.dropdownfunc(true) }} >
                                <span className="text-color-2" style={{ color: "black" }} >{field.value || 'Select'}</span>
                                <span className="ps-1"><div
                                style={{ 
                                  width: '10px',
                                  height: '20px',
                                  transform: field.dropdown? "rotate(180deg)" : "",
                                  backgroundColor: "var(--select-arrow-color)",
                                  WebkitMask: `url('images-new/dropdown-grey-icon.svg') no-repeat center / contain`,
                                  mask: `url('images-new/dropdown-grey-icon.svg') no-repeat center / contain`,
                                  transition: "transform 0.3s ease"
                                }} 
                                className="custom-dropdown-fix" 
                              /></span>
            </button>
             {field.dropdown ?<ul className="dropdown-menu border-0 shadow-sm p-2 bg-color-2 " 
                style={{
                  display : field.dropdown ? "block" : "none" ,
                  maxHeight: "150%",  // Adjust this value as needed
                  overflowY: "auto"
                }}
                >
                  {field.dropdownfieldfunc(field.dropdownval)}
                </ul> : null}
                </>
        )
        case 'dropdown':
        return (
          <DropDownProvider isMulti={field.isMulti}>
          <DropDownComponent
            label=""
            options={field.dropdownval}
            hidelabel={field.hidelabel||false}
            disabled={field.disabled}
            value={field.value|| []} // Set default value to 'issue'
            onChange={field.dropdownfunc}
          />
        </DropDownProvider>
        );
      case 'imagedropdown':
      return (
        <TechStack
          name={field.name}
          label={field.label}
          disabled={field.disabled}
          value={field.value || ''}
          options={field.dropdownval}
          onChange={(value) => field.change({ target: { name: field.name, value } })}
          required={field.required}
          error={field.error}
        />
      );
        
      case 'checkbox':
        return (
          <input
            type="checkbox"
            name={field.name}
            disabled={field.disabled}
            checked={!!field.value}
            onChange={field.change}
            className="form-check-input"
          />
        );
      case 'radio':
        return field.options?.map((option:any, index:any) => (
          <div key={index} className="form-check">
            <input
              type="radio"
              name={field.name}
              disabled={field.disabled}
              value={option}
              checked={field.value === option}
              onChange={field.change}
              className="form-check-input"
            />
            <label className="form-check-label">{option}</label>
          </div>
        ));
        case 'text': // Add a specific case for 'text'
        return (
            <input
                type="text"
                name={field.name}
                value={field.value || ''}
                onChange={field.change}
                disabled={field.disabled}
                id={field.id}
                placeholder={field.placeholder}
                required={field.required}
                className={field.className || "form-control input-field font-14 font-medium color-black border-radius-3 bg-color text-color-4 custom-border-3 cust-search-input resize-none theme-focus"}
            />
        );
      default:
        return (
          <input
          type={field.type}
          id={field.id}
          className={field.className||"form-control input-field font-14 font-medium color-black border-radius-3 bg-color text-color-4 custom-border-3 cust-search-input resize-none theme-focus"}
          placeholder={field.placeholder}
          value={field.value}
          onChange={field.change}
          required={field.required}
        />
        );
    }
  };

  return (
    <form>
      {fields.map((field, index) => (
        <div key={index}>
          <label htmlFor={field.name} className="form-label font-15 font-semibold text-color-4 mb-2">
            {field.label}
            {field.required && <span className="text-danger">*</span>}
          </label>
          {renderField(field)}
          {field.displayNameError&& <div className="text-danger">{field.displayNameError}</div>}
        </div>
      ))}
    </form>
  );
};

export default DynamicForm;