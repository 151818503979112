//SQ_HUB_1.1 - Imports necessary libraries and modules
import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMsal, useAccount } from "@azure/msal-react";
import { useTheme } from '@mui/material/styles';
import Select from 'react-select';
import { ClipLoader } from 'react-spinners';
import BarGraphComp from './BarGraph';
import LineBarGraph from './LineBarGraph';
import LineGraph from './LineBar';
import RowComponent from '../../Reusable Components/Hub Reusable Components/Row';
import ColumnComponent from '../../Reusable Components/Hub Reusable Components/Column';
// import Loader from "../../Other-Components/loader";
import Card from '../../Reusable Components/Hub Reusable Components/Card';
import { TableProvider, useTableContext } from '../../Utility/ReusableTableContext';
import ReusableTable from '../../Reusable Components/ReusableTable';
import { DropDownProvider } from '../../Utility/DropDownContext';
import DropDownComponent from '../../Reusable Components/DropDown';
import { Option } from '../../Utility/FilterContext';
import Tooltip from '../../Reusable Components/ToolTip';
import { StylesConfig } from 'react-select';
import io from 'socket.io-client';
import {
    fetchActiveUsers,
    fetchFixedResponseData,
    fetchMostOrLeastUsedPrompt,
    fetchAsamiUsageData,
    fetchCostAnalysisData,
    fetchFeedbackData
} from "../../Service/API"
import UserContext from '../../Auth/UserContext';
import Loader from '../../Reusable Components/Loader';
import { UrlsAi } from '../../Service/API-Constants';

//SQ_HUB_1.2 - Uses the Interface for typesafety
interface DashboardProps {
    navOpen: string;
}

interface ModelOption {
    value: string;
    label: string;
    model: any;
  }

interface DashboardState {
    activeUsers: number;
    totalUsers: number;
    monthlyCost: number;
    fixedResponsesCount: number;
    showMultiSelect: boolean;
    asamiUsageProjectData: any[];
    asamiUsagePracticeData: any[];
    costAnalysisPracticeData: any[];
    costAnalysisProjectData: any[];
    costAnalysisIndividualData: any[];
    visitorsMonth: any[];
    visitorsWeek: any[];
    visitorsYear: any[];
    visitorsData: any[];
    visitorsDropDown: string;
    happyMeter: any;
    sadMeter: any;
    neutralMeter:any;
    promptInfo: any[];
    mostOrLeastUsedPrompt: string;
    promptSearchVal: string | undefined;
    practiceArray: string[] | undefined;
    tempPracticeArray: string[];
    asamiUsageDropDown: string;
    costAnalysisDropDown: string;
    selectedPromptsDropDown: string[];
    fixedResponseData: any[];
    fixedResponseExpandData: any[];
    fixedExpand: string;
    asamiGraphData: any[];
    options: any[];
    selectedOptions: any[];
    selectedPractice: string[];
    fixedPromptName: string;
    isLoading: boolean;
    isLoad: boolean;
    isDropdownOpen: boolean;
    asamiDropdownOpen: boolean;
    costAnalysisDropdownOpen: boolean;
    visitorsDropdownOpen: boolean;
    mostOrLeastUsedSortDropdownOpen: boolean;
    costAnalysisGraphData: any[];
}


const Dashboard: React.FC<DashboardProps> = ({ navOpen }) => {
    //SQ_HUB_1.3 - Initializes the state variables
    const [state, setState] = useState<DashboardState>({
        activeUsers: 0,
        totalUsers: 0,
        monthlyCost: 0,
        fixedResponsesCount: 0,
        showMultiSelect: false,
        asamiUsageProjectData: [],
        asamiUsagePracticeData: [],
        costAnalysisPracticeData: [],
        costAnalysisProjectData: [],
        costAnalysisIndividualData: [],
        visitorsMonth: [],
        visitorsWeek: [],
        visitorsYear: [],
        visitorsData: [],
        visitorsDropDown: "Monthly",
        happyMeter: 0,
        sadMeter: 0,
        neutralMeter: 0,
        promptInfo: [],
        mostOrLeastUsedPrompt: "",
        promptSearchVal: undefined,
        practiceArray: undefined,
        tempPracticeArray: [],
        asamiUsageDropDown: "",
        costAnalysisDropDown: "",
        selectedPromptsDropDown: [],
        fixedResponseData: [],
        fixedResponseExpandData: [],
        fixedExpand: "",
        asamiGraphData: [],
        options: [],
        selectedOptions: [],
        selectedPractice: [],
        fixedPromptName: "",
        isLoading: false,
        isLoad: false,
        isDropdownOpen: false,
        asamiDropdownOpen: false,
        costAnalysisDropdownOpen: false,
        visitorsDropdownOpen: false,
        mostOrLeastUsedSortDropdownOpen: false,
        costAnalysisGraphData: [],
    });

    const navigate = useNavigate();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || null);
    const userData = useContext(UserContext);
    const theme = useTheme();

    const asamiSelectRef = useRef<HTMLSelectElement>(null);
    const costAnalysisSelectRef = useRef<HTMLSelectElement>(null);
    const visitorsSelectRef = useRef<HTMLSelectElement>(null);
    const mostOrLeastUsedSortSelectRef = useRef<HTMLSelectElement>(null);
    let [limitVal, setLimitVal] = useState(0)
    let [totalCount, setTotalCount] = useState(0)
 
    const { setData } = useTableContext()
    let [fixedResponseLoad, setFixedResponseLoad] = useState<number>(2)

    //SQ_HUB_1.4 to 1.73 - UseEffect is triggered to load the initial data for Hub
    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([
                    getTopCards(),
                    getAsamiUsageData(),
                    getCostAnalysisData(),
                    getMostOrLeastUsedPrompt(),
                    getFeedBackData(),
                    handleSelectChange([{ value: userData?.department, label: userData?.department }])
                ]);
            } catch (error) {
                console.error('An error occurred while fetching data:', error);
            }
        };
    
        fetchData();
    }, [userData]);
    // SQ_HUB_1.84 to 1.86 Sets the state for Practice and Project options 
    useEffect(() => {
        setState(prevState => {
            // Create a Set to store unique practice names
            const uniquePracticeNames = new Set();
   
            // Map through promptInfo and add unique practice names to the Set
            const options = prevState?.promptInfo?.reduce((acc, item) => {
                if (!uniquePracticeNames?.has(item.practiceName)) {
                    uniquePracticeNames?.add(item.practiceName);
                    acc.push({
                        value: item?.practiceName,
                        label: item?.practiceName
                    });
                }
                return acc;
            }, []);
   
            return {
                ...prevState,
                options: options
            };
        });
    }, [state.tempPracticeArray]);
    //SQ_HUB_1.87 , SQ_HUB 1.88 FIlters the data that is being selected as per the practice in the dropdown 
    useEffect(() => {
        const filteredData = state?.promptInfo?.filter(ele =>
            state?.selectedPractice?.length === 0 || state?.selectedPractice?.includes(ele?.practiceName)
        );
        setData(filteredData);
    }, [state.promptInfo, state.selectedPractice]);
    //SQ_HUB_1.89, 1.90 Updates the state when a practice is being selected
    useEffect(() => {
        const tempOptions = state?.selectedOptions?.map((element) => element.value);
        setState(prevState => ({
            ...prevState,
            selectedPractice: tempOptions
        }));
    }, [state.selectedOptions]);
    //SQ_HUB_4.1 to 4.8 Sets the newly selected value for Weekly , Monthly or Yearly
    useEffect(() => {
        if (state.visitorsDropDown === "Weekly") {
            setState(prevState => ({ ...prevState, visitorsData: prevState?.visitorsWeek }));
        } else if (state?.visitorsDropDown === "Monthly") {
            setState(prevState => ({ ...prevState, visitorsData: prevState?.visitorsMonth }));
        } else if (state?.visitorsDropDown === "Yearly") {
            setState(prevState => ({ ...prevState, visitorsData: prevState?.visitorsYear }));
        }
    }, [state.visitorsDropDown]);
    //SQ_HUB_2.1 to 2.7 Sets the state for drop down show for practice or project in asami usage dropdown
    useEffect(() => {
        if (state.asamiUsageDropDown === "Practice") {
            setState(prevState => ({ ...prevState, asamiGraphData: prevState?.asamiUsagePracticeData }));
        } else if (state?.asamiUsageDropDown === "Project") {
            setState(prevState => ({ ...prevState, asamiGraphData: prevState?.asamiUsageProjectData }));
        }
    }, [state.asamiUsageDropDown]);
    //SQ_HUB_3.1 to 3.8 Sets the state for Initial drop down show for practice or project or Individual in Cost Analysis dropdown
    useEffect(() => {
        if (state.costAnalysisDropDown === "Practice") {
            setState(prevState => ({ ...prevState, costAnalysisGraphData: prevState?.costAnalysisPracticeData }));
        } else if (state.costAnalysisDropDown === "Project") {
            setState(prevState => ({ ...prevState, costAnalysisGraphData: prevState?.costAnalysisProjectData }));
        } else if (state.costAnalysisDropDown === "Individual") {
            setState(prevState => ({ ...prevState, costAnalysisGraphData: prevState?.costAnalysisIndividualData }));
        }
    }, [state.costAnalysisDropDown]);
    //SQ_HUB_1.77 to 1.86 The activeUsers card is updated periodically
    //SQ_HUB_1.5 to 1.15 gets the data for the top tile cards in the Hub and sets it
    const getTopCards = async () => {
        try {
            let activeUserRes = await fetchActiveUsers({
                onlineStatus: 1,
                userEmailId: account?.username || '',
                timePeriod: "month",
                VisitorsData: true
            });
       

            setState(prevState => ({
                ...prevState,
                visitorsMonth: activeUserRes?.data?.data?.data?.totalMonthlyUsers,
                visitorsWeek: activeUserRes?.data?.data?.data?.totalWeeklyUsers,
                visitorsYear: activeUserRes?.data?.data?.data?.totalYearlyUsers,
                visitorsData: activeUserRes?.data?.data?.data?.totalMonthlyUsers,
                totalUsers: activeUserRes?.data?.data?.data?.overallUsers
            }));

            const fixedResResponse = await fetchFixedResponseData({
                expanded: "False",
                promptId: ""
            });


            setState(prevState => ({
                ...prevState,
                fixedResponsesCount: fixedResResponse?.data?.data?.totalFixedResponses,
                fixedResponseData: fixedResResponse?.data?.data?.mainOutput
            }));

        } catch (error) {
            console.error("Error fetching top cards data:", error);
        } finally {
            setState(prevState => ({ ...prevState, isLoading: true }));
        }
    };
    //SQ_HUB_1.16 to 1.30 fetches the data for AsamiUsage Graph and sets it
    const getAsamiUsageData = async () => {
        try {
            let asamiUsageResponse = await fetchAsamiUsageData({
                TypeofData: "Practice",
                sort: "",
                Search: "",
                offsetValue: 1
            });

            setState(prevState => ({
                ...prevState,
                asamiUsagePracticeData: asamiUsageResponse?.data?.responseData?.Data,
                asamiGraphData: asamiUsageResponse?.data?.responseData?.Data
            }));

            let asamiUsageResponseProject = await fetchAsamiUsageData({
                TypeofData: "Project",
                sort: "",
                Search: "",
                offsetValue: 1
            });

            setState(prevState => ({
                ...prevState,
                asamiUsageProjectData: asamiUsageResponseProject?.data?.responseData?.Data
            }));

        } catch (error) {
            console.error("Error fetching Asami usage data:", error);
        }
    };

    //SQ_HUB_1.31 to 1.52 fetches the data for cost analysis graph and sets it
    const getCostAnalysisData = async () => {
        try {
            let response = await fetchCostAnalysisData({
                pageValue: "Practice",
                sortOption: "Practice",
                sortValue: "DESC",
                offsetValue: 1,
                searchValue: "",
                filterValue: ""
            });

            setState((prevState: any) => ({
                ...prevState,
                costAnalysisPracticeData: response?.data?.data?.data1,
                costAnalysisGraphData: response?.data?.data?.data1,
                monthlyCost: parseFloat(response?.data?.data?.totalCost).toFixed(2)
            }));

            let response1 = await fetchCostAnalysisData({
                pageValue: "Project",
                sortOption: "Project",
                sortValue: "DESC",
                offsetValue: 1,
                searchValue: "",
                filterValue: ""
            });
            

            setState(prevState => ({
                ...prevState,
                costAnalysisProjectData: response1?.data?.data?.data1
            }));

            let response2 = await fetchCostAnalysisData({
                pageValue: "Individual",
                sortOption: "Individual",
                sortValue: "DESC",
                offsetValue: 1,
                searchValue: "",
                filterValue: ""
            });
            

            setState(prevState => ({
                ...prevState,
                costAnalysisIndividualData: response2?.data?.data?.data1
            }));

        } catch (error) {
            console.error("Error fetching cost analysis data:", error);
        }
    };


    //SQ_HUB_1.53 to 1.64 Fetches the data for MostUsed LessUsed Graph and sets it
    const getMostOrLeastUsedPrompt = async () => {
        try {
            const response = await fetchMostOrLeastUsedPrompt({
                practices: userData?.department?"":"",
                sort: "Desc"
            });
            const practiceNames = response?.data?.responseData?.mainOutput?.map((ele: any) => ele.practiceName);
            const uniquePracticeNames = Array.from(new Set(practiceNames));

            setState((prevState: any) => ({
                ...prevState,
                promptInfo: response?.data?.responseData?.mainOutput,
                practiceArray: uniquePracticeNames,
                tempPracticeArray: uniquePracticeNames,
            }));
           

        } catch (error) {
            console.error("Error fetching most or least used prompt:", error);
        }
    };

    //SQ_HUB_1.65 to 1.73 fetches the data for showing feedback percentage and sets it
    const getFeedBackData = async () => {
        try {
            const response = await fetchFeedbackData();
            setState((prevState: any) => ({
                ...prevState,
                happyMeter: parseFloat(response.feedback_result.Happy).toFixed(2),
                sadMeter: parseFloat(response.feedback_result.Sad).toFixed(2),
                neutralMeter: parseFloat(response.feedback_result.Neutral).toFixed(2)
            }));
        } catch (error) {
            console.error("Error fetching feedback data:", error);
        }
    };
    //SQ_HUB_8.0 to 8.6 Monitors the click event outside of graph to make the drop down close.
    const handleClickOutside = (event: MouseEvent) => {
        if (asamiSelectRef.current && !asamiSelectRef.current.contains(event.target as Node)) {
            setState(prevState => ({ ...prevState, asamiDropdownOpen: false }));
        }
        if (costAnalysisSelectRef.current && !costAnalysisSelectRef.current.contains(event.target as Node)) {
            setState(prevState => ({ ...prevState, costAnalysisDropdownOpen: false }));
        }
        if (visitorsSelectRef.current && !visitorsSelectRef.current.contains(event.target as Node)) {
            setState(prevState => ({ ...prevState, visitorsDropdownOpen: false }));
        }
        if (mostOrLeastUsedSortSelectRef.current && !mostOrLeastUsedSortSelectRef.current.contains(event.target as Node)) {
            setState(prevState => ({ ...prevState, mostOrLeastUsedSortDropdownOpen: false }));
        }
    };
    //SQ_HUB_9.0 to 9.3 Monitors the click event outside of graph to make the drop down close.
    useEffect(() => {
        const socket = io(UrlsAi?.baseurl, {
            query: {
              user_id: account?.username
            }
          });
        socket.on("online_users", (data) => {
          
            setState(prevState => ({ ...prevState, activeUsers: data.count }));            
          });        
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    
    //SQ_HUb_5.1 to 5.6 Handles the sorting based on Most or Less Used prompts
    const handleMostOrLeastUsedSort = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newPromptInfo = [...state.promptInfo];
        if (e.target.value === "Most Used Prompt") {
            newPromptInfo.sort((a, b) => b.count - a.count);
        } else if (e.target.value === "Less Used Prompt") {
            newPromptInfo.sort((a, b) => a.count - b.count);
        }
        setState(prevState => ({ ...prevState, promptInfo: newPromptInfo,mostOrLeastUsedPrompt:e.target.value }));
    };

    //SQ_HUB_6.0 to 6.3 Used to change the state when the option changes
    const handleSelectChange = (selected: any) => {
        setState(prevState => ({ ...prevState, selectedOptions: selected }));
    };
    //SQ_HUB_7.1 to 7.8 handles the flow of expanding view of fixed response
    const handleFixedResExpand = async (promptId:string) => {
        try {
            setState(prevState => ({ ...prevState, isLoad: true }));
            let fixedResponseLoadDays = fixedResponseLoad;
            let fixedResResponse: any = {};
            let popUpOutput:any = [];
          
            do {
                fixedResResponse = await fetchFixedResponseData({
                    expanded: "True",
                    promptId: promptId,
                    days: fixedResponseLoadDays
                });

                popUpOutput = fixedResResponse?.data?.data?.popUpOutput;

                if (popUpOutput.length === 0) {
                    fixedResponseLoadDays += 2;
                }

            } while (popUpOutput.length === 0 && fixedResponseLoadDays < 30); // limiting the max increment to avoid infinite loop

            setState(prevState => ({
                ...prevState,
                fixedResponseExpandData:  popUpOutput as any,
                isLoad: false
            }));
            const totalFixedCases = popUpOutput?.reduce((sum:any, ele:any) => {
                return sum + (ele.total_case || 0);
            }, 0); 
            limitVal=totalFixedCases
            limitVal=limitVal
            setLimitVal(totalFixedCases)


        } catch (error) {
            console.error("Error fetching fixed response data:", error);
            setState(prevState => ({ ...prevState, isLoad: false }));
        }
    };
    //SQ_10.0 to 10.3 Handles the navigation to constAnalysis table
    const handleNavToCostTable = () => {
        navigate("/cost-analysis");
    };
    //SQ_HUB_10.4 to 10.7 Handles the navigation to asami usage table
    const navToAsamiUsageTable = () => {
        navigate("/asami-usage-grid");
    };

    //SQ_10.8 to 11.2 Defines the header for prompt usage table in HUB
    const headers = useMemo(() => [
        {
            key: 'promptName',
            label: 'Prompt Name',
            render: (row: any) => (
              <Tooltip text={row.promptName}>
                <span className="d-inline-block text-truncate" style={{ maxWidth: '200px' }}>
                  {row.promptName.length > 15 ? `${row.promptName.substring(0, 15)}...` : row.promptName}
                </span>
              </Tooltip>
            )
          },
        { key: 'practiceName', label: 'Practice', sortable: false },
        { key: 'count', label: 'Count', sortable: false },
    ], [])
    //SQ_HUB_12.0 to 12.6 Renders the prompt usage table and handles intearctions 
    const PromptTable = () => {
        const { setData } = useTableContext();

        useEffect(() => {
            const filteredData = state?.promptInfo?.filter(ele =>
                state?.selectedPractice?.length === 0 || state?.selectedPractice?.includes(ele.practiceName)
            );
            setData(filteredData);
        }, [state.promptInfo, state.selectedPractice]);

        return (
            <ReusableTable
                headers={headers}
                onRowClick={(row) => { }}
            />
        );
    };

    const customDropdownStyles = {
        control: (provided:any) => ({
          ...provided,
          minHeight: '38px',
          background: 'var(--bg-color, #ffffff)',
          borderColor: 'var(--border-color, #cccccc)',
          boxShadow: 'none',
          '&:hover': {
            borderColor: 'var(--hover-border-color, #999999)',
          },
        }),
        valueContainer: (provided:any) => ({
          ...provided,
          maxWidth: '100%',
          overflow: 'auto',
          flexWrap: 'nowrap',
          '::-webkit-scrollbar': {
            height: '4px',
          },
          '::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '::-webkit-scrollbar-thumb': {
            background: 'var(--scroll-thumb-color, #888888)',
            borderRadius: '2px',
          },
          '::-webkit-scrollbar-thumb:hover': {
            background: 'var(--scroll-thumb-hover-color, #555555)',
          },
          scrollbarWidth: 'thin',
          scrollbarColor: 'var(--scroll-thumb-color, #888888) transparent',
        }),
        multiValue: (provided:any) => ({
          ...provided,
          background: 'var(--multi-value-bg, #E8E8E8)',
          flexShrink: 0,
        }),
        multiValueLabel: (provided:any) => ({
          ...provided,
          color: 'var(--multi-value-text-color, #333333)',
        }),
        multiValueRemove: (provided:any) => ({
          ...provided,
          color: 'var(--multi-value-remove-color, #999999)',
          ':hover': {
            background: 'var(--multi-value-remove-hover-bg, #CCCCCC)',
            color: 'var(--multi-value-remove-hover-color, #333333)',
          },
        }),
        menu: (provided:any) => ({
          ...provided,
          background: 'var(--bg-color, #ffffff)',
          zIndex: 1000,
        }),
        menuList: (provided:any, state:any) => ({
          ...provided,
          maxHeight: '150px',
          overflowY: state.options.length > 4 ? 'auto' : 'hidden',
          overflowX: 'hidden',
          '::-webkit-scrollbar': {
            width: '6px',
          },
          '::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '::-webkit-scrollbar-thumb': {
            background: 'var(--scroll-thumb-color, #888888)',
            borderRadius: '3px',
          },
          '::-webkit-scrollbar-thumb:hover': {
            background: 'var(--scroll-thumb-hover-color, #555555)',
          },
          scrollbarWidth: 'thin',
          scrollbarColor: 'var(--scroll-thumb-color, #888888) transparent',
        }),
        option: (provided:any, state:any) => ({
          ...provided,
          background: state.isSelected 
            ? 'var(--selected-bg-color, #2684FF)' 
            : state.isFocused 
              ? 'var(--focus-bg-color, #DEEBFF)'
              : 'var(--bg-color, #ffffff)',
          color: state.isSelected 
            ? 'var(--selected-text-color, #ffffff)' 
            : 'var(--text-color, #333333)',
          cursor: 'pointer',
        }),
      };
            
    

    return (
        <div>
            {!state.isLoading ? (
               <Loader isLoading={!state.isLoading} navOpening = {navOpen} />
            ) : (
                
                <div className={navOpen=="Close" ? "container-fluid second-component-hub-nav-close" : "container-fluid second-component-hub"}>
                    <RowComponent>
                        <ColumnComponent className="col-md-12">
                            <div className="p-3 mt-3 mb-3 border-radius-8">
                                <h1 className="font-bold font-20 mb-4 text-color-1">Hub</h1>
                                <RowComponent>
                                    <ColumnComponent className="col-xl-8 col-lg-12 col-sm-12 col-md-12 mb-2 mt-0 px-0">
                                        <div className="h-100 border-0 px-2">
                                            <RowComponent>
                                                <ColumnComponent className="col-md-3 mb-3">
                                                    <Card className="p-3">
                                                        <p className="font-medium font-16 mb-2 dark-black-color text-color-1" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Total Users</p>
                                                        <p className="font-bold font-18 primary-color mb-0">{state.totalUsers}</p>
                                                    </Card>
                                                </ColumnComponent>
                                                <ColumnComponent className="col-md-3 mb-3">
                                                    <Card className="p-3">
                                                        <p className="font-medium font-16 mb-2 dark-black-color text-color-1" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Active Users</p>
                                                        <p className="font-bold font-18 primary-color mb-0">{state.activeUsers}</p>
                                                    </Card>
                                                </ColumnComponent>
                                                <ColumnComponent className="col-md-3 mb-3">
                                                    <Card className="p-3">
                                                        <p className="font-medium font-16 mb-2 dark-black-color text-color-1" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Monthly Cost</p>
                                                        <p className="font-bold font-18 primary-color mb-0">${state.monthlyCost}</p>
                                                    </Card>
                                                </ColumnComponent>
                                                <ColumnComponent className="col-md-3 mb-3">
                                                    <Card className="p-3">
                                                        <p className="font-medium font-16 mb-2 dark-black-color text-color-1" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Number of Fixes</p>
                                                        <p className="font-bold font-18 primary-color mb-0">{state.fixedResponsesCount}</p>
                                                    </Card>
                                                </ColumnComponent>
                                            </RowComponent>



                                            <RowComponent>
                                                <ColumnComponent className="col-xl-12 mb-3">
                                                    <Card className="h-100 p-3" style={{ minHeight: '100%' }}>
                                                        <div className="d-md-flex d-block justify-content-between align-items-center mb-3">
                                                            <h3 className="font-semibold font-16 text-color-1">Asami Usage (Based On Chats)</h3>
                                                            <div className="align-items-center d-md-flex d-block">
                                                                
                                                                    <a
                                                                        className="text-decoration-none link-color font-regular font-13 cursor-pointer"
                                                                        onClick={navToAsamiUsageTable}
                                                                    >
                                                                        See All
                                                                    </a>
                                                                
                                                            </div>
                                                        </div>
                                                        <div className="asami-usage-edit">
                                                            <div>
                                                                <BarGraphComp AsamiUsageData={state.asamiGraphData} themeMode={theme.palette.mode} />
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </ColumnComponent>
                                                <ColumnComponent className="col-xl-12">
                                                    <Card className="h-100 p-3" style={{ minHeight: "100%" }}>
                                                        <div className="d-md-flex d-block justify-content-between align-items-center mb-3">
                                                            <h3 className="font-semibold font-16 text-color-1">Cost Analysis</h3>
                                                            <div className="align-items-center d-md-flex d-block">
                                                                <div className="d-flex mb-md-0 mb-3">
                                                                    <div className="usage-cost-dropdown-asami-usage">
                                                                        <DropDownProvider isMulti={false}>
                                                                            <DropDownComponent
                                                                                label=""
                                                                                options={[
                                                                                    { value: 'Practice', label: 'Practice' },
                                                                                    { value: 'Individual', label: 'Individual' }
                                                                                ]}
                                                                                value={{ value: state.costAnalysisDropDown || 'Practice', label: state.costAnalysisDropDown || 'Practice' }}
                                                                                onChange={(selectedOption: any) => {
                                                                                    const value = selectedOption ? (selectedOption as Option).value : 'Practice';
                                                                                    setState(prevState => ({ ...prevState, costAnalysisDropDown: value }));
                                                                                }}
                                                                            />
                                                                        </DropDownProvider>
                                                                    </div>
                                                                </div>
                                                                <a className="text-decoration-none link-color font-regular font-13 cursor-pointer" onClick={handleNavToCostTable}>See All</a>
                                                            </div>
                                                        </div>
                                                        <div className="custom-scroll cust-dashboard-widget-scroll">
                                                            <div className="asami-usage-scroll">
                                                                <LineBarGraph costGraph={state.costAnalysisGraphData} />
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </ColumnComponent>
                                            </RowComponent>
                                        </div>
                                    </ColumnComponent>




























                                    <ColumnComponent className="col-xl-4 col-lg-12 col-sm-12 col-md-12 mb-2">
                                        <RowComponent>
                                            <ColumnComponent className="col-md-12 mb-3">
                                                <Card className="p-3 h-100">
                                                    <p className="font-semibold font-16 text-color-1">Feedback</p>
                                                    <RowComponent>
                                                        <ColumnComponent className="col-sm-12 col-md-4">
                                                            <div className="d-flex align-items-center justify-content-between mb-3 mb-md-0 flex-column col-md-0">
                                                                <span><img src="images-new/happy-emoji.svg" alt="Happy" className="mb-2" /></span>
                                                                <div><span className="font-regular font-14 color-grey emoji-status" style={{marginRight:'0.5rem'}}>Happy</span><span className="font-bold font-14 emoji-percentage text-color-1" style={{marginRight:'0.5rem'}}>{state.happyMeter=='NaN'?0:state.happyMeter}%</span></div>
                                                            </div>
                                                        </ColumnComponent>
                                                        <ColumnComponent className="col-sm-12 col-md-4">
                                                            <div className="d-flex align-items-center justify-content-between mb-3 mb-md-0 flex-column col-md-0">
                                                                <span><img src="images-new/neutral-emoji.svg" alt="neutral" className="feedback-img mb-2" /></span>
                                                                <div>
                                                                    <span className="font-regular font-14 color-grey me-1 emoji-status">Neutral</span><span className="font-bold font-14 emoji-percentage text-color-1">{state.neutralMeter=='NaN'?0:state.neutralMeter}%</span></div>
                                                            </div>
                                                        </ColumnComponent>
                                                        <ColumnComponent className="col-sm-12 col-md-4">
                                                            <div className="d-flex align-items-center justify-content-between mb-3 mb-md-0 flex-column col-md-0">
                                                                <span><img src="images-new/sad-emoji.svg" alt="Sad" className="feedback-img mb-2" /></span>
                                                                <div>
                                                                    <span className="font-regular font-14 color-grey me-1 emoji-status">Sad</span><span className="font-bold font-14 emoji-percentage text-color-1">{state.sadMeter=='NaN'?0:state.sadMeter}%</span></div>
                                                            </div>
                                                        </ColumnComponent>
                                                    </RowComponent>
                                                </Card>
                                            </ColumnComponent>
                                            <ColumnComponent className="col-md-12 mb-3">
                                                <Card className="p-3 h-100 custom-dashboard-section">
                                                    <div className="d-md-flex d-block justify-content-between align-items-center mb-3 ">
                                                        <h3 className="font-semibold font-16 text-color-1">Visitors</h3>
                                                        <div className="align-items-center d-md-flex d-block">
                                                            <div className="d-flex mb-md-0 mb-3">
                                                                <DropDownProvider isMulti={false}>
                                                                    <DropDownComponent
                                                                        label=""
                                                                        options={[
                                                                            { value: 'Monthly', label: 'Monthly' },
                                                                            { value: 'Weekly', label: 'Weekly' },
                                                                            { value: 'Yearly', label: 'Yearly' }
                                                                        ]}
                                                                        value={{ value: state.visitorsDropDown || 'Monthly', label: state.visitorsDropDown || 'Monthly' }}
                                                                        onChange={(selectedOption: any) => {
                                                                            const value = selectedOption ? (selectedOption as Option).value : 'Monthly';
                                                                            setState(prevState => ({ ...prevState, visitorsDropDown: value }));
                                                                        }}
                                                                    />
                                                                </DropDownProvider>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="cust-visitor-widget-scroll visitor-height">
                                                    <LineGraph graphData={state.visitorsData as any} timePeriod={state.visitorsDropDown as any} />
                                                    </div>
                                                </Card>
                                            </ColumnComponent>
                                            <ColumnComponent className="col-md-12 mb-3">
                                                <Card className="h-100" style={navOpen === "Close" ? { minHeight: '100%' } : { minHeight: '100%' }}>
                                                    <div className="p-3 py-2 d-md-flex d-block justify-content-between align-items-center">
                                                        <div className="dropdown me-3" style={{ width: '40%', minWidth: '175px', maxWidth: '300px' }}>
                                                            <DropDownProvider isMulti={false}>
                                                                <DropDownComponent
                                                                    label=""
                                                                    options={[
                                                                        { value: 'Most Used Prompt', label: 'Most Used Prompt' },
                                                                        { value: 'Less Used Prompt', label: 'Less Used Prompt' }
                                                                    ]}
                                                                    value={{ value: state.mostOrLeastUsedPrompt||'Most Used Prompt' , label: state.mostOrLeastUsedPrompt ||'Most Used Prompt'}}
                                                                    onChange={(selectedOption: any) => {
                                                                        const value = selectedOption ? (selectedOption as Option).value : 'Most Used Prompt';
                                                                        handleMostOrLeastUsedSort({ target: { value } } as any);
                                                                    }}
                                                                />
                                                            </DropDownProvider>
                                                        </div>
                                                        <div className="align-items-center d-md-flex d-block theme-focus" style={{ width: '60%', minWidth:'200px', maxWidth: '400px' }}>
                                                            <div className="d-flex mb-md-0 mb-3 search-fix-hub-new theme-focus w-100">
                                                                <div style={{width:'68%'}}>
                                                                    <DropDownProvider isMulti={true}>
                                                                        <DropDownComponent
                                                                            label=""
                                                                            options={state.options}
                                                                            value={state.selectedOptions}
                                                                            onChange={handleSelectChange}
                                                                            customStylesOverride={customDropdownStyles}
                                                                        />
                                                                        </DropDownProvider>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive dashboard-table-scroll" style={navOpen === "Close" ? { minHeight: '68%' } : { minHeight: '70%' }}>
                                                        <TableProvider initialData={[]}>
                                                            <PromptTable />
                                                        </TableProvider>
                                                    </div>
                                                </Card>
                                            </ColumnComponent>
                                        </RowComponent>
                                    </ColumnComponent>


                                    
                                </RowComponent>
                                <RowComponent>
                                    <ColumnComponent className="col-md-12 mb-3">
                                        <Card className="h-100 p-3 fixed-reponse">
                                            <h3 className="font-semibold font-16 text-color-1">Fixed Response</h3>
                                            <p className="mb-0 color-grey-v5 font-semibold font-14 text-color-7 mt-3">Prompt</p>
                                            <div className="cust-prompt-scroll" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                                <ul className="list-unstyled">
                                                    {state.fixedResponseData?.map((ele, index) => (
                                                        <li key={index} className="d-flex justify-content-between align-items-center custom-border-bottom-2 p-3 li-width">
                                                            <p className="font-medium font-14 text-color-1 mb-0 text-decoration-none text-nowrap">
                                                                {ele.promptName}
                                                            </p>
                                                            <div className="d-flex align-items-center">
                                                                <span className="cust-prompt-badge d-flex align-items-center p-2 me-5 text-nowrap">
                                                                    <img src="images-new/clock-icon.svg" className="me-2" alt="clock" />
                                                                    <span className="font-10 font-medium text-color-1"> Number of time Regenerated: </span>
                                                                    <span className="font-bold font-12 primary-color ms-1"> {ele.promptCount}</span>
                                                                </span>
                                                                <span>
                                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#Helpus-popup" onClick={() => {
                                                                        setState(prevState => ({ ...prevState, fixedPromptName: ele.promptName, fixedExpand: "1" }));
                                                                        handleFixedResExpand(ele.promptId);
                                                                        setTotalCount(ele.promptCount)
                                                                        totalCount= ele.promptCount
                                                                    }}>
                                                                        <img src="images-new/eye-img.svg" alt="view" />
                                                                    </a>
                                                                </span>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </Card>
                                    </ColumnComponent>
                                </RowComponent>
                            </div>
                        </ColumnComponent>
                    </RowComponent>
                </div>

          
           )}

            {/* Fixed Response Popup */}
            <div className='modal fade' id="Helpus-popup" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered fixed-response-popup-width border-0">
                    <div className="modal-content p-3 border-0 bg-color" style={{ height: '634px' }}>
                        <div className="modal-header border-0 pt-2 pb-0 mb-3">
                            <h5 className="modal-title text-color-1 font-22 font-bold">Fixed Response</h5>
                            <button type="button"    className="btn-close" data-bs-dismiss="modal" aria-label="Close" 
                            onClick={() => {
                                setState(prevState => ({ ...prevState, fixedResponseExpandData: [] }))
                                fixedResponseLoad = 2
                        }}></button>
                        </div>
                        <div className="modal-body popup-scrl pt-2">
                            <div>
                                <div>
                                    <p className="font-semibold font-20 text-color-4">{state.fixedPromptName}</p>
                                    {state.isLoad ? (
                                        <div style={{
                                            textAlign: 'center',
                                            flex: 1,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            marginTop: '20%'
                                        }}>
                                            <ClipLoader
                                                color={'#865BE7'}
                                                loading={state.isLoad}
                                                size={50}
                                            />
                                        </div>
                                    ) : (
                                        <>
                                        <ul className="list-unstyled fixed-response-popup-scroll">
                                            {state.fixedResponseExpandData?.map((ele, index) => (
                                                <li key={index} className="cust-response-list shadow-sm p-3 mb-3">
                                                    <p className="font-semibold font-14 text-color-6 mb-2" style={{ display: 'inline-block' }}>Reason</p>
                                                    <p className="font-semibold font-14 text-color-6 mb-2 fixes_fixed_res">Fixes: {ele.total_case}</p>
                                                    <p className="font-regular font-14 text-color-6">{ele.responses}</p>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <img src={ele?.photo ? `data:image/jpeg;base64,${ele?.photo}` : "images/Default/default-profile-image.png"} alt="Profile" className="profile-img-sm me-2" />
                                                            <span className="font-semibold font-16 text-color-9">{ele.userName}</span>
                                                        </div>
                                                        <a href={`/chat?chatID=${ele.id}`} target="_blank" className="text-decoration-none link-color font-regular font-13"
                                                            rel="noopener noreferrer"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                localStorage.setItem("ViewChat", "True");
                                                                window.open(`/chat?chatID=${ele.id}&type=Prompt`, '_blank');
                                                            }}>
                                                            View Chat
                                                        </a>
                                                    </div>
                                                </li>
                                            ))}
                                        {limitVal<totalCount &&<button className="font-regular font-13"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setFixedResponseLoad(fixedResponseLoad+2)
                                                const matchingPrompt = state.promptInfo.find(prompt => prompt.promptName === state.fixedPromptName);
                                               
                                                fixedResponseLoad = fixedResponseLoad+2
                                                handleFixedResExpand(matchingPrompt.promptId)
                                                
                                            }}>
                                            <span className='link-color'>Load More</span>
                                        </button>}
                                        </ul>
                                 
                                    </>

                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );




}

export default Dashboard