
import React from 'react';
import { Draggable } from "@hello-pangea/dnd";
import ButtonComponent from './Button';
import { DraggableItemProps } from '../Interfaces/Types';

const DraggableItem: React.FC<DraggableItemProps> = ({
  item,
  index,
  isDragDisabled,
  isHidden,
  HandlePromptHistory,
  onEdit,
  onCopy,
  onDelete
}) => {
  return (
    <Draggable draggableId={`item-${index}`} index={index} isDragDisabled={isDragDisabled}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className="row">
            <div className="col-md-12 col-sm-12 mb-3">
              <div className="bg-color-7 p-3" hidden={isHidden}>
                <div className="text-center pb-3">
               
                </div>
                <div className="d-flex justify-content-between">
                  <div>
                    {!item.choiceName?.some((choice) => choice !== "") && (
                      <div className="form-check mb-3">
                        <label className="form-label label-sty mb-3 text-color-2">
                          {index + 1}. {item.labelName || "Code"}
                        </label>
                      </div>
                    )}
                    <div className="form-check mb-3">
                      {item.choiceName?.some((choice) => choice !== "") && (
                        <>
                          <label className="form-label label-sty mb-3 text-color-2">
                            {index + 1}. {item.labelName || "Code snippet"}
                          </label>
                          <div className="form-check mb-3">
                            {item.choiceName &&
                              item.choiceName.some((value) => value !== null && value !== "") && (
                                <>
                                  {item.choiceName
                                    .filter((value) => value !== null && value !== "")
                                    .map((trimmedValue, choiceIndex) => (
                                      <div key={choiceIndex} className="form-check mb-3">
                                        <input
                                          className={`form-check-input custom-toggle-input 6 theme-focus${item.inputTypeRefCode === "RB"?" width-20":""}`}
                                          disabled
                                          type={
                                            item.inputTypeRefCode === "RB"
                                              ? "radio"
                                              : item.inputTypeRefCode === "CB"
                                              ? "checkbox"
                                              : "hidden"
                                          }
                                          name="flexRadioDefault"
                                          id={`react-js-${choiceIndex}`}
                                        />
                                        <label
                                          className="form-check-label text-color-4"
                                          htmlFor={`react-js-${choiceIndex}`}
                                        >
                                          {trimmedValue}
                                        </label>
                                      </div>
                                    ))}
                                </>
                              )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div>
                    {HandlePromptHistory && index !== 0 && (
                      <ButtonComponent
                        buttonType="icon"
                        className="btn p-0 shadow-none"
                        iconSrc="images-new/edit-icons.svg"
                        altText="edit-icon"
                        onClick={() => onEdit(item, index)}
                         style={{ width: '25px', height: '25px' }} 
                      />
                    )}            
                    {HandlePromptHistory && (
                      <ButtonComponent
                        buttonType="icon"
                        className="btn p-0 shadow-none"
                        iconSrc="images/Default/delete-icon.svg"
                        altText="delete-icon"
                        onClick={() => onDelete(index)}
                        disabled={index === 0}
                        style={{ display: index === 0 ? 'none' : "", width: '25px', height: '25px' }} // Add size constraint
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default DraggableItem;