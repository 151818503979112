import React, { useEffect, useState, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import {  getTerminal } from '../../../Service/Valyria/API';
import Loader from '../../../Reusable Components/Loader';

interface TerminalProps {
  Data: any; 
}

const Terminal: React.FC<TerminalProps> = ({ Data }) => {
  const [terminalData, setTerminalData] = useState(Data);
  const [textColor, setTextColor] = useState<string>('#000000');
  const [backgroundColor, setBackgroundColor] = useState<string>('#ffffff');
  const terminalRef = useRef<HTMLDivElement>(null);
  const [isLoading,setIsLoading] = useState<boolean>(true)
  const theme = useTheme();
  
  async function fetchTerminalData(){
    return await getTerminal({
      "project_id":sessionStorage.getItem('project_id'),
       "user_id": sessionStorage.getItem('user_id')
  })
  }

 
  useEffect(() => {
    if (terminalData.data?.length === 0) {
    setIsLoading(true)
    Promise.resolve(fetchTerminalData()).then((data)=>{
      setTerminalData(data)
      setIsLoading(false)
    })
  }
  else{
    setIsLoading(false)
  }
  }, []);

    useEffect(() => {
      const updateColors = () => {
        if (terminalRef.current) {
          const card = terminalRef.current.closest('.custom-dashboard-section');
          if (card) {
            const bgColor = window.getComputedStyle(card).backgroundColor;
            setBackgroundColor(bgColor);
            const rgb = bgColor.match(/\d+/g);
            if (rgb) {
              const brightness = (parseInt(rgb[0]) * 299 + parseInt(rgb[1]) * 587 + parseInt(rgb[2]) * 114) / 1000;
              setTextColor(brightness > 128 ? '#000000' : '#ffffff');
            }
          }
        }
      };
  
      const resizeObserver = new ResizeObserver(updateColors);
  
      if (terminalRef.current) {
        const card = terminalRef.current.closest('.custom-dashboard-section');
        if (card) {
          resizeObserver.observe(card);
        }
      }
  
      updateColors();
  
      return () => resizeObserver.disconnect();
    }, [theme]);

  return (
    <div className="toggle-right-panel gap-2 custom-dashboard-section" ref={terminalRef}>
      <div className="custom-tab-content-section">
        <div className="custom-right-side-bar-section-contents" style={{ backgroundColor, color: textColor }}>
          <div className="custom-right-side-bar-section-title">
            <h2 className="font-semibold font-14" style={{ color: textColor }}>Terminal</h2>
          </div>
          {isLoading ? (

                              <div className="card-shimmer">
                                <div className="shimmer-title"></div>
                                <div className="shimmer-image"></div>
                                <div className="shimmer-text"></div>
                                <div className="shimmer-text"></div>
                                <div className="shimmer-title"></div>
                                <div className="shimmer-image"></div>
                                <div className="shimmer-wrapper">
                                    <div className="shimmer"></div>
                                </div>
                              </div>
                ) : (
          <div className="custom-right-side-bar-section-details chat-box-terminal-container">
            {terminalData?.data?.length > 0 ? (
              terminalData?.data?.map((item: any, index: number) => (
                <React.Fragment key={index}>
                  {item.commands && (
                    <p className="font-14 font-regular" style={{ color: textColor }}>$ {item.commands}</p>
                  )}
                  {/* {item.results && (
                    <p className={`font-14 font-regular ${item.commands ? "mt-2" : "mt-3"}`} style={{ color: textColor }}>
                      {item.results}
                    </p>
                  )} */}
                </React.Fragment>
              ))
            ) : (

              <div className="custom-right-side-bar-section-details norecords">
                  <img src="../images/no-records-graphics.svg" alt="graphic" width="500px" height={"250px"}/>
                  <p className="mb-0 font-medium font-14 mt-2 text-color-9" style={{"textAlign":"center"}}>No Data Found</p>
              </div>)
            }
          </div>)}
        </div>
      </div>
    </div>
  );
};

export default Terminal;