import React, { useState, useContext, useEffect } from 'react';
import ReactSlider from 'react-slider';
import { StylesConfig } from 'react-select';
import { getBindParams } from '../Service/API';
import { ConfigContext, ConfigType } from '../Utility/ConfigContext';
import DropDownComponent from './DropDown';
import Tooltip from './ToolTip';
 
interface ModelOption {
  value: string;
  label: string;
  model: any;
}
 
interface ConfigurationButtonProps {
  isImage: boolean;
}
 
const ConfigurationButton: React.FC<ConfigurationButtonProps> = ({ isImage }) => {
  const context = useContext(ConfigContext);
 
  if (!context) {
    throw new Error("ConfigurationButton must be used within a ConfigProvider");
  }
 
  const { config, setConfig } = context;
 
  const [showConfigPopUp, setShowConfigPopUp] = useState(false);
  const [maxResponse, setMaxResponse] = useState<number>(config.maxResponse);
  const [temperature, setTemperature] = useState<number>(config.temperature);
  const [topP, setTopP] = useState<number>(config.topP);
  const [frequencyPenalty, setFrequencyPenalty] = useState<number>(config.frequencyPenalty);
  const [presencePenalty, setPresencePenalty] = useState<number>(config.presencePenalty);
  const [modelOptions, setModelOptions] = useState<ModelOption[]>([]);
  const [selectedModel, setSelectedModel] = useState<ModelOption | null>(null);
  const [defaultModel, setDefaultModel] = useState<ModelOption | null>(null);
  const parameterDescriptions = {
    maxResponse: "Controls the maximum number of words or tokens the AI will generate in its response. A higher value allows for longer answers, while a lower value keeps responses concise.",
    temperature: "Affects the randomness of the AI's responses. Lower values (closer to 0) make the output more focused and deterministic, while higher values (closer to 1) make it more creative and diverse.",
    topP: "Another way to control response randomness. It sets a probability threshold for word selection. Lower values make the output more focused, higher values allow for more diversity.",
    frequencyPenalty: "Discourages the AI from repeating the same words or phrases too often. Higher values promote more varied language in the response.",
    presencePenalty: "Encourages the AI to introduce new topics or ideas. Higher values make the AI more likely to explore different subjects in its response."
  };
 
 
  useEffect(() => {
    fetchBindParams();
  }, []);

  useEffect(() => {
    const handleTooltipPosition = (e: MouseEvent) => {
      const tooltips = document.querySelectorAll('.model-option-tooltip');
      tooltips.forEach((tooltip: any) => {
        const rect = tooltip.getBoundingClientRect();
        const spaceRight = window.innerWidth - e.clientX;
        const spaceBottom = window.innerHeight - e.clientY;
 
        let left = e.clientX + 10;
        let top = e.clientY + 10;
 
        if (spaceRight < rect.width) {
          left = e.clientX - rect.width - 10;
        }
 
        if (spaceBottom < rect.height) {
          top = e.clientY - rect.height - 10;
        }
 
        tooltip.style.left = `${left}px`;
        tooltip.style.top = `${top}px`;
      });
    };
 
    document.addEventListener('mousemove', handleTooltipPosition);
 
    return () => {
      document.removeEventListener('mousemove', handleTooltipPosition);
    };
  }, []);
 
  const fetchBindParams = async () => {
    try {
      const response = await getBindParams({});
      const models = response.data.model_config_result;
      const defaultModelInfo = response.data.default_model_result[0];
 
      const options = models.map((model: any) => ({
        value: model.modelConfigurationID,
        label: model.displayName,
        model: model,
      }));
 
      setModelOptions(options);
 
      const defaultModel = options.find((option: ModelOption) => option.value === defaultModelInfo.default_model_id);
      if (defaultModel) {
        setDefaultModel(defaultModel);
        if (!isImage) {
          setSelectedModel(defaultModel);
          setHyperparametersFromModel(defaultModel.model);
          setConfig(prevConfig => ({
            ...prevConfig,
            selectedModelID: defaultModel.value
          }));
        }
      } else {
        console.warn(
          `Default model "${defaultModelInfo.default_model_name}" not found in the configuration`
        );
      }
    } catch (error) {
      console.error('Error fetching bind params:', error);
    }
  };
 
 
  const setHyperparametersFromModel = (model: any) => {
    setMaxResponse(parseInt(model.MaxTokens));
    setTemperature(parseFloat(model.Temperature));
    setTopP(parseFloat(model.TopP));
    setFrequencyPenalty(parseFloat(model.FrequencyPenalty));
    setPresencePenalty(parseFloat(model.PresencePenalty));
  };
 
  const handlePopUp = () => {
    setShowConfigPopUp((prev) => !prev);
  };
 
  const handleReset = () => {
    if (defaultModel) {
      setSelectedModel(defaultModel);
      setHyperparametersFromModel(defaultModel.model);
    }
  };
 
  const handleCancel = () => {
    handleReset();
    setShowConfigPopUp(false);
  };
 
  const handleApply = () => {
    setConfig({
      maxResponse,
      temperature,
      topP,
      frequencyPenalty,
      presencePenalty,
      selectedModelID: selectedModel ? selectedModel.value : '',
    });
    setShowConfigPopUp(false);
  };
 
  const handleModelChange = (selectedOption: ModelOption | null) => {
    setSelectedModel(selectedOption);
    if (selectedOption) {
      setHyperparametersFromModel(selectedOption.model);
    }
  };
 
  const handleSliderChange = (setter: React.Dispatch<React.SetStateAction<number>>) => (
    value: number
  ) => {
    setter(value);
  };
 
  const isBedrockOrClaudeM = () => {
    if (!selectedModel) return false;
    return (
      selectedModel.model.modelName.toLowerCase().includes('bedrock') ||
      selectedModel.model.modelName.toLowerCase().includes('claude')
    );
  };
 
  const renderModelOption = (option: ModelOption) => (
    <div className="model-option-container">
      <div className="model-option-content">
        {option.label}
      </div>
      <div className="model-option-tooltip">
        {option.model.modelDescription}
      </div>
    </div>
  );
   
 
  const customDropdownStyles: StylesConfig<ModelOption, false> = {
    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: 'var(--select-bg, #fff)',
      borderColor: 'var(--select-border-color, #ccc)',
      width:"100%"
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 1010,
      width: '100%', 
      maxHeight: '200px',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '200px', 
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? 'var(--select-option-focus-bg, #f0f0f0)'
        : 'var(--select-bg, #fff)',
      color: 'var(--select-color, #333)',
      ':active': {
        backgroundColor: 'var(--select-option-active-bg, #e0e0e0)',
      },
      padding: 0, 
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'var(--select-color, #333)',
    }),
  };
 
 
return (
  <div className="btn-group custom-settings custom-dashboard-section chat-configure-position" id="chat-configure-parent">
    <button
      className="btn btn-light border rounded-2 configurations border-color-10 bg-color-10 custom-dropdown-li py-2"
      onClick={handlePopUp}>
      <img className='config-icon' src="images/settings.svg" alt="setting" />
    </button>
 
    {showConfigPopUp && (
      <div
        className="dropdown-menu rounded-3 custom-dpd cust-right p-4"
        id='chat-configure-child'
        style={{
          position: 'absolute',
          top: '100%',
          right: 0,
          width: '550px',
          zIndex: 1005,
          display: 'block',
          maxHeight: '80vh',
          overflowY: 'auto',
        }}>
        <div className="row row-gap-32px">
          <div className="col-md-12">
            <div className="d-flex justify-content-between">
              <h4 className="font-16 text-color-1 font-medium">Configurations</h4>
              <img
                src="images/Close-icon.svg"
                alt="close"
                className="cursor-pointer filter-icons"
                width="16"
                onClick={()=>setShowConfigPopUp(false)}
              />
            </div>
          </div>
 
          <div className="col-md-6">
          <DropDownComponent
            label="Choose Model"
            options={modelOptions}
            value={selectedModel}
            onChange={handleModelChange}
            renderOption={renderModelOption}
            customStylesOverride={customDropdownStyles}
          />
 
 
          </div>
          <div className="col-md-6">
          <Tooltip text={parameterDescriptions.maxResponse}>
            <label
              htmlFor="mx-rsponse"
              className="form-label label-color font-15 font-semibold text-color-2 mb-2 dec-hp-config">
              Max Response
            </label>
            </Tooltip>
            <div className="d-flex flex-row align-items-center gap-3">
              <ReactSlider
                className="slider"
                thumbClassName="thumb"
                trackClassName="track"
                value={maxResponse}
                onChange={handleSliderChange(setMaxResponse)}
                min={1}
                max={selectedModel ? parseInt(selectedModel.model.MaxTokens) : 4096}
              />
              <input
                type="number"
                className="form-control input-field shadow-none p-2 font-14 font-regular border-radius-3 bg-transparent text-color-2 rounded-3"
                value={maxResponse}
                onChange={(e) => setMaxResponse(parseInt(e.target.value) || 0)}
                min={1}
                max={selectedModel ? parseInt(selectedModel.model.MaxTokens) : 4096}
                style={{ width: '75px' }}
              />
            </div>
          </div>
 
          <div className="col-md-6">
          <Tooltip text={parameterDescriptions.temperature}>
            <label
              htmlFor="Temperature_"
              className="form-label font-15 font-semibold text-color-2 mb-2 dec-hp-config margin-left">
              Temperature
            </label>
            </Tooltip>
            <div className="d-flex flex-row align-items-center gap-3">
              <ReactSlider
                className="slider"
                thumbClassName="thumb"
                trackClassName="track"
                value={temperature}
                onChange={handleSliderChange(setTemperature)}
                min={0}
                max={1}
                step={0.01}
              />
              <input
                type="number"
                className="form-control input-field shadow-none p-2 font-14 font-regular border-radius-3 bg-transparent text-color-2 rounded-3"
                value={temperature}
                onChange={(e) => setTemperature(Number(e.target.value)<=1?parseFloat(e.target.value):temperature || 0)}
                min={0}
                max={1}
                step={0.01}
                style={{ width: '75px' }}
              />
            </div>
          </div>
 
          {!isBedrockOrClaudeM() && (
            <>
              <div className="col-md-6">
              <Tooltip text={parameterDescriptions.topP}>
                <label
                  htmlFor="Top_P"
                  className="form-label font-15 font-semibold text-color-2 mb-2 dec-hp-config">
                  Top P
                </label>
                </Tooltip>
                <div className="d-flex flex-row align-items-center gap-3">
                  <ReactSlider
                    className="slider"
                    thumbClassName="thumb"
                    trackClassName="track"
                    value={topP}
                    onChange={handleSliderChange(setTopP)}
                    min={0}
                    max={1}
                    step={0.01}
                  />
                  <input
                    type="number"
                    className="form-control input-field shadow-none p-2 font-14 font-regular border-radius-3 bg-transparent text-color-2 rounded-3"
                    value={topP}
                    onChange={(e) => setTopP(Number(e.target.value)<=1?parseFloat(e.target.value):topP || 0)}
                    min={0}
                    max={1}
                    step={0.01}
                    style={{ width: '75px' }}
                  />
                </div>
              </div>
 
              <div className="col-md-6">
              <Tooltip text={parameterDescriptions.frequencyPenalty}>
                <label
                  htmlFor="Frequency_Penalty"
                  className="form-label font-15 font-semibold text-color-2 mb-2 dec-hp-config">
                  Frequency Penalty
                </label>
                </Tooltip>
                <div className="d-flex flex-row align-items-center gap-3">
                  <ReactSlider
                    className="slider"
                    thumbClassName="thumb"
                    trackClassName="track"
                    value={frequencyPenalty}
                    onChange={handleSliderChange(setFrequencyPenalty)}
                    min={0}
                    max={1}
                    step={0.01}
                  />
                  <input
                    type="number"
                    className="form-control input-field shadow-none p-2 font-14 font-regular border-radius-3 bg-transparent text-color-2 rounded-3"
                    value={frequencyPenalty}
                    onChange={(e) => setFrequencyPenalty(Number(e.target.value)<=1?parseFloat(e.target.value):frequencyPenalty  || 0)}
                    min={0}
                    max={1}
                    step={0.01}
                    style={{ width: '75px' }}
                  />
                </div>
              </div>
 
              <div className="col-md-6">
              <Tooltip text={parameterDescriptions.presencePenalty}>
                <label
                  htmlFor="Presence_Penalty"
                  className="form-label font-15 font-semibold text-color-2 mb-2 dec-hp-config">
                  Presence Penalty
                </label>
                </Tooltip>
                <div className="d-flex flex-row align-items-center gap-3">
                  <ReactSlider
                    className="slider"
                    thumbClassName="thumb"
                    trackClassName="track"
                    value={presencePenalty}
                    onChange={handleSliderChange(setPresencePenalty)}
                    min={0}
                    max={1}
                    step={0.01}
                  />
                  <input
                    type="number"
                    className="form-control input-field shadow-none p-2 font-14 font-regular border-radius-3 bg-transparent text-color-2 rounded-3"
                    value={presencePenalty}
                    onChange={(e) => setPresencePenalty(Number(e.target.value)<=1?parseFloat(e.target.value):presencePenalty  || 0)}
                    min={0}
                    max={1}
                    step={0.01}
                    style={{ width: '75px' }}
                  />
                </div>
              </div>
            </>
          )}
 
          <div className="col-md-12">
            <div className="d-flex justify-content-end align-items-center">
              <button
                type="button"
                className="btn btn-outline-secondary font-14 px-4 py-2 me-3 font-bold"
                onClick={handleReset}>
                Reset
              </button>
              <button
                type="button"
                className="btn primary-btn font-14 font-bold border-radius-5 color-white px-4 py-2"
                onClick={handleApply}>
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
);
}
 
export default ConfigurationButton;