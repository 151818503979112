
// SortIcon.tsx
import React from 'react';

interface SortIconProps {
  order: 'asc' | 'desc' | null;
}

const SortIcon: React.FC<SortIconProps> = ({ order }) => {
  const getIconSource = () => {
    switch (order) {
      case 'asc':
        return 'images/sort-up.svg';
      case 'desc':
        return 'images/Sort-Down.svg';
      default:
        return 'images/Sort-Down.svg';
    }
  };

  const getAltText = () => {
    switch (order) {
      case 'asc':
        return 'sort-up';
      case 'desc':
        return 'sort-down';
      default:
        return 'sort-down';
    }
  };

  return <img src={getIconSource()} className='download-btn-icon' alt={getAltText()} />;
};

export default SortIcon;