import React from 'react';
import { usePopup } from '../Utility/PopupContext';
import { PopupProps } from '../Interfaces/PopupTypes';
import ButtonComponent from './Button';
 
const Popup: React.FC<PopupProps> = () => {
  const { isOpen, closePopup, popupData } = usePopup();
  const { title, content, className = '' } = popupData;
 
  const renderContent = (value: string | JSX.Element) => {
    if (React.isValidElement(value)) {

      
      return value;
    }
    return <span>{value}</span>;
  };
 
  if (!isOpen) return null;
 
  return (
    <>
      {/* Backdrop */}
      <div
        className="modal-backdrop fade show"
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1040
        }}
        onClick={closePopup}
      />
 
      <div
        className="modal fade show"
        id="specifiedprompts-popup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-hidden="true"
        style={{
          display: 'block',
          zIndex: 1050 // Ensure popup is above backdrop
        }}
      >
        <div className={`modal-dialog modal-dialog-centered custom-specifiedprompts-width border-0 ${className}`} style={{ maxHeight: '80vh' }}>
          <div 
            className="modal-content p-3 border-0 bg-color-4" 
            style={{ 
              display: 'flex', 
              flexDirection: 'column', 
              maxHeight: '80vh',
              overflow: 'hidden' 
            }}
          >
            <div className="modal-header border-0 py-2 px-2">
              <h5 className="modal-title font-22 font-bold text-color-1">{title}</h5>
              <ButtonComponent
                className="btn-close"
                onClick={closePopup}
                aria-label="Close"
                data-bs-dismiss="modal"
                height="24px"
                width="24px"
              />
            </div>
            <div 
              className="modal-body popup-scrl" 
              style={{ 
                overflowY: 'auto', 
                flexGrow: 1,
                maxHeight: 'calc(80vh - 150px)' // Adjust based on header and footer heights
              }}
            >
              <div className="row">
                {Object.entries(content).map(([key, value]) => (
                  <React.Fragment key={key}>
                    {key.trim() === "Any existing file structure" ? (
                      <div className="col-md-12 mb-2">
                        <label className="font-14 font-semibold text-color-9">
                          {key !== "" ? key : "User Request"}:
                        </label>
                        <span className="font-14 font-semibold text-color-15">
                          {renderContent(value)}
                        </span>
                      </div>
                    ) : key.trim() === "Tech Stack" || key.trim() === "Type Of Code" ? (
                      <div className="col-md-6 mb-3">
                        <label className="font-14 font-semibold text-color-9">{key}:</label>
                        <span className="font-14 font-semibold text-color-15">
                          {renderContent(value)}
                        </span>
                      </div>
                    ) : (
                      <div className="col-md-12 mb-2">
                        <label className="font-14 font-semibold text-color-9">
                          {key !== "" ? key : "User Request"}:
                        </label>
                        <span className="font-14 font-semibold text-color-15">
                          {renderContent(value)}
                        </span>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="modal-footer border-0 d-flex justify-content-center pt-0">
                <ButtonComponent
                text="OK"
                className="btn theme-color-bg text-white font-14 px-4 py-2 font-medium theme-focus"
                onClick={closePopup}
                data-bs-dismiss="modal"
                />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
 
export default Popup;