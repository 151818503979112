import React, { useEffect, useRef, useState, useMemo } from 'react';
import Editor, { OnMount } from "@monaco-editor/react";

interface CodeContent {
  content: string;
  file_name: string;
}

interface CodespaceComponentProps {
  codeContent: CodeContent;
}

export const CodespaceComponent: React.FC<CodespaceComponentProps> = ({ codeContent }) => {
  
  const editorRef = useRef<HTMLDivElement>(null);
  const [theme, setTheme] = useState<string>("");
  const [code, setCode] = useState<string>(codeContent.content);
  const [file, setFile] = useState<string>(codeContent.file_name);
  const [editorKey, setEditorKey] = useState<number>(0);

  const language = useMemo(() => {
    const extension = file.split('.').pop() || '';
    return extension === 'py' ? 'python' : extension === 'jsx' ? 'javascript' : ['css', 'html', 'dart'].includes(extension) ? extension : 'javascript';
  }, [file]);
  useEffect(() => {
    setFile(codeContent.file_name);
    setCode(codeContent.content);
  }, [codeContent]);

  useEffect(() => {
    const observer = new ResizeObserver(updateThemeFromBackground);
    if (editorRef.current) {
      const parent = editorRef.current.closest('.custom-dashboard-section');
      if (parent) {
        observer.observe(parent);
      }
    }
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    setEditorKey(prevKey => prevKey + 1);
  }, [codeContent]);

  const updateThemeFromBackground = () => {
    if (editorRef.current) {
      const parent = editorRef.current.closest('.custom-dashboard-section');
      if (parent) {
        const bgColor = window.getComputedStyle(parent).backgroundColor;
        const rgb = bgColor.match(/\d+/g);
        if (rgb) {
          const brightness = (parseInt(rgb[0]) * 299 + parseInt(rgb[1]) * 587 + parseInt(rgb[2]) * 114) / 1000;
          setTheme(brightness > 128 ? "#FFFFFF" : "#272727");
        }
      }
    }
  };

  const handleEditorDidMount: OnMount = (editor, monaco) => {
    monaco.editor.defineTheme('dynamicTheme', {
      base: theme == "#FFFFFF" ?  'vs' : "vs-dark",
      inherit: true,
      rules: [],
      colors: { 'editor.background': theme },
    });
    monaco.editor.setTheme('dynamicTheme');
  };

  return (
    <div style={{ height: '100vh' }} className="codespace-container">
      <div className="col-md-10 mt-2 ps-0">
        <div className="codespace-file-contents" ref={editorRef}>
          <Editor
            key={`${editorKey}-${theme}`}
            height="100vh"
            width="100vw"
            defaultLanguage={language}
            onMount={handleEditorDidMount}
            value={code}
            options={{
              theme: "dynamicTheme",
            }}
          />
        </div>
      </div>
    </div>
  );
};
