
import React, { useState, useRef, useEffect } from 'react';
import { useFilter } from '../Utility/FilterContext';
import DropDownComponent from './DropDown';
import MyDatePicker from './DateRange';
import ButtonComponent from './Button';
import { DropDownProvider } from '../Utility/DropDownContext';
import { Option } from '../Utility/FilterContext';
import { MultiValue, SingleValue, ActionMeta } from 'react-select';
import { FilterComponentProps,  } from '../Interfaces/Filter';


const FilterComponent: React.FC<FilterComponentProps> = ({ onApply, onCancel, config,filterDates,setFilterDates }) => {
  const { dropdowns: dropdownState, dateRange, setDropdownValue, setDateRange, clearAllFilters } = useFilter();
  const [dateRangeError, setDateRangeError] = useState('')
 
 
  const customDropdownStyles = {
    control: (provided:any) => ({
      ...provided,
      minHeight: '38px',
      background: 'var(--bg-color, #ffffff)',
      borderColor: 'var(--border-color, #cccccc)',
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'var(--hover-border-color, #999999)',
      },
    }),
    valueContainer: (provided:any) => ({
      ...provided,
      maxWidth: '100%',
      overflow: 'auto',
      flexWrap: 'nowrap',
      '::-webkit-scrollbar': {
        height: '4px',
      },
      '::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '::-webkit-scrollbar-thumb': {
        background: 'var(--scroll-thumb-color, #888888)',
        borderRadius: '2px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: 'var(--scroll-thumb-hover-color, #555555)',
      },
      scrollbarWidth: 'thin',
      scrollbarColor: 'var(--scroll-thumb-color, #888888) transparent',
    }),
    multiValue: (provided:any) => ({
      ...provided,
      background: 'var(--multi-value-bg, #E8E8E8)',
      flexShrink: 0,
    }),
    multiValueLabel: (provided:any) => ({
      ...provided,
      color: 'var(--multi-value-text-color, #333333)',
    }),
    multiValueRemove: (provided:any) => ({
      ...provided,
      color: 'var(--multi-value-remove-color, #999999)',
      ':hover': {
        background: 'var(--multi-value-remove-hover-bg, #CCCCCC)',
        color: 'var(--multi-value-remove-hover-color, #333333)',
      },
    }),
    menu: (provided:any) => ({
      ...provided,
      background: 'var(--bg-color, #ffffff)',
      zIndex: 1000,
    }),
    menuList: (provided:any, state:any) => ({
      ...provided,
      maxHeight: '150px',
      overflowY: state.options.length > 4 ? 'auto' : 'hidden',
      overflowX: 'hidden',
      '::-webkit-scrollbar': {
        width: '6px',
      },
      '::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '::-webkit-scrollbar-thumb': {
        background: 'var(--scroll-thumb-color, #888888)',
        borderRadius: '3px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: 'var(--scroll-thumb-hover-color, #555555)',
      },
      scrollbarWidth: 'thin',
      scrollbarColor: 'var(--scroll-thumb-color, #888888) transparent',
    }),
    option: (provided:any, state:any) => ({
      ...provided,
      background: state.isSelected
        ? 'var(--selected-bg-color, #2684FF)'
        : state.isFocused
          ? 'var(--focus-bg-color, #DEEBFF)'
          : 'var(--bg-color, #ffffff)',
      color: state.isSelected
        ? 'var(--selected-text-color, #ffffff)'
        : 'var(--text-color, #333333)',
      cursor: 'pointer',
    }),
  };

  
  const [isVisible, setIsVisible] = useState(false);
  const [localFilters, setLocalFilters] = useState<{ [key: string]: string | undefined }>({});
  const [displayDates, setDisplayDates] = useState<{ from: Date | null; to: Date | null }>({ from: filterDates?.from|| subtractOneDay(dateRange.from)||"", to: filterDates?.to||subtractOneDay(dateRange.to)||"" });
  const filterRef = useRef<HTMLDivElement>(null);

  function subtractOneDay(date:any): any {
    if(date!=null){
  
    return date-1
    }

    return null
  }
  

  const toggleFilter = () => {
    setIsVisible(!isVisible);
  };

  const handleApply = () => {
    onApply(localFilters);
    setIsVisible(false);
  };

  const handleClear = () => {
    clearAllFilters();
    setLocalFilters({});
    setDisplayDates({ from: null, to: null }); // Reset displayed dates
    onCancel();
    setIsVisible(false);
    setFilterDates({from:"",to:""})
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  const handleDropdownChange = (key: string, newValue: MultiValue<Option> | SingleValue<Option>, actionMeta: ActionMeta<Option>) => {
    const value = Array.isArray(newValue)
      ? newValue.map(option => option.value).join(',')
      : (newValue as Option)?.value || undefined;

    setLocalFilters(prev => ({ ...prev, [key]: value }));
    setDropdownValue(key, newValue, actionMeta);
  };


  const handleDateChange = (date: Date | null, dateType: 'from' | 'to') => {
    if (date) {
      // Store the original selected date for display
      const newDisplayDates = {
        ...displayDates,
        [dateType]: date
      };
      setDisplayDates(newDisplayDates);
  
      // Create a new Date object to avoid mutating the original date
      const adjustedDate = new Date(date);
      // Add one day to the selected date for backend processing
      adjustedDate.setDate(adjustedDate.getDate() + 1);
      // Set the time to midnight (start of the day) in the local time zone
      adjustedDate.setHours(0, 0, 0, 0);
  
      // Update the state with the adjusted date for backend processing
      const newDateRange = {
        ...dateRange,
        [dateType]: adjustedDate
      };
      
      // Validate date range
      let errorMessage = "";
      if (dateType === 'to' && newDateRange.from && newDateRange.to && 
          newDateRange.to < newDateRange.from) {
        errorMessage = "End date cannot be earlier than start date";
      } else if (dateType === 'from' && newDateRange.from && newDateRange.to && 
                 newDateRange.to < newDateRange.from) {
        errorMessage = "Start date cannot be later than end date";
      }
      
      // Set error state (you'll need to add this state)
      setDateRangeError(errorMessage);
      
      // Only update states if there's no error
      if (!errorMessage) {
        setDateRange(newDateRange);
        setLocalFilters(prev => ({
          ...prev,
          [dateType === 'from' ? 'fromDate' : 'toDate']: adjustedDate.toISOString().split('T')[0],
        }));
      }
    } else {
      setDisplayDates(prev => ({ ...prev, [dateType]: null }));
      setDateRange({ ...dateRange, [dateType]: null });
      setLocalFilters(prev => ({
        ...prev,
        [dateType === 'from' ? 'fromDate' : 'toDate']: undefined,
      }));
      // Clear error if any
      setDateRangeError("");
    }
  };
 


  return (
    <div className="dropdown tbl-filter" ref={filterRef} style={{ position: 'relative' }}>
      <button
        type="button"
        className="btn font-16 font-semibold align-items-center btn ml-1 font-16 font-semibold align-items-center inter-btn d-flex transparent-grp py-2 theme-focus search-bg"
        onClick={toggleFilter}
      >
        <img
          className="filter-icons"
          src="images/Default/adv-filter.svg"
          alt="filter-icon"
        />
      </button>
      {isVisible && (
        <div className="dropdown-menu show filter-dropdown-bg w-filter-dropdown bg-color"
             style={{
               position: 'absolute',
               top: '100%',
               right: 0,
               left: 'auto',
               zIndex: 1000,
               minWidth: '250px',
               padding: '1rem',
               marginTop: '0.5rem',
               boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
               border: '1px solid #e0e0e0',
               borderRadius: '4px',
             }}>
          <div className="filter-component">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <span className="font-18 font-semibold text-color-1">Advanced Filter</span>
              <ButtonComponent
                buttonType="icon"
                iconSrc="images/Default/flter-close-icon.svg"
                altText="Close filter"
                className="cursor-pointer close-icon-filter p-0 border-0 bg-transparent"
                style={{
                    width: '16px',
                    height: '16px',
                    minWidth: 'unset',
                    minHeight: 'unset',
                    padding: 0,
                    lineHeight: 1,
                }}
                onClick={handleClose}
                />
            </div>

            {config.dropdowns && Object.entries(config.dropdowns).map(([key, { options, isMulti, label }]) => (
              options && (
                <DropDownProvider key={key} isMulti={isMulti || false}>
                  <DropDownComponent
                    label={label}
                    options={options}
                    value={
                      typeof dropdownState[key]?.selectedOptions === 'object'
                        ? (dropdownState[key]?.selectedOptions as MultiValue<Option> | SingleValue<Option>)
                        :dropdownState[key]?.selectedOptions? ({ label: dropdownState[key]?.selectedOptions, value: dropdownState[key]?.selectedOptions } as unknown as MultiValue<Option> | SingleValue<Option>):dropdownState[key]?.selectedOptions as MultiValue<Option> | SingleValue<Option>
                    }
                    
                    onChange={(newValue: MultiValue<Option> | SingleValue<Option>, actionMeta: ActionMeta<Option>) => 
                      handleDropdownChange(key, newValue, actionMeta)
                    }
                    customStylesOverride={customDropdownStyles}
                  />
                </DropDownProvider>
              )
            ))}

            {config.showDateRange && (
              <div className="mb-2 d-block">
                <span className="d-block font-semibold font-16 text-color-9">Date Range</span>
                <div className="row mb-2">
                  <div className="mb-3 d-block col-md-6">
                    <MyDatePicker
                      id="from"
                      label="From"
                      selected={displayDates.from} // Use the original selected date for display
                      onChange={(date) => handleDateChange(date, 'from')}
                    />
                  </div>
                  <div className="mb-3 d-block col-md-6">
                    <MyDatePicker
                      id="to"
                      label="To"
                      selected={displayDates.to} // Use the original selected date for display
                      onChange={(date) => handleDateChange(date, 'to')}
                    />
                  </div>
                </div>
              </div>
            )}
             {dateRangeError && (
              <span style={{color: 'red'}}>
                {dateRangeError}
              </span>
            )}

            <div className="w-100 d-flex justify-content-end">
              <ButtonComponent
                text="Clear"
                className="custom-outline-btn font-14 font-semibold px-3 me-3"
                onClick={handleClear}
              />
              <ButtonComponent
                text="Apply"
                className="primary-btn font-14 font-semibold border-radius-5 px-3 py-2 color-white"
                onClick={handleApply}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterComponent;