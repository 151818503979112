
import React, { useContext } from 'react';
import { Header, SortConfig } from '../Interfaces/ReusableTable';
import { TableContext } from '../Utility/ReusableTableContext';
import SortIcon from './SortIcon';

interface SortableHeaderProps<T> {
  header: Header<T> | any;
  sortConfig: SortConfig;
  dataPresent?:any;
  headerrightAlign?:any;
  onSort: (column: string) => void;
}

const SortableHeader = <T extends Record<string, any>>({ 
  header, 
  sortConfig, 
  headerrightAlign,
  dataPresent,
  onSort 
}: SortableHeaderProps<T>) => {
  const isSorted = sortConfig.column === header.key;
  const order = isSorted ? sortConfig.order : null;

  return (
    <th 
      className={`${header.className || ''} ${header.sortable ? 'cursor-pointer' : ''} `} 
     
      onClick={() => dataPresent? header.sortable && onSort(header.key.toString()):null}
    >
       
      <div 
        className="d-flex align-items-center"  
        style={{
          display: headerrightAlign ? "flex" : undefined,
          justifyContent: headerrightAlign ? "flex-end" : undefined,
          paddingRight: headerrightAlign ? "33%" : undefined
        }}
      >
        <span>{header.label}{header.required && <span className="text-danger">*</span>}</span>
        {header.sortable && dataPresent&& (
          <span className="ms-2">
           <SortIcon order={order} />
          </span>
        )}
      </div>
    </th>
  );
};

export default SortableHeader;