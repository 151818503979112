import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { useChat } from "../../Utility/ChatContext";
import { getPresignedURL } from "../../Service/API";
import MonacoEditorComponent from "../../Reusable Components/MonacoEditor";
import ReactMarkdown from "react-markdown";
import Markdown from 'markdown-to-jsx';

interface Props {
  lastIndex: number;
  assistantIndex: number;
  ChatIndex: number;
  results: any[];
  totallength: number;
  isLastAssistantMessage: boolean;
  navOpenHeader:string ;
  rightNav?:any;
}

const IncomingContent: React.FC<Props> = ({
  lastIndex,
  assistantIndex,
  ChatIndex,
  results,
  totallength,
  isLastAssistantMessage,
  navOpenHeader,
  rightNav
}) => {

  const {
    handleMermaidLinkClick,
    handleLinkClick,
    handleD2LinkClick,
    codeCompare,
    webResult,
    setWebResult,
    handleRegen,
    hiddenObjValues,
    incrementfunc,
    decreamentfunc,
    incrementval,
    excelURLs,
    currentChatID,
    messageOrder,
    isAskAsami,
    sethiddenObjValues,
    handleBtns,
    projectName_projects, 
    collectionName_projects, 
    leftCodeArray, rightCodeArray, isModule,
    setEdit
  } = useChat();
  let { isPrompt } = useChat();
  let BLinksList = JSON.parse(sessionStorage.getItem("BLinksList")as any)



  const myElement = useRef<HTMLDivElement>(null);

  const getExcelPresignedURL = async(url:string) =>{
    try{
      const response = await getPresignedURL({"url":url})
      if (response.data.status_code == 200 ){
        return response.data.S3URL
      }else{
        console.error("Error in getExcelPresignedURL")
      }
    }catch(e){
      console.error("Error in getExcelPresignedURL", e)

    }
    
  }
  

  return (
    <div
      className={`d-flex w-100 flex-col ${
        ChatIndex === assistantIndex && lastIndex - 1 === assistantIndex
          ? "last-spacing"
          : "mvh-8"
      }`}
      ref={myElement}
    >
         <div
        className={`${isLastAssistantMessage ? "chat-content" : ""} position-relative ${
          hiddenObjValues.sendMessage === false ? "incoming-margin" : null
        }`}
        style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" ,marginLeft : navOpenHeader == "Open" && rightNav == true ? "5vw" : ""}}
      >
        <img
          src="images/ai-images/Bot_profile.svg"
          alt="bot-happy"
          className="chat-img position-absolute bottom-0 chat-bot"
        />
        <div className="chat-area position-relative">
          <div className="chat-box position-relative font-14 font-semibold m-0 text-color-15" >
            {results?.map((result: any, idex: number) => {
              switch (result.type) {
                case "code":
                  return (
                  <div key={idex} className="custom-monaco-style" >
                     {  JSON.parse(sessionStorage.getItem("issuesandtaskState")||"{}")["isModule"] !== 'tasks' && JSON.parse(sessionStorage.getItem("issuesandtaskState")||"{}")["isModule"] !== 'issues'? (
                        <>
                    <MonacoEditorComponent editorCode={result.content}/>
                    </>
                      ):(
                        <div
                          style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
                          onLoad={(e) => {
                            (e.target as HTMLDivElement).focus();
                          }}
                        >
                          {
                          leftCodeArray 
                          &&
                           codeCompare(ChatIndex)
                           }
                           <div></div>
                          {" "}
                        </div>
                      )
                      }

                  </div>
                  );
                case "plantuml":
                  return (
                    <div key={idex} className='mt-3 mb-3'>
                      <h6 className={"plantuml-heading"}>
                        You can find your UML diagram.
                      </h6>
                      <nav>
                        <Link
                          to="/plantUml"
                          state={{ chatId: currentChatID }}
                          onClick={(e) => handleLinkClick(e, result)}
                        >
                          UML Diagram
                        </Link>
                      </nav>
                    </div>
                  );
                case "d2":
                  return (
                    <div key={idex} className='mt-3 mb-3'>
                      <h6 className={"d2-heading"}>
                        You can find your D2 diagram here.
                      </h6>
                      <nav>
                        <Link
                          to={{
                            pathname: "/d2",
                            search: `?d2Code=${encodeURIComponent(
                              result.content
                            )}`,
                          }}
                          state={{ chatId: currentChatID }}
                          onClick={(e) => handleD2LinkClick(e, result)}
                        >
                          D2 Diagram
                        </Link>
                      </nav>
                      {/* <DiagramButtonProvider>
                        <DiagramPreview
                          navOpen="Close" 
                          diagramType="d2"
                        />
                      </DiagramButtonProvider> */}
                    </div>
                  );
                case "mermaid":
                  return (
                    <div key={idex}>
                      <h6 className={"d2-heading mt-3 mb-3"} >
                        You can find your mermaid diagram here.
                      </h6>
                      <nav>
                        <Link
                          to={{
                            pathname: "/mermaid",
                            search: ``,
                          }}
                          state={{ chatId: currentChatID }}
                          onClick={(e) => handleMermaidLinkClick(e, result)}
                        >
                          Mermaid Diagram
                        </Link>
                      </nav>
                      {/* <DiagramButtonProvider>
                        <DiagramPreview
                          navOpen="Close"
                          diagramType="mermaid"
                        />
                      </DiagramButtonProvider> */}
                    </div>
                  );
                case "text":
                  return (
                    <Markdown className='markdown-viewer-new'>{result.content}</Markdown>
                  );
                default:
                  return null;
              }
            })}
          </div>

          <div
            className="position-absolute d-flex gap-2 bot-prev-next-decor"
            style={{ marginLeft: "590px" }}
          >
            {/* {incrementval[ChatIndex] > 0 ? (
              <img
                style={{ cursor: "pointer" }}
                src="images/next-arrow-icon.svg"
                alt="next-arrow"
                className="arrow-icon"
                onClick={() => {
                  decreamentfunc(ChatIndex);
                }}
              />
            ) : null}
            {totallength > incrementval[ChatIndex] ||
            incrementval[ChatIndex] > 0 ? (
              <span className="text-color-7 font-12">
                {incrementval[ChatIndex]}/{totallength}
              </span>
            ) : null}
            {totallength > incrementval[ChatIndex] ? (
              <img
                src="images/prev-arrow-icon.svg" 
                style={{ cursor: "pointer" }}
                className="arrow-icon"
                alt="prev-arrow"
                onClick={() => {
                  incrementfunc(ChatIndex);
                }}
              />
            ) : null} */}

            {/* Show "previous" arrow when not at the last item */}
            {incrementval[ChatIndex] > 0 ? (
              <img
                style={{ cursor: "pointer" }}
                src="images/prev-arrow-icon.svg"
                alt="prev-arrow"
                className="arrow-icon"
                onClick={() => {
                  // Decrement instead of increment
                  decreamentfunc(ChatIndex);
                }}
              />
            ) : null}

            {/* Display current position */}
            {totallength > 0 ? (
              <span className="text-color-7 font-12">
                {incrementval[ChatIndex] + 1}/{totallength + 1}
              </span>
            ) : null}


            {/* Show "next" arrow when not at the first item (which is now index 0) */}
            {incrementval[ChatIndex] < totallength ? (
              <img
                style={{ cursor: "pointer" }}
                src="images/next-arrow-icon.svg"
                alt="next-arrow"
                className="arrow-icon"
                onClick={() => {
                  // Increment instead of decrement
                  incrementfunc(ChatIndex);
                }}
              />
            ) : null}


          </div>

          <div className="chat-box-btns d-flex position-absolute ">

            {messageOrder != 0 &&
            isPrompt == true &&
            ChatIndex == assistantIndex &&
            lastIndex - 1 == assistantIndex ? (
              <>
                <button
                  className="btn primary-gradiant-btn border-0 text-white font-12 font-medium me-3"
                  id="yes"
                  // disabled={
                  //   !hiddenObjValues.inputFix ? true : false
                  // }

                  disabled={hiddenObjValues.responseBtn}
                  onClick={(e) => {
                    sethiddenObjValues({
                      ...hiddenObjValues,
                      responseBtn: true,
                      sendMessage: true,
                      inputFix:"Send a message..."
                    });
                    handleBtns(e,BLinksList);
                    // setPopMsg(false)
                    setEdit(false)
                  }}
                >
                  Yes, looks good
                </button>

                <button
                  className="btn theme-outline-btn theme-color theme-focus text-white font-12 font-medium  me-3"
                  id="fix"
                  // disabled={
                  //   !hiddenObjValues.inputFix ? true : false
                  // }
                  disabled={hiddenObjValues.responseBtn}
                  onClick={(e) => { 
                    sethiddenObjValues({
                      ...hiddenObjValues,
                      responseBtn: true,
                      sendMessage: false,
                      inputFix: "Fix your response here",
                    });
                    setEdit(true)
                    handleBtns(e,BLinksList);
                    
                  }}
                >
                  Fix this response
                </button>
              </>
            ) : null}




            {excelURLs[ChatIndex] &&
              excelURLs[ChatIndex] !== "None" &&
              excelURLs[ChatIndex].includes("excel_upload_media") && (
                <button
                  className="btn theme-outline-btn theme-color theme-focus text-white font-12 font-medium"
                  onClick={async () => {
                    const url = excelURLs[ChatIndex];
                    const presignedExcelURL:any = await getExcelPresignedURL(url)
                    window.open(presignedExcelURL, "_blank", "noopener,noreferrer");
                  }}
                >
                  Download as Excel
                </button>
              )}
          </div>

          <div className="chat-option-regenrate bg-transparent">
            {isLastAssistantMessage && (
              <button onClick={()=>handleRegen(BLinksList)} type="button" className="btn bg-transparent lh-0 p-2" style={{display:"block"}}
              >
                <img
                  className="filter-icons"
                  src="/images-new/regenerate-icon.svg"
                  alt="regenerate-icon"
                />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncomingContent;
