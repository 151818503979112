
import React, { useState } from "react";
import PopupBuilder from "./InputPopUpComponent";
import { usePromptBuilder } from "../Utility/ManagePromptContext";

interface FewShotExampleProps {
  index: number;
  userContent: string;
  assistantContent: string;
  HandlePromptHistory: boolean;
  onEdit: (index: number, userContent: string, assistantContent: string) => void;
  onDelete: (index: number) => void;
}

const FewShotExample: React.FC<FewShotExampleProps> = ({
  index,
  userContent,
  assistantContent,
  HandlePromptHistory,
  onEdit,
  onDelete,
}) => {
  const { setUserContent, setAssistantContent } = usePromptBuilder();
  const [newUserContent, setNewUserContent] = useState(userContent);
  const [newAssistantContent, setNewAssistantContent] = useState(assistantContent);
  const [showDropDown, setShowDropDown] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [editOrNot, setEditOrNot] = useState(false);
  const handleDropDown = () => {
    setShowDropDown(!showDropDown);
  };

  const handlePopUp = (edit: boolean = false) => {
    setShowPopUp(true);
    setShowDropDown(false);
    setEditOrNot(edit);
    if (edit) {
      setNewUserContent(userContent);
      setNewAssistantContent(assistantContent);
    }
  };

  const handleSave = (data: { userContent: string; assistantContent: string }) => {
    if (HandlePromptHistory) {
      onEdit(index, data.userContent, data.assistantContent);
    }
    setShowPopUp(false);
  };
 function setdata(){
  setUserContent(newUserContent);setAssistantContent(newAssistantContent);handlePopUp(true)
 }
  return (
    <>
      <div className="example-container rounded-3 bg-color custom-border-3 p-4 overflow-hidden">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="font-bold font-14 m-0 text-color-9">Few Shot User Input</h4>
          <div className="fewShots-drop-down position-relative">
            <button
              className="border-0 bg-none bg-transparent"
              type="button"
              onClick={handleDropDown}
            >
              <img src="images-new/btn-dot.svg" alt="more" />
            </button>
            <ul
              className="font-regular font-13 color-black more-options-dpd fewShot-dd ps-0 position-absolute p-2 ps-2 rounded-3 bg-color-2"
              style={{ display: showDropDown ? "block" : "none" }}
            >
              <li
                className="dropdown-item custom-dropdown-li cursor-pointer mb-1 d-flex align-items-center gap-2 text-color-2"
                onClick={() => { document.body.classList.add("no-scroll-body");handlePopUp(false)}}
              >
                <img src="images-new/view-eye-iocn-violet.svg" alt="view" />
                <span>View</span>
              </li>
              <li
                className="dropdown-item custom-dropdown-li cursor-pointer mb-1 d-flex align-items-center gap-2 text-color-2"
                onClick={() => { document.body.classList.add("no-scroll-body");setdata()}}
              >
                <img src="images-new/edit-icons.svg" alt="Edit" />
                <span>Edit</span>
              </li>
              <li
                className="dropdown-item custom-dropdown-li cursor-pointer d-flex align-items-center gap-2 text-color-2"
                onClick={() => HandlePromptHistory && onDelete(index)}
              >
                <img src="images-new/delete-icons.svg" alt="Delete" />
                <span>Delete</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="font-regular font-14 text-color-6 mt-3">
          <p className="text-truncate mx-w-350px m-0">{userContent}</p>
        </div>
        <h4 className="font-bold font-14 m-0 text-color-9 mt-4 mb-3 pt-2">
          Expected AI Response
        </h4>
        <p className="mb-0 lh-lg text-color-6">{assistantContent}</p>
      </div>

      <PopupBuilder
        index={index}
        popupType="input"
        showModal={showPopUp}
        onClose={() => { document.body.classList.remove("no-scroll-body");setShowPopUp(false)}}
        onSave={handleSave}
        initialData={{
          userContent: newUserContent,
          assistantContent: newAssistantContent,
          index: index
        }}
        editMode={editOrNot}
      />
    </>
  );
};

export default FewShotExample;