import React, { createContext, useState, useContext, ReactNode } from 'react';
import { SortConfig, TableContextType } from '../Interfaces/ReusableTable';
import { useLlmConfig } from './LLMConfigurationForm';

interface LlmFormContextType<T> {
    data: T[];
    setData: React.Dispatch<React.SetStateAction<T[]>>;
    sortConfig: SortConfig;
    setSortConfig: React.Dispatch<React.SetStateAction<SortConfig>>;
    renderInputFields: any;
    renderRuleFields: any;
}

const LlmFormContext = createContext<LlmFormContextType<any> | undefined>(undefined);

interface LlmFormProviderProps<T> {
    children: ReactNode;
    initialData?: T[];
}

export function LlmFormProvider<T>({ children, initialData = [] }: LlmFormProviderProps<T>) {
    // Grouped states and setters
    const [data, setData] = useState<T[]>(initialData);
    const [sortConfig, setSortConfig] = useState<SortConfig>({ column: '', order: null });

    // Destructure values from useLlmConfig
    const {
        isLoading, setIsLoading, ref1, ref2, ref3, ref4, ref5,
        modelType, selectedModelType, errorData, setErrorData,
        newEndpoint, setNewEndpoint, newApikey, setNewApikey,
        newModelID, setNewModelID, newAnthropicVersion, setNewAnthropicVersion,
        newAwsRegion, setNewAwsRegion, handleAddRow,
    } = useLlmConfig();

    // Function to render input fields based on selected model type
    const renderInputFields = () => {
        const setAWSVersion = (e: any) => {
            setErrorData({ ...errorData, modelError: "" });
            setNewAnthropicVersion(e.target.value);
        };

        const setAWSRegion = (e: any) => {
            setErrorData({ ...errorData, awsRegionError: "" });
            setNewAwsRegion(e.target.value);
        };

        const setModelID = (e: any) => {
            setErrorData({ ...errorData, modelIDError: "" });
            setNewModelID(e.target.value);
        };

        const setEndpoint = (e: any) => {
            setErrorData({ ...errorData, modelEndpointError: "" });
            setNewEndpoint(e.target.value);
        };

        const setApiKey = (e: any) => {
            setErrorData({ ...errorData, apikeyError: "" });
            setNewApikey(e.target.value);
        };

        // Render fields for Azure Open AI or Open AI
        if (selectedModelType === 'Azure Open AI' || selectedModelType === 'Open AI') {
            return (
                <tr>
                    <td>
                        <input type="text" className="form-control inline-table-input-w input-field font-14 font-regular border-radius-3 bg-color text-color-4 custom-border-3 cust-search-input" id="value" placeholder="Enter End Point" ref={ref1} value={newEndpoint} onChange={(e) => setEndpoint(e)} />
                        {errorData.modelEndpointError && (
                            <div className="span-color">
                                <h6>{errorData.modelEndpointError}</h6>
                            </div>
                        )}
                    </td>
                    <td>
                        <input type="text" className="form-control inline-table-input-w input-field font-14 font-regular border-radius-3 bg-color text-color-4 custom-border-3 cust-search-input" id="value" placeholder="Enter API Key" ref={ref2} value={newApikey} onChange={(e) => setApiKey(e)} />
                        {errorData.apikeyError && (
                            <div className="span-color">
                                <h6>{errorData.apikeyError}</h6>
                            </div>
                        )}
                    </td>
                    <td>
                        <button className="btn purple-plus-icon-decor" onClick={(e: React.MouseEvent<HTMLButtonElement>) => { e.preventDefault(); handleAddRow(); }}>
                            <img className="purple-plus-icon" src="images/purple-plus-icon.svg" />
                        </button>
                    </td>
                </tr>
            );
        }

        // Render fields for AWS Bedrock
        if (selectedModelType === 'AWS Bedrock') {
            return (
                <tr>
                    <td>
                        <input type="text" className="form-control inline-table-input-w input-field font-14 font-regular border-radius-3 bg-color text-color-4 custom-border-3 cust-search-input" id="value" placeholder="Enter Model ID" ref={ref3} value={newModelID} onChange={(e) => setModelID(e)} />
                        {errorData.modelIDError && (
                            <div className="span-color">
                                <h6>{errorData.modelIDError}</h6>
                            </div>
                        )}
                    </td>
                    <td>
                        <input type="text" className="form-control inline-table-input-w input-field font-14 font-regular border-radius-3 bg-color text-color-4 custom-border-3 cust-search-input" id="value" placeholder="Enter Anthropic Version" ref={ref4} value={newAnthropicVersion} onChange={(e) => setAWSVersion(e)} />
                        {errorData.versionError && (
                            <div className="span-color">
                                <h6>{errorData.versionError}</h6>
                            </div>
                        )}
                    </td>
                    <td>
                        <input type="text" className="form-control inline-table-input-w input-field font-14 font-regular border-radius-3 bg-color text-color-4 custom-border-3 cust-search-input" id="value" placeholder="Enter AWS region" ref={ref5} value={newAwsRegion} onChange={(e) => setAWSRegion(e)} />
                        {errorData.awsRegionError && (
                            <div className="span-color">
                                <h6>{errorData.awsRegionError}</h6>
                            </div>
                        )}
                    </td>
                    <td>
                        <button className="btn purple-plus-icon-decor" onClick={(e: React.MouseEvent<HTMLButtonElement>) => { e.preventDefault(); handleAddRow(); }}>
                            <img className="purple-plus-icon" src="images/purple-plus-icon.svg" />
                        </button>
                    </td>
                </tr>
            );
        }
    };

    // Dummy function for rendering rule fields
    const renderRuleFields = () => {
        // Placeholder for future implementation
    };

    return (
        <LlmFormContext.Provider value={{
            data, setData, sortConfig, setSortConfig, renderInputFields, renderRuleFields
        }}>
            {children}
        </LlmFormContext.Provider>
    );
}

export const useLlmFormContext = <T,>(): LlmFormContextType<T> => {
    const context = useContext(LlmFormContext);
    if (context === undefined) {
        throw new Error('useLlmFormContext must be used within a LlmFormProvider');
    }
    return context;
};