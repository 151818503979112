import { mdata } from "../config/prod.env";

const TENANT_ID: string = mdata.tenant_id;

interface MsalConfigAuth {
  clientId: string;
  authority: string;
  redirectUri: string;
}

interface MsalConfigCache {
  cacheLocation: "localStorage" | "sessionStorage";
  storeAuthStateInCookie: boolean;
}

interface MsalConfig {
  auth: MsalConfigAuth;
  cache: MsalConfigCache;
}

interface LoginRequest {
  scopes: string[];
}

interface Account {
  tenantId: string;
}

// Configuration for Microsoft Authentication Library (MSAL) including auth endpoints and cache settings
export const msalConfig: MsalConfig = {
  auth: {
    clientId: mdata.clientId,
    authority: `https://login.microsoftonline.com/${TENANT_ID}`,
    redirectUri: window.location.origin + "/chat",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

// Checks if a user is authenticated by verifying their tenant ID matches the expected tenant
export const isAuth = (accounts: Account[]): Account | undefined => {
  return accounts.find((_account) => _account?.tenantId === TENANT_ID);
};

// Defines the OAuth2 scopes required for user authentication and profile access
export const loginRequest: LoginRequest = {
  scopes: ["openid", "profile", "User.Read"],
};
