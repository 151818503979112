import React from 'react';
import { useState, useRef, useEffect
 } from 'react';

// Define the interface for the props
interface CommandPopupProps {
  showCommandPopup: any; // Controls visibility of the popup
  caretCoords: { left: number } | null; // Position of the caret
  commandOptions: any
  handleCommandSelect: (command: string) => void; // Function to handle command selection
}

// Apply the interface to the component
const CommandPopup: React.FC<CommandPopupProps> = ({
  showCommandPopup,
  caretCoords,
  commandOptions,
  handleCommandSelect,
}) => {
  const [focusedIndex, setFocusedIndex] = useState(-1); // Tracks the currently focused option
  const popupRef = useRef<HTMLDivElement>(null); // Ref for the popup container

  // Handle keyboard navigation and selection
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!showCommandPopup) return;

      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setFocusedIndex((prevIndex) =>
          prevIndex < commandOptions.length - 1 ? prevIndex + 1 : 0 // Loop to the first option
        );
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        setFocusedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : commandOptions.length - 1 // Loop to the last option
        );
      } else if (e.key === 'Enter' && focusedIndex !== -1) {
        e.preventDefault();
        handleCommandSelect(commandOptions[focusedIndex].command); // Select the focused option
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [showCommandPopup, focusedIndex, commandOptions, handleCommandSelect]);

  // Reset focusedIndex when the popup is closed
  useEffect(() => {
    if (!showCommandPopup) {
      setFocusedIndex(-1);
    }
  }, [showCommandPopup]);

  return showCommandPopup && (
    <div
      ref={popupRef}
      className="bg-color-2 position-absolute custom-options-container"
      style={{
        top: '-143%',
        left: caretCoords ? caretCoords.left + 30 + 'px' : 'auto',
      }}
    >
      <ul className="p-0 list-style-none m-0 py-2 chat-form-list-fix custom-options-container">
        {commandOptions.map((option:any, index:any) => (
          <li key={option.id}>
            <button
              className={`border-0 bg-transparent-chat-form font-12 font-medium text-color-9 text-start w-100 px-3 py-2 hover:bg-gray-100 ${
                focusedIndex === index ? 'bg-gray-100' : '' // Highlight the focused option
              }`}
              onClick={() => handleCommandSelect(option.command)} // Select on click
              ref={(el) => {
                if (focusedIndex === index && el) {
                  el.focus(); // Focus the button programmatically
                }
              }}
            >
              {option.label}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CommandPopup;