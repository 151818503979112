import React, { useEffect, useState } from "react";
import { CodespaceComponent } from "./CodeSpaceComponent";
import { DiagramButtonProvider } from "../../Utility/DiagramButtonContext";
import DiagramPreview from "../../Main Components/ChatComponent/DiagramComponent";
import { NavFooterProvider } from "../../Header Components/NavFooterComponent/NavFooterContext";
import DownloadZipButton from "../Base64ToZip";

interface CodeDetail {
  file_name: string;
  content: string;
}

interface CodeData {
  codeData: CodeDetail[];
}

interface CodeDisplay {
  codeDisplay: boolean;
}

interface FolderStructureComponentProps {
  codeDisplay: CodeDisplay;
  codeData: CodeData;
  navOpen: String;
  downloadbutton?:any;
  chatType?:any;
}

export const FolderStructureComponent: React.FC<FolderStructureComponentProps> = ({
  codeDisplay,
  codeData,
  chatType,
  downloadbutton
}) => {
  const [activeFile, setActiveFile] = useState<string | null>(null);
  const [expandedFolders, setExpandedFolders] = useState<{ [key: string]: boolean }>({});
  const [codeDetails, setCodeDetails] = useState<CodeDetail[]>([]);
  const [mergedCodeDetails, setMergedCodeDetails] = useState<CodeDetail[]>([]);
  const [loopdata] = useState<boolean>(true);

  
  useEffect(() => {
    if (loopdata && codeData?.codeData) {
      setCodeDetails(codeData?.codeData);
    }
  }, [loopdata, codeData]);
  useEffect(() => {
    
  }, [mergedCodeDetails]);

  useEffect(() => {
    if (codeDetails.length > 0) {
      const fileMap = new Map<string, string>();

      codeDetails.forEach((detail) => {
        const existingContent = fileMap.get(detail.file_name);
        
        if (existingContent) {
          fileMap.set(detail.file_name, existingContent + '\n\n// Concatenated file content\n' + detail.content);
        } else {
          fileMap.set(detail.file_name, detail.content);
        }
      });
      
      const mergedDetails: CodeDetail[] = [];
      fileMap.forEach((content, file_name) => {
        mergedDetails.push({ file_name, content });
      });
      
      setMergedCodeDetails(mergedDetails);
    }
  }, [codeDetails]);



  const toggleFolder = (folder: string) => {
    setExpandedFolders((prevState) => ({
      ...prevState,
      [folder]: !prevState[folder],
    }));
  };

  const handleFileClick = (fileName: string) => {
    let code: any = mergedCodeDetails.find((item: CodeDetail) => item.file_name === fileName);
    if (codeDisplay.codeDisplay == false) {
      sessionStorage.setItem("plantumlCode", code?.content);
    }
    setActiveFile(fileName);
  };

  const generateFolderStructure = (paths: string[]) => {
    const structure: { [key: string]: any } = {};
    paths.forEach((path) => {
      const folders = path.trim().split("/");
      let currentStructure = structure;
      folders.forEach((folder, index) => {
        if (!currentStructure[folder]) {
          currentStructure[folder] = index === folders.length - 1 ? null : {};
        }
        currentStructure = currentStructure[folder];
      });
    });
    return structure;
  };

  const renderFolderStructure = (structure: { [key: string]: any }, parentPath: string = "") => {
    return (
      <div>
        {Object.keys(structure).map((item) => {
          const currentPath = parentPath ? `${parentPath}/${item}` : item;
          const isFile = structure[item] === null;
          const isActive = activeFile === currentPath;

          return (
            <div key={currentPath}>
              <div
                className={`folder-item primary-text-color ${isActive ? "active-file" : ""}`}
                onClick={() => isFile ? handleFileClick(currentPath) : toggleFolder(currentPath)}
              >
                {!isFile && (
                  <>
                    {expandedFolders[currentPath] ? (
                      <img className="me-3 collapse-arrow" src="../images/Files-acc-arrow.svg" alt="dropdown-icon" />
                    ) : (
                      <img className="me-3 collapse-arrow" src="../images/Files-acc-arrow.svg" alt="dropdown-icon" style={{ transform: "rotate(270deg)" }} />
                    )}
                    <span className="folder-name primary-text-color">
                      {item}
                    </span>
                  </>
                )}
               {isFile && (
                <div className="file-container-code">
                  <img src='/images-new/single-file.svg' alt="file-icon" style={{ width: '20px', height: '15px' }} />
                  <span className="file-name primary-text-color" title={item} style={{cursor: "default"}}>
                    {item}
                  </span>
                 </div>
              )}
              </div>
              {structure[item] !== null && expandedFolders[currentPath] && (
                <div className="subfolder pl-4">
                  {renderFolderStructure(structure[item], currentPath)}
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const getFolderStruct = () => {
    const folderStruct = mergedCodeDetails
      .map((codeValue) => codeValue.file_name)
      .filter(Boolean);
    return folderStruct;
  };

 

  return (
    <div>
      <div className="px-2 db-chart-scrl cust-scroll font-regular folder-structure-component">
      {codeDisplay.codeDisplay === true && (downloadbutton=="Completed")?(<DownloadZipButton  />):(<></>)}
        <div style={{ display: "flex" }}>
          <div className="col-md-2 mt-2">
            <div className="codespace-file-list" style={{width: "130%"}}>
              <p className="codespace-file-list-title font-regular font-11 mb-0">FILES</p>
              <div className="codespace-file-lists">
                {mergedCodeDetails.length !== 0 ? (
                  renderFolderStructure(generateFolderStructure(getFolderStruct()))
                ) : (
                  <p></p>
                )}
              </div>
            </div>
            <style>{`
              .folder-item {
                cursor: pointer;
              }
              .subfolder {
                padding-left: 20px;
              }
            `}</style>
          </div>
          <div className="col-md-10 mt-2 p-0">
            <div className="codespace-file-contents custom-dashboard-section" style={{marginLeft:"7%"}}>
              {activeFile && codeDisplay.codeDisplay == true && mergedCodeDetails.map(
                (codeValue) =>
                  codeValue.file_name === activeFile ? (
                    <NavFooterProvider>
                      <CodespaceComponent codeContent={codeValue} key={codeValue.file_name} />
                    </NavFooterProvider>
                  ) : null
              )}
               {mergedCodeDetails.length==0?(<div className="d-flex mt-2 gap-2">
                    <div className="col-md-12 bg-color-2 border-radius-12 vh-89 p-3 overflow-auto custom-define-space-page-contents custom-right-side-bar-section-details norecords" style={{"marginLeft":"-115%"}}>
                          <img src="../images/no-records-graphic.svg" alt="graphic"/>
                          <p className="mb-0 font-medium font-14 mt-2 text-color-9" style={{"textAlign":"center"}}>No Data Found</p>
                    </div>
                  </div>):null}
              {activeFile && codeDisplay.codeDisplay == false && mergedCodeDetails.map(
                (codeValue, index) =>
                  codeValue.file_name === activeFile ? (
                    <DiagramButtonProvider key={index}>
                      <DiagramPreview
                        navOpen="Close"
                        diagramType="plantuml"
                      />
                    </DiagramButtonProvider>
                  ) : null
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};