import React, { useEffect } from 'react';
import { useNavLink } from './NavLinkContext';
import { useButtonActions } from '../NavHeaderComponent/ButtonComponentActionContext';

interface ButtonComponentProps {
    id: string; 
}

const NavLink: React.FC<ButtonComponentProps> = ({ id }) => {

  const { navItems, activeTab, setActiveTab, handleNavigation, tab } = useNavLink();
  var { leftNav } = useButtonActions();

  // Effect to handle side effects related to activeTab (currently empty)
  useEffect(() => {}, [activeTab]);

  return (

    // Render a list of navigation items as pills
    <ul className="nav nav-pills w-100">

      {navItems.map((item) => {

        // Skip rendering if the item's `show` property is false
        if (!item.show) return null;

        // Conditionally render items based on the `id` prop and item ID
        else if (
          (id === 'NavFooter' && (item.id === 'feed-back' || item.id === 'AsamiSupport')) ||
          (id !== 'NavFooter' && (item.id !== 'feed-back' && item.id !== 'AsamiSupport'))
        ) {
          return (
            <li
              key={item.id}
              className={`mb-1 w-100 cursor-pointer ${activeTab === item.id ? "active2" : ""}`}
            >
              <a
                className={`nav-link link-dark text-color-9 font-14 primary-gradiant-btn font-medium custom-left-padding ${tab === item.id ? "active2" : ""}`}
                aria-current="page"
                onClick={(e) => {
                  sessionStorage.setItem('toasterDisplay','false')
                  sessionStorage.setItem('isStreaming','false')
                  setActiveTab(item.id); 
                  handleNavigation(item.route); 
                }}
              >
                {/* Render the icon with conditional styling based on active state */}
                {activeTab === item.id ? (
                  <img
                    className="left-nav-icons active"
                    src={item.icon}
                    alt={`${item.text}-icon`}
                    style={{ filter: "brightness(0) invert(1)" }} 
                  />
                ) : (
                  <img
                    className="left-nav-icons"
                    src={item.icon}
                    alt={`${item.text}-icon`}
                  />
                )}

                {/* Render the text label conditionally based on leftNav state */}
                {leftNav === "Open" ? (
                  activeTab === item.id ? (
                    <span className={`ms-2`} style={{ color: "#ffffff" }}>
                      {item.text}
                    </span>
                  ) : (
                    <span className={`ms-2`}>{item.text}</span>
                  )
                ) : null}
              </a>
            </li>
          );
        }
      })}
    </ul>
  );
};

export default NavLink;