import React from 'react';
import { Header, ReusableTableProps } from '../Interfaces/ReusableTable';
import SortableHeader from './SortableHeader';
import NoRecordsFound from './NoRecordsFound';
import { useTableContext } from '../Utility/ReusableTableContext';

const ReusableTable = <T extends Record<string, any>>({
  headers,
  inputFields,
  llmKey,
  onRowClick,
  render
  
}: Omit<ReusableTableProps<T>, 'data' | 'sortConfig' | 'onSort'>) => {
  const { data, sortConfig, setSortConfig } = useTableContext<T>();
  const getNestedValue = (obj: T, path: string): any => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  const handleSort = (column: string) => {
    setSortConfig(prevConfig => ({
      column,
      order: 
        prevConfig.column === column
          ? prevConfig.order === 'asc'
            ? 'desc'
            : prevConfig.order === 'desc'
              ? 'asc'
              :null
          : 'asc'
    }));
  };
    data?.map((row, rowIndex) => {
    })

  const renderCellContent = (row: T, header: Header<T>) => {
    if (header.render) {
      return header.render(row);
    }

    const value = getNestedValue(row, header.key.toString());
    if (header.key === 'modelConfiguration.modelName') {
      return (
        <>

          {value==""?"-":value}
          </>
      );
    }
    if (header.key === 'modelConfiguration.displayName') {
      return (
        <a
          href="#"
          className="text-decoration-none link-color"
          onClick={(e) => {
            e.preventDefault();
            onRowClick && onRowClick(row);
          }}
        >
          {value}
        </a>
      );
    }
    if (header.component) {
      return React.createElement(header.component, { row });
    }
    return value;
  };

  return (
    <table className="table theme-table font-regular font-14 mb-0">
      <thead>
        <tr>

          {headers.map((header) => (
            <SortableHeader 
              key={String(header?.key)} 
              header={header} 
              dataPresent={data?.length > 0 }
              headerrightAlign={header?.rightAlign}
              sortConfig={sortConfig}
              onSort={handleSort}
            />
          ))}
        </tr>
      </thead>
      <tbody>
        {inputFields && (
          <>
              {inputFields}
              </>
        )}
        {data && data.length > 0 ? (
          data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {headers.map((header) => (
                <td 
                  key={`${rowIndex}_${String(header.key)}`} 
                  className={`${header.cellClassName || ""} ${header.rightAlign ? "text-end-manage-grid" : ""}`}
                >
                  {renderCellContent(row, header)}
                </td>
              ))}
            </tr>
          ))
        ) : !inputFields ?(
          <tr>
            <td colSpan={headers.length}>
              <NoRecordsFound message="No Records Found!" />
            </td>
          </tr>
        ):null}
      </tbody>
    </table>
  );
};

export default ReusableTable;