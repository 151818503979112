import React, { useState,useEffect } from 'react';
import { usePromptBuilder } from '../../Utility/ManagePromptContext'; // Ensure correct path to the context
import DraggableTaskTable from '../../Reusable Components/DraggableTable';
import { DropDownProvider } from '../../Utility/DropDownContext';
import DropDownComponent from '../../Reusable Components/DropDown';
import FewShotExample from '../../Reusable Components/FewShotExample';
import ModuleHeading from '../../Reusable Components/ModuleHeading';
import { ActionMeta, MultiValue, SingleValue } from 'react-select';
import DynamicForm, { FormField } from '../../Reusable Components/DynamicForm';
import InputComponent from '../../Reusable Components/TextInput';
import ButtonComponent from '../../Reusable Components/Button';
import { Task } from '../../Interfaces/ManagePrompt';
import PopupBuilder from '../../Reusable Components/InputPopUpComponent';
import { StylesConfig } from 'react-select';
// import FewShotExample from './FewShotExample'; // Ensure correct path
// import CustomDropdown from './CustomDropdown'; // Ensure correct path

interface ModelOption {
  value: string;
  label: string;
  model: any;
}


const ManageTaskAndSequence: React.FC = () => {
  const {
    selectedInputs,
    PF,
    HandlePromptHistory,
    promptMessageData,
    setSelectedModel,
    handleOptionClick,
    setAssistantContent,
    setPromptMessageData,
    setShowInputDD,
    setTempUpdatedPrompt,
    editTaskIndex,
    assistantRef,
    setUserContent,
    handleAddTask,
    handlePromptGen,
    userRef,
    handleDragEnd,
    handleFewShotSave,
    setModelError,
    modelData,
    modelError,
    fewShotValues,
    handleFewShotEdit,
    handleFewShotDelete,
    setSelectedInputs,
    tasks,
    handleDeleteTask,
    bindConditionData,
    setInputArray,
    conditionData,
    setDisableEdit,
    setIsWebSearch,
    setIsKbRetriever,
    setFewShotValues,
    setOutputDescription,
    setEditTaskIndex,
    promptData

  } = usePromptBuilder();
  interface Option {
    label: string;
    value: string;
  }
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [taskDescriptionerror,setTaskDescriptionError]=useState("")

  useEffect(() => {
      const handleTooltipPosition = (e: MouseEvent) => {
        const tooltips = document.querySelectorAll('.model-option-tooltip');
        tooltips.forEach((tooltip: any) => {
          const rect = tooltip.getBoundingClientRect();
          const spaceRight = window.innerWidth - e.clientX;
          const spaceBottom = window.innerHeight - e.clientY;
   
          let left = e.clientX + 10;
          let top = e.clientY + 10;
   
          if (spaceRight < rect.width) {
            left = e.clientX - rect.width - 10;
          }
   
          if (spaceBottom < rect.height) {
            top = e.clientY - rect.height - 10;
          }
   
          tooltip.style.left = `${left}px`;
          tooltip.style.top = `${top}px`;
        });
      };
   
      document.addEventListener('mousemove', handleTooltipPosition);
   
      return () => {
        document.removeEventListener('mousemove', handleTooltipPosition);
      };
    }, []);
    const customDropdownStyles: StylesConfig<ModelOption, false> = {
      option: (provided, state) => ({
        ...provided,
        background: state.isFocused
          ? 'linear-gradient(94.22deg, #5C91F7 1.75%, #865BE7 102.67%)'
          : 'var(--select-option-focus-bg, #f0f0f0)',
        color: state.isFocused?"white": 'var(--select-color, #333)',
        ':active': {
          background: 'linear-gradient(94.22deg, #5C91F7 1.75%, #865BE7 102.67%)',
        },
        padding: 0,
      }),
    };
    
  const handleEditTask = (index: number, task: Task): void => {
    setDisableEdit(true);
    setIsWebSearch(task.isWebSearch);
    setIsKbRetriever(task.isKbRetriever);
    setSelectedModel(task.displayName || "");
    let array: any[] =[]
    if (typeof task.fewShotExamples === 'string') {
    array= Array.isArray(JSON.parse(task.fewShotExamples))
      ? JSON.parse(task.fewShotExamples)
      : [];
    }
    else{
   array= Array.isArray(task.fewShotExamples)
      ? task.fewShotExamples
      : [];
    }

    const inputArray: any[] = Array.isArray(task.inputArray)
      ? task.inputArray
      : [];

    const tempArr = Array.isArray(inputArray)
      ? inputArray.map(ele => ele.inputPlaceholder)
      : [];

    setSelectedInputs(tempArr);
    setInputArray(inputArray);
    setFewShotValues(array);
    setPromptMessageData({
      ...promptMessageData,
      promptMessage: task.promptMessage,
      inputKey: task.inputKey,
      inputValue: task.inputValue,
      conditionID: task.conditionID,
      modelConfigurationID: task.modelConfigurationID,
      isWebSearch: task.isWebSearch,
      webContent: task.webContent,
      isKbRetriever: task.isKbRetriever,
      outputDesc: task.outputDescription,
      inputArray: inputArray,
      fewShotExamples: array,
    });

    setOutputDescription(task.outputDescription);
    setEditTaskIndex(index);
    const selectedOption = modelData.find((option:any) => option.modelConfigurationID === task.modelConfigurationID);
    if (selectedOption) {
      setSelectedOption({
        label: selectedOption.displayName,
        value: selectedOption.modelConfigurationID,
      });
    }
    
    
    
  };
  const renderModelOption = (option:any) => (
    <div className="model-option-container">
      <div className="model-option-content">
        {option.label}
      </div>
      <div className="model-option-tooltip">
       {option.modeldescription}
      </div>
    </div>
  );
  let modelNames:any
  if(Array.isArray(modelData)){
 modelNames = modelData?.map((item: any) => ({
    label: item.displayName,
    value: item.modelConfigurationID,
    modeldescription:item.modelDescription
  })) || [];
}
  const handleChange = (option: any) => {
    setModelError("");
    setSelectedOption(option);
    setPromptMessageData(prev => ({
      ...prev,
      modelConfigurationID: option.value
    }));
  };
  const handleDropdownChange = (
    newValue: MultiValue<Option> | SingleValue<Option>,
    actionMeta: ActionMeta<Option>
  ) => {
    let selectedValues: string[];

    if (Array.isArray(newValue)) {
      selectedValues = newValue.map(option => option.value);
    } else if (newValue) {
      selectedValues = [];
    } else {
      selectedValues = [];
    }
    setSelectedInputs(selectedValues);
    const newInputArray = PF.filter(item =>
      selectedValues.includes(item.inputValueOrder)
    ).map(item => ({
      inputPlaceholder: item.inputValueOrder,
      description: item.labelName
    }));

    setInputArray(newInputArray);
  };
  function processString(value: any) {
    if (typeof value !== "string") return "";

    return value.replace(/([A-Z])/g, " $1").trim();
  }
  const OutputDescription: FormField[] = [
    { name: "end-point-config", label: "Output Description", type: "textarea", value: promptMessageData.outputDesc || '', error: "", placeholder: "Enter the Value", required: false, change: (e: any) => {HandlePromptHistory && setPromptMessageData({ ...promptMessageData, outputDesc: e.target.value });setOutputDescription(e.target.value)}, dropdown: false, dropdownfunc: "", dropdownfieldfunc: "", dropdownval: "", id: "promptDescription", disabled: !HandlePromptHistory }
  ]

  const TaskDescription: FormField[] = [
    {
      name: "end-point-config", label: "Task Description", type: "textarea", value: promptMessageData.promptMessage || '', error: "", placeholder: "Example: You will be getting a functional description. Based on that description, generate a reactJs hooks based code.", required: true,displayNameError:taskDescriptionerror, change: (e: any) => {
        if (HandlePromptHistory) {
          setModelError("")
          setTaskDescriptionError("")
          setPromptMessageData({ ...promptMessageData, promptMessage: e.target.value });
          setShowInputDD(false);
          if (editTaskIndex !== null) setTempUpdatedPrompt(e.target.value);
        }
      },
      dropdown: false, dropdownfunc: "", dropdownfieldfunc: "", dropdownval: "", id: "promptDescription", disabled: !HandlePromptHistory
    }
  ]
  const Value: FormField[] = [
    { name: "end-point-config", label: "Value", type: "text", value: promptMessageData.inputValue || '', error: "", placeholder: "Enter the value", required: false, change: (e: any) => setPromptMessageData({ ...promptMessageData, inputValue: e.target.value }), dropdown: false, dropdownfunc: "", dropdownfieldfunc: "", dropdownval: "", id: "inputValue", disabled: !HandlePromptHistory }
  ]
  const WebContent: FormField[] = [
    { name: "end-point-config", label: "Web content", placeholder: "Enter the value", type: "textarea", value: promptMessageData.webContent || '', error: "", required: true, change: (e: any) => HandlePromptHistory && setPromptMessageData({ ...promptMessageData, webContent: e.target.value }), dropdown: false, dropdownfunc: "", dropdownfieldfunc: "", dropdownval: "", id: "inputValue", disabled: !HandlePromptHistory }
  ]
  return (
    <div className="col-md-10" style={{ width: '90%' }}>
      <ModuleHeading text="Manage Task and Sequence" tag="h4" className="mb-3 font-semibold font-20 text-color-2" />
      <ModuleHeading text="Follow the sequence for prompt execution to get the expected results with proper syntax. Please provide as in the placeholder" tag="p" className="text-color-13 font-14 font-medium" />


      <div className="row mb-4">
        {/* Input Fields Section */}
        <div className="col-md-4 col-sm-12 mb-3 pe-4">
          <DropDownProvider isMulti={true}>
            <DropDownComponent
              label="Input fields"
              options={PF.map(item => ({ label: item.inputValueOrder, value: item.inputValueOrder }))}
              value={selectedInputs.map(input => ({ label: input, value: input }))}
              onChange={(newValue: MultiValue<Option> | SingleValue<Option>, actionMeta: ActionMeta<Option>) =>
                handleDropdownChange(newValue, actionMeta)
              }
              disabled={!HandlePromptHistory}
            />
          </DropDownProvider>
        </div>

        {/* Output Description Section */}
        <div className="col-md-4 col-sm-12 mb-3 pe-4">
          <DynamicForm
            fields={OutputDescription}
          />
        </div>

        {/* Task Description Section */}
        <div className="col-md-4 col-sm-12 mb-3 pe-4">
          <DynamicForm
            fields={TaskDescription}
          />
        </div>
      </div>

      {/* Examples Section */}
      <div className="col-md-12" >
        <div className="row row-gap-32px">
          <div className="col-md-12">
            <ModuleHeading text="Example" tag="h4" className="mb-3 font-semibold font-20 text-color-2" />
            <ModuleHeading text="Training data for fine-tuning the AI" tag="p" className="mb-0 text-color-13 font-15 font-medium" />
          </div>

          {/* Few-Shot Examples */}
          {fewShotValues.map((component, index) => (
            <div className="col-md-4" key={index}>
              <FewShotExample
                index={component.index}
                userContent={component.inputs[0].content}
                assistantContent={component.inputs[1].content}
                onEdit={handleFewShotEdit}
                onDelete={handleFewShotDelete}
                HandlePromptHistory={HandlePromptHistory}
              />
            </div>
          ))}

          {/* Add Example Button */}
          {HandlePromptHistory && (
            <div className="col-md-4">
              <div className="dashed-container flex-column d-flex align-items-center justify-content-center p-4"  data-bs-toggle="modal"
                  data-bs-target="#examplePopup" >
                <ButtonComponent className="flex-column d-flex gap-3 font-semibold font-14 color-violet align-items-center shadow-none border-0 bg-none"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#examplePopup" iconSrc="images-new/add-purple-icon.svg" altText="add" text="Add Example"></ButtonComponent>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Form Bottom Section */}
      <div className="row pb-3 mb-2">
        <div className="col-md-4 col-sm-12 mb-3">
        

          <DropDownProvider isMulti={false}>
            <DropDownComponent
              label="key"
              options={promptMessageData.inputKey==""?PF.map(item => ({ label: item.inputValueOrder, value: item.inputKey })):[{ label: "Select", value: "" },...PF.map(item => ({ label: item.inputValueOrder, value: item.inputKey }))]}
              value={promptMessageData.inputKey!=null?{ label: promptMessageData.inputKey!=""?promptMessageData.inputKey:"Select", value: promptMessageData.inputKey }:{ label:"Select", value: "" }}
              onChange={(newValue: SingleValue<Option>, actionMeta: ActionMeta<Option>) => {
                setPromptMessageData({ ...promptMessageData, inputKey: newValue?.label!="Select" && newValue?.label || "" })
              }
              }
              disabled={!HandlePromptHistory}
            />
          </DropDownProvider>
        </div>

        <div className="col-md-4 col-sm-12 mb-4">
        <DropDownProvider isMulti={false}>
            <DropDownComponent
              label="Condition"
              options={promptMessageData?.conditionID == ""?conditionData?.map(item => ({ label:  item?.inputCondition!=""? item?.inputCondition?.charAt(0)?.toUpperCase() + processString(item?.inputCondition?.slice(1)) :item?.inputCondition , value:item?.conditionID})):[{ label: "Select", value: "" },...conditionData?.map(item => ({ label: item?.inputCondition!=""? item?.inputCondition?.charAt(0)?.toUpperCase() + processString(item?.inputCondition?.slice(1)) :item?.inputCondition  , value:item.conditionID}))]}
              value={
                promptMessageData.conditionID !== ""
                  ? {
                      label: conditionData
                        .filter((data) => data.conditionID === promptMessageData.conditionID)
                        ?.map((data) => data.inputCondition)[0]?.charAt(0)?.toUpperCase() + processString(conditionData
                          ?.filter((data) => data.conditionID === promptMessageData?.conditionID)
                          ?.map((data) => data.inputCondition)[0]?.slice(1))=="undefined" ?"Select" :conditionData
                          .filter((data) => data.conditionID === promptMessageData.conditionID)
                          ?.map((data) => data.inputCondition)[0]?.charAt(0)?.toUpperCase() + processString(conditionData
                            ?.filter((data) => data.conditionID === promptMessageData?.conditionID)
                            ?.map((data) => data.inputCondition)[0]?.slice(1)), // Extracting the first matching inputCondition
                      value: promptMessageData.conditionID,
                    }
                  : { label: "Select", value: "Select" }
              }
              onChange={(newValue: SingleValue<Option>, actionMeta: ActionMeta<Option>) => {
                setPromptMessageData({ ...promptMessageData, conditionID:newValue?.label!="Select" && newValue?.value || ""  })
              }
              }
              disabled={!HandlePromptHistory}
            />
            </DropDownProvider>
        </div>

        <div className="col-md-4 col-sm-12 mb-4">
          <DynamicForm
            fields={Value}
          />
        </div>

        <div className="col-md-4 col-sm-12 mb-4">
      
          <DropDownProvider isMulti={false}>
            <DropDownComponent
              required={"true"}

              label="Choose Model "
              disabled={!HandlePromptHistory}
              options={modelNames}
              value={selectedOption} // Set default value to 'issue'
              onChange={(e: any) => { handleChange(e) }}
              renderOption={renderModelOption}
             
            />
          </DropDownProvider>
          {modelError && <div className="span-color">{modelError}</div>}
        </div>

        <div className="col-md-4 col-sm-12 mt-1 mb-4">
          <ModuleHeading text="Need KB?" tag="p" className="font-15 font-semibold text-color-2 mb-1" />
          <div className="form-check form-switch me-5 d-inline-block cursor-pointer">
            <input
              className="form-check-input custom-toggle-input cursor-pointer"
              type="checkbox"
              id="kbSwitch"
              checked={promptMessageData.isKbRetriever}
              onClick={() => HandlePromptHistory && setPromptMessageData({ ...promptMessageData, isKbRetriever: !promptMessageData.isKbRetriever })}
              disabled={!HandlePromptHistory}
            />
            <label className="form-check-label font-14 font-medium text-color-7 ms-2 cursor-pointer" htmlFor="kbSwitch">
              {promptMessageData.isKbRetriever ? "Yes" : "No"}
            </label>
          </div>
        </div>

        <div className="col-md-4 col-sm-12 mt-1 mb-4">
          <ModuleHeading text="Web Search" tag="p" className="font-15 font-semibold text-color-2 mb-1" />
          <div className="form-check form-switch me-5 d-inline-block cursor-pointer">
            <input
              className="form-check-input custom-toggle-input cursor-pointer"
              type="checkbox"
              id="webSearch"
              checked={promptMessageData.isWebSearch}
              onClick={() => HandlePromptHistory && setPromptMessageData({ ...promptMessageData, isWebSearch: !promptMessageData.isWebSearch })}
              disabled={!HandlePromptHistory}
            />
            <label className="form-check-label font-14 font-medium text-color-7 ms-2 cursor-pointer" htmlFor="webSearch">
              {promptMessageData.isWebSearch ? "Yes" : "No"}
            </label>
          </div>
        </div>

        {promptMessageData.isWebSearch && (
          <div className="col-md-4 col-sm-12 mb-3">
            <DynamicForm
              fields={WebContent}
            />
          </div>
        )}
      </div>

      {HandlePromptHistory && (
        <div className="row pb-3 mb-3">
          <div className="custom-btn-parent-mp">
            <div className="custom-btn-child-mp d-flex justify-content-end gap-2">
            <button
                type="button"
                className="btn primary-btn font-14 font-semibold border-radius-5 color-white px-4"
                onClick={() => 
                  {
                    if ((selectedOption as {label: string})?.label === "Select"&&!selectedOption) {
                    setModelError("Select a Model");
                    }
                    if(promptMessageData.promptMessage==""){
                      setTaskDescriptionError("Add the task Description")
                    }
                  if((selectedOption as {label: string})?.label !== "Select" && promptMessageData.promptMessage!=""){
                  handleAddTask();
                  setSelectedModel("Select");
                  handleOptionClick({ displayName: "Select" });
                  setSelectedOption({
                    "label":"Select",
                    "value":""
                  })
                }

                }}
                disabled={
                  promptMessageData.isWebSearch
                  ? promptMessageData.webContent === "" :false
                }
            
              >
                {editTaskIndex !== null ? "Update" : "Add"}
              </button>
              <ButtonComponent className="btn primary-btn font-14 font-semibold border-radius-5 color-white px-4"
                onClick={() => {
                  if(!selectedOption){
                    setModelError("Select a Model");
                    }
                    if(promptMessageData.promptMessage==""){
                      setTaskDescriptionError("Add the task Description")
                    }
                  if(selectedOption && promptMessageData.promptMessage!=""){
                  handlePromptGen();
                  setSelectedModel("Select");
                  handleOptionClick({ displayName: "Select" });
                  }
                }}
                disabled={
                  promptMessageData.isWebSearch
                  ? promptMessageData.webContent === "" :false
                }

                type="button"
                text={editTaskIndex !== null ? "Re-Build Prompt" : "Build Prompt"}></ButtonComponent>
            </div>
          </div>
        </div>
      )}

      {/* Example Popup Modal */}
      <div className="modal fade" id="examplePopup" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg example-popup">
          <div className="modal-content bg-color-4">
            <div className="modal-header border-0">
              <h1 className="modal-title fs-5 text-color-1" id="staticBackdropLabel">Example</h1>
              <button type="button" className="btn-close close-icon-filter" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="row row-gap-32px">
                <div className="col-md-12">
                  <label htmlFor="FewShotUserInput" className="form-label font-15 font-semibold text-color-1 mb-2">Few Shot User Input</label>
                  <textarea
                    className="form-control input-field font-14 font-regular border-radius-3 bg-color-4 custom-border-3 text-color-4 resize-none shadow-none"
                    id="FewShotUserInput"
                    rows={4}
                    placeholder="Enter Input"
                    ref={userRef}
                    onChange={(e) => setUserContent(e.target.value)}
                  />
                </div>
                <div className="col-md-12">
                  <label htmlFor="ExpectedAIResponse" className="form-label font-15 font-semibold text-color-2 mb-2">Expected AI Response</label>
                  <textarea
                    className="form-control input-field font-14 font-regular border-radius-3 bg-color-4 custom-border-3 text-color-4 resize-none shadow-none"
                    id="ExpectedAIResponse"
                    rows={4}
                    placeholder="Enter Input"
                    ref={assistantRef}
                    onChange={(e) => setAssistantContent(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <button type="button" className="btn custom-outline-btn font-14 px-4 py-2 me-3 font-bold" data-bs-dismiss="modal">Cancel</button>
              <button type="button" className="btn primary-btn font-14 font-bold border-radius-5 color-white px-4 py-2" data-bs-dismiss="modal" onClick={handleFewShotSave}>Save</button>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12 mb-5 pb-5">
        <div className="table-responsive rounded-3 transparent-grp hide-scrollbar">
          <DraggableTaskTable
            tasks={tasks}
            onDragEnd={handleDragEnd}
            onAddTask={handleAddTask}
            onDeleteTask={handleDeleteTask}
            onEditTask={handleEditTask}
          />
        </div>
      </div>
    </div>
  );
}

export default ManageTaskAndSequence;