
import React from "react";
import { YesOrNoPopupProps } from "../Interfaces/YesOrNoPopup";
import ButtonComponent from "./Button";


const YesOrNoPopup: React.FC<YesOrNoPopupProps> = ({
  text = "",
  errorMessage = "",
  id,
  type,
  value,
  onConfirm,
  onCancel,
  setShowPopup = () => {},
}) => {
  const getDisplayText = (): string => {
    if (errorMessage) {
      return errorMessage;
    }

    switch (type) {
      case "prompt":
        return `Are you sure you want to delete this prompt?`;
      case "model":
        return `Are you sure you want to ${value || ""}?`;
      case "task":
        return `Do you want KG?`;
      case "issue":
        return `Are you sure you want to handle this issue?`;
      case "backlog":
        return `Are you sure you want to update this backlog item?`;
      case "planner":
        return `Are you sure you don't want to make any changes?`;
      default:
        return text
          ? `Are you sure you want to ${text}?`
          : "Are you sure you want to proceed?";
    }
  };

  const handleConfirm = (): void => {
    if (onConfirm) {
      switch (type) {
        case "prompt":
        case "model":
        case "task":
        case "issue":
        case "backlog":
        case "planner":
          onConfirm(id);
          break;
        default:
          console.warn("Unknown type", type);
      }
    }
  };

  const handleCancel = (): void => {
    if (onCancel) onCancel();
    setShowPopup(false);
  };

  return (
    <div>
      <div
        className="modal fade show"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-modal="true"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered custom-logout-width border-0">
          <div className="modal-content p-3 border-0 bg-color-4">
            <div className="modal-header border-0 pt-2 pb-3 display-flex justify-content-end">
              <ButtonComponent
                altText="Close"
                className="btn-close"
                onClick={handleCancel}
              />
            </div>
            <div className="modal-body pt-2">
              <div className="row">
                <div className="text-center">
                  <p className="mb-3 font-20 font-medium mt-2 text-color-2">
                    {getDisplayText()}
                  </p>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0 d-flex justify-content-center pb-3">
              <ButtonComponent
                buttonType="text"
                text={errorMessage ? "Try again" : "Yes"}
                className="danger-bg btn-danger border-0 text-white font-14 font-medium px-4 me-2 py-2"
                onClick={handleConfirm}
              />
              {!errorMessage && (
                <ButtonComponent
                  buttonType="text"
                  text="No"
                  className="btn btn-outline-secondary font-14 px-4 py-2 me-3 font-medium"
                  onClick={handleCancel}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
};

export default YesOrNoPopup;