import React, { useState, useEffect } from 'react';
import ButtonComponent from './Button';

interface ToastrProps {
  message: string;
  level: 'Success' | 'Failure';
  duration?: number;
  onClose?: () => void;
  showCloseButton?: boolean;
}

const Toastr: React.FC<ToastrProps> = ({ message, level, duration = 6000, onClose, showCloseButton = true }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    let timer: any;
    if (showCloseButton) {
      timer = setTimeout(() => {
        setIsVisible(false);
        if (onClose) onClose();
      }, duration);
    }
    return () => clearTimeout(timer);
  }, [duration, onClose, showCloseButton]);

  const handleClose = () => {
    setIsVisible(false);
    if (onClose) onClose();
  };

  if (!isVisible) return null;

  const isDarkTheme = document.body.classList.contains('dark-theme');
  const themeClass = isDarkTheme ? 'toastr-dark' : 'toastr-light';
  const iconColor = level === 'Success' ? '#4CAF50' : '#F44336';

  return (
    <div className={`toastr ${themeClass} ${level.toLowerCase()}`}>
      <div className="toastr-content">
        <div className="toastr-icon">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill={iconColor} /> {/* Background Circle */}
            {level === 'Success' ? (
              <path d="M9.00014 16.17L4.53014 11.7L3.47014 12.76L9.00014 18.29L21.0001 6.28998L19.9401 5.22998L9.00014 16.17Z" fill="white"/>
            ) : (
              <>
                <path d="M18.0001 6L6.00014 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M6.00014 6L18.0001 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </>
            )}
          </svg>
        </div>
        <p className="toastr-message">{message}</p>
      </div>
      {showCloseButton && <ButtonComponent
                buttonType="icon"
                iconSrc="images/Default/flter-close-icon.svg"
                altText="Close filter"
                className="cursor-pointer close-icon-filter p-0 border-0 bg-transparent"
                style={{
                    width: '16px',
                    height: '16px',
                    minWidth: 'unset',
                    minHeight: 'unset',
                    padding: 0,
                    lineHeight: 1,
                }}
                onClick={handleClose}
                />}
    </div>
  );
};

export default Toastr;