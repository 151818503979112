

import { useMsal, useAccount } from '@azure/msal-react';
import { Route, Routes, useLocation, BrowserRouter as Router, matchPath } from 'react-router-dom';
import { useContext } from 'react';
import UserContext from './Auth/UserContext';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
  children: React.ReactNode; 
  requiredAccess?: any; 
}
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, requiredAccess }) => {
  
  // Access MSAL account information
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || null);

  // Get the current location for redirection purposes
  const location = useLocation();

  // Check if the user is logged in via localStorage
  const login = localStorage.getItem("login");

  // Access user details from the UserContext
  const userDetails = useContext(UserContext);

  // Redirect to the login page if the user is not authenticated
  if (!account && login !== "true") {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  // Check if the user has the required access level
  if (requiredAccess && userDetails?.role) {
    if (userDetails.role !== requiredAccess) {
      return <Navigate to="/access-denied" replace />;
    }
  }

  // Render the children if all checks pass
  return <>{children}</>;
};

export default ProtectedRoute;