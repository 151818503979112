import React, { useState, useCallback, useEffect } from 'react';
import { usePromptBuilder } from '../../Utility/ManagePromptContext';
import DynamicForm, { FormField } from '../../Reusable Components/DynamicForm';
import DraggableList from '../../Reusable Components/DraggableList';
import AddKnowledgeBase from '../../Reusable Components/AddKnowledgeBase';
import MQRenderInput from './MQRenderInput';
import ButtonComponent from '../../Reusable Components/Button';
import { KnowledgeBaseProvider } from '../../Utility/AddKnowledgeBaseContext';


const ManageQuestions: React.FC = () => {
    
    const {
        PF,
        updatePromptData,
        aiReq,
        setToggleDropDown,
        displayQuestion,
        submitType,
        setAiReq,
        error,
        required,
        setRequired,
        toggleDropDown,
        checkbox,
        sethitAiVar,
        setCheckbox,
        setPF,
        promptFieldData,
        setPromptFieldData,
        disableKBEdit,
        fieldIndex,
        setSubmitType,
        dropdown,
        showPage,
        setShowPage,
        selectedOption,
        setSelectedOption,
        setPromptLogoValues,
        logoObj,
        onChangeData,
        hiddenIndices,
        setHiddenIndices,
        addOption,
        setAddOption,
        promptObj,
        promptFieldObj,
        setPromptIndex,
        setPromptMessagePage,
        setDropdownOpen,
        practiceName,
        setPracticeName,
        handleCopyClick,
        handleDeleteTask,
        
        dropdownOpen,
        promptLogoValues,
        errorData,
        setKnowledgeData,
        promptMessageObj,
        promptMessagePage,
        promptData,
        setLongAnswer,
        setDisplayQuestions,
        editQuestion,
        setEditQuestion,
        errorObj,
        setTasks,
        handleDrag,
        editChoices,
        setEditChoices,
        setPromptMessageData,
        handleSystemMessageUpdate,
        setDisableKBEdit,
        handlePromptChange,
        setFieldIndex,
        setHeartBeat,
        longAnswer,
        promptLogoData,
        heartBeat,
        promptName,
        setPromptName,
        setDropdown,
        promptIndex,
        setError,
        setErrorData,
        handleDragEnd,
        HandlePromptHistory,
        userDetails,
        editedPromptID,
        hitAiVar,
        setPromptData,
        inputTypeData, // Ensure correct context usage
        bindPromptLogo,
    } = usePromptBuilder();

    const [choices, setChoices] = useState<string>("radio");
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showKnowledgeBaseModal, setShowKnowledgeBaseModal] = useState(false);
    const [logodropdown, setlogodropdown] = useState(false)
    const [isPromptInitialized, setIsPromptInitialized] = useState(false);
    
    const questionTypes = [
        { name: 'Text', code: 'TXT' },
        { name: 'Choice', code: 'CHC' },
        { name: 'Tech Stack', code: 'TS' },
        { name: 'Upload', code: 'UF' },
    ];


    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };


    const handleAddQuestion = (code: string) => {
    
        let inputTypeRefCode = code;
        if (code === 'CHC') {
            inputTypeRefCode = 'RB'; // Default to radio button for Choice
        }

        const id = inputTypeData?.find((val: any) => val?.inputTypeRefCode === inputTypeRefCode)?.inputTypeID || '';

        setPromptFieldData((pre: any) => ({
            ...pre,
            inputTypeID: id,
            promptFieldOrder: PF.length + 1,
            promptFieldID: '',
            inputTypeRefCode,
            inputValueOrder: `input-${PF.length + 1}`,
            hitAI: hitAiVar,
            modifiedBy: userDetails?.email,
        }));

        setSelectedOption(code);
        setDropdown(false);
        setCheckbox(false);
        setDropdownOpen(false);
    };
    

    const inputname = (e: any) => {
        const value = e.target.value;
        errorData.promptNameError=""

        promptData.promptName=e.target.value
        promptObj.promptName=e.target.value
        if(promptIndex){
        promptIndex.promptName=e.target.value
        }
        setPromptName(value);
        if(value!=""){
        setDisableKBEdit(value.trim() === "");
        handlePromptChange(e);
        setPromptName(value);
        updatePromptData('promptName', value);
        }
    }


    const nameField: FormField[] = [
        { 
            className: "form-control theme-focus input-field text-color-4 bg-color custom-border-3 rounded-2", 
            name: "end-point-config", 
            id: "promptName", 
            label: "Prompt Name", 
            type: "text", 
            value: promptData.promptName || '', 
            displayNameError: errorData.promptNameError, 
            placeholder: "Enter name of the prompt", 
            required: true, 
            change: inputname, 
            dropdown: false, 
            dropdownfunc: "", 
            dropdownfieldfunc: "", 
            dropdownval: "", 
        },
    ];
    

    const chooseLogo: FormField[] = [
        { 
            name: "end-point-config", 
            label: "Choose logo", 
            type: "imagedropdown", 
            value:promptData?.logo?.target?.value||promptLogoValues.logoName|| '', 
            error: errorData.promptLogoIDError, 
            placeholder: "Select", 
            required: true, 
            change: (value: any) => {
                errorData.promptLogoIDError=""
                bindPromptLogo(value);
                updatePromptData('logo', value);
            }, 
            dropdown: logodropdown, 
            dropdownfunc: setlogodropdown, 
            dropdownfieldfunc: bindPromptLogo, 
            dropdownval: promptLogoData,
            disabled: !HandlePromptHistory 
        }
    ];
    const Description: FormField[] = [
        { 
            name: "end-point-config", 
            label: "Description", 
            type: "textarea", 
            value: promptData.promptDescription || '', 
            error: "", 
            placeholder: "Enter description of the prompt", 
            required: false, 
            change: (e: any) => { 
                handlePromptChange(e); 
                updatePromptData('promptDescription', e.target.value);
            }, 
            dropdown: false, 
            dropdownfunc: "", 
            dropdownfieldfunc: "", 
            dropdownval: "", 
            id: "promptDescription", 
            disabled: !HandlePromptHistory 
        }
    ]

    const SystemMessage: FormField[] = [
        { 
            name: "end-point-config", 
            label: "System Message", 
            type: "textarea", 
            value:promptData.systemMessage || '', 
            error: "", 
            placeholder: "This prompt explicitly instructs the user to input a system message that describes the role or function of the AI assistant, such as providing information (Ex:You are an AI assistant that helps people find information)", 
            required: false, 
            change: (e: any) => { 
                handlePromptChange(e);
                updatePromptData('systemMessage', e.target.value);
            }, 
            dropdown: false, 
            dropdownfunc: "", 
            dropdownfieldfunc: "", 
            dropdownval: "", 
            id: "systemMessage", 
            disabled: !HandlePromptHistory 
        }
    ]


  
   
    interface PromptField {
        promptFieldOrder: number;
        inputValueOrder: string;
        [key: string]: any;
    }

    interface PromptData {
        promptFields: PromptField[];
        editedPromptID: string;
        modifiedBy: string;
        [key: string]: any;
    }


    const handleDelete = (index: number) => {
        setPF((prevPF: PromptField[]) => {
            const updatedPF = [...prevPF];
            updatedPF.splice(index, 1);

            // Update promptFieldOrder and inputValueOrder for the remaining items
            const updatedPFWithOrders = updatedPF.map((item, newIndex) => ({
                ...item,
                promptFieldOrder: newIndex + 1,
                inputValueOrder: `input-${newIndex + 1}`,
            }));

            setPromptData((prevPromptData: PromptData) => ({
                ...prevPromptData,
                promptFields: updatedPFWithOrders,
                editedPromptID: editedPromptID,
                modifiedBy: userDetails?.email || ''
            }));

            return updatedPFWithOrders;
        });
    };

    function onclickEdit(key: any, index: any) {
        setEditQuestion(true);
        setRequired(key?.required === "true");
        setAiReq(key?.hitAI === 1);
        setHiddenIndices([...hiddenIndices, index]);
        setLongAnswer(key.inputTypeRefCode === "LA" || key.inputTypeRefCode === "CS");
        setDropdown(key.inputTypeRefCode === "DD");
        setCheckbox(key.inputTypeRefCode === "CB");
        setPromptFieldData(key);
        setFieldIndex(index + 1);
        setSelectedOption(key?.inputTypeRefCode);
        setAddOption(key?.choiceName?.length || 2);
        document.getElementById("labelName")?.focus();
        setPromptFieldData(key);
        setFieldIndex(index + 1);
        setSelectedOption(key?.inputTypeRefCode);
        setAddOption(key?.choiceName?.length || 2);
        document.getElementById("labelName")?.focus();

    
    }
    
    function clearall() {
        setShowPage("manage prompt");
        setPromptData(promptObj);
        setPromptFieldData(promptFieldObj);
        setSubmitType("");
        setPromptIndex(null);
        setHiddenIndices([]);
        setPromptMessageData(promptMessageObj);
        setPF([]);
        setTasks([]);
        setPromptMessagePage("");
        setPromptLogoValues(logoObj);
        setKnowledgeData([]);
        setErrorData(errorObj);
        setError("");
        setPracticeName("");
    }



    return (
        <div className="container-fluid">
            <div className="row justify-content-lg-end justify-content-center">
                <div className={"Close" == "Close" ? "col-12 col-lg-11 me-xl-3 px-3" : "col-lg-9 col-11 me-sm-0 me-md-3 cust-me-lg-4 me-xl-4"}>
                    <div className="row justify-content-center">
                        {promptMessagePage == "" && "showManagePrompt" === "showManagePrompt" ? (
                            <div className="col-md-12">
                                <h3 className="font-24 font-semibold text-color-1 mt-3 mb-5">
                                    {promptIndex ? `Update ` : `Create New`} Prompt
                                </h3>
                                <div className="row">
                                    <div className="col-md-10 col-sm-12 mb-3">
                                        <DynamicForm
                                            fields={nameField}
                                        />
                                    </div>
                                    <div className="col-md-2 cursor-pointer">
                                        <DynamicForm
                                            fields={chooseLogo}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 col-sm-12 mb-3">
                                    <DynamicForm
                                        fields={Description}
                                    />
                                </div>


                                <div className="row custom-border-bottom-4 pb-3 system-message-container">
                                    <div className="col-md-12 col-sm-12 mb-3">
                                        <>
                                            <div className="position-relative">
                                                <DynamicForm
                                                    fields={SystemMessage}
                                                />
                                                <ButtonComponent
                                                    buttonType='icon'
                                                    imgClass={heartBeat ? `system-message-rot001` : ""}
                                                    altText="ask-asami"
                                                    iconSrc="images-new/ask-asami-btn.svg"
                                                    className={`border-0 bg-none system-message position-absolute add-textarea-btn sys-message-fix ${heartBeat ? "system-message-rot001" : ""}`}
                                                    onClick={(e) => {
                                                        if (promptData.systemMessage.length > 1) {
                                                            setHeartBeat(true)
                                                            handleSystemMessageUpdate()
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </>

                                    </div>
                                </div>
                                   
                                    <div className="col-md-12 col-sm-12 mb-3">
                                        <h3 className="font-20 text-color-2 font-bold my-4">Knowledge Base</h3>
                                        <p className="font-15 font-medium text-color-9">
                                        Elevate your prompts with precision – seamlessly integrate and manage knowledge bases for enhanced task execution.
                                        </p>
                                        <button
                                        type="button"
                                        disabled={disableKBEdit}
                                        className="btn primary-btn font-14 font-semibold border-radius-5 color-white py-2 px-3 mb-3"
                                        onClick={() => setShowKnowledgeBaseModal(true)}
                                        >
                                        Add Knowledge Base
                                        </button>
                                    </div>
                                    
                                    <KnowledgeBaseProvider>
                                        <AddKnowledgeBase
                                            show={showKnowledgeBaseModal}
                                            onHide={() => setShowKnowledgeBaseModal(false)}
                                            existingKbUrls={promptData?.kbUrl}
                                            currentChatID=''
                                        />
                                    </KnowledgeBaseProvider>

                                <div
                                    className="row"
                                >
                                    <h3 className="font-20 font-semibold text-color-2 mt-5 mb-4">
                                        Manage Questions
                                    </h3>
                                   
                                </div>
                                {selectedOption && (
                                    <div>
                                        <div className="row custom-border-bottom-4 pb-3">
                                            <div className="col-md-12 col-sm-12 mb-3">
                                                <label
                                                    htmlFor="Enter-Question-1"
                                                    className="form-label label-sty text-color-2"
                                                >
                                                    {fieldIndex ? fieldIndex : PF.length + 1}. Enter Question
                                                </label>
                                                {errorData.labelNameError && (
                                                    <h4>
                                                        <div className="span-color">{errorData.labelNameError}</div>
                                                    </h4>
                                                )}
                                                <input
                                                    type="text"
                                                    className="form-control theme-focus input-field mb-3 bg-color text-color-4 custom-border-3 cust-search-input"
                                                    id="labelName"
                                                    placeholder="Specify the Question"
                                                    value={promptFieldData.labelName}
                                                    onChange={(e) => onChangeData(e)}
                                                />
                                            </div>

                                            <MQRenderInput
                                                selectedOption={selectedOption}
                                                editChoices={promptFieldData.choiceName || []} // Add this line
                                                addOption={addOption}
                                                onChangeData={onChangeData}
                                                addOptionProp={() => setAddOption(prev => prev + 1)}
                                                removeOptionProp={() => setAddOption(prev => prev > 2 ? prev - 1 : prev)}
                                                longAnswer={longAnswer}
                                                setLongAnswer={setLongAnswer}
                                                dropdown={dropdown}
                                                setDropdown={setDropdown}
                                                checkbox={checkbox}
                                                setCheckbox={setCheckbox}
                                                required={required}
                                                setRequired={setRequired}
                                                aiReq={aiReq}
                                                setAiReq={setAiReq}
                                                hitAiVar={hitAiVar}
                                                sethitAiVar={sethitAiVar}
                                                promptFieldData={promptFieldData}
                                                setPromptFieldData={setPromptFieldData}
                                                inputTypeData={inputTypeData}
                                                setSelectedOption={setSelectedOption}
                                                userDetails={userDetails}
                                                updateChoiceName={(newChoices) => { // Add this function
                                                    setPromptFieldData((prev:any) => ({
                                                    ...prev,
                                                    choiceName: newChoices
                                                    }));
                                                }}
                                                />
                                        </div>

                                        <div className="row">
                                            <div className="d-flex justify-content-between mt-4" style={{ marginBottom : '2%'}} >
                                                <div className="d-flex align-items-center">
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-secondary font-14 font-semibold me-3"
                                                        onClick={() => {
                                                            setSelectedOption("");
                                                            setLongAnswer(false)
                                                            setFieldIndex("");
                                                            setPromptFieldData(promptFieldObj);
                                                            setLongAnswer(!longAnswer)
                                                            setEditChoices([]);
                                                            setHiddenIndices([]);
                                                            setRequired(false);
                                                            setAiReq(false);
                                                            setKnowledgeData([]);
                                                            setDisplayQuestions(false);
                                                            setIsOpen(false);
                                                            setEditQuestion(false);
                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="button"
                                                        id="add-field-button"
                                                        className="btn primary-btn font-14 font-semibold theme-focus me-3"
                                                        disabled={promptFieldData.labelName == "" || ((selectedOption == "RB" || selectedOption == "DD" || selectedOption == "CB") && (promptFieldData.choiceName.length != addOption || promptFieldData.choiceName.some((element: string) => element === "")))}
                                                        onClick={() => {
                                                            errorData.PromptField=""
                                                            if (promptFieldData.labelName) {
                                                                let promptArr = PF;
                                                                setEditQuestion(false);
                                                                if (fieldIndex) {
                                                                    promptArr[fieldIndex - 1] = promptFieldData;
                                                                } else {
                                                                    let promptFieldDataObj = promptFieldData;
                                                                    promptFieldDataObj.createdBy = userDetails?.email;
                                                                    promptArr.push(promptFieldDataObj);
                                                                }
                                                                setPF(promptArr);
                                                            }
                                                            setSelectedOption("");
                                                            setFieldIndex("");
                                                            setLongAnswer(false)
                                                            setCheckbox(false);
                                                            setAddOption(2);
                                                            setDropdown(false);
                                                            setChoices("radio");
                                                            setHiddenIndices([]);
                                                            setPromptFieldData((pre: any) => ({
                                                                ...pre,
                                                                labelName: "",
                                                                choiceName: [],
                                                                required: "",
                                                                hitAI: 0,
                                                                errorMessage: "",
                                                                modifiedBy: userDetails?.email
                                                            }));
                                                            setEditChoices([]);
                                                            setRequired(false);
                                                            setAiReq(false);
                                                            sethitAiVar(0);
                                                            setDisplayQuestions(false);
                                                            setError("");
                                                        }}
                                                    >
                                                        {`${promptIndex ? `UPDATE` : `ADD`}`}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <DraggableList
                                    items={PF}
                                    onDragEnd={handleDrag}
                                    onEdit={onclickEdit}
                                    onCopy={handleCopyClick}
                                    onDelete={handleDelete}
                                    hiddenIndices={hiddenIndices}
                                    HandlePromptHistory={HandlePromptHistory}
                                />
                                {HandlePromptHistory && (
                                    <div className="dropdown mt-3 mb-5">
                                        <button
                                            className="btn theme-outline-btn font-14 font-semibold theme-focus primary-color"
                                            type="button"
                                            disabled={editQuestion !== false}
                                            onClick={() => setDropdownOpen(!dropdownOpen)}
                                        >
                                            Add a Question
                                        </button>
                                        {errorData.PromptField && (
                                                    
                                                        <div className="span-color">{errorData.PromptField}</div>
                        
                                                )}
                                        {dropdownOpen && (
                                            <ul className="dropdown-menu show drop-down-fix-mp">
                                                {questionTypes.map((type) => (
                                                    <li key={type.code}>
                                                        <button
                                                            className="dropdown-item drop-down-fix-mp-li"
                                                            onClick={() => handleAddQuestion(type.code)}
                                                        >
                                                            {type.name}
                                                        </button>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                )}
                            </div>

                        )
                            : (<></>)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageQuestions;