// Retrieves the tenant ID from environment variables for Azure AD authentication
const tenantENV = process.env.REACT_APP_TENANT_ID;

// Retrieves the client/application ID from environment variables for Azure AD authentication
const clientENV = process.env.REACT_APP_CLIENT_ID;


// Object containing decoded tenant ID and client ID for authentication
export const mdata = {
  tenant_id: getReportData(tenantENV),
  clientId: getReportData(clientENV)
}

// Decodes an encoded string by extracting numeric values, performing base64 decoding,
// and converting character codes to create the final decoded string
function getReportData(es) {

  let pi = []
  let rs = ""
  let al = ""

  for (let i = es.length - 1; i >= 0; i--) {
    if (!isNaN(es.split("")[i])) {
      al += es.split("")[i]
    }
    else {
      break
    }
  }

  al = al.split('').sort().join('')
  let pl
  pl = es.slice(((es.length - 1) - al), es.length)
  let dp = ""

  for (let i = 0; i < pl.length; i++) {
    if (isNaN(pl.split("")[i])) {
      dp += pl.split("")[i]
    }
    else {
      break;
    }
  }

  dp = atob(dp)
  es = es.split(pl)[0]
  es = atob(es).split(',')
  let ea = es.slice(0, dp)
  pi = es.slice(dp, es.length)

  for (let i = 0; i < ea.length; i++) {
    if (ea[i].length > 3) {
      ea[i] = ea[i].split('').slice(0, 3).join('')
    }
  }

  pi.forEach((e) => {
    ea[e] = ea[e]?.slice(0, 2)
  })

  ea.forEach((e) => {
    rs += String.fromCharCode(e)
  })

  return rs;
}

const devConfig = {

  phaseAi: {
    
    downloadExcel: "https://devapp.asami.ai/chat/getExcelURL", 
    getPresignedURL:"https://devapp.asami.ai/chat/getPresignedURL", 
    getS3URL: "https://devapp.asami.ai/chat/getS3URL",
    getVideoResponse: "https://devapp.asami.ai/chat/getVideoResponse",
    getBindParams: "https://devapp.asami.ai/chat/getBindParams",
    calculateCost: "https://devapp.asami.ai/chat/calculateCost",
    loadBalancer: "https://devapp.asami.ai/chat/LoadBalancer",

    insertChat: "https://devapp.asami.ai/chat/insertChat", 
    getChat: "https://devapp.asami.ai/chat/getChat",
    postMessage: "https://devapp.asami.ai/chat/postMessage",

    serpAPI: "https://devapp.asami.ai/chat/serpApi",
    kbRetriever: "https://devapp.asami.ai/chat/kbRetriever",
    kbUpload: "https://devapp.asami.ai/mp/kbUpload",
    
    activeuser : "https://devapp.asami.ai/grid/getActiveUsers",
    promptUsageFetch : "https://devapp.asami.ai/grid/getPromptUsage",
    fixedResponseFetch : "https://devapp.asami.ai/grid/getFixedResponse",
    getAsamiUsage : "https://devapp.asami.ai/grid/getAsamiUsage",
    costAnalysis : "https://devapp.asami.ai/grid/getCost",
    getApprovalRequestData: "https://devapp.asami.ai/grid/getApprovalRequest",
    getChatHistory: "https://devapp.asami.ai/grid/getChatHistory",

    allProjects:"https://qabe-avalon.avasoft.com/allProjects",
    fetchTask:"https://qabe-avalon.avasoft.com/fetchTask",
    fetchIssues:"https://qabe-avalon.avasoft.com/fetchIssues",

    fetchProjectConfig : "https://devapp.asami.ai/grid/getProjects",
    getTaskConfig : "https://devapp.asami.ai/grid/getTasks",
    getIssuesConfig : "https://devapp.asami.ai/grid/getIssues",

    functionalDescription: "https://devapp.asami.ai/tfs/createKG",
    similarityCheck : "https://devapp.asami.ai/tfs/similaritySearch",
    configRoute : "https://devapp.asami.ai/tfs/getTFSConfig",
    postTfsConfig: "https://devapp.asami.ai/tfs/insertProjectConfig",
    configureOrUpdate: "https://devapp.asami.ai/tfs/insertTfsConfig",
    getRepoandBranch : "https://devapp.asami.ai/tfs/getRepoandBranch",

    d2Service : "https://devapp.asami.ai/chat/replaceD2Image",
    krokiService : "https://kroki.io/d2/svg",
    plantUMLKroki:"https://kroki.io/plantuml/svg",

    insertModelConfiguration: "https://devapp.asami.ai/grid/insertModelConfig",
    getModelConfiguration:"https://devapp.asami.ai/grid/getModelConfig",
    getAllModelConfiguration: "https://devapp.asami.ai/grid/getAllModelConfig",
    deleteModelConfiguration: "https://devapp.asami.ai/grid/deleteModelConfig",

    insertGetFeedBack : "https://devapp.asami.ai/mp/insertGetFeedback",
    fetchPromptData : "https://devapp.asami.ai/mp/getPromptDetails",
    getPromptFieldJson: "https://devapp.asami.ai/mp/getPromptFieldJson",
    insertPrompt: "https://devapp.asami.ai/mp/insertPrompt",
    deletePrompt: "https://devapp.asami.ai/mp/deletePrompt",
    getPromptHistory: "https://devapp.asami.ai/mp/getPromptHistory",
    getPractice: "https://devapp.asami.ai/mp/getPractice",
    getInputTypeAndTechStack: "https://devapp.asami.ai/mp/getInputTypeAndTechStack",

    getRules: "https://devapp.asami.ai/mp/getRules",
    postRules: "https://devapp.asami.ai/mp/postRules", 

    promptGen : "https://devapp.asami.ai/hub/generatePrompt",
    systemMessageGen : "https://devapp.asami.ai/hub/generateSysMsg",
    inputEnhancer :"https://devapp.asami.ai/hub/enhanceInput",

    stream_loadBalancer:"https://devapp.asami.ai/lb/streamLoadBalancer",
    processRequirement:"https://devapp.asami.ai/mp/process-requirement",
    processChat : "https://devapp.asami.ai/chat/process", 
    insertErrorLogs: "https://devapp.asami.ai/mp/insertErrorLog",
    baseurl:"https://devapp.asami.ai",
  
  },

  phaseAi2: {
    getPlanner: "https://devapp.asami.ai/valyria/getplanner", 
    getTerminalInput:  "https://devapp.asami.ai/valyria/getterminalcommands", 
    getvdocs: "https://devapp.asami.ai/valyria/getvdocs", 
    updatevdoc: "https://devapp.asami.ai/valyria/updatevdocs",
    getbrowserurl: "https://devapp.asami.ai/valyria/getbrowserurl",
    userchat:"https://devapp.asami.ai/valyria/userchat",
    downloadcode:"https://devapp.asami.ai/valyria/downloadcode",
    getdesign:"https://devapp.asami.ai/valyria/getdesign",
    getcode:"https://devapp.asami.ai/valyria/getcode",
    uploaddesign:"https://devapp.asami.ai/valyria/uploaddesign",
    uploadfrd:"https://devapp.asami.ai/valyria/uploadfrd",
    uploadhtml:"https://devapp.asami.ai/valyria/uploadhtml",
    uploadimage:"https://devapp.asami.ai/valyria/uploadimage",
    getchatinput:"https://devapp.asami.ai/valyria/getchatinput",
    editplanner:"https://devapp.asami.ai/valyria/editplanner",
    layertwovl:"https://devapp.asami.ai/valyria/layer-two-vl", 
    orchestrate: "https://devapp.asami.ai/orchestrate",
    valyriaId:"https://devapp.asami.ai/chat/getID",
  }, 
  
  roleConfig: {
    LeadershipDL: "54e13cf7-521b-4ca5-a457-06a8208f0027",
    LeadershipGroup: "53d5f124-34a5-45a7-900a-6402799920dc",
    LeadGroup: "e7be7b3a-e524-461b-a9b1-15fe6705deff",
    ScopeID: "api://2214b374-39be-4eec-9ca0-3c6bdac6e02a/CDGEN",
  },

};

export default devConfig;