import React from 'react';
import { DragDropContext, Droppable } from "@hello-pangea/dnd";
import DraggableItem from './DraggableItems';
import { DraggableListProps } from '../Interfaces/Types';
 
const DraggableList: React.FC<DraggableListProps> = ({
  items,
  hiddenIndices,
  HandlePromptHistory,
  onDragEnd,
  onEdit,
  onCopy,
  onDelete,
}) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((item, index) => (
              <DraggableItem
                key={index}
                item={item}
                index={index}
                isDragDisabled={index === 0}
                onEdit={onEdit}
                onCopy={onCopy}
                onDelete={onDelete}
                isHidden={hiddenIndices.includes(index)}
                HandlePromptHistory={HandlePromptHistory}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
 
export default DraggableList;