import React from 'react';
import axios from 'axios';
import { getS3URL, uploadHtml, uploadImage } from '../../../Service/API';
import { lean_right } from 'mermaid/dist/rendering-util/rendering-elements/shapes/leanRight';

interface FileUploadProps {
  type: string;
  userId: string;
  projectId: number;
  onUpload: (files: any) => void;
  onImageUpload: () => void
}

const FileUpload: React.FC<FileUploadProps> = ({ type, userId, projectId, onUpload, onImageUpload }) => {
    
    let fileArray: any
    const formData = new FormData();
    const fileNames: string[] = [];
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = event.target.files;

      fileArray = Array.from(files);
        if (type === 'uploadHTML') { 
          for (const file of fileArray) {
            const fileName = file.name;
            fileNames.push(fileName);
          
            // Append file to formData
            formData.append('files', file);
          }
          upload_html(formData);
        } else if (type === 'uploadImage') {
          for (const file of fileArray) {
        
            const base64Data = await convertToBase64(file);
    
            const payload = {
              user_id: userId,
              project_id: projectId,
              files: 
                {
                  base64Data:base64Data,
                },
            };
    
            upload_image(payload);
        }
      }
    }
  };

  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const upload_html = async (payload: any) => {
    try {
        
      const response = await getS3URL(payload);
      
      onUpload(response.ResponseData.extracted_urls[0]);
    } catch (error) {
      console.error('Error uploading file', error);
    }
  };
  const upload_image = async (payload: any) => {
    try {
        
      const response = await uploadImage(payload);
      
      // onUpload(response);
      onImageUpload()
    } catch (error) {
      console.error('Error uploading file', error);
    }
  };

  return (
    <div>
      <label>
        {type === 'uploadHTML' ? 'Upload HTML File' : 'Upload Image'}
        <input type="file" onChange={handleFileChange} />
      </label>
    </div>
  );
};

export default FileUpload;