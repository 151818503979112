import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { PromptCard, Practice, PromptHistoryItem, InputType, PromptFieldData, ManagePromptCardContextType } from '../Interfaces/ManagePrompt';
import { fetchPrompt, getPractice, fetchPromptHistory, restoreManagePrompt, getPromptHistory, deletePrompt } from "../Service/API";
import { useNavigate } from 'react-router-dom';

// Creates a context for managing prompt cards
const ManagePromptCardContext = createContext<ManagePromptCardContextType | undefined>(undefined);

// Provides the context for managing prompt cards
export const ManagePromptCardProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // State for managing prompt cards and UI visibility
  const [promptCards, setPromptCards] = useState<PromptCard[]>([]);
  const [showPage, setShowPage] = useState('');
  const [practiceID, setPracticeID] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [promptIndex, setPromptIndex] = useState<PromptCard | null>(null);
  const [popUpText, setPopUpText] = useState('');
  const [handlePromptHistory, setHandlePromptHistory] = useState(true);

  // State for practice-related data
  const [practiceName, setPracticeName] = useState('');
  const [practiceDetails, setPracticeDetails] = useState<Practice[]>([]);

  // State for loading and search functionality
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');

  // Sample user details (can be replaced with actual user context)
  const [userDetails] = useState({ email: 'user@example.com' });

  // State for input type and prompt field data
  const [inputTypeData, setInputTypeData] = useState<InputType[]>([]);
  const [PF, setPF] = useState<PromptFieldData[]>([]);

  // State for sorting and filtering prompts
  const [sortname, setSortname] = useState("promptName");
  const [sortvalue, setSortvalue] = useState("ASC");
  const [promptSort, setPromptSort] = useState(true);

  // State for tracking modifications and timestamps
  const [modifiedBy, setModifiedBy] = useState(false);
  const [timeStamp, setTimeStamp] = useState(false);

  // State for prompt IDs and names
  const [promptid, setPromptid] = useState<string>("");
  const [promptdataid, setPromptDataId] = useState<string>("");
  const [promptdataName, setPromptDataName] = useState<string>("");

  // State for managing prompt card data
  const [promptCardData, setPromptCardData] = useState<PromptCard[]>([]);
  const [isconfig, setisconfig] = useState(false);

  // Hook for navigation
  const navigate = useNavigate();

  // Fetches prompt data based on the practice ID and search query
  const fetchPromptData = async () => {
    try {
      setIsLoading(true);
      const query = new URLSearchParams(window.location.search);
      const practiceId = query.get('practiceID') || promptdataid;
      const response = await fetchPrompt({"promptId":"","practiceID":practiceId,"searchdata":search});
      setPromptCardData(response?.data?.responseData?.ResponseData?.prompts);
    } catch (error) {
      console.error(error);
      setShowPage("error");
    } finally {
      setIsLoading(false);
    }
  };

  // Fetches practice details based on the search query
  const fetchPractice = async () => {
    try {
      setIsLoading(true);
      const response = await getPractice({ search, page: 100 });
      if (response?.data?.responseData?.PracticeDetails) {
        setPracticeDetails(response?.data?.responseData?.PracticeDetails);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Creates a new prompt field with default values for chat name
  const handleCreatePrompt = () => {
    let id = "";
    inputTypeData.forEach((val) => {
      if (val.inputTypeRefCode === "TXT") {
        id = val.inputTypeID;
      }
    });

    const promptFieldDataObj = {
      labelName: "Chat Name",
      inputTypeID: id,
      promptFieldID: "",
      inputTypeRefCode: "TXT",
      promptFieldOrder: "1",
      placeHolder: "Enter the chat name",
      errorMessage: "Please enter the chat name",
      required: "true",
      hitAI: 0,
      inputValueOrder: "input-1",
      uniqueField: 0,
      choiceName: [],
      createdBy: "",
      modifiedBy: "",
    };

    setPF((prev) => [...prev, promptFieldDataObj]);
  };

  // Checks the prompt history for a specific prompt
  const check = async (promptVal: string) => {
    try {
      const data = await fetchPromptHistory({ 
        promptId: promptVal, 
        editedPromptID: promptVal, 
        sortOption: 'promptName', 
        sortValue: 'ASC' 
      });

    } catch (error) {
      console.error(error);
    }
  };

  // Fetches and sets the prompt history for a specific prompt ID
  const promptHistory = async (promptID: string) => {
    try {
      const data = await getPromptHistory({ 
        promptId: promptID, 
        editedPromptID: '', 
        sortOption: 'promptName', 
        sortValue: 'DESC' 
      });
      if (data?.responseData?.ResponseData) {
        setPromptIndex(data.responseData.ResponseData.prompts[0]);
      }
      setShowPage("showManagePrompt");
      setHandlePromptHistory(false);
      navigate("/asamimanagebase");
      return data.responseData.ResponseData.prompts[0];
    } catch (error) {
      console.error(error);
    }
  };

  // Restores a specific prompt version
  const restore = async (promptVal: string, editedPromptVal: string) => {
    try {
      setIsLoading(true);
      await restoreManagePrompt({ editedPromptID: promptVal, promptID: editedPromptVal });
      await fetchPromptData();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Deletes a prompt by its ID and refreshes the prompt list
  const deletePromptData = async (promptID: any) => {
    try {
      setShowPopup(false)
      setIsLoading(true)
      let response = await deletePrompt({ "prompt_id": promptID });
      setPromptIndex(null);
      await fetchPromptData();
    } catch (error) {
      console.error(error);
    }
  };

  // Fetches prompt and practice data when promptdataid changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchPromptData();
        await fetchPractice();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (promptdataid !== "") {
      fetchData();
    }
  }, [promptdataid]);   

  // Provides the context value for ManagePromptCardContext
  return (
    <ManagePromptCardContext.Provider
      value={{
      promptdataName, setPromptDataName, // State for prompt data name
      promptCards, setPromptCards, // State for prompt cards
      promptdataid, setPromptDataId, // State for prompt data ID
      isconfig, setisconfig, // State for configuration status
      promptCardData, setPromptCardData, // State for prompt card data
      deletePromptData, // Function to delete prompt data
      showPage, setShowPage, // State for managing page visibility
      practiceID, setPracticeID, // State for practice ID
      showPopup, setShowPopup, // State for popup visibility
      promptIndex, setPromptIndex, // State for prompt index
      popUpText, setPopUpText, // State for popup text
      handlePromptHistory, setHandlePromptHistory, // State for prompt history
      practiceName, setPracticeName, // State for practice name
      practiceDetails, setPracticeDetails, // State for practice details
      isLoading, setIsLoading, // State for loading status
      search, setSearch, // State for search functionality
      userDetails, // User details from context
      inputTypeData, setInputTypeData, // State for input type data
      PF, setPF, // State for prompt fields
      sortname, setSortname, // State for sorting by name
      sortvalue, setSortvalue, // State for sorting by value
      promptSort, setPromptSort, // State for prompt sorting
      modifiedBy, setModifiedBy, // State for modified by user
      timeStamp, setTimeStamp, // State for timestamp
      promptid, setPromptid, // State for prompt ID
      fetchPromptData, // Function to fetch prompt data
      fetchPractice, // Function to fetch practice data
      handleCreatePrompt, // Function to create a new prompt
      check, // Function to check data
      promptHistory, // State for prompt history
      restore, // Function to restore data
    }}
  >
    {children}
  </ManagePromptCardContext.Provider>
);
};

export const useManagePromptCard = () => {
  const context = useContext(ManagePromptCardContext);
  if (context === undefined) {
    throw new Error('useManagePromptCard must be used within a ManagePromptCardProvider');
  }
  return context;
};