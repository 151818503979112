import React from 'react';
import TabComponentProps  from '../Interfaces/TabSwitch';

const Tab: React.FC<TabComponentProps> = ({ activeTab, tabContents, onTabClick }) => {
  const tabList = Object.keys(tabContents);

  return (
    <div className="custom-border-bottom">
      <ul className="nav nav-pills font-14 font-semibold custom-pills color-white-bg border-radius-5 cust-respt" id="newtab" role="tablist">
        {tabList.map((tabName, idx) => (
          <li key={idx} className="nav-item mb-2" role="presentation">
            <button
              className={`custom-new-tab-btn nav-link ${activeTab === tabName ? 'active' : ''} d-flex px-3 font-16 font-semibold`}
              type="button"
              onClick={() => onTabClick(tabName)}
            >
              {tabName}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Tab;