import React, { useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import UserContext from "../../Auth/UserContext";
import { isAuth } from "../../Auth/AuthConfig";
import { useLlmConfig } from '../../Utility/LLMConfigurationForm';
import ReusableTable from '../../Reusable Components/ReusableTable';
import { TableProvider, useTableContext } from "../../Utility/ReusableTableContext";
import DynamicForm, { FormField } from '../../Reusable Components/DynamicForm';
import { useLlmFormContext } from '../../Utility/Input';
import Loader from '../../Reusable Components/Loader';

interface LlmConfigFormProps {
    navOpen: String;
  }


export const LlmConfigForm: React.FC<LlmConfigFormProps> = ({ navOpen }) => {
      const {
        isLoading, setIsLoading,
        modelType,selectedModelType,setSelectedModelType, setModelType,
        errorData,setErrorData,     
        modelTypeDD,setModelTypeDD,
        disableEdit,setDisableEdit,
        identity,setIdentity,
        llmConfig,setllmConfig,
        fetchBindParams,
        fetchOneModelConfig,
        validateModelConfig,
        cancel,
        handleAddRow,
        handleEditRow,
        handleDeleteRow,
        bindModelType,
        addAuthTypeRow,
        setTableData
      } = useLlmConfig();

    const { 
        renderInputFields } = useLlmFormContext();
    const { accounts } = useMsal();
    const userDetails = useContext(UserContext);
    const history = useNavigate();

    useEffect(() => {
        if (!isAuth(accounts)) {
          history("/");
        }
      }, [accounts, history]);
    
    useEffect(() => {
        try {
        fetchBindParams();
        if (identity !== null) {
            fetchOneModelConfig(identity, userDetails?.email);
            
        }
        else{
          setTableData([])
        }
        } catch (error) {
        }
    }, [identity, userDetails]);

    

    const authheaders = [
        { key: 'modelEndpoint', label: 'End Point' ,required:true},
        { key: 'APIKey', label: 'API Key',required:true },
        {
          key: 'actions',
          label: 'Action',
          component: ({ row }: any) => (
    
            <div className="text-center">
              <button className="btn me-3 p-0" disabled={disableEdit} onClick={() => handleEditRow(row)}>
                <img src="images-new/edit-icons.svg" alt="Edit" />
              </button>
              <button className="btn p-0" disabled={disableEdit} onClick={() => handleDeleteRow(row)}>
                <img src="images-new/delete-icons.svg" alt="Delete" />
              </button>
            </div>
          )
        }
      ];

    const bedrockheaders = [
    { key: 'modelId', label: 'Model ID', required:true},
    { key: 'version', label: 'Anthropic Version', required:true },
    { key: 'awsRegion', label: 'AWS Region', required:true},
    {
        key: 'actions',
        label: 'Action',
        component: ({ row }: any) => (

        <div className="text-center">
            <button className="btn me-3 p-0" disabled={disableEdit} onClick={() => handleEditRow(row)}>
            <img src="images-new/edit-icons.svg" alt="Edit" />
            </button>
            <button className="btn p-0" disabled={disableEdit} onClick={() => handleDeleteRow(row)}>
            <img src="images-new/delete-icons.svg" alt="Delete" />
            </button>
        </div>
        )
    }
    ];

    const modelTypeField: FormField = {
        name: "modelType",
        label: "Model Type",
        type: "dropdown",
        value: selectedModelType ? { label: selectedModelType, value: selectedModelType } : null,
        placeholder: "Select",
        required: true,
        dropdown: modelTypeDD,
        hidelabel:true,
        change: "",
        dropdownval:  modelType ? modelType.map(item => ({
          label: item.modelType,
          value: item.modelTypeID
        })):[],
        dropdownfieldfunc: bindModelType,
        dropdownfunc: (selectedOption: any) => {
          
          if (selectedOption) {
            const selectedModelType = modelType.find(item => item.modelTypeID === selectedOption.value);
            if (selectedModelType) {
              const modelTypeId = selectedModelType.modelTypeID;
              const modelTypeName = selectedModelType.modelType;
    
              setSelectedModelType(modelTypeName);
              setModelTypeDD(false);
              setllmConfig({
                ...llmConfig,
                modelTypeID: modelTypeId,
                modelConfiguration: {
                  ...llmConfig.modelConfiguration,
                  modelType: modelTypeName
                },
              });
              setErrorData({ ...errorData, modelTypeError: '' });
            }
          } else {
            setSelectedModelType('');
            setllmConfig({
              ...llmConfig,
              modelTypeID: '',
              modelConfiguration: {
                ...llmConfig.modelConfiguration,
                modelType: '',
              },
            });
          }
        },
        error: errorData.modelTypeError,
        displayNameError: errorData.modelTypeError,
        className: "btn input-field font-14 font-regular border-radius-3 bg-color text-color-4 custom-border-3 cust-search-input w-100 d-flex justify-content-between theme-focus",
        options: modelType ? modelType.map(item => ({
          label: item.modelType,
          value: item.modelTypeID
        })):[],
        disabled: false,
        id: "model-type"
      };

    const displayNameField: FormField[] = [
        {
          name: "end-point-config",
          label: "Display Name",
          type: "text",
          value: llmConfig?.modelConfiguration?.displayName || '',
          error: errorData.displayNameError,
          placeholder: "Enter Display Name",
          required: true,
          change: (e: React.ChangeEvent<HTMLInputElement>) => {
            setErrorData({ ...errorData, displayNameError: "" });
            setllmConfig({
              ...llmConfig,
              modelConfiguration: {
                ...llmConfig.modelConfiguration,
                displayName: e.target.value,
              },
            });
          },
          dropdown: false,
          dropdownfunc: "",
          dropdownfieldfunc: "",
          displayNameError: errorData.displayNameError,
          dropdownval: "",
          id: "displayname",
          className: "form-control input-field font-14 font-medium color-black border-radius-3 bg-color text-color-4 custom-border-3 cust-search-input resize-none theme-focus",
        },
      ];

      const regionField: FormField[] = [
        {
          name: "end-point-config",
          label: "Region",
          type: "text",
          value: llmConfig?.modelConfiguration?.region || '',
          error: errorData.regionError,
          placeholder: "Enter Region",
          required: true,
          change: (e: React.ChangeEvent<HTMLInputElement>) => {
            setErrorData({ ...errorData, regionError: "" });
            setllmConfig({
              ...llmConfig,
              modelConfiguration: {
                ...llmConfig.modelConfiguration,
                region: e.target.value,
              },
            });
          },
          dropdown: false,
          dropdownfunc: "",
          dropdownfieldfunc: "",
          displayNameError: errorData.regionError,
          dropdownval: "",
          id: "region",
          className: "form-control input-field font-14 font-medium color-black border-radius-3 bg-color text-color-4 custom-border-3 cust-search-input resize-none theme-focus",
        },
      ];

      const modelNameField: FormField[] = [
        {
          name: "modelName",
          label: "Model Name",
          type: "text",
          value: llmConfig?.modelConfiguration?.modelName || '',
          error: errorData.modelNameError,
          placeholder: "Enter Model Name",
          required: true,
          change: (e: React.ChangeEvent<HTMLInputElement>) => {
            setErrorData({ ...errorData, modelNameError: "" });
            setllmConfig({
              ...llmConfig,
              modelConfiguration: {
                ...llmConfig.modelConfiguration,
                modelName: e.target.value,
              },
            });
          },
          dropdown: false,
          dropdownfunc: "",
          dropdownfieldfunc: "",
          displayNameError: errorData.modelNameError,
          dropdownval: "",
          id: "modelName",
          className: "form-control input-field font-14 font-medium color-black border-radius-3 bg-color text-color-4 custom-border-3 cust-search-input resize-none theme-focus",
        },
      ];

      const modelField: FormField[] = [
        {
          name: "model",
          label: "Model",
          type: "text",
          value: llmConfig?.modelConfiguration?.model || '',
          error: errorData.modelError,
          placeholder: "Enter Model",
          required: true,
          change: (e: React.ChangeEvent<HTMLInputElement>) => {
            setErrorData({ ...errorData, modelError: "" });
            setllmConfig({
              ...llmConfig,
              modelConfiguration: {
                ...llmConfig.modelConfiguration,
                model: e.target.value,
              },
            });
          },
          displayNameError: errorData.modelError,
          dropdown: false,
          dropdownfunc: "",
          dropdownfieldfunc: "",
          dropdownval: "",
          id: "model",
          className: "form-control input-field font-14 font-medium color-black border-radius-3 bg-color text-color-4 custom-border-3 cust-search-input resize-none theme-focus",
        },
      ];
    
      const modelDescriptionField: FormField = {
        name: "modelDescription",
        label: "Model Description",
        type: "textarea",
        value: llmConfig?.modelConfiguration?.modelDescription || '',
        placeholder: "Enter Model Description",
        required: true,
        change: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
          setErrorData({ ...errorData, modelDescriptionError: "" });
          setllmConfig({
            ...llmConfig,
            modelConfiguration: {
              ...llmConfig.modelConfiguration,
              modelDescription: e.target.value,
            },
          });
        },
        error: errorData.modelDescriptionError,
        displayNameError: errorData.modelDescriptionError,
        className: "form-control input-field font-14 font-regular border-radius-3 bg-color custom-border-3 text-color-4 resize-none place-holder-fix-mp theme-focus",
        id: "description",
        dropdown: false,
        dropdownfieldfunc: () => { },
        dropdownfunc: () => { },
        dropdownval: [],
      };

      return (
        <>
          {isLoading ? (
          <Loader isLoading={isLoading} navOpening={navOpen} />
          ) : (
            // <div>
            <>
              <div className="container-fluid">
                <div className="row justify-content-lg-end justify-content-center">
                  <div className={navOpen == "Close" ? "col-md-11 second-component-llmForm-nav-close" : "col-md-9 second-component-llmForm"} id="chat-history-center">
                    <title>LLM Configurations</title>
                    <div className="container-fluid llm-border">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-12 mt-3">
                            <h3 className="font-20 font-semibold text-color-1 mt-3 mb-3">LLM Configurations</h3>
                            <p className="font-15 font-medium text-color-13 mb-5">Customize your Language Model with ease using the LLM.</p>
                            {/*Forms starts here*/}
                            <div className="row d-flex mb-4">
                              {/*Model Type*/}
                              <div className="col-md-4 col-sm-12 mb-4">
                                <DynamicForm fields={[modelTypeField]} />
                              </div>
                              <div className="col-md-4 col-sm-12 mb-4">
                                <div className="dropdown card-fix-llm w-100">
                                {selectedModelType&&(
                                  <div className="col-md-12 col-sm-12 mb-3">
                                    <DynamicForm fields={displayNameField} />
                                  </div>)}
                                </div>
                              </div>
                              {/* Region*/}
                              {(selectedModelType === 'Azure Open AI') ? (
                                <>
                                  {/*Regions*/}
                                  <div className="col-md-4 col-sm-12 mb-4">
                                    <div className="col-md-12 col-sm-12 mb-3">
                                      <DynamicForm fields={regionField} />
                                    </div>
    
                                  </div>
                                </>
                              ) : null}
                              {(selectedModelType === 'Azure Open AI') ? (
                                <>
                                    {/*Model Names*/}
                                    <div className="col-md-4 col-sm-12 mb-4">                                        
                                        <DynamicForm fields={modelNameField} />            
                                    </div>

                                    {/*Model*/}
                                    <div className="col-md-4 col-sm-12 mb-4">
                                        <DynamicForm fields={modelField} />
                                    </div>

                                    {/*Model Description*/}
                                    <div className="col-md-12 col-sm-12 mb-3">
                                        <DynamicForm fields={[modelDescriptionField]} />
                                    </div>
                                </>
                              ) : 
                              <>
                                {/*Model Description*/}
                                <div className="col-md-12 col-sm-12 mb-3">
                                    <DynamicForm fields={[modelDescriptionField]} />
                                </div> 
                                </>
                              } 
                              </div>
    
                            {(selectedModelType === 'Azure Open AI' || selectedModelType === 'Open AI') ? (
                              <div className="col-md-12">
                                <div className="table-responsive rounded-3 transparent-grp mb-5">
                                <ReusableTable
                                    headers={authheaders}
                                    inputFields={renderInputFields()}
                                    llmKey="llm"
                                    onRowClick={(row) => {
                                      // Handle row click if needed
                                    }}
                                  />
                                </div>
                              </div>
                            ) : null}                            
                            {/*Bedrock Authorization Table*/}
                            {(selectedModelType === 'AWS Bedrock') ? (
                              <div className="col-md-12">
                                <div className="table-responsive rounded-3 transparent-grp mb-5">
                                  <ReusableTable
                                    headers={bedrockheaders}
                                    inputFields={renderInputFields()}
                                    llmKey="llm"
                                    onRowClick={(row) => {
                                      // Handle row click if needed
                                    }}
                                  />
                                </div>
                              </div>
                            ) : null}
                            {/*buttons*/}
                            <div className="row d-flex justify-content-center mb-5">
                              <div className="col-md-12 py-3">
                                <div className="d-flex justify-content-end">
                                  <div>
                                    <button type="button" disabled={disableEdit} className="btn custom-outline-btn font-14 font-semibold border-radius-5 me-3 py-2 px-4" onClick={() => { cancel() }}>Cancel</button>
                                    <button type="button" disabled={disableEdit} className="btn primary-btn font-14 font-semibold border-radius-5 color-white py-2 px-4" onClick={() => { validateModelConfig()}}>Save</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/*buttons*/}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
            // </div>
          )}
        </>
      );
    
}