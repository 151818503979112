import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { usePromptBuilder } from '../Utility/ManagePromptContext';

interface DraggableTaskTableProps {
  tasks: any[];
  modelData?: any[];
  onDragEnd: (result: any) => void;
  onAddTask?: () => void;
  onDeleteTask?: (index: number) => void;
  onEditTask?: (index: number, task: any) => void;
}

const DraggableTaskTable: React.FC<DraggableTaskTableProps> = ({
  tasks,
  modelData,
  onDragEnd,
  onAddTask,
  onDeleteTask,
  onEditTask,
}) => {
  
  const PF = modelData || []; 
  
  const { HandlePromptHistory} = usePromptBuilder();

  const [hoveredInput, setHoveredInput] = useState<string | null>(null);

  const findModelName = (modelId: string, models: any[]) => {
    const model = models?.find(m => m.id === modelId);
    return model?.displayName || 'Unknown Model';
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <table className="table theme-table font-regular font-14 mb-0">
            <thead>
                <tr>
                  <th style={{ width: "11%" }} className="w-11">Inputs</th>
                  <th>Output Description</th>
                  <th className="ps-4">Task</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>


        <Droppable droppableId="tasks">
          {(provided:any) => (
            <tbody ref={provided.innerRef} {...provided.droppableProps}>
              {tasks?.map((task, index) => (
                task.promptMessage ? (
                  <Draggable
                    key={index.toString()}
                    draggableId={index.toString()}
                    index={index}
                  >
                    {(provided:any) => (
                      <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={provided.draggableProps.style}
                      >
                        <td className="ps-4 position-relative w-11" style={{ whiteSpace: "normal", width: "11%" }}>
                          {task?.inputArray != null && typeof (task?.inputArray) !== 'string' ? (
                            <div>
                              {task?.inputArray?.map((ele:any) => 
                                <span key={ele.inputPlaceholder}>
                                  {PF.includes(ele.inputPlaceholder) ? 
                                    ele.inputPlaceholder + "," : 
                                    ele.inputPlaceholder}
                                </span>
                              )}
                            </div>
                          ) : null}
                        </td>
                        <td className="ps-4 position-relative" style={{ whiteSpace: "normal",maxWidth:"17vw" }}>
                          {task?.outputDescription ? (
                            <span>{task.outputDescription}</span>
                          ) : null}
                        </td>
                        <td className="ps-4 position-relative" style={{ whiteSpace: "normal",    maxWidth: "25vw" }}>
                          {task?.promptMessageID ? (
                            <span>
                              {task?.promptMessage
                                .split(/(\(input-\d+\))/)
                                .map((part:any, partIndex:any) => {
                                  const match = part.match(/\((input-\d+)\)/);
                                  if (match) {
                                    const inputValueOrder = match[1];
                                    const label = PF.find(field => field.inputValueOrder === inputValueOrder)?.labelName;

                                    return (
                                      <span
                                        key={partIndex}
                                        onMouseEnter={() => setHoveredInput(inputValueOrder)}
                                        onMouseLeave={() => setHoveredInput(null)}
                                        className={`cursor-pointer ${hoveredInput === inputValueOrder ? "bold-text" : ""}`}
                                      >
                                        {hoveredInput === inputValueOrder ? (label ? label : "Code snippet") : part}
                                      </span>
                                    );
                                  }
                                  return part;
                                })}
                            </span>
                          ) : (
                            task?.promptMessage
                          )}
                        </td>
                        <td className="text-center" style={{ whiteSpace: "nowrap" }}>
                          {onEditTask && (
                            <img 
                              src="images-new/edit-icons.svg" 
                              onClick={() => {
                                onEditTask(index, task);
                              }}
                              alt="edit-icon"
                              className="me-3 cp-icon-sty"
                              style={{ marginLeft: '10%', cursor: 'pointer' }}
                            />
                          )}

                          {onDeleteTask&& (
                            <img
                              src="images/Default/delete-icon.svg"
                              alt="delete-icon"
                              className="me-3 cp-icon-sty"
                              onClick={() => onDeleteTask(index)}
                              style={{ marginLeft: '1%', cursor: 'pointer' }}
                            />
                          )}
                        </td>
                      </tr>
                    )}
                  </Draggable>
                ) : null
              ))}
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </table>
    </DragDropContext>
  );
};

export default DraggableTaskTable;