import React, { createContext, useState, ReactNode, useEffect } from 'react';
import { getBindParams } from '../Service/API'; // Adjust the import path as needed

// Define the configuration type
export interface ConfigType {
  maxResponse: number;
  temperature: number;
  topP: number;
  frequencyPenalty: number;
  presencePenalty: number;
  selectedModelID: string;
}

// Define the context type for configuration
interface ConfigContextType {
  config: ConfigType;
  setConfig: React.Dispatch<React.SetStateAction<ConfigType>>;
}

// Create the configuration context
export const ConfigContext = createContext<ConfigContextType | undefined>(undefined);

// Configuration provider component
export const ConfigureProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  
  // Initialize configuration state with default values
  const [config, setConfig] = useState<ConfigType>({
    maxResponse: 4096, temperature: 0.7, topP: 0.2, 
    frequencyPenalty: 0.1, presencePenalty: 0.1, selectedModelID: ""
  });

  // Fetch the default model ID on component mount
  useEffect(() => {
    const fetchDefaultModel = async () => {
      const response = await getBindParams({});
      // Uncomment and adjust the following lines if needed
      // const defaultModel = response?.data?.defaultModel[0];
      // if (defaultModel) {
      //   setConfig(prevConfig => ({
      //     ...prevConfig,
      //     selectedModelID: defaultModel?.default_model_id
      //   }));
      // }
    };

    fetchDefaultModel();
  }, []);

  // Provide the configuration context to children
  return (
    <ConfigContext.Provider value={{ config, setConfig }}>
      {children}
    </ConfigContext.Provider>
  );
};