
import React, { useContext, useEffect, useState } from 'react';
import toggleContext from '../../Utility/Context';
import { Text } from '../../Reusable Components/ManagePrompt Reusable Components/Text';
import { MediaUpload } from '../../Reusable Components/ManagePrompt Reusable Components/MediaUpload';
import { FileUploadProvider } from '../../Utility/FileUploadContext';
import { ToggleContextType } from '../../Interfaces/ToggleContextTypes';
import TechStack from '../../Reusable Components/ManagePrompt Reusable Components/TechStack';
import ChoicesComponent from '../../Reusable Components/ManagePrompt Reusable Components/ChoicesComponent';

interface MQRenderInputProps {
  selectedOption: string;
  editChoices: string[];
  addOption: number;
  onChangeData: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    updatedChoices?: string[]
  ) => void;
  addOptionProp: () => void;
  removeOptionProp: () => void;
  longAnswer: boolean;
  setLongAnswer: (value: boolean) => void;
  dropdown: boolean;
  setDropdown: (value: boolean) => void;
  checkbox: boolean;
  setCheckbox: (value: boolean) => void;
  required: boolean;
  setRequired: (value: boolean) => void;
  aiReq: boolean;
  setAiReq: (value: boolean) => void;
  hitAiVar: number;
  sethitAiVar: (value: number) => void;
  promptFieldData: any;
  setPromptFieldData: (value: any) => void;
  inputTypeData: any[];
  setSelectedOption: (value: string) => void;
  userDetails: any;
  updateChoiceName: (newChoices: string[]) => void;
}

const MQRenderInput: React.FC<MQRenderInputProps> = ({
  selectedOption,
  editChoices,
  addOption,
  onChangeData,
  addOptionProp,
  removeOptionProp,
  longAnswer,
  setLongAnswer,
  dropdown,
  setDropdown,
  checkbox,
  setCheckbox,
  required,
  setRequired,
  aiReq,
  setAiReq,
  hitAiVar,
  sethitAiVar,
  promptFieldData,
  setPromptFieldData,
  inputTypeData,
  setSelectedOption,
  userDetails,
  updateChoiceName,
}) => {
  const [internalChoices, setInternalChoices] = useState<string[]>([]);
  
  useEffect(() => {
    if (["CHC", "RB", "DD", "CB"].includes(selectedOption)) {
      setInternalChoices(promptFieldData.choiceName?.length > 0 ? promptFieldData.choiceName : new Array(addOption).fill(''));
      setDropdown(selectedOption === "DD");
      setCheckbox(selectedOption === "CB");
    }
  }, [selectedOption, promptFieldData, addOption]);

  const renderInputComponent = () => {
    let choiceType = "radio";
    if (["CHC", "RB", "DD", "CB"].includes(selectedOption)) {
      if (dropdown || selectedOption === "DD") {
        choiceType = "dropdown";
      } else if (checkbox || selectedOption === "CB") {
        choiceType = "checkbox";
      }
    }
  
    const toggleValue: ToggleContextType = {
      editChoices: internalChoices,
      chs: choiceType,
      options: addOption,
      functions: onChangeData,
    };
  
    switch (selectedOption) {
      case "CHC":
      case "RB":
      case "DD":
      case "CB":
        return (
          <toggleContext.Provider value={toggleValue}>
            <ChoicesComponent
              choiceType={choiceType}
              choices={internalChoices}
              onChangeChoice={(index: number, value: string) => {
                const updatedChoices = [...internalChoices];
                updatedChoices[index] = value;
                setInternalChoices(updatedChoices);
                updateChoiceName(updatedChoices);
              }}
              onBlurChoice={(e: React.FocusEvent<HTMLInputElement>) => {
                onChangeData(e, internalChoices);
              }}
            />
          </toggleContext.Provider>
        );
      case "TXT":
      case "CS":
      case "LA":
        return (
          <toggleContext.Provider value={toggleValue}>
            <Text 
              record={promptFieldData} 
              isLongAnswer={selectedOption === "CS" || selectedOption === "LA" || longAnswer}
            />
          </toggleContext.Provider>
        );
      case "UF":
        return (
          <FileUploadProvider>
            <toggleContext.Provider value={toggleValue}>
              <MediaUpload record={promptFieldData} />
            </toggleContext.Provider>
          </FileUploadProvider>
        );
      case "TS":
        return (
          <toggleContext.Provider value={toggleValue}>
            <TechStack record={promptFieldData} />
          </toggleContext.Provider>
        );
      default:
        return null;
    }
  };

  const handleAddOption = () => {
    addOptionProp();
    const newChoices = [...internalChoices, ''];
    setInternalChoices(newChoices);
    updateChoiceName(newChoices);
  };

  const handleRemoveOption = () => {
    if (internalChoices.length > 2) {
      removeOptionProp();
      const newChoices = [...internalChoices];
      newChoices.pop();
      setInternalChoices(newChoices);
      updateChoiceName(newChoices);
    }
  };

  const updateInputType = (newType: string) => {
    setSelectedOption(newType);
    setPromptFieldData((prev: any) => ({
      ...prev,
      inputTypeRefCode: newType,
      inputTypeID: inputTypeData?.find(type => type?.inputTypeRefCode === newType)?.inputTypeID
    }));
  };

  return (
    <div>
      {renderInputComponent()}
      <div className="d-flex align-items-start mt-2 py-3">
        {["CHC", "RB", "DD", "CB"].includes(selectedOption) && (
          <>
            <button
              type="button"
              className="btn btn-outline-secondary font-14 font-medium me-3"
              onClick={handleAddOption}
            >
              Add Option
            </button>
            {internalChoices.length > 2 && (
              <button
                type="button"
                className="btn btn-outline-secondary font-14 font-medium me-3"
                onClick={handleRemoveOption}
              >
                Remove Option
              </button>
            )}
            <div className="form-check form-switch me-5">
              <input
                className="form-check-input custom-toggle-input theme-focus"
                type="checkbox"
                role="switch"
                id="Convert-to-dropdown"
                checked={dropdown || selectedOption === "DD"}
                onChange={() => {
                  const newType = dropdown ? "RB" : "DD";
                  setDropdown(!dropdown);
                  setCheckbox(false);
                  updateInputType(newType);
                }}
              />
              <label className={`form-check-label text-color-4 ${internalChoices.length > 2 ? "dropdownwhenaddoptions" : ""}`} htmlFor="Convert-to-dropdown">
                Convert to dropdown
              </label>
            </div>
            <div className="form-check form-switch me-5">
              <input
                className="form-check-input custom-toggle-input theme-focus"
                type="checkbox"
                role="switch"
                id="Multiple-answers"
                checked={checkbox || selectedOption === "CB"}
                onChange={() => {
                  const newType = checkbox ? "RB" : "CB";
                  setCheckbox(!checkbox);
                  setDropdown(false);
                  updateInputType(newType);
                }}
              />
              <label className={`form-check-label text-color-4 ${internalChoices.length > 2 ? "dropdownwhenaddoptions" : ""}`} htmlFor="Multiple-answers">
                Multiple answers
              </label>
            </div>
          </>
        )}
        {["TXT", "CS", "LA"].includes(selectedOption) && (
          <div className="form-check form-switch me-5">
            <input
              className="form-check-input custom-toggle-input theme-focus"
              type="checkbox"
              role="switch"
              id="Long-answer"
              checked={longAnswer || ["CS", "LA"].includes(selectedOption)}
              onChange={() => {
                const newType = longAnswer ? "TXT" : "CS";
                setLongAnswer(!longAnswer);
                updateInputType(newType);
              }}
            />
            <label className="form-check-label text-color-4" htmlFor="Long-answer">
              Long answer
            </label>
          </div>
        )}
        <div className="form-check form-switch me-5">
          <input
            className="form-check-input custom-toggle-input theme-focus"
            type="checkbox"
            role="switch"
            id="Required"
            checked={required}
            onChange={() => {
              setRequired(!required);
              setPromptFieldData((prev: any) => ({
                ...prev,
                required: !required ? "true" : "false",
              }));
            }}
          />
          <label className={`form-check-label text-color-4 ${internalChoices.length > 2 ? "dropdownwhenaddoptions" : ""}`} htmlFor="Required">
            Required
          </label>
        </div>
        <div className="form-check form-switch me-5">
          <input
            className="form-check-input custom-toggle-input theme-focus"
            type="checkbox"
            role="switch"
            id="AIHit"
            checked={aiReq}
            onChange={() => {
              setAiReq(!aiReq);
              sethitAiVar(!aiReq ? 1 : 0);
              setPromptFieldData((prev: any) => ({
                ...prev,
                hitAI: !aiReq ? 1 : 0,
              }));
            }}
          />
          <label className={`form-check-label text-color-4 ${internalChoices.length > 2 ? "dropdownwhenaddoptions" : ""}`} htmlFor="AIHit">
            Enhance Requirement
          </label>
        </div>
      </div>
    </div>
  );
};

export default MQRenderInput;