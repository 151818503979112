// PS_FDBKFORM_01, PS_FDBKFORM_02
import React, { useState, useEffect, ChangeEvent } from "react";
import { fetchPromptEmj, insertGetFeedBack } from "../../Service/API";
import DropDownComponent from "../../Reusable Components/DropDown";
import { DropDownComponentProps } from "../../Interfaces/DropDown";
import axios from "axios";

// PS_FDBKFORM_03, PS_FDBKFORM_04
interface Prompt {
  Prompt_id: string;
  Prompt_name: string;
}

interface Emoji {
  emoji_id: string;
  emoji_desc: string;
  imageUrl: string;
}

interface Option {
  label: string;
  value: string;
}




// PS_FDBKFORM_05
const FeedBackForm: React.FC = () => {
  // PS_FDBKFORM_06
  const [promptOptions, setPromptOptions] = useState<Prompt[]>([]);
  const [emojiOptions, setEmojiOptions] = useState<Emoji[]>([]);
  const [selectedPrompt, setSelectedPrompt] = useState<any>("");
  const [selectedEmoji, setSelectedEmoji] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedEmojiIndex, setSelectedEmojiIndex] = useState<number | null>(
    null
  );
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] =
    useState<boolean>(false);

  // PS_FDBKFORM_07
  useEffect(() => {
    fetchPromptAndEmojis();
    checkAndOpenModal();
  }, []);

  // PS_FDBKFORM_08, PS_FDBKFORM_09, PS_FDBKFORM_10, PS_FDBKFORM_11
  const fetchPromptAndEmojis = async () => {
    try {
      const response = await fetchPromptEmj({});
  
      const emojiMapping: { [key: string]: string } = {
        happy: "images-new/happy-emoji.svg",
        sad: "images-new/sad-emoji.svg",
        neutral: "images-new/neutral-emoji.svg"
      };
  
      const updatedEmojis: Emoji[] = (response.emoji_result || []).map((emoji: any) => ({
        ...emoji,
        imageUrl: emojiMapping[emoji.emoji_desc.toLowerCase()]
      }));
  
      setPromptOptions(response.prompt_result || []);
      setEmojiOptions(updatedEmojis);
    } catch (err) {
      setError("Error fetching prompt and emoji data.");
    }
  };
  
  
  
  

  // PS_FDBKFORM_12, PS_FDBKFORM_13, PS_FDBKFORM_14
  const checkAndOpenModal = async () => {
    const response = await axios.get('https://timeapi.io/api/Time/current/zone?timeZone=Etc/UTC');
    const today = new Date();
    const isWednesday = response?.data?.dayOfWeek ? response?.data?.dayOfWeek ==="Wednesday" : today.getDay() === 3
    const feedbackSubmitted =
      localStorage.getItem("feedbackSubmitted") === "true";
    if (isWednesday && !isFeedbackSubmitted && !feedbackSubmitted) {
      setShowModal(true);
    }
  };

  // PS_FDBKFORM_15, PS_FDBKFORM_16, PS_FDBKFORM_17
  const resolveEmojiUrl = (url: string): string => {
    const emojiMap: Record<string, string> = {
      "neutral-emoji.svg": "images-new/neutral-emoji.svg",
      "happy-emoji.svg": "images-new/happy-emoji.svg",
      "sad-emoji.svg": "images-new/sad-emoji.svg",
    };
    const fileName = url.split("/").pop() || "";
    return emojiMap[fileName] || url;
  };

  // PS_FDBKFORM_18
  const handlePromptChange = (selectedOption: Option | null) => {
    setSelectedPrompt(selectedOption);
  };

  // PS_FDBKFORM_19
  const handleEmojiClick = (emojiId: string, index: number) => {
    setSelectedEmoji(emojiId);
    setSelectedEmojiIndex(index);
  };

  // PS_FDBKFORM_20
  const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  };

  // PS_FDBKFORM_21, PS_FDBKFORM_22, PS_FDBKFORM_23, PS_FDBKFORM_24, PS_FDBKFORM_25, PS_FDBKFORM_26, PS_FDBKFORM_27
  const handleSubmitFeedback = async () => {
    if (!selectedPrompt || !selectedEmoji || !description) {
      setError("Please fill out all required fields");
      return;
    }
    setIsSubmitting(true);
    try {
      const response = await insertGetFeedBack({
        PromptID: selectedPrompt?.value,
        ReactionID: selectedEmoji,
        description: description,
      });
      if (response.responseData[0].Success) {
        localStorage.setItem("feedbackSubmitted", "true");
        setIsFeedbackSubmitted(true);
        handleCancel();
        setError(null);
      } else {
        setError("Failed to submit feedback");
      }
    } catch (err) {
      setError("An error occurred while submitting feedback");
    } finally {
      setIsSubmitting(false);
    }
  };

  // PS_FDBKFORM_28
  const handleCancel = () => {
    setSelectedPrompt("");
    setDescription("");
    setSelectedEmoji("");
    setShowModal(false);
  };

  // PS_FDBKFORM_29, PS_FDBKFORM_30
  return (
    <div className="container-fluid">
      {showModal && <div className="modal-backdrop show"></div>}
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered custom-popup-width border-0">
          <div className="modal-content p-3 border-0 bg-color-4">
            <div className="modal-header border-0 pt-2 px-1 pb-0">
              <h5 className="modal-title text-dark font-20 font-bold text-color-1">Feedback</h5>
              <button type="button" className="btn-close close-icon-filter" onClick={handleCancel} aria-label="Close" />
            </div>
            <div className="modal-body">
              {error && <div className="alert alert-danger">{error}</div>}
              <div className="mb-3">
             
              <DropDownComponent
                          required
                          label="Select Prompt"
                          options={promptOptions.map((prompt) => ({
                            label: prompt.Prompt_name,
                            value: prompt.Prompt_id,
                          }))}
                          value={selectedPrompt}
                          onChange={handlePromptChange}
                          customStylesOverride={{}}
                        />

              </div>
              <div className="mb-3">
                <label className="font-16 font-medium text-color-1 mb-0">
                  What do you think of this tool?
                </label>
                <div className="d-flex">
                {emojiOptions.map((emoji: Emoji, index: number) => (
                          <button
                            key={emoji.emoji_id}
                            type="button"
                            className={`btn me-2 ${
                              selectedEmojiIndex === index ? "btn-primary" : ""
                            }`}
                            onClick={() => handleEmojiClick(emoji.emoji_id, index)}
                          >
                            <img
                              src={emoji.imageUrl}
                              alt={emoji.emoji_desc}
                              style={{ width: "24px", height: "24px" }}
                            />
                          </button>
                        ))}
                </div>
              </div>
              <div className="mb-3">
                <label className="font-16 font-medium text-color-1 mb-0">Your thoughts</label>
                <textarea
                  className="form-control input-field font-14 font-regular border-radius-3 bg-color-4 custom-border-3 text-color-4 resize-none shadow-none"
                  rows={4}
                  value={description}
                  onChange={handleDescriptionChange}
                  placeholder="Enter your thoughts"
                ></textarea>
              </div>
            </div>
            <div className="modal-footer border-0">
              <button
                type="button"
                className="btn theme-outline-btn theme-color theme-focus text-white font-12 font-medium  me-3" style={{paddingBottom: "2%", paddingTop: "2%"}}
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                type="button"
                className="primary-btn font-14 font-semibold border-radius-5 px-3 py-2 color-white"
                onClick={handleSubmitFeedback}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Sending..." : "Send"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FeedBackForm;