
import React from 'react';
import { useTableContext } from '../Utility/ReusableTableContext';
import { ModelConfig } from '../Interfaces/LLMConfigGrid';

interface DetailedViewProps {
  isOpen: boolean;
  title: string;
  fields: Array<{
    label: string;
    key: string;
  }>;
  onClose: () => void;
}

const DetailedView: React.FC<DetailedViewProps> = ({ isOpen, onClose, title, fields }) => {
  const { detailedViewData } = useTableContext<ModelConfig>();

  if (!isOpen || !detailedViewData) return null;

  const getNestedValue = (obj: any, path: string) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  return (
    <div 
      className={`modal fade ${isOpen ? 'show' : ''}`}
      id="specifiedprompts-popup" 
      tabIndex={-1} 
      aria-hidden={!isOpen}
      style={{display: isOpen ? 'block' : 'none'}}
    >
      <div className="modal-dialog modal-dialog-centered custom-specifiedprompts-width border-0">
        <div className="modal-content p-3 border-0">
          <div className="modal-header border-0 pt-2 pb-0">
            <h5 className="modal-title font-22 font-bold">{title}</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            />
          </div>
          <div className="modal-body popup-scrl">
            <div className="row">
              {fields.map((field, index) => (
                <div key={index} className="col-md-12 mb-2">
                  <label className="font-14 font-semibold grey-v1">{field.label}:</label>
                  <span className="font-14 font-semibold grey-blue">
                    {getNestedValue(detailedViewData, field.key)}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="modal-footer border-0 d-flex justify-content-center pt-0">
            <button
              type="button"
              className="btn theme-color-bg text-white font-14 px-4 py-2 font-medium theme-focus"
              onClick={onClose}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailedView;