import React from 'react';

interface LoaderProps {
  isLoading: boolean;
  navOpening?:any
}

const Loader: React.FC<LoaderProps> = ({ isLoading, navOpening }) => {
  return (
    <>
      {isLoading && (
        <div className= {navOpening !=="Close" ? "loader-css" : "loader-css-nav-close"} >
          <div
            className="spinner-border theme-color align-center"
            role="status"
          >
            <span className="visually-hidden"></span>
          </div>
        </div>
        
      )}
    </>
  );
}

export default Loader;