
import React, { useState } from 'react';

interface ToolbarProps {
  canUndo: boolean;
  canRedo: boolean;
  onStyleApply: (style: string) => void;
  onUndo: () => void;
  onRedo: () => void;
  onSave: () => void;
  onCancel: () => void;
}

const Toolbar: React.FC<ToolbarProps> = ({
  canUndo,
  canRedo,
  onStyleApply,
  onUndo,
  onRedo,
  onSave,
  onCancel
}) => {
  const [showHeadings, setShowHeadings] = useState<boolean>(false);

  const handleHeadingClick = (level: number) => {
    onStyleApply(`h${level}`);
    setShowHeadings(false);
  };

  return (
    <div className="toolbar">
      <div className="toolbar-group">
        <div className="dropdown">
          <button className="toolbar-button" onClick={() => setShowHeadings(!showHeadings)}>
            Heading
          </button>
          {showHeadings && (
            <div className="dropdown-content">
              {[1, 2, 3, 4, 5, 6].map((level) => (
                <button key={level} onClick={() => handleHeadingClick(level)}>
                  H{level}
                </button>
              ))}
            </div>
          )}
        </div>
        <button className="toolbar-button" onClick={() => onStyleApply('bold')}>Bold</button>
        <button className="toolbar-button" onClick={() => onStyleApply('italic')}>Italic</button>
        <button className="toolbar-button" onClick={() => onStyleApply('strikethrough')}>Strikethrough</button>
        <button className="toolbar-button" onClick={() => onStyleApply('link')}>Link</button>
        <button className="toolbar-button" onClick={() => onStyleApply('orderedList')}>Ordered List</button>
        <button className="toolbar-button" onClick={() => onStyleApply('unorderedList')}>Unordered List</button>
        <button className="toolbar-button" onClick={() => onStyleApply('codeBlock')}>Code Block</button>
        <button className="toolbar-button" onClick={() => onStyleApply('horizontalRule')}>Horizontal Rule</button>
      </div>
      <div className="toolbar-group">
        <button className="toolbar-button" onClick={onUndo} disabled={!canUndo}>Undo</button>
        <button className="toolbar-button" onClick={onRedo} disabled={!canRedo}>Redo</button>
        <button className="toolbar-button save" onClick={onSave}>Save</button>
        <button className="toolbar-button cancel" onClick={onCancel}>Cancel</button>
      </div>
    </div>
  );
};

export default Toolbar;