
import React, { useState, useEffect, useCallback } from "react";
import { ExistData, fetchReposAndBranch, saveConfiguration } from "../Service/API";
import ButtonComponent from './Button';
import DropDownComponent from './DropDown';
import { SingleValue, MultiValue, ActionMeta } from 'react-select';

interface ConfigPopupProps {
  projectIdFromMP: string;
  TypeOfData: 'config' | 'update';
  typeOfGrid: 'issue' | 'task' | 'story'|'epic'|'backlog';
  uniqueIdFromMP: string;
  url: string;
  PAT: string;
  loaddata?:any;
  setloaddata?:any;
  setShowPopUp: (show: boolean) => void;
}

interface RepoData {
  Repo_name: string;
  Repo_url: string;
}

interface BranchData {
  branch_name: string;
  branch_url: string;
}

interface Option {
  value: string;
  label: string;
}

export const ConfigPopup: React.FC<ConfigPopupProps> = ({
  projectIdFromMP,
  TypeOfData,
  typeOfGrid,
  uniqueIdFromMP,
  url,
  PAT,
  loaddata,
  setloaddata,
  setShowPopUp,
}) => {
  const [repoOptions, setRepoOptions] = useState<RepoData[]>([]);
  const [selectedRepo, setSelectedRepo] = useState<RepoData | null>(null);
  const [branchOptions, setBranchOptions] = useState<BranchData[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<BranchData | null>(null);
  const [alertMessage, setAlertMessage] = useState('');

  const fetchRepoOptions = useCallback(async () => {
    try {
      const req = { ProjectID: projectIdFromMP };
      const response = await fetchReposAndBranch(req);
      setRepoOptions(response.data.ReposData);
    } catch (error) {
      console.error('Error fetching repo options:', error);
    }
  }, [projectIdFromMP]);

  

  const getExistConfig = useCallback(async () => {
    
    try {
      const req = { [typeOfGrid + 'ID']: uniqueIdFromMP };
      const response = await ExistData(req);
      setSelectedRepo(response.data.Responsedata[0].Repo_data);
    } catch (error) {
      console.error('Error fetching existing config:', error);
    }
  }, [typeOfGrid, uniqueIdFromMP]);


  useEffect(() => {
   
    const updateOverflowStyle = () => {
      const modalBackdrop = document.querySelector('.modal');
      if (modalBackdrop) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    };
    const observer = new MutationObserver(() => {
      updateOverflowStyle();
    });
    observer.observe(document.body, { childList: true, subtree: true });
    updateOverflowStyle();
    return () => {
      observer.disconnect();
      document.body.style.overflow = ''; 
    };
  }, []);

  useEffect(() => {
    if (TypeOfData === "config") {
      fetchRepoOptions();
    } else {
      getExistConfig();
      fetchRepoOptions();
    }
  }, [TypeOfData, fetchRepoOptions, getExistConfig]);
  
  
  const handleRepoChange = (
    newValue: SingleValue<Option> | MultiValue<Option>,
    actionMeta: ActionMeta<Option>
  ) => {
    if (newValue) {
      if (Array.isArray(newValue)) {
        // Handle MultiValue (array of Options)
        // For this case, you might want to select the first option or handle it differently
        const firstOption = newValue[0];
        if (firstOption) {
          const selectedRepoData = repoOptions.find(repo => repo.Repo_name === firstOption.value);
          setSelectedRepo(selectedRepoData || null);
        } else {
          setSelectedRepo(null);
        }
      } else if('value' in newValue) {   
        // Handle SingleValue (single Option object)
        const selectedRepoData = repoOptions.find(repo => repo.Repo_name === newValue.value);
        setSelectedRepo(selectedRepoData || null);
      }
    } else {
      setSelectedRepo(null);
    }
    setAlertMessage('');
  };
  

  
  const handleSave = async () => {
    if (!selectedRepo) {
      setAlertMessage('Please select a Repo and branch');
      return;
    }

    try {
      const reqBody = {
        selectedRepo: selectedRepo.Repo_name,
        selectedBranch: "dev",
        userEmailID: "",
        repoUrl: selectedRepo.Repo_url,
        branchUrl: "",
        selectedGrid: typeOfGrid,
        uniqueId: uniqueIdFromMP,
        configOrUpdate: TypeOfData,
        projectID: projectIdFromMP
      };

      const response = await saveConfiguration(reqBody);
      if (response.statusCode === 200) {
        handleCancel();
        localStorage.setItem("collection_name_avalon", selectedRepo.Repo_name);
        setloaddata(!loaddata);
      }
    } catch (error) {
      console.error('Error saving configuration:', error);
    }
  };

  const handleCancel = () => {
    setSelectedBranch(null);
    setSelectedRepo(null);
    setAlertMessage('');
    setShowPopUp(false);
  };

  return (
    <div className="modal show" style={{ display: "block", background: "#000000cc" }}>
      <div className="modal-dialog modal-dialog-centered custom-popup-repos border-1">
        <div className="modal-content p-3 border-0 bg-color-4">
          <div className="modal-header border-0 pt-2 p-0 px-2">
            <h5 className="modal-title text-color-1 font-22 font-bold">
              Configure Repos URL and Branch
            </h5>
            <ButtonComponent
                buttonType="icon"
                iconSrc="images/Default/flter-close-icon.svg"
                altText="Close filter"
                className="cursor-pointer close-icon-filter p-0 border-0 bg-transparent"
                style={{
                    width: '16px',
                    height: '16px',
                    minWidth: 'unset',
                    minHeight: 'unset',
                    padding: 0,
                    lineHeight: 1,
                }}
                onClick={handleCancel}
                />
          </div>
          <div className="modal-body popup-scrl pt-2">
            <div className="row">
              <div className="col-md-6 mt-4  custom-dashboard-section">
                <DropDownComponent
                  label="Repos URL"
                  options={repoOptions.map(repo => ({ value: repo.Repo_name, label: repo.Repo_name }))}
                  value={selectedRepo ? { value: selectedRepo.Repo_name, label: selectedRepo.Repo_name } : null}
                  onChange={handleRepoChange}
                />
                {alertMessage && <span style={{ color: 'red' }}>{alertMessage}</span>}
              </div>
            
            </div>
          </div>
          <div className="modal-footer border-0 gap-3">
            <ButtonComponent
              text="Cancel"
              onClick={handleCancel}
              className="btn-outline-secondary font-14 px-4 pb-2 m-0 font-medium"
            />
            <ButtonComponent
              text="Save"
              onClick={handleSave}
              className="primary-btn font-14 font-semibold border-radius-5 color-white pb-2 px-4 m-0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};