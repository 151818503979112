
import React from 'react';
import { SourcePopupProps } from '../../Interfaces/SourcePopups';

const SourcePopup: React.FC<SourcePopupProps> = ({ isOpen, onClose, sourceType, content }) => {
  if (!isOpen) return null;
  

  return (
    <div className="dropdown-menu src-dpd rounded-3 p-4 m-1 src-pop-up bg-color-4 border-color-10 web-sorce-dropdown"
         style={{ display: "block", width: "500px", backgroundColor:"black"}}>
      <div className="d-flex justify-content-between mb-3">
        <h4 className="font-20 text-color-1 font-medium m-0">Source</h4>
        <img src="images\Close-icon.svg" alt="close" className="cursor-pointer close-icon-filter" width="16"
             onClick={onClose} />
      </div>
      <p className="font-semibold font-10 text-color-1 rounded-1 px-2 py-1 bg-transparent violet-border d-inline">
        Source : <span>{sourceType}</span>
      </p>
      <div className="font-medium font-14 color-grey-v2 mt-3 src-dpd-content">
        <p className="mb-0 text-color-15">{content}</p>
      </div>
    </div>
  );
};

export default SourcePopup;