// Import necessary dependencies and components
import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import "./App.css"
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from "@azure/msal-browser";
import { msalConfig } from "./Auth/AuthConfig";
import { BrowserRouter } from "react-router-dom";
import { ButtonActionsProvider } from "./Header Components/NavHeaderComponent/ButtonComponentActionContext";

// Initialize the MSAL instance and set up event listeners for login success
const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  msalInstance.enableAccountStorageEvents();
  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
  });
});

// Function to remove error pages injected by Azure AD or other sources
const removeErrorPage = () => {
  const errorPage = document.querySelector('html > body > iframe[style*="position: fixed"]') as HTMLIFrameElement | null;
  if (errorPage) {
    errorPage.remove();
    document.body.style.overflow = 'auto';
  }
};

removeErrorPage();

// Set up a MutationObserver to detect and remove dynamically injected error pages
const observer = new MutationObserver((mutations) => {
  for (let mutation of mutations) {
    if (mutation.type === 'childList') {
      removeErrorPage();
    }
  }
});

observer.observe(document.body, { childList: true, subtree: true });

// Render the application inside the root container with necessary providers
const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  root.render(
    <DndProvider backend={HTML5Backend}>
      <MsalProvider instance={msalInstance}>
        <BrowserRouter>
          <ButtonActionsProvider>
            <App />
          </ButtonActionsProvider>
        </BrowserRouter>
      </MsalProvider>
    </DndProvider>
  );
}

// Measure and report web vitals for performance monitoring
reportWebVitals();