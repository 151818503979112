
import React from 'react';
import InputComponent from '../../Reusable Components/TextInput';

interface ChoicesComponentProps {
  choiceType: string;
  choices: string[];
  label?: string;
  required?: boolean;
  selectedValue?: string | string[];
  onChangeChoice: (index: number, value: string) => void;
  onBlurChoice: (e: React.FocusEvent<HTMLInputElement>) => void;
  onSelect?: (value: string | string[]) => void;
}

const ChoicesComponent: React.FC<ChoicesComponentProps> = ({
  choiceType,
  choices,
  label,
  required,
  selectedValue,
  onChangeChoice,
  onBlurChoice,
  onSelect,
}) => {
  const handleCheckboxChange = (choice: string) => {
    const newValue = Array.isArray(selectedValue)
      ? selectedValue.includes(choice)
        ? selectedValue.filter((v) => v !== choice)
        : [...selectedValue, choice]
      : [choice];
    onSelect?.(newValue);
  };

  const renderChoice = (choice: string, index: number) => {
    if (onSelect) {
      switch (choiceType) {
        case "checkbox":
          return (
            <div key={index} className="form-check form-check-inline">
              <input
                type="checkbox"
                className="form-check-input custom-check-input"
                id={`choice-${index}`}
                value={choice}
                checked={Array.isArray(selectedValue) && selectedValue.includes(choice)}
                onChange={() => handleCheckboxChange(choice)}
              />
              <label className="form-check-label font-14 font-medium" htmlFor={`choice-${index}`}>
                {choice}
              </label>
            </div>
          );
        case "radio":
          return (
            <div key={index} className="form-check form-check-inline">
              <input
                type="radio"
                className="user-radio form-check-input bg-transparent text-color-1"
                id={`choice-${index}`}
                name={label}
                value={choice}
                checked={selectedValue === choice}
                onChange={() => onSelect(choice)}
              />
              <label className="form-check-label font-14 font-medium text-color-2" htmlFor={`choice-${index}`}>
                {choice}
              </label>
            </div>
          );
        default:
          return null;
      }
    } else {
      // Original rendering
      return (
        <div key={index} className="col-md-5 col-sm-12 mb-3 d-flex align-items-center">
          {choiceType === "checkbox" && (
            <input type="checkbox" className="form-check-input align-middle p-2 me-4 user-checkbox update-prompt-checkbox" disabled />
          )}
          {choiceType === "radio" && (
            <label className="me-3">
              <span className="circle-label d-block" />
            </label>
          )}
          <InputComponent
            type="text"
            className="form-control theme-focus input-field text-color-4 bg-color custom-border-3"
            value={choice}
            placeholder={`Enter choice ${index + 1}`}
            onChange={(value: string) => onChangeChoice(index, value)}
            onBlur={onBlurChoice}
          />
        </div>
      );
    }
  };

  const renderDropdown = () => {
    if (onSelect) {
      return (
        <select
          className="form-select font-14 font-medium theme-focus input-field custom-border-3 text-color-4 bg-color-4"
          value={selectedValue as string}
          onChange={(e) => onSelect(e.target.value)}
        >
          <option value="">Select</option>
          {choices.map((choice, index) => (
            <option key={index} value={choice}>
              {choice}
            </option>
          ))}
        </select>
      );
    } else {
      return (
        <div className="col-md-6 col-sm-12 mb-3">
          <div className="input-field pe-2 bg-color custom-border-3">
            <ul className="list-unstyled common-dropdown-scroll-popup mb-0">
              <li className="custom-border-bottom text-color-2 font-14 py-2">Add Choices</li>
              {choices.map((choice, index) => renderChoice(choice, index))}
            </ul>
          </div>
        </div>
      );
    }
  };

  return (
    <div className={choiceType === "dropdown" ? "row pb-3" : "w-100"}>
      {label && onSelect && (
        <label className="form-label font-15 font-semibold w-100 text-color-1">
          {label}
          {required && <span className="color-red">*</span>}
        </label>
      )}
      {choiceType === "dropdown" ? renderDropdown() : choices.map((choice, index) => renderChoice(choice, index))}
    </div>
  );
};

export default ChoicesComponent;