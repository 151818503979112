
import React, { useState, useEffect, ChangeEvent, KeyboardEvent, FocusEvent } from 'react';

import InputProps from '../Interfaces/TextInput'

const InputComponent: React.FC<InputProps> = ({
  type,
  className,
  placeholder,
  value: initialValue = '',
  getevalue,
  onChange,
  onKeyUp,
  onEnterPress,
  onFocus,
  onBlur,
  ...props
}) => {
  const [value, setValue] = useState(initialValue);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target?.value;

    setValue(newValue);
    if (onChange) {
      onChange(newValue);    }
    if(getevalue){
      getevalue(e)
    }
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (onKeyUp) {
      onKeyUp(e);
    }
    if(onEnterPress && e.key==='Enter'){
        onEnterPress()
    }
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <input
      type={type}
      className={`${className} ${isFocused ? 'focused' : ''}`}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      onKeyUp={handleKeyUp}
      onFocus={handleFocus}
      onBlur={handleBlur}
      {...props}
    />
  );
};

export default InputComponent;