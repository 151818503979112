 
import React, { useState, useEffect } from 'react';
import DynamicForm, { FormField } from './DynamicForm';
 
interface PopupBuilderProps {
  showModal: boolean;
  title: string;
  fields: FormField[];
  onClose: () => void;
  onSave: (data: any) => void;
}
const PopupBuilder: React.FC<PopupBuilderProps> = ({
  showModal,
  title,
  fields,
  onClose,
  onSave,
}) => {
  const [formData, setFormData] = useState<Record<string, any>>({});
 
  useEffect(() => {
    const initialData: Record<string, any> = {};
    fields.forEach(field => {
        initialData[field.name] = field.value || '';
    });
    setFormData(initialData);
}, [fields]);
 
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
      const { name, value } = e.target;
      setFormData(prevData => ({ ...prevData, [name]: value }));
  };
 
  const handleSave = () => {
      onSave(formData);
      onClose();
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      handleSave();
    }
  };
 
  if (!showModal) return null;
 
  return (
    <>
    <div
        className="modal-backdrop fade show"
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1040
        }}
        onClick={onClose}
      />
 
      <div className="modal fade show" style={{
        display: 'block',
        zIndex: 1050 // Ensure popup is above backdrop
      }}>
      <div className="modal fade show" style={{ display: 'block' }}>
          <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content bg-color-4">
                  <div className="modal-header border-0">
                      <h1 className="modal-title fs-5 text-color-1" id="staticBackdropLabel">
                          {title}
                      </h1>
                      <button type="button" className="btn-close" onClick={onClose}></button>
                  </div>
                  <div className="modal-body"  onKeyDown={handleKeyDown}>
                      <DynamicForm
                          fields={fields.map(field => ({
                              ...field,
                              change: handleChange,  // Pass the handleChange function here
                              value: formData[field.name] || field.value || '',
                          }))}
                      />
                  </div>
                  <div className="modal-footer border-0">
                      <button type="button" className="btn custom-outline-btn font-14 px-4 py-2 me-3 font-bold" onClick={onClose}>
                          Cancel
                      </button>
                      <button type="button" className="btn primary-btn font-14 font-bold border-radius-5 color-white px-4 py-2" onClick={handleSave}>
                          Save
                      </button>
                  </div>
              </div>
          </div>
      </div>
      </div>
      </>
  );
};
 
export default PopupBuilder;