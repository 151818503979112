import React, { useState, useEffect, useContext } from "react";
import { Route, Routes, useLocation, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { ConfigProvider } from "antd";
import { AccountInfo } from "@azure/msal-browser";
import { useMsal, useAccount } from "@azure/msal-react";
import io from 'socket.io-client';
import devConfig from "./config/env";
// Context Providers
import UserContext from "./Auth/UserContext";
import { CostProvider } from "./Utility/CostAnalysisContext";
import { PromptBuilderProvider } from "./Utility/ManagePromptContext";
import { ManagePromptCardProvider } from "./Utility/ManagePromptCardContext";
import { HeaderProvider } from "./Utility/HeaderContext";
import { NavFooterProvider } from "./Header Components/NavFooterComponent/NavFooterContext";
import { ButtonActionsProvider } from "./Header Components/NavHeaderComponent/ButtonComponentActionContext";
import { NavLinkProvider } from "./Header Components/NavLinkComponent/NavLinkContext";
import { TableProvider } from "./Utility/ReusableTableContext";
import { PopupProvider } from "./Utility/PopupContext";
import { DateProvider } from "./Utility/DateContext";
import { FilterProvider } from "./Utility/FilterContext";
import { LlmConfigProvider } from "./Utility/LLMConfigurationForm";
import { ConfigureProvider } from "./Utility/ConfigContext";
import { ChatProvider } from "./Utility/ChatContext";
import { FileUploadProvider } from "./Utility/FileUploadContext";
import { LlmFormProvider } from "./Utility/Input";
import { WholeDataProvider } from "./Utility/WholeDataContext";
import { KnowledgeBaseProvider } from "./Utility/AddKnowledgeBaseContext";
import { ImageDocLinksProvider } from "./Utility/ImageDocLinksContext";
import { DropDownProvider } from "./Utility/DropDownContext";
import { DiagramPreviewWrapper } from "./Utility/DiagramPreviewWrapper";

// Utility Functions
import { setSessionItem, getSessionItem } from "./Utility/encrypt-storage";

// Header Components
import HeaderComponent from "./Header Components/Header";

// Main Components - Grid
import { LlmConfigGrid } from "./Main Components/Grid/LLMConfigGrid";
import AsamiUsageTable from "./Main Components/Grid/AsamiUsageGrid";
import CostAnalysisTable from "./Main Components/Grid/CostAnalysisGrid";
import ChatHistory from "./Main Components/Grid/ChatHistory";
import ManageIssues from "./Main Components/Grid/Projects/ManageIssues";
import ManageTask from "./Main Components/Grid/Projects/ManageTask";
import { ManageProject } from "./Main Components/Grid/Projects/ManageProject";
import ManageExecution from "./Main Components/Grid/ManageExecution";
import UserActivityLogs from "./Main Components/Grid/UserActivityLogs";

// Main Components - Chat
import ChatComponent from "./Main Components/ChatComponent/ChatComponent";

// Main Components - Manage Prompt
import ManagePromptCards from "./Main Components/Manage Prompt/ManagePromptCard";
import ManageTaskAndSequence from "./Main Components/Manage Prompt/ManagePromptTask";
import ManagePromptBaseComponent from "./Main Components/Manage Prompt/ManagePromptBaseComponent";
import ManageQuestions from "./Main Components/Manage Prompt/ManageQuestions";

// Main Components - Feedback
import FeedBackForm from "./Main Components/Feedback/FeedbackForm";
import FeedbackCards from "./Main Components/Feedback/FeedbackCards";

// Main Components - Other
import { LlmConfigForm } from "./Main Components/LLMConfiguration/LLMConfiguration";
import Dashboard from "./Main Components/Hub/Hub";
import { Login } from "./Main Components/Login";
import Error from "./Main Components/Error";
import Loader from "./Reusable Components/Loader";


import { UrlsAi } from "./Service/API-Constants";
import ProtectedRoute from "./ProtectedApp";
import { getValyriaID } from "./Service/API";
import { isAuth } from "./Auth/AuthConfig";
import ShimmerLoader from "./Reusable Components/ShimmerChatLoader";


// Define the interface for user details
interface UserDetails {
  userName: string;
  email: string;
  role: string;
  jobTitle: string;
  profileImage: string;
  department: string;
  approverMailID: string;
}

// Main App component
const App: React.FC = () => {

  const [userDetails, setUserDetails] = useState<UserDetails | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const token = getSessionItem("access-token");
  const [navOpen, setNavOpen] = useState<any>("Open");
  const [errorPage, setErrorPage] = useState(false);
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || null);
  const location = useLocation();
  let Navs = sessionStorage.getItem("NavOpen");
  const navigate = useNavigate()

  // Initialize socket connection with user-specific data
  useEffect(()=>{
    const socket = io(UrlsAi?.baseurl, {
      transports: ['websocket'],
      query: {
        user_id: account?.username,
      },
      extraHeaders: {
        "USER-ID": account?.username || "",
      },
    });

    // Handle socket connection events
    socket.on("connect", () => {});
 
  },[])

  // Update session storage with the current location pathname
  useEffect(() => {
    sessionStorage.setItem("previousLocation", location.pathname);
  }, [location.pathname]);

  // Periodically acquire tokens for authenticated users
  useEffect(() => {
    const interval = setInterval(() => {
      if (account && location?.pathname !== "/") {
        (async () => {
          try {
            const accountResponse = await instance.acquireTokenSilent({
              account: account as AccountInfo,
              scopes: ["User.Read"],
            });

            const apiToken = await instance.acquireTokenSilent({
              account: account as AccountInfo,
              scopes: [devConfig?.roleConfig?.ScopeID],
            });

            setSessionItem("api-token", apiToken.accessToken);
            setSessionItem("access-token", accountResponse.accessToken);
            localStorage.setItem("api-token", apiToken.accessToken);
            localStorage.setItem("access-token", accountResponse.accessToken);
          } catch (error) {
            console.error("Error acquiring tokens:", error);
          }
        })();
      }
    }, 300000);
    return () => clearInterval(interval);
  }, [account, location?.pathname, instance]);

  // Fetch user data and update local storage when account or token changes
  useEffect(() => {
    setErrorPage(false);
    if (account && location?.pathname !== "/") {
      fetchUserData();
      fetchValyriaId();
    }
  }, [account, token]);


  // Function to update user details state
  const updateUserDetails = (newUserDetails: UserDetails) => {
    setUserDetails(newUserDetails);
  };
  //function to fetch valyria ID
  const fetchValyriaId = async () => {
    try{
      const response = await getValyriaID()
  
      localStorage.setItem('valyriaId', response.appId)
    }
    catch{
 
    }
  }


// Function to fetch and process user data from Microsoft Graph API and other endpoints
const fetchUserData = async () => {
  if (!account) return;

  try {
    setIsLoading(false);
    let userDetails: UserDetails = {
      userName: account.name || "",
      email: account.username || "",
      role: "User",
      jobTitle: "",
      profileImage: "images/Default/default-profile-image.png",
      department: "",
      approverMailID: "",
    };

    // Store user email in session and local storage
    setSessionItem("user-email", userDetails.email);
    localStorage.setItem("user-email", userDetails.email);

    // Acquire access tokens for API calls
    const accountResponse = await instance.acquireTokenSilent({
      account: account as AccountInfo,
      scopes: ["User.Read"],
    });

    const apiToken = await instance.acquireTokenSilent({
      account: account as AccountInfo,
      scopes: [devConfig?.roleConfig?.ScopeID],
    });

    setSessionItem("api-token", apiToken.accessToken);
    setSessionItem("access-token", accountResponse.accessToken);

    // Fetch user profile data (e.g., job title)
    const profileData = await axios.get(
      `https://graph.microsoft.com/v1.0/me`,
      {
        headers: {
          Authorization: `Bearer ${accountResponse.accessToken}`,
        },
      }
    );

    userDetails.jobTitle = profileData.data.jobTitle;

    // Fetch and process user profile image
    try {
      const profileImage = await axios.get(
        `https://graph.microsoft.com/v1.0/me/photo/$value`,
        {
          headers: {
            Authorization: `Bearer ${accountResponse.accessToken}`,
          },
          responseType: "blob",
        }
      );

      const reader = new FileReader();
      reader.onloadend = () => {
        const dataUrl = reader.result as string;
        userDetails.profileImage = dataUrl;
      };
      reader.readAsDataURL(profileImage.data);
    } catch (error) {
      console.error(error);
    }

    // Fetch and process user department information
    try {
      const departmentResponse = await axios.get(
        `https://graph.microsoft.com/beta/users/${account.localAccountId}/department`,
        {
          headers: {
            Authorization: `Bearer ${accountResponse.accessToken}`,
          },
        }
      );
      userDetails.department =
        departmentResponse.data.value || "Not specified";
      if (userDetails.department.includes("Team")) {
        userDetails.department = userDetails.department
          .replace(/\bTeam\b/g, "")
          .trim();
      }
    } catch (error) {
      console.error("Error fetching department:", error);
      userDetails.department = "Not available";
    }

    // Determine user role based on group memberships and hierarchy
    try {
      // Check if the user is part of the Leadership DL
      const isInLeadership = await axios.get(
        `https://graph.microsoft.com/v1.0/groups/${devConfig?.roleConfig?.LeadershipDL}/transitiveMembers/${accountResponse.account.localAccountId}`,
        {
          headers: {
            Authorization: `Bearer ${accountResponse.accessToken}`,
          },
        }
      );
      userDetails.role = "Leadership";
    } catch (err) {
      try {
        // Check if the user is part of the Leadership Group
        const isInLeadership = await axios.get(
          `https://graph.microsoft.com/v1.0/groups/${devConfig?.roleConfig?.LeadershipGroup}/members/${accountResponse.account.localAccountId}`,
          {
            headers: {
              Authorization: `Bearer ${accountResponse.accessToken}`,
            },
          }
        );
        userDetails.role = "Leadership";
      } catch (err) {
        try {
          // Check if the user has direct reports (Lead role)
          const isInLeads = await axios.get(
            `https://graph.microsoft.com/v1.0/users/${accountResponse.account.localAccountId}/directReports`,
            {
              headers: {
                Authorization: `Bearer ${accountResponse.accessToken}`,
              },
            }
          );
          if (isInLeads?.data?.value?.length > 0) {
            userDetails.role = "Lead";
          } else {
            try {
              // Check if the user is part of the Lead Group
              const isInLeads = await axios.get(
                `https://graph.microsoft.com/v1.0/groups/${devConfig?.roleConfig?.LeadGroup}/members/${accountResponse.account.localAccountId}`,
                {
                  headers: {
                    Authorization: `Bearer ${accountResponse.accessToken}`,
                  },
                }
              );
              userDetails.role = "Lead";
            } catch (error) {
              userDetails.role = "User";
            }
          }
        } catch (err) {
          try {
            // Fallback check for Lead Group membership
            const isInLeads = await axios.get(
              `https://graph.microsoft.com/v1.0/groups/${devConfig?.roleConfig?.LeadGroup}/members/${accountResponse.account.localAccountId}`,
              {
                headers: {
                  Authorization: `Bearer ${accountResponse.accessToken}`,
                },
              }
            );
            userDetails.role = "Lead";
          } catch (error) {
            userDetails.role = "User";
          }
        }
      }
    }

    // Update user details state and set loading to true
    updateUserDetails(userDetails);
    setIsLoading(true);
  } catch (error) {
    console.error(error);
  }
};

useEffect(() => {
  if (!isAuth(accounts) && localStorage.getItem("login") != "true") {
    navigate('/')
  }
}, []);

return (
  <UserContext.Provider value={userDetails}>

    <ButtonActionsProvider>
      <NavLinkProvider>
        <NavFooterProvider>
          <HeaderProvider>
            {!errorPage && location?.pathname !== "/" ? (
              <HeaderComponent setNavOpen={setNavOpen} navOpen={navOpen} />
            ) : (
              <></>
            )}
          </HeaderProvider>
        </NavFooterProvider>
      </NavLinkProvider>
    </ButtonActionsProvider>

    <ConfigProvider>
      <TableProvider>
        <ImageDocLinksProvider>

          <main>
            <Routes>

              <Route path="/" element={<Login />} />

              <Route
                path="/chat"
                element={
                  <DropDownProvider>
                    <ManagePromptCardProvider>
                      <PromptBuilderProvider>
                        <KnowledgeBaseProvider>
                          <ConfigureProvider>
                            <FileUploadProvider>
                              <ChatProvider>
                                <HeaderProvider>
                                  <ButtonActionsProvider>
                                    <ChatComponent navOpen={navOpen} setNavOpen={setNavOpen} />
                                  </ButtonActionsProvider>
                                </HeaderProvider>
                              </ChatProvider>
                            </FileUploadProvider>
                          </ConfigureProvider>
                        </KnowledgeBaseProvider>
                      </PromptBuilderProvider>
                    </ManagePromptCardProvider>
                  </DropDownProvider>
                }
              />

              {/* Hub Route */}
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute requiredAccess={'Leadership'}>
                    <Dashboard navOpen={navOpen} />
                  </ProtectedRoute>
                }
              />

              {/* Cost Analysis Route */}
              <Route
                path="/cost-analysis"
                element={
                  <ProtectedRoute requiredAccess={'Leadership'}>
                    <FilterProvider>
                      <DateProvider>
                        <PopupProvider>
                          <CostProvider>
                            <CostAnalysisTable navOpen={navOpen} />
                          </CostProvider>
                        </PopupProvider>
                      </DateProvider>
                    </FilterProvider>
                  </ProtectedRoute>
                }
              />

              {/* Asami Usage Route */}
              <Route
                path="/asami-usage-grid"
                element={
                  <ProtectedRoute requiredAccess={'Leadership'}>
                    <AsamiUsageTable navOpen={navOpen} />
                  </ProtectedRoute>
                }
              />

              {/* LLM Configuration Routes */}
              <Route
                path="/llm-config-grid"
                element={
                  <ProtectedRoute requiredAccess={'Leadership'}>
                    <LlmConfigGrid navOpen={navOpen} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/asami-llm-config"
                element={
                  <ProtectedRoute requiredAccess={'Leadership'}>
                    <LlmConfigProvider>
                      <LlmFormProvider>
                        <LlmConfigForm navOpen={navOpen} />
                      </LlmFormProvider>
                    </LlmConfigProvider>
                  </ProtectedRoute>
                }
              />

              {/* Manage Project, Issues, and Tasks Routes */}
              <Route
                path="/manage-project"
                element={
                  <DateProvider>
                    <FilterProvider>
                      <TableProvider initialData={[]}>
                        <ManageProject navOpen={navOpen} />
                      </TableProvider>
                    </FilterProvider>
                  </DateProvider>
                }
              />
              <Route
                path="/manage-issues"
                element={
                  <PopupProvider>
                    <DateProvider>
                      <FilterProvider>
                        <ManageIssues navOpen={navOpen} />
                      </FilterProvider>
                    </DateProvider>
                  </PopupProvider>
                }
              />
              <Route
                path="/manage-task-project"
                element={
                  <PopupProvider>
                    <DateProvider>
                      <FilterProvider>
                        <ManageTask navOpen={navOpen} />
                      </FilterProvider>
                    </DateProvider>
                  </PopupProvider>
                }
              />
              <Route
                path="/manage-story-project"
                element={
                  <PopupProvider>
                    <DateProvider>
                      <FilterProvider>
                        <ManageTask navOpen={navOpen} />
                      </FilterProvider>
                    </DateProvider>
                  </PopupProvider>
                }
              />

              {/* Manage Execution Route */}
              <Route
                path="/asami-manage-execution"
                element={
                  <ProtectedRoute requiredAccess={'Leadership'}>
                    <ManagePromptCardProvider>
                      <PromptBuilderProvider>
                        <ManageExecution navOpen={navOpen} />
                      </PromptBuilderProvider>
                    </ManagePromptCardProvider>
                  </ProtectedRoute>
                }
              />

              {/* Manage Prompt Routes */}
              <Route
                path="/asami-manage-prompt"
                element={
                  <ProtectedRoute requiredAccess={'Leadership'}>
                    <ManagePromptCardProvider>
                      <PromptBuilderProvider>
                        <ManagePromptCards navOpen={navOpen} />
                      </PromptBuilderProvider>
                    </ManagePromptCardProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/asamimanagebase"
                element={
                  <ProtectedRoute requiredAccess={'Leadership'}>
                    <ManagePromptCardProvider>
                      <PromptBuilderProvider>
                        <ManagePromptBaseComponent navOpen={navOpen} />
                      </PromptBuilderProvider>
                    </ManagePromptCardProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/manage-questions"
                element={
                  <ProtectedRoute requiredAccess={'Leadership'}>
                    <ManagePromptCardProvider>
                      <PromptBuilderProvider>
                        <ManageQuestions />
                      </PromptBuilderProvider>
                    </ManagePromptCardProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/manage-task"
                element={
                  <ProtectedRoute requiredAccess={'Leadership'}>
                    <ManagePromptCardProvider>
                      <PromptBuilderProvider>
                        <ManageTaskAndSequence />
                      </PromptBuilderProvider>
                    </ManagePromptCardProvider>
                  </ProtectedRoute>
                }
              />

              {/* Feedback Routes */}
              <Route
                path="/feedback-form"
                element={
                  <ProtectedRoute requiredAccess={'User'}>
                    <FeedBackForm />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/feedback-cards"
                element={
                  <ProtectedRoute requiredAccess={'Leadership'}>
                    <FilterProvider>
                      <DateProvider>
                        <FeedbackCards navOpen={navOpen} />
                      </DateProvider>
                    </FilterProvider>
                  </ProtectedRoute>
                }
              />

              {/* User Activity and Past Conversations Routes */}
              <Route
                path="/user-activity-logs"
                element={
                  <ProtectedRoute requiredAccess={'Lead'}>
                    <HeaderProvider>
                    <PopupProvider>
                      <DateProvider>
                        <FilterProvider>
                          <UserActivityLogs navOpen={navOpen} />
                        </FilterProvider>
                      </DateProvider>
                    </PopupProvider>
                    </HeaderProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/past-conversations"
                element={
                  <PopupProvider>
                    <DateProvider>
                      <FilterProvider>
                        <HeaderProvider>
                        <ChatHistory navOpen={navOpen} />
                        </HeaderProvider>
                      </FilterProvider>
                    </DateProvider>
                  </PopupProvider>
                }
              />

              {/* Miscellaneous Routes */}
              <Route
                path="/diagram-preview"
                element={<DiagramPreviewWrapper navOpen={navOpen} />}
              />
              <Route
                path="/loader"
                element={<Loader navOpening={navOpen} isLoading={true} />}
              />
               <Route
                path="/shimmer-loader"
                element={<ShimmerLoader navOpening={navOpen} isLoading={true} />}
              />
              <Route path="/not-found" element={<Error setErrorPage={setErrorPage} />} />
              <Route path="*" element={<Error setErrorPage={setErrorPage} />} />

            </Routes>
          </main>

        </ImageDocLinksProvider>
      </TableProvider>
    </ConfigProvider>
    
  </UserContext.Provider>
);

};

export default App;
