import { useSearchParams } from 'react-router-dom';
import { DiagramButtonProvider } from './DiagramButtonContext';
import DiagramPreview from '../Main Components/ChatComponent/DiagramComponent';

// Wrapper component for DiagramPreview to handle diagram type from URL search params
export const DiagramPreviewWrapper = ({ navOpen }: { navOpen: any }) => {
  // Extract search parameters from the URL
  const [searchParams] = useSearchParams();
  const diagramType = searchParams.get('type');

  return (
    // Provide the DiagramButton context to the DiagramPreview component
    <DiagramButtonProvider>
      <DiagramPreview
        navOpen={navOpen}
        diagramType={diagramType || 'defaultType'} // Use a default type if 'type' is not provided in the URL
      />
    </DiagramButtonProvider>
  );
};