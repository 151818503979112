import React, { useState,useEffect } from "react";
import { tfsconfigForm, kgBuildService } from "../Service/API";
import DynamicPopup from "./DynamicPopup";

interface ManageProjectFormProps {
  projectName: string;
  configureOrEdit: "configure" | "EDIT";
  projectID: string;
  initialData: { userName: string; repoUrls: string };
  setShowTfsPopUp: (show: boolean) => void; 
  handleCancelFilters?:any;
}

interface FormField {
  name: string;
  label: string;
  type: "text" | "textarea" | "password" | "checkbox" | "radio" | "dropdown";
  placeholder: string;
  options?: { value: string; label: string }[];
  value?: string;
  required: boolean;
}

export default function ManageProjectForm({ 
  projectName, 
  configureOrEdit, 
  projectID , 
  initialData,
  handleCancelFilters,
  setShowTfsPopUp,
}: ManageProjectFormProps) {
  const [formData, setFormData] = useState({
    userName: initialData.userName,
    repoUrls: initialData.repoUrls
  });

  const fields: FormField[] = [
    { 
      name: 'userName', 
      label: 'User Name', 
      type: "text", 
      required: true, 
      placeholder: 'Enter User Name',
      value: formData.userName // Use value from formData
    },
    { 
      name: 'repoUrls', 
      label: 'Repository URLs', 
      type: "textarea", 
      required: true, 
      placeholder: 'Enter Repository URLs (comma-separated)',
      value: formData.repoUrls // Use value from formData
    }
  ];

  const successMessage = (
    <div>
      <span className="color-green p-3 finish-decor">
        <img src="images/success-green-tick.svg" alt="finish-icon" />
      </span>
      {/* <h4 className="font-24 font-bold mt-4 text-color-1">Great!</h4> */}
      <h5 className="font-16 font-bold mt-4 mb-4 text-color-4">
      Project(s) is configured successfully! KG building in progress,  Come back after a while.
      </h5>
    </div>
  );
  
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
      if (isSuccess) {
        const timer = setTimeout(() => {
          handleCancel();
        }, 20000); 
  
        return () => clearTimeout(timer);
      }
    }, [isSuccess]);
  

  const validateForm = (data: typeof formData) => {
    if (!data.userName || !data.repoUrls) {
      setErrors("Please fill out all the required fields");
      return false;
    }

    const usernameRegex = /^[a-zA-Z0-9._-]+$/;
    if (!usernameRegex.test(data.userName)) {
      setErrors("Please provide a valid username");
      return false;
    }

    const repoUrls = data.repoUrls.split(',').map(url => url.trim());
    if (repoUrls.some(url => !url)) {
      setErrors("Please provide valid repository URLs (comma-separated)");
      return false;
    }

    setErrors("");
    return true;
  };

  const processRepoUrls = (repoUrls: string) => {
    return repoUrls.split(',').map(url => {
      const trimmedUrl = url.trim();
      const repoName = trimmedUrl.split('/').pop()?.replace('.git', '') || '';
      return {
        Repo_Url: trimmedUrl,
        Repo_name: repoName
      };
    });
  };

  const handleSave = async (data: typeof formData) => {
    if (!validateForm(data)) {
      return;
    }

    setLoading(true);
    try {
      setFormData({userName:data.userName.trim(),repoUrls:data.repoUrls.split(',')[0].trim()})
      const userPayload = {
        key: configureOrEdit,
        next: "TFS",
        username: data.userName.trim(),
        projectUrl: data.repoUrls.split(',')[0].trim(),
        
        project_id: projectID
      };

      const userResponse = await tfsconfigForm(userPayload);
      if (!userResponse?.data?.ResponseData?.tfs_config_id) {
        throw new Error("Failed to configure TFS");
      }

      const tfsConfigId = userResponse.data.ResponseData.tfs_config_id;

      const repoPayload = {
        key: configureOrEdit,
        next: "REPO",
        tfs_config_id: tfsConfigId,
        selected_repos: processRepoUrls(data.repoUrls)
      };

      const repoResponse = await tfsconfigForm(repoPayload);
      if (repoResponse?.data?.status_code !== 200) {
        throw new Error("Failed to configure repositories");
      }
      setIsSuccess(true);

      const kgPayload = {
        ProjectName: projectName,
        Repos: processRepoUrls(data.repoUrls),
        ProjectId: projectID.toUpperCase()
      };

      const kgResponse = await kgBuildService(kgPayload);
      // if (kgResponse?.data.status_code !== 200) {
      //   throw new Error("Failed to create KG");
      // }

    } catch (error) {
      setErrors(error instanceof Error ? error.message : "An unexpected error occurred");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setShowTfsPopUp(false);
    handleCancelFilters();
    setFormData({
      userName: "",
      repoUrls: ""
    });
  };

  

  return (
    <DynamicPopup
      showModal={true}
      onClose={handleCancel}
      onSave={isSuccess ? handleCancel : handleSave}
      title={`${projectName} - ${isSuccess ? 'projectSuccess' : 'Configure Project'}`}
      steps={isSuccess ? [] : [{ title: 'Configure Project', fields }]}
      currentStep={0}
      onNext={() => {}}
      errors={errors}
      loading={loading}
      columns={1}
      successMessage={isSuccess ? successMessage : undefined}
    />
  );
}