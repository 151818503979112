
import React, { useEffect, useState, useContext } from "react";
import UserContext from "../../Auth/UserContext";
import { getModelConfig, deleteModelConfiguration, getAllModelConfig, errorLogs } from "../../Service/API"
import { useNavigate } from "react-router-dom";
import Loader from "../../Reusable Components/Loader";
import { isAuth } from "../../Auth/AuthConfig";
import { useMsal } from "@azure/msal-react";
import YesOrNoPopup from "../../Reusable Components/YesOrNoPopup";
import { ModelConfig, getModelConfigProps } from "../../Interfaces/LLMConfigGrid";
import { AxiosResponse } from "axios";
import ReusableTable from "../../Reusable Components/ReusableTable";
import { Header } from "../../Interfaces/ReusableTable";
import { useTableContext } from "../../Utility/ReusableTableContext";
import DetailedView from "../../Reusable Components/DetailedView";
import ModuleHeading from "../../Reusable Components/ModuleHeading";
import TextInput from "../../Reusable Components/TextInput";
import NumberOfRecordsComponent from "../../Reusable Components/NoofRecords";
import ButtonComponent from "../../Reusable Components/Button";
export function LlmConfigGrid({ navOpen }: { navOpen: string }) {
  const { data: modelConfigData, setData: setModelConfigData, sortConfig, isModalOpen, setIsModalOpen,setDetailedViewData } = useTableContext<ModelConfig>();
  const [search, setSearch] = useState<string>("");
  const [count, setCount] = useState<number>(10);
  const [shownRecords, setShownRecords] = useState<number>(count);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [modelConfigurationID, setModelConfigurationID] = useState<string | undefined>("");
  const userDetails = useContext(UserContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { accounts } = useMsal();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [popUpText, setPopUpText] = useState<string>("");

  const history = useNavigate();

  useEffect(() => {
    if (!isAuth(accounts)) {
      history("/");
    } else {
      fetchModelConfig();
    }
  }, []);

  useEffect(() => {
    fetchModelConfig();
  }, [sortConfig, count]);

  const fetchModelConfig = async () => {
    try {
      const modelConfig: getModelConfigProps = {
        search: search,
        sort:sortConfig.column=='modelConfiguration.displayName'||sortConfig.column==''?sortConfig.order=='asc'?`${sortConfig.column||'modelConfiguration.displayName'} desc`:`${sortConfig.column||'modelConfiguration.displayName'} asc`:`${sortConfig.column} ${sortConfig.order || ''}`,
        count: count,
        createdBy: userDetails?.email,
        modelConfigurationID: ""
      };
   
      setIsLoading(true);
      const response: AxiosResponse<any, any> = await getAllModelConfig(modelConfig);
   
      if (response.status === 200) {
        setModelConfigData(response.data.data.responseData);
        const record = response.data.data.responseData.length - shownRecords;
        setShownRecords(record + shownRecords);
        setTotalRecords(response.data.data.modelCount);
      } 
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const deleteModelConfig = async (id: string | undefined) => {
    try {
      const obj = {
        modelConfigurationID: id
      };
   
      setIsLoading(true);
      const response: AxiosResponse<any, any> | null = await deleteModelConfiguration(obj);
   
      if (response?.status === 200) {
        setShowPopup(false);
        await fetchModelConfig();
      }
    } catch (error) {
      await handleError(error, "deleteModelConfig");
    } finally {
      setIsLoading(false);
    }
  };

  const handleError = async (error: unknown, functionName: string) => {
    let errorMessage = 'An unknown error occurred';
    if (error instanceof Error) {
      errorMessage = error.message;
    } else if (typeof error === 'string') {
      errorMessage = error;
    }
    await errorLogs({
      errorMessage: errorMessage,
      userEmailID: userDetails?.email,
      errorOccured: "Frontend",
      functionName: functionName,
    });
  };

  const loadmoreClick = () => {
    let countValue = count + 10;
    setCount(countValue);
    if (shownRecords >= totalRecords) {
      setIsLoadMoreDisabled(true);
    }
  };

  const ActionButtons = ({ row }: { row: ModelConfig }) => (
    <>
      <button
        className="btn text-nowrap font-14 font-medium"
        onClick={() => handleEdit(row)}
      >
        <img src="images-new/edit-icons.svg" alt="Edit" />
      </button>
      <button
        className="btn text-nowrap font-14 font-medium"
        onClick={() => handleDelete(row)}
      >
        <img src="images-new/delete-icons.svg" alt="Delete" />
      </button>
    </>
  );

  const handleRowClick = (row: ModelConfig) => {
    setDetailedViewData(row);
    setIsModalOpen(true);
  };

  const headers: Header<ModelConfig>[] = [
    { key: 'modelConfiguration.displayName', label: 'Display Name', sortable: true },
    { key: 'modelConfiguration.modelName', label: 'Model Name', sortable: true },
    { key: 'modelTypeName', label: 'Model Type', sortable: true },
    { key: 'actions', label: 'Actions', component: ActionButtons },
  ];

  const handleEdit = (row: ModelConfig) => {
    history(`/asami-llm-config?modelConfigurationID=${row.modelConfiguration.modelConfigurationID}`);
  };

  const handleDelete = (row: ModelConfig) => {
    setPopUpText(`delete ${row.modelConfiguration.displayName}`);
    setModelConfigurationID(row.modelConfiguration.modelConfigurationID);
    setShowPopup(true);
  };

  return (
    <>
      <div className={`content-wrapper ${isModalOpen ? 'modal-open' : ''}`}>
        {isLoading ? (
          <Loader isLoading={isLoading} navOpening={navOpen}/>
        ) : (
          <div className="container-fluid">
            <div className="row justify-content-lg-end justify-content-center">
              <div className={navOpen === "Close" ? "col-11" : "col-lg-9 cust-me-lg-4 col-11 mt-3"}>
                <div className="row custom-nav-tab-width">
                  <div className="my-4 d-grid d-md-flex justify-content-md-between align-items-center custom-llm-config-top-spacing">
                  <ModuleHeading text="LLM Configuration" tag='h3' className="font-20 font-bold text-color-1"/>
                    <div className="d-grid d-md-flex align-items-center">
                      <div className="input-group transparent-grp w-100 search-bg">
                      <TextInput 
                     type="text"
                     className="form-control custom-search-input text-nowrap search-bg border-0 text-color-4 focus-none font-14 font-medium pe-0 ps-3"
                     placeholder="Search"
                     value={search}
                     onChange={(value: string) => setSearch(value)}
                     onEnterPress={fetchModelConfig}
                    />
                    <ButtonComponent buttonType='icon' className='focus-none search-bg border-0'  onClick={() => fetchModelConfig()} iconSrc='images/Default/input-search.svg' altText='input-search-icon'></ButtonComponent>

                      </div>
                      <ButtonComponent  className='primary-btn font-14 font-semibold border-radius-5 border-0 px-4 py-2 ms-3'  style={{ width: "-webkit-fill-available" }} onClick={() => history('/asami-llm-config')}iconSrc='images\Default\add-white-icon.svg' altText="Add-icon" text="New Configuration"></ButtonComponent>
        
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="table-responsive rounded-3 transparent-grp">
                      <ReusableTable<ModelConfig>
                        
                        headers={headers}
                        onRowClick={handleRowClick}
                      />
                    </div>
                    <NumberOfRecordsComponent shownRecords={shownRecords} totalRecords={totalRecords} className="font-regular font-14 text-color-1 mt-3 mb-4" />
                    <div className="text-center mb-4">
                      <button
                        className="btn btn-gradient-outline-loadmore border-radius-4 font-14 font-semibold py-2 px-3"
                        type="button"
                        onClick={loadmoreClick}
                        hidden={totalRecords <= 10 || isLoadMoreDisabled}
                      >
                        <span className="color-gradient">Load More</span>
                      </button>
                    </div>
                    {popUpText && showPopup && (
                      <YesOrNoPopup
                        type="model"
                        id={modelConfigurationID}
                        onConfirm={deleteModelConfig}
                        onCancel={()=>{setShowPopup(false)}}
                        value={popUpText}
                        
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
  
      {isModalOpen && <div className="modal-backdrop fade show"></div>}
      <DetailedView 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)}
        title="Specified Models"
        fields={[
          { label: "Model Type", key: "modelTypeName" },
          { label: "Model Name", key: "modelConfiguration.modelName" },
          { label: "Model Description", key: "modelConfiguration.modelDescription" }
        ]}
      />
    </>
  );
}

