import React, { createContext, useContext, useState, ReactNode, useRef, useEffect } from 'react';
import { insertMessage,KBRetriever, similarityCheck, getImageDocResponse, CostCalculationNav, errorLogs, DownloadAsExcel, LoadBalancer, serpAPI, fetchChat, generateNewPrompt, chatCreation, getValyriaChat } from '../Service/API';
import { UrlsAi } from '../Service/API-Constants';
import { getSessionItem } from './encrypt-storage';
import { ConfigContext } from './ConfigContext';
import axios from "axios";
import { useNavigate } from 'react-router';
import ReactDiffViewer from 'react-diff-viewer';
import DiffViewer from './DiffViewer';




export interface ChatMessage {
  role: 'user' | 'assistant';
  content: string;
  cost?: string;
  messageID?: string;
}

interface DesiredMessage {
  isKbRetriever?: boolean;
  modelConfigurationID?: string;
}

interface HiddenObjValues {
  inputFix: string;
  sendMessage: boolean;
  regenerateBtn: boolean;
  loader: boolean;
  responseBtn: boolean;
  tokenError: string;
  APIError: string;
  invalidChat: boolean;
}
interface SearchResult {
  formattedResponse: string;
  rawOutput: string[];
}

interface IncrementVal {
  [key: number]: number;
}
interface ResponseContent {
  content: string;
}
interface CodeCompareProps {
  leftCodeArray: string[];
  rightCodeArray: string[];
}


interface ChatContextType {

  isStreaming: boolean;
  setIsStreaming: React.Dispatch<React.SetStateAction<boolean>>;
  toasterDisplay: boolean;
  setToasterDisplay: React.Dispatch<React.SetStateAction<boolean>>;
  collectionName_avalon: String
  navOpen: String;
  isRecording: boolean
  pauseButtonEnable: boolean;
  setPauseButtonEnable: React.Dispatch<React.SetStateAction<boolean>>;
  haveLastAssistantMessageCome: boolean;
  askAsamiChatCreation: boolean;
  messageOrder: number ;
  chatCreated: boolean;
  setChatCreated: React.Dispatch<React.SetStateAction<boolean>>;
  projectName_projects: string
  collectionName_projects: any
  loadingIndicator: boolean;
  setLoadingIndicator: React.Dispatch<React.SetStateAction<boolean>>;
  // startRecording: boolean, 
  // setStartRecording: React.Dispatch<React.SetStateAction<boolean>>;  
  // stopRecording: boolean,
  // setStopRecording: React.Dispatch<React.SetStateAction<boolean>>;
  setMessageOrder : React.Dispatch<React.SetStateAction<any>>;
  setAskAsamiChatCreation: React.Dispatch<React.SetStateAction<boolean>>;
  isWebSearch: boolean;
  setIsWebSearch: React.Dispatch<React.SetStateAction<boolean>>;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  askAsamiChatName: String;
  codeGenStatus: any;
  setAskAsamiChatName: React.Dispatch<React.SetStateAction<string>>;
  // setSendMessageValue: React.Dispatch<React.SetStateAction<String>>;
  setHaveLastAssistantMessageCome: React.Dispatch<React.SetStateAction<boolean>>;
  currentChat: ChatMessage[];
  setcurrentChat: React.Dispatch<React.SetStateAction<ChatMessage[]>>;
  // BLinksList: string | null;
  // setBLinksList: React.Dispatch<React.SetStateAction<string | null>>;
  isKbRetrieverActive: boolean | null;
  setIsKbRetrieverActive: React.Dispatch<React.SetStateAction<boolean | null>>;
  indexName: string | null;
  setIndexName: React.Dispatch<React.SetStateAction<string | null>>;
  // collectionName: string | null;
  // setCollectionName: React.Dispatch<React.SetStateAction<string | null>>;
  isEditMessage: boolean | null;
  setIsEditMessage: React.Dispatch<React.SetStateAction<boolean | null>>;
  newMessageID: string | null;
  setNewMessageID: React.Dispatch<React.SetStateAction<string | null>>;
  promptData: any;
  setPromptData: React.Dispatch<React.SetStateAction<any>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  bURL: string;
  setBUrl: React.Dispatch<React.SetStateAction<string>>;
  sendMessageValue: string;
  setsendMessageValue: React.Dispatch<React.SetStateAction<string>>;
  data: string;
  setData: React.Dispatch<React.SetStateAction<string>>;
  UtcDateTime: string
  setUtcDateTime: React.Dispatch<React.SetStateAction<string>>;
  isKGBuild: boolean;
  setIsKGBuild: React.Dispatch<React.SetStateAction<boolean>>;
  leftCodeArray: Record<number, string>;
  setLeftCodeArray: React.Dispatch<React.SetStateAction<Record<number, string>>>;
  rightCodeArray: Record<number, string>;
  setRightCodeArray: React.Dispatch<React.SetStateAction<Record<number, string>>>;
  isModule: string;
  setIsModule: React.Dispatch<React.SetStateAction<string>>;
  excelURLs: Record<number, string>;
  setExcelURLs: React.Dispatch<React.SetStateAction<Record<number, string>>>;
  modelID: string;
  setModelID: React.Dispatch<React.SetStateAction<string>>;
  defaultModelFromEnv: string;
  setDefaultModelFromEnv: React.Dispatch<React.SetStateAction<string>>;
  hiddenObjValues: HiddenObjValues;
  sethiddenObjValues: React.Dispatch<React.SetStateAction<HiddenObjValues>>;
  userData: Record<string, any>;
  setUserData: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  handleVoiceButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleBtns: (e: React.MouseEvent<HTMLButtonElement>, BLinksList?:any) => void;
  KbChatName: boolean;
  setKbChatName: React.Dispatch<React.SetStateAction<boolean>>;
  startConversation: boolean;
  setStartConversation: React.Dispatch<React.SetStateAction<boolean>>;
  askAsamiChatID: String;
  setAskAsamiChatID: React.Dispatch<React.SetStateAction<string>>;
  apiCallMade: boolean;
  setApiCallMade: React.Dispatch<React.SetStateAction<boolean>>;
  codeSnippetValue: String;
  setCodeSnippetValue: React.Dispatch<React.SetStateAction<string>>;
  CodeSnippet: boolean;
  setCodeSnippet: React.Dispatch<React.SetStateAction<boolean>>;
  isAskAsami: boolean;
  setIsAskAsami: React.Dispatch<React.SetStateAction<boolean>>;
  currentChatID: any;
  setcurrentChatID: React.Dispatch<React.SetStateAction<string>>;
  kbContents:any;
  setKbContents: React.Dispatch<React.SetStateAction<any>>;
  webContents:any; 
  setWebContents: React.Dispatch<React.SetStateAction<any>>;
  incrementval:any;
  setincrementval: React.Dispatch<React.SetStateAction<any>>;
  webResult : String
  setWebResult: React.Dispatch<React.SetStateAction<string>>; 
  webSearchResult: string;
  openKbIndex:any;
  setOpenKbIndex: React.Dispatch<React.SetStateAction<any>>;
  openWebIndex:any;
  setOpenWebIndex: React.Dispatch<React.SetStateAction<any>>;
  askAsamiKbRetriever:boolean | undefined;
  setAskAsamiKbRetriever:  React.Dispatch<React.SetStateAction<boolean | undefined>>;
  askAsamiKbData: any;
  setAskAsamiKbData: React.Dispatch<React.SetStateAction<any>>;
  profile: any;
  setProfile: React.Dispatch<React.SetStateAction<any>>; 
  promptName: string;
  setPromptName: React.Dispatch<React.SetStateAction<string>>;
  approverName: string;
  setApproverName: React.Dispatch<React.SetStateAction<string>>;
  jsonObj: string;
  setJsonObj: React.Dispatch<React.SetStateAction<string>>;
  isPrompt: boolean
  setIsCodeGen: React.Dispatch<React.SetStateAction<boolean>>;
  isCodeGen: boolean
  setIsPrompt: React.Dispatch<React.SetStateAction<boolean>>;
  userPromptQuery: string;
  setUserPromptQuery: React.Dispatch<React.SetStateAction<string>>;
  populateMsg: string; 
  setPopulateMsg: React.Dispatch<React.SetStateAction<string>>;
  voiceToText: string;
  setVoiceToText: React.Dispatch<React.SetStateAction<string>>;
  formattedWebSearchResult:any;
  isStopGeneration:boolean;
  setIsStopGeneration:React.Dispatch<React.SetStateAction<boolean>>;
  newChat:string;
  fileURLs:any;
  setFileURLs:React.Dispatch<React.SetStateAction<any>>;
  tempShow: string;
  setTempShow:React.Dispatch<React.SetStateAction<any>>;
  formattedDateTime: any;
  setFormatteddateTime: React.Dispatch<React.SetStateAction<any>>


  pushChat: (
    currentChat: ChatMessage[],
    roleProvided: 'user' | 'assistant',
    contentProvided: string,
    kbContentGiven?: string,
    webContentGiven?: string,
    BLinksList?:[]
  ) => Promise<void>;
  postMessage: (
    AIResponse: any,
    finish_reason: string | null,
    desiredMessage: DesiredMessage | null,
    webSearchResult?: string,
    excelURL?: string,
    parentFunction?: string,
    solvedParentFunction?: string,
    ImageBlinks?:[]
  ) => Promise<void>;
  getChatName: (firstContent: string) => Promise<string>;
  getValyriaChatName: (firstContent: string) => Promise<string>;
  handleAddKB: () => Promise<void>; 
  handleWebSearch : (query: string,BLinksList?:[]) => Promise<any>
  incrementfunc: (Chat_index: number) => void;
  decreamentfunc: (Chat_index: number) => void;
  handleD2LinkClick : (e: React.MouseEvent<HTMLAnchorElement>, _res: ResponseContent) => void;
  handleMermaidLinkClick : (e: React.MouseEvent<HTMLAnchorElement>, _res: ResponseContent) => void;
  handleLinkClick : (e: React.MouseEvent<HTMLAnchorElement>, _res: ResponseContent) => void;
  codeCompare : (Chat_index: number) => any;
  handleRegen: (BLinksList?:[]) => void;
  getPromptMsgOrder: (newChat: any) => Promise<any>;
  getChatMessages: (newChat: any) => void;
  getUsername: (newChat: any) => void,
  timeout: (ms: any)=> void,
  // setDate : (utcDateTime: string, setFormattedDateTime: (date: string)) => Promise<void>;
  setDate: () => void;
  errorMsg:string;
  setErrorMsg: React.Dispatch<React.SetStateAction<string>>;
  handleAbort: () => void ;
  requestGptToken:any;
  getGPTResponse:any;
  getPromptMessage:any;
  createChat:(askAsami: string, payload:any) => Promise<number>;
  setReplaceObj:any;
  promptIDMessages: any;
  setPromptIDMessages: React.Dispatch<React.SetStateAction<any>>;
  valyriaCurrentChat: []
  setValyriaCurrentChat: React.Dispatch<React.SetStateAction<[]>>;
  edit: boolean;
  setEdit: React.Dispatch<React.SetStateAction<boolean>>
  regen:boolean;
  setRegen: React.Dispatch<React.SetStateAction<boolean>>
  isloading:boolean; 
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  caretCoords:any; 
  setCaretCoords: React.Dispatch<React.SetStateAction<any>>;
}

interface Prompt {
  promptName: string;
  draft: boolean;
  indexName: string;
  collectionName: string;
  promptFields:any
  promptMessages:any
  promptData:any
  // Add other properties as needed
}

interface HitGPTParams {
  currentChat: ChatMessage[];
  AIResponse: string | any;
  finish_reason: string | undefined;
  excelURL?: string;
  llmbedrock?: string;
  abort?: any;
  parentFunction?: string;
  solvedParentFunction?: string;
  BLinksList?:any
}

interface ChatType {
  ChatTypeID: string;
  ChatTypeName: string;
}



const defaultHiddenObjValues: HiddenObjValues = {

  inputFix: "Type / to choose options",
  sendMessage: false,
  regenerateBtn: false,
  loader: false,
  responseBtn: false,
  tokenError: "",
  invalidChat: false,
  APIError: ""
};

export const ChatContext = createContext<ChatContextType | undefined>(undefined);

interface ChatProviderProps {
  children: ReactNode;
}

export const ChatProvider: React.FC<ChatProviderProps> = ({ children }) => {
  const [haveLastAssistantMessageCome, setHaveLastAssistantMessageCome] = useState<boolean>(false);
  let [currentChat, setcurrentChat] = useState<ChatMessage[]>([]);
  const [codeGenStatus, setCodeGenStatus] = useState("")
  // const [BLinksList, setBLinksList] = useState<string | null>(null);
  const [isKbRetrieverActive, setIsKbRetrieverActive] = useState<boolean | null>(null);
  const [indexName, setIndexName] = useState<string | null>(null);
  let [isEditMessage, setIsEditMessage] = useState<boolean | null>(null);
  const [newMessageID, setNewMessageID] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [bURL, setBUrl] = useState<string>("");
  let [sendMessageValue, setsendMessageValue] = useState<string>("");
  const [data, setData] = useState<string>("");
  const [loadingIndicator, setLoadingIndicator] = useState<boolean>(false);
  const [isKGBuild, setIsKGBuild] = useState<boolean>(false);
  const [leftCodeArray, setLeftCodeArray] = useState<Record<number, string>>({});
  const [rightCodeArray, setRightCodeArray] = useState<Record<number, string>>({});
  const [isModule, setIsModule] = useState<string>("");
  let [errorMsg, setErrorMsg]=useState<string>("")
  const [excelURLs, setExcelURLs] = useState<Record<number, string>>({});
  const [modelID, setModelID] = useState<string>("");
  const [defaultModelFromEnv, setDefaultModelFromEnv] = useState<string>("");
  const [hiddenObjValues, sethiddenObjValues] = useState<HiddenObjValues>(defaultHiddenObjValues);
  const [userData, setUserData] = useState<Record<string, any>>({});
  let [messageOrder, setMessageOrder] = useState<number>(1);
  const [userChatID, setUserChatID] =  useState<string|null>("");
  const token = getSessionItem("access-token");
  const { config }:any= useContext(ConfigContext);
  const [popMsg, setPopMsg] = useState<boolean>(false);
  const [regen, setRegen] = useState<boolean>(false);
  let [edit, setEdit] = useState(true)
  let [isStreaming, setIsStreaming]= useState<boolean>(false)
  let [toasterDisplay, setToasterDisplay] = useState<boolean>(false)
  // const [yesFixButton, setYesFixButton] = useState<boolean>(false);
  // const [message_Order, setMessage_Order] = useState<number>(0);
  const controllerRef = useRef(new AbortController);
  const [chatCreated, setChatCreated] = useState<boolean>(false);
  // const [startRecording, setStartRecording] =  useState<boolean>(false);
  // const [stopRecording, setStopRecording] =  useState<boolean>(false);
  const [navOpen, setNavOpen] = useState("Open");
  const [pauseButtonEnable, setPauseButtonEnable] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isWebSearch, setIsWebSearch] = useState(false);
  const [chatTypes, setChatTypes] = useState<ChatType[]>([]);
  const [promptData, setPromptData] = useState<Prompt | null>(null);
  const [promptSearch, setPromptSearch] = useState("");
  const [lastArrayObj, setlastArrayObj] = useState({});
  const [currentChatID, setcurrentChatID] = useState<any>("");
  const [KbChatName, setKbChatName] = useState<boolean>(false);
  const [startConversation, setStartConversation] = useState<boolean>(false);
  const [askAsamiChatID, setAskAsamiChatID] = useState<string>("");
  const [askAsamiChatCreation, setAskAsamiChatCreation] = useState<boolean>(false);
  const [askAsamiChatName, setAskAsamiChatName] = useState<string>("");
  const [apiCallMade, setApiCallMade] = useState<boolean>(false);
  const [codeSnippetValue, setCodeSnippetValue] = useState<string>("");
  const [CodeSnippet, setCodeSnippet] = useState<boolean>(false);
  const [isAskAsami, setIsAskAsami] = useState<boolean>(false);
  const [kbContents, setKbContents] = useState<any>({});
  const [webContents, setWebContents] = useState<any>({});
  const [incrementval, setincrementval] = useState<any>({});
  const [webResult, setWebResult] = useState<string>("");
  const [openKbIndex, setOpenKbIndex] = useState<any>(null);
  const [openWebIndex, setOpenWebIndex] = useState<any>(null);
  const [jsonObj, setJsonObj] = useState<any>(null);
  const [approverName, setApproverName] = useState<string>("");
  const [promptName, setPromptName]=  useState<string>("");
  const [UtcDateTime, setUtcDateTime]=  useState<any>(new Date());
  const [promptIDMessages, setPromptIDMessages] = useState<any>([]);
  const [askAsamiKbRetriever, setAskAsamiKbRetriever] = useState<boolean>();
  const [askAsamiKbData, setAskAsamiKbData] = useState<any>("");
  const [profile, setProfile] = useState<string>("");
  const [kbQuery, setKbQuery] = useState("")
  const [isKbRetriever, setIsKbRetriever] = useState()
  const [replaceObj, setReplaceObj] = useState()
  let [isPrompt,setIsPrompt]=useState<boolean>(false);
  let [isCodeGen,setIsCodeGen]=useState<boolean>(false);
  const [userPromptQuery, setUserPromptQuery]=useState<string>("");
  const [abort, setAbort] = useState<boolean>(false);
  const [populateMsg, setPopulateMsg] = useState<string>("");
  const [voiceToText, setVoiceToText] = useState<string>("");
  let globalJSON: any;
  const [tempShow, setTempShow] = useState("")
  const [formattedDateTime, setFormattedDateTime] =  useState("")
  let block_trigger_once = true;
  const [configdata, setConfigSet] = useState<boolean>()
  let promptIDMessagesArray = [];
  const [kbAskAsamiRetrieverUrl, setKbAskAsamiRetrieverUrl] = useState("");
  const [askAsamiIndexName, setAskAsamiIndexName] = useState("");
  const [askAsamiKnowledge, setAskAsamiKnowledge] = useState("");
  const [collectionName,setCollectionName] = useState("")
  const [valyriaCurrentChat, setValyriaCurrentChat] = useState([])
  const search = window.location.search;
  const newChat = new URLSearchParams(search).get("chatID");
  const [fileURLs,setFileURLs] = useState({})
  const [isloading, setIsLoading] = useState<boolean>(false);
  const [caretCoords, setCaretCoords] = useState<{ top: number, left: number } | null>(null);
  
  // let [replaceObj, setreplaceObj] = useState<object>({});
  let message_Order = 1;
  
 

  const navigate=useNavigate()
  const recognitionRef = useRef() as any;
  const audioChunksRef = useRef([]) as any;
  const transcriptRef = useRef([]) as any;


    //  const{ collectionName, 
    //     setCollectionName,
    //     kbFiles, kbURL, knowledgeData_func, knowledgeUrl_func
    //   } = useKnowledgeBase();


  let webSearchResult = ""
  let sampleArray: any[] = [];
  let formattedWebSearchResult = ""
  const State=JSON.parse(sessionStorage.getItem("issuesandtaskState")||"{}")
  const projectNameFromLocal = State['projectNameFromLocal'] || '';
  const workItemFromMI = State['workItemFromMI'] || '';
  // const collectionNameFromMI = localStorage.getItem('collectionNameFromMT') || '';
  // const collectionName_avalon = localStorage.getItem('collectionName_avalon') || '';
  const collectionName_projects = State['collectionName_projects']|| 'ZEB_CLOUDEGEN_FE';
  const collectionName_avalon = State['projectName']|| '';
  const projectName_projects =  State['projectName'] || 'CloudGen';

  const [isStopGeneration,setIsStopGeneration] = useState(false)
  let [systemPrompt, setSystemPrompt] = useState("")

  // Value not recieved
  // useEffect(() => {
  

  const handleBtns = (e: React.MouseEvent<HTMLElement>, BLinksList?:[]) => {

    if (e.target instanceof HTMLElement) {
      if (e.target.id === "yes") {
        setMessageOrder(prev => prev +=1)
        messageOrder += 1;
        messageOrder = messageOrder;
       
        if (replaceObj && promptData?.promptData?.promptMessages && messageOrder) {
          getPromptMessage(promptIDMessages, replaceObj,"",BLinksList);
        } else if (promptIDMessages && messageOrder) {
          requestGptToken(promptIDMessages, replaceObj, BLinksList);
        }
      } else if (e.target.id === "fix") {
        
        sethiddenObjValues((prevValues: HiddenObjValues) => ({
          ...prevValues,
          inputFix: "Fix your response here",
          sendMessage: false,
          responseBtn: true,
        }));
      // } else if (e.target.id === "ok") {
      //   // sethiddenObjValues((prevValues: HiddenObjValues) => ({
      //   //   ...prevValues,
      //   //   inputFix: true,
      //   // }));
      //   pushChat(currentChat, "user", fixValue);
      //   postMessage({
      //     role: "user",
      //     content: fixValue,
      //     messageType: "text",
      //   });
      } else if (e.target.id === "Regen") {
        const desiredMessage = (promptIDMessages as any)?.find(
          (message: any) => message.promptMessageOrder === messageOrder
        );
  
        sethiddenObjValues((prevValues: HiddenObjValues) => ({
          ...prevValues,
          regenerateBtn: true,
          popUpErrorMsg: "",
          tokenError: "",
          binding: false
        }));
        setErrorMsg("")
        if (desiredMessage) {
          getGPTResponse(desiredMessage,undefined, null, "",BLinksList);
        }
      }
    }
  };


 

  const handleRegen = (BLinksList?:[]) => {
    setRegen(false)
    const lastAssistantChatIndex = currentChat.map(chat => chat.role).lastIndexOf("assistant");
    const messageIDIndex = currentChat.map(chat => chat.role).lastIndexOf("user")
    
    if(messageIDIndex === -1) {
      throw new Error("No user chat found in currentChat.");
    }

    const messageID:any = currentChat[messageIDIndex].messageID || newMessageID;
    
    if (lastAssistantChatIndex !== -1) {
      if (Array.isArray(currentChat[lastAssistantChatIndex].content)) {
        for (let i = 0; i < currentChat[lastAssistantChatIndex].content.length; i++) {
          sampleArray.push(currentChat[lastAssistantChatIndex].content[i]);
        }
      } else {
        sampleArray.push(currentChat[lastAssistantChatIndex].content);
      }
      // sampleArray.concat(currentChat[lastAssistantChatIndex].content);
      currentChat.splice(lastAssistantChatIndex, 1);
    }

    const desiredMessage = promptIDMessages?.find(
      (message: { promptMessageOrder: number }) => message.promptMessageOrder === messageOrder
    );
    sethiddenObjValues({
      ...hiddenObjValues,
      sendMessage: true,
      regenerateBtn: true,
      APIError: "",
      tokenError: "",
      // binding: false

    });
    setErrorMsg("")
    isEditMessage = true;
    setIsEditMessage(true)
    getGPTResponse(desiredMessage, null, null, messageID,BLinksList);
  }

  const handleAbort = (): void => {

    if (controllerRef.current) {
        controllerRef.current.abort();
    }
    
    controllerRef.current = new AbortController();

    const abort = setAbort(true);
    // setStreamstop(true)

    hitGPT({
      currentChat,
      AIResponse: null,
      finish_reason: undefined,
      excelURL: undefined,
      llmbedrock: undefined,
      abort:abort,
      parentFunction: undefined,
      solvedParentFunction: undefined,
    });
    setIsEditMessage(true);
    setData("");
};

  const handleD2LinkClick = React.useCallback((e: React.MouseEvent<HTMLAnchorElement>, _res: ResponseContent) => {
    e.preventDefault();
    let diagramType='d2'
    sessionStorage.setItem('d2Code', _res.content);
    const path = `/d2`;
    sessionStorage.setItem('d2Data', JSON.stringify({
      chatId: currentChatID
    }));
    window.open(`/diagram-preview?type=${diagramType}`, '_blank');
  }, [currentChatID]);

  const handleMermaidLinkClick = React.useCallback((e: React.MouseEvent<HTMLAnchorElement>, _res: ResponseContent) => {
    e.preventDefault();
    let diagramType='mermaid'
    sessionStorage.setItem('mermaidCode', _res.content);
    const path = `/mermaid`;
    sessionStorage.setItem('mermaidData', JSON.stringify({
      chatId: currentChatID
    }));
    window.open(`/diagram-preview?type=${diagramType}`, '_blank');
  }, [currentChatID]);

   const handleLinkClick = React.useCallback((e: React.MouseEvent<HTMLAnchorElement>, _res: ResponseContent) => {
       e.preventDefault();
       let diagramType='plantuml'
       
       // Store any necessary data in sessionStorage
       sessionStorage.setItem('plantumlCode', _res.content);
       sessionStorage.setItem('plantumlData', JSON.stringify({
         chatId: currentChatID
       }));
     
       // Open the new route in a new window with diagramType as a query parameter
       window.open(`/diagram-preview?type=${diagramType}`, '_blank');
     }, [currentChatID]);
   
  function decreamentfunc(Chat_index: number): void {
    setincrementval((previncrementval: IncrementVal) => ({
      ...previncrementval,
      [Chat_index]: previncrementval.hasOwnProperty(Chat_index) ? previncrementval[Chat_index] - 1 : 1
    }));
  }
  function incrementfunc(Chat_index: number): void {
    setincrementval((previncrementval: IncrementVal) => ({
      ...previncrementval,
      [Chat_index]: previncrementval.hasOwnProperty(Chat_index) ? previncrementval[Chat_index] + 1 : 1
    }))
  }

  const codeCompare = (Chat_index: number) => (
    <DiffViewer
    oldValue={leftCodeArray[Chat_index]}
    newValue={rightCodeArray[Chat_index]}/>
  );
  



  const pushChat = async (
    currentChat: ChatMessage[],
    roleProvided: 'user' | 'assistant',
    contentProvided: string,
    kbContentGiven?: string,
    webContentGiven?: string,
    BLinksList?:[]
  ): Promise<void> => {
    
    // const updatedChat = [...currentChat];
    
    
    if (roleProvided === "user" && (kbContentGiven || webContentGiven || BLinksList)) {
      const lastUserChatIndex = currentChat.map(chat => chat.role).lastIndexOf("user");
      
      if (lastUserChatIndex !== -1) {
        let additionalContent = "";
    
  
        // Then add KB content if present
        if (kbContentGiven) {
          additionalContent += `\n\nKnowledge Base:\n${kbContentGiven}`;
        }
    
        // Finally add Web content if present
        if (webContentGiven) {
          additionalContent += `\n\nWeb Content:\n${webContentGiven}`;
        }
    
        // Append all additional content to the last user message
        if (additionalContent) {
          currentChat[lastUserChatIndex].content += additionalContent;
        }
      }
    } else {
      currentChat.push({ role: roleProvided, content: contentProvided });
      setHaveLastAssistantMessageCome(roleProvided === "assistant");
    }
    

    // setcurrentChat(updatedChat);
  };

  const handleAddKB = async (): Promise<void> => {
    
    sethiddenObjValues((prevValues) => ({
      ...prevValues,
      loader: true
    }));

    const currentChat_ID = new URLSearchParams(window.location.search).get("chatID");
    setcurrentChatID(currentChat_ID)

    if (currentChat_ID === null) {
      setKbChatName(true);
      await createChat("knowledgeBase");
    }

    sethiddenObjValues((prevValues) => ({
      ...prevValues,
      loader: false
    }));
  };

  // const stopRecording = (): void => {
  //   try {
  //     // setStartVisual(false);
  //     // if (recognitionRef.current) {
  //     //   recognitionRef.current.stop();
  //     // }
  //     setIsRecording(false);
  //   } catch (e) {
  //     const data: {
  //       error_type: string;
  //       error_source: string;
  //       error_description: string;
  //     } = {
  //       error_type: "review component",
  //       error_source: "stop recording",
  //       error_description: e instanceof Error ? e.toString() : String(e)
  //     };
  //   }
  // };
  // const startRecording = async () => {
  //   // try {
  //   //   setVoiceToText('');
  //   //   audioChunksRef.current = [];
  //   //   recognitionRef.current = new (window as any).webkitSpeechRecognition();
  //   //   recognitionRef.current.continuous = true;
  //   //   recognitionRef.current.interimResults = true;

  //   //   recognitionRef.current.start();

  //   //   recognitionRef.current.onresult = (event: any) => {
  //   //     const fullTranscript = Array.from(event.results)
  //   //       .map((result: any) => result[0].transcript)
  //   //       .join('');
  //   //     transcriptRef.current = fullTranscript;
  //   //     setVoiceToText(fullTranscript);
  //   //     setsendMessageValue((prevValue) => {
  //   //       if (fullTranscript === '') {
  //   //         return 'Listening...';
  //   //       } else {
  //   //         return prevValue + fullTranscript;
  //   //       }
  //   //     });
  //   //   };

  //   //   setIsRecording(true);
  //   // } catch (error) {
  //   //   console.error('\n');
  //   //   // Provide feedback to the user if necessary
  //   // }
  // };

  const stopRecognition = () => {
    if (recognitionRef.current) {
      recognitionRef.current.onresult = null; // Detach event listener
      recognitionRef.current.stop();
      recognitionRef.current = null; // Optional: clear the reference
    }
    
  };

  const stopRecording = (): void => {
    try {

      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }
      setIsRecording(false);
    } catch (e: any) {
      const data = {
        error_type: "review component",
        error_source: "stop recording",
        error_description: e.toString()
      };
    }
  };
  
  const startRecording = async () => {
    try {
      setVoiceToText("");
      audioChunksRef.current = [];
      recognitionRef.current = new (window as any).webkitSpeechRecognition() as any;
      recognitionRef.current.continuous = true;
      recognitionRef.current.interimResults = true;
      recognitionRef.current.start();
  
      recognitionRef.current.onresult = (event: any) => {
        const fullTranscript = Array.from(event.results)
          .map((result:any) => result[0].transcript)
          .join('');
        transcriptRef.current = fullTranscript;
        setVoiceToText(fullTranscript);
        setsendMessageValue(fullTranscript);
        if (fullTranscript === "") {
          setsendMessageValue("Listening...");
        } else {
          setsendMessageValue(sendMessageValue + fullTranscript);
        }
      };
  
      setIsRecording(true);
    } catch (error) {
      console.error(error);
      // Provide feedback to the user if necessary
    }
  };
  
  // Ensure recognitionRef.current is not null before accessing its methods
  // if (recognitionRef.current) {
  //   recognitionRef.current.start();
  // }
  // const startRecording = async () => {
  //   try {
  //     setVoiceToText("");
  //     audioChunksRef.current = [];
      
  //     // Stop any existing recognition instance before starting a new one
  //     stopRecognition();
  
  //     recognitionRef.current = new (window as any).webkitSpeechRecognition() as any;
  //     recognitionRef.current.continuous = true;
  //     recognitionRef.current.interimResults = true;
  //     recognitionRef.current.start();
  
  //     recognitionRef.current.onresult = (event: any) => {
  //       const fullTranscript = Array.from(event.results)
  //         .map((result:any) => result[0].transcript)
  //         .join('');
  //       transcriptRef.current = fullTranscript;
  //       setVoiceToText(fullTranscript);
  //       setsendMessageValue(fullTranscript);
        
  //       if (fullTranscript === "") {
  //         setsendMessageValue("Listening...");
  //       } else {
  //         setsendMessageValue(sendMessageValue + fullTranscript);
  //       }
  //     };
  
  //     setIsRecording(true);
  //   } catch (error) {
  //     console.error(error);
  //     // Provide feedback to the user if necessary
  //   }
  // };
  
  // const stopRecording = (): void => {
  //   try {
      
  //     stopRecognition();
  //     setIsRecording(false);
  //   } catch (e: any) {
  //     const data = {
  //       error_type: "review component",
  //       error_source: "stop recording",
  //       error_description: e.toString()
  //     };
  //     // You might want to log this error or send it to a monitoring service
  //   }
  // };
  
  

  const requestGptToken = async (promptIDMessages: any, replaceObj: any,BLinksList?:any) => {

    promptIDMessages.sort((a:any, b:any) => 
      (a.promptMessageOrder || 0) - (b.promptMessageOrder || 0)
    );
    setPromptIDMessages(promptIDMessages)
    setReplaceObj(replaceObj)
    
    try {
      const desiredOrder = messageOrder; // Assuming messageOrder is accessible in this scope
      const desiredMessage = promptIDMessages?.find(
        (message: any) => message.promptMessageOrder === desiredOrder
      );
  
      if (
        messageOrder &&
        currentChat[currentChat.length - 1]?.role == "user"
      ) {
        if (localStorage.getItem("ViewChat") == "True") {
          localStorage.setItem("VIeChat", "False")
        }
        else {
          // Call getGPTResponse with only the desiredMessage, making other parameters optional
          getGPTResponse(desiredMessage, undefined, null, "",BLinksList);
        }
      }
  
      if (
        messageOrder &&
        hiddenObjValues.inputFix != "Fix your response here" &&
        (currentChat[currentChat?.length - 1]?.role != "user" ||
          currentChat.length == 0)
      ) {
        getPromptMessage(promptIDMessages, replaceObj, "",BLinksList);
      }
      
      // if (!messageOrder) {
      //   // Call getGPTResponse with only the desiredMessage, making other parameters optional
      //   getGPTResponse(desiredMessage, undefined, null, "",BLinksList);
      // }
  
    } catch (error) {
      console.error("Logging error", error);
    }
  };

  const generateNewPromptForAIHit = async (inputKey: string, replaceObj: any, messageID: string) => {
    const inputName = inputKey.replace(/[()]/g, '');
    try {
      
      const req_body = {
        messageID: messageID,
        userInput: { [inputName]: replaceObj[inputName] }
      };
      const response = await generateNewPrompt(req_body);
      if (response && response.finalPrompt) {
        return response.finalPrompt;
      } else {
        console.error('Unexpected response:', response);
        return replaceObj[inputName] || inputKey;
      }
    } catch (error) {
      console.error('Error in generateNewPrompt:', error);
      return replaceObj[inputName] || inputKey;
    }
  };


  const getPromptMessage = async (promptIDMessages: any, replaceObj: any, promptDatas:any ,BLinksList?:[]) => {
    if( messageOrder == 1){
        edit = false
        edit = edit
        setEdit(false)
    }
    let PromptDataToBeUsed = promptDatas?.promptData?.promptFields?.length > 0 ? promptDatas : promptData
    systemPrompt = PromptDataToBeUsed?.promptData?.systemMessage || PromptDataToBeUsed?.promptData?.enhancedSystemMessage || systemPrompt || ""
    setSystemPrompt(PromptDataToBeUsed?.promptData?.systemMessage || PromptDataToBeUsed?.promptData?.enhancedSystemMessage || systemPrompt || "")
    if(PromptDataToBeUsed?.promptData?.kbUrl?.length!=0){
      const newAskAsamiKbData  = {
        chatID: new URLSearchParams(window.location.search).get("chatID"),
        collectionName: PromptDataToBeUsed?.promptData?.collectionName,
        indexName:PromptDataToBeUsed?.promptData?.collectionName,
        kbFiles: PromptDataToBeUsed?.promptData?.kbFiles, //[{}, {}]
        kbUrl:PromptDataToBeUsed?.promptData?.kbUrl, //[''.'']
        isKbRetriever: PromptDataToBeUsed?.promptData?.isKbRetriever, //true
      };
      setAskAsamiKbData(newAskAsamiKbData);
      sessionStorage.setItem('askAsamiKbData', JSON.stringify(newAskAsamiKbData))
    }
    promptIDMessages.sort((a:any, b:any) => 
      (a.promptMessageOrder || 0) - (b.promptMessageOrder || 0)
    );
    setPromptIDMessages(promptIDMessages)
    setReplaceObj(replaceObj)
    let hello = replaceObj;
    if (promptIDMessages.length == messageOrder) {
      for (const val of promptIDMessages) {
        if (val.promptMessageOrder == messageOrder) {
          if (val.inputValue && val.inputKey && val.conditionRefCode) {
            if (checkCondition(val, replaceObj)) {
              const desiredMessage = promptIDMessages.find(
                (message: any) => message.promptMessageOrder === messageOrder
              );
              let message = val.promptMessage;
  
              let a = val;
              let pMessageId = val.promptMessageID;
              let pFieldJs = replaceObj;
              let req_body = {
                "messageID": val.promptMessageID,
                "userInput": replaceObj
              };
              let proceedWithAI = 0;
              const inputKeys = message.match(/\(input-\d+\)/g);
        
              if (inputKeys) {
                for (const inputKey of inputKeys) {
                  const inputName = inputKey.replace(/[()]/g, '');
                  const correspondingField = promptData?.promptData?.promptFields.find(
                    (field: any) => field.inputValueOrder === inputName
                  );
                  if (correspondingField && correspondingField.ai_hit == 1) {
                    proceedWithAI = 1;
                    const newPrompt = await generateNewPromptForAIHit(inputKey, replaceObj, val.promptMessageID);
                    message = message.replace(inputKey, newPrompt);
                  }
                }
              }
              setRegen(false);
  
              let formattedResponse = "";
              let webresultfromprompt = "";
  
              if (val.webContent !== "" && val.webContent !== null) {
                let response = await serpAPI({ query: val.webContent });
                formattedResponse = `
                  $$$
                  ${val.webContent}
                  
                  As an AI assistant, please provide a clear and concise answer to the query above. Base your response on the following information, but present it as if you already possess this knowledge without referencing any specific sources or contexts:
                  
                  ${response.data.output.google_result[0]}
                  
                  Synthesize this information into a coherent response that directly addresses the query. Ensure your answer is factual, informative, and to the point. If the query requires a more detailed explanation, offer to provide additional information if needed.`;
  
                webresultfromprompt = response.data.output.google_result[0];
              }
              const replacedString = message.replaceAll(
                /\((input-\d+)\)/g,
                (match: any, key: any) => {
                  return replaceObj[key] || match;
                }
              );
  
              const finalString = replacedString + formattedResponse;
  
              setIsKbRetriever(val?.isKbRetriever);
              setKbQuery(replacedString);
              setlastArrayObj({
                role: "user",
                content: replacedString,
              });
  
              pushChat(currentChat, "user", finalString);
  
              if (webresultfromprompt != "") {
                pushChat(currentChat, "user", "", "", webresultfromprompt);
              }
  
              postMessage(
                {
                  role: "user",
                  content: replacedString,
                },
                null,
                desiredMessage,
                webresultfromprompt,
                undefined,
                undefined,
                undefined,
                BLinksList
              );
            } else {
              
              sethiddenObjValues({
                ...hiddenObjValues,
                sendMessage: false,
                responseBtn: true,
              });
            }
          } else {
            let message = val.promptMessage;
            const inputKeys = message.match(/\(input-\d+\)/g);
        
            if (inputKeys) {
              for (const inputKey of inputKeys) {
                const inputName = inputKey.replace(/[()]/g, '');
                const correspondingField = promptData?.promptData?.promptFields.find(
                  (field: any) => field.inputValueOrder === inputName
                );
                if (correspondingField && correspondingField.ai_hit == 1) {
                  let newPrompt = await generateNewPromptForAIHit(inputKey, replaceObj, val.promptMessageID);
                  message = message.replace(inputKey, newPrompt);
                }
              }
            }
            setRegen(false);
  
            let formattedResponse = "";
            let webresultfromprompt = "";
  
            if (val.webContent !== "" && val.webContent !== null) {
              let response = await serpAPI({ query: val.webContent });
              formattedResponse = `
                $$$
                ${val.webContent}
                
                As an AI assistant, please provide a clear and concise answer to the query above. Base your response on the following information, but present it as if you already possess this knowledge without referencing any specific sources or contexts:
                
                ${response.data.output.google_result[0]}
                
                Synthesize this information into a coherent response that directly addresses the query. Ensure your answer is factual, informative, and to the point. If the query requires a more detailed explanation, offer to provide additional information if needed.`;
  
              webresultfromprompt = response.data.output.google_result[0];
            }
  
            const desiredMessage = promptIDMessages.find(
              (message: any) => message.promptMessageOrder === messageOrder
            );
            const replacedString = message.replaceAll(
              /\((input-\d+)\)/g,
              (match: any, key: any) => {
                return replaceObj[key] || match;
              }
            );
            const finalString = replacedString + formattedResponse;
            setKbQuery(replacedString);
            setIsKbRetriever(val?.isKbRetriever);
            setlastArrayObj({
              role: "user",
              content: replacedString,
            });
  
            pushChat(currentChat, "user", finalString);
  
            if (webresultfromprompt != "") {
              pushChat(currentChat, "user", "", "", webresultfromprompt);
            }
  
            postMessage(
              {
                role: "user",
                content: replacedString,
              },
              null,
              desiredMessage,
              webresultfromprompt,
              undefined,
              undefined,
              undefined,
              BLinksList
            );
          }
        }
      }
      setMessageOrder(0);
      messageOrder = 0;
      messageOrder = messageOrder;
      sethiddenObjValues((pre: any) => ({
        ...pre,
        sendMessage:true,
        responseBtn:false
      }));
    } 
    else if (promptIDMessages.length > messageOrder) {
      for (const val of promptIDMessages) {
        if (val.promptMessageOrder == messageOrder) {
          if (val.inputValue && val.inputKey && val.conditionRefCode) {
            if (checkCondition(val, replaceObj)) {
              const desiredMessage = promptIDMessages.find(
                (message: any) => message.promptMessageOrder === messageOrder
              );
              let message = val.promptMessage;
              const inputKeys = message.match(/\(input-\d+\)/g);
        
              if (inputKeys) {
                for (const inputKey of inputKeys) {
                  const inputName = inputKey.replace(/[()]/g, '');
                  const correspondingField = promptData?.promptData?.promptFields.find(
                    (field: any) => field.inputValueOrder === inputName
                  );
                  if (correspondingField && correspondingField.ai_hit == 1) {
                    const newPrompt = await generateNewPromptForAIHit(inputKey, replaceObj, val.promptMessageID);
                    message = message.replace(inputKey, newPrompt);
                  }
                }
              }
              setRegen(false);
  
              let formattedResponse = "";
              let webresultfromprompt = "";
  
              if (val.webContent !== "" && val.webContent !== null) {
                let response = await serpAPI({ query: val.webContent });
                formattedResponse = `
                  $$$
                  ${val.webContent}
                  
                  As an AI assistant, please provide a clear and concise answer to the query above. Base your response on the following information, but present it as if you already possess this knowledge without referencing any specific sources or contexts:
                  
                  ${response.data.output.google_result[0]}
                  
                  Synthesize this information into a coherent response that directly addresses the query. Ensure your answer is factual, informative, and to the point. If the query requires a more detailed explanation, offer to provide additional information if needed.`;
  
                webresultfromprompt = response.data.output.google_result[0];
              }
              const replacedString = message.replaceAll(
                /\((input-\d+)\)/g,
                (match: any, key: any) => {
                  return replaceObj[key] || match;
                }
              );
              const finalString = replacedString + formattedResponse;
              setKbQuery(replacedString);
              setIsKbRetriever(val?.isKbRetriever);
              setlastArrayObj({
                role: "user",
                content: replacedString,
              });
              pushChat(currentChat, "user", finalString);
  
              if (webresultfromprompt != "") {
                pushChat(currentChat, "user", "", "", webresultfromprompt);
              }
  
              postMessage(
                {
                  role: "user",
                  content: replacedString,
                },
                null,
                desiredMessage,
                webresultfromprompt,
                undefined,
                undefined,
                undefined,
                BLinksList

              );
            } else {
              setMessageOrder(prev => prev + 1)
              messageOrder += 1;
              messageOrder = messageOrder;
              if (messageOrder > promptIDMessages.length){
                setMessageOrder(0)
                setIsPrompt(false)
                return
              }
 
            }
          } else {
            let message = val.promptMessage;
            const inputKeys = message.match(/\(input-\d+\)/g);
        
            if (inputKeys) {
              for (const inputKey of inputKeys) {
                const inputName = inputKey.replace(/[()]/g, '');
                const correspondingField = promptData?.promptData?.promptFields.find(
                  (field: any) => field.inputValueOrder === inputName
                );
                if (correspondingField && correspondingField.ai_hit == 1) {
                  const newPrompt = await generateNewPromptForAIHit(inputKey, replaceObj, val.promptMessageID);
                  message = message.replace(inputKey, newPrompt);
                }
              }
            }
            setRegen(false);
            
            const desiredMessage = promptIDMessages.find(
              (message: any) => message.promptMessageOrder === messageOrder
            );
  
            let formattedResponse = "";
            let webresultfromprompt = "";
  
            if (val.webContent !== "" && val.webContent !== null) {
              let response = await serpAPI({ query: val.webContent });
              formattedResponse = `
                $$$
                ${val.webContent}
                
                As an AI assistant, please provide a clear and concise answer to the query above. Base your response on the following information, but present it as if you already possess this knowledge without referencing any specific sources or contexts:
                
                ${response.data.output.google_result[0]}
                
                Synthesize this information into a coherent response that directly addresses the query. Ensure your answer is factual, informative, and to the point. If the query requires a more detailed explanation, offer to provide additional information if needed.`;
  
              webresultfromprompt = response.data.output.google_result[0];
            }
  
            const replacedString = message.replaceAll(
              /\((input-\d+)\)/g,
              (match: any, key: any) => {
                return replaceObj[key] || match;
              }
            );
            const finalString = replacedString + formattedResponse;
            setKbQuery(replacedString);
            setIsKbRetriever(val?.isKbRetriever);
            setlastArrayObj({
              role: "user",
              content: replacedString,
            });
            pushChat(currentChat, "user", finalString);
  
            if (webresultfromprompt != "") {
              pushChat(currentChat, "user", "", "", webresultfromprompt);
            }
  
            postMessage(
              {
                role: "user",
                content: replacedString,
              },
              null,
              desiredMessage,
              webresultfromprompt,
              undefined,
              undefined,
              undefined,
              BLinksList

            );
          }
        }
      }
    } else {

      if (messageOrder > promptIDMessages.length) {
        setMessageOrder(0)
        setIsPrompt(false)
        return
      }
      
      sethiddenObjValues((pre: any) => ({
        ...pre,
        success: false,
        sendMessage: false,
      }));
    }
  };


  const checkCondition = (promptIDMessage:any, replaceObj:any) => {
    
    let key = promptIDMessage.inputValue;
    let keysArray = Object.values(replaceObj);
    let result = true;

    if (promptIDMessage.conditionRefCode == "INC") {
      if (
        key.includes(replaceObj[promptIDMessage.inputKey]) &&
        promptIDMessage.inputValue == replaceObj[promptIDMessage.inputKey]
      ) {
        result = true;
      } else {
        result = false;
      }
    } else if ((promptIDMessage.conditionRefCode == "NINC")) {
      if (!key.includes(replaceObj[promptIDMessage.inputKey]) &&
        promptIDMessage.inputValue != replaceObj[promptIDMessage.inputKey]
      ) {
        result = true;
      } else {
        result = false;
      }
    } else if (promptIDMessage.conditionRefCode == "EQ") {
      if (
        // key.includes(replaceObj[promptIDMessage.inputKey]) &&
        promptIDMessage.inputValue == replaceObj[promptIDMessage.inputKey]
      ) {
        result = true;
      } else {
        result = false;
      }
    } else if (promptIDMessage.conditionRefCode) {
      if (
        // key.includes(replaceObj[promptIDMessage.inputKey]) &&
        promptIDMessage.inputValue !== replaceObj[promptIDMessage.inputKey]
      ) {
        result = true;
      } else {
        result = false;
      }
    }

    return result;
  };
  
  const createChat = async (askAsami: string, payload?: any): Promise<number> => {
    if (userData?.role !== "User" && userData?.role !== "Lead" && userData?.role !== "Leadership") {
      setStartConversation(false);
      sessionStorage.removeItem('issueState');
    }
  
    let approverEmail = "";
  
    try {
      const approverResponse = await axios.get(
        `https://graph.microsoft.com/v1.0/users/${userData?.email}/manager`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      approverEmail = approverResponse?.data?.mail || "";
    } catch (error) {
      console.error("Error fetching manager data:", error);
    }
  
    try {
      
      
      const defaultPayload = {
        projectName: projectNameFromLocal || "",
        approverEmailID: approverEmail,
        chatType: "General",
        userEmailID: userData?.email,
        userName: userData?.userName,
        promptFormJson: { "Chat Name": askAsami },
        role: "user",
        codegenStatus: "ip",
        bLinks: []
      };

  
      // Use the provided payload if it exists, otherwise use the default
      const finalPayload = payload || defaultPayload;
  
      const result = await chatCreation(finalPayload);
  
      setChatCreated(true);
      navigate(`/chat?chatID=${result.responseData.ResponseData}`);
      return 200;
    } catch (error) {
      console.error("Error inserting message:,6666666666", error);
      return 500;
    }
  };

  const handleVoiceButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };
  
  const hitGPT = ({
    currentChat,
    AIResponse,
    finish_reason,
    excelURL,
    llmbedrock,
    abort,
    parentFunction,
    solvedParentFunction,
    BLinksList
  }: HitGPTParams): ChatMessage[] => {

    sessionStorage.setItem('isStreaming', "false")  
    sessionStorage.setItem('toasterDisplay', 'false')
    if (messageOrder == 0){
      setPromptIDMessages([])
    }
    
    const updateHiddenObjValues = (newValues: Partial<HiddenObjValues>): void => {
      sethiddenObjValues(prevValues => ({ ...prevValues, ...newValues }));
    };

    const commonUpdates: Partial<HiddenObjValues> = {
      sendMessage: true,
      inputFix: "Type / to choose options",
      responseBtn: false,
    };

    updateHiddenObjValues(commonUpdates);
    let msg = "Your Max response limit is reached";
    if (abort) {
      updateHiddenObjValues({
        regenerateBtn: false,
        tokenError: "",
      });
      setErrorMsg("")
      pushChat(currentChat, "assistant", AIResponse);
      postMessage(
        {
          role: "assistant",
          content: AIResponse,
        },
        finish_reason,
        null,
        null,
        excelURL,
        parentFunction,
        solvedParentFunction,
        BLinksList
      );
      setData("");
      setIsEditMessage(false);
      return currentChat;
    }

    if (currentChat.length > 0) {
      switch (finish_reason) {
        case "stop":
          
          sethiddenObjValues({
            ...hiddenObjValues,
            regenerateBtn: false,
            tokenError: "",
            sendMessage:
              hiddenObjValues.inputFix === "Fix your response here"
                ? true
                : hiddenObjValues.sendMessage,
            inputFix: "Type / to choose options",
            responseBtn: false,
          });
          setErrorMsg("")
          pushChat(currentChat, "assistant", AIResponse);
          postMessage(
            {
              isEditMessage: isEditMessage,
              role: "assistant",
              content: AIResponse,
              cost: llmbedrock
            },
            finish_reason,
            null,
            null,
            excelURL,
            parentFunction,
            solvedParentFunction,
            BLinksList
          );
          setData("");
          setIsEditMessage(false);
          setPopMsg(true);
          setRegen(true);
          if (isAskAsami || messageOrder === 0) {
            sethiddenObjValues({
              ...hiddenObjValues,
              sendMessage: true,
            });
          }
          break;
    
        case "length":
          sethiddenObjValues({
            ...hiddenObjValues,
            sendMessage:
              hiddenObjValues.inputFix === "Fix your response here"
                ? true
                : hiddenObjValues.sendMessage,
                tokenError: msg
          });
          setData("");
          setErrorMsg(msg)
          break; 
        case "content_filter":
          sethiddenObjValues({
            ...hiddenObjValues,
            sendMessage:
              hiddenObjValues.inputFix === "Fix your response here"
                ? true
                : hiddenObjValues.sendMessage,
                tokenError: "Something went wrong. Try New Chat"
              });
              setData("");
              setErrorMsg("Something went wrong. Try New Chat")
              break;
        case "error":
          sethiddenObjValues({
            ...hiddenObjValues,
            sendMessage:
              hiddenObjValues.inputFix === "Fix your response here"
                ? true
                : hiddenObjValues.sendMessage,
            tokenError: "Something went wrong. Try new chat"
          });
          setData("");
          setErrorMsg("Something went wrong. Try New Chat")
          break;
    
        case null:
          sethiddenObjValues({
            ...hiddenObjValues,
            sendMessage:
              hiddenObjValues.inputFix === "Fix your response here"
                ? true
                : hiddenObjValues.sendMessage,
            tokenError: "Something went wrong. Try New Chat"
          });
          setData("");
          setErrorMsg("Something went wrong. Try New Chat")
          break;
      }
    } else {
      updateHiddenObjValues({
        tokenError: "ran out of tokens try again after sometimes",
      });
      setData("");
      setErrorMsg("ran out of tokens try again after sometimes")
    }

    return currentChat;
  };
/////////////////////////////////////////////////////////////////////////////////////////////
  const chatNameInsertion =  async (askAsami: string) => {
    
    try {
      const currentChat_ID = new URLSearchParams(window.location.search).get(
        "chatID"
      );

      let result = await chatCreation({
        chatID: currentChat_ID,
        asamiChatName: askAsami,
        bLinks: []
      });
      
    }
    catch (error) {
      console.error('\n');
    }
  };
  const getGPTResponse = async (
    desiredMessage: any,
    AIResponse: any,
    promptMessageOrder: number | null,
    MessageID: string | undefined,
    BLinksList?:any
  ) => {
    
   
    // setLoading(true);
    setLoadingIndicator(true);

    // if(collectionName || askAsamiKbRetriever){
    //   setIsKbRetrieverActive(true)
    // }
    
    setFileURLs(prevState => ({
      ...prevState,
      [(currentChat.length)-1]: BLinksList
    }));
    let isKbRtrieverVar = JSON.parse(sessionStorage.getItem('askAsamiKbData') as any)
    if(!isKbRtrieverVar.collectionName  || !isKbRtrieverVar.chatID ){
      isKbRtrieverVar.isKbRetriever = false
    }
    let kbCheck = desiredMessage ? desiredMessage?.isKbRetriever : isKbRtrieverVar.isKbRetriever;
  
    try {
      
  
      if (isKGBuild) {
        const obj = {
          question: sendMessageValue,
          projectName: projectName_projects,
          collectionName: collectionName_projects,
          workItem: workItemFromMI,
          module: sessionStorage.getItem('isModule') || 'tasks', 
          currentChat : currentChat
        };
        const response = await similarityCheck(obj);
        const content = response?.data?.Response;
  
        if (isModule !== 'tasks') {
           setLeftCodeArray(prevState => ({
            ...prevState,
            [currentChat.length -1 ]: response?.data?.parentFunction
          }));
          setRightCodeArray(prevState => ({
            ...prevState,
            [currentChat.length -1 ]: response?.data?.solvedParentFunction
          }));
  
          hitGPT({
            currentChat,
            AIResponse: content,
            finish_reason: "stop",
            excelURL: undefined,
            llmbedrock: undefined,
            parentFunction: response?.data?.parentFunction,
            solvedParentFunction: response?.data?.solvedParentFunction,
            BLinksList:BLinksList
          });
        } else {
          hitGPT({
            currentChat,
            AIResponse: content,
            finish_reason: "stop",
            BLinksList:BLinksList
          });
        }
  
        setLoadingIndicator(false);
        return;
      }
  
      if (!bURL.endsWith(".mp4") && !isKGBuild) {
        let modelConfigurationID = desiredMessage?.modelConfigurationID || modelID || config.selectedModelID ||  "";
  
        if (config.selectedModelID) {
          modelConfigurationID = config.selectedModelID;
        }
        
        const access_token = getSessionItem("access-token");
        
        if (modelConfigurationID === "") {
          modelConfigurationID = defaultModelFromEnv;
        }
        
        let req: any = {
          modelConfigurationID: desiredMessage?.modelConfigurationID ? desiredMessage?.modelConfigurationID : config.selectedModelID,
          messages: currentChat,
          "systemPrompt": systemPrompt,
          HyperConfig: desiredMessage?.modelConfigurationID ? {} :   {
            maxResponse: config.maxResponse,
            temperature: config.temperature,
            frequencyPenalty: config.frequencyPenalty,
            presencePenalty: config.presencePenalty,
            topP: config.topP
          },
          messageID:MessageID,
          userEmailID: userData?.email,
        };

  
        if (bURL) {
          req = { 
            modelConfigurationID: desiredMessage?.modelConfigurationID ? desiredMessage?.modelConfigurationID : config.selectedModelID,
            messages: currentChat, 
            HyperConfig: {}, 
            url: bURL, 
            query: sendMessageValue ,
            messageID:MessageID

          };
        }
        let collection_Name = JSON.parse(sessionStorage.getItem('askAsamiKbData') as any) 
        const allowKB = ((kbCheck == true ||((collection_Name.collectionName ? true: false) && (promptMessageOrder == 0 && isKbRetrieverActive))))
        if (allowKB) {
          
          
          const lastUserChatIndex = currentChat.map(chat => chat.role).lastIndexOf("user");
          let message:any=''

          if(AIResponse){
            message = AIResponse.content

          }
          else{
            message= currentChat[lastUserChatIndex]
          }
          let obj:any = ""
          if (bURL) {
            obj = {
              "url": bURL,
              "query": message.content,
              "collection_name": collection_Name.collectionName,
              "index_name":  collection_Name.collectionName,
              "messages": currentChat,
              "messageID":MessageID
            };
          } else {
            obj = {
              "query": message.content,
              "collection_name": collection_Name.collectionName,
              "index_name":  collection_Name.collectionName,
              "messages": currentChat,
              "messageID":MessageID
            };
          }
  
          const response = await KBRetriever(obj);
  
          pushChat(currentChat, "user", "", response?.data?.similaritySearchResult ? response?.data?.similaritySearchResult: "No KB Data Found!");
          
          await insertMessage({
            chatID: currentChatID,
            isEditMessage: isEditMessage,
            messageID: MessageID,
            kbContent: response?.data?.similaritySearchResult
          });
          if (currentChat.length==1 && !(messageOrder >=1)){
            const result = await getChatName(sendMessageValue);

          }
          

          let HyperConfig= { 
            maxResponse: config.maxResponse, 
            temperature: config.temperature, 
            frequencyPenalty: config.frequencyPenalty, 
            presencePenalty: config.presencePenalty, 
            topP: config.topP 
          }
          const messageIDInd = currentChat.map(chat => chat.role).lastIndexOf("user");

            // Create the req object
            req = {
              modelConfigurationID: desiredMessage?.modelConfigurationID ? desiredMessage?.modelConfigurationID : config.selectedModelID,
              "systemPrompt": systemPrompt,
              messages: currentChat.map((msg, index) =>
                index === messageIDInd
                  ? { ...msg, content: AIResponse? AIResponse.content : currentChat[lastUserChatIndex].content}
                  : msg
              ),
              HyperConfig:desiredMessage?.modelConfigurationID ? {} :  HyperConfig,
              query: AIResponse? AIResponse.content : currentChat[lastUserChatIndex].content,
              collection_name: collection_Name.collectionName,
              messageID:MessageID ? MessageID : newMessageID
            };
        }
  
        // const controller = new AbortController();
        const controller = controllerRef.current
        const signal = controller.signal;
  
        const updatedList = req.messages.map((item: any) => {
          if (item.role === "assistant" && Array.isArray(item.content)) {
            return {
              ...item,
              content: item.content[item.content.length - 1]
            };
          }
          return item;
        });
        req.messages = updatedList;
  
        const options = {
          method: 'POST',
          headers: {
            access_token: access_token,
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(req),
          signal
        };

        setIsStreaming(true)
        const response = await fetch(UrlsAi.stream_loadBalancer, options);
        setIsStreaming(false)
        if (response.status !== 200) {
          setLoadingIndicator(false);
          setData("");
          sethiddenObjValues({
            ...hiddenObjValues,
            sendMessage: hiddenObjValues.inputFix === "Fix your response here" ? true : hiddenObjValues.sendMessage,
            tokenError: "Something went wrong, please do try again later",
          });
          setErrorMsg("Something went wrong, please do try again later")
          return;
        } 
  
        sethiddenObjValues({
          ...hiddenObjValues,
          tokenError: "",
        });
        setErrorMsg("")
        if( messageOrder == 0){
          setIsPrompt(false);
        }
  
        setLoadingIndicator(false);
        setData("");
  
        const reader = response.body!.getReader();
        let result = '';
        let llmbedrock = "";
        let fullResponse = '';
  
        while (true) {
          const { done, value } = await reader.read();
          sessionStorage.setItem('isStreaming', "true")
          if(!value){
            hitGPT({
              currentChat,
              AIResponse: result,
              finish_reason: "error",
              BLinksList:BLinksList
            });
            break;
          }
  
          if (done) {
            // When streaming is complete, call hitGPT with the full response
            
            hitGPT({
              currentChat,
              AIResponse: fullResponse,
              finish_reason: "stop",
              excelURL: BLinksList[0],
              llmbedrock,
              BLinksList:BLinksList
            });
            break;
          }
  
          let ai_data = new TextDecoder().decode(value);
          fullResponse += ai_data;
  
          const searchString = "ASA$MIcODE";
const searchString2 = "modelVal~~BedRock";
const searchString3 = "~~~~";
const searchString4 = "~~~~~";

if (ai_data.includes(searchString4)) {
  llmbedrock = ai_data.split(searchString4)[1];
  ai_data = ai_data.split(searchString4)[0];
}

if (ai_data.includes("ErrorLo@db@l@ncer")) {
  hitGPT({
    currentChat,
    AIResponse: result,
    finish_reason: "error",
    BLinksList:BLinksList
  });
  break;
}

if (ai_data.includes( "context_length_exceeded")) {
  hitGPT({
    currentChat,
    AIResponse: result,
    finish_reason: "content_filter",
    BLinksList:BLinksList
  });
  break;
}

if (ai_data !== searchString && !ai_data.endsWith(searchString) && !ai_data.endsWith(searchString2)) {
  result += ai_data;
} else if (ai_data?.startsWith(searchString)) {
  result += ai_data?.slice(searchString.length);
} else if (ai_data?.endsWith(searchString2)) {
  if (ai_data?.includes(searchString3)) {
    result += ai_data?.split('~~~~')[0];
  }
 
  llmbedrock = ai_data?.split('~~~~')[1].slice(0, -searchString2.length);
} else if (ai_data?.endsWith(searchString)) {
  result += ai_data?.slice(0, -searchString.length);
}

setData(() => result);
setRegen(true)

if (ai_data === "ASA$MIcODE" || ai_data.includes("ASA$MIcODE") || ai_data.endsWith(searchString2)) {
  const regex = /\|---/;
  setBUrl("");
  const hasPseudocode = regex.test(result);

  if (hasPseudocode) {
    const obj = {
      modelConfigurationID: config.selectedModelID,
      messages: [{
        role: "user",
        content: result
      }]
    };
    const excelURL = await DownloadAsExcel(obj);

    setExcelURLs(prevState => ({
      ...prevState,
      [currentChat.length]: excelURL?.data?.S3URL
    }));

    if (sampleArray.length !== 0) {
      sampleArray.push(result);
      hitGPT({
        currentChat,
        AIResponse: sampleArray,
        finish_reason: "stop",
        excelURL: excelURL?.data?.S3URL,
        llmbedrock,
        BLinksList:BLinksList
      });
    } else {
      hitGPT({
        currentChat,
        AIResponse: result,
        finish_reason: "stop",
        excelURL: excelURL?.data?.S3URL,
        llmbedrock,
        BLinksList:BLinksList
      });
    }
    break;
  } else {
    const codeArray = result.split("```").map((code, index) => {
      return index % 2 !== 0 ? { type: "code", content: code } : null;
    }).filter(Boolean);


    if (sampleArray.length !== 0) {
      sampleArray.push(result);
      hitGPT({
        currentChat,
        AIResponse: sampleArray,
        finish_reason: "stop",
        excelURL: undefined,
        llmbedrock,
        BLinksList:BLinksList
      });
    } else {
      hitGPT({
        currentChat,
        AIResponse: result,
        finish_reason: "stop",
        excelURL: undefined,
        llmbedrock,
        BLinksList:BLinksList
      });
    }
    break;
  }
}

if (ai_data === "L3n&th-" || ai_data.includes("L3n&th-")) {
  hitGPT({
    currentChat,
    AIResponse: result,
    finish_reason: "length",
    excelURL: undefined,
    llmbedrock,
    BLinksList:BLinksList
  });
  break;
}
        }
  
      }
    } catch (error: any) {
      console.error(error);
      setLoadingIndicator(false);
      setData("");
      
      sethiddenObjValues({
        ...hiddenObjValues,
        sendMessage: hiddenObjValues.inputFix === "Fix your response here" ? true : hiddenObjValues.sendMessage,
        tokenError: "",
      });
      setErrorMsg("")
      try {
        const errorResult = await errorLogs({
          errorMessage: error.message,
          userEmailID: userData?.email,
          errorOccured: "frontEnd",
          functionName: "getGPTResponse",
        });
  
        if (errorResult.Success) {
          setLoadingIndicator(false);
          sethiddenObjValues({
            ...hiddenObjValues,
            sendMessage: hiddenObjValues.inputFix === "Fix your response here" ? true : hiddenObjValues.sendMessage
          });
        } else {
          sethiddenObjValues({
            ...hiddenObjValues,
            sendMessage: hiddenObjValues.inputFix === "Fix your response here" ? true : hiddenObjValues.sendMessage,
            tokenError: "Your token got expired try after few minutes",
          });
          setErrorMsg("Your token got expired try after few minute")
        }
      } catch (logError) {
        console.error("Error logging failed", logError);
      }
    }
  };

  interface SerpAPIResponse {
    data: {
      output: {
        google_result: string[];
      };
    };
  }


  interface ChatResponseData {
    currentTxt: Array<{ createdDateTime: string }>;
    kbRetriever?: Array<{
      chatType: string;
      isKbRetriever: boolean;
      collectionName: string;
      indexName: string;
      kbFiles: string;
      kbUrl: string;
    }>;
    bLinks?:any;
    JSON: any;
    currentChat: ChatMessage[];
    promptIDMessages: Array<{ promptMessageOrder: number }>;
    currentPromptMessageOrder: Array<{ promptMessageOrder: number }>;
    promptFormIDJson: any;
    kbContents: any;
    webContents: any;
    excelURLs: any;
    existingParentFunction: Record<number, string>;
    solvedParentFunction: Record<number, string>;
    approverName?: string;
    promptName?: string;
    approvalStatus?: string;
  }
  
  // Define ApiResponse interface
  interface ApiResponse {
    ResponseData: ChatResponseData;
    // You can add other properties if the response contains more information
  }

  type AskAsamiKbData = {
    chatID: string | null;
    collectionName: string | undefined | null;
    indexName: string | undefined | null;
    kbFiles: string;
    kbUrl: string;
    isKbRetriever: boolean | undefined;
  };

  const setDate = () => {
    const currentUtcDate = new Date(); // Get the current UTC date
    const utcDate = new Date(UtcDateTime);
    const istDate = new Date(utcDate.getTime()); // Adding 5.5 hours for the IST offset
    const formattedDate = istDate.toDateString();
 
    let displayDate;
    if (
      currentUtcDate.getUTCFullYear() === utcDate.getUTCFullYear() &&
      currentUtcDate.getUTCMonth() === utcDate.getUTCMonth() &&
      currentUtcDate.getUTCDate() === utcDate.getUTCDate()
    ) {
      displayDate = "Today";
    } else if (
      currentUtcDate.getUTCFullYear() === utcDate.getUTCFullYear() &&
      currentUtcDate.getUTCMonth() === utcDate.getUTCMonth() &&
      currentUtcDate.getUTCDate() === utcDate.getUTCDate() + 1
    ) {
      displayDate = "Yesterday";
    } else {
      displayDate = formattedDate;
    }
 
    setFormattedDateTime(displayDate);
  };
 

  const getPromptMsgOrder = async (newChat:any) => {
    // let result = await fetchChat({ chatID: newChat });
    //
  
    
  }

  async function getUsername(newChat:any) {
    try {

      let result = await fetchChat({ ExistchatID: newChat });
      let data = result.data.userEmailID
      return data
    }
    catch (e) {
      console.log(e,"error in getting username")
    }
  }
 

  function timeout(ms:any) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const getValyriaChatName = async (firstContent:string): Promise<string> => {
    try {
      let askAsami =''

      // Make the API call
      const result = await LoadBalancer({
        modelConfigurationID: '',
        messages: [
          {
            role: 'user',
            content: `Analyze this input: "${firstContent}".
    Task: Generate a single, descriptive word to serve as a chat name.
    This word should encapsulate the essence or main topic of the input.
    It must be ONE WORD ONLY, with no additional explanation.
    If the input is code or technical, choose a relevant technical term.
    Respond with just this one word, nothing else.`,
          },
        ],
      });

      let askAsamiResponse = result?.data;
      if (askAsamiResponse) {
        askAsami = askAsamiResponse.ResponseData.response?.trim() || '';

        // First, remove any truncated part (ending with ...)
        askAsami = askAsami.replace(/\s*\.{3,}$/, '').trim();

        // Then, remove any trailing dot
        askAsami = askAsami.replace(/\.$/, '');
      } else {
        askAsami = 'Untitled Project';
      }
      return askAsami;
    } catch (error) {
      console.error('\n');
      throw error;
    }
};

  // const fetchValyiraChat = async (newChat:string) => {

  // }

  
  const getChatMessages = async (newChat:string) => {
    sethiddenObjValues({
      ...hiddenObjValues,
      // binding: false,
    });

    try {
      
      // setIsLoad(true)
      let type = sessionStorage.getItem('type')
      
      let id = localStorage.getItem('valyriaId')
      if(type == id){
        let payload={
          "asami_chat_id" : newChat
        }
        setIsLoading(true)
        let response = await getValyriaChat(payload);
        if(response?.code_gen_status !== "Completed"){
          setLoadingIndicator(true);
          setIsPrompt(true)
          setCodeGenStatus(response?.code_gen_status)
        }else{
          setCodeGenStatus(response?.code_gen_status)
          setLoadingIndicator(false)
          setIsPrompt(false)
        }
        setIsLoading(false)
        setcurrentChat(response.data)
        const blinks = response.data[0].blinks;
        setFileURLs([blinks]);
        setValyriaCurrentChat(response.data)
        setUtcDateTime(response.data[0]?.datetime);
        sessionStorage.setItem('user_id',response.data[0].user_id)
        sessionStorage.setItem('project_id',response.data[0].project_id) 
      }
      else  
      {
        setIsLoading(true)
        let response = await fetchChat({ chatID: newChat });
        setIsLoading(false)
        
        let result= response.data

        const lengthofkey = (Object.keys(result.ResponseData).length)
        if(result.ResponseData.kbRetriever.kbFiles!=null||result.ResponseData.kbRetriever.kbUrl!=null){
        const unescapedStr = result.ResponseData.kbRetriever.kbFiles.replace(/\\"/g, '"');
        const parsedFiles = JSON.parse(unescapedStr);
        const unescapedUrl = result.ResponseData.kbRetriever.kbUrl.replace(/\\"/g, '"');
        const parsedUrl = JSON.parse(unescapedUrl)
        const newAskAsamiKbData  = {
          chatID: new URLSearchParams(window.location.search).get("chatID"),
          collectionName: result.ResponseData.kbRetriever.collectionName,
          indexName:result.ResponseData.kbRetriever.collectionName,
          kbFiles: parsedFiles, //[{}, {}]
          kbUrl:JSON.parse(result.ResponseData.kbRetriever.kbUrl), //[''.'']
          isKbRetriever: result.ResponseData.kbRetriever.isKbRetriever, //true
        };

        setAskAsamiKbData(newAskAsamiKbData);
        sessionStorage.setItem('askAsamiKbData', JSON.stringify(newAskAsamiKbData))
      }
        if (lengthofkey > 0) {
          setUtcDateTime(result.ResponseData.currentTxt[0].createdDateTime);
          if (result.ResponseData.kbRetriever.chatType == 'General') {
            // setHidePrompts(true);
            // setYesFixButton(true);
            // setConfigSet(true)
            sethiddenObjValues((pre) => ({
              ...pre,

              success: true,
              sendMessage: false,
              binding: false
            }));
            setcurrentChat(result.ResponseData.currentChat)
          }
          var approverName = "";
          const approvermailID = result.ResponseData.promptIDMessages.length > 0
          ? result.ResponseData.promptIDMessages[0]?.approverEmailID
          : null;
          if (approvermailID) {
            let value =
              result.ResponseData.promptIDMessages[0].approverEmailID.split("@");
            approverName = value[0];
          }
          setJsonObj(result.ResponseData.JSON);

          let kbFilesArray = [];
          let kbPairs = [];

          let kbCollName = "";
          let KbIndName = "";

          if (result.ResponseData.kbRetriever && result.ResponseData.kbRetriever.length> 0) {
            let kbRetrieverData = result.ResponseData.kbRetriever;
            let isAskAsamiKbRetriever = kbRetrieverData.isKbRetriever;

            setAskAsamiKbRetriever(isAskAsamiKbRetriever)

            let kbFiles = kbRetrieverData.kbFiles;
            let kbUrl = kbRetrieverData.kbUrl;
            if (kbRetrieverData.collectionName) {
              kbCollName = kbRetrieverData.collectionName;
            }
            setCollectionName(kbCollName);

            if (kbRetrieverData.indexName) {
              KbIndName = kbRetrieverData.indexName;
            }
            setAskAsamiIndexName(KbIndName);

            // Parse kbFiles if it exists
            if (kbFiles) {
              try {
                kbFilesArray = JSON.parse(kbFiles);
              } catch (error) {
                console.error("\n");
              }
            }
            setAskAsamiKnowledge(kbFilesArray as any);
            setKbAskAsamiRetrieverUrl(kbRetrieverData.kbUrl);
          }
          
          if (
            result.ResponseData.currentTxt.length == 1 &&
            result.ResponseData.currentChat.length != 0 &&
            result.ResponseData.currentPromptMessageOrder !=
            0
          ) {
            if (
              result.ResponseData.currentChat[
                result.ResponseData.currentChat.length - 1
              ].role == "user"
            ) {
              setcurrentChat(result.ResponseData.currentChat);
              currentChat = result.ResponseData.currentChat;
              setKbContents(result.ResponseData.kbContents);
              setWebContents(result.ResponseData.webContents);
              setFileURLs(result.ResponseData.bLinks)
              setExcelURLs(result.ResponseData?.excelURLs);
              setLeftCodeArray(result.ResponseData.existingParentFunction)
              setRightCodeArray(result.ResponseData.solvedParentFunction)
              setReplaceObj(result.ResponseData.promptFormIDJson);
              globalJSON = result.ResponseData.promptFormIDJson;
              setMessageOrder(
                result.ResponseData.currentPromptMessageOrder
              );
              message_Order =
                result.ResponseData.currentPromptMessageOrder;
              messageOrder = message_Order;
              promptIDMessagesArray = result.ResponseData.promptIDMessages;
              setPromptIDMessages(
                result.ResponseData.promptIDMessages.sort(
                  (a: any, b: any) => a.promptMessageOrder - b.promptMessageOrder
                )
              );

              if (result.ResponseData.kbRetriever.chatType == 'General') {
                // setHidePrompts(true);
                // setYesFixButton(true);
                // setConfigSet(true)
                sethiddenObjValues((pre) => ({
                  ...pre,

                  success: true,
                  sendMessage: false,
                  binding: false
                }));
              }
              else {
                setIsPrompt(true)
                setConfigSet(false)
                sethiddenObjValues((pre) => ({
                  ...pre,

                  success: true,
                  sendMessage: true,
                  binding: false
                }));
              }

              requestGptToken(
                result.ResponseData.promptIDMessages.sort(
                  (a: any, b: any) => a.promptMessageOrder - b.promptMessageOrder
                ),
                globalJSON
              );
            } else {
              setcurrentChat(result.ResponseData.currentChat);
              setKbContents(result.ResponseData.kbContents);
              setWebContents(result.ResponseData.webContents);
              setFileURLs(result.ResponseData.bLinks)
              setExcelURLs(result.ResponseData?.excelURLs);
              setLeftCodeArray(result.ResponseData.existingParentFunction)
              setRightCodeArray(result.ResponseData.solvedParentFunction)
              setReplaceObj(result.ResponseData.promptFormIDJson);
              globalJSON = result.ResponseData.promptFormIDJson;
              setMessageOrder(
                result.ResponseData.currentPromptMessageOrder
              );
              message_Order =
                result.ResponseData.currentPromptMessageOrder;
              messageOrder = message_Order;
              promptIDMessagesArray = result.ResponseData.promptIDMessages;
              setPromptIDMessages(
                result.ResponseData.promptIDMessages.sort(
                  (a: any, b: any) => a.promptMessageOrder - b.promptMessageOrder
                )
              );
              if (result.ResponseData.kbRetriever.chatType == 'General') {
                // setHidePrompts(true);
                // setYesFixButton(true);
                setConfigSet(true)
                //(configdata, "NOFEMMDVMDFOVMOFDMVODFMVODFOVMFDOVMFOV")
                sethiddenObjValues((pre) => ({
                  ...pre,

                  success: true,
                  sendMessage: false,
                  binding: false
                }));
              }
              else {
                sethiddenObjValues((pre) => ({
                  ...pre,

                  success: true,
                  sendMessage: true,
                  binding: false
                }));
              }
              sethiddenObjValues({
                ...hiddenObjValues,
                // binding: false,
              });
            }
          } else if (
            result.ResponseData.currentTxt.length == 1 &&
            result.ResponseData.currentChat.length != 0 &&
            result.ResponseData.currentPromptMessageOrder ==
            0
          ) {
            setcurrentChat(result.ResponseData.currentChat);
            currentChat = result.ResponseData.currentChat;
            setKbContents(result.ResponseData.kbContents);
            setExcelURLs(result.ResponseData?.excelURLs);
            setLeftCodeArray(result.ResponseData.existingParentFunction)
            setRightCodeArray(result.ResponseData.solvedParentFunction)
            setWebContents(result.ResponseData.webContents);
            setFileURLs(result.ResponseData.bLinks)

            setMessageOrder(
              result.ResponseData.currentPromptMessageOrder
            );
            message_Order =
              result.ResponseData.currentPromptMessageOrder
            sethiddenObjValues((pre) => ({
              ...pre,

              success: false,
              sendMessage: false,
            }));
            if (
              result.ResponseData.currentChat[
                result.ResponseData.currentChat.length - 1
              ].role == "user"
            ) {
              requestGptToken(
                result.ResponseData.promptIDMessages.sort(
                  (a: any, b: any) => a.promptMessageOrder - b.promptMessageOrder
                ),
                globalJSON
              );
              sethiddenObjValues({
                ...hiddenObjValues,
                // binding: false,
              });
            }
            sethiddenObjValues({
              ...hiddenObjValues,
              // binding: false,
            });
          } else if (result.ResponseData.currentTxt.length == 2) {
            promptIDMessagesArray = result.ResponseData.promptIDMessages;
            setApproverName(result.ResponseData.approverName);
            setPromptName(result.ResponseData.promptName);
            setPromptIDMessages(
              result.ResponseData.promptIDMessages.sort(
                (a: any, b: any) => a.promptMessageOrder - b.promptMessageOrder
              )
            );
            setReplaceObj(result.ResponseData.promptFormIDJson);
            globalJSON = result.ResponseData.promptFormIDJson;

            sethiddenObjValues((pre) => ({
              ...pre,

              success: false,
              sendMessage: true,
              awaiting: false,
              requestSendMsg: false,
              binding: false
            }));
            setApproverName(approverName);
          } else if (
            result.ResponseData.currentTxt.length == 3 &&
            result.ResponseData.currentChat.length == 0
          ) {
            
            if (result.ResponseData.approvalStatus == "Approved") {
              if (!block_trigger_once) {
                setPromptName(result.ResponseData.promptName);
                setPromptIDMessages(
                  result.ResponseData.promptIDMessages.sort(
                    (a: any, b: any) => a.promptMessageOrder - b.promptMessageOrder
                  )
                );
                setReplaceObj(result.ResponseData.promptFormIDJson);
                globalJSON = result.ResponseData.promptFormIDJson;
                promptIDMessagesArray = result.ResponseData.promptIDMessages;
                sethiddenObjValues((pre) => ({
                  ...pre,

                  requestSendMsg: false,
                  approvedMsg: false,
                  awaiting: true,
                  status: result.ResponseData.approvalStatus,
                  sendMessage: true,
                  binding: false,
                }));
                setApproverName(approverName);
                promptIDMessagesArray = result.ResponseData.promptIDMessages;
                requestGptToken(
                  result.ResponseData.promptIDMessages,
                  result.ResponseData.promptFormIDJson
                );
              } else {
                setPromptName(result.ResponseData.promptName);
                setPromptIDMessages(
                  result.ResponseData.promptIDMessages.sort(
                    (a: any, b: any) => a.promptMessageOrder - b.promptMessageOrder
                  )
                );
                setReplaceObj(result.ResponseData.promptFormIDJson);
                globalJSON = result.ResponseData.promptFormIDJson;
                promptIDMessagesArray = result.ResponseData.promptIDMessages;
                sethiddenObjValues((pre) => ({
                  ...pre,

                  requestSendMsg: false,
                  approvedMsg: false,
                  awaiting: true,
                  status: result.ResponseData.approvalStatus,
                  sendMessage: true,
                  binding: false,
                }));
                setApproverName(approverName);
                promptIDMessagesArray = result.ResponseData.promptIDMessages;
                requestGptToken(
                  result.ResponseData.promptIDMessages,
                  result.ResponseData.promptFormIDJson
                );
                block_trigger_once = false;
              }
            } else {
              const hiddenObjectValues = {
                ...hiddenObjValues,
                requestSendMsg: false,
                rejectedMsg: false,
                status: result.ResponseData.approvalStatus,
              };

              sethiddenObjValues(hiddenObjectValues);
              // sethiddenObjValues({
              //   ...hiddenObjValues,

              //   // requestSendMsg: false,
              //   // rejectedMsg: false,
              //   status: result.ResponseData.approvalStatus,
              //   awaiting: true,
              //   sendMessage: true,
              //   binding: false,
              // });

              setApproverName(approverName);
            }
          }
          else if (
            result.ResponseData.currentTxt.length == 3 &&
            result.ResponseData.currentChat.length != 0 &&
            result.ResponseData.currentPromptMessageOrder!=
            0
          ) {
            if (
              result.ResponseData.currentChat[
                result.ResponseData.currentChat.length - 1
              ].role == "user"
            ) {
              sethiddenObjValues((pre) => ({
                ...pre,
                requestSendMsg: false,
                approvedMsg: false,
                status: result.ResponseData.approvalStatus,
                binding: false
              }));
              setPromptName(result.ResponseData.promptName);
              promptIDMessagesArray = result.ResponseData.promptIDMessages;
              setApproverName(approverName);
              setcurrentChat(result.ResponseData.currentChat);
              currentChat = result.ResponseData.currentChat;
              setKbContents(result.ResponseData.kbContents);
              setWebContents(result.ResponseData.webContents);
              setFileURLs(result.ResponseData.bLinks)
              setExcelURLs(result.ResponseData?.excelURLs);
              setLeftCodeArray(result.ResponseData.existingParentFunction)
              setRightCodeArray(result.ResponseData.solvedParentFunction)
              setPromptIDMessages(
                result.ResponseData.promptIDMessages.sort(
                  (a: any, b: any) => a.promptMessageOrder - b.promptMessageOrder
                )
              );
              setReplaceObj(result.ResponseData.promptFormIDJson);
              globalJSON = result.ResponseData.promptFormIDJson;

              setMessageOrder(
                result.ResponseData.currentPromptMessageOrder
              );
              message_Order =
                result.ResponseData.currentPromptMessageOrder
              messageOrder = message_Order;
              requestGptToken(result.ResponseData.promptIDMessages, globalJSON);
            } else {
              sethiddenObjValues((pre) => ({
                ...pre,
                requestSendMsg: false,
                approvedMsg: false,
                status: result.ResponseData.approvalStatus,
                binding: false
              }));
              setPromptName(result.ResponseData.promptName);
              promptIDMessagesArray = result.ResponseData.promptIDMessages;
              setApproverName(approverName);
              setcurrentChat(result.ResponseData.currentChat);
              setKbContents(result.ResponseData.kbContents);
              setWebContents(result.ResponseData.webContents);
              setFileURLs(result.ResponseData.bLinks)
              setExcelURLs(result.ResponseData?.excelURLs);
              setLeftCodeArray(result.ResponseData.existingParentFunction)
              setRightCodeArray(result.ResponseData.solvedParentFunction)
              setPromptIDMessages(
                result.ResponseData.promptIDMessages.sort(
                  (a: any, b: any) => a.promptMessageOrder - b.promptMessageOrder
                )
              );
              setReplaceObj(result.ResponseData.promptFormIDJson);
              globalJSON = result.ResponseData.promptFormIDJson;

              setMessageOrder(
                result.ResponseData.currentPromptMessageOrder
              );
              message_Order =
                result.ResponseData.currentPromptMessageOrder;
              messageOrder = message_Order;
            }
          } else if (
            result.ResponseData.currentTxt.length == 3 &&
            result.ResponseData.currentChat.length != 0 &&
            result.ResponseData.currentPromptMessageOrder ==
            0
          ) {
            sethiddenObjValues((pre) => ({
              ...pre,

              requestSendMsg: false,
              approvedMsg: false,
              status: result.ResponseData.approvalStatus,
              binding: false
            }));
            setMessageOrder(
              result.ResponseData.currentPromptMessageOrder
            );
            message_Order =
              result.ResponseData.currentPromptMessageOrder;
              setApproverName(approverName);
              setPromptName(result.ResponseData.promptName);
            promptIDMessagesArray = result.ResponseData.promptIDMessages;
            setcurrentChat(result.ResponseData.currentChat);
            // currentChat = result.ResponseData.currentChat;
            setKbContents(result.ResponseData.kbContents);
            setWebContents(result.ResponseData.webContents);
            setFileURLs(result.ResponseData.bLinks)
            setExcelURLs(result.ResponseData?.excelURLs);
            setLeftCodeArray(result.ResponseData.existingParentFunction)
            setRightCodeArray(result.ResponseData.solvedParentFunction)
            setPromptIDMessages(
              result.ResponseData.promptIDMessages.sort(
                (a: any, b: any) => a.promptMessageOrder - b.promptMessageOrder
              )
            );
            setReplaceObj(result.ResponseData.promptFormIDJson);
            globalJSON = result.ResponseData.promptFormIDJson;

            if (
              result.ResponseData.currentChat[
                result.ResponseData.currentChat.length - 1
              ].role == "user"
            ) {
              requestGptToken(
                result.ResponseData.promptIDMessages.sort(
                  (a: any, b: any) => a.promptMessageOrder - b.promptMessageOrder
                ),
                globalJSON
              );
              sethiddenObjValues({
                ...hiddenObjValues,
                // binding: false,
              });
            }

            sethiddenObjValues((pre) => ({
              ...pre,
              success: false,
              sendMessage: false,
              binding: false
            }));
          }
        } 
        else {
          sethiddenObjValues({
            ...hiddenObjValues,
            invalidChat: true,
            tokenError: "Invalid chat",
            // binding: false
          });
          setErrorMsg("Invalid chat")
        }
        // setIsLoad(false)
    
        setJsonObj(result.ResponseData.JSON);
        // setShowJsonObj(result.ResponseData.JSON)  
        let val = result.ResponseData.currentPromptMessageOrder <= result.ResponseData.promptIDMessages.length
        setMessageOrder(result.ResponseData.currentPromptMessageOrder)
        if (val && result.ResponseData.promptIDMessages.length>0&&result.ResponseData.currentPromptMessageOrder!=0) {
          setIsPrompt(true)
          sethiddenObjValues({
            ...hiddenObjValues,
            sendMessage: false,
          });
        }
      }

    } catch (error) {
      //("Logging error");
      sethiddenObjValues({
        ...hiddenObjValues,
        // binding: false,
      });

    }
    sethiddenObjValues({
      ...hiddenObjValues,
      // binding: false,
    });
  };

    const handleWebSearch = async (query: string, BLinksList?:[]): Promise<SearchResult> => {

      try {
        
        const response = await serpAPI({ query });
        
        const [firstResult, secondResult] = response.data?.responseData?.google_result;
  
        const formattedResponse = `
          ${query}
        
          $$$
          
          As an AI assistant, please provide a clear and concise answer to the query above. Base your response on the following information, but present it as if you already possess this knowledge without referencing any specific sources or contexts:
          
          ${firstResult}
          
          ${secondResult}
          
          Synthesize this information into a coherent response that directly addresses the query. Ensure your answer is factual, informative, and to the point. If the query requires a more detailed explanation, offer to provide additional information if needed.
        `;
        
        return {
          formattedResponse,
          rawOutput: response.data?.responseData?.google_result
        };
      } catch (error) {
        console.error("Error in handleSearch:", error);
        return {
          formattedResponse: query,
          rawOutput: []
        };
      }
    };

    const getChatName = async (firstContent:string): Promise<string> => {
      try {
        let askAsami = '';
  
        // Make the API call
        const result = await LoadBalancer({
          modelConfigurationID: '',
          messages: [
            {
              role: 'user',
              content: `Analyze this input: "${firstContent}".
      Task: Generate a two to three descriptive word to serve as a chat name.
      This word should encapsulate the essence or main topic of the input.
      If the input is code or technical, choose a relevant technical term.
      JUST TWO OR THREE WORDS. NOTHING MORE
      `,
            },
          ],
        });
  
        let askAsamiResponse = result?.data;
        if (askAsamiResponse) {
          askAsami = askAsamiResponse.ResponseData.response?.trim() || '';
  
          // First, remove any truncated part (ending with ...)
          askAsami = askAsami.replace(/\s*\.{3,}$/, '').trim();
  
          // Then, remove any trailing dot
          askAsami = askAsami.replace(/\.$/, '');
        } else {
          askAsami = 'Ask Asami';
        }
  
  
        if (KbChatName) {
          chatNameInsertion(askAsami);
        } else {
          await createChat(askAsami);
        }
  
        return askAsami;
      } catch (error) {
        console.error('\n');
        throw error;
      }
  };


  const postMessage = async (
    AIResponse: any,
    finish_reason: string | null | undefined,
    desiredMessage: DesiredMessage | null,
    webSearchResult?: string | null,
    excelURL?: string,
    parentFunction?: string,
    solvedParentFunction?: string,
    BlinksList?:any
  ): Promise<void> => {
    
    ////////////////////////////////////////////////////////////////////////////////////
    const currentChat_ID = new URLSearchParams(window.location.search).get("chatID") || "";
    setcurrentChatID(currentChat_ID)

    if (desiredMessage?.isKbRetriever) {
      setIsKbRetrieverActive(true);
    }

    // if (promptData?.indexName) {
    //   setIndexName(promptData.indexName);
    //   setCollectionName(promptData.collectionName);
    // }


    


    
    // const handleAddLink = (newLink:any) => {
    //   setBLinksList([...imageDocLinks, newLink]);
    // };

    try {
      
      const tempPromptMessageOrder = messageOrder || 0;
      // REQUIRED
      
      const isEditMessagesForAssistant = (isStopGeneration && AIResponse.role !== "user") ? false :isEditMessage
      const result = await insertMessage({
        isEditMessage:isEditMessagesForAssistant,
        AIResponse,
        chatID: currentChat_ID,
        messageType: "chat",
        promptMessageOrder: tempPromptMessageOrder,
        url: excelURL || null,
        webContent: webSearchResult || "",
        kbContent: "",
        existingParentFunction: parentFunction || null,
        solvedParentFunction: solvedParentFunction || null,
        bLinks: BlinksList || []
      });
      
      if (AIResponse?.cost && !isEditMessage && currentChat_ID && result.responseData != null) {
        
        await CostCalculationNav({
          AIResponse: { cost: AIResponse.cost },
          isEditMessage,
          chatID: currentChat_ID,
          MessageID: result.responseData.ResponseData,
        });
      }

      if (result.status_code=='200' && AIResponse.role === "user") {
        await getGPTResponse( desiredMessage,
          AIResponse,
          tempPromptMessageOrder,
          result.responseData.ResponseData, BlinksList)
        setNewMessageID(result.responseData.ResponseData);
      }
    } catch (error) {
      console.error("Error in postMessage:", error);
    }
  };

  const value: any = {
    navOpen,
    pauseButtonEnable,
    setPauseButtonEnable,
    haveLastAssistantMessageCome,
    isWebSearch,
    setIsWebSearch,
    // collectionName, 
    setChatCreated,
    setHaveLastAssistantMessageCome,
    currentChat,
    setcurrentChat,
    // BLinksList,
    // setBLinksList,
    isKbRetrieverActive,
    setIsKbRetrieverActive,
    askAsamiKbRetriever, 
    setAskAsamiKbRetriever, 
    indexName,
    setIndexName,
    isEditMessage,
    setIsEditMessage,
    newMessageID,
    setNewMessageID,
    promptData,
    setPromptData,
    loading,
    setLoading,
    bURL,
    setBUrl,
    sendMessageValue,
    setsendMessageValue,
    data,
    setData,
    isKGBuild,
    setIsKGBuild,
    leftCodeArray,
    setLeftCodeArray,
    rightCodeArray,
    setRightCodeArray,
    isModule,
    setIsModule,
    excelURLs,
    setExcelURLs,
    loadingIndicator, 
    setLoadingIndicator, 
    modelID,
    setModelID,
    defaultModelFromEnv,
    setDefaultModelFromEnv,
    hiddenObjValues,
    sethiddenObjValues,
    userData,
    setUserData,
    messageOrder,
    setMessageOrder,
    pushChat,
    postMessage,
    getChatName,
    getValyriaChatName,
    collectionName_avalon,
    handleVoiceButtonClick,
    handleAddKB,
    handleWebSearch,
    chatCreated,
    isPrompt,
    setIsPrompt,
    isCodeGen,
    setIsCodeGen,
    // startRecording, 
    // stopRecording,
    startConversation,
    setStartConversation,
    askAsamiChatID,
    setAskAsamiChatID,
    isRecording,
    askAsamiChatCreation,
    // startRecording,
    // stopRecording,
    setAskAsamiChatCreation,
    isEditing,
    askAsamiChatName,
    setAskAsamiChatName,
    KbChatName, 
    setKbChatName,
    setApiCallMade,
    apiCallMade,
    // handleSearch,
    codeSnippetValue,
    setCodeSnippetValue,
    CodeSnippet,
    setCodeSnippet,
    isAskAsami,
    setIsAskAsami,
    currentChatID,
    setcurrentChatID,
    kbContents,
    setKbContents,
    webContents, 
    setWebContents,
    incrementval, 
    setincrementval,
    handleBtns,
    incrementfunc,
    decreamentfunc,
    handleD2LinkClick,
    handleMermaidLinkClick,
    handleLinkClick,
    codeCompare,
    webResult,
    setWebResult,
    setIsEditing,
    webSearchResult,
    openKbIndex, 
    setOpenKbIndex,
    openWebIndex, 
    setOpenWebIndex, 
    requestGptToken,
    getGPTResponse,
    getPromptMessage,
    setJsonObj, 
    jsonObj, 
    promptName, 
    setPromptName, 
    approverName, 
    setApproverName, 
    UtcDateTime, 
    setUtcDateTime, 
    createChat, 
    setReplaceObj,
    promptIDMessages,
    setPromptIDMessages,
    askAsamiKbData, 
    setAskAsamiKbData,
    handleRegen,
    getPromptMsgOrder,
    getChatMessages,
    getUsername,
    timeout,
    profile,
    setProfile,
    setDate,
    userPromptQuery,
    setUserPromptQuery,
    handleAbort,
    populateMsg,
    setPopulateMsg,
    voiceToText, 
    setVoiceToText,
    formattedWebSearchResult,
    isStopGeneration,
    setIsStopGeneration,
    projectName_projects,
    collectionName_projects,
    valyriaCurrentChat,
    setValyriaCurrentChat,
    newChat,
    fileURLs,
    setFileURLs,
    edit,
    setEdit,
    regen, 
    setRegen,
    isloading, setIsLoading,
    tempShow,
    setTempShow,
    formattedDateTime,
    setFormattedDateTime,
    caretCoords, setCaretCoords,errorMsg,setErrorMsg,
    isStreaming,setIsStreaming,toasterDisplay,
    setToasterDisplay,codeGenStatus
    
  };
 
  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

export const useChat = (): ChatContextType => {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context;
};