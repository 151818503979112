
import React, { useEffect, useState, useContext,useCallback } from "react";
import toggleContext from "../../Utility/Context";
import { ToggleContextType } from "../../Interfaces/ToggleContextTypes";
import InputComponent from '../../Reusable Components/TextInput';

interface RecordData {
  labelName: string;
  inputValueOrder: string;
  required: string;
  placeHolder?: string;
  fieldValues: {
    [key: string]: {
      value: string;
    };
  };
}

interface Record {
  record: RecordData;
  handleFieldValues: (labelName: string, value: string, inputValueOrder: string, required: string) => void;
  validationErrors?: {
    [key: string]: string;
  };
  fieldValues:any
}

interface TextProps {
  record: Record;
  isLongAnswer: boolean;
}

export const Text = React.memo(({ record, isLongAnswer }: TextProps) => {
  const [recordData, setRecordData] = useState<RecordData | undefined>();
  const [hideError, setHideError] = useState(true);
  const toggle = useContext(toggleContext) as ToggleContextType | null;

  useEffect(() => {
    if (record) {
      setRecordData(record?.record);
    }
  }, [record]);

  const bind = () => {
    if (recordData) {
      const err = `${recordData?.inputValueOrder}`;
      
      if (record?.validationErrors) {
        return (
          <>
            {hideError ? (
              <span
                className="mandatory-star font-14 font-medium mb-0"
                style={{ color: "red" }}
              >
                {record?.validationErrors[err]}
              </span>
            ) : null}
          </>
        );
      }
    }
    return null;
  };


  const actualRecord = record?.record;
  const fieldValues = record?.fieldValues || {};

  // Get the current value from fieldValues
  const currentValue = fieldValues[actualRecord?.inputValueOrder]?.value || '';

  const handleChange = useCallback((newValue: string) => {
    setHideError(!newValue);
    record.handleFieldValues(
      actualRecord.labelName,
      newValue,
      actualRecord?.inputValueOrder,
      actualRecord?.required
    );
    if (toggle && toggle.functions) {
      toggle.functions({
        target: { value: newValue }
      } as React.ChangeEvent<HTMLInputElement>);
    }
  }, [actualRecord, record, toggle]);


  return (
    <>
      {recordData?.labelName && (
        <label
          className="font-15 font-semibold text-color-14"
          htmlFor={recordData.labelName}
        >
          {recordData.labelName}
          {recordData?.required === "true" && <span className="color-red">*</span>}
        </label>
      )}
      { isLongAnswer?(
        <textarea
        className={`form-control font-14 font-medium theme-focus bg-transparent text-color-4 cust-search-input custom-border-3 input-field w-100 mb-3 rounded-3`}
        placeholder={recordData?.placeHolder || "Enter the Text"}
        id={recordData?.labelName}
        onChange={(e) => handleChange(e.target.value)}
        rows={5}
        disabled={!!toggle}
      >{currentValue}</textarea>
      ):(
          <InputComponent
          type={"text"}
          className={`form-control font-14 font-medium theme-focus bg-transparent text-color-4 cust-search-input custom-border-3 input-field ${isLongAnswer ? 'w-100' : 'w-50'} mb-3 rounded-3`}
          placeholder={recordData?.placeHolder || (isLongAnswer ? "Enter the Text" : "Enter the Text")}
          id={recordData?.labelName}
          value={currentValue}
          onChange={handleChange}
          rows={isLongAnswer ? 5 : undefined}
          disabled={!!toggle}
        />
      )}
    
      {bind()}
    </>
  );
});