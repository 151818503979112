import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLinkContextState, NavItem } from './NavLinkInterface';
import UserContext from "../../Auth/UserContext";
import { NavFooterProviderProps } from '../NavFooterComponent/NavFooterInterface';

const initialState: NavLinkContextState = {
  navItems: [
    { id: 'hub', text: 'Hub', icon: 'images-new/hub-icon.svg', route: '/dashboard', show: false, href: "" },
    { id: 'manage-project', text: 'Projects', icon: 'images-new/projects-icon.svg', route: '/manage-project', show: false, href: "" },
    { id: 'manage-prompt', text: 'Manage Execution', icon: 'images-new/manage-prompt-icon.svg', route: '/asami-manage-execution', show: false, href: "" },
    { id: 'llm-grid', text: 'LLM Configuration', icon: 'images-new/llm-config.svg', route: '/llm-config-grid', show: false, href: "" },
    { id: 'feed-back', text: 'Feed Back', icon: 'images-new/feedback-icon.svg', route: '/feedback-cards', show: false, href: "" },
    { id: 'AsamiSupport', text: 'Asami Support', icon: 'images-new/asami-support-icon.svg', route: '#', show: false, href: "mailto:AsamiSupportCrew@avasoft.com?subject=Asami-Support-{REASON}-{USERNAME}" },
    { id: 'user-activity', text: 'Activity Logs', icon: 'images/user-activity-icon.svg', route: '/user-activity-logs', show: false, href: "" },
  ],
  activeTab: '',
  setActiveTab: (tabName: string) => {}, // This will be overridden by the provider
  handleNavigation: (route: string) => {}, // This will be overridden by the provider
  tab: "",
  setTab: () => {},
};

export const NavLinkContext = createContext<NavLinkContextState>(initialState);

export const NavLinkProvider: React.FC<NavFooterProviderProps> = ({ children }) => {
  const [navItems, setNavItems] = useState<NavItem[]>(initialState.navItems);
  const [activeTab, setActiveTab] = useState<string>(initialState.activeTab);
  const userData = useContext(UserContext);
  let [role, setRole] = useState("");

  const history = useNavigate();

  // Effect to dynamically update navigation items based on user role
  useEffect(() => {
    if (userData) {
      const updatedNavItems = navItems.map((item) => {
        switch (userData.role) {
          case "Lead":
            setRole("Lead");
            if (item.id === "user-activity" || item.id === "manage-project") {
              return { ...item, show: true };
            }
            break;
          case "Leadership":
            setRole("Leadership");
            if (["hub", "manage-project", "asami-usage", "manage-prompt", "llm-grid", 'feed-back'].includes(item.id)) {
              return { ...item, show: true };
            }
            break;
          case "User":
            setRole("User");
            if (["manage-project"].includes(item.id)) {
              return { ...item, show: true };
            }
            break;
          default:
            break;
        }
        return item; // For all other cases, return the item unchanged
      });

      setNavItems(updatedNavItems); // Update the state with the new nav items
    }
  }, [userData]);

  // Function to handle navigation to a specific route
  const handleNavigation = (route: string) => {
    history(route);
  };

  // State for managing the currently selected tab
  const [tab, setTab] = useState("Asami");

  const value = {
    navItems,
    activeTab,
    setActiveTab,
    handleNavigation,
    tab,
    setTab,
    role,
    setRole
  };

  return (
    <NavLinkContext.Provider value={value}>
      {children}
    </NavLinkContext.Provider>
  );
};

// Custom hook to access the NavLink context
export const useNavLink = (): NavLinkContextState => {
  const context = useContext(NavLinkContext);
  if (!context) {
    throw new Error('useNavLink must be used within a NavLinkProvider');
  }
  return context;
};