import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import { fetchProjectCost, fetchPracticeCost, fetchIndividualCost } from "../Service/API";
import { OptionType, CostContextType } from "../Interfaces/CostAnalysis";
import { useTableContext } from './ReusableTableContext';
import { ModelConfig } from '../Interfaces/LLMConfigGrid';

const CostContext = createContext<CostContextType | undefined>(undefined);

export const CostProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    // State declarations
    const [page, setPage] = useState('Practice');
    const [projectSearch, setProjectSearch] = useState('');
    const [projectSortOption, setProjectSortOption] = useState('cost');
    const [projectSortValue, setProjectSortValue] = useState('asc');
    const [practiceSearch, setPracticeSearch] = useState('');
    const [search, setSearch] = useState('');
    const [projectCount, setProjectCount] = useState(1);
    const [projectShownRecords, setProjectShownRecords] = useState(0);
    const [practiceCount, setPracticeCount] = useState(1);
    const [individualCount, setIndividualCount] = useState(1);
    const [projectTotalRecords, setProjectTotalRecords] = useState(0);
    const [bindProjectHistory, setBindProjectHistory] = useState<any[]>([]);
    const [filterListProject, setFilterListProject] = useState<any[]>([]);
    const [FilterBindProjectHistory, setFilterBindProjectHistory] = useState<any[]>([]);
    const [projectDupSearch, setProjectDupSearch] = useState('');
    const [bindPracticeHistory, setBindPracticeHistory] = useState<any[]>([]);
    const [bindIndividualHistory, setBindIndividualHistory] = useState<any[]>([]);
    const [isLoad, setIsLoad] = useState(false);
    const [options, setOptions] = useState<OptionType[]>([]);
    const [projectSelectedOptions, setProjectSelectedOptions] = useState<OptionType[]>([]);

    const [practiceSortOption, setPracticeSortOption] = useState('cost');
    const [practiceSortValue, setPracticeSortValue] = useState('asc');
    const [practiceDupSearch, setPracticeDupSearch] = useState('');
    const [practiceShownRecords, setPracticeShownRecords] = useState(0);
    const [practiceTotalRecords, setPracticeTotalRecords] = useState(0);
    const [filterListPractice, setFilterListPractice] = useState<any[]>([]);
    const [FilterBindPracticeHistory, setFilterBindPracticeHistory] = useState<any[]>([]);

    const [individualSearch, setIndividualSearch] = useState('');
    const [individualSortOption, setIndividualSortOption] = useState('cost');
    const [individualSortValue, setIndividualSortValue] = useState('asc');
    const [individualDupSearch, setIndividualDupSearch] = useState('');
    const [individualShownRecords, setIndividualShownRecords] = useState(0);
    const [individualTotalRecords, setIndividualTotalRecords] = useState(0);
    const [filterListIndividual, setFilterListIndividual] = useState<any[]>([]);
    const [FilterBindIndividualHistory, setFilterBindIndividualHistory] = useState<any[]>([]);
    const { data, setData, sortConfig, isModalOpen, setIsModalOpen, setDetailedViewData } = useTableContext<ModelConfig>();

    // Effect to fetch data based on the current page
    useEffect(() => {
        if (page === "Practice") {
            fetchPracticeHistory();
        } else if (page === "Project") {
            fetchProjectHistory();
        } else {
            fetchIndividualHistory();
        }
    }, [sortConfig, search, projectCount, practiceCount, filterListProject, filterListPractice, individualCount, page, practiceSortValue, projectSearch, projectSortValue, individualSearch, practiceSearch, projectSelectedOptions, individualSortValue]);

    // Function to fetch project cost history
    const fetchProjectHistory = async (obj = false) => {
        setIsLoad(true);
        try {
            const ProjectHistoryObj = obj || {
                pageValue: "Project",
                searchValue: projectSearch,
                sortOption: sortConfig.column === "projectName" ? "project" : sortConfig.column || "cost",
                sortValue: sortConfig.order || "desc",
                offsetValue: projectCount,
                filterValue: filterListProject,
            };

            const response = await fetchProjectCost(obj ? obj : ProjectHistoryObj);
            setIsLoad(false);
            setBindProjectHistory(response.data1);
            let record = response.data1.length - projectShownRecords;
            setProjectShownRecords(record + projectShownRecords);
            setFilterBindProjectHistory(response.data2);
            setData(response.data1);
            setProjectTotalRecords(response.data2.length);
        } catch (error) {
            console.error(error);
        }
    };

    // Function to fetch practice cost history
    const fetchPracticeHistory = async (obj = false) => {
        setIsLoad(true);
        try {
            const PracticeHistoryObj = obj || {
                pageValue: "Practice",
                searchValue: practiceSearch,
                sortOption: sortConfig.column === "practiceName" ? "Practice" : sortConfig.column || "cost",
                sortValue: sortConfig.order || "desc",
                offsetValue: practiceCount,
                filterValue: filterListPractice,
            };
            const response = await fetchPracticeCost(obj ? obj : PracticeHistoryObj);
            setIsLoad(false);
            setBindPracticeHistory(response.data1);
            let record = response.data1.length - practiceShownRecords;
            setPracticeShownRecords(record + practiceShownRecords);
            setFilterBindPracticeHistory(response.data2);
            setData(response.data1);
            setPracticeTotalRecords(response.data2.length);
        } catch (error) {
            console.error(error);
        }
    };

    // Function to fetch individual cost history
    const fetchIndividualHistory = async (obj = false) => {
        setIsLoad(true);
        try {
            const IndividualHistoryObj = obj || {
                pageValue: "Individual",
                searchValue: individualSearch,
                sortOption: sortConfig.column === "individualName" ? "userName" : sortConfig.column || "cost",
                sortValue: sortConfig.order || "desc",
                offsetValue: individualCount,
                filterValue: filterListIndividual,
            };
            const response = await fetchIndividualCost(obj ? obj : IndividualHistoryObj);
            setIsLoad(false);
            setBindIndividualHistory(response.data1);
            let record = response.data1.length - individualShownRecords;
            setIndividualShownRecords(record + individualShownRecords);
            setFilterBindIndividualHistory(response.data2);
            setData(response.data1);
            setIndividualTotalRecords(response.data2.length);
        } catch (error) {
            console.error(error);
        }
    };

    // Function to set options for dropdowns based on the current page
    const optionSetter = () => {
        if (page === "Practice") {
            setOptions(bindPracticeHistory.map(item => ({
                value: item.practiceName,
                label: item.practiceName,
            })));
        } else {
            setOptions(bindProjectHistory.map(item => ({
                value: item.projectName,
                label: item.projectName,
            })));
        }
    };

    // Function to handle changes in selected options
    const handleSelectChange = (selectedOptions: OptionType[]) => {
        setFilterListProject([]);
        if (page === 'Project') {
            setFilterListProject([]);
        } else if (page === 'Practice') {
            setFilterListPractice([]);
        }
    };

    // Function to apply filters
    const handleApplyClick = (localFilters: any) => {
        if (page === "Project") {
            setFilterListProject(localFilters?.codegenStatus?.split(",") || []);
        } else if (page === "Practice") {
            setFilterListPractice(localFilters?.codegenStatus?.split(",") || []);
        } else if (page === "Individual") {
            setFilterListIndividual(localFilters?.codegenStatus?.split(",") || []);
        }
    };

    // Function to assign search value based on the current page
    function assign_value() {
        if (page === "Project") {
            return projectDupSearch;
        } else if (page === "Practice") {
            return practiceDupSearch;
        } else if (page === "Individual") {
            return individualDupSearch;
        }
    }

    // Function to handle load more button click
    const loadmoreClick = () => {
        if (page === "Individual") {
            let countValue = individualCount + 1;
            setIndividualCount(countValue);
        } else if (page === "Practice") {
            let countValue = practiceCount + 1;
            setPracticeCount(countValue);
        } else if (page === "Project") {
            let countValue = projectCount + 1;
            setProjectCount(countValue);
        }
    };

  const value: CostContextType = {
    // Pagination and loading states
    page, setPage,
    isLoad,
    loadmoreClick,
  
    // Search and filter states for projects
    projectSearch, setProjectSearch,
    projectDupSearch, setProjectDupSearch,
    projectShownRecords, setProjectShownRecords,
    projectTotalRecords, setProjectTotalRecords,
    projectCount, setProjectCount,
    projectSortOption, setProjectSortOption,
    projectSortValue, setProjectSortValue,
    FilterBindProjectHistory, setFilterBindProjectHistory,
  
    // Search and filter states for practices
    practiceSearch, setPracticeSearch,
    practiceDupSearch, setPracticeDupSearch,
    practiceShownRecords, setPracticeShownRecords,
    practiceTotalRecords, setPracticeTotalRecords,
    practiceCount, setPracticeCount,
    practiceSortOption, setPracticeSortOption,
    practiceSortValue, setPracticeSortValue,
    FilterBindPracticeHistory, setFilterBindPracticeHistory,
  
    // Search and filter states for individuals
    individualSearch, setIndividualSearch,
    individualDupSearch, setIndividualDupSearch,
    individualShownRecords, setIndividualShownRecords,
    individualTotalRecords, setIndividualTotalRecords,
    individualCount, setIndividualCount,
    individualSortOption, setIndividualSortOption,
    individualSortValue, setIndividualSortValue,
    FilterBindIndividualHistory, setFilterBindIndividualHistory,
  
    // General search and options
    search, setSearch,
    options,
    optionSetter,
    handleSelectChange,
    handleApplyClick,
  
    // Data fetching functions
    fetchProjectHistory,
    fetchPracticeHistory,
    fetchIndividualHistory,
  
    // Binding history data
    bindProjectHistory,
    bindPracticeHistory,
    bindIndividualHistory,
  
    // Additional utility functions
    assign_value,
  };
  
  return <CostContext.Provider value={value}>{children}</CostContext.Provider>;
  };
  
  export const useCost = () => {
    const context = useContext(CostContext);
    if (context === undefined) {
      throw new Error('useCost must be used within a CostProvider');
    }
    return context;
  };