
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MyDatePickerProps } from '../Interfaces/DropDown';

const MyDatePicker: React.FC<MyDatePickerProps> = ({ id, label, selected, onChange }) => {
  return (
    <div className="mb-3">
      <label htmlFor={id} className="form-label text-color-9 font-15 font-semibold">
        {label}
      </label>
      <div className="position-relative">
        <DatePicker
          id={id}
          selected={selected}
          onChange={onChange}
          className="form-control input-field font-14 font-regular border-radius-3 text-color-1 bg-color-4 custom-border-3"
          dateFormat="MM-dd-yyyy"
          placeholderText="mm-dd-yyyy"
        />
        <img
          className="calender-icon"
          src="images/calender-icon.svg"
          alt="calendar-icon"
          onClick={() => document.getElementById(id)?.focus()}
        />
      </div>
    </div>
  );
};

export default MyDatePicker;