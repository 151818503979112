import React, { useEffect, useRef, useState } from 'react';
import Select, {components, StylesConfig } from 'react-select';
import { Option, useFilter } from '../Utility/FilterContext';
import { useDropDown } from '../Utility/DropDownContext';
import { DropDownComponentProps } from '../Interfaces/DropDown';
import { useTheme } from '@mui/material/styles';

const 

DropDownComponent: React.FC<DropDownComponentProps> = ({
  hidelabel =false,
  required,
  disabled = false,
  label,
  options,
  value,
  onChange,
  customStylesOverride = {},
  customUI,
  projectID,
  
  renderOption,
  navigate,
}) => {
  const { isMulti } = useDropDown();
  const theme = useTheme();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [textColor, setTextColor] = useState<string>('#000000');
  const [backgroundColor, setBackgroundColor] = useState<string>('#ffffff');
  const [componentKey, setComponentKey] = useState<number>(0);
  const [isCustomDropdownOpen, setIsCustomDropdownOpen] = useState(false);
  const primaryGradient = 'linear-gradient(to right,rgb(95, 136, 232),rgb(139, 90, 216))';

  useEffect(() => {
    const updateColors = () => {
      if (dropdownRef.current) {
        const card = dropdownRef.current.closest('.custom-dashboard-section');
        if (card) {
          const bgColor = window.getComputedStyle(card).backgroundColor;
          setBackgroundColor(bgColor);
          const rgb = bgColor.match(/\d+/g);
          if (rgb) {
            const brightness = (parseInt(rgb[0]) * 299 + parseInt(rgb[1]) * 587 + parseInt(rgb[2]) * 114) / 1000;
            setTextColor(brightness > 128 ? '#000000' : '#ffffff');
          }
        }
      }
    };

    const resizeObserver = new ResizeObserver(updateColors);

    if (dropdownRef.current) {
      const card = dropdownRef.current.closest('.custom-dashboard-section');
      if (card) {
        resizeObserver.observe(card);
      }
    }

    updateColors();
    setComponentKey(prev => prev + 1);

    return () => resizeObserver.disconnect();
  }, [theme]);

  const CustomOption = ({ innerProps, label, data, ...rest }:any) => (
    <components.Option {...rest} innerProps={innerProps}>
      {renderOption ? renderOption(data) : label}
    </components.Option>
  );
  const CustomDropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <div className="custom-dropdown-arrow">
          <img src="images-new/drpdwn-arrow.svg" alt="dropdown-icon" />
        </div>
      </components.DropdownIndicator>
    );
  };

 const customStyles: StylesConfig<Option, boolean> = {
  control: (provided, state) => ({
    ...provided,
    minHeight: '38px',
    height: '38px',
    backgroundColor: backgroundColor, // Use the backgroundColor state variable
    borderColor: theme.palette.mode === 'dark' ? '#555' : '#ccc',
    boxShadow: state.isFocused ? (theme.palette.mode === 'dark' ? '0 0 0 1px #fff' : '0 0 0 1px #000') : 'none',
    '&:hover': {
      borderColor: theme.palette.mode === 'dark' ? '#777' : '#999',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    flexWrap: 'nowrap',
    width: '100%',
    maxWidth: '100%',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    flexShrink: 0,
    backgroundColor: theme.palette.mode === 'dark' ? '#555' : '#e0e0e0',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: theme.palette.mode === 'dark' ? '#fff' : '#333',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: theme.palette.mode === 'dark' ? '#fff' : '#333',
    ':hover': {
      backgroundColor: theme.palette.mode === 'dark' ? '#777' : '#ccc',
      color: theme.palette.mode === 'dark' ? '#fff' : '#333',
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    transition: 'all 0.2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    color: textColor,
    padding: '0 8px',
    '& svg': {
      width: '20px',
      height: '20px',
    },
    borderLeft: 'none', // This line removes the vertical separator
  }),

  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
      
  clearIndicator: (provided) => ({
    ...provided,
    color: textColor,
  }),
  input: (provided, state) => ({
    ...provided,
    className: 'dropdown-indicator-css', // Set the input text color to white
  }),
  placeholder: (provided) => ({
    ...provided,
    position: 'absolute',
    left: '8px',
    top: '50%',
    transform: 'translateY(-50%)',
    color: textColor
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: backgroundColor,
    maxHeight: '200px',
  }),

  menuList: (provided) => ({
    ...provided,
    maxHeight: '200px', // Remove max-height to prevent vertical scrollbar
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isFocused
      ? primaryGradient // Use the primary gradient for hover
      : backgroundColor,
    color: state.isFocused
      ? '#ffffff' // White text on hover for better contrast
      : textColor,
    ':active': {
      background: primaryGradient, // Same gradient for active state
      color: '#ffffff',
    },
    ':hover': {
      background: primaryGradient, // Same gradient for hover
      color: '#ffffff', // White text on hover
    },
    fontSize: '14px', // Match the font size of the "New Chat" button (font-14)
    fontWeight: 500, // Match the font weight (font-medium)
    cursor: 'pointer', // Add pointer cursor on hover
    transition: 'background 0.3s ease', // Smooth transition for hover effect
  }),

  singleValue: (provided) => ({
    ...provided,
    color: textColor,
    maxWidth: 'calc(100% - 8px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
};



  const mergedStyles: StylesConfig<Option, boolean> = {
    ...customStyles,
    ...customStylesOverride,
    control: (provided, state) => ({
      ...customStyles.control?.(provided, state),
      ...customStylesOverride.control?.(provided, state),
    }),
    placeholder: (provided) => ({
      ...provided,
      className: "custom-select-placeholder", // Ensures placeholder text is white
    }),
  };

  const handleItemClick = (value: string) => {
    if (navigate) {
      let path = '';
      switch (value) {
        case 'task':
          
          path = '/manage-task-project';
          break
        case 'issue':
          path = '/manage-issues';
      }
      navigate(path, { state: { projectIdFromMP: projectID } });
    }
    setIsCustomDropdownOpen(false);
  };

  if (customUI) {
    return (
      <div className="position-relative" ref={dropdownRef} style={{"marginLeft":"1vw", width: "8vw"}}>
        <button
          className="btn input-field font-14 story-drpdwn-btn font-regular border-radius-3 bg-color-12 border-0 w-100 d-flex justify-content-between"
          type="button"
          onClick={() =>{ setIsCustomDropdownOpen(!isCustomDropdownOpen)}}
          style={{ width: "8vw"}}
        >
          <span className="issue-color font-medium font-14 d-flex" >
            <img
              src={customUI.iconSrc}
              alt={customUI.buttonText}
              className="me-2"
            />
            {customUI.buttonText}
          </span>
          <span className="ps-1">
            <img
              src="images-new/drpdwn-arrow.svg"
              alt="dropdown-icon"
              className="ms-1"
            />
          </span>
        </button>
        {isCustomDropdownOpen && (
          <ul className="dropdown-menu border-0 shadow-sm py-2 bg-color-4 show mt-2" style={{position:'absolute',marginLeft: "" , zIndex: '1000', display: 'block', width: "6vw" , right:"2%"}}>
            {customUI.dropdownItems.map((item, index) => (
              <li key={index} className="font-14 font-medium color-black px-2">
                <a className="dropdown-item py-2 custom-dropdown-li text-color-1" onClick={() => {handleItemClick(item.value)}}>
                  <img
                    src={item.iconSrc}
                    alt={item.text}
                    className="me-2 icon-width"
                  />
                  
                  {item.text}
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
  
  return (
    <div className="mb-3" ref={dropdownRef}>
      {!hidelabel?<label className="form-label font-15 font-semibold text-color-9" style={{ color: textColor }}>{label} </label>:null} {required=="true"?<span className="text-danger">*</span>:<></>}
      <Select<Option, boolean>
        key={`${componentKey}-${textColor}-${backgroundColor}`}
        isDisabled={disabled} 
        options={options}
        value={value}
        onChange={onChange}
        isMulti={isMulti}
        styles={mergedStyles}
        classNamePrefix="select"
        placeholder="Select"
        components={{ Option: CustomOption, DropdownIndicator: CustomDropdownIndicator  }}
      />
    </div>
  );


  

};

export default DropDownComponent;