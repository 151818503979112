import React, { useEffect, useState, useRef, useContext } from 'react';
import { ChatProvider, useChat } from '../../Utility/ChatContext';
import { FilterProvider } from '../../Utility/FilterContext';
import ConfigurationButton from '../../Reusable Components/ChatConfigure';
import { FileUploadProvider, useFileUpload } from '../../Utility/FileUploadContext';
import { ClipLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import { DiagramButtonProvider } from '../../Utility/DiagramButtonContext';
import ChatForm from './ChatForm';
import OutgoingContent from './OutgoingContent';
import DiagramPreview from './DiagramComponent';
import IncomingContent from './IncomingContent';
import UserContext from '../../Auth/UserContext';
import MyContext from '../../Utility/MyContext';
import { PromptFormComponent } from '../Manage Prompt/ManagePromptForm';
import axios from 'axios';
import TextStream from './TextStream';
import { getModelConfig, layerCheck, orchestrate } from '../../Service/API';
import { WholeDataProvider } from '../../Utility/WholeDataContext';
import { useHeaderContext } from '../../Utility/HeaderContext';
import UserInput from './Components/UserInput';
import ChoiceButtons from './Components/ChoiceButtons';
import FileUpload from './Components/FileUpload';
import PlannerComponent from './Valyria/Planner';
import { message } from 'antd';
import io from 'socket.io-client';
import Terminal from './Valyria/Terminal';
import Sidebar from '../../Header Components/RightNavigation/rightNavigation';
import { useKnowledgeBase } from '../../Utility/AddKnowledgeBaseContext';
import MarkdownEditor from '../../Reusable Components/MDToolbar';
import LoadingIndicator from '../../Reusable Components/LoadingIndicator';
import { useNavigate,useLocation } from 'react-router';
import { ProcessRequirement } from '../../Service/API';
import JsonDisplay from './JsonDisplayComponent';
import Loader from '../../Reusable Components/Loader';
import { getPresignedURL } from '../../Service/API';
import { UrlsAi } from '../../Service/API-Constants';
import { getValyriaID } from '../../Service/API';
import { isAuth } from '../../Auth/AuthConfig';
import FeedBackForm from '../Feedback/FeedbackForm';
import { format } from 'date-fns';


import { getSessionItem } from '../../Utility/encrypt-storage';
import AIProcessingIndicator from '../../Reusable Components/ThinkingLoader';
import AIProcessingIndicatorCodegen from '../../Reusable Components/ThinkingLoaderCodegen';
import Toastr from '../../Reusable Components/Toastr';
const plantUmlRegex = /^@startuml|(?:^|\s)\bplantuml\b/i;
const d2Regex = /^--d2\s*\n/m;
const mermaidRegex = /^mermaid\s*\n/m;

// Define interface for special messages
interface SpecialMessage {
  chat_type: string;
  message?: any;
  question?: string;
  question_id?: string;
  choices?: string[];
  data?: any;
  isAwaitingConfirmation?: boolean;
  fileUploadType?: any;
  user_id: string;
  userId?: any;
  project_id: any;
  chat_id?: any;
  datetime?: any;
  agent_id?:any;
}

export interface ChatProps {
  navOpen: string;
  setNavOpen : any;
}

const ChatComponent: React.FC<ChatProps> = ({ navOpen , setNavOpen}) => {

  const [isImage, setIsImage] = useState<boolean>(false);
  const {
    loading,
    hiddenObjValues,
    sethiddenObjValues,
    messageOrder,
    currentChatID,
    kbContents,
    setKbContents,
    webContents,
    setWebContents,
    incrementval,
    excelURLs,
    isAskAsami,
    isModule,
    handleBtns,
    incrementfunc,
    decreamentfunc,
    leftCodeArray,
    setLeftCodeArray,
    handleD2LinkClick,
    handleMermaidLinkClick,
    handleLinkClick,
    codeCompare,
    webResult,
    setWebResult,
    handleRegen,
    setPromptData,
    promptData,
    requestGptToken,
    createChat,
    getPromptMessage,
    setReplaceObj,
    userPromptQuery,
    data,
    setData,
    setsendMessageValue,
    handleAbort,
    populateMsg,
    getValyriaChatName,
      setIsStopGeneration,
      promptIDMessages,
    setChatCreated,
    setcurrentChatID,
    getChatMessages,
    newChat,
    jsonObj,
    getUsername,
    setProfile,
    timeout,
    setValyriaCurrentChat,
    valyriaCurrentChat,
    setJsonObj, 
    setIsPrompt,
    loadingIndicator,
    regen,
    isloading,
    setTempShow,
    tempShow,
    setLoadingIndicator,
    formattedDateTime,
    setDate,
    UtcDateTime,
    isEditing,
    setIsLoading,
    toasterDisplay,
    setToasterDisplay,
    codeGenStatus
  } = useChat();

  let {    currentChat,
    setcurrentChat,errorMsg,setErrorMsg} = useChat()


  const { isValyria, setIsValyria } = useHeaderContext();
  const navigate=useNavigate()
  const { terminalData, setTerminalData, } = useHeaderContext();
  let { isPrompt , isCodeGen} = useChat();
  let BLinksList = JSON.parse(sessionStorage.getItem("BLinksList") as any)
  const contextData = useContext(MyContext);
  const myElement = useRef<HTMLDivElement>(null);

  const { collectionName } = useKnowledgeBase()

  const [formHidden, setFormHidden] = useState(false);
  const userDetails = useContext(UserContext);
  const projectNameFromLocal = localStorage.getItem('projectNameFromLocal') || '';
  const [cancelButtonTriggered, setCancelButtonTriggered] = useState(false); 
  // State for special message handling
  const [specialMessages, setSpecialMessages] = useState<SpecialMessage[]>([]);
  const [showUserInput, setShowUserInput] = useState(false);
  const [currentQuestionId, setCurrentQuestionId] = useState<string | null>(null);
  const [pendingFileUpload, setPendingFileUpload] = useState<{ type: string, questionId: string } | null>(null);
  const [showFileUpload, setShowFileUpload] = useState<boolean>(false)
  const [fileUploadType, setFileUploadType] = useState<string>('')
  const [userId, setUserId] = useState<string>('')
  const [projectId, setProjectId] = useState<number>(0)
  const [hideChatForm, setHideChatForm] = useState<boolean>(false);
  const [socketConnected, setSocketConnected] = useState<boolean>(false);
  const { activeComponent, setActiveComponent } = useHeaderContext();

  // valyria display states
  const [showValyriaChat, setShowValyriaChat] = useState(false)
  let [valyriaChatContent, setValyriaChatContent] = useState("")
  const [htmlQuestionId, setHtmlQuestionId] = useState<any>()
  const [plannerData, setPlannerData] = useState<object>()
  const [socketInfo, setSocketInfo] = useState<any>()

  const [rightNavOpen,setRightNavOpen] = useState(false)
  const location = useLocation()
  const UserDetails= useContext(UserContext);
  const socketRef = useRef<any>(null);
  const [downloadbutton,setdownloadbutton]=useState(false);

  // // Scroll to end ref variable
  const endOflistRef = useRef() as any

  useEffect(()=>{
    if (downloadbutton){
      setLoadingIndicator(false)
      setIsPrompt(false)
    }
   
  },[downloadbutton])
  useEffect(() => {
    // Parse the chatID from URL
    const queryParams = new URLSearchParams(window.location.search);
    const projectID = queryParams.get('projectID') || '';
    const userEmail = localStorage.getItem('user-email') || '';    
    // Disconnect any existing socket
    if (socketRef.current) {

      socketRef.current.disconnect();
    }
    
    // Initialize socket connection with chat ID in query
    const socket = io(UrlsAi?.baseurl, {
      query: {
        user_id: userEmail,
        project_id: projectID
      },
      transports:['websocket']
    });
    
    // Store the socket reference
    socketRef.current = socket;

    // Set up connection events
    socket.on('connect', () => {

      setSocketConnected(true);
    });

    socket.on('disconnect', () => {

      setSocketConnected(false);
    });

    
    // Listen for frontend_update events
    socket.on('frontend_update', (data: any) => {

      const receivedProjectId = String(data.project_id); // Convert data.project_id to string
      const currentProjectId = String(projectID);       // Convert projectID to string
    

    
      // Always process the message if chatId matches or if we're not filtering by chatId yet
      if (receivedProjectId === currentProjectId) {


        setSpecialMessages(prev => [...prev, data]);
        setUserId(data?.user_id);
        setSocketInfo(data);
        setProjectId(data?.project_id);
        
        // Store in localStorage
        localStorage.setItem('user_id', data?.user_id);
        localStorage.setItem('project_id', data?.project_id);
        localStorage.setItem('question_id', data?.question_id);
        
        handleIncomingSpecialMessage(data);
        setHtmlQuestionId(data?.question_id);
      } else {

      }
    });

    // Listen for orchestration_message events
    socket.on('orchestration_message', (data: SpecialMessage) => {

      
      if (data.project_id === projectID) {
        setSpecialMessages(prev => [...prev, data]);
        setUserId(data?.user_id);
        setProjectId(data?.project_id);
        handleIncomingSpecialMessage(data);
        setHtmlQuestionId(data?.question_id);
      } else {

      }
    });

    // Cleanup on unmount or chatId change
    return () => {

      socket.off('connect');
      socket.off('disconnect');
      socket.off('frontend_update');
      socket.off('orchestration_message');
      socket.disconnect();
    };
  
  }, [window.location.search]);
  useEffect(() => {
    if (cancelButtonTriggered ) {
      setsendMessageValue(populateMsg);
      setCancelButtonTriggered(false);
      setIsPrompt(false)
    }
  }, [cancelButtonTriggered]);

useEffect(() => {
    const fetchValyriaId = async (type:string) => {
      try {
        setcurrentChat([])
        if (!newChat){
        setIsLoading(false)
        }else{
          setIsLoading(true)
        }
        const response = await getValyriaID(); // assuming getValyriaID() is a function that returns a promise
        const id = response.appId;
        if (type) {
          if (type === 'CodeGen') {
            localStorage.setItem('valyriaId', id);
            sessionStorage.setItem('type', id);
          } else {
            localStorage.setItem('valyriaId', id);
            sessionStorage.setItem('type', '');
          }
        }
        setIsLoading(false)
        return id;
      } catch (error) {
        console.error("Error fetching Valyria ID:", error);
        throw error; // Re-throw error to handle it in the caller
      }
    };
 
    const initChat = async () => {
      try {
        const searchParams = new URLSearchParams(location.search);
        const chatID = searchParams.get('chatID');
        const type:any = searchParams.get('type');
        errorMsg=''
        errorMsg = errorMsg
        setErrorMsg("")
        setcurrentChat([]);
        setJsonObj('')
 
        const valyriaId = await fetchValyriaId(type);
 
        if (location.state?.chatID || chatID) {
          setIsLoading(true)
          setcurrentChat([]);
          setJsonObj('')
          setValyriaCurrentChat([])
          setcurrentChatID(location.state?.chatID);
          setChatCreated(true)
          setJsonObj('')
          getUserProfile()
          setIsPrompt(false)
          setHideChatForm(false);
          setSpecialMessages([]);
          setTempShow(false)
          setShowValyriaChat(false)
          getChatMessages(location.state?.chatID || chatID);
        } else {
 
            if(!newChat){
              setcurrentChat([]);
              setProfile(userDetails?.profileImage)
              setChatCreated(false);
              setHideChatForm(false);
              setIsPrompt(false);
              setSpecialMessages([]);
              setTempShow(false)
              setShowValyriaChat(false)
              setJsonObj('')
              setValyriaCurrentChat([])
            }
            else{
              sessionStorage.setItem('type','')
              getUserProfile()
              setcurrentChatID(newChat);
              setChatCreated(true);
              setcurrentChat([]);
              setJsonObj('')
              setValyriaCurrentChat([]);
              setTempShow(false)
              setShowValyriaChat(false)
              setIsPrompt(false)
              setHideChatForm(false);
              setSpecialMessages([]);
              getChatMessages(newChat);
            }
            }
      } catch (error) {
        console.error("Error initializing chat:", error);
      }
    };
 
    initChat();
  }, [location.state?.chatID]);



  useEffect(()=>{

    if(location.state?.askAsamiChatID){
      setcurrentChat([])
      setChatCreated(false)
      setJsonObj('')
      setValyriaCurrentChat([])
      setValyriaChatContent("")
      setSpecialMessages([])
      setTempShow("")
      setIsPrompt(false)
      sethiddenObjValues((pre: any) => ({
        ...pre,
        inputFix: 'Send a Message',
      }));
      setHideChatForm(false);
    }

  },[location.state?.askAsamiChatID])

  useEffect(() => {
    setDate();
  }, [UtcDateTime]);

  useEffect(()=>{
    const newAskAsamiKbData  = {
      chatID: null,
      collectionName:  null,
      indexName: null,
      kbFiles: [], 
      kbUrl: [], 
      isKbRetriever: true, 
    };
    sessionStorage.setItem('askAsamiKbData', JSON.stringify(newAskAsamiKbData))
    sessionStorage.setItem('BLinksList', JSON.stringify([]))
  },[])



  useEffect(() => {
    if (endOflistRef.current) {
      endOflistRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentChat,specialMessages]);
 
const getUserProfile = async()  =>{

  try{
    const access_token = getSessionItem("access-token");
    const requests = await getUsername(newChat);

    const url = `https://graph.microsoft.com/v1.0/users/${requests}/photo/$value`;

    const response = await axios.get<Blob>(url, {
      headers: {
        "access-token": access_token,
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      responseType: "blob",
    });
    const reader = new FileReader();
    reader.readAsDataURL(response.data);
 
    // Using a promise to wrap the reader's onload event
    const base64String: string = await new Promise((resolve, reject) => {
      reader.onloadend = () => {
        if (reader.result) {
          resolve(reader.result.toString());
        } else {
          reject("Failed to read file as Data URL");
        }
      };
      reader.onerror = () => {
        reject("Failed to read file");
      };
    });
 
    await timeout(500);
 
    // Assuming setProfile expects a base64 string
    setProfile(base64String);

  } catch (error) {
    setProfile(userDetails?.profileImage)

  }
}

  // Update hideChatForm when specialMessages changes
  useEffect(() => {
    if (specialMessages.length > 0) {
      setHideChatForm(true);
    } else {
      setHideChatForm(false);
    }
  }, [specialMessages]);

  const handleIncomingSpecialMessage = (data: SpecialMessage) => {

    switch (data.chat_type) {
      case "primary":
        setShowUserInput(false);
        setCurrentQuestionId(null);
        break;
      case "userquestion":
        setShowUserInput(true);
        setCurrentQuestionId(data.question_id || null);
        break;
      case "choices":
        setShowUserInput(true);
        setCurrentQuestionId(data.question_id || null);
        break;
      case "displayPlanner":
        setShowUserInput(true);
        setCurrentQuestionId(data.question_id || null);
        setPlannerData(data)
        setActiveComponent('calendar')
        break
      case "uploadHTML":
      case "uploadImage":
        // First, display a confirmation question to the user
        const confirmMessage: SpecialMessage = {
          chat_type: 'choices',
          question: data.message || `Do you want to upload a ${data?.chat_type === 'uploadImage' ? 'image' : 'HTML'} file?`,
          choices: ["Yes", "No"],
          question_id: data.question_id || Date.now().toString(),
          isAwaitingConfirmation: true,
          fileUploadType: data.chat_type,
          user_id: data.user_id,
          project_id: data.project_id
        };
        setSpecialMessages(prevMessages => [...prevMessages, confirmMessage]);
        setShowUserInput(true);
        setCurrentQuestionId(data.question_id || null);
        break;
      case "terminal":
        setShowUserInput(true);
        setCurrentQuestionId(data.question_id || null);
        terminalData.data.push(data.message?.output?{"commands":data.message?.executioncommand,"results":data.message?.output}:{"commands":data.message?.executioncommand,"results":data.message?.error})
        setTerminalData(terminalData);
        setActiveComponent('code')
        break;
        
        case "codegenagentflow":
          setShowUserInput(true);

          setActiveComponent('codepad')
          break;
      case "displayFRD":
        setShowUserInput(true);
        setCurrentQuestionId(data.question_id || null);
        setTerminalData(data.data);
        setActiveComponent(activeComponent === "target" ? "" : "target")
        break;

      case "buttondisplay":
        setdownloadbutton(true)
        break;
      case "browser_url":
        setActiveComponent('web')
        break;
   
      default:
        break;
    }
  };

  const handleToastrClose = () => {
    setToasterDisplay(false);
  };


  const handleUserResponse = (response: string, messageData?: SpecialMessage) => {

    if (messageData && messageData.isAwaitingConfirmation && messageData.fileUploadType) {
      if (response === "Yes") {
        // User confirmed file upload
        setShowFileUpload(true);
        setFileUploadType(messageData.fileUploadType);
        setUserId(messageData.user_id)
        setProjectId(messageData.project_id)

      } else if (messageData) {
        // User declined file upload
        setShowUserInput(true);
        setSpecialMessages(prev => [...prev, {
          chat_type: 'primary',
          message: 'File upload cancelled.',
          user_id: userId || userDetails?.email || '',
          project_id: messageData.project_id
        }]);
        // const socket = io(UrlsAi?.baseurl); // This should ideally be a ref or maintained instance
        socketRef.current?.emit('user_response', {
          question_id: htmlQuestionId,
          message: 'No',
          user_id: userId || userDetails?.email || '',
          project_id: projectId,          
        });
      }
    }
    else if (messageData) {


      // Add the user's response to messages
      setSpecialMessages((prevMessages) => [
        ...prevMessages,
        {
          chat_type: 'primary',
          message: `You selected: ${response}`,
          user_id: userId || userDetails?.email || '',
          project_id: messageData.project_id
        }
      ]);
      // const socket = io(UrlsAi?.baseurl); // This should ideally be a ref or maintained instance
      socketRef.current.emit('user_response', {
        question_id: currentQuestionId,
        message: response,
        user_id: userId || userDetails?.email || '',
        project_id: messageData.project_id
      });
    }

    // Emit the response through socket
    // const socket = io(UrlsAi?.baseurl); // This should ideally be a ref or maintained instance
    // socket.emit('user_response', {
    //   question_id: currentQuestionId,
    //   response: response,
    //   user_id: userId || userDetails?.email || '',
    //   project_id: projectId
    // });

    // Check if this was a confirmation for file upload
    if (messageData?.isAwaitingConfirmation && messageData.fileUploadType) {
      if (response === "Yes") {
        // Show the file upload component
        setPendingFileUpload({
          type: messageData.fileUploadType,
          questionId: messageData.question_id || Date.now().toString()
        });
      } else {
        // User declined file upload, show a message
        setSpecialMessages((prevMessages) => [
          ...prevMessages,
          {
            chat_type: 'primary',
            message: `You chose not to upload a file.`,
            user_id: messageData.user_id,
            project_id: messageData.project_id
          }
        ]);
      }
    }

    setShowUserInput(false);
    setCurrentQuestionId(null);
  };

  const handleSpecialMessageSubmit = async (input: string, codeGenStatus: boolean, url: string): Promise<string> => {


    // Add user message
    setSpecialMessages((prevMessages) => [
      ...prevMessages,
      {
        chat_type: 'primary',
        message: input,
        user_id: userId || userDetails?.email || '',
        project_id: projectId || 0
      }
    ]);

    // Emit the message through socket
    // const socket = io(UrlsAi?.baseurl); // This should ideally be a ref or maintained instance
    socketRef.current.emit('user_message', {
      message: input,
      user_id: userId || userDetails?.email || '',
      project_id: projectId,
      code_gen_status: codeGenStatus,
      url: url
    });


    return 'success';
  };

  const handleResponse = async (requirement: string): Promise<void> => {

    // const socket = io(UrlsAi?.baseurl); // This should ideally be a ref or maintained instance
    socketRef.current.emit('user_response', {
      question_id: currentQuestionId,
      message: requirement,
      user_id: userId || userDetails?.email || '',
      project_id: projectId
    });
  }

  const handleProcessRequirement = async (requirement: string, codeGenStatus: boolean, url: string,  BLinksList:[]): Promise<string> => {
    // If we have special messages active, use the special message handler


    if (specialMessages.length > 0) {
      return handleSpecialMessageSubmit(requirement, codeGenStatus, url);
    }

    // Otherwise, proceed with normal processing
    try {
      let response: any = {}
      if (codeGenStatus) {
        setTempShow(requirement);
        let chat_name = await getValyriaChatName(requirement)

        setLoadingIndicator(true)
        response = await layerCheck({
          requirement: requirement.replace(/^\/(Prompt|CodeGen)\s*/g, ""),
          practiceName: UserDetails?.department,
          s3_folder_url: BLinksList,
          chat_name : chat_name
        })
       
        if (response?.type == 'asami') { 

          sethiddenObjValues((pre: any) => ({
            ...pre,
            sendMessage: true,
          }));

        bindForm(response,BLinksList)
        setLoadingIndicator(false)
        }
        else {
          sessionStorage.setItem('project_id', response.data.project_id)
          sessionStorage.setItem('user_id', response.data.user_id)
  
          localStorage.setItem('reward',response.data.reward)
  
          navigate(`/chat?chatID=${response.data.asami_chat_id}&projectID=${response.data.project_id}`);
          sessionStorage.setItem('project_id', response.data.project_id)
          sessionStorage.setItem('user_id', response.data.user_id)
          localStorage.setItem('reward',response.data.reward)
          navigate(`/chat?chatID=${response.data.asami_chat_id}&projectID=${response.data.project_id}`);
          // set variable
          setShowValyriaChat(true);
          // setsendMessageValue(false)
          setValyriaChatContent(requirement);
          setIsValyria(true)
          
          let payload = {
                        message: requirement.replace(/^\/(Prompt|CodeGen)\s*/g, ""),
                        type: response.type,
                        user_id: userDetails?.email,
                        project_id: response.data.project_id,
                        reward: response.data.reward,
                        userEmailID: userDetails?.email
                  }
          try {
            setTempShow("")
            response = await orchestrate(payload)
            setLoadingIndicator(false)
          }
          catch (e) {
            setTempShow("")
            return 'error';
          }
        }
      }
      else {
          sethiddenObjValues((pre: any) => ({
            ...pre,
            sendMessage: true,
          }));
          setTempShow(requirement);
          setLoadingIndicator(true)
        response = await ProcessRequirement({
          requirement: requirement.replace(/^\/(Prompt|CodeGen)\s*/g, ""),
          practiceName: userDetails?.department || "General",
          blob_folder_url: BLinksList
        }) 


        bindForm(response,BLinksList)
        setLoadingIndicator(false)
      }

      return 'success';
    } catch (error) {
      setTempShow("")
      console.error('Error processing requirement:', error);
      return 'error';
    }
  };


    const bindForm = async (response: any,BLinksList?:any): Promise<any> => {
      setIsPrompt(true)
      
      if (!response?.data.filled_status) {
        setPromptData(response.data);
        setFormHidden(true);  // Show the PromptFormComponent
        
      } else {
        setPromptData(response.data);
        const simplifiedInputJsonValues: { [key: string]: string | string[] } = {};
        const promptFormJson: { [key: string]: string | string[] } = {};
        const fieldValues = response.data.filled_fields;
        const promptFields = response.data.promptData.promptFields; // Add this line to extract the promptFields

      Object.entries(fieldValues).forEach(([key, fieldValue]) => {
        if (fieldValue !== null && typeof fieldValue === "object" && "value" in fieldValue) {
          simplifiedInputJsonValues[key] = (fieldValue as any).value;
          const correspondingField = promptFields.find((field: any) => field.inputValueOrder === key);
          if (correspondingField) {
            promptFormJson[correspondingField.labelName] = (fieldValue as any).value;
          }
        } else {
          simplifiedInputJsonValues[key] = String(fieldValue);
          const correspondingField = promptFields.find((field: any) => field.inputValueOrder === key);
          if (correspondingField) {
            promptFormJson[correspondingField.labelName] = String(fieldValue);
          }
        }
      });
      Object.keys(simplifiedInputJsonValues).forEach((key) => {
        const value = simplifiedInputJsonValues[key];
        if (typeof value !== 'string' && !Array.isArray(value)) {
          simplifiedInputJsonValues[key] = JSON.stringify(value);
        }
      });
      setReplaceObj(simplifiedInputJsonValues)

      const approverEmailID = "";  // Fetch or set approver email ID as needed
      const chatName = "New Chat";  // Define a name for the chat

        const payload = {
          approverEmailID,
          userEmailID: userDetails?.email,
          userName: userDetails?.userName,
          promptFormJson, // Ensure that promptFormJson has correct labelName: value pairs
          inputJsonValues: simplifiedInputJsonValues,
          promptID: response.data.promptData.promptID,
          role: "user",
          codegenStatus: "ip",
          projectName: projectNameFromLocal,  // Set a default project name, or fetch as needed
          chatType: "Prompt",
          bLinks: BLinksList?.length > 0 ? BLinksList : ""
        };

        try {
          await createChat(chatName, payload);
          let promptIDMessages = response.data.promptData.promptMessages;
          await getPromptMessage(promptIDMessages, simplifiedInputJsonValues,response.data,BLinksList );
          setTempShow("")
        } catch (error) {
          console.error("Error calling createChat:", error);
          return 'error';
        }
      }
    }
    const convertToTimeFormat = (dateTimeStr: string): string => {
      try{
        const date = new Date(dateTimeStr);
        return format(date, 'hh:mm a');
 
      }
      catch(error){
        return dateTimeStr
      }
    };
    useEffect(()=>{

    },[BLinksList])

    useEffect(()=>{

      if(valyriaCurrentChat.length!=0){
        setIsValyria(true)
   
      }
      else{
        setIsValyria(false)
      }
   
    },[valyriaCurrentChat])
   

    const handleFileRemove = (indexToRemove:any) => {
      // Retrieve the BLinksList from sessionStorage and parse it
      const storedBLinksList = JSON.parse(sessionStorage.getItem("BLinksList") as any);
      
      // Check if the file exists in the list
      if (storedBLinksList && storedBLinksList[indexToRemove]) {
        // Remove the file at the specified index
        const updatedBLinksList = storedBLinksList.filter((_:any, index:any) => index !== indexToRemove);
        
        // Save the updated list back to sessionStorage
        sessionStorage.setItem("BLinksList", JSON.stringify(updatedBLinksList));
        
        BLinksList = updatedBLinksList
          }
    };
  
      const getFilePresignedURL = async(url:string) =>{
              try{
                const response = await getPresignedURL({"url":url})
                if (response.data.status_code == 200 ){
                  return response.data.S3URL
                }else{
                  console.error("Error in getExcelPresignedURL")
                }
              }catch(e){
                console.error("Error in getExcelPresignedURL", e)
          
              }
              
            }
  
      const renderFileGrid = () => {
        // Retrieve BLinksList from sessionStorage
        const BLinksListString = sessionStorage.getItem('BLinksList');
        const BLinksList = BLinksListString ? JSON.parse(BLinksListString) : [];
      
        if (!BLinksList || BLinksList.length === 0) {
          return null;
        }
      
        const fileCount = BLinksList.length;
      
        return (
          <div className={`file-container px-3 pt-3 ${fileCount > 1 ? 'file-grid' : ''}`} style={{display: fileCount>1 ? "block" : "none"}}>
            {BLinksList.map((url:any, index:any) => {
              const fileName = url.split('/').pop();
              const isImage = fileName.toLowerCase().includes('jpg') || fileName.toLowerCase().includes('png');
              return (
                <div key={index} className="file-attach-container text-color-15 px-3 py-2">
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <span className="file-name">
                      <img src={isImage ? '../images/attach-img.svg' : '../images-new/single-file.svg'} alt="file-icon" className="me-2" />
                      <span className="file-name-text">{fileName}</span>
                    </span>
                    <div className="d-flex align-items-center gap-2">
                      <button className="border-0 bg-transparent d-flex" onClick={async () => {
                        const presignedURL = await getFilePresignedURL(url);
                        if (presignedURL) {
                          window.open(presignedURL, "_blank", "noopener,noreferrer");
                        }
                      }}>
                        <img className="filter-icons" src="../images/eye-img.svg" alt="eye-icon" />
                      </button>
                      {isEditing && (
                        <button className="border-0 bg-transparent d-flex" onClick={() => handleFileRemove(index)}>
                          <img className="filter-icons" src="../images/close.svg" alt="close-icon" />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      };
  
  
  
      const renderValyiraChat = () => {
        let splitRegex = /\/(CodeGen|Prompt)/g;
        let parts = valyriaChatContent.split(splitRegex);
 
        return (
          <div className="outgoing-content d-flex mt-5" key={1} style={{marginLeft: navOpen == "Open" && rightNavOpen ? "13vw" : "", width : navOpen == "Open" && rightNavOpen ? "30vw" : "100%"}}>
            <div className="chat-content position-relative">
              <div className="chat-box right position-relative" >
                <p
                  className="font-14 font-semibold m-0 text-color-15"
                  style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
                >
         {parts.map((part, index) => {
                    if (part === "CodeGen" || part === "Prompt") {
                        return (
                          <>{" "}
                            <span
                                key={index}
                                className="font-14 font-semibold m-0"
                                style={{ color: "#5C91F7", whiteSpace: "pre-wrap", wordBreak: "break-word" }}
                            >
                                /{part}
                            </span>
                            {" "}
                            </>
                        );
                    }
                    return (
                        <span
                            key={index}
                            className="font-14 font-semibold m-0 text-color-15"
                            style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
                        >
                            {part.trim()}
                        </span>
                    );
                })}
                </p>
                {renderFileGrid()}
              </div>
            </div>
          </div>
        )
      };
      
      const renderTempValyiraAsamiChat = () => {
        let splitRegex = /\/(CodeGen|Prompt)/g;
        let parts = tempShow.split(splitRegex);
   
 
        return (
          <div className="outgoing-content d-flex mt-5" key={1} style={{marginLeft: navOpen == "Open" && rightNavOpen ? "13vw" : "", width : navOpen == "Open" && rightNavOpen ? "30vw" : "100%"}}>
            <div className="chat-content position-relative">
              <div className="chat-box right position-relative" >
              <p
                  className="font-14 font-semibold m-0 text-color-15"
                  style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
                >
                  {parts.map((part, index) => {
                    if (part === "CodeGen" || part === "Prompt") {
                        return (
                          <>{" "}
                            <span
                                key={index}
                                className="font-14 font-semibold m-0"
                                style={{ color: "#5C91F7", whiteSpace: "pre-wrap", wordBreak: "break-word" }}
                            >
                                /{part}
                            </span>
                            {" "}
                            </>
                        );
                    }
                    return (
                        <span
                            key={index}
                            className="font-14 font-semibold m-0 text-color-15"
                            style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
                        >
                            {part.trim()}
                        </span>
                    );
                })}
                </p>
                {renderFileGrid()}
              </div>
            </div>
          </div>
        )
      }
 





  

  // }
  const hasUpdatedRef = useRef(false);

  const generateMessageHistory = () => {
    let assistantIndex = 0;
    let last_index = currentChat.length;
    let lastUserIndex = 0
    let lastAssistantIndex = 0
    if(!currentChat.hasOwnProperty('chat_type')){
      currentChat.find((value, index) => {
        if (value?.role == "assistant") {
          assistantIndex = index;
        }
      });  
      lastUserIndex = currentChat.reduce((lastIndex, item, index) => (
        item.role === 'user' ? index : lastIndex
      ), -1);
  
      lastAssistantIndex = currentChat.reduce((lastIndex, item, index) => (
        item.role === 'assistant' ? index : lastIndex
      ), -1);

    }
    

    return (
      <>
        {false ? (<div style={{
          textAlign: 'center', flex: 1,
          display: 'flex',
          justifyContent: 'center', marginTop: '15%', marginLeft: '-5%'
        }}>
          <ClipLoader
            color={'#865BE7'}
            loading={loading}
            size={50}
          /></div>) :
          <>
          {jsonObj!=null && <JsonDisplay jsonObj={jsonObj} userData={UserDetails}/>}
          {currentChat.map((value:any, Chat_index) => {


            let profilePic = "";
            let vl_last_index = currentChat.length - 1
            let role = value.role || value.chat_type;
            let mainContent = value?.content;
            let kbContent = kbContents[Chat_index];
            let webContent =
              webContents[Chat_index] !== null &&
                webContents[Chat_index] !== undefined
                ? webContents[Chat_index]
                : webResult;
            

            let isLastUserMessage = Chat_index === lastUserIndex;

            let isLastAssistantMessage = Chat_index === lastAssistantIndex;

              if (isLastAssistantMessage && !hasUpdatedRef.current) {
                if (!isAskAsami) {
                  hasUpdatedRef.current = true;
                  setTimeout(() => {
                    // sethiddenObjValues(prev => ({
                    //   ...prev,
                    //   sendMessage: true
                    // }));
                  }, 0);

                }
                else {
                  hasUpdatedRef.current = true;
                  setTimeout(() => {
                    sethiddenObjValues(prev => ({
                      ...prev,
                      sendMessage: true
                      //change it to false, arunachalam
                    }));
                  }, 0);
                }
              }
              if (typeof value.content === 'object' && value.content !== null && !Array.isArray(value?.content)) {
                value.content = JSON?.stringify(value?.content);
              }
              else if (Array.isArray(value.content) && typeof value.content[0] === "object") {
                value.content = JSON.stringify(value.content);
              }
              else if (typeof value.content === 'string') {
                value.content = value.content;
              }
              
              if (value?.content?.includes('\n\nWeb Content:\n')) {
                [mainContent, webContent] = value.content.split('\n\nWeb Content:\n');  
              }
              if (value?.content?.includes('\n\nKnowledge Base:\n')) {
                [mainContent, kbContent] = value.content.split('\n\nKnowledge Base:\n');
              }

              if (value?.content?.includes('$$$')) {
                // Split the content and keep only the part before the delimiter
                [mainContent] = value.content.split('$$$');
              }

              switch (role) {
                case "user":
                  return (
                    <div
                    key={`chat-${Chat_index}`}
                    onLoad={(e) => {
                      (e.target as HTMLElement).focus();
                    }}
                    >
                        <OutgoingContent
                          mainContent={mainContent}
                          isLastUserMessage={isLastUserMessage}
                          Chat_index={Chat_index}
                          kbContent={kbContent} // Replace with actual content if available
                          webContent={webContent}
                          marginIndex = {Chat_index === lastUserIndex}
                          navOpen = {navOpen}
                          rightNavOpen={rightNavOpen}

                           // Replace with actual content if available
                        />
                    </div>
                  );

                case "assistant":
                  let tempvaluecontent = ""
                  var totallength = 0
                  var currentlength = 0
                  if (!Array.isArray(value?.content)) {
                    tempvaluecontent = value?.content
                  }
                  else {
                    let temp = { [Chat_index]: 0 }
                    if (!incrementval.hasOwnProperty(Chat_index)) {

                      incrementval[Chat_index] = value?.content.length-1
                      tempvaluecontent = value?.content[incrementval[Chat_index]]
                      totallength = value.content.length - 1
                      currentlength = incrementval[Chat_index]

                    }
                    else {
                      tempvaluecontent = value?.content[incrementval[Chat_index]]
                      totallength = value.content.length - 1
                      currentlength = incrementval[Chat_index]
                    }
                  }
                  profilePic = "";
                  if (tempvaluecontent) {
                    const code_array = tempvaluecontent?.split("```")
                      ? tempvaluecontent?.split("```")
                      : tempvaluecontent?.split("@")
                        ? tempvaluecontent?.split("@")
                        : "";

                    let results : any = [];

                    (code_array as string[]).forEach((_code, index)  => {
                      const d2Match = _code.match(d2Regex);
                      if (d2Match) {
                        const trimmedD2Code = _code.slice(_code.indexOf(d2Match[0]) + d2Match[0].length).trim();
                        results.push({
                          type: 'd2',
                          content: trimmedD2Code,
                        });
                      } else if (plantUmlRegex.test(_code.trim()) && index === 1) {
                        results.push({
                          type: 'plantuml',
                          content: _code,
                        });
                      }
                      else if (mermaidRegex.test(_code.trim())) {
                        const mermaidMatch = _code.match(mermaidRegex);
                        if (mermaidMatch) {
                          // Remove everything up to and including the --mermaid line
                          const trimmedMermaidCode = _code.slice(_code.indexOf(mermaidMatch[0]) + mermaidMatch[0].length).trim();

                          results.push({
                            type: 'mermaid',
                            content: trimmedMermaidCode,
                          });
                        }
                      }
                      else if (index % 2 === 0) {
                        results.push({
                          type: "text",
                          content: _code,
                        });
                      } else {
                        results.push({
                          type: 'code',
                          content: _code,
                        });
                      }
                    });

                  return (<>
                    <div ref={endOflistRef}>
                    <IncomingContent
                      key={`chat-${Chat_index}`}
                      lastIndex={last_index}
                      assistantIndex={assistantIndex}
                      ChatIndex={Chat_index}
                      results={results}
                      totallength={totallength}
                      isLastAssistantMessage={isLastAssistantMessage}
                      navOpenHeader={navOpen}
                      rightNav={rightNavOpen}
                    />
                    </div>
                    </>
                 
                  )
                }
                break;
                default:
                return (
                  <div key={`special-${Chat_index}`} className="d-flex w-100" ref={endOflistRef}>
                    <div className="chat-content position-relative" style={{marginLeft: navOpen === "Open" && rightNavOpen ? "5vw" : navOpen === "Close" && rightNavOpen ? "2vw" :  "", marginBottom : Chat_index == vl_last_index ? "13vh" : ""}}>
                      <img
                        src="images\ai-images\Bot_profile.svg"
                        alt="bot-happy"
                        className="chat-img position-absolute bottom-0 chat-bot"
                      />
                       <div className="chat-box position-relative">
                        <p className="font-14 font-semibold m-0 time-theme ">
                          {atob(value.content)}
                        </p>

                        {/* Added the div tag for the time from orchestrator */}
                        <div className="chat-box-time-container grey-blue">
                        <img
                        src="../images/clock-icon-grey-white-theme.svg"
                        alt="clock-icon"
                        className="d-inline-block d-none clock-icon "
                      />
                      <img
                        src="../images/clock-icon-white-dark-theme.svg"
                        alt="clock-icon"
                        className="d-inline-block"
                      />
                      
                      <span className="chat-box-text-time font-regular font-12 ms-1 time-colour-vl grey-blue" >
                      <span className="ms-1 custom-planned grey-blue">
                      {convertToTimeFormat(value?.datetime) || new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} 
                      </span>
                      <span className="ms-1 custom-planned grey-blue"> •</span>
 
                        <span className="ms-1 custom-planned grey-blue">
                          {value.agent_type || "System"}
                        </span>
                      </span>
                    </div>
                    {/* end div tag for the time from orchestrator */}
                      </div>
                    </div>
                  </div>
                );
            }
          })}

          {/* Render special message components */}
          {specialMessages.map((message, index) => {
            let last_index_spl = specialMessages.length
            switch (message.chat_type) {
              case "primary":
                return (
                  <div key={`special-${index}`} className="d-flex w-100" ref={endOflistRef}>
                    <div className="chat-content position-relative" style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" ,marginLeft : navOpen == "Open" && rightNavOpen == true ? "5vw" : "" , marginBottom : index == last_index_spl ? "4vh" : ""}}>
                      <img
                        src="images\ai-images\Bot_profile.svg"
                        alt="bot-happy"
                        className="chat-img position-absolute bottom-0 chat-bot"
                      />
                    <div className="chat-box position-relative">
                        <p className="font-14 font-semibold m-0 time-theme">
                          {message.message}
                        </p>

                        {/* Added the div tag for the time from orchestrator */}
                        <div className="chat-box-time-container">
                        <img
                        src="../images/clock-icon-grey-white-theme.svg"
                        alt="clock-icon"
                        className="d-inline-block d-none clock-icon "
                      />
                      <img
                        src="../images/clock-icon-white-dark-theme.svg"
                        alt="clock-icon"
                        className="d-inline-block"
                      />
                      <span className="chat-box-text-time font-regular font-12 ms-1 time-theme">
                      <span className="ms-1 custom-planned grey-blue">
                      {convertToTimeFormat(message?.datetime) || new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                      </span>
                      <span className="ms-1 custom-planned grey-blue"> •</span>
                      <span className="ms-1 custom-planned grey-blue">
                          {message?.agent_id || "System"}
                        </span>
                      </span>
                    </div>
                    {/* end div tag for the time from orchestrator */}
                      </div>
                    </div>
                  </div>
                );
              case "userquestion":
                return (
                  <div key={`special-${index}`} className="incoming-content d-flex w-100 mt-5">
                    <div className="chat-content position-relative">
                      <img
                        src="images\ai-images\Bot_profile.svg"
                        alt="bot-happy"
                        className="chat-img position-absolute bottom-0 chat-bot"
                      />
                      <div className="chat-box position-relative">
                        <p className="font-14 font-semibold m-0 grey-blue">
                          {message.question}
                        </p>
                      </div>
                      {showUserInput && !isloading && currentQuestionId === message.question_id && (
                        <div className="mt-3">
                          <ChatForm onSubmit={handleProcessRequirement} onUserResponse={handleResponse} specialMessagesActive={specialMessages.length > 0}  navOpenHeader={navOpen} rightNav={rightNavOpen}/>
                        </div>
                      )}
                    </div>
                  </div>
                );
              case "choices":
                return (
                  <div key={`special-${index}`} className="incoming-content d-flex w-100 mt-5">
                    <div className="chat-content position-relative">
                      <img
                        src="images\ai-images\Bot_profile.svg"
                        alt="bot-happy"
                        className="chat-img position-absolute bottom-0 chat-bot"
                      />
                      <div className="chat-box position-relative">
                        <p className="font-14 font-semibold m-0 grey-blue">
                          {message.question}
                        </p>
                      </div>
                      <ChoiceButtons
                        key={`special-${index}`}
                        choices={message.choices || []}
                        onSubmit={response => handleUserResponse(response, message)}
                      />
                    </div>
                  </div>

                );
              case "displayPlanner":
                return (
                  <>
                  </>
                )
              case "uploadHTML":
              case "uploadImage":
                // This case won't be directly rendered anymore
                // Instead, we use the pendingFileUpload state
                return null;
                break;
              case "terminal":
                // <Terminal terminalData={message.data}/>
                break;
              case "displayFRD":
                return (
                  <>

                  </>
                )
                break
              case "codegenagentflow":
                break
              default:
                return null;
            }
          })}

          {/* {terminalData && (
              <div>
                    <Terminal Data={terminalData}/>
              </div>
            )} */}


          {/* Show file upload component only when user confirms */}
          {pendingFileUpload && (

            <div>
              {showFileUpload && (
                <div>
                  <div className="incoming-content d-flex w-100 mt-5">
                    <div className="chat-content position-relative">
                      <img
                        src="images\ai-images\Bot_profile.svg"
                        alt="bot-happy"
                        className="chat-img position-absolute bottom-0 chat-bot"
                      />
                      <div className="chat-box position-relative">
                        <FileUpload
                          type={fileUploadType}
                          userId={userId}
                          projectId={projectId}
                          onUpload={(fileData: any) => {

                            setShowFileUpload(false);
                            setShowUserInput(true);
                            setSpecialMessages(prev => [...prev, {
                              chat_type: 'primary',
                              message: `File uploaded successfully!`,
                              user_id: userId,
                              project_id: projectId

                            }]);
                            // handleResponse(fileData)

                            // Send file upload success to backend
                            // const socket = io(UrlsAi?.baseurl); // This should ideally be a ref or maintained instance
                            socketRef.current.emit('user_response', {
                              question_id: htmlQuestionId,
                              message: fileData,
                              user_id: userId || userDetails?.email || '',
                              project_id: projectId
                            });
                          }}
                          onImageUpload = { () => {
                            // const socket = io(UrlsAi?.baseurl); // This should ideally be a ref or maintained instance
                            socketRef.current.emit('user_response', {
                              question_id: htmlQuestionId,
                              message: 'yes',
                              user_id: userId || userDetails?.email || '',
                              project_id: projectId
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}


            </div>


          )}

        </>
      }
    </>
  );
};

  useEffect(() => {

  }, [isValyria]);

  return (
    <>
    <div className='chat-component-main-container'>
    <div className="row justify-content-lg-end justify-content-center" style={{width: isValyria && rightNavOpen == false ? "93vw" : isValyria && rightNavOpen == true ? "60vw" : "99vw"}}>
        <div className={navOpen === "Close" ? "col-md-11 my-2 py-2" : "col-md-9 my-2 py-2"} id="chat-center">
        {
          !isloading && (<>
        <div className="w-50 d-flex align-items-center justify-content-between" style={{marginLeft: navOpen == "Open" && rightNavOpen ? "7vw" : navOpen=="Close" && rightNavOpen != false ? "2vw" : ""}}>
            <h2 className=" font-bold font-24 m-0 ask-asami-h2 text-color-1" style={{left: '25px'}}>Ask Asami</h2>
            <p className="text-end font-12 font-medium my-3 text-color-9">{formattedDateTime}</p>
        </div>
        
          <ConfigurationButton isImage={isImage} />
          </>)
        }

        {toasterDisplay &&   <Toastr
            message="Please Wait till the streaming is done"
            level="Success"
            duration={5000}
            onClose={handleToastrClose}
            showCloseButton={false}
          />} 
           {sessionStorage.getItem('toasterDisplay')=='true' && (
          <div className="notification-bar">
            Please wait till the streaming is done
          </div>
         )}
 
          {tempShow && renderTempValyiraAsamiChat()}


          {showValyriaChat && renderValyiraChat()} 
          
          {generateMessageHistory()}
          {errorMsg!="" && (<div className="incoming-content d-flex w-100 mt-5"  >
                    <div className={`chat-content position-relative ${hiddenObjValues.sendMessage == false ? "incoming-margin" : null} `} >
                      <img
                        src="images\ai-images\Bot_profile.svg"
                        alt="bot-happy"
                        className="chat-img position-absolute bottom-0 chat-bot"
                      />
                      <div
                        className="chat-box  position-relative  font-14 font-semibold m-0 grey-blue"
                        style={{ border: "1px solid #F7BF55" }}
                      >
                        <p className="font-14 font-semibold m-0 grey-blue " >
                          {hiddenObjValues.tokenError}
                        </p>
                      </div>
                      <div className="chat-box-btns d-flex position-absolute ">
                        <button
                          className="btn primary-gradiant-btn text-white font-12 font-medium me-3 border-0"
                          id="Regen"
                          disabled={hiddenObjValues.regenerateBtn}
                          onClick={(e) => {
                            handleRegen();
                          }}
                        >
                          Regenerate
                        </button>
                        <button
                          className="btn theme-outline-btn theme-color theme-focus text-white font-12 font-medium"
                          id="try"
                          onClick={(e) => {
                            if (isPrompt) {
                              navigate('/chat', {
                                state: {
                                  startConversation: true,
                                  hidePrompts: true,
                                  sendMessage: false,
                                  fixButton: true,
                                  yesFixButton: true
                                }
                              });
                              window.location.reload();
                            } else {
                              window.location.href = "/chat";
                            }
                          }}
                        >
                          Try New Chat
                        </button>
                      </div>
                    </div>
                  </div>


          )}
          {loadingIndicator && (currentChat.length>=1 || specialMessages.length>=1) ? (
            <div style={{marginLeft: (navOpen == "Open" && rightNavOpen == true ) ? "5rem" : ""}}>
                  <LoadingIndicator />
                </div>    

                ) : null}
          {/* {loadingIndicator&&(isPrompt||isValyria?currentChat.length!=0:currentChat.length!=1) ? (
            <div style={{marginLeft: (navOpen == "Open" && rightNavOpen == true ) ? "5rem" : ""}}>
                  <LoadingIndicator />
                </div>    

                ) : null}
                 {loadingIndicator&&!(isPrompt||isValyria?currentChat.length!=0:currentChat.length!=1)  ? (
                <div style={{marginLeft: (navOpen == "Open" && rightNavOpen == true ) ? "5rem" : ""}}>
                  <TypingDotsLoader />
                </div>
                ) : null} */}

                {
                  loadingIndicator && isPrompt && currentChat.length<1 && <AIProcessingIndicator />
                }

                {
                  loadingIndicator && isCodeGen && currentChat.length<1 && specialMessages.length<1 && <AIProcessingIndicatorCodegen />
                }

              <FileUploadProvider>              
                  {!data  && ((isPrompt && hiddenObjValues.inputFix == "Fix your response here" || !isPrompt && hiddenObjValues.inputFix !== "Fix your response here")  && !hideChatForm && !isloading && !isValyria &&<ChatForm onSubmit={handleProcessRequirement} onUserResponse={handleResponse} specialMessagesActive={specialMessages.length > 0} currentChatID={currentChatID} navOpenHeader={navOpen} rightNav={rightNavOpen}/>)}
                  {formHidden && promptData && (
                  <MyContext.Provider
                    value={{
                      setformHidden: setFormHidden,
                      currentPrompt: promptData.prompt,
                      replaceObj: promptData.filled_fields,
                      cancelButtonTriggered,
                      setCancelButtonTriggered
                    }}
                  >
                    
                      <PromptFormComponent 
                        fields={promptData.fields}
                        filled_fields={promptData.filled_fields}
                        global_data={promptData.global_data}
                        promptData={promptData.promptData}
                        requestGptToken={requestGptToken}
                        createChat={createChat}
                      />
                    
                  </MyContext.Provider>
                )}
              </FileUploadProvider>
            {/* Other components */}
            
            
            
            {data ? (
                  <div>
                    <div className="incoming-content  d-flex w-100 mt-5">
                      <div className="chat-content position-relative">
                        <img
                          src="images\ai-images\Bot_profile.svg"
                          alt="bot-happy"
                          className="chat-img position-absolute bottom-0 chat-bot"
                        />
                        <div className="chat-box  position-relative">
                          <p
                            className="font-14 font-semibold m-0 grey-blue "
                            // datatype={data}
                          >
                            <TextStream text={data} />
                          </p>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                ) : null}
                {data  && regen && ((hiddenObjValues.inputFix == "Fix your response here" && messageOrder>0 && promptIDMessages.length >0)  || (hiddenObjValues.inputFix !== "Fix your response here" && messageOrder==0 && promptIDMessages.length ==0 ))  ? (
                <div>
                  <div
                    className={navOpen === "Close" ? "stop-generating-nav-close" : "stop-generating"}
                  >
                    <button
                      className="btn primary-gradiant-btn border-radius-4 font-14 font-medium w-100 py-2 mb-2"
                      onClick={(e) => {

                        setIsStopGeneration(true)

                        handleAbort();

                        setsendMessageValue(populateMsg);

                      }}
                      type="button"
                    // style={{animation:"pulse 1s infinite"}}
                    >
                      <img
                        className="me-1 filter-icons"
                        src="/images-new/stop-icon.svg"
                        alt="stop-icon"
                      />
                      <span className="text-white custom-none NewChat" style={{ fontSize: '0.9rem' }}>
                        Stop Generating
                      </span>
                    </button>
                  </div>

              <style>
                {`
                      @keyframes pulse {
                        0% {
                          transform: scale(1);
                        }
                        50% {
                          transform: scale(1.05);
                        }
                        100% {
                          transform: scale(1);
                        }
                      }
                    `}
              </style>
            </div>) : null}
        </div>

      </div>
      {isloading &&<div style={{  "marginRight": "42%","marginTop": "21%"}} ><Loader isLoading={isloading} navOpening={navOpen}/></div>}
      <div className='position-absolute-right-nav'>
        {/* { true?<Sidebar terminalData={terminalData} plannerData={plannerData} socketInfo={socketInfo} navOpen={navOpen} setNavOpen={setNavOpen} setRightNavOpen = {setRightNavOpen}/>:null} */}
        {isValyria && <Sidebar terminalData={terminalData} plannerData={plannerData} setPlannerData={setPlannerData} socketInfo={socketInfo} navOpen={navOpen} setNavOpen={setNavOpen} setRightNavOpen = {setRightNavOpen} downloadbutton={codeGenStatus}/>}
      </div>
      </div>
      {userDetails?.role === "User" ? <FeedBackForm /> : <></>}
    </>
  );
};

export default ChatComponent;