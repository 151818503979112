import React, {  useEffect, useState } from 'react';
import { useManagePromptCard } from '../Utility/ManagePromptCardContext';
import NumberOfRecordsComponent from './NoofRecords';
import { Header } from '../Interfaces/ReusableTable';
import ReusableTable from './ReusableTable';
import { useTableContext } from '../Utility/ReusableTableContext';
import { ModelConfig } from '../Interfaces/LLMConfigGrid';

interface PromptHistoryItem {
  promptName: string;
  ModifiedBy: string;
  DateTime: string;
  promptID: string;
  EditedTo: string;
}

interface ActivityModalProps {
  handlePromptHistory:any;
  arr:any[];
  handleRestore:any;
}

const ActivityModal: React.FC<ActivityModalProps> = ({
  arr,
  handlePromptHistory,
  handleRestore,
}) => {
  const [sortname, setSortname] = useState("promptName");
  const [sortvalue, setSortvalue] = useState("ASC");
  const [promptSort, setPromptSort] = useState(true);
  const [modifiedBy, setModifiedBy] = useState(false);
  const { data, setData, sortConfig, setSortConfig} = useTableContext<ModelConfig>();
  const [timeStamp, setTimeStamp] = useState(false);
  const {promptCards}=useManagePromptCard()
  const sortPromptName = () => {
    setSortname("promptName");
    setSortvalue(promptSort ? "DESC" : "ASC");
    setPromptSort(!promptSort);
  };

  useEffect(()=>{
    setData(arr)
  })

  const headers: Header<PromptHistoryItem>[] = [
    {
      key: 'promptName',
      label: 'Prompt',
      sortable: true,
    },
    {
      key: 'ModifiedBy',
      label: 'Modified by',
      sortable: true,
    },
    {
      key: 'DateTime',
      label: 'Time Stamp',
      sortable: true,
    },
    {
      key: 'actions',
      label: 'Actions',
      component: ({ row }) => (
        <>
          <button
            className="btn me-2 pe-0"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => handlePromptHistory(row.promptID)}
          >
            <img src="images/view.svg" alt="View" />
          </button>
          <button
            className="btn"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => handleRestore(row.promptID, row.EditedTo)}
          >
            <img src="images-new/watch-history-icon.svg" alt="History" />
          </button>
        </>
      ),
    },
  ];
  
  return (
    <div className="modal fade" id="Helpus-popup" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg border-0">
          <div className="modal-content p-4 border-0 bg-color">
            <div className="modal-header border-0 pt-2 pb-2 ps-2">
              <h5 className="modal-title text-color-1 font-22 font-bold">Activity</h5>
              <button type="button" className="btn-close close-icon-filter" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body pt-2 px-0 pb-0">
              <div className="table-responsive rounded-3 transparent-grp manageprompt-activity-scroll" style={{ maxHeight: "70vh", overflowY: "scroll" }}>
                  <ReusableTable<PromptHistoryItem>
                    headers={headers}
                    onRowClick={(row: PromptHistoryItem) => handlePromptHistory(row.promptID)}
                  />
              </div>
              {/* <p className="font-regular font-14 text-color-1 mt-3 mb-0">
                # of Records : <span className="font-bold font-14 text-color-9">{arr.length}</span> out of <span className="font-bold font-14 text-color-9">{arr.length}</span>
              </p> */}
              <NumberOfRecordsComponent
                shownRecords={arr.length}
                totalRecords={arr.length}
                className="mb-0"
              />
            </div>
          </div>
        </div>
      </div>
  );
};

export default ActivityModal;