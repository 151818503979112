/**
 * PS_01 to PS_08
 *  Import required modules, components, and hooks for AsamiUsageTable
 * @param -
 */
import React, { useEffect, useState } from "react";
import { fetchAsamiUsageData } from "../../Service/API";
import ModuleHeading from "../../Reusable Components/ModuleHeading";
import TextInput from "../../Reusable Components/TextInput";
import NumberOfRecordsComponent from "../../Reusable Components/NoofRecords";
import Tab from "../../Reusable Components/TabSwitch";
import ReusableTable from "../../Reusable Components/ReusableTable";
import { TableProvider, useTableContext } from "../../Utility/ReusableTableContext";
import { Header } from "../../Interfaces/ReusableTable";
import { AsamiUsageTableProps, UsageData } from "../../Interfaces/AsamiUsageTableInterface";
import Loader from "../../Reusable Components/Loader";
/**
 * PS_09
 *  User navigates to Asami Usage Table page and requests data visualization
 * @param {object} navOpen
 */
const AsamiUsageTable: React.FC<AsamiUsageTableProps> = ({ navOpen }) => {
  return (
    <TableProvider initialData={[]}>
      <AsamiUsageTableContent navOpen={navOpen} />
    </TableProvider>
  );
};

/**
 * PS_10 to PS_25
 *  Setup state hooks, define methods, and render components for AsamiUsageTableContent
 * @param {object} navOpen
 */

const AsamiUsageTableContent: React.FC<AsamiUsageTableProps> = ({ navOpen }) => {
  const { data, setData, sortConfig, setSortConfig } = useTableContext<UsageData>();
  const [page, setPage] = useState<"Project" | "Practice">("Practice");
  const [search, setSearch] = useState<string>("");
  const [recordCount, setRecordCount] = useState<number>(0);
  const [shownRecords, setShownRecords] = useState<number>(0);
  const [offset, setOffset] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const headers: Header<UsageData>[] = [
    { key: page, label: page, sortable: true },
    { key: "NoOfPeople", label: "No Of People", sortable: true  },
    { key: "AsamiUsage", label: "Asami Usage", sortable: true },
    { 
      key: "LastAccessed", 
      label: "Last Accessed", 
      sortable: true,
      component: ({ row }) => (
        <span>{convertDate(row.LastAccessed)}</span>
      )
    }
  ];


  /**
   * PS_13
   *  Convert date string to localized date format
   * @param {string} inputDate
   */
  const convertDate = (inputDate: string) => {
    const [datePart, timePart, period] = inputDate.split(' ');
    const date = new Date(datePart);
    return date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    }) + ` ${timePart} ${period}`;
  };
  /**
   * PS_09, PS_15 to PS_19
   *  Fetch data from API and update state accordingly
   * @param -
   */
  const fetchData = async () => {
    setIsLoad(true);
    const reqBody = {
      "TypeofData": page,
      sort: {
        column: sortConfig.column=="NoOfPeople"?"NoOfPeople":sortConfig.column=="AsamiUsage"?"AsamiUsage":sortConfig.column=="LastAccessed"?"LastAccessed" :sortConfig.column|| page, // Use the current page as default column
        order: sortConfig.order ||"desc"
      },
      "Search": searchTerm,
      "offsetValue": offset,
    };
    const response = await fetchAsamiUsageData(reqBody);
    setIsLoading(true);
    setIsLoad(false);
    setData(response?.data?.responseData?.Data|| []);
    setShownRecords((response?.data?.responseData?.Data.length || 0));
    setRecordCount(response?.data?.responseData?.RecordCount || 0);
  };

  /**
   * PS_11, PS_73 to PS_74
   *  Handle click event for loading more records
   * @param -
   */
  const loadMoreClick = () => {
    setOffset(offset + 1);
  };
  
  /**
   * PS_12, PS_26 to PS_27
   *  Handle tab switching between Project and Practice views
   * @param {string} type
   */
  const tabswitch = (type: any) => {
    if (page === "Practice" && page !== type) {
      setPage("Project");
      setSearchTerm("");
      setSortConfig({ column: "Project", order: 'desc' });
    } else {
      setPage("Practice");
      setSearchTerm("");
      setSortConfig({ column: "Practice", order: 'desc' });
    }
    setOffset(1);
  };

  useEffect(() => {
    fetchData();
  }, [search, sortConfig, offset]);

  return (
    <>
      {!isLoading ? (
      <Loader isLoading={!isLoading} navOpening={navOpen} />
      ) : (
        <div className="container-fluid">
          <div className="row justify-content-lg-end ms-lg-3 ms-xl-0 justify-content-center">
            <div className={navOpen === "Close" ? `col-11 me-xl-4 me-lg-3` : `col-lg-9 me-md-2 cust-me-lg-4 col-11`}>
              <div className="row">
                <div className="col-md-12">
                  <div className="mt-4 mb-3 d-grid d-md-flex justify-content-md-between align-items-center">
                    <ModuleHeading text={"Asami Usage"} tag={"h2"} className="font-bold font-20 mb-0 text-color-1">
                      <a href="/dashboard">
                        <img src="images-new/back-black-arrow.svg" alt="Back" className="me-2 icon-filter" />
                      </a>
                    </ModuleHeading>
                    <div className="mt-3 mt-md-0 d-flex align-items-center">
                      <div className="input-group transparent-grp w-100" >
                        <TextInput 
                          type={'text'} 
                          className={'form-control custom-search-input cust-search-input search-bg border-0 text-color-4 focus-none font-14 font-medium pe-0 ps-3'} 
                          placeholder={'Search'} 
                          onChange={setSearchTerm} 
                          onEnterPress={fetchData}
                        />
                        <button className="btn focus-none search-bg" type="button" onClick={() => setSearch(searchTerm)}>
                          <img src="images-new/input-search.svg" alt="input-search-icon" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="tab-content mt-2">
                    {isLoad ? (
                      <div style={{ textAlign: 'center', flex: 1, display: 'flex', justifyContent: 'center', marginTop: '-27%', marginLeft: '-120%' }}>
                        <Loader isLoading={isLoad} navOpening={navOpen} />
                      </div>
                    ) : (
                      <div className="col-md-12">
                        <div className="table-responsive rounded-3 transparent-grp">
                          <ReusableTable
                            headers={headers}
                          />
                        </div>
                        <NumberOfRecordsComponent shownRecords={shownRecords} totalRecords={recordCount} className="font-regular font-14 text-color-1 mt-3 mb-4" />
                        {(recordCount > shownRecords && recordCount > 10) && (
                          <div className="text-center mb-5">
                            <button className="btn gradient-btn border-radius-4 font-14 font-semibold py-2 px-3" type="button" onClick={loadMoreClick}>
                              <span className="color-gradient">Load More</span>
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AsamiUsageTable;