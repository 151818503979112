import React, { useState, useEffect, useRef, FC } from 'react';
import MonacoEditorComponent from "../../Reusable Components/MonacoEditor";

let index = 0;

export function set_Index_zero() {
  index = 0;
}

interface TextStreamProps {
  text: string;
}

export const TextStream: FC<TextStreamProps> = ({ text }) => {
  const [displayedText, setDisplayedText] = useState<string>('');
  const [codeSegments, setCodeSegments] = useState<{ id: number; code: string }[]>([]);
  const [isCodeSegment, setIsCodeSegment] = useState<boolean>(false);
  const intervalRef = useRef<number | null>(null);

  const typingDelay = 1; // Increased delay for visibility

  useEffect(() => {
    let localDisplayedText = displayedText;
    let localCodeSegments = [...codeSegments];
    let localIsCodeSegment = isCodeSegment;

    intervalRef.current = window.setInterval(() => {
      if (index >= text.length) {
        clearInterval(intervalRef.current!);
        return;
      }

      const nextText = text.slice(index);

      if (nextText.startsWith('```')) {
        index += 3;
        localIsCodeSegment = !localIsCodeSegment;
        setIsCodeSegment(localIsCodeSegment);
        if (localIsCodeSegment) {
          localCodeSegments.push({ id: Date.now(), code: '' });
        }
      } else {
        const currentChar = text[index];
        if (localIsCodeSegment) {
          const lastSegment = localCodeSegments[localCodeSegments.length - 1];
          if (lastSegment) {
            lastSegment.code += currentChar;
          }
        } else {
          localDisplayedText += currentChar;
        }
        index++;
      }

      setDisplayedText(localDisplayedText);
      setCodeSegments([...localCodeSegments]);
    }, typingDelay);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [text]); // Removed isCodeSegment from dependencies

  return (
    <>
      <div>{displayedText}</div>
      {codeSegments.map(segment => (
        <div key={segment.id} style={{ marginTop: '20px' }}>
          <MonacoEditorComponent editorCode={segment.code} language="" />
        </div>
      ))}
    </>
  );
};

export default TextStream;