import React, { useState, useEffect } from 'react';

const AIProcessingIndicatorCodegen = () => {
  const [dots, setDots] = useState('');
  const phrases = [
    "Analyzing the requirements",
    "Deciding the solution executor",
    "Few more minutes..."
  ];
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  
  useEffect(() => {
    // Animate dots - even faster speed (250ms for more responsiveness)
    const dotsInterval = setInterval(() => {
      setDots(prevDots => {
        if (prevDots === '...') return '';
        return prevDots + '.';
      });
    }, 250);
    
    // Cycle through phrases - smoother animation
    const phraseInterval = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentPhraseIndex(prev => (prev + 1) % phrases.length);
        setTimeout(() => {
          setIsTransitioning(false);
        }, 100); // Slightly delay the fade-in for smoother effect
      }, 150); // Quick fade transition
    }, 2000);
    
    return () => {
      clearInterval(dotsInterval);
      clearInterval(phraseInterval);
    };
  }, []);

  const styles = {
    container: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      padding: '8px',
      // gap: '12px' // Add space between image and text
    },
    image: {
      width: "60px", 
      height: "100%"
    },
    text: {
      marginTop: '2.5vh',
      color: '#535353', // Updated to a slightly different purple
      // fontWeight: '600', // Increased font weight for a bolder appearance
      fontSize: '14px',
      fontFamily: 'SF Pro Text Medium', // More modern fonts
      letterSpacing: '0.02em', // Increased letter spacing slightly
      opacity: isTransitioning ? '0.2' : '1', // More dramatic fade
      transition: 'opacity 180ms cubic-bezier(0.4, 0, 0.2, 1)', // Smoother easing function
      transform: isTransitioning ? 'translateY(3px)' : 'translateY(0)', // Subtle movement during transition
      transitionProperty: 'opacity, transform', // Transition both properties
      transitionDuration: '180ms' // Faster transition
    }
  };

  return (
    <div style={styles.container}>
      <img
        style={styles.image}
        src="images/ai-images/Bot_profile.svg"
        alt="bot-happy"
        className="chat-bot"
      />
      <div style={styles.text}>
        {phrases[currentPhraseIndex]}<span>{dots}</span>
      </div>
    </div>
  );
};

export default AIProcessingIndicatorCodegen;