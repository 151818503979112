import React, { createContext, useState, ReactNode } from 'react';
import { SortConfig, TableContextType } from '../Interfaces/ReusableTable';

// Create a context for the table with default values
export const TableContext = createContext<TableContextType<any>>({
  data: [],
  setData: () => {},
  sortConfig: { column: '', order: null },
  setSortConfig: () => {},
  selectedRows: [],
  handleRowSelection: () => {},
  detailedViewData: null,
  setDetailedViewData: () => {},
  isModalOpen: false,
  setIsModalOpen: () => {},
});

// Interface for the TableProvider props
interface TableProviderProps<T> {
  children: ReactNode;
  initialData?: T[];
}

export function TableProvider<T>({ children, initialData = [] }: TableProviderProps<T>) {

  // State to manage the table data
  const [data, setData] = useState<any[]>([]);
  const [sortConfig, setSortConfig] = useState<SortConfig>({ column: '', order: null });
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [detailedViewData, setDetailedViewData] = useState<T | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to handle row selection
  const handleRowSelection = (index: number) => {
    setSelectedRows(prev => 
      prev.includes(index) ? prev.filter(i => i !== index) : [...prev, index]
    );
  };

  // Provide the context value to all children components
  return (
    <TableContext.Provider value={{
      data,
      setData,
      sortConfig,
      setSortConfig,
      selectedRows,
      handleRowSelection,
      detailedViewData,
      setDetailedViewData,
      isModalOpen,
      setIsModalOpen,
    }}>
      {children}
    </TableContext.Provider>
  );
}

export const useTableContext = <T,>(): TableContextType<T> => {

  const context = React.useContext(TableContext);

  // Throw an error if the hook is used outside of a TableProvider
  if (context === undefined) {
    throw new Error('useTableContext must be used within a TableProvider');
  }

  // Return the context value
  return context as TableContextType<T>;
};