import React, { useEffect, useState, useRef } from 'react';
import { fetchPlannerData } from '../../../Service/API';
import { useHeaderContext } from '../../../Utility/HeaderContext';
import { useTheme } from '@mui/material/styles';
import io from 'socket.io-client';
import { UrlsAi } from '../../../Service/API-Constants';
import YesOrNoPopup from '../../../Reusable Components/YesOrNoPopup';

// Define interfaces for the new data structure
interface CoreLogic {
  file_name: string;
  function_name: string;
  core_logic: string;
  called_file_info: {
    called_file_name: string;
    called_functions: string;
  }[];
}

interface PlannerItem {
  id: number;
  data: {
    pseudocode: string;
    core_logic: CoreLogic;
  };
}

interface PlannerData {
  agent_id: string;
  project_id: number;
  user_id: string;
  data: PlannerItem[];
  chat_type: string;
  question: string;
  length?:any;
}

// Interface for the edited content structure
interface EditedItem {
  id: number;
  file_name: string;
  function_name: string;
  core_logic: string;
  isModified: boolean; // Track whether this item has been modified
}

interface PlannerProps {
  plannerData?: PlannerData;
  socketInfo: any;
  setRightNavOpen ? : any;
  setPlannerData?: any;
  active?:any;
}


interface Item {
  id: number;
  file_name: string;
  function_name: string;
  core_logic: string;
  isModified: boolean;
}

const PlannerComponent: React.FC<PlannerProps> = ({ plannerData, socketInfo , setRightNavOpen , setPlannerData,active}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState<EditedItem[]>([]);
  const [tempContent, setTempContent] = useState<any>([]);
  const [plannerDataState, setPlannerDataState] = useState<PlannerData | null>(plannerData || null);
  const [sampleplannerData,setsampleplannerData]= useState<PlannerData | null>(plannerData || null);
  const [textColor, setTextColor] = useState<string>('#000000');
  const [backgroundColor, setBackgroundColor] = useState<string>('#ffffff');
  const plannerRef = useRef<HTMLDivElement>(null);
  const [isPlannerFromProps, setIsPlannerFromProps] = useState<boolean>(false)
  const theme = useTheme();
  const { activeComponent, setActiveComponent } = useHeaderContext();
  const [showPopUp,setShowPopUp]=useState(false)
  const [isLoading,setIsLoading] = useState<boolean>(false)
  

  const getPlannerData = async () => {
    let requestBody = {
      "user_id": sessionStorage.getItem("user_id"),
      "project_id": sessionStorage.getItem("project_id")
    }
    let response = await fetchPlannerData(requestBody);
    return response;
  }
  const sortItemsByFileName = (items: Item[]): Item[] => {
    return items.sort((a, b) => a.file_name.localeCompare(b.file_name));
  };
  // Transform the new data structure to our editable format
  const transformData = (data: PlannerData | null): EditedItem[] => {
    if (!data || !data.data) return [];
    
    return data.data.map(item => ({
      id: item.id,
      file_name: item.data.core_logic.file_name,
      function_name: item.data.core_logic.function_name,
      core_logic: item.data.core_logic.core_logic,
      isModified: false // Initially, nothing is modified
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      if (plannerData) {
        setIsPlannerFromProps(true);
        setPlannerDataState(plannerData);
        setEditedContent(sortItemsByFileName(transformData(plannerData)));
        setTempContent(plannerData.data);
      } else {
        setIsPlannerFromProps(false);
        try {
          setIsLoading(true)
          const response = await getPlannerData();
          setPlannerDataState(response);
          setEditedContent(sortItemsByFileName(transformData(response)));
          setTempContent(response.data);
          setIsLoading(false)
        } catch (error) {
          console.error('Error fetching planner data:', error);
          // Handle the error appropriately
        }
      }
    };
  
    fetchData();
  }, [plannerData]);

  useEffect(() => {
    const updateColors = () => {
      if (plannerRef.current) {
        const card = plannerRef.current.closest('.custom-dashboard-section');
        if (card) {
          const bgColor = window.getComputedStyle(card).backgroundColor;
          setBackgroundColor(bgColor);
          const rgb = bgColor.match(/\d+/g);
          if (rgb) {
            const brightness = (parseInt(rgb[0]) * 299 + parseInt(rgb[1]) * 587 + parseInt(rgb[2]) * 114) / 1000;
            setTextColor(brightness > 128 ? '#000000' : '#ffffff');
          }
        }
      }
    };
  
    const resizeObserver = new ResizeObserver(updateColors);
  
    if (plannerRef.current) {
      const card = plannerRef.current.closest('.custom-dashboard-section');
      if (card) {
        resizeObserver.observe(card);
      }
    }
  
    updateColors();
  
    return () => resizeObserver.disconnect();
  }, [theme]);

  const handleEdit = () => {
    setIsEditing(true);
    // Deep clone the content to avoid reference issues
    setTempContent(JSON.parse(JSON.stringify(editedContent)));
  };

  const handleCancel = () => {
    
    setIsEditing(false);
    // const socket = io('https://devapp.asami.ai');
    // // socket.emit('user_response', {
    // //   question_id: socketInfo.question_id,
    // //   response: [],
    // //   user_id: socketInfo.user_id || '',
    // //   project_id: socketInfo.project_id
    // // });
    if(plannerData)
    setEditedContent(sortItemsByFileName(transformData(plannerData)))
    // setActiveComponent("")
  };

  const handleSave = () => {
    setIsEditing(false);
    // Get only the modified items
    const modifiedItems = editedContent.filter(item => item.isModified);
    
    // Format data for sending back through socket - only the modified items
    const formattedData = modifiedItems.map(item => {
      // Find the original item to preserve any other data
      const originalItem = plannerDataState?.data.find(d => d.id === item.id);
      
      if (!originalItem) return null;
      
      // Create a deep copy to avoid mutations
      const newItem = JSON.parse(JSON.stringify(originalItem));
      
      // Update the edited fields
      newItem.data.core_logic.file_name = item.file_name;
      newItem.data.core_logic.function_name = item.function_name;
      newItem.data.core_logic.core_logic = item.core_logic;
      
      return newItem;
    }).filter(Boolean);
      setActiveComponent("")
      const socket = io(UrlsAi.baseurl);
      socket.emit('user_response', {
        question_id: socketInfo.question_id,
        response: formattedData,
        user_id: socketInfo.user_id || '',
        project_id: socketInfo.project_id
      });
      setPlannerData("")
      active("message")
    
  };

  const handleContentChange = (id: number, field: keyof Omit<EditedItem, 'id' | 'isModified'>, value: string) => {
    const newEditedContent = editedContent.map(item => {
      if (item.id === id) {
        // Check if the value is actually different from the original
        const originalItem = tempContent.find((temp:any)=> temp.id === id);
        const isValueChanged = originalItem ? originalItem[field] !== value : true;
        
        // Update all fields and mark as modified if any field has changed
        const updatedItem = { ...item, [field]: value };
        
        // Check if any field is different from the original to determine if this item is modified
        if (originalItem) {
          const isAnyFieldChanged = 
            updatedItem.file_name !== originalItem.file_name ||
            updatedItem.function_name !== originalItem.function_name ||
            updatedItem.core_logic !== originalItem.core_logic;
          
          updatedItem.isModified = isAnyFieldChanged;
        }
        
        return updatedItem;
      }
      return item;
    });
    
    setEditedContent(sortItemsByFileName(newEditedContent));
  };

  return (
    <div className="row justify-content-center custom-dashboard-section" ref={plannerRef}>
      <div className="toggle-right-panel gap-2">
        <div className="custom-tab-content-section">
          <div className="custom-right-side-bar-section-contents" style={{ backgroundColor, color: textColor }}>
            <div className="custom-right-side-bar-section-title">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-center gap-2">
                  <h2 className="font-semibold font-14" style={{ color: textColor }}>Planner</h2>
                  {/* {isPlannerFromProps && (
                    <button className="custom-edit-btn" onClick={handleEdit}>
                      <img src={backgroundColor === '#ffffff' ? "../images/Edit-icon-dark-theme.svg" : "../images/Edit-icon-white-theme.svg"} alt="Edit-icon" />
                    </button>
                  )} */}
                </div>
                {isPlannerFromProps && editedContent.length>0  && (
                  <div className="d-flex align-items-center gap-2">
                    <button
                      type="button"
                      className="btn outline-btn bg-transprent font-14 font-semibold px-4 border-radius-5"
                      onClick={()=>setShowPopUp(true)}
                      style={{ color: textColor, borderColor: textColor }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn primary-btn font-14 font-semibold border-radius-5 color-white px-4"
                      onClick={handleSave}
                    >
                      Save
                    </button>
                  </div>
                )}
              </div>
            </div>
            { isLoading ? (
                              <div className="card-shimmer">
                                <div className="shimmer-title"></div>
                                <div className="shimmer-image"></div>
                                <div className="shimmer-text"></div>
                                <div className="shimmer-text"></div>
                                <div className="shimmer-title"></div>
                                <div className="shimmer-image"></div>
                                <div className="shimmer-wrapper">
                                    <div className="shimmer"></div>
                                </div>
                              </div>
                            ) : 
            <div className="custom-right-side-bar-section-details chat-box-planner-container">
              {editedContent && editedContent.map((item) => (
                <div key={item.id} style={{ color: textColor, marginBottom: '20px' }}>
                  {isPlannerFromProps ? (
                    <div className="planner-edit-container core-logic-container">
                      <div className="form-group mb-2 ">
                        <label className="font-semibold" style={{ color: textColor }}>File Name:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={item.file_name}
                          onChange={(e) => handleContentChange(item.id, 'file_name', e.target.value)}
                          style={{ color: textColor, backgroundColor: 'transparent', border: "none" }}
                        />
                      </div>
                      <div className="form-group mb-2">
                        <label className="font-semibold" style={{ color: textColor }}>Function Name:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={item.function_name}
                          onChange={(e) => handleContentChange(item.id, 'function_name', e.target.value)}
                          style={{ color: textColor, backgroundColor: 'transparent', border: "none" }}
                        />
                      </div>
                      <div className="form-group">
                        <label className="font-semibold" style={{ color: textColor }}>Core Logic:</label>
                        <textarea
                          className="form-control"
                          value={item.core_logic}
                          onChange={(e) => {
                            handleContentChange(item.id, 'core_logic', e.target.value);
                            // Auto-resize the textarea
                            e.target.style.height = 'auto';
                            e.target.style.height = `${e.target.scrollHeight}px`;
                          }}
                          onFocus={(e) => {
                            // Set initial height on focus
                            e.target.style.height = 'auto';
                            e.target.style.height = `${e.target.scrollHeight}px`;
                          }}
                          ref={(el) => {
                            // Store reference to resize on initial render
                            if (el) {
                              setTimeout(() => {
                                el.style.height = 'auto';
                                el.style.height = `${el.scrollHeight}px`;
                              }, 0);
                            }
                          }}
                          rows={4}
                          style={{ 
                            color: textColor, 
                            backgroundColor: 'transparent', 
                            border: "none",
                            minHeight: '100px',
                            resize: 'none',
                            overflow: 'hidden'
                          }}
                        />
                      </div>
                      {item.isModified && (
                        <div className="text-right mt-2">
                          <span style={{ fontSize: '12px', color: '#f59e0b' }}>Modified</span>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="planner-view-container">
                      <p>
                        <b style={{ color: textColor }}>File Name:</b> <span style={{ color: textColor }}>{item.file_name}</span>
                      </p>
                      <p>
                        <b style={{ color: textColor }}>Function Name:</b><span style={{ color: textColor }}> {item.function_name}</span>
                      </p>
                      <p  style={{ color: textColor, paddingBottom:"19px" }}>
                        <b style={{ color: textColor }}>Core Logic:</b><span style={{ color: textColor }}> {item.core_logic}</span>
                      </p>
                    </div>
                  )}
                </div>
              ))}
              {
                editedContent.length==0 && (
                  <>
                    <div className="custom-right-side-bar-section-details norecords">
                        <img src="../images/no-records-graphics.svg" alt="graphic" width="500px" height={"250px"}/>
                        <p className="mb-0 font-medium font-14 mt-2 text-color-9" style={{"textAlign":"center"}}>No Data Found</p>
                    </div>
                  </>
                )
              }

              {showPopUp && (
                      <YesOrNoPopup
                        type="planner"
                        
                        onConfirm={() => { handleCancel(); setShowPopUp(false) }}
                        onCancel={() => { setShowPopUp(false) }}

                      />
                    )}
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlannerComponent;