
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonComponent from '../../../Reusable Components/Button';
import FilterComponent from '../../../Reusable Components/Filter';
import ModuleHeading from '../../../Reusable Components/ModuleHeading';
import NumberOfRecordsComponent from '../../../Reusable Components/NoofRecords';
import ReusableTable from '../../../Reusable Components/ReusableTable';
import { ConfigPopup } from '../../../Reusable Components/ConfigPopUp';
import TextInput from '../../../Reusable Components/TextInput';
import { useManageIssues, ManageIssuesProvider } from '../../../Utility/ManageIssueContext';
import { DropDownProvider } from '../../../Utility/DropDownContext';
import DropDownComponent from '../../../Reusable Components/DropDown';
import InputComponent from '../../../Reusable Components/TextInput';
import Loader from '../../../Reusable Components/Loader';
import { useEffect } from 'react';
interface ManageTasksContentProps {
  navOpen: any;
}

const ManageIssuesContent: React.FC<ManageTasksContentProps> = ({ navOpen }) => {
  const {
    issues,
    isLoading,
    totalRecords,
    showConfigPop,
    editScenario,
    url,
    PAT,
    issueValueID,
    setValueIssueID,
    setSearchValue,
    handleLoadMore,
    handleFilterApply,
    handleFilterCancel,
    handleIssueClick,
    tableHeaders,
    setShowConfigPop,
    setloaddata,
    loaddata

  } = useManageIssues();

  const navigate = useNavigate();
  const projectState = JSON.parse(sessionStorage.getItem("projectState") || "")
  const projectIdFromMP = projectState['projectIdFromMP'] || "";
  const projectID = projectState['project.projectID'] || "";
  const projectName = projectState['projectName'];
  const imageurl = projectState["ProjectImage"]

  const dropdownOptions = [
    { value: 'task', label: 'View by Task' },
    // { value: 'story', label: 'View by Story' },
    // { value: 'backlog', label: 'View by BackLog' },
    // { value: 'epic', label: 'View by Epic' },
    // { value: 'story', label: 'View by Story' }, // Uncomment if needed
  ];



  const handleDropdownChange = (selectedOption: any) => {
    if (selectedOption.value === 'task') {
      navigate('/manage-task-project', {
        state: {
          projectIdFromMP: projectID,
        }
      });
    }
    else if (selectedOption.value === 'story') {
      navigate('/manage-story-project', {
        state: {
          projectIdFromMP: projectID,
        }
      });
    }
    else if (selectedOption.value === 'epic') {
      navigate('/manage-epic-project', {
        state: {
          projectIdFromMP: projectID,
        }
      });
    }
    else if (selectedOption.value === 'backlog') {
      navigate('/manage-backlog-project', {
        state: {
          projectIdFromMP: projectID,
        }
      });
    }
  }
  return (
    <>
      {isLoading ? (
        <Loader isLoading={isLoading} navOpening={navOpen} />
      ) : (
        <>
          <div className="container-fluid">
            <div className="row justify-content-lg-end ms-lg-3 ms-xl-0 justify-content-center">
              <div className={navOpen == "Close" ? `col-11 me-xl-4 me-lg-3` : `col-lg-9 me-md-3 me-lg-4 me-xl-5 col-11`}>
                <div className="row">
                  <h1 className="color-black-v1 font-24 font-bold mt-5 mb-4 d-flex align-items-center" >
                    <a onClick={() => navigate("/manage-project")}>
                      <img
                        src="images-new/back-black-arrow.svg"
                        className="me-3 mb-1 icon-filter"
                        alt="back-arrow"
                        title="Back"
                        style={{ cursor: "pointer" }}
                      />
                    </a>
                    <span className='text-color-1'>
                      <img src={imageurl || ""} alt="Profile"
                        className="me-2 mb-1"
                        style={{ width: '50px' }} />
                      {projectName}
                    </span>

                  </h1>
                  <div className="col-md-12 px-4 pe-lg-0 pe-2 pt-3">


                    <div className="d-flex justify-content-between align-items-center mb-4 w-100">
                      <div>
                      <h2 className="font-bold font-20 mb-0 text-nowrap text-color-1" >
                        Manage Issues ({issues.length})
                      </h2>
                      </div>
                      <div className='d-flex justify-content-between'>
                      <div className="p-2 px-3 border-radius-35 d-flex justify-content-between me-3 mt-3 mt-md-0 search-bg border-color-10">
                        <ModuleHeading text='Integrated with' tag="span" className="flex-nowrap text-nowrap text-color-9" />
                        <img src="./images-new/avalon-v2.svg" alt="icon" className="ms-2" />
                      </div>

                      {/* <div className="input-group transparent-grp w-225">
                        <TextInput
                          type="text"
                          className="form-control custom-search-input text-color-4 border-0 bg-transparent focus-none font-14 font-medium pe-0 ps-3"
                          placeholder="Search"
                          value={issueValueID}
                          onChange={(value: string) => { setValueIssueID(value) }}
                          onEnterPress={() => { setSearchValue(issueValueID) }}
                        />
                        <ButtonComponent buttonType='icon' className='focus-none search-bg' onClick={() => { setSearchValue(issueValueID) }} iconSrc='images/input-search.svg' altText='input-search-icon'></ButtonComponent>
                      </div>
                       */}




                      <div className="table-header d-flex align-items-center">
                        <div className="input-group transparent-grp w-225 me-3 m-0 search-bg" style={{ marginRight: "5%", width: "8vw" }}>
                          <InputComponent
                            type="text"
                            className="form-control custom-search-input text-color-4 bg-transparent border-0 focus-none font-14 font-medium pe-0 ps-3 text-nowrap"
                            placeholder="Search"
                            value={issueValueID}
                            onChange={(value: string) => { setValueIssueID(value) }}
                            onEnterPress={() => setSearchValue(issueValueID)}
                          />
                             <ButtonComponent
                              buttonType="icon"
                              iconSrc="images-new/input-search.svg"
                              altText="input-search-icon"
                              className="btn focus-none border-0"
                              onClick={(e) => {
                                setSearchValue(issueValueID)
                              }}
                              style={{ height: '100%', width: 'auto' }}
                            />
                        </div>
                      </div>



                      {/* <div style={{marginRight: "1%"}}> */}
                      <FilterComponent
                        onApply={handleFilterApply}
                        onCancel={handleFilterCancel}
                        config={{
                          dropdowns: {
                            status: {
                              options: ['Open', 'Proposed', 'Active', 'Resolved'].map(status => ({ value: status, label: status })),
                              label: 'Status'
                            }
                          },
                          showDateRange: true
                        }}
                      />
                      {/* </div> */}


                      <DropDownProvider isMulti={false}>
                        <div>
                          <DropDownComponent
                            customUI={{
                              buttonText: "Issue",
                              iconSrc: "images-new/task-icon.svg",
                              dropdownItems: [
                                {
                                  value: "task",
                                  text: "Task",
                                  iconSrc: "images-new/task-icon.svg"
                                }
                              ]
                            }}
                            projectID={projectID}
                            navigate={navigate}
                          />
                        </div>
                      </DropDownProvider>

                    </div>
                    </div>

                    <ReusableTable
                      headers={tableHeaders}
                      onRowClick={handleIssueClick}
                    />

                    <NumberOfRecordsComponent
                      shownRecords={issues.length}
                      totalRecords={totalRecords}
                    />

                    <div className="text-center mb-5">
                      {totalRecords > issues.length && (
                        <ButtonComponent
                          buttonType="text"
                          text={<span className="color-gradient">Load More</span>}
                          className="gradient-btn border-radius-4 font-14 font-semibold py-2 px-3"
                          style={{ color: "#865BE7", marginLeft: "2px", marginTop: "4px" }}
                          onClick={handleLoadMore}
                        />
                      )}
                    </div>

                    {showConfigPop && (
                      <DropDownProvider isMulti={false}>
                        <ConfigPopup
                          projectIdFromMP={projectIdFromMP}
                          TypeOfData={editScenario as "config" | "update"}
                          setShowPopUp={setShowConfigPop}
                          typeOfGrid="issue"
                          uniqueIdFromMP={sessionStorage.getItem('uniqueIdFromLocal') || ''}
                          url={url}
                          PAT={PAT}
                          setloaddata={setloaddata}
                          loaddata={loaddata}
                        />
                      </DropDownProvider>
                    )}


                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const ManageIssues: React.FC<{ navOpen: boolean }> = ({ navOpen }) => (
  <ManageIssuesProvider>
    <ManageIssuesContent navOpen={navOpen} />
  </ManageIssuesProvider>
);

export default React.memo(ManageIssues);