import React, { createContext, useContext, useState, ReactNode, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { DropResult } from 'react-beautiful-dnd';

import { PromptBuilderContextType, PromptFieldData, PromptMessageData, PromptDataType, InputType, Prompt, Task, ModelData, PromptLogo } from '../Interfaces/ManagePrompt';
import UserContext from "../Auth/UserContext";
import { useManagePromptCard } from './ManagePromptCardContext';
import { getBindParams, promptGen, systemMessageGen, getInputTypeAndTechStack, deletePrompt, fetchPrompt, insertPrompt } from "../Service/API";


const PromptBuilderContext = createContext<PromptBuilderContextType | undefined>(undefined);

export const PromptBuilderProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

  const { showPage, setShowPage } = useManagePromptCard();
  const userDetails = useContext(UserContext);
  const location = useLocation();
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();

  // State for managing UI and user interactions
  const [isCopied, setIsCopied] = useState(false);
  const [removeOption, setRemoveOption] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [inputTypeData, setInputTypeData] = useState<any>();
  const [show, setShow] = useState(true);
  const [choices, setChoices] = useState("radio");
  const [promptMessagePage, setPromptMessagePage] = useState("");
  const [flag, setflag] = useState(0);
  const [tasks, setTasks] = useState<any[]>([]);
  const [submitType, setSubmitType] = useState("");
  const [promptCard, setPromptCard] = useState<any[]>([]);
  const { promptIndex, setPromptIndex } = useManagePromptCard();
  const [showPopup, setShowPopup] = useState(false);
  const [popUpText, setPopUpText] = useState("");
  const [fieldIndex, setFieldIndex] = useState<any>();
  const [editTaskIndex, setEditTaskIndex] = useState<number | null>(null);
  const [userData, setuserData] = useState({});
  const [addOption, setAddOption] = useState(2);
  const [checkbox, setCheckbox] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [required, setRequired] = useState(false);
  const [suma, setSuma] = useState("");
  const [longAnswer, setLongAnswer] = useState(false);
  const [error, setError] = useState("");
  const [editChoices, setEditChoices] = useState<any[]>([]);
  const [conditionData, setConditionData] = useState<any[]>([]);
  const [promptLogoData, setPromptLogoData] = useState("");
  const [userEmailId, setUserEmailId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  let logoObj = { logoPath: "", logoName: "", logoID: "" }
  const [promptLogoValues, setPromptLogoValues] = useState(logoObj);
  const [hoveredInput, setHoveredInput] = useState<any>(null);
  const [hiddenIndices, setHiddenIndices] = useState<number[]>([]);
  const [modelData, setModelData] = useState<any>("");
  const [isWebSearch, setIsWebSearch] = useState(false);
  const { practiceID, setPracticeID } = useManagePromptCard();
  const [allKbFiles, setAllKnFiles] = useState<any[]>([]);
  const [selectedModelConfigurationID, setSelectedModelConfigurationID] = useState('');
  const [collection_name, setcollection_name] = useState('');
  const [indexName, setIndexName] = useState("");
  const [promptName, setPromptName] = useState("");
  const [promptID, setPromptID] = useState("");
  const [editedPromptID, setEditedPromptID] = useState("");
  const [isKbRetriever, setIsKbRetriever] = useState(false);
  const [selectedModelName, setSelectedModelName] = useState("");
  const [kbRetrieverUrl, setKbRetrieverUrl] = useState<any>();
  const [modelError, setModelError] = useState("");
  const { handlePromptHistory: HandlePromptHistory, setHandlePromptHistory } = useManagePromptCard();
  const [exampleVal, setExampleVal] = useState<any[]>([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [fewShotValues, setFewShotValues] = useState<any[]>([]);
  const [outputDescription, setOutputDescription] = useState("");
  const [inputArray, setInputArray] = useState<any[]>([]);
  const [userContent, setUserContent] = useState("");
  const [assistantContent, setAssistantContent] = useState("");
  const [updatedPromt, setUpdatedPrompt] = useState("");
  const userRef = useRef<any>();
  const assistantRef = useRef<any>();
  const [enhancedSystemMessage, setEnhancedSystemMessage] = useState("");
  const [heartBeat, setHeartBeat] = useState(false);
  const [showInputDD, setShowInputDD] = useState(false);
  const [selectedInputs, setSelectedInputs] = useState<any[]>([]);
  const [tempUpdatePrompt, setTempUpdatedPrompt] = useState("");
  const [askAsamiKbRetriever, setAskAsamiKbRetriever] = useState(false);
  const [disableEdit, setDisableEdit] = useState(false);
  const [aiReq, setAiReq] = useState(false);
  const [hitAiVar, sethitAiVar] = useState(0);
  const [disableKBEdit, setDisableKBEdit] = useState(false);
  const [collectionNameFix, setCollectionNameFix] = useState('');
  const [displayQuestion, setDisplayQuestions] = useState(false);
  const [practiceName, setPracticeName] = useState("");
  const [toggleDropDown, setToggleDropDown] = useState("down");
  const [editQuestion, setEditQuestion] = useState(false);
  const [tempFlag, setTempFlag] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [inputValue, setInputValue] = useState<any[]>([]);
  const [knowledgeData, setKnowledgeData] = useState<any[]>([]);
  const [kbUrl, setKbUrl] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [description, setDescription] = useState('');
  const [isOpenCustom, setIsOpenCustom] = useState(false);
  const [selectedOptionVal, setSelectedOptionVal] = useState(false);

  // Function to handle copying text to clipboard
  const handleCopyClick = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    }).catch(err => console.error('Failed to copy: ', err));
  };

  // Function to handle option selection
  const handleOptionClick = (option: any) => {
    setSelectedOptionVal(option);
    setIsOpenCustom(false);
    setDescription('');
  };

  // Object for prompt field data
  const promptFieldObj: PromptFieldData = {
    labelName: "",
    modifiedBy: userEmailId,
    inputTypeID: "",
    choiceName: [],
    inputTypeRefCode: "",
    promptFieldOrder: "",
    placeHolder: "Enter the value",
    errorMessage: "",
    required: "false",
    hitAI: hitAiVar,
    inputValueOrder: "",
    uniqueField: 0,
    createdBy: "",
    promptFieldID: ""
  };

  // Object for prompt message data
  const promptMessageObj: PromptMessageData = {
    promptMessageOrder: 1,
    modifiedBy: userEmailId,
    promptMessage: "",
    outputDesc: "",
    inputArr: inputArray,
    inputKey: "",
    inputValue: "",
    inputArray: [],
    conditionID: "",
    isKbRetriever: false,
    modelConfigurationID: "",
    promptMessageID: "",
    isWebSearch: false,
    webContent: "",
    oldPrompt: "",
    outputDescription: "",
    fewShotExamples: [],
    createdBy: "",
    draft: 0,
  };

  // State for managing prompt field and message data
  const [PF, setPF] = useState<PromptFieldData[]>([]);
  const [promptFieldData, setPromptFieldData] = useState(promptFieldObj);
  const [promptMessageData, setPromptMessageData] = useState(promptMessageObj);
 

  // Object for prompt data
  const promptObj = {
    promptID: "",
    promptName: "",
    promptDescription: "",
    promptLogoID: "",
    systemMessage: "",
    enhancedSystemMessage: enhancedSystemMessage,
    editedPromptID: editedPromptID,
    modifiedBy: userEmailId,
    kbFiles: [],
    kbUrl: [],
    collectionName: collection_name,
    indexName: "",
    practiceID: practiceID,
    createdBy: userEmailId,
    draft: 0,
    promptFields: PF,
    promptMessages: inputValue,
  };

  // State for managing prompt data
  const [promptData, setPromptData] = useState<PromptDataType>(promptObj);

  // Object for error data
  const errorObj = {
    promptNameError: "",
    labelNameError: "",
    choicesError: "",
    promptLogoIDError: "",
  };

  // State for managing error data
  const [errorData, setErrorData] = useState(errorObj);

  // Fetch user details and bind params when userDetails changes
  useEffect(() => {
    if (userDetails) {
      setUserEmailId(userDetails?.email || '');
      setuserData(userDetails);
      fetchBindParams();
      // fetchPromptData();
    }
  }, [userDetails]);

  // Update promptData when practiceID, enhancedSystemMessage, or editedPromptID changes
  useEffect(() => {
    setPromptData(prevData => ({
      ...prevData,
      practiceID: practiceID,
      enhancedSystemMessage: enhancedSystemMessage,
      editedPromptID: editedPromptID,
      modifiedBy: ""
    }));
  }, [practiceID, enhancedSystemMessage, editedPromptID]);

  // Update promptData when enhancedSystemMessage changes
  useEffect(() => {
    setPromptData(prevData => ({
      ...prevData,
      enhancedSystemMessage: enhancedSystemMessage,
      modifiedBy: ""
    }));
  }, [enhancedSystemMessage]);

  // Fetch input type and tech stack on component mount
  useEffect(() => {
    fetchInputTypeAndTechStack();
    setPracticeID(practiceID);
  }, []);

  const isAuth = (accounts: any[]): boolean => {
    return accounts && accounts.length > 0;
  };
  // Redirect to home page if user is not authenticated
  useEffect(() => {
    if (!isAuth(accounts)) {
      navigate("/");
    }
  }, [accounts]);

  // Update promptData and related states when promptIndex changes
  useEffect(() => {
    if (showPage === "showManagePrompt" && promptIndex) {
      let editObj = { ...promptIndex };
      let updatedPromptData = { ...editObj };

      if (!promptData.kbUrl || promptData.kbUrl.length <= 0) {
        let kbFilesArray = [];
        if (editObj.kbFiles) {
          try {
            kbFilesArray = Array.isArray(editObj.kbFiles) ? editObj.kbFiles : JSON.parse(editObj.kbFiles);
          } catch (error) {
            console.error("Error parsing kbFiles:", error);
          }
        }

        let kbUrl = [];
        if (editObj.kbUrl) {
          try {
            kbUrl = Array.isArray(editObj.kbUrl) ? editObj.kbUrl : JSON.parse(editObj.kbUrl);
          } catch (error) {
            console.error("Error parsing kbUrl:", error);
          }
        }

        updatedPromptData = {
          ...editObj,
          kbFiles: kbFilesArray,
          kbUrl: kbUrl
        };

        setKnowledgeData(kbFilesArray);
        setKbRetrieverUrl(kbUrl);
      } else {
        // Preserve existing kbFiles and kbUrl in promptData
        updatedPromptData = {
          ...editObj,
          kbFiles: promptData.kbFiles,
          kbUrl: promptData.kbUrl
        };
      }
      const jsonFileString = promptIndex.kbFiles
      sessionStorage.setItem('promptKbFile',jsonFileString)
      setPromptData(updatedPromptData);
      setPromptID(editObj.promptID);
      setSubmitType("SD");
      setPromptName(editObj.promptName);
      setPF(editObj.promptFields);
      setCollectionNameFix(editObj.collectionName);

      try {
        const newPromptMessages = editObj.promptMessages.map((message: any) => ({
          ...message,
          inputArray: JSON.parse(message?.inputArray !== null && message?.inputArray != "None" && message?.inputArray.length > 1 && message.inputArray != undefined ? message.inputArray : "[]")
        }));
        setTasks(newPromptMessages);
      } catch (error) {
        const newPromptMessages = editObj.promptMessages.map((message: any) => ({
          ...message,
          inputArray: []
        }));
        setTasks(newPromptMessages);
        console.error(error);
      }

      setPromptLogoValues({
        ...promptLogoValues,
        logoID: editObj?.promptLogoID,
        logoName: editObj?.promptLogoName,
        logoPath: editObj?.promptLogoPath,
      });
    }
  }, [promptIndex]);

  // Empty effect for submitType (no logic implemented)
  useEffect(() => {
    // This effect is empty in the original code
  }, [submitType]);

  // Navigate to manage prompt page if coming from back button
  useEffect(() => {
    if (location.state && location.state.fromBackButton) {
      navigate("/asami-manage-prompt");
      // window.location.reload();
    }
  }, [location, navigate]);

  // Update promptData when collection_name, indexName, editedPromptID, or enhancedSystemMessage changes
  useEffect(() => {
    setPromptData(prevData => ({
      ...prevData,
      collectionName: collection_name,
      indexName: indexName,
      modifiedBy: "",
      editedPromptID: editedPromptID,
      enhancedSystemMessage: enhancedSystemMessage
    }));
  }, [collection_name, indexName, editedPromptID, enhancedSystemMessage]);

  // Handle adding a task when updatedPromt changes
  useEffect(() => {
    if (updatedPromt !== "") {
      handleAddTask();
    }
  }, [updatedPromt]);


  // Navigates back to the previous page
  const handleBackClick = () => {
    navigate(-1);
  };

  // Toggles the visibility of the input dropdown
  const handleInputDD = () => {
    setShowInputDD(prev => !prev);
  };

  // Saves the user and assistant content as a few-shot example
  const handleFewShotSave = () => {
    setFewShotValues(prev => [...prev, {
      "index": Date.now(),
      "inputs": [
        {
          "role": "user",
          "content": userContent
        },
        {
          "role": "assistant",
          "content": assistantContent
        }
      ]
    }]);
    if (userRef.current) userRef.current.value = '';
    if (assistantRef.current) assistantRef.current.value = '';
    setUserContent("")
    setAssistantContent("")
  };

  // Deletes a few-shot example by its index
  const handleFewShotDelete = (index: number) => {
    setFewShotValues(prevData => prevData.filter(ele => ele.index !== index));
  };

  // Edits a few-shot example by its index with updated user and assistant content
  const handleFewShotEdit = (index: number, updatedUserCont: string, updatedAssistantContent: string) => {
    setFewShotValues(prevValues => {
      const elementIndex = prevValues.findIndex(item => item.index === index);
      if (elementIndex !== -1) {
        const newValues = [...prevValues];
        newValues[elementIndex] = {
          index: index,
          inputs: [
            { role: "user", content: updatedUserCont },
            { role: "assistant", content: updatedAssistantContent }
          ]
        };
        return newValues;
      }
      return prevValues;
    });
  };

  // Handles multi-selection of inputs and updates the input array
  const handleMultiSelect = (e: React.ChangeEvent<HTMLInputElement>, description: string) => {
    if (e.target.checked) {
      setInputArray(prev => [...prev, { inputPlaceholder: e.target.value, description }]);
      setSelectedInputs(prev => [...prev, e.target.value]);
    } else {
      setInputArray(prev => prev.filter(ele => ele.inputPlaceholder !== e.target.value));
      setSelectedInputs(prev => prev.filter(ele => ele !== e.target.value));
    }
  };

  // Generates a prompt based on input, output, and task descriptions
  const handlePromptGen = async () => {
    let obj = { modelError: "" };

    if (promptMessageData.modelConfigurationID === "") {
      setModelError("Select a Model");
      return;
    }

    try {
      setIsLoading(true);
      const inputDesc = inputArray;
      const outputDesc = outputDescription;
      const taskDescription = promptMessageData.promptMessage;
      const fewShots = fewShotValues.map(ele => ele.inputs);
      const req_body = {
        inputs: inputDesc,
        outputDescription: outputDesc,
        taskDescription: taskDescription,
        examples: fewShots
      };

      const updated_prompt = await promptGen(req_body);

      setIsLoading(false);
      setTempUpdatedPrompt(updated_prompt.data.finalPrompt);
      setPromptMessageData(prev => ({
        ...prev,
        promptMessage: updated_prompt.data.finalPrompt,
      }));
      setUpdatedPrompt(updated_prompt.data.finalPrompt);
    } catch (error) {
      setIsLoading(false);
    }
  };

  // Updates the system message and enhanced system message
  const handleSystemMessageUpdate = async () => {
    try {
      setHeartBeat(true);
      const sys_mess = promptData.systemMessage;
      const reqBody = { systemPrompt: promptData.systemMessage };
      const response = await systemMessageGen(reqBody);
      setTempFlag(true);
      setPromptData(prev => ({
        ...prev,
        enhancedSystemMessage: sys_mess,
        systemMessage: response.data,
        editedPromptID: editedPromptID,
        modifiedBy: ""
      }));
      setEnhancedSystemMessage(sys_mess);
      updatePromptData('systemMessage', response.data);
      setHeartBeat(false);
    } catch (error) {
      setHeartBeat(false);
    }
  };

  // Creates a new prompt field with default values for chat name
  const handleCreatePrompt = () => {
    const id = inputTypeData?.find((val: InputType) => val.inputTypeRefCode === "TXT")?.inputTypeID || "";

    const promptFieldDataObj: PromptFieldData = {
      labelName: "Chat Name",
      inputTypeID: id,
      promptFieldID: "",
      inputTypeRefCode: "TXT",
      promptFieldOrder: "1",
      placeHolder: "Enter the chat name",
      errorMessage: "Please enter the chat name",
      required: "true",
      hitAI: 0,
      inputValueOrder: "input-1",
      uniqueField: 0,
      choiceName: [],
      createdBy: "",
      modifiedBy: "",
    };

    setPF(prev => [...prev, promptFieldDataObj]);
    setDisableEdit(false);
  };

  // Fetches bind parameters for model configuration
  const fetchBindParams = async () => {
    try {
      const response = await getBindParams({});
      setModelData(response.data.model_config_result);
    } catch (error) {
      console.error(error);
    }
  };

  // Handles changes in input data and updates prompt field choices
  const onChangeData = (e: React.ChangeEvent<HTMLInputElement> | any, choice = "") => {
    let updatedChoices = Array.isArray(promptFieldData.choiceName)
      ? [...promptFieldData.choiceName]
      : [];

    const updatePromptFieldAndEditChoices = (newChoice: any[]) => {
      setPromptFieldData(prevData => ({
        ...prevData,
        placeHolder: e.target?.placeholder || prevData.placeHolder,
        choiceName: newChoice,
        hitAI: hitAiVar,
        modifiedBy: userDetails?.email || ""
      }));
      setEditChoices(newChoice);
    };

    if (e.target?.id === "labelName") {
      setError("");
      setPromptFieldData(pre => ({
        ...pre,
        [e.target.id]: e.target.value,
        hitAI: hitAiVar,
        modifiedBy: userDetails?.email || ""
      }));
    } else if (e.target?.id === "old-values") {
      if (choice === "") updatedChoices.push(e.target.value);
      else updatedChoices = typeof choice === "string" ? [e.target.value] : choice;
      updatePromptFieldAndEditChoices(updatedChoices);
    } else if (
      selectedOption === "RB" ||
      selectedOption === "CB" ||
      selectedOption === "DD"
    ) {
      if (!updatedChoices.includes(e.target.value)) {
        updatedChoices.push(e.target.value);
        updatePromptFieldAndEditChoices(updatedChoices);
      }
    }
  };

  // Handles changes in prompt data fields
  const handlePromptChange = (e: any) => {
    setPromptData(prev => ({ ...prev, [e.target.id]: e.target.value }));
  };

  // Fetches input types, conditions, and prompt logos from the server
  const fetchInputTypeAndTechStack = async () => {
    try {
      const response = await getInputTypeAndTechStack({});
      if (response.status === 200) {
        setInputTypeData(response?.data?.data?.ResponseData?.input_types);
        setConditionData(response?.data?.data?.ResponseData?.conditions);
        setPromptLogoData(response?.data?.data?.ResponseData?.prompt_logos);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Deletes a prompt by its ID and refreshes the prompt list
  const deletePromptData = async (promptID: string) => {
    try {
      const response = await deletePrompt({ promptID });
      if (response.data.Success) {
        setShowPopup(false);
        setPromptIndex(null);
      }
      const query = new URLSearchParams(window.location.search);
      const practiceId = query.get('practiceID');
      await fetchPrompt({"promptId":"","practiceID":practiceId});
    } catch (error) {
      console.error(error);
    }
  };

  // Fetches prompt data based on the practice ID from the URL
  const fetchPromptData = async () => {
    try {
      setIsLoading(true);
      const query = new URLSearchParams(window.location.search);
      const practiceId = query.get('practiceID');
      const response = await fetchPrompt({"promptId":"","practiceID":practiceId});
      if (response.status === 200) {
        const prompts = response.data?.ResponseData?.prompts || [];
        const arr = prompts.filter((prompt: Prompt) => prompt.draft !== "");
        setPromptCard(arr);
        setShowPage(arr.length ? "manage prompt" : "no record");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Validates the prompt data before submission
  const validate = async (id: string): Promise<void> => {
    setIsLoading(true);
    const validateObj = {
      promptNameError: promptData.promptName ? "" : "Enter the Prompt Name",
      labelNameError: "",
      choicesError: "",
      promptLogoIDError: ""  
    };
    setErrorData(validateObj);
    if (!validateObj.promptNameError) {
      await submitPrompt(id);
    }
    setIsLoading(false);
  };

  // Submits the prompt data to the server
  const submitPrompt = async (id: string) => {
    const promptObject = { ...promptData };
    promptObject.modifiedBy = userDetails?.email || "";
    if (promptObject.kbUrl?.length !== 0) {
      promptObject.collectionName = `${promptObject.promptName}_collection`;
    }
    if (!promptData.promptID) {
      promptObject.createdBy = userDetails?.email || "";
      if (id === "SD") {
        promptObject.draft = 0;
      } else {
        promptObject.draft = 1;
      }
      promptObj.practiceID=practiceID;
      promptObject.promptFields=PF;
      await insertPrompt(promptObject);
    } else {
      if (id === "SD") {
        promptObject.draft = 0;
      } else {
        promptObject.draft = 1;
      }
      promptObj.practiceID=practiceID;
      promptObject.promptFields=PF;
      await insertPrompt(promptObject);
    }
    fetchPromptData();
    resetForm();
  };

  // Updates a specific field in the prompt data
  const updatePromptData = (field: string, value: string | any) => {
    if (field === "promptDescription" || field === "systemMessage") {
      if (promptObj) {
        promptObj[field] = value;
      }
      if (promptIndex) {
        promptIndex[field] = value;
      }
    }
    setPromptData((prev: any) => ({
      ...prev,
      [field]: value,
    }));
  };

  // Resets the form to its initial state
  const resetForm = () => {
    setPromptMessagePage("");
    setPF([]);
    setTasks([]);
    setPromptFieldData(promptFieldObj);
    setPromptMessageData(promptMessageObj);
    setPromptData(promptObj);
    setPromptLogoValues(logoObj);
    setEnhancedSystemMessage("");
    setFewShotValues([]);
    setOutputDescription("");
    setUpdatedPrompt("");
    setSelectedInputs([]);
  };

  // Updates promptData when enhancedSystemMessage changes
  useEffect(() => {
    setPromptData(prev => ({
      ...prev,
      enhancedSystemMessage,
      editedPromptID,
      modifiedBy: ""
    }));
  }, [enhancedSystemMessage]);

  // Handles adding or editing a task in the prompt message list
  const handleAddTask = (): void => {
    setModelError("");

    if (promptMessageData.promptMessage.trim() !== "") {
      if (typeof editTaskIndex === "number") {
        const updatedPromptData = promptData.promptMessages.map((task, index) => {
          if (index === editTaskIndex) {
            const messageValue = promptMessageData.promptMessage.trim();
            const outputDes = promptMessageData.outputDesc ? outputDescription.trim() : "";

            return {
              ...task,
              promptMessage: messageValue,
              oldPrompt: promptMessageData.promptMessage.trim(),
              outputDescription: outputDes,
              fewShotExamples: fewShotValues,
              modifiedBy: promptMessageData.modifiedBy,
              inputKey: promptMessageData.inputKey,
              conditionID: promptMessageData.conditionID,
              modelConfigurationID: promptMessageData.modelConfigurationID,
              isWebSearch: promptMessageData.isWebSearch,
              webContent: promptMessageData.webContent,
              isKbRetriever: promptMessageData.isKbRetriever,
              inputValue: promptMessageData.inputValue ? promptMessageData.inputValue.trim() : promptMessageData.inputValue,
              inputArray: inputArray
            };
          }
          return task;
        });

        const newPromptMessages = updatedPromptData.map(message => ({
          ...message,
          inputArray: typeof message.inputArray === "string" && message.inputArray.length > 2
            ? JSON?.parse(message.inputArray)
            : message.inputArray
        }));

        setTasks(newPromptMessages);
        setIsWebSearch(false);
        setIsKbRetriever(false);
        setPromptData(prev => ({
          ...prev,
          promptMessages: updatedPromptData,
          enhancedSystemMessage,
          editedPromptID,
          modifiedBy: userDetails?.email || ""
        }));
        setEditTaskIndex(null);
        setPromptMessageData(prev => ({ ...prev, promptMessage: "", inputArray: inputArray }));
      } else {
        const newTask: Task = {
          promptMessage: promptMessageData.promptMessage.trim(),
          oldPrompt: promptMessageData.promptMessage.trim(),
          outputDescription: promptMessageData.outputDesc.trim(),
          fewShotExamples: fewShotValues,
          inputValue: promptMessageData.inputValue ? promptMessageData.inputValue.trim() : promptMessageData.inputValue,
          inputKey: promptMessageData.inputKey,
          conditionID: promptMessageData.conditionID,
          modelConfigurationID: promptMessageData.modelConfigurationID,
          isWebSearch: promptMessageData.isWebSearch,
          isKbRetriever: promptMessageData.isKbRetriever,
          webContent: promptMessageData.webContent,
          createdBy: userDetails?.email || "",
          modifiedBy: promptMessageData.modifiedBy,
          promptMessageOrder: promptData.promptMessages.length + 1,
          promptMessageID: promptMessageData.promptMessageID,
          inputArray: inputArray,
          displayName: "",
        };

        setIsKbRetriever(false);
        setTasks(prevTasks => [...prevTasks, newTask]);
        setIsWebSearch(false);
        setPromptData(prev => ({
          ...prev,
          promptMessages: [...prev.promptMessages, newTask],
          modifiedBy: ""
        }));
      }

      setPromptMessageData(prev => ({
        ...prev,
        promptMessage: "",
        fewShotExamples: [],
        outputDesc: "",
        inputArr: [],
        inputValue: "",
        inputKey: "",
        conditionID: "",
        modelConfigurationID: "",
        isWebSearch: false,
        webContent: "",
        isKbRetriever: false,
        inputArray: []
      }));
    }

    setFewShotValues([]);
    setInputArray([]);
    setSelectedInputs([]);
    setOutputDescription("");
    setDisableEdit(false);
    setSelectedModel("");
  };

  // Handles editing a task by populating the form with its data
  const handleEditTask = (index: number, task: Task): void => {
    setDisableEdit(true);
    setIsWebSearch(task.isWebSearch);
    setIsKbRetriever(task.isKbRetriever);
    setSelectedModel(task.displayName || "");

    const array: any[] = Array.isArray(task.fewShotExamples)
      ? task.fewShotExamples
      : [];

    const inputArray: any[] = Array.isArray(task.inputArray)
      ? task.inputArray
      : [];

    const tempArr = Array.isArray(inputArray)
      ? inputArray.map(ele => ele.inputPlaceholder)
      : [];

    setSelectedInputs(tempArr);
    setInputArray(inputArray);
    setFewShotValues(array);
    setPromptMessageData({
      ...promptMessageData,
      promptMessage: task.promptMessage,
      inputKey: task.inputKey,
      inputValue: task.inputValue,
      conditionID: task.conditionID,
      modelConfigurationID: task.modelConfigurationID,
      isWebSearch: task.isWebSearch,
      webContent: task.webContent,
      isKbRetriever: task.isKbRetriever,
      outputDesc: task.outputDescription,
      inputArray: inputArray,
      fewShotExamples: array,
    });

    setOutputDescription(task.outputDescription);
    setEditTaskIndex(index);
  };

  // Handles deleting a task from the prompt message list
  const handleDeleteTask = (index: number): void => {
    const updatedTasks = tasks.filter((_, i) => i !== index);
    const updatedTasksWithOrder = updatedTasks.map((task, newIndex) => ({
      ...task,
      promptMessageOrder: newIndex + 1,
    }));

    setTasks(updatedTasksWithOrder);
    setPromptData(prev => ({
      ...prev,
      promptMessages: updatedTasksWithOrder,
      editedPromptID: editedPromptID,
      modifiedBy: ""
    }));
  };
      
  // Handles drag-and-drop reordering of tasks and updates prompt messages
  const handleDragEnd = (result: DropResult): void => {
    if (!result.destination) {
      return;
    }
    const reorderedTasks = Array.from(tasks);
    const [removed] = reorderedTasks.splice(result.source.index, 1);
    reorderedTasks.splice(result.destination.index, 0, removed);

    const updatedTasks = reorderedTasks.map((task, index) => ({
      ...task,
      promptMessageOrder: index + 1,
    }));

    setTasks(updatedTasks);
    setPromptData(prev => ({
      ...prev,
      promptMessages: updatedTasks,
      editedPromptID,
      modifiedBy: userDetails?.email || ""
    }));
  };

  // Sets the number of options to add
  const addOptionProp = (addOpt: number = 2): void => {
    setAddOption(addOpt);
  };

  // Handles drag-and-drop reordering of prompt fields and updates their order
  const handleDrag = (result: DropResult): void => {
    if (!result.destination || result.destination.index === 0) {
      return;
    }

    const updatedPF = Array.from(PF);
    const movedItem = updatedPF[result.source.index];
    updatedPF.splice(result.source.index, 1);
    updatedPF.splice(result.destination.index, 0, movedItem);

    const updatedPFWithOrders = updatedPF.map((item, index) => ({
      ...item,
      promptFieldOrder: (index + 1).toString(),
      inputValueOrder: `input-${index + 1}`
    }));

    setPF(updatedPFWithOrders);
    setPromptData(prev => ({
      ...prev,
      promptFields: updatedPFWithOrders,
      editedPromptID,
      modifiedBy: userDetails?.email || ""
    }));
  };

  // Maps model data to dropdown options
  const bindModel = (modelData: ModelData) => {
    return Array.isArray(modelData) ? modelData.map((value, index) => (
      <option key={index} value={value.modelConfigurationID} id="modelConfiguration"
        data-bs-toggle="tooltip"
        data-bs-placement="left"
        title={value.modelDescription}>
        {value.displayName}
      </option>
    )) : null;
  };

  // Maps prompt field data to dropdown options
  const bindKeyData = (PF: PromptFieldData[]): JSX.Element[] | null => {
    return Array.isArray(PF) ? PF.map((value, index) => (
      <option key={index} value={value.inputKey}>
        {value.inputValueOrder}
      </option>
    )) : null;
  };

  // Maps condition data to dropdown options
  const bindConditionData = (conditionData: any[]): JSX.Element[] | null => {
    return Array.isArray(conditionData) ? conditionData.map((value, index) => (
      <option key={index} value={value.conditionID}>
        {value.inputCondition}
      </option>
    )) : null;
  };


  // Maps prompt logo data to dropdown items and handles logo selection
  const bindPromptLogo = (promptLogoData: PromptLogo) => {
    return Array.isArray(promptLogoData) ? promptLogoData.map((value, index) => (
      <li key={index} value={value.promptLogoID} id={value.promptLogoID} className="font-14 font-medium px-2 text-color-4 align-items-center d-flex">
        <a className="dropdown-item py-2 custom-dropdown-li custom-border-bottom text-color-2"
          data-value={value.promptLogoID}
          id={`${value.promptLogoID}#${value.promptLogoPath}#${value.promptLogoName}`}
          onClick={(e) => {
            setErrorData(prev => ({ ...prev, promptLogoIDError: "" }));
            const values = e.currentTarget.id.split("#");
            setPromptLogoValues({
              logoPath: values[1],
              logoName: values[2],
              logoID: values[0]
            });
            setPromptData(prev => ({ ...prev, promptLogoID: values[0], enhancedSystemMessage, modifiedBy: userDetails?.email || "" }));
          }}>
          <span className="me-2">
            <img src={value.promptLogoPath} alt="drop-icon" className="drop-icon" />
          </span>
          {value.promptLogoName}
        </a>
      </li>
    )) : null;
  };

  // Toggles the custom dropdown open/close state
  const toggleDropdown = (): void => {
    setIsOpenCustom(prev => !prev);
  };

  // Updates the model configuration ID in prompt message data
  const handleModelChange = (event: { modelConfigurationID: string }): void => {
    setPromptMessageData(prev => ({
      ...prev,
      modelConfigurationID: event.modelConfigurationID
    }));
  };

  // Finds the display name of a model by its configuration ID
  const findModelName = (key: string, data: ModelData): string | null => {
    const item = data.find(obj => obj.modelConfigurationID === key);
    return item ? item.displayName : null;
  };

  const contextValue: PromptBuilderContextType = {
    promptObj, promptMessageObj, promptFieldObj, errorObj, logoObj,
    isCopied, setIsCopied,
    removeOption, setRemoveOption,
    selectedOption, setSelectedOption,
    inputTypeData, setInputTypeData,
    show, setShow,
    choices, setChoices,
    promptMessagePage, setPromptMessagePage,
    flag, setflag,
    tasks, setTasks,
    submitType, setSubmitType,
    promptCard, setPromptCard,
    promptIndex, setPromptIndex,
    showPopup, setShowPopup,
    popUpText, setPopUpText,
    fieldIndex, setFieldIndex,
    editTaskIndex, setEditTaskIndex,
    userData, setuserData,
    addOption, setAddOption,
    checkbox, setCheckbox,
    dropdown, setDropdown,
    required, setRequired,
    suma, setSuma,
    longAnswer, setLongAnswer,
    error, setError,
    editChoices, setEditChoices,
    conditionData, setConditionData,
    promptLogoData, setPromptLogoData,
    userEmailId, setUserEmailId,
    isLoading, setIsLoading,
    hoveredInput, setHoveredInput,
    hiddenIndices, setHiddenIndices,
    modelData, setModelData,
    isWebSearch, setIsWebSearch,
    practiceID, setPracticeID,
    allKbFiles, setAllKnFiles,
    selectedModelConfigurationID, setSelectedModelConfigurationID,
    collection_name, setcollection_name,
    indexName, setIndexName,
    promptName, setPromptName,
    promptID, setPromptID,
    editedPromptID, setEditedPromptID,
    isKbRetriever, setIsKbRetriever,
    selectedModelName, setSelectedModelName,
    kbRetrieverUrl, setKbRetrieverUrl,
    modelError, setModelError,
    HandlePromptHistory, setHandlePromptHistory,
    exampleVal, setExampleVal,
    dropdownOpen, setDropdownOpen,
    fewShotValues, setFewShotValues,
    outputDescription, setOutputDescription,
    promptFieldData, setPromptFieldData,
    inputArray, setInputArray,
    userContent, setUserContent,
    assistantContent, setAssistantContent,
    updatedPromt, setUpdatedPrompt,
    enhancedSystemMessage, setEnhancedSystemMessage,
    heartBeat, setHeartBeat,
    showInputDD, setShowInputDD,
    selectedInputs, setSelectedInputs,
    tempUpdatePrompt, setTempUpdatedPrompt,
    askAsamiKbRetriever, setAskAsamiKbRetriever,
    disableEdit, setDisableEdit,
    aiReq, setAiReq,
    hitAiVar, sethitAiVar,
    disableKBEdit, setDisableKBEdit,
    collectionNameFix, setCollectionNameFix,
    errorData, setErrorData,
    promptLogoValues, setPromptLogoValues,
    displayQuestion, setDisplayQuestions,
    practiceName, setPracticeName,
    promptData, setPromptData,
    toggleDropDown, setToggleDropDown,
    editQuestion, setEditQuestion,
    PF, setPF,
    showPage, setShowPage, 
    promptMessageData, setPromptMessageData,
    isOpenCustom, setIsOpenCustom,
    description, setDescription,
    selectedOptionVal, setSelectedOptionVal,
    selectedModel, setSelectedModel,
    userRef, assistantRef,
  
    handleCopyClick, handleBackClick, handleInputDD, handleOptionClick, 
    handleFewShotSave, handleFewShotDelete, handleFewShotEdit,
    handleMultiSelect, handlePromptGen, handleSystemMessageUpdate,
    handleCreatePrompt, fetchBindParams, onChangeData,
    handlePromptChange, fetchInputTypeAndTechStack, deletePromptData, updatePromptData, 
    fetchPromptData, validate, submitPrompt,
    handleAddTask, handleEditTask, handleDeleteTask,
    handleDragEnd, addOptionProp, handleDrag,
    bindModel, bindKeyData, bindConditionData,
    bindPromptLogo, toggleDropdown, handleModelChange,
    setKnowledgeData, userDetails, findModelName
  };

  return (
    <PromptBuilderContext.Provider value={contextValue}>
      {children}
    </PromptBuilderContext.Provider>
  );
};

export const usePromptBuilder = () => {
  const context = useContext(PromptBuilderContext);
  if (context === undefined) {
    throw new Error('usePromptBuilder must be used within a PromptBuilderProvider');
  }
  return context;
};