//SQ_LB_1.1 Importing necessary modules and libraries needed
import React, { useMemo, useEffect, useState, useRef } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';
import { ApexOptions } from 'apexcharts';

//SQ_LB_1.2 Uses the interface for type safety
interface LineGraphProps {
  graphData: {
    totalMonthlyUsers?: any;
    totalYearlyUsers?: any;
    totalWeeklyUsers?: any;
  } ;
  timePeriod: 'Yearly' | 'Monthly' | 'Weekly';
}

const LineGraph: React.FC<LineGraphProps> = ({ graphData, timePeriod }) => {


  //SQ_LB_1.3 to SQ_LB_1.8 Initializes the state variables
  const theme = useTheme();
  // const textColor = theme.palette.mode === 'dark' ? '#ffffff' : '#000000';
  const chartRef = useRef<HTMLDivElement>(null);
  const [textColor, setTextColor] = useState<string>(theme.palette.mode);

  //SQ_LB_1.7 to 1.13 updates the theme color of graph according to light and dark theme of parent component
  useEffect(() => {
    const updateTextColor = () => {
      if (chartRef.current) {
        const card = chartRef.current.closest('.custom-dashboard-section');
        if (card) {
          const bgColor = window.getComputedStyle(card).backgroundColor;
          const rgb = bgColor.match(/\d+/g);
          if (rgb) {
            const brightness = (parseInt(rgb[0]) * 299 + parseInt(rgb[1]) * 587 + parseInt(rgb[2]) * 114) / 1000;
            setTextColor(brightness > 128 ? '#000000' : '#ffffff');
          }
        }
      }
    };

    updateTextColor();

    const resizeObserver = new ResizeObserver(updateTextColor);

    if (chartRef.current) {
      const card = chartRef.current.closest('.custom-dashboard-section');
      if (card) {
        resizeObserver.observe(card);
      }
    }

    return () => resizeObserver.disconnect();
  }, [theme]);


  //SQ_LB_1.23 to 1.28 Processes the data to show in graph for time periods
  const processedData = useMemo(() => {
    if (!graphData) {
      console.error('No graph data provided');
      return { xAxis: [], yAxis: [] };
    }

    let data: { [key: string]: number } = {};
    if ('totalMonthlyUsers' in graphData || 'totalYearlyUsers' in graphData || 'totalWeeklyUsers' in graphData) {
      switch (timePeriod) {
        case 'Yearly':
          data = graphData.totalYearlyUsers ?? {};
          break;
        case 'Monthly':
          data = graphData.totalMonthlyUsers ?? {};
          break;
        case 'Weekly':
          data = graphData.totalWeeklyUsers ?? {};
          break;
        default:
          console.error('Invalid time period:', timePeriod);
          return { xAxis: [], yAxis: [] };
      }
    } else {
      // If graphData is directly the data object
      data = graphData;
    }

    // Process the data
    if (timePeriod === 'Yearly') {
      const months = ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar'];
      const currentMonth = new Date().getMonth();
      const orderedMonths = [...months.slice(currentMonth), ...months.slice(0, currentMonth)];
      
      const xAxis = orderedMonths;
      const yAxis = orderedMonths.map(month => data[month] || 0);

      return { xAxis, yAxis };
    } else if (timePeriod === 'Monthly') {
      const weeks = ['Week-1', 'Week-2', 'Week-3', 'Week-4'];
      const xAxis = weeks;
      const yAxis = weeks.map(week => data[week] || 0);

      return { xAxis, yAxis };
    } else {
      const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
      const xAxis = days;
      const yAxis = days.map(day => data[day] || 0);

      return { xAxis, yAxis };
    }
  }, [graphData, timePeriod]);

  //SQ_LB_1.32 to 1.36 Chart options are configured here
  const options: ApexOptions = {
    chart: {
      type: 'line',
      zoom: { enabled: false },
      toolbar: { show: false },
    },
    colors: ['#8A2BE2', '#FF69B4'],
    dataLabels: { enabled: false },
    stroke: { curve: 'smooth', width: 2 },
    xaxis: {
      categories: processedData.xAxis,
      labels: {
        style: { colors: textColor },
        rotate: -45,
        rotateAlways: false,
      },
      tooltip:{
        enabled: false
      },

    },
    yaxis: {
      labels: {
        style: { colors: textColor },
        formatter: (value) => value.toFixed(0),
      },
    },
    tooltip: {
      y: {
        formatter: (value) => value.toString(),
      },
    },
    grid: {
      row: { colors: ['transparent'] },
      borderColor: textColor === '#ffffff' ? '#888888' : '#f1f1f1',
      strokeDashArray: 5
    },
  };

  //SQ_LB_1.29 to 1.31 series for the graph is rendered here
  const series = [
    {
      name: 'Users',
      data: processedData.yAxis,
    },
  ];

  useEffect(() => {
  }, [processedData, options, series]);

  if (processedData.xAxis.length === 0 || processedData.yAxis.length === 0) {
    return <div>No data available for the selected time period.</div>;
  }

  return (
    <div id='chart' ref={chartRef}>
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height={270}
    />
    </div>
  );
};

export default LineGraph;