import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useManagePromptCard } from '../../Utility/ManagePromptCardContext';
import { PromptCard, Practice, PromptHistoryItem } from '../../Interfaces/ManagePrompt';
// import Loader from "../Other-Components/loader";
import UserContext from "../../Auth/UserContext";
import { useContext } from "react";
import { fetchPrompt, fetchPromptHistory } from "../../Service/API";
import ActivityModal from '../../Reusable Components/ActivityModal';
import ButtonComponent from '../../Reusable Components/Button';
import ModuleHeading from '../../Reusable Components/ModuleHeading';
import CardComponent from '../../Reusable Components/Card';
import { useTableContext } from '../../Utility/ReusableTableContext';
import { ModelConfig } from '../../Interfaces/LLMConfigGrid';
import { usePromptBuilder } from '../../Utility/ManagePromptContext';
import YesOrNoPopup from '../../Reusable Components/YesOrNoPopup';
import Tab from '../../Reusable Components/TabSwitch';
import RulesGrid from '../Grid/RulesGrid';
import { LlmConfigProvider } from '../../Utility/LLMConfigurationForm';
import { LlmFormProvider } from '../../Utility/Input'
import TextInput from '../../Reusable Components/TextInput';
import Loader from "../../Reusable Components/Loader";
interface ManageTasksContentProps {
  navOpen: any;
}
const ManagePromptCards: React.FC <ManageTasksContentProps>= ({navOpen}) => {
  const {
    promptCards,
    setShowPage,
    setPracticeID,
    showPopup,
    setShowPopup,
    setPromptIndex,
    setPopUpText,
    setPracticeName,
    practiceDetails,
    fetchPractice,
    fetchPromptData,
    deletePromptData,
    promptIndex,
    isLoading,
    search,
    setSearch,
    promptHistory,
    promptCardData,
    setPromptCardData,
    promptdataid,
    setPromptDataId,
    setPromptDataName,
    promptdataName,
    restore,
    practiceID,
    setisconfig
  } = useManagePromptCard();
  const { handleCreatePrompt , setPF , setTasks , setPromptData , setPromptLogoData,setPromptLogoValues,logoObj,setSelectedOption,setLongAnswer } = usePromptBuilder();


  const [accordian, setAccordian] = useState(true);
  const [page, setPage] = useState('Prompt');
  const [arr, setArr] = useState<PromptHistoryItem[]>([]);
  const [sortname, setSortname] = useState("promptName");
  const [sortvalue, setSortvalue] = useState("ASC");
  const [promptSort, setPromptSort] = useState(true);
  const [modifiedBy, setModifiedBy] = useState(false);
  const [timeStamp, setTimeStamp] = useState(false);
  const [promptid, setPromptid] = useState("");
  const { sortConfig, setSortConfig } = useTableContext<ModelConfig>();

  const check = async (promptVal: string) => {
    let sort = 'ASC'
    let column = 'promptName'
    if (sortConfig.column != '') {
      if (sortConfig.column == 'DateTime') {
        column = 'createdDateTime'

      }
      else if (sortConfig.column == 'ModifiedBy') {
        column = 'createdBy'
      }
      else {
        column = sortConfig.column
      }
    }
    if (sortConfig.order == 'desc') {
      sort = 'DESC'
    }
    try {
      const data = await fetchPromptHistory({
        promptId: promptVal,
        editedPromptID: promptVal,
        sortOption: column,
        sortValue: sort
      });

      setArr(data?.responseData?.data)
    } catch (error) {
      console.error(error);
    }
  };

  const userDetails = useContext(UserContext);
  const history = useNavigate();
  function canceldelete() {
    setShowPopup(false);
    setPromptIndex(null);
  }


  useEffect(() => {
    if (promptid) {
      checkPromptHistory(promptid);
    }
  }, [sortvalue, promptSort, timeStamp, modifiedBy, promptid, promptCards]);

  const checkPromptHistory = async (promptVal: string) => {
    try {
      const data = await check(promptVal);

    } catch (error) {
      console.error(error);
    }
  };

  const handlePromptHistory = async (promptID: string) => {
    try {
      let data = await promptHistory(promptID);
      setPromptIndex(data);
      setShowPage("showManagePrompt");
      history("/asamimanagebase");
    } catch (error) {
      console.error(error);
    }
  };

  const handleRestore = async (promptVal: string, editedPromptVal: string) => {
    try {

      await restore(promptVal, editedPromptVal);
      fetchPromptData()
    } catch (error) {
      console.error(error);
    }
  };

  const sortPromptName = () => {
    setSortname("promptName");
    setSortvalue(promptSort ? "DESC" : "ASC");
    setPromptSort(!promptSort);
  };

  const handleSearch = () => {
    fetchPromptData()
  };

  function isValidJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const renderPromptCards = (practice: Practice, filteredPromptCards: PromptCard[]) => {
    return filteredPromptCards.map((promptCard) => (
      <div key={promptCard.promptID} className="col-md-4 col-sm-12">
        <CardComponent
          cardType="prompt"
          promptCard={promptCard}
          onActivityClick={(promptID) => {
            setTimeStamp(false);
            setModifiedBy(false);
            setPromptSort(true);
            setPromptid(promptID);
          }}
          onEditClick={() => {
            setShowPage("showManagePrompt");
            setPF([]);
            setPromptLogoValues(logoObj)
            setTasks([]);
            sessionStorage.setItem('askAsamiKbData', JSON.stringify({
              chatID: null,
              collectionName:  null,
              indexName: null,
              kbFiles: promptCard.kbFiles && isValidJsonString(promptCard.kbFiles) ? JSON.parse(promptCard.kbFiles) : '',
              kbUrl: promptCard.kbUrl && isValidJsonString(promptCard.kbUrl) ? JSON.parse(promptCard.kbUrl) : '',
              isKbRetriever: true, 
            }))
            sessionStorage.setItem('promptKbFile',"[]")
            setPromptData(    
              {promptID: "",
              promptName: "",
              promptDescription: "",
              promptLogoID: "",
              systemMessage: "",
              enhancedSystemMessage: "",
              editedPromptID: "",
              modifiedBy: "",
              kbFiles: [],
              kbUrl: [],
              collectionName: "",
              indexName: "",
              practiceID: practiceID,
              createdBy: "",
              draft: 0,
              promptFields: [],
              promptMessages: [],
          })
            setPromptIndex(null)
            setSelectedOption("")
            setLongAnswer(false)
            setPracticeID(practice.practiceID);
            setPracticeName(practice.practiceName);
            setPromptIndex(promptCard);
            history("/asamimanagebase");
          }}
          onDeleteClick={() => {
            setShowPopup(true);
            setPromptIndex(promptCard.promptID);
            setPopUpText(`delete ${promptCard.promptName}`);
          }}
          showDelete={true}
          userDetails={userDetails}
        />
      </div>
    ));
  };
  let navigate = useNavigate()

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const practiceId = query.get('practiceID');
  
    if (practiceId) {
      setPracticeID(practiceId);
      setPromptDataId(practiceId)
      const filteredPracticeCards:any = practiceDetails?.filter(
        (promptCard: any) => promptCard.practiceID.toUpperCase() === practiceId.toUpperCase()
      );
      if(filteredPracticeCards.length!=0){
      setPromptDataName(filteredPracticeCards[0]?.practiceName)
      }
    } else {
      // Redirect if there's no practiceID in the query
      navigate('/asami-manage-execution');
    }
  }, [practiceDetails]);
  return (
    <>
      {isLoading ? (
       <Loader isLoading={isLoading} navOpening={navOpen} />
      ) : (
        <div className="container-fluid">
          <div className="row justify-content-lg-end justify-content-center">
            <div className={navOpen == "Close" ? "col-md-11 second-component-llmForm-nav-close" : "col-md-9 second-component-llmForm"} id="chat-history-center">
              <div className="row justify-content-center">
                <div className="col-md-12">
                <ModuleHeading text={promptdataName} tag={"h2"} className="mt-4 font-bold font-20 mb-0 text-color-1">
                    <a  onClick={() => {navigate(`/asami-manage-execution`);setisconfig(false)}}>
                        <img src="images-new/back-black-arrow.svg" alt="Back" title='Back' style={{cursor: "pointer"}} className="me-2 icon-filter" />
                      </a>
              </ModuleHeading>
                  
                  {page==="Prompt" ? (<>
                    {practiceDetails?.length > 0 ? (
                      practiceDetails?.map((practice) => {
                        const filteredPromptCards = promptCardData?.filter(
                          (promptCard: any) => promptCard.practiceID.toUpperCase() === promptdataid.toUpperCase()
                        );
                        if (practice.practiceID.toUpperCase() == promptdataid.toUpperCase())
                          return (
                            <>
                              <div className="d-flex justify-content-between mt-4 mb-3 align-items-center manage-prompts-page-top-spacing">

                                <Tab
                                  activeTab={page}
                                  tabContents={{ Prompt: 'Project content goes here', Rule: 'Practice content goes here' }}
                                  onTabClick={setPage}
                                />
                                <div className='dummy-baba'>
                                  <div className="input-group transparent-grp w-225" style={{ width: "255px" }}>
                                    <div className="input-group transparent-grp w-100" >
                                      <TextInput
                                        type={'text'}
                                        className={'form-control custom-search-input cust-search-input search-bg border-0 text-color-4 focus-none font-14 font-medium pe-0 ps-3'}
                                        placeholder={'Search'}
                                        value={search}
                                        onChange={setSearch}
                                        onEnterPress={handleSearch}
                                      />
                                      <button className="btn focus-none search-bg" type="button" onClick={handleSearch}>
                                        <img src="images-new/input-search.svg" alt="input-search-icon" />
                                      </button>
                                    </div>
                                  </div>
                                  <button
                                    type="button"
                                    className="btn primary-btn font-14 font-semibold border-radius-5 color-white  py-2"
                                    onClick={() => {
                                      setShowPage("showManagePrompt");
                                      setPF([]);
                                      setPromptLogoValues(logoObj)
                                      setTasks([]);
                                      setPromptData(    
                                        {promptID: "",
                                        promptName: "",
                                        promptDescription: "",
                                        promptLogoID: "",
                                        systemMessage: "",
                                        enhancedSystemMessage: "",
                                        editedPromptID: "",
                                        modifiedBy: "",
                                        kbFiles: [],
                                        kbUrl: [],
                                        collectionName: "",
                                        indexName: "",
                                        practiceID: practiceID,
                                        createdBy: "",
                                        draft: 0,
                                        promptFields: [],
                                        promptMessages: [],
                                    })
                                      setPromptIndex(null)
                                      setSelectedOption("")
                                      setLongAnswer(false)
                                      sessionStorage.setItem('promptKbFile',"[]")
                                      sessionStorage.setItem('askAsamiKbData', JSON.stringify({
                                        chatID: null,
                                        collectionName:  null,
                                        indexName: null,
                                        kbFiles: [], 
                                        kbUrl: [], 
                                        isKbRetriever: true, 
                                      }))
                                      setPracticeID(practice.practiceID);
                                      setPracticeName(practice.practiceName);
                                      handleCreatePrompt();
                                      history("/asamimanagebase");
                                    }}
                                  >
                                    <img src="images\Default\add-white-icon.svg" alt="Add" className="me-2" />
                                    Prompt
                                  </button>
                            </div>
                              
                            </div>
                              <div className="accordion-custom-scroll mt-4 px-1 custom-accordion-scroll-height">
                              <div className="row row-cols-1 row-cols-md-3 g-4">

                                {filteredPromptCards.length > 0 ? (

                                  renderPromptCards(practice, filteredPromptCards)
                                ) : (
                                  <div className="text-center d-flex align-items-center justify-content-center vh-75 w-100" style={{ height: "20%", width: "20%" }}>
                                    <div className="w-100">
                                      <img src="images/Default/no-record-img.svg" alt="no-record-img" className="no-record-img mb-5" />
                                      <p className="font-20 font-medium text-color-1">
                                        Sorry! You don't have any prompts created.
                                        <span className="d-block mt-2 text-color-1">
                                          Click on <span
                                            className="theme-color cursor-pointer"
                                            onClick={() => {
                                              setShowPage("showManagePrompt");
                                              setPracticeID(practice.practiceID);
                                              setPracticeName(practice.practiceName);
                                              handleCreatePrompt();
                                              history("/asamimanagebase");
                                            }}
                                          >
                                            "Create New Prompt"
                                          </span> to add a new prompt.
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </div>
                              </div>
                            </>
                          );
                      })
                    ) : (
                      <div className="text-center py-5 vh-50 acc-child-fix">
                        <img src="images/Default/no-record-img.svg" alt="no-record-img" className="no-record-img mb-5" />
                        <span className="font-20 font-medium w-100 d-block text-color-1">No Match Found</span>
                      </div>
                    )}
                  </>) : (

                  <LlmConfigProvider>
                    <LlmFormProvider>
                      <RulesGrid navOpen={"Open"} page={page} setPage={setPage}></RulesGrid>
                    </LlmFormProvider>
                  </LlmConfigProvider>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Activity Modal */}
      <ActivityModal
        arr={arr}
        handlePromptHistory={handlePromptHistory}
        handleRestore={handleRestore}
      />
      {showPopup && (
        <YesOrNoPopup
          type="prompt"
          id={promptIndex}
          onConfirm={deletePromptData}
          onCancel={canceldelete}
        />
      )}

    </>
  );
};

export default ManagePromptCards;