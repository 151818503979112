
import React, { useEffect, useState } from "react";
import { getPractice } from "../../Service/API"; // Assume this function exists
import ModuleHeading from "../../Reusable Components/ModuleHeading";
import TextInput from "../../Reusable Components/TextInput";
import NumberOfRecordsComponent from "../../Reusable Components/NoofRecords";
import ReusableTable from "../../Reusable Components/ReusableTable";
import { TableProvider, useTableContext } from "../../Utility/ReusableTableContext";
import { Header } from "../../Interfaces/ReusableTable";
import { useManagePromptCard } from "../../Utility/ManagePromptCardContext";
import ManagePromptCards from "../Manage Prompt/ManagePromptCard";
import { useNavigate } from "react-router-dom";
import Loader from "../../Reusable Components/Loader";

interface ManageExecutionProps {
  navOpen: string;
}

interface UsageData {
  Practice: string;
  NoofPrompts: number;
  NoofRules: number;
}

const ManageExecution: React.FC<ManageExecutionProps> = ({ navOpen }) => {
  return (
    <TableProvider initialData={[]}>
      <ManageExecutionContent navOpen={navOpen} />
    </TableProvider>
  );
};

const ManageExecutionContent: React.FC<ManageExecutionProps> = ({ navOpen }) => {
  const { data, setData, sortConfig, setSortConfig } = useTableContext<UsageData>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [recordCount, setRecordCount] = useState<number>(0);
  const [shownRecords, setShownRecords] = useState<number>(0);
  const [offset, setOffset] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const { isconfig, setisconfig,setPromptDataId,setPromptDataName} = useManagePromptCard();
  let navigate=useNavigate()

  const ActionButtons = ({ row }: { row: any }) => (
    <a   className="text-decoration-none link-color" onClick={() => { navigate(`/asami-manage-prompt?practiceID=${row.practiceID}`);setisconfig(true);setPromptDataName(row.practice_name);setPromptDataId(row.practiceID);}}style={{cursor: "pointer"}}>{row.practiceName}</a>
  );

  const headers: Header<UsageData>[] = [
    { key: "practiceName", label: "Practice", component: ActionButtons, sortable: true },
    { key: "promptCount", label: "# of Prompts", sortable: true, rightAlign:true },
    { key: "rulesCount", label: "# of Rules", sortable: true, rightAlign:true  },
  ];

  const handleSearch = async () => {
    setIsLoad(true);
    const reqBody = {
      sort: sortConfig,
      search: searchTerm,
      offsetValue: offset,
    };
    const response = await getPractice(reqBody);
    setIsLoading(true);
    setIsLoad(false);
    const responseData = response?.data?.responseData?.PracticeDetails || [];
    setData(responseData);
    setShownRecords(responseData.length);
    setRecordCount(response?.data?.responseData?.pagination?.page_size || 0);
  };
  const handleOrderChange = async () => {
    setIsLoad(true);
    const reqBody = {
      sort_by:sortConfig.column =="rulesCount"?"rule_count":sortConfig.column =="promptCount"?"prompt_count":sortConfig.column =="practiceName"?"practice_name":"",
      sort_order: sortConfig.column === "practiceName" 
      ? (sortConfig.order === "asc" ? "desc" : "asc") 
      : (sortConfig.order || "desc"),
      search: searchTerm,
      page: offset,
    };
    const response = await getPractice(reqBody);
    setIsLoading(true);
    setIsLoad(false); 
    setData(response.data.responseData?.PracticeDetails|| []);
    setShownRecords((response.data.responseData?.PracticeDetails?.length || 0));
    setRecordCount(response.data.responseData?.pagination?.total_count || 0);
  };

  const loadMoreClick = () => {
    setOffset(offset + 1);
  };
  useEffect(()=>{
    const loaddata =async()=>{
    await handleOrderChange()
    }
    loaddata()
  },[searchTerm, sortConfig, offset])
  useEffect(()=>{

  },[isconfig])
  useEffect(()=>{setisconfig(false)},[])

  return (
    <>
      {!isLoading ? (
      <Loader isLoading={!isLoading}  navOpening={navOpen}/>
      ) : isconfig ? (
        <ManagePromptCards navOpen={navOpen} />
      ) : (
        <div className="container-fluid">
          <div className="row justify-content-lg-end ms-lg-3 ms-xl-0 justify-content-center">
            <div className={navOpen === "Close" ? `col-11 me-xl-4 me-lg-3` : `col-lg-9 me-md-2 cust-me-lg-4 col-11`}>
              <div className="row">
                <div className="col-md-12">
                  <div className="mt-4 mb-3 d-grid d-md-flex justify-content-md-between align-items-center">
                    <ModuleHeading text={"Manage Execution"} tag={"h2"} className="font-bold font-20 mb-0 text-color-1">
                      <a href="/dashboard">
                        <img src="images-new/back-black-arrow.svg" alt="Back" title="Back" style={{cursor:"pointer"}} className="me-2 icon-filter" />
                      </a>
                    </ModuleHeading>
                    <div className="mt-3 mt-md-0 d-flex align-items-center">
                      <div className="input-group transparent-grp w-225 me-3 m-0 search-bg" >
                        <TextInput 
                          type={'text'} 
                          className={'form-control custom-search-input text-color-4 bg-transparent border-0 focus-none font-14 font-medium pe-0 ps-3 text-nowrap'} 
                          placeholder={'Search'} 
                          onChange={setSearchTerm} 
                          onEnterPress={handleOrderChange}
                        />
                        <button className="btn focus-none search-bg" type="button" onClick={handleSearch}>
                          <img src="images-new/input-search.svg" alt="input-search-icon" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="tab-content mt-2">
                    {isLoad ? (
                      <div style={{ textAlign: 'center', flex: 1, display: 'flex', justifyContent: 'center', marginTop: '20%' }}>
                        <></>
                      </div>
                    ) : (
                      <div className="col-md-12">
                        <div className="table-responsive rounded-3 transparent-grp">
                          <ReusableTable
                            headers={headers}
                            onRowClick={(row) =>{}}
                          />
                        </div>
                        <NumberOfRecordsComponent shownRecords={shownRecords} totalRecords={recordCount} className="font-regular font-14 text-color-1 mt-3 mb-4" />
                        {(recordCount > shownRecords && recordCount > 10) && (
                          <div className="text-center mb-5">
                            <button className="btn gradient-btn border-radius-4 font-14 font-semibold py-2 px-3" type="button" onClick={loadMoreClick}>
                              <span className="color-gradient">Load More</span>
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ManageExecution;