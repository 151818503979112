import React from "react";

export interface UserDetails {
  userName?: string;
  email?: string;
  role?: string;
  jobTitle?: string;
  profileImage?: string;
  department?: string;
  approverMailID?: string
}

// Create the context with an initial undefined value
const UserContext = React.createContext<UserDetails | undefined>(undefined);

export default UserContext;