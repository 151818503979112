import { useEffect, useState } from "react";

import { useHeaderContext } from '../../Utility/HeaderContext';
import { useButtonActions } from "../NavHeaderComponent/ButtonComponentActionContext";

import MarkdownEditor from "../../Reusable Components/MDToolbar";
import Tooltip from '../../Reusable Components/ToolTip';

import CodeSpace from "../../Main Components/ChatComponent/Valyria/CodeSpace";
// import DesignSpaceComponent from "../../Main Components/ChatComponent/Valyria/DesignSpace";
import PlannerComponent from "../../Main Components/ChatComponent/Valyria/Planner";
import Terminal from "../../Main Components/ChatComponent/Valyria/Terminal";
import { BrowserComponent } from "../../Main Components/ChatComponent/Valyria/Browser";

// Define the props interface for the Sidebar component
interface rightNavProps {
  terminalData: any,
  message?: any;
  handleResponse?: any;
  plannerData?: any;
  socketInfo?: any;
  navOpen: string;
  setNavOpen?: any;
  setRightNavOpen?: any;
  browserData?: any;
  downloadbutton?:any;
  setPlannerData?:any;
}

const Sidebar: React.FC<rightNavProps> = ({ 
  terminalData, message, handleResponse, plannerData , setPlannerData , socketInfo, navOpen, setNavOpen, setRightNavOpen, browserData , downloadbutton
}) => {

  const { activeComponent, setActiveComponent } = useHeaderContext();
  var { setLeftNav, leftNav } = useButtonActions();
  const [chatType, setChatType] = useState('');

  // State to manage tooltip data for buttons
  const [toolTipData, SetToolTipData] = useState({
    "Chat": "General Chat",
    "Planner": "Planner",
    "CodeGen": "Code",
    "Terminal": "Terminal",
    "Debugger": "Browser"
  });

  // Effect to initialize navigation states when the component mounts


  // Effect to handle changes in the active component and extract chat type from URL
  useEffect(() => {
    if (activeComponent != "message" && activeComponent != "") {
      setRightNavOpen(true);
    }
    const query = new URLSearchParams(window.location.search);
    setChatType(query.get('type') || "");
  }, [activeComponent]);

  // Function to dynamically render components based on the active component
  const renderComponent = () => {
    switch (activeComponent) {
      case "message":
        setRightNavOpen(false);
        return;
      case "target":
        return <MarkdownEditor initialBase64Content={terminalData} active={setActiveComponent} handleResponse={handleResponse} />;
      // case "bulb":
      //   return <DesignSpaceComponent navOpen={navOpen} />;
      case "calendar":
        return <PlannerComponent plannerData={plannerData} socketInfo={socketInfo} active={setActiveComponent} setPlannerData={setPlannerData}/>;
      case "codepad":
        return <CodeSpace navOpen={navOpen} downloadbutton={downloadbutton} chatType={chatType} />;
      case "code":
        return <Terminal Data={terminalData} />;
      case "web":
        return <BrowserComponent />;
      default:
        return;
    }
  };

  return (
    // Main container for the Sidebar with dynamic width based on the active component
    <div className="right-nav-container" style={{
      maxWidth:
        activeComponent == "target" && navOpen == "Close" ? "100vw" :
        activeComponent == "target" && navOpen == "Open" ? "80vw" :
        (activeComponent == "codepad" || activeComponent == "bulb") && navOpen == "Close" ? "93vw" :
        (activeComponent == "codepad" || activeComponent == "bulb") && navOpen == "Open" ? "79vw" :
        "40vw"
    }}>
      {/* Container for the expanded tabs */}
      <div className={(activeComponent == "codepad" || activeComponent == "bulb") ? "expanded-tabs bg-hide-vl" : "expanded-tabs"} style={{ display: activeComponent && activeComponent != 'message' ? "block" : "none" }}>
        {renderComponent()}
      </div>

      {/* Sidebar navigation pills */}
      <div id="sidebar" className="right-nav bg-color-2 border-radius-12">
        <ul className="custom-right-nav-pill nav nav-pills">
          {/* Message button with tooltip */}
          <li
            className="mb-4 nav-link link-dark font-14 font-medium d-flex align-items-center justify-content-center position-relative"
            onClick={() =>
              setActiveComponent(activeComponent === "message" && chatType !== "CodeGen" ? "" : "message")
            }
            style={{
              backgroundImage: activeComponent === "message" ? "linear-gradient(94.22deg, #5C91F7 1.75%, #865BE7 102.67%)" : "",
              color: activeComponent === "message" ? "white" : ""
            }}
          >
            <Tooltip text={toolTipData.Chat} moveleft={125}>
              <button type="button" className="btn">
                <img src="../images/side-bar-msg-icon.svg" alt="message-icon" style={{
                  filter: activeComponent === "message" ? "brightness(0) invert(1)" : ""
                }} />
              </button>
            </Tooltip>
          </li>

          {/* Calendar button with tooltip */}
          <li
            className="mb-4 nav-link link-dark font-14 font-medium d-flex align-items-center justify-content-center position-relative"
            onClick={() =>
              setActiveComponent(activeComponent === "calendar" && chatType !== "CodeGen" ? "message" : "calendar")
            }
            style={{
              backgroundImage: activeComponent === "calendar" ? "linear-gradient(94.22deg, #5C91F7 1.75%, #865BE7 102.67%)" : "",
              color: activeComponent === "calendar" ? "white" : ""
            }}
          >
            <Tooltip text={toolTipData.Planner} moveleft={125}>
              <button type="button" className="btn">
                <img src="../images/side-bar-calendar-icon.svg" alt="calendar-icon" style={{
                  filter: activeComponent === "calendar" ? "brightness(0) invert(1)" : ""
                }} />
              </button>
            </Tooltip>
          </li>

          {/* Codepad button with tooltip */}
          <li
            className="mb-4 nav-link link-dark font-14 font-medium d-flex align-items-center justify-content-center position-relative"
            onClick={() =>
              setActiveComponent(activeComponent === "codepad" && chatType !== "CodeGen" ? "" : "codepad")
            }
            style={{
              backgroundImage: activeComponent === "codepad" ? "linear-gradient(94.22deg, #5C91F7 1.75%, #865BE7 102.67%)" : "",
              color: activeComponent === "codepad" ? "white" : ""
            }}
          >
            <Tooltip text={toolTipData.CodeGen} moveleft={125}>
              <button type="button" className="btn">
                <img src="../images/side-bar-codepad-icon.svg" alt="codepad-icon" style={{
                  filter: activeComponent === "codepad" ? "brightness(0) invert(1)" : ""
                }} />
              </button>
            </Tooltip>
          </li>

          {/* Code button with tooltip */}
          <li
            className="mb-4 nav-link link-dark font-14 font-medium d-flex align-items-center justify-content-center position-relative"
            onClick={() =>
              setActiveComponent(activeComponent === "code" && chatType !== "CodeGen" ? "" : "code")
            }
            style={{
              backgroundImage: activeComponent === "code" ? "linear-gradient(94.22deg, #5C91F7 1.75%, #865BE7 102.67%)" : "",
              color: activeComponent === "code" ? "white" : ""
            }}
          >
            <Tooltip text={toolTipData.Terminal} moveleft={125}>
              <button type="button" className="btn">
                <img src="../images/side-bar-code-icon.svg" alt="code-icon" style={{
                  filter: activeComponent === "code" ? "brightness(0) invert(1)" : ""
                }} />
              </button>
            </Tooltip>
          </li>

          {/* Web button with tooltip */}
          <li
            className="mb-4 nav-link link-dark font-14 font-medium d-flex align-items-center justify-content-center position-relative"
            onClick={() =>
              setActiveComponent(activeComponent === "web" && chatType !== "CodeGen" ? "" : "web")
            }
            style={{
              backgroundImage: activeComponent === "web" ? "linear-gradient(94.22deg, #5C91F7 1.75%, #865BE7 102.67%)" : "",
              color: activeComponent === "web" ? "white" : ""
            }}
          >
            <Tooltip text={toolTipData.Debugger} moveleft={125}>
              <button type="button" className="btn">
                <img src="../images/side-bar-web-icon.svg" alt="web-icon" 
                style={{
                  filter: activeComponent === "web" ? "brightness(0) invert(1)" : ""
                }} 
                />
              </button>
            </Tooltip>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;