import React from "react";
 
interface DiffViewerProps {
  oldValue: string;
  newValue: string;
}
 
const DiffViewer: React.FC<DiffViewerProps> = ({ oldValue, newValue }) => {
  const oldLines = oldValue?.split("\n");
  const newLines = newValue?.split("\n");
  const maxLines = Math.max(oldLines.length, newLines.length);
 
  // Ensure consistent width based on the longest line
  const maxLineLength = Math.max(
    ...oldLines.map(line => line.length),
    ...newLines.map(line => line.length)
  );
  const maxWidth = maxLineLength * 8; // Approximate character width
 
  return (
    <div style={wrapperStyle}>
      <div style={columnContainer}>
        {/* Old Content */}
        <div style={columnStyle}>
          <div style={scrollStyle}>
            <div style={contentStyle}>
              {Array.from({ length: maxLines }).map((_, index) => {
                const oldLine = oldLines[index] || "";
                const newLine = newLines[index] || "";
 
                // Determine if the line is changed
                const isRemoved = oldLine && !newLines.includes(oldLine);
                const style = isRemoved ? removedStyle : {};
 
                return (
                  <div key={index} style={{ ...rowStyle, ...style, width: maxWidth }}>
                    {isRemoved ? `- ${oldLine}` : oldLine}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
 
        {/* New Content */}
        <div style={columnStyle}>
          <div style={scrollStyle}>
            <div style={contentStyle}>
              {Array.from({ length: maxLines }).map((_, index) => {
                const oldLine = oldLines[index] || "";
                const newLine = newLines[index] || "";
 
                // Determine if the line is added
                const isAdded = newLine && !oldLines.includes(newLine);
                const style = isAdded ? addedStyle : {};
 
                return (
                  <div key={index} style={{ ...rowStyle, ...style, width: maxWidth }}>
                    {isAdded ? `+ ${newLine}` : newLine}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
 
// Styles
const wrapperStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  padding: "10px",
  fontFamily: "monospace",
  width: "100%",
  maxWidth: "900px",
  margin: "0 auto",
};
 
const columnContainer: React.CSSProperties = {
  display: "flex",
  width: "100%",
  border: "1px solid #ddd",
  borderRadius: "5px",
  overflow: "hidden",
  height: "300px",
};
 
const columnStyle: React.CSSProperties = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  borderRight: "1px solid #ddd",
  overflow: "hidden",
};
 
const scrollStyle: React.CSSProperties = {
  flex: 1,
  overflowY: "auto",
  padding: "10px",
  backgroundColor: "#ffffff", // Keep scroll background white
  color: "#000",
  height: "100%",
};
 
const contentStyle: React.CSSProperties = {
  whiteSpace: "pre",
  wordBreak: "break-word",
  width: "100%",
};
 
const rowStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  padding: "5px",
  borderBottom: "1px solid #ddd",
};
 
const removedStyle: React.CSSProperties = {
  backgroundColor: "#ffeef0", // Light red for removed
  color: "#d73a49",
};
 
const addedStyle: React.CSSProperties = {
  backgroundColor: "#e6ffed", // Light green for added
  color: "#22863a",
};
 
export default DiffViewer;
 
 