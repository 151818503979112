// Import necessary dependencies and hooks
import React, { useEffect, useState } from 'react';
import { useButtonActions } from './ButtonComponentActionContext';
import { useNavLink } from '../NavLinkComponent/NavLinkContext';

// Define the props interface for the ButtonComponent
interface ButtonComponentProps {
  id: string; 
  setNavOpen?: React.Dispatch<React.SetStateAction<string>>;
}

// Functional component for rendering a dynamic button based on its configuration
const ButtonComponent: React.FC<ButtonComponentProps> = ({ id }) => {

  const { getButtonConfig, handleNewChat, askAsami, handleToggle, leftNav, setLeftNav } = useButtonActions();

  const [buttonConfig, setButtonConfig] = useState(getButtonConfig(id));
  const { activeTab, setActiveTab } = useNavLink();

  // Effect to update the button configuration whenever the `id` or `getButtonConfig` changes
  useEffect(() => {
    const config = getButtonConfig(id);
    setButtonConfig(config);
    setLeftNav(leftNav); 
  }, [id, getButtonConfig, leftNav]);

  // Warn and return null if no configuration is found for the given button ID
  if (!buttonConfig) {
  
    return null;
  }

  // Handle button click actions based on the button's ID
  const handleClick = () => {
    switch (id) {
      case 'new-chat': 
        handleNewChat();
        break;
      case 'ask-asami': 
        setActiveTab("");
        askAsami();
        break;
      case 'toggle': 
        handleToggle();
        break;
      default: 
        console.warn('No action defined for this button');
    }
  };

  // Render the button with dynamic styles and content
  return (
    <button
      className={buttonConfig.className} 
      onClick={handleClick} 
      type="button"
      style={
        id === 'toggle'
          ? { backgroundColor: "transparent", border: "transparent" }
          : id === 'new-chat'
          ? { width: "120%", marginLeft: "30%" }
          : id === 'ask-asami'
          ? { width:"100%" }
          : undefined
      }
    >
      {/* Render the button's icon if it exists in the configuration */}
      {buttonConfig.iconSrc && (
        <img
          src={`${buttonConfig.iconSrc}?${Date.now()}`} 
          alt={buttonConfig.iconAlt} 
          key={buttonConfig.iconSrc} 
          className={id === 'toggle' ? buttonConfig.className : ""}
        />
      )}

      {/* Render the button's text if it exists in the configuration */}
      {buttonConfig.text && (
        <span className={`custom-none ms-1 ${buttonConfig.textClassName}`}>
          {buttonConfig.text}
        </span>
      )}

    </button>
  );
};

// Export the ButtonComponent as the default export
export default ButtonComponent;