import React from 'react';
 
interface ChoiceButtonsProps {
  choices: string[];
  onSubmit: (choice: string) => void;
}
 
const ChoiceButtons: React.FC<ChoiceButtonsProps> = ({ choices, onSubmit }) => {
    
  return (
    <div className="d-flex flex-wrap gap-2" style={{"marginLeft":"2%"}}>
        {choices.map((choice, index) => (
    <button
            key={index}
            onClick={() => onSubmit(choice)}
            className="btn btn-outline-primary border-radius-4 font-14 px-3 py-2"
            style={{
                borderColor: '#865BE7',
                color: '#865BE7',
                background: 'transparent',
                transition: 'all 0.2s ease',
            }}
            onMouseOver={(e) => {
                e.currentTarget.style.background = '#865BE7';
                e.currentTarget.style.color = 'white';
            }}
            onMouseOut={(e) => {
                e.currentTarget.style.background = 'transparent';
                e.currentTarget.style.color = '#865BE7';
            }}
    >
            {choice}
    </button>
        ))}
    </div>
  );
};
export default ChoiceButtons