
import React, { useState, useRef,useEffect } from 'react';
import { usePromptBuilder } from '../Utility/ManagePromptContext';

interface PopupBuilderProps {
  popupType: 'input' | 'chatHistory' | 'feedback';
  promptDropDown?: Array<{ Prompt_id: string; Prompt_name: string }>;
  emojiData?: Array<{ emoji_id: string; blob_url: string }>;
  initialData?: {
    userContent: string;
    assistantContent: string;
    index: number;
  };
  index?:any;
  showModal: boolean;
  editMode?: boolean;
  onClose: () => void;
  onSave?: (data: any) => void;
}

const PopupBuilder: React.FC<PopupBuilderProps> = ({
  index,
  popupType,
  showModal,
  promptDropDown = [],
  emojiData = [],
  initialData,
  editMode = false,
  onClose,
  onSave,
}) => {
  const { userContent, assistantContent, setUserContent, setAssistantContent, handleFewShotSave, handleFewShotEdit } = usePromptBuilder();
  const userRef = useRef<HTMLTextAreaElement>(null);
  const assistantRef = useRef<HTMLTextAreaElement>(null);
  
  const [editedName, setEditedName] = useState('');
  const [selectedPrompt, setSelectedPrompt] = useState('');
  const [selectedEmojiId, setSelectedEmojiId] = useState<number | null>(null);
  const [userDescription, setUserDescription] = useState('');
  const [error, setError] = useState('');


    
  const handleFewshotExample = () => {
    document.body.classList.remove("no-scroll-body")
    if (editMode === false) {
      handleFewShotSave();
    } else if (initialData) {
      handleFewShotEdit(initialData.index, userContent, assistantContent);
    }
    onClose();
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedName(e.target.value);
  };

  const handleSaveClick = () => {
    if(onSave)
    onSave({ editedName });
    onClose();
  };

  const handlePrompt = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedPrompt(e.target.value);
  };

  const handleEmojiClick = (emojiId: string, index: number) => {
    setSelectedEmojiId(index);
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUserDescription(e.target.value);
  };

  const handleinsertFeedback = () => {
    if (!selectedPrompt || selectedEmojiId === null || !userDescription) {
      setError('Please fill all required fields');
      return;
    }
    if(onSave)
    onSave({ selectedPrompt, selectedEmojiId, userDescription });
    onClose();
  };


  const renderInputPopup = () => (
    <>
     <div id="backdrop" className={`backdrop show`}></div>
    <div className="modal-dialog modal-dialog-centered modal-lg example-popup">
      <div className="modal-content bg-color-4">
        <div className="modal-header border-0">
          <h1 className="modal-title fs-5 text-color-1" id="staticBackdropLabel">
            {editMode ? 'Edit Example' : 'Example'}
          </h1>
          <button type="button" className="btn-close close-icon-filter" onClick={onClose}></button>
        </div>
        <div className="modal-body">
          <div className="row row-gap-32px">
            <div className="col-md-12">
              <label htmlFor="FewShotUserInput" className="form-label font-15 font-semibold text-color-1 mb-2">
                Few Shot User Input
              </label>
              <textarea
                className="form-control input-field font-14 font-regular border-radius-3 bg-color-4 custom-border-3 text-color-4 resize-none shadow-none"
                id="FewShotUserInput"
                rows={4}
                placeholder="Enter Input"
                ref={userRef}
                defaultValue={initialData?.userContent}
                onChange={(e) => setUserContent(e.target.value)}
                disabled={!editMode}
              />
            </div>
            <div className="col-md-12">
              <label htmlFor="ExpectedAIResponse" className="form-label font-15 font-semibold text-color-2 mb-2">
                Expected AI Response
              </label>
              <textarea
                className="form-control input-field font-14 font-regular border-radius-3 bg-color-4 custom-border-3 text-color-4 resize-none shadow-none"
                id="ExpectedAIResponse"
                rows={4}
                placeholder="Enter Input"
                ref={assistantRef}
                defaultValue={initialData?.assistantContent}
                onChange={(e) => setAssistantContent(e.target.value)}
                disabled={!editMode}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer border-0">
          {editMode?(<><button type="button" className="btn custom-outline-btn font-14 px-4 py-2 me-3 font-bold" onClick={onClose}>
            Cancel
          </button>
          <button type="button" className="btn primary-btn font-14 font-bold border-radius-5 color-white px-4 py-2" onClick={handleFewshotExample}>
            Save
          </button></>):(<></>)}
          
        </div>
      </div>
    </div>
    </>
  );

  const renderChatHistoryPopup = () => (
    <div className="modal-dialog modal-dialog-centered custom-popup-issues border-1">
      <div className="modal-content p-4 border-0 bg-color-4">
        <div className="modal-header border-0 p-0">
          <h5 className="modal-title text-color-1 font-22 font-bold ">Edit Chat Name</h5>
          <button type="button" className="custom-close me-0 bg-transparent" onClick={onClose}>
            <img className="close-icon-filter" src="./images-new/close-icon.svg" alt="close-icon"/>
          </button>
        </div>
        <div className="modal-body popup-scrl pt-2">
          <div className="row">
            <div className="col-md-12 col-sm-12 mt-3 mb-5 p-0">
              <label htmlFor="chat-name" className="form-label label-color font-15 font-semibold text-color-2 mb-2">Chat Name</label>
              <input 
                type="text" 
                className="form-control bg-color-4 text-color-4 custom-border-3 cust-search-input" 
                id="exampleInputEmail1" 
                aria-describedby="emailHelp"
                value={editedName}
                onChange={handleNameChange}
                placeholder="Enter Chat Name"
              />
            </div>
          </div>
        </div>
        <div className="modal-footer border-0 gap-3">
          <button type="button" className="btn custom-outline-btn font-14 px-4 pb-2 m-0 font-medium" onClick={onClose}>Cancel</button>
          <button type="button" className="btn primary-btn font-14 font-semibold border-radius-5 color-white pb-2 px-4 m-0" onClick={handleSaveClick}>Save</button>
        </div>
      </div>
    </div>
  );

  const renderFeedbackPopup = () => (
    <div className="modal-dialog modal-dialog-centered feedback-popup border-0">
      <div className="modal-content p-4 border-0">
        <div className="modal-header border-0 pt-2 pb-2 px-0">
          <div>
            <h5 className="modal-title text-dark font-22 font-bold">Feedback</h5>
            <p className="font-medium font-15 color-grey-v7">Tell us what you think about prompt and help us improve</p>
            {error && <span style={{ color: 'red' }}>{error}</span>}
          </div>
          <button type="button" className="btn-close" onClick={onClose} />
        </div>
        <div className="modal-body pt-2 px-0 pb-0">
          <div className="row">
            <div className="col-md-7">
            <div className="mb-4">
                <label htmlFor="from" className="form-label label-color font-15 font-semibold">Select Prompt <span style={{ color: 'darkred' }}>*</span></label>
                <select className="form-select input-field font-14 font-regular border-radius-3" value={selectedPrompt} onChange={handlePrompt}>
                  <option value="">Select</option>
                  {promptDropDown.map((prompt, index) => (
                    <option key={index} value={prompt.Prompt_id}>{prompt.Prompt_name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <label className="form-label label-color font-15 font-semibold d-block">What do you think of this tool ?<span style={{ color: 'darkred' }}>*</span></label>
            <div className="btn-group" role="group" aria-label="Basic example">
              {emojiData.map((emoji, index) => (
                <button 
                  key={index} 
                  type="button"   
                  className={`emoji-active align-items-center me-5 position-relative ${selectedEmojiId === index ? 'selected' : ''}`} 
                  onClick={() => handleEmojiClick(emoji.emoji_id, index)}
                >
                  <img src={emoji.blob_url} alt={`emoji-${index}`} />
                </button>
              ))}
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="from" className="form-label label-color font-15 font-semibold">Do you have any thoughts you'd like to share?<span style={{ color: 'darkred' }}>*</span></label>
            <textarea 
              rows={4} 
              className="form-control resize-none input-field" 
              placeholder="Enter your thoughts" 
              id="floatingTextarea" 
              value={userDescription} 
              onChange={handleDescriptionChange} 
            />
          </div>
        </div>
        <div className="modal-footer border-0 gap-3 px-0">
          <button type="button" className="btn primary-btn font-14 font-semibold border-radius-5 color-white px-4 m-0" onClick={handleinsertFeedback}>Send</button>
        </div>
      </div>
    </div>
  );

  if (!showModal) return null;

  return (
    <div className={`modal fade show`} style={{ display: 'block' }}>
      {popupType === 'input' && renderInputPopup()}
      {popupType === 'chatHistory' && renderChatHistoryPopup()}
      {popupType === 'feedback' && renderFeedbackPopup()}
    </div>
  );
};

export default PopupBuilder;