import { Client } from './Client';
import { UrlsAi, ValyriaEndpoints } from './API-Constants';
import axios, { CancelToken } from 'axios';


interface ErrorLogBody {
  errorMessage: string;
  userEmailID: string | undefined;
  errorOccured: string;
  functionName:string;
}

export async function errorLogs(body: ErrorLogBody): Promise<any> {
  try {
    const result = await Client(UrlsAi?.insertErrorLogs, body, "POST");
    return 'data' in result ? result?.data : null;
  } catch (error: any) {
    console?.error(`error in function errorLogs: `, error);
    return null;
  }
}

export async function getValyriaID(body: any = {}): Promise<any> {
  try {
    const result = await Client(ValyriaEndpoints?.valyriaId, body, "GET");
   
    return 'data' in result ? result.data : null;
  } catch (error: any) {
    console?.error(`error in function CostCalculationNav: `, error);
    return null;
  }
}
  

export async function getAllRules(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.getRules, body, "POST");
    
    return 'data' in result ? result : null;
  } catch (error: any) {
    console?.error(`error in function CostCalculationNav: `, error);
    return null;
  }
}

export async function addRule(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.postRules, body, "POST");
    return 'data' in result ? result?.data : null;
  } catch (error: any) {
    console?.error(`error in function CostCalculationNav: `, error);
    return null;
  }
}


export async function getBlobURL(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.getS3URL, body, "POST");
    return 'data' in result ? result?.data : null;
  } catch (error: any) {
    console?.error(`error in function getBlobURL: `, error);
    return null;
  }
}

export async function deleteRule(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.postRules, body, "POST");
    return 'data' in result ? result?.data : null;
  } catch (error: any) {
    console?.error(`error in function CostCalculationNav: `, error);
    return null;
  }
}
export async function CostCalculationNav(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.calculateCost, body, "POST");
    return 'data' in result ? result?.data : null;
  } catch (error: any) {
    console?.error(`error in function CostCalculationNav: `, error);
    return null;
  }
}

export async function getInputTypeAndTechStack(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.getInputTypeAndTechStack, body, "post");
    return result;
  } catch (error: any) {
    console?.error(`error in function getInputTypeAndTechStack: `, error);
    return null;
  }
}


export async function generateNewPrompt(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.inputEnhancer, body, "POST");
    return 'data' in result ? result?.data : null;
  } catch (error: any) {
    console?.error(`error in function generateNewPrompt: `, error);
    return null;
  }
}


export async function validateD2Diagram(code: string): Promise<any> {
  try {
    const response = await Client(UrlsAi?.d2Service, { code }, "post");
    return 'data' in response ? response?.data : null;
  } catch (error: any) {
    console?.error(`error in function validateD2Diagram: `, error);
    return null;
  }
}

export async function fetchPlantUMLDiagram(code: string): Promise<any> {
  try {
    const response = await Client(UrlsAi?.plantUMLKroki, code, 'post');
    return { diagramSvg: response?.data, error: null };
  } catch (error) {
    console?.error('Error fetching diagram:', error);
    return null;
  }
}

export async function generateD2Diagram(finalCode: string): Promise<any> {
  try {
    const response = await Client(UrlsAi?.krokiService, finalCode, "post");
    return 'data' in response ? response?.data : null;
  } catch (error: any) {
    console?.error(`error in function generateD2Diagram: `, error);
    return null;
  }
}


export async function getApprovalRequestData(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.getApprovalRequestData, body, "POST");
    return result;
  } catch (error: any) {
    console?.error(`error in function getApprovalRequestData: `, error);
    return null;
  }
}

export async function getPromptFieldJson(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.getPromptFieldJson, body, "post");
    return result;
  } catch (error: any) {
    console?.error(`error in function getPromptFieldJson: `, error);
    return null;
  }
}

export async function deletePrompt(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.deletePrompt, body, "post");
    return result;
  } catch (error: any) {
    console?.error(`error in function deletePrompt: `, error);
    return null;
  }
}

export async function getBindParams(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.getBindParams, body, "get");
    return result;
  } catch (error: any) {
    console?.error(`error in function getBindParams: `, error);
    return null;
  }
}

export async function getModelConfig(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.getModelConfiguration, body, "POST");
    return result?.data;
  } catch (error: any) {
    console?.error(`error in function getModelConfig: `, error);
    return null;
  }
}

export async function getAllModelConfig(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.getAllModelConfiguration, body, "POST");
    return result;
  } catch (error: any) {
    console?.error(`error in function getAllModelConfig: `, error);
    return null;
  }
}

export async function getPractice(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.getPractice, body, "POST");
    return result;
  } catch (error: any) {
    console?.error(`error in function getPractice: `, error);
    return null;
  }
}

export async function deleteModelConfiguration(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.deleteModelConfiguration, body, "POST");
    return result;
  } catch (error: any) {
    console?.error(`error in function deleteModelConfiguration: `, error);
    return null;
  }
}

export async function kbInsert(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.kbUpload, body, "POST");
    return result;
  } catch (error: any) {
    console?.error(`error in function kbInsert: `, error);
    return null;
  }
}

export async function serpAPI(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.serpAPI, body, "POST");
    return result;
  } catch (error: any) {
    console?.error(`error in function serpAPI: `, error);
    return null;
  }
}

export async function LoadBalancer(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.loadBalancer, body, "POST");
    return result;
  } catch (error: any) {
    console?.error(`error in function LoadBalancer: `, error);
    return null;
  }
}

export async function fetchActiveUsers(requestBody: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.activeuser, requestBody, "POST");
    return result;
  } catch (error: any) {
    console?.error(`error in function fetchActiveUsers: `, error);
    return null;
  }
}

export async function fetchFixedResponseData(requestBody: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.fixedResponseFetch, requestBody, "POST");
    return result;
  } catch (error: any) {
    console?.error(`error in function fetchFixedResponseData: `, error);
    return null;
  }
}

export async function fetchMostOrLeastUsedPrompt(requestBody: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.promptUsageFetch, requestBody, "POST");
    return result;
  } catch (error: any) {
    console?.error(`error in function fetchMostOrLeastUsedPrompt: `, error);
    return null;
  }
}

export async function fetchAsamiUsageData(requestBody: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.getAsamiUsage, requestBody, "POST");
    return result;
  } catch (error: any) {
    console?.error(`error in function fetchAsamiUsageData: `, error);
    return null;
  }
}

export async function fetchCostAnalysisData(requestBody: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.costAnalysis, requestBody, "POST");
    return result;
  } catch (error: any) {
    console?.error(`error in function fetchCostAnalysisData: `, error);
    return null;
  }
}

export async function fetchProjectCost(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.costAnalysis,body, "POST");
    return 'data' in result ? result?.data.data : null;
  } catch (error: any) {
    console?.error(`error in function fetchProjectCost: `, error);
    return null;
  }
}

export async function fetchPracticeCost(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi.costAnalysis, body, "POST");
    return 'data' in result ? result.data.data : null;
  } catch (error: any) {
    console?.error(`error in function fetchPracticeCost: `, error);
    return null;
  }
}

export async function fetchIndividualCost(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi.costAnalysis, body, "POST");
    return 'data' in result ? result.data.data : null;
  } catch (error: any) {
    console?.error(`error in function fetchIndividualCost: `, error);
    return null;
  }
}



export async function fetchFeedbackData(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.getBindParams, body, "GET");
    return 'data' in result ? result?.data : null;
  } catch (error: any) {
    console?.error(`error in function fetchFeedbackData: `, error);
    return null;
  }
}

export async function getImageDocResponse(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.getVideoResponse, body, "POST");
    return 'data' in result ? result?.data : null;
  } catch (error: any) {
    console?.error(`error in function getImageDocResponse: `, error);
    return null;
  }
}

export async function getS3URL(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.getS3URL, body, "POST");
    return 'data' in result ? result?.data : null;
  } catch (error: any) {
    console?.error(`error in function getBlobURL: `, error);
    return null;
  }
}

export async function getProjectConfig(body: any = {}, cancelToken?: any): Promise<any> {
  try {
    const result = await Client(UrlsAi?.fetchProjectConfig, body, "POST", cancelToken);
    return result?.data;
  } catch (error: any) {
    console?.error(`error in function getProjectConfig: `, error);
    return null;
  }
}

export async function getIssuesConfig(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi.getIssuesConfig, body, "POST");
    return result.data;
  } catch (error: any) {
    console?.error(`error in function getIssuesConfig: `, error);
    return null;
  }
}

export async function getTaskConfig(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi.getTaskConfig, body, "POST");
    return result.data;
  } catch (error: any) {
    console?.error(`error in function getTaskConfig: `, error);
    return null;
  }
}


export async function getProject(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.allProjects, body, "POST");
    return result;
  } catch (error: any) {
    console?.error(`error in function getProject: `, error);
    return null;
  }
}





export async function getIssues(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.fetchIssues, body, "POST");
    return result;
  } catch (error: any) {
    console?.error(`error in function getIssues: `, error);
    return null;
  }
}

export async function getTask(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.fetchTask, body, "POST");
    return result;
  } catch (error: any) {
    console?.error(`error in function getTask: `, error);
    return null;
  }
}

export async function similarityCheck(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.similarityCheck, body, "POST");
    return result;
  } catch (error: any) {
    console?.error(`error in function similarityCheck: `, error);
    return null;
  }
}

export async function insertModelConfig(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.insertModelConfiguration, body, "POST");
    return 'data' in result ? result?.data : null;
  } catch (error: any) {
    console?.error(`error in function insertModelConfig: `, error);
    return null;
  }
}

export async function restoreManagePrompt(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.insertPrompt, body, "POST");
    return 'data' in result ? result?.data : null;
  } catch (error: any) {
    console?.error(`error in function restoreManagePrompt: `, error);
    return null;
  }
}

export async function getPromptHistory(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.getPromptHistory, body, "POST");
    console?.log("result",result)
    return 'data' in result ? result?.data : null;
  } catch (error: any) {
    console?.error(`error in function getPromptHistory: `, error);
    return null;
  }
}

export const fetchPrompt = async (body: any = {}): Promise<any> => {
  try {
    const result = await Client(UrlsAi?.fetchPromptData, body, "post");
    return result
  } catch (error: any) {
    console?.error(`error in function fetchPrompt: `, error);
    return null;
  }
};

export async function fetchPromptHistory(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.getPromptHistory, body, "POST");
    return 'data' in result ? result?.data : null;
  } catch (error: any) {
    console?.error(`error in function fetchPromptHistory: `, error);
    return null;
  }
}

export const insertGetFeedBack = async (Reqbody: any = {}): Promise<any> => {
  try {
    const response = await Client(UrlsAi?.insertGetFeedBack, Reqbody, "POST");
    return 'data' in response ? response?.data : null;
  } catch (error: any) {
    console?.error(`error in function insertGetFeedBack: `, error);
    return null;
  }
};

export const fetchPromptEmj = async (Reqbody: any = {}): Promise<any> => {
  try {
    const response = await Client(UrlsAi?.getBindParams, Reqbody, "GET");
    return 'data' in response ? response?.data : null;
  } catch (error: any) {
    console?.error(`error in function fetchPromptEmj: `, error);
    return null;
  }
};

export async function getProjectForPrompt(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.allProjects, body, "POST");
    return 'data' in result ? result?.data : null;
  } catch (error: any) {
    console?.error(`error in function getProjectForPrompt: `, error);
    return null;
  }
}

export async function kgBuildService(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.functionalDescription, body, "POST");
    return result;
  } catch (error: any) {
    console?.error(`error in function kgBuildService: `, error);
    return null;
  }
}

export async function ExistData(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.configRoute, body, "POST");
    return 'data' in result ? result?.data : null;
  } catch (error: any) {
    console?.error(`error in function ExistData: `, error);
    return null;
  }
}

export async function tfsconfigForm(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.postTfsConfig, body, "POST");
    return 'data' in result ? result?.data : null;
  } catch (error: any) {
    console?.error(`error in function tfsconfigForm: `, error);
    return null;
  }
}

export async function saveConfiguration(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.configureOrUpdate, body, "POST");
    return 'data' in result ? result?.data : null;
  } catch (error: any) {
    console?.error(`error in function saveConfiguration: `, error);
    return null;
  }
}

export async function fetchReposAndBranch(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.getRepoandBranch, body, "POST");
    return 'data' in result ? result?.data : null;
  } catch (error: any) {
    console?.error(`error in function fetchReposAndBranch: `, error);
    return null;
  }
}

export async function insertPrompt(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.insertPrompt, body, "post");
    
    return result;
  } catch (error: any) {
    console?.error(`error in function insertPrompt: `, error);
    return null;
  }
}

export async function promptGen(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.promptGen, body, "post");
    return 'data' in result ? result?.data : null;
  } catch (error: any) {
    console?.error(`error in function promptGen: `, error);
    return null;
  }
}

export async function systemMessageGen(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.systemMessageGen, body, "post");
    return 'data' in result ? result?.data : null;
  } catch (error: any) {
    console?.error(`error in function systemMessageGen: `, error);
    return null;
  }
}

export async function KBRetriever(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.kbRetriever, body, "POST");
    return result;
  } catch (error: any) {
    console?.error(`error in function KBRetriever: `, error);
    return null;
  }
}

export async function getChatHistory(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.getChatHistory, body, "POST");
    return result;
  } catch (error: any) {
    console?.error(`error in function getChatHistory: `, error);
    return null;
  }
}

export const insertMessage = async (body: any = {}): Promise<any> => {
  try {
    const result = await Client(UrlsAi?.postMessage, body, "POST");
    return 'data' in result ? result?.data : null;
  } catch (error: any) {
    console?.error(`error in function insertMessage: `, error);
    return null;
  }
};

export const fetchChat = async (body: any = {}): Promise<any> => {
  try {
    const result = await Client(UrlsAi?.getChat, body, "POST");
    return 'data' in result ? result?.data : null;
  } catch (error: any) {
    console?.error(`error in function fetchChat: `, error);
    return null;
  }
};

export async function DownloadAsExcel(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.downloadExcel, body, "POST");
    return result;
  } catch (error: any) {
    console?.error(`error in function DownloadAsExcel: `, error);
    return null;
  }
}

export async function ProcessRequirement(body:any={}):Promise<any>{
  try {
    const result = await Client(UrlsAi?.processRequirement, body, "POST");
    return result;
  } catch (error: any) {
    console?.error(`error in function ProcessRequirement: `, error);
    return null;
  }
} 

export async function getPresignedURL(body: any = {}): Promise<any> {
  try {
    const result = await Client(UrlsAi?.getPresignedURL, body, "POST");
    return result;
  } catch (error: any) {
    console?.error(`error in function DownloadAsPDF: `, error);
    return null;
  }
}


export const  layerCheck = async (body: any = {}): Promise<any> => {
  try {
    const result = await Client(UrlsAi?.processChat, body, "POST");
    return 'data' in result ? result.data : null;
  } catch (error: any) {
    console.error(`error in function insertMessage: `, error);
    return null;
  }
};

export const chatCreation = async (body: any = {}): Promise<any> => {
  try {
    const result = await Client(UrlsAi.insertChat, body, "POST");
    return 'data' in result ? result.data : null;
  } catch (error: any) {
    console.error(`error in function insertMessage: `, error);
    return null;
  }
};

export async function uploadHtml(body: any = {}): Promise<any> {
  try {
    const result = await Client(ValyriaEndpoints?.uploadhtml,  body, "POST");
    return 'data' in result ? result?.data : null;
  } catch (error: any) {
    console?.error(`error in function getBlobURL: `, error);
    return null;
  }
}
export async function uploadImage(body: any = {}): Promise<any> {
  try {
    const result = await Client(ValyriaEndpoints?.uploadimage, body, "POST");
    return 'data' in result ? result?.data : null;
  } catch (error: any) {
    console?.error(`error in function getBlobURL: `, error);
    return null;
  }
}
export async function orchestrate(body: any = {}): Promise<any> {
  try {
    const result = await Client(ValyriaEndpoints?.orchestrate, body, "POST");
    return 'data' in result ? result?.data : null;
  } catch (error: any) {
    console?.error(`error in function getBlobURL: `, error);
    return null;
  }
}


export const fetchPlannerData = async (body: any = {}): Promise<any> => {
  try {
    const result = await Client(ValyriaEndpoints?.getPlanner, body, "POST");
    return 'data' in result ? result.data : null;
  } catch (error: any) {
    console.error(`error in function insertMessage: `, error);
    return null;
  }
}; 


export const fetchBrowserImages = async (body: any = {}): Promise<any> => {
  try {
    const result = await Client(ValyriaEndpoints?.getbrowserurl, body, "POST");
    return 'data' in result ? result.data : null;
  } catch (error: any) {
    console.error(`error in function insertMessage: `, error);
    return null;
  }
};

export async function getDesignData(body: any = {}): Promise<any> {
  try {
    const result = await Client(ValyriaEndpoints?.getdesign, body, "POST");
    return 'data' in result ? result?.data : null;
  } catch (error: any) {
    console?.error(`error in function CostCalculationNav: `, error);
    return null;
  }
}
export async function getCodeData(body: any = {}): Promise<any> {
  try {
    const result = await Client(ValyriaEndpoints?.getcode, body, "POST");
    return 'data' in result ? result?.data : null;
  } catch (error: any) {
    console?.error(`error in function CostCalculationNav: `, error);
    return null;
  }
}

export async function getValyriaChat(body: any = {}): Promise<any> {
  try {
    const result = await Client(ValyriaEndpoints?.getchatinput, body, "POST");
    return 'data' in result ? result?.data : null;
  } catch (error: any) {
    console?.error(`error in function CostCalculationNav: `, error);
    return null;
  }
}

export async function downloadCode(body: any = {}): Promise<any> {
  try {
    const result = await Client(ValyriaEndpoints?.downloadcode, body, "POST");
   
    return 'data' in result ? result.data : null;
  } catch (error: any) {
    console?.error(`error in function CostCalculationNav: `, error);
    return null;
  }
}