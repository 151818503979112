import React, { useState, useEffect, useRef } from 'react';
import { useKnowledgeBase } from '../Utility/AddKnowledgeBaseContext';
import { useTableContext } from '../Utility/ReusableTableContext';
import ButtonComponent from './Button';
import InputComponent from './TextInput';
import ReusableTable from './ReusableTable';
import Tab from './TabSwitch';
import { usePromptBuilder } from '../Utility/ManagePromptContext';
import Loader from './Loader';
 
interface AddKnowledgeBaseProps {
  show: boolean;
  existingKbUrls: string[];
  currentChatID: string | null;
  onHide: () => void;
}
 
const AddKnowledgeBase: React.FC<AddKnowledgeBaseProps> = ({
  show,
  existingKbUrls,
  currentChatID,
  onHide,
}) => {
  const {
    files, setFiles,
    urls, setUrls,
    url, setUrl,
    errorMessage,
    errorVal,
    fileInputRef,
    collectionName,
    setCollectionName,
    handleFileUpload,
    handleUrlAdd,
    handleSave,
    handleCancel,
    handleUrlCancel,
    askAsamiKbData,
    setAskAsamiKbData,
    kbURL,
    setKbUrl,
  } = useKnowledgeBase();
  const { promptData, setPromptData, HandlePromptHistory } = usePromptBuilder();
  const { setData } = useTableContext();
 
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [activeTab, setActiveTab] = useState('Files');
  const [newUrls, setNewUrls] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
 
  const dropRef = useRef<HTMLDivElement>(null);
 
  useEffect(() => {
    if (activeTab === 'Files') {
      setData(files.map((file, index) => ({ name: file.name, index })));
    } else {
      if (typeof urls[0] === 'object') {
        const names = urls.map((item: any) => item.name);
        const uniqueNames = Array.from(new Set(names)); // Remove duplicates
        setData(uniqueNames.map((url, index) => ({ url, index })));
      } else {
        const uniqueUrls = Array.from(new Set(urls)); // Remove duplicates
        setData(uniqueUrls.map((url, index) => ({ url, index })));
      }
     
    }
  }, [activeTab, files, urls, setData]);
  useEffect(() => {
 
    if ((promptData?.kbFiles.length!=0 || promptData?.kbUrl.length!=0) && sessionStorage.getItem('promptKbFile')!='[]') {
      let content:any = sessionStorage.getItem('promptKbFile')
      // try{
      let kbfiles:any = JSON.parse(content)
      const fileObjects = kbfiles.filter((item:any) => typeof item === 'object') ;
      const urlStrings = kbfiles.filter((item:any) => typeof item === 'string');
      setFiles(fileObjects);
      setUrls(urlStrings);
      // }
     
    }
  }, [promptData]);
 
  if (!show) return null;
 
  const handleFileDelete = (index: number) => {
    setFiles(prevFiles => {
      const newFiles = prevFiles.filter((_, i) => i !== index);
      return newFiles;
    });
  };
 
  const handleUrlDelete = (index: number) => {
    setUrls(prevUrls => {
      const newUrls = prevUrls.filter((_, i) => i !== index);
      return newUrls;
    });
  };
 
  const FileActionButtons = ({ row }: { row: { name: string, index: number } }) => (
    <span className="cursor-pointer" onClick={() => handleFileDelete(row.index)}>
      <img src="images/Default/delete-icons.svg" alt="Delete"  />
    </span>
  );
 
  const UrlActionButtons = ({ row }: { row: { url: string, index: number } }) => (
    <span className="cursor-pointer" onClick={() => handleUrlDelete(row.index)}>
      <img src="images/Default/delete-icons.svg" alt="Delete"  />
    </span>
  );
  const urlLink = ({ row }:  { row: { url: string, index: number } }) => (
    <a
        className="text-decoration-none link-color"
        // data-bs-toggle="modal"
        // data-bs-target="#specifiedprompts-popup"
        style={{ cursor: 'pointer' }}
        href={row.url}
        target="_blank"
        rel="noopener noreferrer"
    >
        {row.url}
    </a>
);
 
 
  const fileHeaders = [
    { key: 'name', label: 'Files', sortable: false },
    { key: 'actions', label: 'Actions', component: FileActionButtons },
  ];
 
  const urlHeaders = [
    { key: 'url', label: 'URLs', sortable: false,component:urlLink },
    { key: 'actions', label: 'Actions', component: UrlActionButtons },
  ];
 
  const tabContents = {
    'Files': 'file',
    'URL': 'url'
  };
 
  const handleCancelAndClear = () => {
    handleCancel();
    handleUrlCancel();
    setFiles([]);
    setUrls([]);
    setPromptData((prevData: any) => ({
      ...prevData,
      kbFiles: [],
      kbUrl: []
    }));
    onHide();
  };
 
  const handleSaveAndClose = async () => {
    try {
      setIsLoading(true)
      const res = await handleSave(currentChatID);
      setPromptData((prevData: any) => ({
        ...prevData,
        kbFiles: res?.inputs,
        kbUrl: res?.blob_urls
    }));
 
    sessionStorage.setItem('promptKbFile', JSON.stringify(res?.inputs))
      onHide();
      setIsLoading(false)
    } catch (error) {
      console.error("Error saving knowledge base:", error);
    }
  };  
 
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };
 
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    setFiles(prevFiles => [...prevFiles, ...droppedFiles]);
  };
 
  return (
    <div className="modal fade show" id="Helpus-popup" tabIndex={-1} aria-hidden="true" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
      <div className="modal-dialog modal-dialog-centered custom-codesnippet-width cust-kb-popup border-0" style={{ zIndex: '10' }}>
        <div className="modal-content p-3 border-0 bg-color-4 pt-2">
          <div className="modal-header border-0 pt-2 pb-2 ps-2">
            <h5 className="modal-title text-color-1 font-22 font-bold">Add Knowledge Base</h5>
            <button type="button" className="mt-2 btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onHide} />
          </div>
          {isLoading ? (
            <div style={{ marginBottom: "56%", marginRight: "19%", marginTop: "30%" }}>
              <Loader isLoading={isLoading} navOpening={"Open"} />
            </div>
          ) : (
            <>
              <div className="modal-body pt-2 px-0 temp-fix-kb" style={{height: '70vh'}}>
                <div className="d-inline-flex">
                  <Tab
                    activeTab={activeTab}
                    tabContents={tabContents}
                    onTabClick={setActiveTab}
                  />
                </div>
                <div className="tab-content mt-3" id="pills-newtab">
                  <div
                    className={`tab-pane fade ${activeTab === 'Files' ? 'show active' : ''} px-3`}
                    id="file"
                    role="tabpanel"
                    aria-labelledby="file-tab"
                    ref={dropRef}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                  >
                    <div className="row">
                      <div className="col-md-6 custom-border-right ps-0 cursor-pointer">
                        <p className="font-15 form-label-color font-semibold ">Upload Knowledge Base Files</p>
                        <div className="upload-btn-wrapper-popup">
                          <button className="upload-btn-popup font-12 font-medium color-grey-v2 cursor-pointer" onClick={() => fileInputRef.current?.click()} style={{height: '55vh'}}>
                            <img src="images/Default/popup-upload.svg" alt="upload" className="me-2 mb-3" />
                            Drag or Browse to choose file
                            <p>Maximum File Size : 150 MB</p>
                          </button>
                          <input type="file" name="myfile" onChange={handleFileUpload} ref={fileInputRef} style={{ display: 'none' }} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="table-responsive rounded-3 stack-table-scroll custom-border-4" style={{height:'426px'}}>
                          <ReusableTable headers={fileHeaders} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`tab-pane fade ${activeTab === 'URL' ? 'show active' : ''}`} id="url" role="tabpanel" aria-labelledby="url-tab">
                    <div className="row">
                      <div className="col-md-6 custom-border-right ps-3">
                        <label htmlFor="Enter-Question-6" className="font-bold text-color-1 mb-3">URL</label>
                        <InputComponent
                          type="text"
                          className="form-control theme-focus input-field mb-3 bg-transparent text-color-4 custom-border-3 cust-search-input rounded-3"
                          placeholder="Enter URL"
                          value={url}
                          onChange={(value) => setUrl(value)}
                        />
                        <ButtonComponent
                          text="Add"
                          className="btn primary-btn color-white font-14 font-semibold theme-focus me-3 px-4 w-100 mb-3"
                          onClick={handleUrlAdd}
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="table-responsive rounded-3 stack-table-scroll custom-border-4" style={{height:'426px'}}>
                          <ReusableTable headers={urlHeaders} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h6>
                {errorVal && (
                  <div className="span-color">
                    {errorMessage}
                  </div>
                )}
              </h6>
              <div className="modal-footer border-0">
                <ButtonComponent
                  text="CLEAR"
                  className="btn btn-outline-secondary font-14 px-4 py-2 me-3 font-medium"
                  onClick={handleCancelAndClear}
                />
                {HandlePromptHistory ? (
                  <ButtonComponent
                    text="Save"
                    className="btn primary-btn font-14 font-semibold border-radius-5 color-white px-4 py-2"
                    onClick={handleSaveAndClose}
                  />
                ) : null}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
 
export default AddKnowledgeBase;
 
 