import React, { ReactNode } from 'react';

interface CardProps {
  className?: string;
  style?: React.CSSProperties;
  children: ReactNode;
}

const Card: React.FC<CardProps> = ({ className = '', style = {}, children }) => {
  return (
    <div className={`card custom-dashboard-section ${className}`} style={style}>
      {children}
    </div>
  );
};

export default Card