
// CodeSpace.tsx

import React, { useState, useEffect } from 'react';
import {FolderStructureComponent} from '../../../Reusable Components/Valyria Reusable Component/NewFolderStructure';
import { getCodeData } from '../../../Service/API';
import Loader from '../../../Reusable Components/Loader';



interface FileDetails {
    file_name: string;
    content: string;
}

interface CodeSpaceProps {
    navOpen: string;
    downloadbutton?:boolean;
    chatType?:any;
}

const CodeSpace: React.FC<CodeSpaceProps> = ({  navOpen, downloadbutton,chatType}) => {
    const [codeData, setCodeData] = useState<FileDetails[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const user_id =  sessionStorage.getItem('user_id');  // Replace with actual user_id logic if needed
    const project_id =sessionStorage.getItem('project_id'); // Replace with actual project_id logic if needed
    const codeDisplay = {
        codeDisplay: true
      };
    

    useEffect(() => {
        const fetchcodeData = async () => {
            setLoading(true);
            try {
                const requestBody = {
                    user_id: user_id,
                    project_id: project_id
                };
                const response = await getCodeData(requestBody);
                
                setCodeData(response.data)

            } catch (error) {
                console.error("Error fetching design data:", error);
                setCodeData([]);
            } finally {
                setLoading(false);
            }
        };

        fetchcodeData();
    }, []);

    return (
        <div>
            {loading ? (
                <Loader isLoading={loading} navOpening={navOpen} />
            ) : (
                <FolderStructureComponent codeData={{codeData: codeData}} codeDisplay={codeDisplay} navOpen={navOpen} downloadbutton={downloadbutton} chatType={chatType} />
            )} 
        </div>
    );
};

export default CodeSpace;