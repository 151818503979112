import React, { createContext, useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getIssues, ExistData, getIssuesConfig } from '../Service/API';
import { useTableContext } from "../Utility/ReusableTableContext";
import { useFilter, Option, DateRange } from '../Utility/FilterContext';
import { IssueType } from '../Interfaces/ManageIssues';
import { SingleValue, MultiValue } from 'react-select';
import ButtonComponent from '../Reusable Components/Button';

// Interface for the ManageIssuesContext
interface ManageIssuesContextType {
  issues: IssueType[]; setIssues: React.Dispatch<React.SetStateAction<IssueType[]>>;
  statusDropdown: string[]; setStatusDropdown: React.Dispatch<React.SetStateAction<string[]>>;
  isLoading: boolean; setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  totalRecords: number; setTotalRecords: React.Dispatch<React.SetStateAction<number>>;
  endOffset: number; setEndOffset: React.Dispatch<React.SetStateAction<number>>;
  showConfigPop: boolean; setShowConfigPop: React.Dispatch<React.SetStateAction<boolean>>;
  editScenario: string; setEditScenario: React.Dispatch<React.SetStateAction<string>>;
  url: string; setUrl: React.Dispatch<React.SetStateAction<string>>;
  PAT: string; setPAT: React.Dispatch<React.SetStateAction<string>>;
  issueValueID: string; setValueIssueID: React.Dispatch<React.SetStateAction<string>>;
  searchValue: string; setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  IssueIDs: { issueID: string | number }[]; setIssuesIDs: React.Dispatch<React.SetStateAction<{ issueID: string | number }[]>>;
  configuredIssues: string; setConfiguredIssues: React.Dispatch<React.SetStateAction<string>>;
  handleLoadMore: () => void;
  fetchConfigIssues: () => Promise<void>;
  fetchIssues: () => Promise<void>;
  fetchInitialData: () => Promise<void>;
  handleFilterApply: (newFilters: any) => void;
  handleFilterCancel: () => void;
  handleIssueClick: (issue: IssueType) => void;
  tableHeaders: any;
  setloaddata: any; loaddata: any;
}

// Creates a context for managing issues
const ManageIssuesContext = createContext<ManageIssuesContextType | undefined>(undefined);

// Provides the context for managing issues
export const ManageIssuesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { projectName = '' } = location.state || {};
  const projectState = JSON.parse(sessionStorage.getItem("projectState") || "{}");
  let projectIdFromMP = projectState['projectIdFromMP'] || "";
  let projectNameFromLocal = projectState['projectName'] || "";
  const { data, setData, sortConfig, setSortConfig } = useTableContext<IssueType>();
  const { dropdowns, dateRange, setDropdownValue, setDateRange, clearAllFilters } = useFilter();

  // State for managing issues and their configuration
  const [issues, setIssues] = useState<IssueType[]>([]);
  const [statusDropdown, setStatusDropdown] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [endOffset, setEndOffset] = useState(10);
  const [showConfigPop, setShowConfigPop] = useState(false);
  const [editScenario, setEditScenario] = useState('CONFIG');
  const [url, setUrl] = useState('');
  const [PAT, setPAT] = useState('');
  const [issueValueID, setValueIssueID] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [IssueIDs, setIssuesIDs] = useState<{ issueID: string | number }[]>([]);
  const [configuredIssues, setConfiguredIssues] = useState('');
  const [repoName, setRepoName] = useState();
  const [repoUrl, setRepoUrl] = useState();
  const [issueID, setIssueID] = useState("");
  const [loaddata, setloaddata] = useState<any>(false);

  // Fetches and updates the configuration status of issues
  const fetchConfigIssues = async () => {
    try {
      let data = { "issues": IssueIDs };
      const response = await getIssuesConfig(data);
      setConfiguredIssues(response.data[0].issues);
      const configuredIssueIds = new Set(response.data[0].issues.issues.map((issue: any) => issue.issueID));

      // Update the issues with isConfigured property
      setIssues(prevIssues => {
        const updatedIssues = prevIssues.map(issue => ({
          ...issue,
          isConfigured: configuredIssueIds.has(issue.project_issue_key)
        }));
        setData(updatedIssues);
        setIsLoading(false);
        return updatedIssues;
      });
    } catch (error) {
      console.error('Error in issueFetchIsconfig:', error);
    }
  };
  
  // Converts raw issue data into a standardized format
  function convertIssues(issues: any) {
    return issues.map((issue: { issueID: any; workItem: any; ProjectTimeline: any; AssignedTo: any; Status: any; imageUrl: any; }) => ({
      project_issue_key: issue.issueID,
      work_item: issue.workItem,
      date_range: issue.ProjectTimeline,
      assigned_to: issue.AssignedTo,
      Status: issue.Status,
      imageUrl: issue.imageUrl
    }));
  }

  // Fetches issues based on filters, sorting, and pagination
  const fetchIssues = async () => {
    setIsLoading(true);
    try {
      const filters = {
        ...Object.entries(dropdowns).reduce((acc, [key, value]) => {
          let selectedOptions = value.selectedOptions;
          return {
            ...acc,
            [key]: selectedOptions
              ? Array.isArray(selectedOptions)
                ? selectedOptions.map(o => (typeof o === 'object' && 'value' in o ? o.value : o))
                : typeof selectedOptions === 'object' && 'value' in selectedOptions
                  ? selectedOptions.value
                  : selectedOptions // Use as-is if it's a string or null
              : null
          };
        }, {}),
        fromDate: dateRange.from ? dateRange.from.toISOString() : null,
        toDate: dateRange.to ? dateRange.to.toISOString() : null,
      };

      const response = await getIssues({
        projectID: projectIdFromMP,
        issueID: searchValue,
        filterStatus: (dropdowns?.status?.selectedOptions as SingleValue<Option>)?.value=="Open"?"open": (dropdowns?.status?.selectedOptions as SingleValue<Option>)?.value=="Proposed"?"proposed": (dropdowns?.status?.selectedOptions as SingleValue<Option>)?.value,
        sortColumn: sortConfig.column,
        sortOrder: sortConfig.order == "desc" ? "Desc" : "asc",
        workItemType: "issue",
        ...filters,
        offsetValue: endOffset
      });
      if (response.status === 200) {
        const convertedIssue: any = convertIssues(response.data[0].issues);
        setIssues(convertedIssue || []);
        setTotalRecords(response.data[0].totalRecords || 0);
        setStatusDropdown(['Open', 'Proposed', 'Active', 'Resolved']);
        const responseData: { issues: { issueID: string | number }[] }[] = response.data;
        const uniqueProjectIDs = Array.from(
          new Set(responseData[0].issues.map(issue => issue.issueID))
        ).map(issueID => ({ issueID }));
        if (uniqueProjectIDs.length <= 0) {
          setIsLoading(false);
        }
        setIssuesIDs(uniqueProjectIDs);
      }
    } catch (error) {
      console.error('Error fetching issues:', error);
    }
  };

  // Fetches initial data such as project URL, PAT, and repository details
  const fetchInitialData = useCallback(async () => {
    try {
      const response = await ExistData({ projectID: projectIdFromMP });
      if (response.Responsedata && response.Responsedata[0]) {
        setUrl(response.Responsedata[0].project_url || '');
        setPAT(response.Responsedata[0].PAT || '');
        setRepoName(response.Responsedata[0].Repo_data[0].Repo_name);
        setRepoUrl(response.Responsedata[0].Repo_data[0].Repo_Url);
      } else {
        console.error('Unexpected response structure:', response);
      }
    } catch (error) {
      console.error('Error fetching initial data:', error);
    }
  }, [projectIdFromMP]);

  // Fetches initial data and issues when dependencies change
  useEffect(() => {
    fetchInitialData();
    fetchIssues();
  }, [fetchInitialData, projectIdFromMP, endOffset, sortConfig, searchValue, setData, loaddata]);

  // Fetches configured issues when IssueIDs change or clears data if IssueIDs is empty
  useEffect(() => {
    if (IssueIDs.length > 0) {
      fetchConfigIssues();
    } else {
      setData([]);
    }
  }, [IssueIDs]);

  // Applies filters and refetches issues
  const handleFilterApply = (newFilters: any) => {
    Object.entries(newFilters).forEach(([key, value]) => {
      if (key === 'dateRange') {
        setDateRange(value as DateRange);
      } else {
        setDropdownValue(key, value as SingleValue<Option> | MultiValue<Option>, { action: 'select-option', option: {} as Option });
      }
    });
    fetchIssues();
  };
  const fetchIssuesCancel = async () => {
    setIsLoading(true);
    try {
      const filters = {
        ...Object.entries(dropdowns).reduce((acc, [key, value]) => {
          let selectedOptions = value.selectedOptions;
          return {
            ...acc,
            [key]:  null
          };
        }, {}),
        fromDate: null,
        toDate: null,
      };

      const response = await getIssues({
        projectID: projectIdFromMP,
        issueID: searchValue,
        filterStatus:"",
        sortColumn: sortConfig.column,
        sortOrder: sortConfig.order == "desc" ? "Desc" : "asc",
        workItemType: "issue",
        ...filters,
        offsetValue: endOffset
      });
      if (response.status === 200) {
        const convertedIssue: any = convertIssues(response.data[0].issues);
        setIssues(convertedIssue || []);
        setTotalRecords(response.data[0].totalRecords || 0);
        setStatusDropdown(['Open', 'Proposed', 'Active', 'Resolved']);
        const responseData: { issues: { issueID: string | number }[] }[] = response.data;
        const uniqueProjectIDs = Array.from(
          new Set(responseData[0].issues.map(issue => issue.issueID))
        ).map(issueID => ({ issueID }));
        if (uniqueProjectIDs.length <= 0) {
          setIsLoading(false);
        }
        setIssuesIDs(uniqueProjectIDs);
      }
    } catch (error) {
      console.error('Error fetching issues:', error);
    }
  };

  // Clears all filters and refetches issues
  const handleFilterCancel = useCallback(() => {
    clearAllFilters();
    fetchIssuesCancel();
  }, [clearAllFilters]);

  // Loads more issues by increasing the endOffset
  const handleLoadMore = useCallback(() => {
    setEndOffset(prevOffset => prevOffset + 10);
  }, []);

  // Navigates to the chat page with issue-specific state
  const handleIssueClick = useCallback((issue: IssueType) => {
    navigate('/chat', {
      state: {
        startConversation: true,
        hidePrompts: true,
        sendMessage: false,
        fixButton: true,
        tfsCollectionName: issue.repoName,
        projectName: projectName,
        workItem: issue.work_item,
      }
    });
  }, [navigate, projectName]);

  // Fetches the repository configuration for a specific issue ID
  const getExistConfig = async (issueID: string) => {
    try {
      const req = { ["issue" + 'ID']: issueID };
      const response = await ExistData(req);
      setRepoName(response.data.Responsedata[0].Repo_data.Repo_name);
      let repo_name = response.data.Responsedata[0].Repo_data.Repo_name;
      return repo_name;
    } catch (error) {
      console.error('Error fetching existing config:', error);
    }
  };

  // Handles the "Ask Asami" functionality for a specific issue
  const askAsami = async (issueID: any, workitem: any) => {
    sessionStorage.removeItem('issuesandtaskState');
    let repo_name = await getExistConfig(issueID);
    setIssueID(issueID);

    const state = {
      yesFixButton: true,
      startConversation: true,
      hidePrompts: true,
      sendMessage: false,
      fixButton: true,
      tfsCollectionName: repoName,
      projectName: projectNameFromLocal,
      workItem: workitem,
      issueIDFromMI: issueID,
      collectionName_projects: repo_name,
      isModule: "issues",
      workItemFromMI: workitem,
    };

    // Store the state in sessionStorage
    sessionStorage.setItem('issuesandtaskState', JSON.stringify(state));

    // Use useNavigate to navigate
    navigate('/chat', { state });
  };

  // Checks if an issue is configured by its ID
  const getIsConfigured = (issueID: string) => {
    const config = issues.find((item: any) => item.project_issue_key === issueID);
    return config ? config.isConfigured : null;
  };

  // Defines the table headers with custom rendering logic for each column
  const tableHeaders = useMemo(() => [
    {
      key: 'project_issue_key', label: 'Issue ID',
      component: ({ row }: { row: IssueType }) => (
        <>
          {getIsConfigured(row.project_issue_key) ? (
            <a
              className="text-decoration-none link-color link-click"
              onClick={() => {
                askAsami(row.project_issue_key, row.work_item);
              }}
            >
              {row.project_issue_key}
            </a>
          ) : (
            <span
              onClick={() => {
                askAsami(row.project_issue_key, row.work_item);
              }}
            >
              {row.project_issue_key}
            </span>
          )}
        </>
      ),
      sortable: true
    },
    { key: 'work_item', label: 'Work Item', sortable: true },
    { key: 'date_range', label: 'Project Timeline', sortable: true },
    {
      key: 'assigned_to', label: 'Assigned To',
      component: ({ row }: { row: any }) => (
        <>
          {row.imageUrl ? (
            <img
              src={`data:image/jpeg;base64,${row.imageUrl}`}
              alt="Profile"
              className="profile-img-sm me-2"
              style={{ cursor: "pointer" }}
              title={`${row.assigned_to}`}
            />
          ) : (
            <img
              src="images-new/default-profile-image.png"
              alt="Profile"
              className="profile-img-sm me-2"
              style={{ cursor: "pointer" }}
              title={`${row.assigned_to}`}
            />
          )}
        </>
      ),
      sortable: true
    },
    {
      key: 'Status', label: 'Status',
      component: ({ row }: { row: IssueType }) => (
        <>
          {row.Status === "proposed" ? (
            <div className='d-flex text-start align-items-center gap-2'>
              <span className="status-dot in-progress-bg" />
              <span className="in-progress">Proposed</span>
            </div>
          ) : row.Status === "resolved" ? (
            <div className='d-flex text-start align-items-center gap-2'>
              <span className="status-dot not-started-bg"></span>
              <span className="not-started">Resolved</span>
            </div>
          ) : row.Status === "closed" ? (
            <div className='d-flex text-start align-items-center gap-2'>
              <span className="status-dot in-progress-bg" />
              <span className="in-progress">Closed</span>
            </div>
          ) : (row.Status === "open" ? (
            <div className='d-flex text-start align-items-center gap-2'>
              <span className="status-dot danger-bg"></span>
              <span className="in-delay">Open</span>
            </div>
          ) : (
            <span>Unknown status</span>
          ))}
        </>
      ),
      sortable: true
    },
    {
      key: 'actions',
      label: 'Actions',
      component: ({ row }: { row: IssueType }) => (
        <ButtonComponent
          buttonType="icon"
          iconSrc={row.isConfigured ? "images-new/edit-icons.svg" : "images-new/right-icon.svg"}
          altText={row.isConfigured ? "Edit" : "Configure"}
          onClick={() => {
            setEditScenario(row.isConfigured ? "update" : "config");
            setShowConfigPop(true);
            sessionStorage.setItem('uniqueIdFromLocal', row.project_issue_key);
          }}
        />
      )
    }
  ], [data]);

  return (
    <ManageIssuesContext.Provider value={{
      issues, setIssues,
      statusDropdown, setStatusDropdown,
      isLoading, setIsLoading,
      totalRecords, setTotalRecords,
      endOffset, setEndOffset,
      showConfigPop, setShowConfigPop,
      editScenario, setEditScenario,
      url, setUrl,
      PAT, setPAT,
      issueValueID, setValueIssueID,
      searchValue, setSearchValue,
      IssueIDs, setIssuesIDs,
      configuredIssues, setConfiguredIssues,
      handleLoadMore,
      fetchConfigIssues,
      fetchIssues,
      fetchInitialData,
      handleFilterApply,
      handleFilterCancel,
      handleIssueClick,
      tableHeaders,
      setloaddata,loaddata
    }}>
      {children}
    </ManageIssuesContext.Provider>
  );
};

export const useManageIssues = () => {
  const context = useContext(ManageIssuesContext);
  if (context === undefined) {
    throw new Error('useManageIssues must be used within a ManageIssuesProvider');
  }
  return context;
};