import React, { createContext, useState, ReactNode, useContext } from 'react';
import { MultiValue, SingleValue, ActionMeta } from 'react-select';

// Interface defining the structure of a dropdown option
export interface Option {
  value: string;
  label: string;
}

// Interface defining the structure of a date range
export interface DateRange {
  from: Date | any;
  to: Date | any;
}

// Interface defining the state of a single dropdown
interface DropdownState {
  options: Option[];
  selectedOptions: SingleValue<Option> | MultiValue<Option> | null;
}

// Interface defining the state of multiple dropdowns
interface DropdownsState {
  [key: string]: DropdownState;
}

// Interface defining the structure of the filter context
interface FilterContextType {
  dropdowns: DropdownsState;
  dateRange: DateRange;
  setDropdownValue: (
    key: string,
    newValue: MultiValue<Option> | SingleValue<Option>,
    actionMeta: ActionMeta<Option>
  ) => void;
  setDateRange: (range: DateRange) => void;
  clearAllFilters: () => void;
}

// Create the filter context with an initial undefined value
const FilterContext = createContext<FilterContextType | undefined>(undefined);

// Interface defining the props for the FilterProvider component
interface FilterProviderProps {
  children: ReactNode;
  initialDropdowns?: {
    [key: string]: {
      options: Option[];
    };
  };
}

// Filter provider component to wrap around the application
export const FilterProvider: React.FC<FilterProviderProps> = ({ children, initialDropdowns = {} }) => {
  // State for managing dropdowns and date range
  const [dropdowns, setDropdowns] = useState<DropdownsState>(() => 
    Object.entries(initialDropdowns).reduce((acc, [key, value]) => ({
      ...acc,
      [key]: {
        options: value.options,
        selectedOptions: null
      }
    }), {} as DropdownsState)
  );
  const [dateRange, setDateRange] = useState<DateRange>({ from: null, to: null });

  // Function to update the selected value of a dropdown
  const setDropdownValue = (
    key: string,
    newValue: MultiValue<Option> | SingleValue<Option>,
    actionMeta: ActionMeta<Option>
  ) => {
    setDropdowns(prev => ({
      ...prev,
      [key]: {
        ...prev[key],
        selectedOptions: newValue
      }
    }));
  };

  // Function to clear all filters (dropdowns and date range)
  const clearAllFilters = () => {
    setDropdowns(prev => 
      Object.entries(prev).reduce((acc, [key, value]) => ({
        ...acc,
        [key]: {
          ...value,
          selectedOptions: null
        }
      }), {} as DropdownsState)
    );
    setDateRange({ from: null, to: null });
  };

  // Value object to be provided by the context
  const value = {
    dropdowns, setDropdownValue,
    dateRange, setDateRange, 
    clearAllFilters,
  };

  // Provide the context value to children
  return (
    <FilterContext.Provider value={value}>
      {children}
    </FilterContext.Provider>
  );
};

// Custom hook to access the filter context
export const useFilter = () => {
  const context = useContext(FilterContext);
  if (context === undefined) {
    throw new Error('useFilter must be used within a FilterProvider');
  }
  return context;
};