import React, { createContext, useState, useContext } from 'react';
import { PopupContextType, PopupProviderProps, PopupData, PopupContent } from '../Interfaces/PopupTypes';

const PopupContext = createContext<PopupContextType | undefined>(undefined);

export const PopupProvider: React.FC<PopupProviderProps> = ({ children }) => {

  const [isOpen, setIsOpen] = useState(false);
  const [popupData, setPopupData] = useState<PopupData>({
    title: '',
    content: {},
    className: '',
  });

  // Function to open the popup with the provided title, content, and optional className
  const openPopup = (title: string, content: PopupContent, className?: string) => {
    setPopupData({ title, content, className });
    setIsOpen(true);
  };

  // Function to close the popup
  const closePopup = () => {
    setIsOpen(false);
  };

  // Provide the context value to all children components
  return (
    <PopupContext.Provider value={{ isOpen, openPopup, closePopup, popupData }}>
      {children}
    </PopupContext.Provider>
  );
};

// Custom hook to access the PopupContext
export const usePopup = (): PopupContextType => {

  const context = useContext(PopupContext);

  if (context === undefined) {
    throw new Error('usePopup must be used within a PopupProvider');
  }

  return context;
};