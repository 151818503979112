import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';

// Define the shape of your context
interface ImageDocLinksContextType {
  imageDocLinks: string[];
  setBLinksList: React.Dispatch<React.SetStateAction<string[]>>;
}

// Create the context with an initial undefined value
const ImageDocLinksContext = createContext<ImageDocLinksContextType | undefined>(undefined);

// Define props type for ImageDocLinksProvider
interface ImageDocLinksProviderProps {
  children: ReactNode;
}

export const ImageDocLinksProvider: React.FC<ImageDocLinksProviderProps> = ({ children }) => {
  const [imageDocLinks, setBLinksList] = useState<string[]>([]);

  useEffect(() => {
   
  }, [imageDocLinks]);

  return (
    <ImageDocLinksContext.Provider value={{ imageDocLinks, setBLinksList }}>
      {children}
    </ImageDocLinksContext.Provider>
  );
};

// Custom hook to use the BLinksList context
export const useImageDocLinks = (): ImageDocLinksContextType => {
  const context = useContext(ImageDocLinksContext);
  if (context === undefined) {
    throw new Error('useImageDocLinks must be used within an ImageDocLinksProvider');
  }
  return context;
};