import  { useEffect, useState, useContext } from "react";
import { getInputTypeAndTechStack } from "../../Service/API";
import toggleContext from "../../Utility/Context";
import { ToggleContextType } from "../../Interfaces/ToggleContextTypes";

interface TechStackItem {
  techstackID: string;
  techstackName: string;
  techstackLogo: string;
  typeOfCodeID: string;
}

interface Record {
  record: {
    labelName: string;
    inputValueOrder: string;
    required: string;
  };
  techstackName?: string;
  validationErrors?: {
    [key: string]: string;
  };
  fieldValues: any;
  handleFieldValues: (labelName: string, value: string, inputValueOrder: string, required: string, typeOfCodeID: string) => void;
}

export default function TechStack({ record }: { record: Record }) {
  const [techstack, setTechstack] = useState<TechStackItem[]>([]);
  const [selectedTechStack, setSelectedTechStack] = useState<{ text: string; imageUrl: string }>({ text: "", imageUrl: "" });
  const [error, setError] = useState("");
  const [hideError, setHideError] = useState(true);
  const toggle = useContext(toggleContext) as ToggleContextType | null;

 

  useEffect(() => {
    fetchInputTypeAndTechStack();
  }, []);

  useEffect(() => {
    if (record.techstackName && techstack?.length > 0) {
      const tech = techstack.find(t => t.techstackName.toLowerCase() === record?.techstackName?.toLowerCase());
      if (tech) {
        setSelectedTechStack({ text: tech.techstackName, imageUrl: tech.techstackLogo });
      }
    }
  }, [record.techstackName, techstack]);

  const fetchInputTypeAndTechStack = async () => {
    try {
      const result = await getInputTypeAndTechStack();
      setTechstack(result?.data?.data?.ResponseData?.tech_stacks);
    } catch (error) {
      console.error("Error fetching tech stack:", error);
    }
  };


  const handleTechStackChange = (typeOfCodeID: string, techstackName: string, techstackLogo: string) => {
    setSelectedTechStack({ text: techstackName, imageUrl: techstackLogo });
    setError("");
    setHideError(false);
    record?.handleFieldValues(
      record?.record?.labelName,
      techstackName,
      record?.record?.inputValueOrder,
      record?.record?.required,
      typeOfCodeID
    );
  };

  const renderValidationError = () => {
    if (record?.validationErrors && hideError) {
      const err = record?.record?.inputValueOrder;
      return record?.validationErrors[err] ? (
        <span className="mandatory-star font-14 font-medium mb-0" style={{ color: "red" }}>
          {record?.validationErrors[err]}
        </span>
      ) : null;
    }
    return null;
  };

  return (
    <>
      {record?.record && record?.record?.labelName && (
        <label className="form-label font-15 font-semibold color-secondary-black" htmlFor="Tech-Stack">
          {record?.record?.labelName}
          {record?.record?.required === "true" && <span className="color-red">*</span>}
        </label>
      )}

      {toggle && (
        <div className="row pb-3">
          <div className="col-md-6 col-sm-12 mb-3">
            <div className="input-field pe-2 custom-techstack-selection">
              <ul className="list-unstyled stack-li-scroll mb-0">
                <li className="border-bottom font-14 py-2">Select</li>
                {techstack?.map((value, index) => (
                  <li key={value?.techstackID} className={`${index === techstack?.length - 1 ? 'border-bottom' : ''} font-14 py-2`}>
                    <span className="me-3">
                      <img className="template-dropdown-icon" src={value?.techstackLogo} alt="js-icon" />
                    </span>
                    {value?.techstackName}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}

      {!toggle && (
        <div className="dropdown w-100">
          <button
            className="text-start custom-dropdown-toggle theme-focus input-field py-2 px-3 w-100 bg-white font-14 bg-transparent font-medium color-secondary-black"
            type="button"
            id="choose-teck"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {selectedTechStack?.text && selectedTechStack?.imageUrl ? (
              <span className="me-3">
                <img className="template-dropdown-icon" src={selectedTechStack?.imageUrl} alt="js-icon" />
                {selectedTechStack?.text}
              </span>
            ) : (
              "Select"
            )}
          </button>
          <ul className="dropdown-menu w-100 py-0 techstackbackground " aria-labelledby="dropdownMenuButton1" style={{"overflowY":"scroll","height":"200px",position:"fixed"}}>
            {techstack?.map((val) => (
              <li
                key={val.techstackID}
                className="py-2 "
                onClick={() => handleTechStackChange(val?.typeOfCodeID, val?.techstackName, val?.techstackLogo)}
              >
                <a className="dropdown-item custom-dropdown-li font-14 font-medium py-2 color-secondary-black">
                  <span className="me-3">
                    <img className="template-dropdown-icon" src={val?.techstackLogo} alt="js-icon" />
                  </span>
                 <span className="techstackbackgroundfont"> {val?.techstackName}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}

      {error && (
        <span className="mandatory-star font-14 font-medium mb-0" style={{ color: "red" }}>
          {error}
        </span>
      )}
      {renderValidationError()}
    </>
  );
}