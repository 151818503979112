import React, { createContext, useState, useEffect, ReactNode, useRef, useContext } from 'react';
import { getBindParams, insertModelConfig, getModelConfig } from "../Service/API";
import { useNavigate } from 'react-router-dom';
import { ModelType, AuthType, LlmConfig, ValidateObj, ModelConfigRequest } from '../Interfaces/LLMConfigurationForm';
import { useTableContext } from './ReusableTableContext';
import UserContext from '../Auth/UserContext';

interface LlmConfigContextProps {
    isLoading: boolean; setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    ref1: React.RefObject<HTMLInputElement>; ref2: React.RefObject<HTMLInputElement>; 
    ref3: React.RefObject<HTMLInputElement>; ref4: React.RefObject<HTMLInputElement>; 
    ref5: React.RefObject<HTMLInputElement>;
    modelType: ModelType[]; setModelType: React.Dispatch<React.SetStateAction<ModelType[]>>;
    selectedModelType: string; setSelectedModelType: React.Dispatch<React.SetStateAction<string>>;
    displayName: string; setDisplayName: React.Dispatch<React.SetStateAction<string>>;
    region: string; setRegion: React.Dispatch<React.SetStateAction<string>>;
    modelName: string; setModelName: React.Dispatch<React.SetStateAction<string>>;
    tableData: any; setTableData: React.Dispatch<React.SetStateAction<any>>;
    errorData: ValidateObj; setErrorData: React.Dispatch<React.SetStateAction<ValidateObj>>;
    newEndpoint: string; setNewEndpoint: React.Dispatch<React.SetStateAction<string>>;
    newApikey: string; setNewApikey: React.Dispatch<React.SetStateAction<string>>;
    newApiVersion: string; setNewApiVersion: React.Dispatch<React.SetStateAction<string>>;
    newModelID: string; setNewModelID: React.Dispatch<React.SetStateAction<string>>;
    newAnthropicVersion: string; setNewAnthropicVersion: React.Dispatch<React.SetStateAction<string>>;
    newAwsRegion: string; setNewAwsRegion: React.Dispatch<React.SetStateAction<string>>;
    modelTypeDD: boolean; setModelTypeDD: React.Dispatch<React.SetStateAction<boolean>>;
    disableEdit: boolean; setDisableEdit: React.Dispatch<React.SetStateAction<boolean>>;
    show: string; setShow: React.Dispatch<React.SetStateAction<string>>;
    identity: string | null; setIdentity: React.Dispatch<React.SetStateAction<string | null>>;
    flag: number; setFlag: React.Dispatch<React.SetStateAction<number>>;
    llmConfig: LlmConfig; setllmConfig: React.Dispatch<React.SetStateAction<LlmConfig>>;
    fetchBindParams: () => Promise<void>;
    fetchOneModelConfig: (identity: string | null, email?: string) => Promise<void>;
    validateModelConfig: () => Promise<void>;
    handleAddRow: () => void;
    handleEditRow: (index: number) => void;
    handleDeleteRow: (index: number) => void;
    cancel: () => void;
    bindModelType: (modelType: ModelType[] | undefined) => JSX.Element[] | null;
    addAuthTypeRow: (rowData: any, refs: any, stateSetters: any) => void;
}

interface ConfigurationData {
    modelEndpoint?: string; APIKey?: string; modelId?: string; 
    version?: string; awsRegion?: string; apiVersion?: string;
}

export const LlmConfigContext = createContext<LlmConfigContextProps | undefined>(undefined);

interface LlmConfigProviderProps { children: ReactNode; }

export const LlmConfigProvider: React.FC<LlmConfigProviderProps> = ({ children }) => {
    // State for model configuration and related data
    const [modelType, setModelType] = useState<ModelType[]>([]);
    const [displayName, setDisplayName] = useState<string>('');
    const [region, setRegion] = useState<string>('');
    const [modelName, setModelName] = useState<string>('');
    const [selectedModelType, setSelectedModelType] = useState<string>('');
    const { data: tableData, setData: setTableData } = useTableContext();
    const userDetails = useContext(UserContext);

    // State for form inputs and dropdowns
    const [newEndpoint, setNewEndpoint] = useState<string>('');
    const [newApikey, setNewApikey] = useState<string>('');
    const [newModelID, setNewModelID] = useState<string>('');
    const [newAwsRegion, setNewAwsRegion] = useState<string>('');
    const [newApiVersion, setNewApiVersion] = useState<string>('');
    const [newAnthropicVersion, setNewAnthropicVersion] = useState<string>('');
    const [modelTypeDD, setModelTypeDD] = useState(false);

    // State for UI controls and flags
    const [disableEdit, setDisableEdit] = useState<boolean>(false);
    const [show, setShow] = useState("");
    const search = window.location.search;
    const url = new URLSearchParams(search).get("modelConfigurationID");
    const [identity, setIdentity] = useState(url);
    const [flag, setFlag] = useState<number>(0);

    // State for loading and navigation
    const [isLoading, setIsLoading] = useState(false);
    const history = useNavigate();

    // State for LLM configuration and error handling
    const [llmConfig, setllmConfig] = useState<LlmConfig>({
        authType: [],
        modelConfiguration: {
            modelConfigurationID: "", modelDescription: "", modelType: "",
            modelEndpoint: [], region: "", modelName: "", model: "", 
            displayName: "", apiKey: [], modelID: [], version: [], awsRegion: [], apiVersion: [],
        },
        modelTypeID: "", createdBy: ""
    });
    const [errorData, setErrorData] = useState<ValidateObj>({
        modelTypeError: "", regionError: "", modelNameError: "", modelError: "", 
        modelDescriptionError: "", displayNameError: "", apikeyError: "", endpointError: "", 
        awsRegionError: "", apiVersionError: "", modelIDError: "", versionError: "", modelEndpointError: ""
    });

    // Refs for form inputs
    const ref1 = useRef<HTMLInputElement>(null); const ref2 = useRef<HTMLInputElement>(null); 
    const ref3 = useRef<HTMLInputElement>(null); const ref4 = useRef<HTMLInputElement>(null); 
    const ref5 = useRef<HTMLInputElement>(null);

    // Initialize table data on component mount
    useEffect(() => { setTableData([]) }, []);

    const errorObj = {
      modelTypeError: "",
      regionError: "",
      modelNameError: "",
      modelError: "",
      modelDescriptionError: "",
      displayNameError: "",
      apikeyError: "",
      endpointError: "",
      awsRegionError: "",
      apiVersionError: "",
      modelIDError: "",
      versionError: "",
      modelEndpointError: ""
  };
  
    // Function to fetch bind parameters for model configuration
    const fetchBindParams = async () => {
        try {
            setIsLoading(true);
            const response = await getBindParams();
            setModelType(response.data.model_type_result);
            const query = new URLSearchParams(window.location.search);
            const modelConfigurationID = query.get('modelConfigurationID') || "";
            if (modelConfigurationID == "") {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    };
    
    // Function to fetch a single model configuration by ID
    const fetchOneModelConfig = async (identity: string | null, email?: string) => {
        const obj: ModelConfigRequest = {
            modelConfigurationID: identity,
            createdBy: email
        };
        try {
            let result = await getModelConfig(obj);
            result = result.responseData;
            setllmConfig({
                ...llmConfig,
                authType: result.authType,
                modelConfiguration: result.modelConfiguration,
                modelTypeID: result.modelTypeID
            });
            setSelectedModelType(result.modelTypeName);
            setTableData(result.authType);
            const query = new URLSearchParams(window.location.search);
            const modelConfigurationID = query.get('modelConfigurationID') || "";
            if (modelConfigurationID != "") {
                setIsLoading(false);
            }
        } catch (error) {
        }
    };
    
    // Function to validate model configuration before saving
    const validateModelConfig = async () => {
        let flag = 0;
        let validateObj: ValidateObj = {
            modelTypeError: "",
            regionError: "",
            modelNameError: "",
            modelError: "",
            modelDescriptionError: "",
            displayNameError: "",
            apikeyError: "",
            endpointError: "",
            awsRegionError: "",
            apiVersionError: "",
            modelIDError: "",
            versionError: "",
            modelEndpointError: ""
        };
    
        const { modelConfiguration, authType } = llmConfig;
    
        if (!modelConfiguration.modelType) {
            validateObj.modelTypeError = "Select the model Type";
            flag++;
        }
        if (!modelConfiguration.modelDescription) {
            validateObj.modelDescriptionError = "Model Description is required";
            flag++;
        }
    
        if (!modelConfiguration.displayName) {
            validateObj.displayNameError = "Display name is required";
            flag++;
        }
    
        // Specific validation for Open AI and Azure Open AI
        if (modelConfiguration.modelType === 'Azure Open AI') {
            if (!modelConfiguration.region) {
                validateObj.regionError = "Region is required";
                flag++;
            }
            if (!modelConfiguration.model) {
                validateObj.modelError = "Model is required";
                flag++;
            }
            if (!modelConfiguration.modelName) {
                validateObj.modelNameError = "Model Name is required";
                flag++;
            }
            if (modelConfiguration.modelEndpoint?.length === 0) {
                validateObj.modelEndpointError = "Model Endpoint is required";
                flag++;
            }
            if (modelConfiguration.apiKey?.length === 0) {
                validateObj.apikeyError = "Model ApiKey is required";
                flag++;
            }
    
            if (authType.length === 0) {
                validateObj.modelEndpointError = "Model Endpoint is required";
                validateObj.apikeyError = "Model ApiKey is required";
                flag++;
            }
        }
    
        // Specific validation for AWS Bedrock
        if (modelConfiguration.modelType === 'AWS Bedrock') {
            if (modelConfiguration.awsRegion?.length === 0) {
                validateObj.awsRegionError = "Model Region is required";
                flag++;
            }
            if (modelConfiguration.modelID?.length === 0) {
                validateObj.modelIDError = "Model ID is required";
                flag++;
            }
            if (modelConfiguration.version?.length === 0) {
                validateObj.versionError = "Model Version is required";
                flag++;
            }
            if (authType.length === 0) {
                validateObj.awsRegionError = "Model Region is required";
                validateObj.modelIDError = "Model ID is required";
                validateObj.versionError = "Model Version is required";
                flag++;
            }
        }
    
        setErrorData(validateObj);
        if (flag === 0) {
            const modelObject = { ...llmConfig, createdBy:userDetails?.email };
            modelObject["authType"] = tableData as AuthType[];
            setIsLoading(true);
            try {
                await insertModelConfig(modelObject);
                history("/llm-config-grid");
            } catch (error) {
            } finally {
                setIsLoading(false);
            }
        }
    };

    // Function to bind model types to dropdown options
    const bindModelType = (modelType: ModelType[] | undefined) => {
      if (modelType) {
          return modelType.map((value, index) => (
              <li
                  key={index}
                  className="font-14 font-medium text-color-2"
                  onClick={() => {
                      setSelectedModelType(value.modelType);
                      const modelTypeId = value.modelTypeID;
                      const modelTypeName = value.modelType;
                      setSelectedModelType(modelTypeName);
                      setModelTypeDD(false);
                      setllmConfig({
                          ...llmConfig,
                          modelTypeID: modelTypeId,
                          modelConfiguration: {
                              ...llmConfig.modelConfiguration,
                              modelType: modelTypeName,
                          },
                      });
                  }}
                  style={{ cursor: 'pointer' }}
              >
                  <a
                      href="#"
                      data-model-type-name={value.modelTypeName}
                      data-model-type-id={value.modelTypeID}
                      className="dropdown-item py-2 custom-dropdown-li custom-border-bottom text-color-2"
                      style={{ color: 'black' }}
                      onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                          e.preventDefault();
                          setErrorData({ ...errorData, modelTypeError: '' });
                          setllmConfig({ ...llmConfig, modelTypeID: e.currentTarget.dataset.modelTypeId || '' });
                      }}
                  >
                      {value.modelType}
                  </a>
              </li>
          ));
      }
      return null;
    };

    // Function to handle adding a new row to the table
    const handleAddRow = () => {
      let validateObj: ValidateObj = {
          modelTypeError: "",
          regionError: "",
          modelNameError: "",
          modelError: "",
          modelDescriptionError: "",
          displayNameError: "",
          apikeyError: "",
          endpointError: "",
          awsRegionError: "",
          apiVersionError: "",
          modelIDError: "",
          versionError: "",
          modelEndpointError: ""
      };
      let flag = 0;

      // Validation for AWS Bedrock
      if (selectedModelType === "AWS Bedrock") {
          if (newAwsRegion?.length === 0) {
              validateObj.awsRegionError = "Model Region is required";
              flag++;
          }
          if (newModelID?.length === 0) {
              validateObj.modelIDError = "Model ID is required";
              flag++;
          }
          if (newAnthropicVersion?.length === 0) {
              validateObj.versionError = "Model Version is required";
              flag++;
          }
      }
      // Validation for Azure Open AI
      else if (selectedModelType === "Azure Open AI") {
          if (!newEndpoint) {
              validateObj.modelEndpointError = "Model Endpoint is required";
              flag++;
          }
          if (!newApikey) {
              validateObj.apikeyError = "Model ApiKey is required";
              flag++;
          }
      }

      setErrorData(validateObj);

      // Add new row if validation passes
      if (flag === 0) {
          setDisableEdit(false);

          // Add row for Azure Open AI
          if (newEndpoint && newApikey) {
              const newRow = {
                  APIKey: newApikey,
                  modelEndpoint: newEndpoint
              };
              addAuthTypeRow(newRow, [ref1, ref2], [setNewEndpoint, setNewApikey]);
          }

          // Add row for AWS Bedrock
          if (newModelID && newAnthropicVersion && newAwsRegion) {
              const newRow = {
                  modelId: newModelID,
                  version: newAnthropicVersion,
                  awsRegion: newAwsRegion
              };
              addAuthTypeRow(newRow, [ref3, ref4, ref5], [setNewModelID, setNewAnthropicVersion, setNewAwsRegion]);
          }
      }
    };
    
    // Function to add a new authentication type row to the table and update state
    const addAuthTypeRow = (rowData: any, refs: any, stateSetters: any) => {
      setErrorData(errorObj);
      setTableData(prevTableData => [...prevTableData, rowData]);
      setllmConfig(prevConfig => ({
          ...prevConfig,
          authType: [...prevConfig.authType, rowData],
          modelConfiguration: {
              ...prevConfig.modelConfiguration,
              apiKey: [...(prevConfig.modelConfiguration.apiKey || []), rowData.APIKey],
              modelEndpoint: [...(prevConfig.modelConfiguration.modelEndpoint || []), rowData.modelEndpoint],
              modelID: [...(prevConfig.modelConfiguration.modelID || []), rowData.modelId],
              version: [...(prevConfig.modelConfiguration.version || []), rowData.anthropicVersion],
              awsRegion: [...(prevConfig.modelConfiguration.awsRegion || []), rowData.awsRegion]
          }
      }));
      refs.forEach((ref: { current: { value: string; }; }) => {
          if (ref.current) ref.current.value = '';
      });
      stateSetters.forEach((setter: (arg0: string) => any) => setter(''));
    };

    // Function to handle editing a row in the table
    const handleEditRow = (index: number) => {
      setDisableEdit(true);

      // Get the selected row data
      const selectedRowData = index as ConfigurationData;

      // Remove the selected row from tableData
      const updatedTableData = tableData.filter((_, i) => _ !== index);
      setTableData(updatedTableData);

      // Update state based on the selected data
      if (selectedRowData?.modelId && selectedRowData.version && selectedRowData?.awsRegion) {
          setNewModelID(selectedRowData.modelId ?? "");
          setNewAnthropicVersion(selectedRowData.version ?? "");
          setNewAwsRegion(selectedRowData.awsRegion ?? "");
      } else {
          setNewEndpoint(selectedRowData?.modelEndpoint ?? "");
          setNewApikey(selectedRowData?.APIKey ?? "");
      }
    };

    // Function to handle deleting a row from the table
    const handleDeleteRow = (index: number) => {
      setTableData(prevTableData => prevTableData.filter((_, i) => _ !== index));
      setllmConfig(prevConfig => ({
          ...prevConfig,
          authType: prevConfig.authType.filter((_: any, i) => _ !== index)
      }));
    };

    // Function to cancel the current operation and reset state
    const cancel = () => {
      history("/llm-config-grid");
      setllmConfig({
          authType: [],
          modelConfiguration: {
              modelConfigurationID: "",
              modelDescription: "",
              modelType: "",
              modelEndpoint: [],
              region: "",
              modelName: "",
              model: "",
              displayName: "",
              apiKey: [],
              apiVersion: [],
              modelID: [],
              version: [],
              awsRegion: []
          },
          modelTypeID: "",
          createdBy: ""
      });
      setTableData([]);
    };
    
    return (

      <LlmConfigContext.Provider
          value={{

              // Grouped states and setters
              isLoading, setIsLoading,
              ref1, ref2, ref3, ref4, ref5,
              modelType, setModelType, selectedModelType, setSelectedModelType,
              displayName, setDisplayName, region, setRegion, modelName, setModelName,
              tableData, setTableData, errorData, setErrorData,
              newEndpoint, setNewEndpoint, newApikey, setNewApikey, newApiVersion, setNewApiVersion,
              newModelID, setNewModelID, newAnthropicVersion, setNewAnthropicVersion, newAwsRegion, setNewAwsRegion,
              modelTypeDD, setModelTypeDD, disableEdit, setDisableEdit,
              show, setShow, identity, setIdentity, flag, setFlag,
              llmConfig, setllmConfig,
  
              // Grouped functions
              fetchBindParams, fetchOneModelConfig, validateModelConfig, cancel,
              handleAddRow, handleEditRow, handleDeleteRow, bindModelType, addAuthTypeRow
          }}
      >
          {children}
      </LlmConfigContext.Provider>
  );
};

export const useLlmConfig = () => {
    const context = useContext(LlmConfigContext);
    if (context === undefined) {
      throw new Error('useLlmConfig must be used within a LlmConfigProvider');
    }
    return context;
};