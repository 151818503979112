
import React, { useState, useEffect } from 'react';
import ButtonComponent from './Button';
import InputComponent from './TextInput';
import DropDownComponent from './DropDown';
import { DropDownProvider } from '../Utility/DropDownContext';

interface FormField {
    name: string;
    label: string;
    placeholder?: string;
    value?: string | number | string[];
    error?: string;
    options?: { value: string; label: string }[];
    type: 'text' | 'password' | 'checkbox' | 'radio' | 'textarea' | 'dropdown';
    required?: boolean;
    isMulti?: boolean;
}

interface Step {
    title: string;
    fields: FormField[];
}

interface DynamicPopupProps {
    showModal: boolean;
    loading: boolean;
    title: string;
    errors: string;
    steps: Step[];
    currentStep: number;
    columns: number;
    onClose: () => void;
    onSave: (data: any) => void;
    onPrevious?: () => void;
    onNext: () => void;
    successMessage?: React.ReactNode;
}

const DynamicPopup: React.FC<DynamicPopupProps> = ({
    showModal,
    title,
    steps,
    currentStep,
    errors,
    loading,
    columns,
    successMessage,
    onClose,
    onSave,
    onPrevious,
    onNext,
}) => {
    const isSuccessStep = title.toLowerCase().includes('success');
    const titleParts = title.split('-').map(part => part.trim());
 
    // Get the second value (i.e., the part after the hyphen)
    const secondValue = titleParts[1];
 
    // Check if the second value includes 'projectSuccess'
    const isSuccessStepValue = secondValue && secondValue.toLowerCase().includes('projectsuccess');
    const [formData, setFormData] = useState<Record<string, any>>({});
    
    useEffect(() => {
        const initialData = steps[currentStep]?.fields.reduce((acc, field) => {
            acc[field.name] = field.value || '';
            return acc;
        }, {} as Record<string, any>);
        setFormData(prevData => ({ ...prevData, ...initialData }));
    }, [steps, currentStep]);

    const handleChange = (name: string, value: any) => {
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSave = () => {
        onSave(formData);
    };

    const renderField = (field: FormField) => {
        switch (field.type) {
            case 'text':
            case 'password':
                return (
                    <InputComponent
                        type={field.type}
                        className="form-control bg-transparent text-color-4 custom-border-3 cust-search-input"
                        placeholder={field.placeholder}
                        value={formData[field.name] || ''}
                        onChange={(value) => handleChange(field.name, value)}
                    />
                );
            case 'textarea':
                return (
                    <textarea
                        name={field.name}
                        value={formData[field.name] || ''}
                        onChange={(e) => handleChange(field.name, e.target.value)}
                        placeholder={field.placeholder}
                        required={field.required}
                        className="form-control bg-transparent text-color-4 custom-border-3 cust-search-input"
                        style={{resize: 'none'}}
                    />
                );
            case 'checkbox':
                return (
                    <div className="d-flex flex-wrap">
                        {field.options?.map((option, index) => (
                            <div key={index} className="card m-2" style={{ width: '200px' }}>
                                <div className="card-body">
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            id={`${field.name}-${index}`}
                                            name={field.name}
                                            value={option.value}
                                            checked={(formData[field.name] || []).includes(option.value)}
                                            onChange={(e) => {
                                                const newValue = e.target.checked
                                                    ? [...(formData[field.name] || []), option.value]
                                                    : (formData[field.name] || []).filter((v: string) => v !== option.value);
                                                handleChange(field.name, newValue);
                                            }}
                                            className="form-check-input"
                                        />
                                        <label className="form-check-label" htmlFor={`${field.name}-${index}`}>
                                            {option.label}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                );
            case 'radio':
                return (
                    <div>
                        {field.options?.map((option, index) => (
                            <div key={index} className="form-check">
                                <input
                                    type="radio"
                                    id={`${field.name}-${index}`}
                                    name={field.name}
                                    value={option.value}
                                    checked={formData[field.name] === option.value}
                                    onChange={(e) => handleChange(field.name, e.target.value)}
                                    className="form-check-input"
                                />
                                <label className="form-check-label" htmlFor={`${field.name}-${index}`}>
                                    {option.label}
                                </label>
                            </div>
                        ))}
                    </div>
                );
            case 'dropdown':
                return (
                    <DropDownProvider isMulti={field.isMulti || false}>
                        <DropDownComponent
                            label={field.label}
                            options={field.options || []}
                            value={formData[field.name]}
                            onChange={(selectedOption:any) => handleChange(field.name, selectedOption)}
                        />
                    </DropDownProvider>
                );
            default:
                return null;
        }
    };
    

    if (!showModal) return null;

    return (
        <div className="modal fade show" style={{ display: 'block', background: "#000000cc" }}>
            <div className="modal-dialog modal-dialog-centered custom-popup-width-box border-1">
                <div className="modal-content p-3 border-0 bg-color-4">
                    <div className="modal-header border-0 pt-2 p-0 px-2">
                    {!isSuccessStepValue?<h5 className="modal-title text-color-1 font-22 font-bold">{title}</h5>:<h5 className="modal-title text-color-1 font-22 font-bold">{titleParts[0]}</h5>}
                        <button type="button" className="btn-close close-icon-filter" onClick={onClose}></button>
                    </div>
                    <div className="modal-body popup-scrl pt-2">
                    {!(isSuccessStep||isSuccessStepValue) && currentStep < steps.length && (
                            <div className="d-flex justify-content-center my-4 position-relative p-0">
                                {steps.map((step, i) => (
                                    <React.Fragment key={i}>
                                       {step.title!='Configure Project'?(
                                        <>
                                        <span className={`wizard-number${i <= currentStep ? '-active' : ''} font-18 font-semibold d-block d-flex align-items-center justify-content-center me-0 me-md-3 pt-1`}>
                                            {i + 1}
                                        </span> 
                                        <span className={`wizard-text${i <= currentStep ? '-active' : ''} font-15 font-semibold d-block d-flex align-items-center justify-content-center me-0 me-md-5 position-relative ms-2 ms-md-0`}>
                                            {step.title}
                                        </span>
                                        </>
                                        ):(<></>)}
                                        {i < steps.length - 1 && (
                                            <span
                                                style={{
                                                    borderTop: '2px dashed #ccc',
                                                    width: '50px',
                                                    height: '1px',
                                                    margin: '17px 9px 0px -30px',
                                                    display: 'inline-block'
                                                }}
                                            ></span>
                                        )}
                                    </React.Fragment>
                                ))}
                            </div>
                        )}
                        {isSuccessStep||isSuccessStepValue ? (
                            <div className="text-center">
                                {successMessage}
                            </div>
                        ) : (
                            <div className={`row row-cols-${columns}`}>
                                {steps[currentStep]?.fields.map((field, index) => (
                                    <div key={index} className="col mb-3">
                                        <label htmlFor={field.name} className="form-label font-14 font-bold text-color-2">
                                            {field.label}{field.required && <span style={{ color: "red" }}>*</span>}
                                        </label>
                                        {renderField(field)}
                                        {field.error && <div className="text-danger">{field.error}</div>}
                                    </div>
                                ))}
                            </div>
                        )}
                        {errors && <div className="text-danger mt-3">{errors}</div>}
                    </div>
                    <div className="modal-footer border-0 pe-1 pt-0 gap-3">
                        {currentStep > 0 && currentStep < steps.length && (
                            <ButtonComponent
                                text="Previous"
                                className="custom-outline-btn font-14 px-4 py-2 m-0 font-medium"
                                onClick={onPrevious}
                            />
                        )}
                        {currentStep < steps.length && (
                            <ButtonComponent
                                text="Cancel"
                                className="custom-outline-btn font-14 px-4 py-2 m-0 font-medium"
                                onClick={onClose}
                            />
                        )}
                        {currentStep < steps.length ? (
                            <ButtonComponent
                                text={loading ? 'Loading...' : 'Save'}
                                className="primary-btn font-14 font-semibold border-radius-5 color-white py-2 px-4 m-0"
                                onClick={handleSave}
                                disabled={loading}
                            />
                        ) : (
                            <ButtonComponent
                                text="Ok"
                                className="primary-btn font-14 font-semibold border-radius-5 color-white py-2 px-5 m-0 mx-auto"
                                onClick={onClose}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DynamicPopup;