import React, { useState, useEffect, useRef } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// import Editor from '@monaco-editor/react';
import mermaid from 'mermaid';
import { useDiagramButtons } from '../../Utility/DiagramButtonContext';
import ButtonComponent from '../../Reusable Components/Button';
import { fetchAndValidateDiagram } from '../../Service/ReplaceD2Image';
import Editor from '@monaco-editor/react';
import { fetchPlantUMLDiagram } from '../../Service/API';

interface DiagramPreviewProps {
    navOpen: string;
    diagramType: string;
}

const DiagramPreview: React.FC<DiagramPreviewProps> = ({ navOpen, diagramType }) => {
    const {
        copiedCode,
        copiedImage,
        isDiagramFullscreen,
        isEditorFullscreen,
        zoom,
        layout,
        handleCopyCode,
        handleCopyImage,
        toggleEditorFullscreen,
        toggleDiagramFullscreen,
        handleLayoutChange,
        handleZoomIn,
        handleZoomOut,
        handleGoBack
    } = useDiagramButtons();

    const initialCode = sessionStorage.getItem(`${diagramType}Code`) || '';
    const [code, setCode] = useState<string>(initialCode);
    const [diagramSvg, setDiagramSvg] = useState<string>('');
    const [chatId, setChatId] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const ref = useRef<HTMLDivElement>(null);
    if (code.startsWith('plantuml')) {
        let plantumlCode = code.split('\n').slice(1).join('\n');
        setCode(plantumlCode)
    }

    useEffect(() => {
        const storedData = sessionStorage.getItem(`${diagramType}Data`);
        if (storedData) {
            const { chatId } = JSON.parse(storedData);
            setChatId(chatId);
        }
    }, [diagramType]);

    useEffect(() => {
        const renderDiagram = async () => {
            switch (diagramType) {
                case 'plantuml':
                    setError(null);
                    // renderPlantUML(code);
                    await getPlantUMLDiagram();
                    break;
                case 'd2':
                    const { diagramSvg: d2Svg, finalCode, error: d2Error } = await fetchAndValidateDiagram(code, layout);
                    if (d2Svg) {
                        setDiagramSvg(d2Svg);
                        if (finalCode !== code) {
                            setCode(finalCode);
                        }
                        setError(null);
                    } else {
                        setError(d2Error);
                    }
                    break;
                case 'mermaid':
                    try {
                        mermaid.initialize({ startOnLoad: false, theme: 'default' });
                        const { svg } = await mermaid.render('mermaidDiagram', code);
                        setDiagramSvg(svg);
                        setError(null);
                    } catch (err) {
                        setError((err as Error).message);
                        setDiagramSvg('');
                    }
                    break;
            }
        };

        renderDiagram();
    }, [code, layout, diagramType]);

    const getPlantUMLDiagram = async () => {
        try {
            const { diagramSvg, error } = await fetchPlantUMLDiagram(code);
            setDiagramSvg(diagramSvg);
            // setCurrentService("Kroki");
        } catch (krokiError) {
            console.error("Kroki service failed:", krokiError);
            // setCurrentService("None");
            setDiagramSvg("<div>Error displaying diagram</div>");
        }
    };

    const handleEditorChange = (value: string | undefined) => {
        if (value !== undefined) {
            setCode(value);
        }
    };

    const renderPlantUML = (umlCode: string) => {
        const encodedCode = encodeURIComponent(umlCode);
        const plantUmlServer = 'https://www.plantuml.com/plantuml/img/';
        const imageUrl = `${plantUmlServer}${encodedCode}`;
        setDiagramSvg(`<img src="${imageUrl}" alt="PlantUML Diagram" style="width: 100%; height: 100%;" />`);
    };

    const renderDiagram = () => {
        if (error) {
            return (
                <div className="error-message" style={{ color: 'red', padding: '10px' }}>
                    {error}
                </div>
            );
        }

        return diagramSvg ? (
            <div
                style={{
                    transform: `scale(${zoom})`,
                    transformOrigin: 'top left',
                }}
                dangerouslySetInnerHTML={{ __html: diagramSvg }}
            />
        ) : (
            <div>Loading diagram...</div>
        );
    };
    return (
        <div className="container-fluid">
            <div className="row justify-content-lg-end ms-lg-3 ms-xl-0 justify-content-center">
                <div className={navOpen === "Close" ? `col-11 me-xl-4 me-lg-3` : `col-lg-9 me-md-2 cust-me-lg-4 col-11`}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex justify-content-between align-items-center mt-3 mb-4">
                                <h3 className="font-24 font-semibold text-color-1 mb-0">
                                    <ButtonComponent
                                        altText="Back"
                                        className="me-3 icon-filter"
                                        onClick={() => handleGoBack(chatId)}
                                        iconSrc="images/back-black-arrow.svg"
                                    />
                                    Code Snippet & {diagramType.toUpperCase()} Diagram
                                </h3>
                                {diagramType === 'd2' && (
                                    <div className="layout-selector">
                                        <label htmlFor="layout-select" className="me-2 font-24 font-semibold text-color-1">Layout : </label>
                                        <select id="layout-select" value={layout} onChange={handleLayoutChange} className="form-select form-select-sm">
                                            <option value="right">Right</option>
                                            <option value="down">Down</option>
                                            <option value="left">Left</option>
                                            <option value="up">Up</option>
                                        </select>
                                    </div>
                                )}
                            </div>
                            <div className="row">
                                {!isDiagramFullscreen && (
                                    <div className={isEditorFullscreen ? "col-md-12" : "col-md-6"}>
                                        <div className="position-relative">
                                            <Editor
                                                defaultLanguage={diagramType === 'mermaid' ? 'markdown' : diagramType}
                                                className="px-2 py-2 er-snippet code-snippet-area"
                                                value={code}
                                                onChange={handleEditorChange}
                                                options={{ minimap: { enabled: false } }}
                                                height={isEditorFullscreen ? "80vh" : "400px"}
                                            />
                                            <div className="code-btns">
                                                <CopyToClipboard text={code} onCopy={handleCopyCode}>
                                                    <ButtonComponent
                                                        altText="Copy Code"
                                                        className={`btn textarea-btn ${copiedCode ? 'copied' : ''} me-3`}
                                                        iconSrc={copiedCode ? "images/tick-icon.svg" : "images/copy-icon-btn.svg"}
                                                    />
                                                </CopyToClipboard>
                                                <ButtonComponent
                                                    altText={isEditorFullscreen ? "Minimize" : "Maximize"}
                                                    className="btn textarea-btn me-3"
                                                    onClick={toggleEditorFullscreen}
                                                    iconSrc={isEditorFullscreen ? "images/minimize-icon.svg" : "images/maximize-icon.svg"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className={isDiagramFullscreen ? "col-md-12" : isEditorFullscreen ? "d-none" : "col-md-6"}>
                                    <div className="position-relative mb-200" style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        overflow: "visible",
                                    }}>
                                        <div ref={ref} className="px-2 py-2 er-snippet code-snippet-area div12 position-relative uml-scroll"
                                            style={{
                                                height: isDiagramFullscreen ? "80vh" : "400px",
                                                width: "100%",
                                                overflow: "auto",
                                            }}>
                                            {renderDiagram()}
                                        </div>
                                        <div className="btn-group cust-btngrp p-1 mt-2 position-fixed" role="group" aria-label="Basic example">
                                            <div className='border-bottom' >
                                                <div style={{marginLeft:"0.5vw"}}>
                                                <ButtonComponent
                                                    altText="Zoom In"
                                                    className="d-block btn zoom-btn px-1 rounded-0"
                                                    onClick={handleZoomIn}
                                                    iconSrc="images/plus-icons.svg"
                                                />
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <ButtonComponent
                                                    altText="Zoom Out"
                                                    className="btn zoom-btn px-2 rounded-0"
                                                    onClick={handleZoomOut}
                                                    iconSrc="images/minus-icon.svg"
                                                />
                                            </div>
                                        </div>
                                        <div className="code-btns">
                                            <ButtonComponent
                                                altText="Copy Image"
                                                className={`btn textarea-btn ${copiedImage ? 'copied' : ''} me-3`}
                                                onClick={() => handleCopyImage(diagramSvg)}
                                                iconSrc={copiedImage ? "images/tick-icon.svg" : "images/copy-icon-btn.svg"}
                                            />
                                            <ButtonComponent
                                                altText={isDiagramFullscreen ? "Minimize" : "Maximize"}
                                                className="btn textarea-btn me-3"
                                                onClick={toggleDiagramFullscreen}
                                                iconSrc={isDiagramFullscreen ? "images/minimize-icon.svg" : "images/maximize-icon.svg"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DiagramPreview;