import React, { createContext, useState, useContext, useEffect, useRef, useCallback, cloneElement } from 'react';
import { kbInsert, insertMessage } from '../Service/API';
import { usePromptBuilder } from './ManagePromptContext';

// Define the interface for the KnowledgeBase context
interface KnowledgeBaseContextType {
  files: File[]; setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  urls: string[]; setUrls: React.Dispatch<React.SetStateAction<string[]>>;
  url: string; setUrl: React.Dispatch<React.SetStateAction<string>>;
  error: string; setError: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean; setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  errorMessage: string; setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  editFiles: any[]; setEditFiles: React.Dispatch<React.SetStateAction<any[]>>;
  editUrls: string[]; setEditUrls: React.Dispatch<React.SetStateAction<string[]>>;
  errorVal: boolean; setErrorVal: React.Dispatch<React.SetStateAction<boolean>>;
  cancel: boolean; setCancel: React.Dispatch<React.SetStateAction<boolean>>;
  newFiles: File[]; setNewFiles: React.Dispatch<React.SetStateAction<File[]>>;
  newUrls: string[]; setNewUrls: React.Dispatch<React.SetStateAction<string[]>>;
  urlCancel: boolean; setUrlCancel: React.Dispatch<React.SetStateAction<boolean>>;
  fileInputRef: React.RefObject<HTMLInputElement>;
  handleFileUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleUrlAdd: () => void;
  handleFileDelete: (index: number) => void;
  handleUrlDelete: (index: number) => void;
  handleSave: (currentChatID: any) => Promise<any>;
  handleCancel: () => void;
  handleUrlCancel: () => void;
  readFileAsBase64: (file: File) => Promise<string>;
  setAskAsamiKbRetriever: (value: boolean) => void;
  knowledgeData_func: (data: any) => void;
  collection_func: (name: string) => void;
  index_func: (name: string) => void;
  knowledgeUrl_func: (urls: string[]) => void;
  kbURL: any; kbFiles: any;
  collectionName: any; setCollectionName: any;
  askAsamiKbData:any;
  setAskAsamiKbData: React.Dispatch<React.SetStateAction<any>>;
  setKbUrl: React.Dispatch<React.SetStateAction<any>>;
 
}

// Define the interface for the KnowledgeBase provider props
interface KnowledgeBaseProviderProps {
  children: React.ReactNode;
  currentChatID: string;
}

// Define the type for AskAsamiKbData
type AskAsamiKbData = {
  chatID: string | null;
  collectionName: string | undefined | null;
  indexName: string | undefined | null;
  kbFiles: string;
  kbUrl: string | undefined;
  isKbRetriever: boolean | undefined;
};

// Create the KnowledgeBase context
const KnowledgeBaseContext = createContext<KnowledgeBaseContextType | undefined>(undefined);

// Define the KnowledgeBase provider component
export const KnowledgeBaseProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // State declarations in single lines
  const [files, setFiles] = useState<File[]>([]);
  const [urls, setUrls] = useState<string[]>([]);
  const [url, setUrl] = useState('');
  const [kbURL, setKbUrl] = useState();
  const [kbFiles, setKbFiles] = useState<any>();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [editFiles, setEditFiles] = useState<any[]>([]);
  const [editUrls, setEditUrls] = useState<string[]>([]);
  const [errorVal, setErrorVal] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [newUrls, setNewUrls] = useState<string[]>([]);
  const [urlCancel, setUrlCancel] = useState(false);
  const [collectionName, setCollectionName] = useState("");
  const [askAsamiKbData, setAskAsamiKbData] = useState<any>("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { promptData, setPromptData } = usePromptBuilder();
  const [flag, setFlag] = useState(0);

  // Effect to update askAsamiKbData in session storage
  useEffect(() => {
    let sessionKbData: any = JSON.parse(sessionStorage?.getItem('askAsamiKbData') as any);
    const currentChat_ID = new URLSearchParams(window.location.search).get('chatID');

    const newAskAsamiKbData: AskAsamiKbData = {
      chatID: currentChat_ID,
      collectionName: collectionName ? collectionName : sessionKbData?.collectionName ? sessionKbData?.collectionName : null,
      indexName: collectionName ? collectionName : sessionKbData?.collectionName ? sessionKbData?.collectionName : null,
      kbFiles: kbFiles ? kbFiles : sessionKbData?.kbFiles ? sessionKbData?.kbFiles : [],
      kbUrl: kbURL ? kbURL : sessionKbData?.kbUrl ? sessionKbData?.kbUrl : [],
      isKbRetriever: true,
    };
    // setKbUrl(sessionKbData?.kbFiles)
    // setFiles(sessionKbData?.kbFiles)
    setAskAsamiKbData(newAskAsamiKbData);
    sessionStorage.setItem('askAsamiKbData', JSON.stringify(newAskAsamiKbData));  
    setFlag(1)                              
  }, [collectionName, kbFiles, kbURL]);

  // Effect to insert data when askAsamiKbData is updated
  useEffect(() => {
    if (
      askAsamiKbData?.isKbRetriever &&
      askAsamiKbData?.collectionName !== null &&
      askAsamiKbData?.indexName !== null
    ) {
      insertData(askAsamiKbData);
    }
  }, [askAsamiKbData]);
  useEffect(() => {
    let sessionKbData: any = JSON.parse(sessionStorage?.getItem('askAsamiKbData') as any);
    if (
      askAsamiKbData?.isKbRetriever &&
      askAsamiKbData?.collectionName !== '' &&
      askAsamiKbData?.indexName !== ''
    ) { 
      setKbFiles(sessionKbData?.kbFiles)
      setKbUrl(sessionKbData?.kbUrl)
      sessionStorage.setItem('promptKbFile',JSON.stringify(sessionKbData?.kbFiles))
      setPromptData((prevData: any) => ({
          ...prevData,
          kbFiles:sessionKbData?.kbFiles,
          kbUrl: sessionKbData?.kbUrl
      }));
      let filteredUrls:any=[]
      if(typeof sessionKbData?.kbUrl !== 'string'){
         filteredUrls = sessionKbData?.kbUrl.filter((url:any) => typeof url === 'string');
 
      }
      else{
        let val=JSON.parse(sessionKbData?.kbUrl)
        filteredUrls = val.filter((url:any) => typeof url === 'string');
      }
    
        // Append the filtered URLs to the state
      setUrls(prevUrls => [...prevUrls, ...filteredUrls]);
      // setUrls(sessionKbData?.kbUrl)
      const fileNames = sessionKbData.kbFiles
        .filter((item:any) => typeof item === 'object')  // Filter out non-objects (strings)
        // .map((item:any) => item.name); 
      setFiles(fileNames)
    }
  }, [flag]);

  // Function to insert data into the knowledge base
  const insertData = async (data: AskAsamiKbData) => {
    try {
      const result = await insertMessage(data);
      setAskAsamiKbRetriever(true);
    } catch (error) {
      console.error(error);
    }
  };

  // Function to handle file upload
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const acceptedExtensions = ['.txt', '.xls', '.xlsx', '.pdf', '.json', '.csv'];
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
    const newFiles = Array.from(event.target.files || []);

    for (const file of newFiles) {
      const extension = file.name.split('.').pop()?.toLowerCase() || '';
      const fileSizeMB = file.size / (1024 * 1024);

      if (fileSizeMB > 150) {
        setErrorMessage('File size exceeds the maximum limit of 150 MB');
        setErrorVal(true);
        return;
      } 
      else if (file.type === 'application/vnd.ms-powerpoint' || file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
        setErrorMessage('Uploading PowerPoint files (.ppt) is not allowed');
        setErrorVal(true);
        return;
      }
      else {
        if (!imageExtensions.includes(extension)) {
          setFiles(prevFiles => [...prevFiles, file]);
          setNewFiles(prevNewFiles => [...prevNewFiles, file]);
          setErrorMessage('');
          setErrorVal(false);
        } else {
          setErrorMessage('The file cannot be an image');
          setErrorVal(true);
        }
      }
    }
  };

  // Function to handle URL addition
  const handleUrlAdd = () => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    if (!urlRegex.test(url)) {
      setErrorMessage('Invalid URL format');
      setErrorVal(true);
      return;
    }

    setUrls(prevUrls => [...prevUrls, url]);
    setNewUrls(prevNewUrls => [...prevNewUrls, url]);
    setErrorMessage('');
    setErrorVal(false);
    setUrl('');
  };

  // Function to handle file deletion
  const handleFileDelete = (index: number) => {
    setEditFiles(prevEditFiles => prevEditFiles.filter((_, i) => i !== index));
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  // Function to handle URL deletion
  const handleUrlDelete = (index: number) => {
    setEditUrls(prevEditUrls => prevEditUrls.filter((_, i) => i !== index));
    setUrls(prevUrls => prevUrls.filter((_, i) => i !== index));
  };

  // Function to handle saving knowledge base data
  const handleSave = async (currentChatID: any) => {
    const formData = new FormData();
    setAskAsamiKbRetriever(true);
    setIsLoading(false);
    setErrorVal(false);
    setUrl('');
    setCancel(false);
    setUrlCancel(false);
    setNewFiles([]);
    setNewUrls([]);
    const combinedInputs: string[] = [];
    const inputs: any[] = [];

    try {
        // Process files and convert them to base64
        await Promise.all(files.map(async (file) => {
            if (file instanceof File) {
                const base64Data = await readFileAsBase64(file);
                formData.append('filename', file.name);
                formData.append('file', file);
            }
        }));
        // Combine existing URLs and prompt data URLs
        urls.forEach((url) => {
          combinedInputs.push(url);
          formData.append('urls', url);  // Add each URL to the form data with the key 'urls'
        });
        combinedInputs.push(...editFiles.map(url => typeof url === 'string' ? url : ''));
        if (promptData?.kbUrl)
          combinedInputs.push(...promptData.kbUrl.map((url: string | any) => typeof url === 'string' ? url : url));

        // Prepare inputs for saving
        inputs.push(...files.map(file => ({ name: file.name })));
        // inputs.push(...urls.map(url => typeof url === 'string' ? url : ''));
        let matched:any =[]
        if (promptData?.kbUrl!=''){
          promptData.kbUrl.forEach((item:any) => {
          if (typeof item === 'string') {
            urls.forEach((url) => {
              if (!matched.includes(url)) {
                matched.push(url);
              }
            });
          } 
          else if (typeof item === 'object' && item.name) {
            // Append objects from files to matched array
            const fileNames = files.map(file => file.name);
            if (fileNames.includes(item.name)) {
              matched.push(item);  // Or push the entire item based on your requirement
            }
          }
        });

        }
        else{
          matched=urls.map(url=>url);
        }

        let obj: any;
        let chatId=new URLSearchParams(window.location.search).get('chatID');

        // Prepare the object for saving based on collection name
        if (collectionName) {
          obj = {
              kbUrl: matched,
              collection_name: `${collectionName}_collection`,
              index_name: collectionName,
              promptName: currentChatID,
              chat_id: chatId
          };
      } else {
          // Default to prompt data if no collection name is provided
          obj = {
              kbUrl: matched,
              collection_name: promptData?.promptName,
              index_name: promptData?.promptName,
              promptName: currentChatID,
              chat_id: chatId
          };
      }
        formData.append("JsonObj", JSON.stringify(obj));

        // Save the knowledge base data
        const response = await kbInsert(formData);
        setIsLoading(true);

        // Update state with the response data
        collection_func(response.data.collection_name);
        setCollectionName(response.data.collection_name);
        setKbFiles(response?.data?.files);
        setKbUrl(response.data.s3_urls);
        setFiles(inputs);
        const filteredUrls = response.data.s3_urls.filter((url:any) => typeof url === 'string');
        setUrls(prevUrls => [...prevUrls, ...filteredUrls]);
        setKbFiles(response?.data?.files);
        setKbUrl(response.data.s3_urls);
        return { "inputs": response?.data?.files, "blob_urls": response?.data?.s3_urls };
    } catch (error: any) {
        setError(error.message || "An error occurred");
    }
  };

  // Function to handle file upload cancellation
  const handleCancel = () => {
    if (cancel) {
        setFiles(prevFiles => prevFiles.filter(file => !newFiles.some(newFile => newFile.name === file.name)));
        setCancel(false);
    } else {
        setFiles(prevFiles => prevFiles.filter(file => !newFiles.some(newFile => newFile.name === file.name)));
    }
    if (fileInputRef.current) {
        fileInputRef.current.value = '';
    }
    setErrorVal(false);
  };

  // Function to handle URL cancellation
  const handleUrlCancel = () => {
    if (urlCancel) {
        setUrls(prevUrls => prevUrls.filter(url => !newUrls.includes(url)));
        setUrlCancel(false);
    } else {
        setUrls(prevUrls => prevUrls.filter(url => !newUrls.includes(url)));
    }
    setErrorVal(false);
    setUrl('');
  };

  // Function to read a file as base64
  const readFileAsBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const base64Data = reader.result?.toString().split(",")[1] || '';
            resolve(base64Data);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
    });
  };

  // Callback functions for updating knowledge base state
  const setAskAsamiKbRetriever = useCallback((value: boolean) => {
    // Placeholder for setting KB retriever state
  }, []);

  const knowledgeData_func = useCallback((data: any) => {
    setKbFiles(data);
  }, []);

  const collection_func = useCallback((name: string) => {
    // Placeholder for collection function
  }, []);

  const index_func = useCallback((name: string) => {
    // Placeholder for index function
  }, []);

  const knowledgeUrl_func = useCallback((urls: any) => {
    setKbUrl(urls);
  }, []);

// Context provider for knowledge base
return (
  <KnowledgeBaseContext.Provider value={{
      files, setFiles, urls, setUrls, url, setUrl, error, setError,
      isLoading, setIsLoading, errorMessage, setErrorMessage, editFiles, setEditFiles,
      editUrls, setEditUrls, errorVal, setErrorVal, cancel, setCancel,
      newFiles, setNewFiles, newUrls, setNewUrls, urlCancel, setUrlCancel,
      fileInputRef, handleFileUpload, handleUrlAdd, handleFileDelete, handleUrlDelete,
      handleSave, handleCancel, handleUrlCancel, readFileAsBase64,
      setAskAsamiKbRetriever, knowledgeData_func, collection_func, index_func, knowledgeUrl_func,
      kbFiles, kbURL, collectionName, setCollectionName,askAsamiKbData, setAskAsamiKbData, setKbUrl
  }}>
      {children}
  </KnowledgeBaseContext.Provider>
);
};

// Hook to use the knowledge base context
export const useKnowledgeBase = () => {
  const context = useContext(KnowledgeBaseContext);
  if (context === undefined) {
      throw new Error('useKnowledgeBase must be used within a KnowledgeBaseProvider');
  }
  return context;
};