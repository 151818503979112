// Import necessary dependencies and interfaces
import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { NavFooterContextState, UserProfile, NavFooterProviderProps } from './NavFooterInterface';
import { ButtonConfig } from '../NavHeaderComponent/ButtonComponentInterface';
import UserContext from "../../Auth/UserContext";

// Initial state for the NavFooter context
const initialState: NavFooterContextState = {
  footerButtons: [
    {
      id: 'darkMode',
      className: "form-check form-switch d-flex justify-content-between align-items-center px-3 py-2 nav-link link-dark text-color-9 font-14 font-medium custom-left-padding position-relative pdgiving5",
      iconSrc: "images/darkTheme/dark-mode-icon.svg",
      iconAlt: "dark-mode-icon",
      text: "Dark Mode",
      action: "toggleTheme"
    },
  ],
  userProfile: {
    profileImage: '',
    userName: '',
    jobTitle: '',
  },
  isChecked: false,
  setIsChecked: () => {},
  handleLogout: () => {},
  toggleTheme: () => {},
  selectedTheme : ''
};

// Create the NavFooter context
export const NavFooterContext = createContext<NavFooterContextState>(initialState);

// NavFooterProvider component to manage and provide context values
export const NavFooterProvider: React.FC<NavFooterProviderProps> = ({ children }) => {

  const [footerButtons] = useState<ButtonConfig[]>(initialState.footerButtons);
  const [userProfile, setUserProfile] = useState<UserProfile>(initialState.userProfile);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState("");
  const userData = useContext(UserContext); 

  // Function to handle user logout

  const handleLogout = async () => {
    try {
      // Store the value of the key you want to keep
      const feedbackSubmitted = window.localStorage.getItem('feedbackSubmitted');
  
      // Clear sessionStorage
      window.sessionStorage.clear();
  
      // Clear localStorage
      window.localStorage.clear();
  
      // Restore the key you want to keep
      if (feedbackSubmitted !== null) {
        window.localStorage.setItem('feedbackSubmitted', feedbackSubmitted);
      }
  
      // Redirect to home page
      window.location.href = "/";
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  // Function to apply a specific theme to the application
  const applyTheme = (theme: any) => {
    document.documentElement.setAttribute("data-theme", `${theme}-theme`);
    const bodyClasses = document.body.classList;
    bodyClasses.remove('light-theme', 'dark-theme');
    bodyClasses.add(`${theme}-theme`);
  };

  // Function to toggle between light and dark themes
  const toggleTheme = () => {
    const newChecked = !isChecked;
    setIsChecked(newChecked);
    const newTheme = newChecked ? "dark" : "light";
    applyTheme(newTheme);
    localStorage.setItem("selectedTheme", newTheme);
    setSelectedTheme(newTheme);
  };

  // Effect to initialize the theme based on local storage
  useEffect(() => {
    setIsChecked(localStorage.getItem("selectedTheme") === "dark");
  }, []);

  // Value object to be provided by the context
  const value = {
    footerButtons,
    userProfile,
    isChecked,
    setIsChecked,
    handleLogout,
    toggleTheme,
    selectedTheme
  };

  // Provide the context value to children components
  return (
    <NavFooterContext.Provider value={value}>
      {children}
    </NavFooterContext.Provider>
  );
};

// Custom hook to access the NavFooter context
export const useNavFooter = (): NavFooterContextState => {
  const context = useContext(NavFooterContext);
  if (!context) {
    throw new Error('useNavFooter must be used within a NavFooterProvider');
  }
  return context;
};