import { Client } from "../Client";
import { ValyriaEndpoints } from "../API-Constants";

export async function getPlanner(body: any = {}): Promise<any> {
  try {
    const result = await Client(ValyriaEndpoints.getPlanner, body, "POST");
    return "data" in result ? result.data : null;
  } catch (error: any) {
    console.error(`error in function CostCalculationNav: `, error);
    return null;
  }
}

export async function getTerminalInput(body: any = {}): Promise<any> {
  try {
    const result = await Client(
      ValyriaEndpoints.getTerminalInput,
      body,
      "POST"
    );
    return "data" in result ? result.data : null;
  } catch (error: any) {
    console.error(`error in function CostCalculationNav: `, error);
    return null;
  }
}


export async function getmddoc(body: any = {}): Promise<any> {
  try {
    const result = await Client(ValyriaEndpoints.getvdocs, body, "POST");
    return result?.data;
  } catch (error: any) {
    console?.error(`error in function getChatHistory: `, error);
    return null;
  }
}
export async function updatemddoc(body: any = {}): Promise<any> {
  try {
    const result = await Client(ValyriaEndpoints.updatevdoc, body, "POST");
    return result?.data;
  } catch (error: any) {
    console?.error(`error in function getChatHistory: `, error);
    return null;
  }
}


export async function getTerminal(body: any = {}): Promise<any> {
  try {
    const result = await Client(ValyriaEndpoints.getTerminalInput, body, "POST");
    return "data" in result ? result.data : null;
  } catch (error: any) {
    console.error(`error in function CostCalculationNav: `, error);
    return null;
  }
}