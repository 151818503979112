//PS_UL_01
//Import statements are made here
import React, { useEffect, useState, useContext } from "react";
import axios from "axios";

import {
  getApprovalRequestData,
  fetchChat,
  errorLogs,
  getValyriaChat
} from "../../Service/API";
import UserContext from "../../Auth/UserContext";

import NumberOfRecordsComponent from "../../Reusable Components/NoofRecords";
import ButtonComponent from "../../Reusable Components/Button";
import FilterComponent from "../../Reusable Components/Filter";
import InputComponent from "../../Reusable Components/TextInput";
import ModuleHeading from "../../Reusable Components/ModuleHeading";
import ReusableTable from "../../Reusable Components/ReusableTable";
import ShareConversationPopup from "../../Reusable Components/ShareConvoPopup";
import Loader from "../../Reusable Components/Loader";

import { ModelConfig } from "../../Interfaces/LLMConfigGrid";
import { Header } from "../../Interfaces/ReusableTable";
import { ChatMessage } from "../../Interfaces/ChatHistory";
import { UserActivityLogsProps } from "../../Interfaces/UserActivityLogs";

import { useTableContext } from "../../Utility/ReusableTableContext";
import { getSessionItem } from "../../Utility/encrypt-storage";
import { useHeaderContext } from "../../Utility/HeaderContext";


interface ApprovalRequestDataObject {
  search: string;
  sort: string;
  count: number;
  fromDate: string;
  toDate: string;
  approvalStatusName: string;
  codegenStatusName: string;
  approverEmailID: string;
}

const UserActivityLogs: React.FC<UserActivityLogsProps> = ({ navOpen }) => {

  const userDetails = useContext(UserContext);
  const {
    setData: setApprovalRequestData,
    sortConfig
  } = useTableContext<ModelConfig>();
  const token = getSessionItem("access-token");

  //Necessary state variables are made here

  const [search, setSearch] = useState<string>("");
  const [sort, setSort] = useState<string>("createdDateTime Desc");
  const [count, setCount] = useState<number>(10);
  const [fromDate, setFromDate] = useState<string | null | undefined>(undefined);
  const [toDate, setToDate] = useState<string | null | undefined>(undefined);
  const [approvalStatus, setApprovalStatus] = useState<string>("");
  const [codegenStatus, setCodegenStatus] = useState<string>("");
  const [shownRecords, setShownRecords] = useState<number>(count);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [codegenStatusData, setCodegenStatusData] = useState<any[]>([]);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filterFlag, setFilterFlag] = useState<boolean>(false);
  const [sharePromptName, setSharePromptName] = useState<string>("");
  const [shareChatID, setShareChatID] = useState<string>("");
  const [isSharePopupOpen, setIsSharePopupOpen] = useState<boolean>(false);
  const [chatContent, setChatContent] = useState<ChatMessage[]>([]);
  const [profileImage, setProfileImage] = useState<string>("");
  const {setIsValyria} = useHeaderContext()
  const [chatType, setChatType]= useState('')
  const [projectID, setProjectID] = useState('')
  
  const share = window.location.href;
  const url = new URL(share);
  const baseUrl = url.origin;

  //PS_UL_03 to PS_UL_05 - Necessary useEffects of onLoad, filtering and search

  useEffect(() => {
    if (userDetails?.email) {
      fetchApprovalRequestData();
    }
  }, [userDetails]);

  useEffect(() => {
    if (userDetails?.email) {
      fetchApprovalRequestData();
    }
  }, [sort, count, sortConfig, codegenStatus, fromDate, toDate]);


  // PS_UL_06 to PS_UL_20:For rendering the popup while clicking on the prompt name
  async function profileImg(userEmailID: any) {
    try {
      if (!userEmailID || !userEmailID) {
        throw new Error("User details or email is missing");
      }

      let photoResponse = await axios.get(
        `https://graph.microsoft.com/v1.0/users/${userEmailID}/photo/$value`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      );
      const reader = new FileReader();
      reader.readAsDataURL(photoResponse.data);

      const getBase64 = () =>
        new Promise((resolve) => {
          reader.onload = () => resolve(reader.result);
        });

      const base64Image = await getBase64();

      if (typeof base64Image === "string") {
        setProfileImage(base64Image);
      } else {
        console.error("Failed to convert image to base64");
      }
    } catch (error) {
      console.error("Error fetching profile image:", error);
      setProfileImage("images/Default/default-profile-image.png");
    }
  }

  // PS_UL_06 to PS_UL_20:For rendering the popup while clicking on the prompt name
  const getChatMessages = async (chatID: string, chatType: string): Promise<void> => {
    try {
      if(chatType=='CodeGen'){
        let payload={
            "asami_chat_id" : chatID
        }
        // setLoading(true)
        let response = await getValyriaChat(payload);
        setIsValyria(true)
        setChatContent(response.data);
        // setLoading(false)
        sessionStorage.setItem('user_id',response.data[0].user_id)
        sessionStorage.setItem('project_id',response.data[0].project_id)
        setProjectID(response.data[0].project_id)
      }
      else{
        let result = await fetchChat({ chatID: chatID });
        setChatContent(result.data.ResponseData.currentChat);
        setProjectID('')
      }
    } catch (error: any) {
     const errorResult = await errorLogs({
        errorMessage: error.message,
        userEmailID: userDetails?.email,
        errorOccured: "frontEnd",
        functionName: "getGPTResponse",
      });
    }
  };
 
  //Headers for rendering the table
  const headers: Header<ModelConfig>[] = [
    { key: "userName", label: "User Name", sortable: true, component: ({ row }) => <>{row.userName.replace(/\./g, ' ')}</>, },
    { key: "userEmailID", label: "Email Address", sortable: false },
    {
      key: "createdDateTime",
      label: "Date & Time",
      sortable: true,
      component: ({ row }) => <>{formatDate(row.createdDateTime)}</>,
    },
    {
      key: "promptName",
      label: "Type",
      sortable: false,
      component: ({ row }) => (
        <a
          className="text-decoration-none link-color"
          style={{cursor:"pointer"}}
          onClick={(e) => {
            e.preventDefault();
            handleRowClick(row);
          }}
        >
          {row?.chatType === 'CodeGen' ? 'Valyria' : (row?.chatType === 'General' ? 'Ask Asami' : row?.promptName)}
        </a>
      ),
    }
  ];

  //PS_UL_08 to PS_UL_20: Fetch data from API
  const fetchApprovalRequestData = async (
    obj: ApprovalRequestDataObject | false = false
  ): Promise<void> => {
    try {
      let sort = "Asc";
      if (sortConfig.order == "desc") {
        sort = "Desc";
      }

      let approvalRequestDataObject: ApprovalRequestDataObject = {
        search: search,
        sort:
          (sortConfig.column ? sortConfig.column : "createdDateTime") +
          " " +
          (sortConfig.order ? sortConfig.order : "desc"),
        count: count,
        fromDate: fromDate ?? "",
        toDate: toDate ?? "",
        approvalStatusName: approvalStatus,
        codegenStatusName: codegenStatus,
        approverEmailID: userDetails?.email ?? "",
      };
      approvalRequestDataObject = obj ? obj : approvalRequestDataObject;

      if (
        approvalRequestDataObject.fromDate !== "" ||
        approvalRequestDataObject.toDate !== "" ||
        approvalRequestDataObject.approvalStatusName !== ""
      ) {
        setFilterFlag(true);
      } else {
        setFilterFlag(false);
      }
      setIsLoading(false);

      let response = await getApprovalRequestData(
        obj ? obj : approvalRequestDataObject
      );

      if (response.status === 200) {
        setIsLoading(true);
        let record = response.data.data.ChatTbls.length - shownRecords;
        setShownRecords(record + shownRecords);
        setApprovalRequestData(response.data.data.ChatTbls);
        setCodegenStatusData(response.data.data.codegen_statuses);
        setTotalRecords(response.data.data.prompt_count);
      }
    } catch (error: any) {
      const errorResult = await errorLogs({
          errorMessage: error.message,
          userEmailID: userDetails?.email,
          errorOccured: "frontEnd",
          functionName: "getGPTResponse",
        });
    }
  };

  // PS_UL_54 to PS_UL_63: Load more data
  const loadmoreClick = (): void => {
    let countValue = count + 10;
    setCount(countValue);
    if (shownRecords >= totalRecords) {
      setIsLoadMoreDisabled(true);
    }
  };

  // PS_UL_42 to PS_UL_43: Cancel applied filters
  const cancelFilter = (): void => {
    setFromDate(null);
    setToDate(null);
    setApprovalStatus("");
    setCodegenStatus("");

    let approvalRequestDataObject: ApprovalRequestDataObject = {
      search: search,
      sort: sort,
      count: count,
      fromDate: "",
      toDate: "",
      approvalStatusName: "",
      codegenStatusName: "",
      approverEmailID: userDetails?.email ?? "",
    };
    if (filterFlag) {
      fetchApprovalRequestData(approvalRequestDataObject);
    }
  };

  const formatDate = (date: string): string => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear(),
      hours = d.getHours(),
      minutes = d.getMinutes(),
      ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    let hoursStr: string;
    let minutesStr: string;
    if (hours < 10) {
      hoursStr = "0" + hours;
    } else {
      hoursStr = hours.toString();
    }

    if (minutes < 10) {
      minutesStr = "0" + minutes;
    } else {
      minutesStr = minutes.toString();
    }

    return (
      [month, day, year].join("/") +
      " " +
      [hours, minutes].join(":") +
      " " +
      ampm
    );
  };

  const handleRowClick = (row: any) => {
    setSharePromptName(
      row.chatType === "General" ? "Ask Asami" : row.promptName
    );
    setChatType(row.chatType);
    setShareChatID(row.ChatTblID);
    profileImg(row.userEmailID);
    getChatMessages(row.ChatTblID, row.chatType);
    setIsSharePopupOpen(true);
  };

  return (
    <>
      {!isLoading ? (
        <Loader isLoading={!isLoading} navOpening={navOpen} />
      ) : (
        <>
          <div className="row w-100 justify-content-lg-end justify-content-center">
            <div
              className={
                navOpen == "Close"
                  ? "col-11 ps-lg-5 me-xl-4 me-lg-2"
                  : "col-lg-9 col-11 me-xl-4 me-lg-2 me-0 ms-3 ms-md-0"
              }
              id="chat-history-center"
            >
              <div className="row">
                <div className="col-md-12">
                  <div className="mt-5 mb-3 d-flex justify-content-between align-items-center">
                    <ModuleHeading
                      text="User Activity Logs"
                      tag="h3"
                      className="font-20 font-semibold text-color-1"
                    />
                    <div className="table-header d-flex align-items-center filter-fix">
                      <div className="input-group transparent-grp">
                        <InputComponent
                          type="text"
                          className="form-control border-0 focus-none font-14 font-medium search-bg text-color-2"
                          placeholder="Search"
                          value={search}
                          onChange={(newValue) => setSearch(newValue)}
                          onEnterPress={fetchApprovalRequestData}
                        />
                        <ButtonComponent
                          buttonType="icon"
                          iconSrc="images/Default/input-search.svg"
                          altText="input-search-icon"
                          className="btn focus-none search-bg"
                          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            e.preventDefault();
                            fetchApprovalRequestData();
                          }}
                          style={{ height: "auto", width: "auto" }} 
                        />
                      </div>
                      <div className="dropdown custom-dashboard-section  mr-4x" style={{marginLeft: "20px"}}>
                        <FilterComponent
                          config={{
                            showDateRange: true,
                          }}
                          onApply={(filters) => {
                            if (filters.fromDate) {
                              setFromDate(filters.fromDate);
                            }
                            if (filters.toDate) {
                              setToDate(filters.toDate);
                            }
                            // if (filters.codegenStatus) {
                            //   setCodegenStatus(filters.codegenStatus);
                            // }
                            fetchApprovalRequestData({
                              search: search,
                              sort:
                                (sortConfig.column
                                  ? sortConfig.column
                                  : "createdDateTime") +
                                " " +
                                (sortConfig.order ? sortConfig.order : "desc"),
                              count: count,
                              fromDate: filters.fromDate || "",
                              toDate: filters.toDate || "",
                              approvalStatusName: approvalStatus,
                              codegenStatusName: filters.codegenStatus || "",
                              approverEmailID: userDetails?.email ?? "",
                            });
                          }}
                          onCancel={() => {
                            cancelFilter();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive rounded-3 transparent-grp">
                    <ReusableTable
                      headers={headers}
                      onRowClick={handleRowClick}
                    />
                  </div>
                  <NumberOfRecordsComponent
                    shownRecords={shownRecords}
                    totalRecords={totalRecords}
                    className="mb-0"
                  />
                  <div className="text-center mb-5">
                    <ButtonComponent
                      buttonType="text"
                      text={<span className="color-gradient">Load More</span>}
                      className="gradient-btn border-radius-4 font-14 font-semibold py-2 px-3"
                      style={{ color: "#865BE7" , marginLeft: "2px", marginTop:"4px"}}
                      onClick={loadmoreClick}
                      disabled={totalRecords <= 10 || isLoadMoreDisabled}
                      hidden={totalRecords <= 10 || isLoadMoreDisabled}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

<ShareConversationPopup
        isOpen={isSharePopupOpen}
        onClose={() => setIsSharePopupOpen(false)}
        sharePromptName={sharePromptName}
        shareDate={""}
        shareChatID={shareChatID}
        chatContent={chatContent}
        profileImage={profileImage}
        baseUrl={baseUrl}
        chatType={chatType}
        projectID={projectID}
      />
    </>
  );
};

export default UserActivityLogs;