import React, { useEffect, useState, useCallback, useContext } from "react";
import { ChangeEvent } from "react";
import { useChat } from "../../Utility/ChatContext";
import { useFileUpload } from "../../Utility/FileUploadContext";
import { useLocation, useNavigate } from "react-router-dom";
import AddKnowledgeBase from "../../Reusable Components/AddKnowledgeBase";
import { KnowledgeBaseProvider, useKnowledgeBase } from "../../Utility/AddKnowledgeBaseContext";
import { kbInsert } from "../../Service/API";
import { v4 as uuidv4 } from "uuid";
import { getSessionItem } from "../../Utility/encrypt-storage";
import axios from "axios";
import { AxiosResponse } from "axios";
import UserContext from "../../Auth/UserContext";
import { PromptBuilderProvider } from "../../Utility/ManagePromptContext";
import Toastr from "../../Reusable Components/Toastr";
import { useHeaderContext } from "../../Utility/HeaderContext";
import HighlightedTextarea from "./Components/HighlightedTextArea";
import MyContext from '../../Utility/MyContext';
import CommandPopup from "./CommandPopup";
 

interface ChatFormProps {
  onSubmit: (requirement: string, codegenCheck: boolean, url:string, BLinksList:[]) => Promise<string>;
  onUserResponse: (requirement: string) => Promise<void>;
  specialMessagesActive: boolean
  currentChatID?:any
  navOpenHeader:string 
  rightNav?:any
}
interface UploadedFile {
  name: string;
  type: string;
  path: string;
  file: File;
}

interface FolderStructure {
  [key: string]: FolderStructure | UploadedFile;
}

const ChatForm: React.FC<ChatFormProps> = ({ onSubmit, onUserResponse, specialMessagesActive , navOpenHeader , rightNav}) => {
const {
    currentChatID,
    setcurrentChatID,
    navOpen,
    pauseButtonEnable,
    haveLastAssistantMessageCome,
    isWebSearch,
    setIsWebSearch,
    setHaveLastAssistantMessageCome,
    isKbRetrieverActive,
    setIsKbRetrieverActive,
    indexName,
    setIndexName,
    // collectionName,
    // setCollectionName,
    newMessageID,
    setNewMessageID,
    promptData,
    setPromptData,
    loading,
    setLoading,
    data,
    setData,
    isKGBuild,
    setIsKGBuild,
    leftCodeArray,
    setLeftCodeArray,
    rightCodeArray,
    setRightCodeArray,
    isModule,
    setIsModule,
    excelURLs,
    setExcelURLs,
    modelID,
    setModelID,
    defaultModelFromEnv,
    setDefaultModelFromEnv,
    hiddenObjValues,
    sethiddenObjValues,
    userData,
    setUserData,
    messageOrder,
    setMessageOrder,
    pushChat,
    postMessage,
    getChatName,
    collectionName_avalon,
    handleVoiceButtonClick,
    handleAddKB,
    handleWebSearch,
    chatCreated,
    setChatCreated,
    startConversation,
    setStartConversation,
    askAsamiChatID,
    setAskAsamiChatID,
    askAsamiChatCreation,
    setAskAsamiChatCreation,
    askAsamiChatName,
    setAskAsamiChatName,
    apiCallMade,
    CodeSnippet,
    codeSnippetValue,
    isAskAsami,
    setIsAskAsami,
    isRecording,
    isEditing,
    getPromptMsgOrder,
    getUsername,
    timeout,
    setProfile,
    profile,
    setDate,
    setUserPromptQuery,
    setPopulateMsg,
    setPauseButtonEnable,
    setIsCodeGen,
    getChatMessages,
    collectionName_projects,
    setPromptIDMessages,
    newChat,
    setLoadingIndicator,
    caretCoords, setCaretCoords
  } = useChat();
  let {    isEditMessage,
    setIsEditMessage,    currentChat,
    setcurrentChat} = useChat()


  const collectionName_projects_value = JSON.parse(sessionStorage.getItem("issuesandtaskState")||"{}")['collectionName_projects'];
  let {
    sendMessageValue,
    setsendMessageValue,
    webSearchResult,
    isPrompt,
    setIsPrompt,
    setIsStopGeneration
  } = useChat();
  let block_Get_once = true;
  const {
    setFileUploaded,
    uploadMedia,
    folderUrl,
    fileShowIndicator,
    setFileShowIndicator,
    toastrLevel,
    toastrMessage,
    setToastrLevel,
    setToastrMessage
    
  } = useFileUpload();

  let BLinksList  =JSON.parse(sessionStorage.getItem("BLinksList") as any)
  const { activeComponent, isValyria} = useHeaderContext()
  const [hideTextArea, setHideTextArea] = useState(true)
  const contextData = useContext(MyContext);




  useEffect(() => {
    sessionStorage.setItem("BLinksList", JSON.stringify(BLinksList))
  }, [BLinksList])





  const [isHovering, setIsHovering] = useState(false);
  const [commandPopupVisible, setCommandPopupVisible] = useState(false);
  const [showToastr, setShowToastr] = useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);


  const navigate = useNavigate();
  const location = useLocation();

  const [showKnowledgeBaseModal, setShowKnowledgeBaseModal] = useState(false);
  const [fileDropDown, setFileDropDown] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const [showCommandPopup, setShowCommandPopup] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<Array<{ name: string, type: string }>>([]);
  const [BLinksLists,setBLinksLists] = useState([])

  // Command options with colors
  const commandOptions = [
    // { id: 'automate', label: 'Automate', command: '/Automate' },
    { id: 'codegen', label: 'CodeGen', command: '/CodeGen' },
    { id: 'prompt', label: 'Prompt', command: '/Prompt' }
  ];

  const [regen, setRegen] = useState(false)
  const [popMsg, setPopMsg] = useState(false)


  // Handle command selection
// Handle command selection
const handleCommandSelect = (command: string) => {
  if(newChat) {
    setShowToastr(true)
  }
  
  // Get the current message text
  let currentText = sendMessageValue;
  
  // Find the last slash position
  const lastSlashIndex = currentText.lastIndexOf('/');
  
  if (lastSlashIndex !== -1) {
    // Replace the slash with the selected command
    const textBeforeSlash = currentText.substring(0, lastSlashIndex);
    currentText = textBeforeSlash + command + ' ';
  } else {
    // If no slash found (shouldn't happen), just append
    currentText += command + ' ';
  }
  
  setsendMessageValue(currentText);
  setShowCommandPopup(false);
};
  const handleToastrClose = () => {
    setShowToastr(false);
  };


  const getFileIconWidth = (files: string[]): string => {
    if (files.length === 0) return "30px";
    const fileExtension = files[0].split(".").pop()?.toLowerCase();
    return ["mp4", "webp", "gif", "jpg", "png", "jpeg"].includes(
      fileExtension || ""
    )
      ? "20px"
      : "30px";
  };

  const getFileIconSrc = (files: string[]): string => {
    if (files.length === 0) return "images-new/file-icon.svg";
    const fileExtension = files[0].split(".").pop()?.toLowerCase();
    return ["mp4", "webp", "gif", "jpg", "png", "jpeg"].includes(
      fileExtension || ""
    )
      ? "images-new/attach-img.svg"
      : "images-new/file-icon.svg";
  };

  const getFileName = (files: string[]): string => {
    if (files.length === 0) return "";
    if (files.length === 1) return files[0];
    return `${files.length} files`;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setsendMessageValue(value);
 
    // Show the popup if the last character is '/'
    if (value.endsWith('/')) {
      setShowCommandPopup(true);
 
      // Calculate caret position
      const inputRect = inputRef.current?.getBoundingClientRect();
      if (inputRect) {
        setCaretCoords({ left: inputRect.left });
      }
    } else {
      setShowCommandPopup(false);
    }
  };
 
  const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    const items = e.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") !== -1) {
        const blob = items[i].getAsFile();
        if (blob) {
          const file = new File([blob], "pasted_image.png", {
            type: blob.type,
          });

          // Create a custom FileList-like object
          const dataTransfer = new DataTransfer();
          dataTransfer.items.add(file);
          const fileList = dataTransfer.files;

          uploadMedia({
            target: { files: fileList },
          } as React.ChangeEvent<HTMLInputElement>);
          e.preventDefault();
          break;
        }
      }
    }
  };

const handleSubmit = async (value:any, codeGencheck:boolean, folderUrl: string): Promise<string> => {
    if (value.trim() !== "") {
        try {
            const result = await onSubmit(value, codeGencheck, folderUrl, BLinksList);
            setFileUploaded(false);
            return result as any;
        } catch (error) {
            console.error("Error in handleSubmit:", error);
            return 'error';
        }
    }
    return 'no input';
  };

  


  function removeKeywords(requirement: string): string {
    const keywords = ["/Prompt", "/CodeGen"];
    let cleanRequirement = requirement;

    keywords.forEach((keyword) => {
      cleanRequirement = cleanRequirement.split(keyword).join("").trim();
    });

    return cleanRequirement;
  }
  // Modified handleFileUpload
  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      const newUploadedFiles = files.map(file => ({
        name: file.name,
        type: file.type
      }));

      setUploadedFiles([...uploadedFiles, ...newUploadedFiles]);
      uploadMedia(e);
    }
    setFileDropDown(false);
  };


  // const handleFileRemove = (index: number) => {
  //   const newFiles = [...uploadedFiles];
  //   newFiles.splice(index, 1);
  //   setUploadedFiles(newFiles);

  //   const newImgDocFile = [...BLinksList];
  //   newImgDocFile.splice(index, 1);
  //   setBLinksList(newImgDocFile);
  //   setFileUploaded(newImgDocFile.length > 0);
  //   // Remove this line: setUploadedFiles([])
  // };

  // Modified textarea onChange handler
  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setsendMessageValue(value);
    setPopulateMsg(value);
    
    // Check if the message already contains a command
    const hasExistingCommand = /\/(CodeGen|Prompt)\b/.test(value);
    
    // Only show command popup if there's no existing command and the last character is "/"
    if (!hasExistingCommand && value.charAt(value.length - 1) === '/') {
      setShowCommandPopup(true);
    } else {
      setShowCommandPopup(false);
    }
  };


  const handleFileRemove = (indexToRemove:any) => {
    // Retrieve the BLinksList from sessionStorage and parse it
    const storedBLinksList = JSON.parse(sessionStorage.getItem("BLinksList") as any);
    
    // Check if the file exists in the list
    if (storedBLinksList && storedBLinksList[indexToRemove]) {
      // Remove the file at the specified index
      const updatedBLinksList = storedBLinksList.filter((_:any, index:any) => index !== indexToRemove);
      
      // Save the updated list back to sessionStorage
      sessionStorage.setItem("BLinksList", JSON.stringify(updatedBLinksList));
      
      BLinksList = updatedBLinksList
      setBLinksLists(updatedBLinksList);
        }
  };
    

    const sendFunctionality = async(e:any)=> {   
    

      setHideTextArea(false)
      setRegen(false)
      setPopMsg(false)
      setFileUploaded(false);
      let promptCheck = false
      let codegenCheck = false
      if(!isPrompt){
        setIsPrompt(
          sendMessageValue.includes("/Prompt"))
      }
      setIsCodeGen(sendMessageValue.includes("/CodeGen"))
      promptCheck = sendMessageValue.includes("/Prompt")
      codegenCheck = sendMessageValue.includes("/CodeGen")

      if (sendMessageValue.trim() != "") {
        if (codegenCheck) {
          setsendMessageValue("")
          setFileShowIndicator(false)

          setUserPromptQuery(removeKeywords(sendMessageValue.trim()))
          const res = await handleSubmit(sendMessageValue, codegenCheck, folderUrl);

          // If the PromptForm is shown, don't execute the rest of the code
          if (res === 'success') {
            return;
          }

        }
        if (promptCheck) {
          setsendMessageValue("")
          setFileShowIndicator(false)

          setUserPromptQuery(removeKeywords(sendMessageValue.trim()))
          const res = await handleSubmit(sendMessageValue, codegenCheck, folderUrl);

          // If the PromptForm is shown, don't execute the rest of the code
          if (res === 'success') {
            return;
          }

        }

        if (hiddenObjValues.inputFix == "Fix your response here") {
          // sethiddenObjValues({
          //   ...hiddenObjValues,
          //   inputFix: "Send a message...",
          //   sendMessage: true,
          // });
          setsendMessageValue("");
          setFileShowIndicator(false)
          if (isEditMessage == true) {
            const lastUserChatIndex = currentChat
              .map((chat) => chat.role)
              .lastIndexOf("user");
            if (lastUserChatIndex !== -1) {
              currentChat[lastUserChatIndex].content =
                sendMessageValue;
            }
          }else{
            pushChat(currentChat, "user", sendMessageValue);
          }
          

          await postMessage(

            {
              role: "user",
              content: sendMessageValue,
            },
            null,
            null,
          );
        } else {
          setMessageOrder(0);
          //   sethiddenObjValues({
          //     ...hiddenObjValues,
          //     inputFix: "Send a message...",
          //     sendMessage: true,
          //   });
          setsendMessageValue("");
          setFileShowIndicator(false)
          if (isEditMessage == true) {
            const lastUserChatIndex = currentChat
              .map((chat) => chat.role)
              .lastIndexOf("user");
            if (lastUserChatIndex !== -1) {
              currentChat[lastUserChatIndex].content =
                sendMessageValue;
            }
          }
          if (isWebSearch) {

            const { formattedResponse, rawOutput } =
              await handleWebSearch(sendMessageValue,BLinksList);
            //  sendMessageValue = formattedResponse;
            webSearchResult = rawOutput;
            sendMessageValue = formattedResponse
          }

          if (!promptCheck && !chatCreated) {
            try {
              setLoadingIndicator(true)
              pushChat(currentChat, "user", sendMessageValue);
              const result = await getChatName(sendMessageValue); // Await the handleAPICall function
              setAskAsamiChatName(result);
              if(BLinksList?.length>0){
                pushChat(
                  currentChat,
                  "user",
                  "",
                  undefined,
                  undefined,
                  BLinksList
                  
                );

              }
              if (webSearchResult != "") {
                pushChat(
                  currentChat,
                  "user",
                  "",
                  undefined,
                  webSearchResult
                );
              }
              await postMessage(
                {
                  role: "user",
                  content: sendMessageValue,
                },
                null,
                null,
                webSearchResult,
                undefined,
                undefined,
                undefined,
                BLinksList
              );


              // Set apiCallMade to true to indicate that the API call has been made
            } catch (error) {
              console.error("\n");
            }
          }

          if (newChat) {

            setLoadingIndicator(true)
            if (!isEditMessage) {

              pushChat(currentChat, "user", sendMessageValue);
              if (BLinksList?.length > 0) {
                pushChat(
                  currentChat,
                  "user",
                  "",
                  undefined,
                  undefined,
                  BLinksList
                );
              }
              
            }

            if (webSearchResult != "") {
              pushChat(
                currentChat,
                "user",
                "",
                "",
                webSearchResult,
                BLinksList
              );
              // const lastUserMessage = currentChat.findLast(message => message.role === "user");

              // if (lastUserMessage) {
              //     await postMessage(
              //         lastUserMessage,
              //         null,
              //         null,
              //         webSearchResult
              //     );
              //   }

            }
            // if (BLinksList.length>0) {

            //    await postMessage(
            //      {
            //        role: "user",
            //        content: BLinksList + " " + sendMessageValue,
            //      },
            //      null,
            //      null,
            //      webSearchResult
            //    );
            //  } else {

            await postMessage(
              {
                role: "user",
                content: sendMessageValue,
              },
              null,
              null,
              webSearchResult,
              undefined,
              undefined,
              undefined,
              BLinksList
            );
            // }

          }
        }
      }
    setHideTextArea(true) 
    setFileUploaded(false);
  }
  const closeToastr = () => {
    setToastrMessage(null);
    setToastrLevel(undefined);
  }
    

  return (
    <div
      className={`row ${navOpenHeader === "Close" ? "leftnav" : "fixed-bottom"} chat-form-content pe-0`}
    >
      <div
        className="custom-bg fixed-bottom"
        style={{
          display : hideTextArea && ( activeComponent != "codepad" && activeComponent != "target" ) ? "block" : "none",
          left: navOpenHeader === "Close" ? "7.5%" : "21.5%",
          width: navOpenHeader == "Close" && isValyria == false ? "90%" :
          navOpenHeader == "Open" && isValyria == false ? "77%" :
          navOpenHeader === "Close" && rightNav == false ? "85%" :
            navOpenHeader === "Close" && rightNav == true ? "50%" :
              navOpenHeader === "Open" && rightNav == false ? "72%" :
                navOpenHeader == "Open" && rightNav == true ? "37%" : "72%"
        }}
      >
        {showToastr && (
  <Toastr
  message="You cannot initiate a new Prompt/CodeGen flow in this current chat session. Please create a new chat or continue as normal chat."
  level="Failure"
  onClose={handleToastrClose}
  showCloseButton={true}
/>
      )}
      {toastrMessage && <Toastr message={toastrMessage} level={toastrLevel!} onClose={closeToastr} />}
        <div className="col-md-12">
          {/* File Upload Preview */}
          {BLinksList?.length > 0 && fileShowIndicator &&(
              <div className="horizontal-scroll-container px-5 pt-3">
                <div className="d-flex align-items-start gap-3 flex-nowrap">
                  {BLinksList?.map((filePath:any, index:any) => {
                    const fileName = filePath.split('/').pop(); // Extract file name from path
                    return (
                      <div key={index} id={`file-${index}`}  title={fileName} style={{cursor:"default"}}className="doc-attach-container text-color-15 px-3 py-2 d-flex align-items-center justify-content-between">
                        <span className="d-flex align-items-center gap-2 file-name">
                          <img src={fileName.includes('jpg') || fileName.includes('png') ? '../images/attach-img.svg' : '../images-new/single-file.svg'} alt="file-icon" />
                          <span className="file-name-text">{fileName}</span>
                        </span>
                        <button className="border-0 bg-transparent d-flex" onClick={() => handleFileRemove(index)}>
                          <img className="filter-icons" src="../images/close.svg" alt="close-icon" />
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          <div className="input-group bg-color-4 mb-2 chat-form-field mt-3">
            {/* File upload button */}
            <div className="btn-group dropup file-upload-additional-css">

            {!isPrompt &&<button
                type="button"
                className="file-input-wrapper cursor-pointer btn focus-none border-0 me-3"
                style={{ display: pauseButtonEnable ? "none" : "block" }}
                onClick={() => setFileDropDown(!fileDropDown)}
                // disabled = {!showFileButton}
              >
                <img className="filter-icons" src="/images/attach-file-icon.svg" alt="attach-file" />
              </button>}

              {/* File upload dropdown */}
              {fileDropDown && (
                <ul className="upload-dpd m-1 font-14 font-medium py-3 px-2 file-upload-list-ele">
                   <label htmlFor="fileToUpload" className="dropdown-item custom-dropdown-li custom-border-bottom text-color-9 py-2 cursor-pointer">
                    Upload Files
                    <input
                      type="file"
                      name="fileToUpload"
                      id="fileToUpload"
                      onChange={handleFileUpload}
                      multiple
                      style={{ display: 'none' }}
                    />
                  </label>
                  <label htmlFor="fileUploadFolder" className="dropdown-item custom-dropdown-li custom-border-bottom text-color-9 py-2 cursor-pointer">
                    Upload Folders
                    <input
                    type="file"
                    name="fileUploadFolder"
                    id="fileUploadFolder"
                    onChange={handleFileUpload}
                    multiple
                    // @ts-ignore
                    webkitdirectory=""
                    // @ts-ignore
                    directory=""
                    style={{ display: 'none' }}
                  />
                  </label>
                  <li
                    className="dropdown-item py-2 cursor-pointer custom-dropdown-li CustomKnowledegeBase text-color-9"
                    data-bs-toggle="modal"
                    data-bs-target="#Helpus-popup"
                    onClick={() => {
                      handleAddKB();
                      setFileDropDown(false);
                      setShowKnowledgeBaseModal(true);
                    }}
                  >
                    Custom Knowledge Base
                  </li>
                </ul>
              )}


              <KnowledgeBaseProvider>
                <AddKnowledgeBase
                  show={showKnowledgeBaseModal}
                  onHide={() => setShowKnowledgeBaseModal(false)}
                  // onAddKnowledgeBase={(selectedFiles) => {
                  // }}
                  // onSave={handleSave}
                  existingKbUrls={[]}
                  currentChatID={newChat}
                />
              </KnowledgeBaseProvider>

            </div>

            {/* Textarea with command popup */}
            <div className="">
            <HighlightedTextarea
                isRecording={isRecording}
                pauseButtonEnable={pauseButtonEnable}
                hiddenObjValues={hiddenObjValues}
                isEditing={isEditing}
                sendMessageValue={sendMessageValue}
                handleTextareaChange={handleTextareaChange}
                navOpenHeader={navOpenHeader}
                rightNav={rightNav}
                sendFunctionality={sendFunctionality}
                handlePaste={handlePaste}
                caretCoords={caretCoords}
                setCaretCoords={setCaretCoords}
                showToastr={showToastr}
              />

              {/* Command popup */}
              {/* {showCommandPopup && (
                <div className="bg-color-2 position-absolute custom-options-container ">
                  <ul className="p-0 list-style-none m-0 py-2 chat-form-list-fix custom-options-container">
                    {commandOptions.map(option => (
                      <li key={option.id}>
                        <button
                          className="border-0 bg-transparent-chat-form font-12 font-medium text-color-9 text-start w-100 px-3 py-2 hover:bg-gray-100"
                          onClick={() => handleCommandSelect(option.command)}
                        >
                          {option.label}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )} */}
              {showCommandPopup && (
                <CommandPopup
                  showCommandPopup={showCommandPopup}
                  caretCoords={caretCoords}
                  commandOptions={commandOptions}
                  handleCommandSelect={handleCommandSelect}
                />
              )}

                <button
                           type="button"
                           className="file-input-wrapper cursor-pointer btn focus-none border-0 mic-fix-text"
                           style={{ display: pauseButtonEnable ? "none" : "block",  marginRight:'2%' }}
                           onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                             setPauseButtonEnable(true);
                             handleVoiceButtonClick(e);
                           }}
                           // onClick={handleVoiceButtonClick}
                         >
                           <img
                             className="filter-icons"
                             src="/images/microphone-icon.svg"
                             alt="microphone"
                           />
                         </button>
             
                         {/* Stop button (visible when recording) */}
                         <button
                           className="btn focus-none border-0 px-4"
                           type="button"
                           style={{ display: pauseButtonEnable ? "block" : "none", top: '-49%', right: '-123%', marginRight: '2%' }}
                           onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                             setPauseButtonEnable(false);
                             handleVoiceButtonClick(e);
                           }}
                         >
                           <img
                             className="filter-icons"
                             src="images/stop-icon.svg"
                             alt="stop"
                           />
                         </button>
             
                         {/* Send button */}
                         <button
                           className="btn focus-none border-0 px-4 send-button-text"
                           style={{ display: pauseButtonEnable ? "none" : "block"}}
                           type="button"
                           //   onClick={() => handleSendMessage(sendMessageValue)}
                           disabled={showToastr==true}
                           onClick={async(e)=>{
                            handleToastrClose()
                            setIsStopGeneration(false)
                            await sendFunctionality(e);
                           }}
                         >
                           <img
                             className="filter-icons"
                             src="images/Default/send-img.svg"
                             alt="send-img"
                           />
                         </button>              
            </div>

            {/* Voice and Send buttons */}
            {/* ... (keep existing buttons) */}
             
              {/* Voice button */}
                         
                       </div>
                       
              {/* Web Search and KG Search toggles */}
          <div className="col-md-4 col-sm-12 web-search-fix-chat-page d-flex align-items-center">
            <p
              className="font-15 font-semibold mb-0 text-color-4"
              style={{ marginRight: "2px"}}
            >
              Web Search
            </p>
            <div
              className="form-check form-switch me-5 d-inline-block"
              style={{ paddingBottom: "3px" }}
            >
              <input
                className="form-check-input custom-toggle-input"
                type="checkbox"
                role="switch"
                id="webSearch"
                style={{ cursor: "pointer", marginLeft: "-35px" }}
                checked={isWebSearch}
                onChange={() => setIsWebSearch(!isWebSearch)}
              />
            </div>

            {collectionName_projects_value&&  (JSON.parse(sessionStorage.getItem("issuesandtaskState")||"{}")['isModule']!='tasks'||JSON.parse(sessionStorage.getItem("issuesandtaskState")||"{}")['iskgsearch']==true) && (
              <>
                <p
                  className="font-15 font-semibold mb-0 text-color-4"
                  style={{ marginRight: "2px" }}
                >
                  KG Search
                </p>
                <div
                  className="form-check form-switch me-5 d-inline-block"
                  style={{ paddingBottom: "3px" }}
                >
                  <input
                    className="form-check-input custom-toggle-input"
                    type="checkbox"
                    role="switch"
                    id="kgSearch"
                    style={{ cursor: "pointer", marginLeft: "-35px" }}
                    checked={isKGBuild}
                    onChange={() => setIsKGBuild(!isKGBuild)}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatForm;

