import React from 'react';
import { NoRecordsFoundProps } from '../Interfaces/NoRecordsFound';

const NoRecordsFound: React.FC<NoRecordsFoundProps> = ({ message,nodata }) => {
  return (
    <div className={`text-center py-5 ${!nodata ? 'vh-50' : ''}`}>
      {!nodata&&<img
        src="images/Default/no-record-img.svg"
        alt="no-record-img"
        className="no-record-img mb-5"
      />}
  
      <span className="font-20 font-medium w-100 d-block text-color-1">
        {message}
      </span>
    </div>
  );
};

NoRecordsFound.defaultProps = {
  message: "No Records Found!"
};

export default NoRecordsFound;