import React, { useState, useEffect, useRef } from 'react';
import { useManagePromptCard } from '../Utility/ManagePromptCardContext';
import { usePromptBuilder } from '../Utility/ManagePromptContext';

interface TechStackOption {
  promptLogoID: string;
  promptLogoName: string;
  promptLogoPath: string;
}

interface TechStackProps {
  name: string;
  label: string;
  disabled?:any;
  value: string;
  options?: TechStackOption[];
  required?: boolean;
  error?: string;
  onChange: (value: string) => void;
}

const TechStack: React.FC<TechStackProps> = ({
  name,
  label,
  value,
  disabled,
  options = [],
  required,
  error,
  onChange,
}) => {
  const {promptIndex, setPromptIndex} = useManagePromptCard();
  const{promptData,promptObj} = usePromptBuilder();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<TechStackOption | null>(null);
 

  useEffect(() => {
    if (Array.isArray(options) && options.length > 0) {
      const selected = options.find(option => option.promptLogoName === value);
      if (selected) {
        setSelectedOption(selected);
      }
    }
  }, [value, options]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelect = (option: TechStackOption) => {
    if(promptIndex){
    promptIndex.promptLogoID=option.promptLogoID
    promptIndex.promptLogoName=option.promptLogoName
    promptIndex.promptLogoPath=option.promptLogoPath
    }
    if(promptData){
      promptData.logo=option
      promptData.promptLogoID=option.promptLogoID

    }
    setSelectedOption(option);
    onChange(option.promptLogoName);
    setIsOpen(false);
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <div className="tech-stack-dropdown" ref={dropdownRef}>
      <div className="dropdown-container">
        <button
          type="button"
          className="btn input-field font-14 font-regular border-radius-3 bg-color text-color-4 custom-border-3 cust-search-input w-100 d-flex justify-content-between align-items-center"
          disabled={disabled}
          onClick={toggleDropdown}
        >
          {selectedOption ? (
            <>
              <img src={selectedOption.promptLogoPath} alt={selectedOption.promptLogoName} className="me-2" style={{ width: '20px', height: '20px' }} />
              <span>{selectedOption.promptLogoName}</span>
            </>
          ) : (
            <span>Select</span>
          )}
          <span className="ps-1">
            <div
              style={{
                width: '10px',
                height: '20px',
                transform: isOpen ? "rotate(180deg)" : "",
                backgroundColor: "var(--select-arrow-color)",
                WebkitMask: `url('images-new/dropdown-grey-icon.svg') no-repeat center / contain`,
                mask: `url('images-new/dropdown-grey-icon.svg') no-repeat center / contain`,
                transition: "transform 0.3s ease"
              }}
              className="custom-dropdown-fix"
            />
          </span>
        </button>
        {isOpen && Array.isArray(options) && options.length > 0 && (
          <div className="dropdown-menu-custom border-0 shadow-sm p-2 bg-color-2" style={{ position: 'absolute', zIndex: 1000, maxHeight: '200px', overflowY: 'auto', 'width':'9.5%' }}>
            {options.map((option) => (
              <div 
                key={option.promptLogoID} 
                onClick={() => handleSelect(option)} 
                className="dropdown-item-custom d-flex align-items-center p-2 cursor-pointer logo-dropdown"
                style={{ cursor: 'pointer' }}
              >
                <img src={option.promptLogoPath} alt={option.promptLogoName} className="me-2" style={{ width: '20px', height: '20px' }} />
                <span className='text-color-1'>{option.promptLogoName}</span>
              </div>
            ))}
          </div>
        )}
      </div>
      {error && <div className="text-danger mt-1">{error}</div>}
    </div>
  );
};

export default TechStack;