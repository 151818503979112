import React, { useState, useEffect } from 'react';
import { getProject, getProjectConfig, kgBuildService, ExistData } from '../../../Service/API';
import { useNavigate } from 'react-router-dom';
// import ManageProjectForm from './TfsForm';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useMsal, useAccount, useIsAuthenticated } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-browser";
import devConfig from '../../../config/env';
import UserContext from '../../../Auth/UserContext';
import { useContext } from "react";
import { TableProvider, useTableContext } from '../../../Utility/ReusableTableContext';
import ReusableTable from '../../../Reusable Components/ReusableTable';
import { Header } from '../../../Interfaces/ReusableTable'
import InputComponent from '../../../Reusable Components/TextInput';
import ButtonComponent from '../../../Reusable Components/Button';
import { FilterProvider } from '../../../Utility/FilterContext';
import FilterComponent from '../../../Reusable Components/Filter';
import { useFilter } from '../../../Utility/FilterContext';
import { ActionMeta, MultiValue, SingleValue } from 'react-select';
import { Option } from '../../../Utility/FilterContext';
import ManageProjectForm from '../../../Reusable Components/ManageProjectForm';
import SortableHeader from '../../../Reusable Components/SortableHeader';
import SortIcon from '../../../Reusable Components/SortIcon';
import Loader from '../../../Reusable Components/Loader';
import NoRecordsFound from '../../../Reusable Components/NoRecordsFound';
import NumberOfRecordsComponent from '../../../Reusable Components/NoofRecords';


interface Project {
  projectID: string;
  projectName: string;
  image_url: string;
  projectTimeline: string;
  Status: string;
}

interface ProjectConfig {
  projectID: string;
  isConfigured: number;
}

interface StatusDropdown {
  project_status: string;
}

interface NavOpenProps {
  navOpen: string;
}

interface UserDetails {
  role: string;
}

export function ManageProject({ navOpen }: NavOpenProps) {
  const { data: project, setData, setDetailedViewData, setIsModalOpen, sortConfig, setSortConfig } = useTableContext<Project>();
  
  const [statusDropdown, setStatusDropdown] = useState<StatusDropdown[]>([]);
  const [flag, setFlag] = useState<string>("");
  const [filterAlert, setFilterAlert] = useState<string>("");
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [projectID, setProjectID] = useState<string>("");
  const [searchProject, setSearchProject] = useState<string>("");
  const [subSearchProject, setSubSearchProject] = useState<string>("");
  const [projectCheckbox, setProjectCheckbox] = useState<string[]>([]);
  const [filterStatus, setFilterStatus] = useState<string>("");
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [endOffset, setEndOffset] = useState<number>(10);
  const [projectDropdown, setProjectDropdown] = useState<any>("");
  const [sortColumn, setSortColumn] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [isAscendingProject, setIsAscendingProject] = useState<boolean>(true);
  const [lengthOfRecords, setLengthOfRecords] = useState<number>(0);
  const [buttonShow, setButtonShow] = useState<boolean>(false);
  const [loadThing, setLoadThing] = useState<boolean>(false);
  const [searchDubProject, setSearchDubProject] = useState<string>("");
  const [showTfsPopUp, setShowTfsPopUp] = useState<boolean>(false);
  const [projectName, setProjectName] = useState<string>("");
  const [projectTFSId, setProjectTFSId] = useState<string>("");
  const navigate = useNavigate();
  const [advancefilterpopup, setadvancefilterpopup] = useState<boolean>(false);
  const [showmenu, setShowMenu] = useState<boolean>(false);
  const [tfsConfigOrUpdate, setTfsConfigOrUpdate] = useState<string>("configure");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [projectIDs, setProjectIDs] = useState<{ projectID: string }[]>([]);
  const [projectConfigValue, setProjectConfigValue] = useState<ProjectConfig[]>([]);
  const [btnIsconfig, setbtnIDconfig] = useState<string>("");
  const [cancel, setCancel] = useState<boolean>(false);
  const [hasAccess, setHasAccess] = useState<boolean>(false);
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || null);
  const userDetails = useContext(UserContext) as UserDetails;
  const [filters, setFilters] = useState<{ [key: string]: string | undefined }>({});
  const { setDropdownValue, dateRange,setDateRange } = useFilter();
  const [triggerFetch, setTriggerFetch] = useState(0);
  const [activeProject, setActiveProject] = useState<Project | null>(null);
  const [configureOrEdit, setConfigureOrEdit] = useState<"configure" | "EDIT">("configure");
  const [nodata,setNoData]=useState(false)
  const [formData, setFormData] = useState({
    userName: "",
    repoUrls: ""
  });
  
  

 

  

  const [lengthIssue, setLengthIssue] = useState<{
    success: boolean;
  }>({
    success: false
  });

  useEffect(() => {
    if (projectIDs.length > 0) {
      fetchConfigProject();
    }
    else{
      setIsLoading(true);
    }
  }, [projectIDs]);

  useEffect(() => {     
    setIsLoading(false);
    fetchProject();
  }, [searchProject, filters,  endOffset, sortConfig]);

  useEffect(() => {
    if (buttonShow) {
      setShowFilter(true);
    } else {
      setShowFilter(false);
    }
  }, [buttonShow]);

  useEffect(() => {
    async function checkAccess() {
      const result = await accessCheck();
      setHasAccess(result);
    }
    checkAccess();
  }, [])



  const fetchProject = async () => {
    let data = {
      search: searchProject,
      sortColumn: sortConfig.column,
      sortOrder: sortConfig.order,
      subSearch: filters.subSearch || '',
      checkbox: filters.project ? filters.project.split(',') : [],
      status: filters.status || '',
      fromDate: filters.fromDate || dateRange.from||'',
      toDate: filters.toDate ||dateRange.to|| '',
      offsetValue: endOffset
    };
    
    try {
      setIsLoading(false)
      let response: any = await getProject(data);
      setIsLoading(true)
      
      if (response && response.status === 200 && response.data[0]?.success) {
        // Only try to access these properties if we have a successful response with projects
        setData(response.data[0]?.projects || []);
        setLengthOfRecords(response.data[0]?.projects?.length || 0);
        setTotalRecords(response.data[0]?.totalRecords || 0);
        setLengthIssue(response.data[0] || { success: false });
        
        const uniqueProjectIDs = Array.from(
          new Set(response.data[0]?.projects?.map((project: Project) => project?.projectID) || [])
        ).map(projectID => ({ projectID: projectID as string }));
        
        setProjectIDs(uniqueProjectIDs);
        setProjectDropdown(response.data[0]?.projects || []);
        setbtnIDconfig(response.data[0]?.group_name || "");
      } else {
        // Handle all error cases (404, other errors, or invalid response)
        // Don't try to access properties that might not exist
        setData([]);
        setLengthOfRecords(0);
        setTotalRecords(0);
        setLengthIssue(response.data[0] || { success: false });
        setProjectIDs([]);
        setProjectDropdown([]);
        setbtnIDconfig("");
        setIsLoading(true)
      }
    } catch (error) {
      console.error('Fetch error:', error);
      // Handle error case by setting empty data
      setData([]);
      setIsLoading(true)
      setLengthOfRecords(0);
      setTotalRecords(0);
      setLengthIssue({ success: false });
      setProjectIDs([]);
      setProjectDropdown([]);
      setbtnIDconfig("");
    }
  };

  const handleApplyFilters = (appliedFilters: { [key: string]: string | undefined }) => {
    setFilters(appliedFilters);
    if (appliedFilters.fromDate && appliedFilters.toDate) {
      setDateRange({
        from: new Date(appliedFilters.fromDate),
        to: new Date(appliedFilters.toDate)
      });
    }
    // Update dropdown values
    Object.entries(appliedFilters).forEach(([key, value]) => {
      if (key !== 'fromDate' && key !== 'toDate') {
        const options = value ? value.split(',').map(v => ({ value: v, label: v=='completed'?"Completed":v=='notStarted'?"Not Started" :v=='inProgress'?'In Progress' :v})) : [];
        if (options.length > 0) {
          const actionMeta: ActionMeta<Option> = {
            action: 'select-option',
            option: options[0],
            name: key
          };
          setDropdownValue(key, options.length === 1 ? options[0] : options, actionMeta);
        } else {
          const actionMeta: ActionMeta<Option> = {
            action: 'clear',
            removedValues: [],
            name: key
          };
          setDropdownValue(key, null, actionMeta);
        }
      }
    });
  };
  
  
  
  const handleCancelFilters = () => {
    setFilters({});
    setDateRange({ from: null, to: null });
    // Reset all dropdown values
    ['project', 'status'].forEach(key => {
      const actionMeta: ActionMeta<Option> = {
        action: 'clear',
        removedValues: [],
        name: key
      };
      setDropdownValue(key, null, actionMeta);
    });
  };
  



  const fetchConfigProject = async () => {
    try {
      let data = {
        "projects": projectIDs
      };
      const response = await getProjectConfig(data);
      setIsLoading(true);
      setNoData(false)
      if (response.status_code === 200) {
        setProjectConfigValue(response.data.projects.projects);
        setStatusDropdown(response.data.statusDropdownResult);
      }
    } catch (error) {
      setIsLoading(true);
      console.error('Error in projectFetchIsconfig:', error);
    }
  };

  const getIsConfigured = (projectID: string) => {
    const config = projectConfigValue.find(item => item.projectID.toLowerCase() === projectID?.toLowerCase());
    return config ? config.isConfigured : null;
  };

  
  const accessCheck = async () => {
    try {
      const accountResponse = await instance.acquireTokenSilent({
        account: account as AccountInfo,
        scopes: ["User.Read"],
      });

      if (userDetails.role === "Lead") {
        return true;
      } else if (userDetails.role === "Leadership") {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error checking access:", error);
      return false;
    }
  };


  const ActionButtons = ({ row }: { row: Project }) => (
    <>
      {getIsConfigured(row.projectID) === 2 ? (
        <>
          <button className="btn text-nowrap font-14 font-medium" onClick={() => handleEdit(row)}>
            <img src="images-new/edit-img.svg" title="Edit" alt="Edit" style={{cursor: "pointer"}} />
          </button>
          <button className="btn text-nowrap font-14 font-medium">
            <img src="images-new/re-load.svg" title="Rebuild" alt="Reload" style={{cursor: "pointer"}}/>
          </button>
        </>
      ) : getIsConfigured(row.projectID) === 1 ? (
        <button className="btn text-nowrap font-14 font-medium text-color-1" disabled>
          Building
        </button>
      ) : (
        <button className="btn text-nowrap font-14 font-medium"  onClick={() => handleConfigure(row)}>
          <img src="images-new/right-icon.svg" alt="Configure" />
        </button>
      )}
    </>
  );


  const handleRowClick = (row: Project) => {
    setDetailedViewData(row);
    setIsModalOpen(true);
  };


  const handleEdit = async (row: Project) => {
    setActiveProject(row);
    setConfigureOrEdit("EDIT");
    const data = await fetchProjectDetails(row.projectID);
    if (data) {
      setFormData({
        userName: data.user_name,
        repoUrls: data.Repo_data.map((repo: any) => repo.Repo_Url).join(", ")
      });
    }
    setShowTfsPopUp(true);
  };
  
  const fetchProjectDetails = async (projectID: string) => {
    try {
      const response = await ExistData({ projectID });
      if (response.status_code === 200) {
        return response.data.Responsedata[0];
      }
    } catch (error) {
      console.error('Error fetching project details:', error);
    }
  };
  
  

  const handleConfigure = (row: Project) => {
    setActiveProject(row);
    setConfigureOrEdit("configure");
    setShowTfsPopUp(true);
    setFormData({
      userName: "",
      repoUrls:""
    });
  };

  const handleSearchChange = (newValue: string) => {
    setSearchDubProject(newValue);
  };

  const handleSearchEnter = () => {
    setSearchProject(searchDubProject);
  };

  
  const handleSort = (column: string) => {
    setSortConfig(prevConfig => ({
      column,
      order: prevConfig.column === column && prevConfig.order === 'asc' ? 'desc' : 'asc'
    }));
  };

  const handleProjectNameClick = (project: Project) => {
    
    if (true) {
      
      let projectstate={
        projectID:project.projectID,
        projectIdFromMP: project.projectID,
        workItemFromMP: "task",
        projectName: project.projectName,
        "ProjectImage":project.image_url
      }
      sessionStorage.setItem('projectState',JSON.stringify(projectstate));
      navigate('/manage-issues', {
        state: {
          projectIdFromMP: project.projectID,
          workItemFromMP: "task",
          projectName: project.projectName
        }
      });
    }
  };

  const ProjectNameComponent: React.FC<{ row: Project }> = ({ row }) => {
    const isConfigured = getIsConfigured(row.projectID);
  
    if (isConfigured === 2) {
      return (
        <a  
          onClick={(e) => {
            // e.preventDefault();
            handleProjectNameClick(row);
          }}
          className="blue-link text-decoration-none font-14 font-semibold cursor-pointer"
        >
         <span className="project_logo_bg">{row.image_url?<img src={row.image_url} alt="Profile" className=" me-2" />: <img src="images-new/default-project-icon.svg" alt="Profile" className=" me-2" />} </span>
         <span className = 'project-config-href' title={row.projectName}>
         {row.projectName.length > 40 ? `${row.projectName.substring(0, 40)}...` : row.projectName}
         </span>
         
        </a>
  
      );
    } else {
      return (
        <span className="font-14 font-semibold text-color-9">
          <span className="project_logo_bg">{row.image_url?<img src={row.image_url} alt="Profile" className=" me-2" />: <img src="images-new/default-project-icon.svg" alt="Profile" className=" me-2" />} </span>
          {row.projectName.length > 40 ? `${row.projectName.substring(0, 40)}...` : row.projectName}
        </span>
      );
    }
  };
  
  const rowStatus = ({ row }: { row: Project }) => (
    <>
    
      <td>{row.Status=='completed'?"Completed":row.Status=='notStarted'?"Not Started" :row.Status=='inProgress'?'In Progress' :""}</td>
    </>
  );

  const headers: Header<Project>[] = [
    { 
      key: 'projectName', 
      label: 'Project Name', 
      sortable: true,
      component: ProjectNameComponent
    },
    { key: 'projectTimeline', label: 'Planned Timeline', sortable: true },
    { key: 'Status', label: 'Status', sortable: true,component:rowStatus },
    { key: 'actions', label: 'Actions', component: ActionButtons },
  ]


  return (
    <>
      {!isLoading ? (
        <Loader isLoading={!isLoading}  navOpening={navOpen}/>
      ) : nodata?(<>
        <div className="text-center py-5 vh-50" style={{marginTop:"9%"}}>
                            <NoRecordsFound message="No Records Found!" />
                              </div></>): (
        <>
          <div className="container-fluid">
            <div className="row justify-content-lg-end ms-lg-3 ms-xl-0 justify-content-center">
              <div className={navOpen == "Close" ? `col-11 me-xl-4 me-lg-3` : `col-lg-9 me-md-3 me-lg-4 me-xl-5 col-11`}>
                <div className="row">
                  <div className="col-md-12 px-4 pt-3 pe-2">
                    <div className="mt-3 mb-4 d-grid d-md-flex justify-content-md-between align-items-center">
                      <h2 className="font-bold font-20 mb-0 text-nowrap text-color-1">Manage Projects ({project?.length})</h2>
                      <div className="table-header d-md-flex align-items-center">
                        <div className="p-2 px-3 border border-radius-35 d-flex justify-content-between me-3 mt-3 mt-md-0 search-bg border-color-10">
                          <span className="flex-nowrap text-nowrap text-color-9">Integrated with</span>
                          <img src="images-new/avalon-v2.svg" alt="icon" className="ms-2" />
                        </div>
                        <div className="d-flex align-items-center mt-3 mt-md-0">
                          <div className="input-group transparent-grp w-225 me-3 m-0 search-bg">
                            <InputComponent
                              type="text"
                              className="form-control custom-search-input text-color-4 bg-transparent border-0 focus-none font-14 font-medium pe-0 ps-3 text-nowrap"
                              placeholder="Search"
                              value={searchDubProject}
                              onChange={handleSearchChange}
                              onEnterPress={handleSearchEnter}
                            />
                            <ButtonComponent
                              buttonType="icon"
                              iconSrc="images-new/input-search.svg"
                              altText="input-search-icon"
                              className="btn focus-none border-0"
                              onClick={handleSearchEnter}
                              style={{ height: '100%', width: 'auto' }}
                            />
                          </div>
                          <div className="dropdown custom-dashboard-section">
                          <FilterComponent
                            config={{
                              dropdowns: {
                                project: {
                                  options: projectDropdown.map((project: any) => ({
                                    value: project.projectName,
                                    label: project.projectName
                                  })),
                                  isMulti: true,
                                  label: "Project"
                                },
                                status: {
                                  options: statusDropdown.map((status: any) => ({
                                    value:  status.project_status,
                                    label: status.project_status=='completed'?"Completed":status.project_status=='notStarted'?"Not Started" :status.project_status=='inProgress'?'In Progress' :status.project_status
                                  })),
                                  isMulti: false,
                                  label: "Status"
                                }
                              },
                              showDateRange: true
                            }}
                            onApply={handleApplyFilters}
                            onCancel={handleCancelFilters}
                          />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="table-responsive rounded-3 transparent-grp mb-4">
                      <ReusableTable<Project>
                          headers={headers}
                          onRowClick={handleRowClick}
                        />
                      </div>
                      <NumberOfRecordsComponent
                        shownRecords={project.length}
                        totalRecords={totalRecords}
                        className="mb-0"
                      />
                      <div className="text-center mb-5">
                        <button
                          className="btn gradient-btn border-radius-4 font-14 font-semibold py-2 px-3"
                          onClick={() => {
                            setEndOffset(prevEndOffset => prevEndOffset + 10);
                          }}
                          hidden={totalRecords <= project.length}
                        >
                          <span className="color-gradient">Load More</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showTfsPopUp && activeProject && (
        <ManageProjectForm
        handleCancelFilters={handleCancelFilters}
          projectName={activeProject.projectName}
          setShowTfsPopUp={setShowTfsPopUp}
          configureOrEdit={configureOrEdit}
          projectID={activeProject.projectID}
          initialData={formData}
        />
      )}
        </>
      )}
    </>
  );
}