import React from 'react';
import { ColumnComponentProps } from '../../Interfaces/Column';

const ColumnComponent: React.FC<ColumnComponentProps> = ({ children, className = '', style }) => {
  return (
    <div className={`col ${className}`} style={style}>
      {children}
    </div>
  );
};

export default ColumnComponent;


