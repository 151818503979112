import React, { useContext, useEffect, useState,useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useHeaderContext } from '../Utility/HeaderContext';
import UserContext from "../Auth/UserContext";
import { getSessionItem } from '../Utility/encrypt-storage';

import ButtonComponent from './NavHeaderComponent/ButtonComponent';
import { useButtonActions } from './NavHeaderComponent/ButtonComponentActionContext';
import NavLink from './NavLinkComponent/NavLinkComponent';
import { useNavLink } from './NavLinkComponent/NavLinkContext';
import NavFooter from "./NavFooterComponent/NavFooterComponent";

import ShareConversationPopup from '../Reusable Components/ShareConvoPopup';

interface HeaderComponentProps {
  setNavOpen: any;
  navOpen: any;
}

export const HeaderComponent: React.FC<HeaderComponentProps> = ({
  setNavOpen, navOpen
}) => {

  // Save the state of the navigation menu in session storage when it is open
  if (navOpen) {
    sessionStorage.setItem('navOpen', navOpen)
  }

  // Destructure values and functions from the header context
  const {
    bindChatHistory,
    chatContent,
    handleNewChat,
    askAsami,
    bindChat,
    isCopied,
    shareChatID,
    sharePromptName,
    setIsSharePopupOpen,
    isSharePopupOpen,
    profileImage,
    chatType,
    projectId
  } = useHeaderContext();
  var { leftNav, setLeftNav } = useButtonActions();
  const userData = useContext(UserContext);


  const share = window.location.href;
  const token = getSessionItem("access-token");
  const url = new URL(share);
  const baseUrl = url.origin;
  const navigate = useNavigate();
    
  const [isDarkMode, setIsDarkMode] = useState()
  const [isExpandedPC, setIsExpandedPC] = useState('Close')

  // Effect to sync the left navigation state with the navOpen prop
  useEffect(() => {
    setNavOpen(leftNav)
  }, [leftNav]);

  // Get the active tab and role from the nav link context
  const { activeTab, setActiveTab, role } = useNavLink();

  // Effect to sync the left navigation state with the navOpen prop
  useEffect(() => {
    setLeftNav(navOpen)
  }, [navOpen]);

  // Function to navigate to the chat page
  const navigateToChat =  useCallback(() => {
    ['issuesandtaskState', 'isModule', 'previousLocation', 'projectState', 'BLinksList'].forEach(item => sessionStorage.removeItem(item));  
    const newAskAsamiKbData  = {
      chatID: null,
      collectionName:  null,
      indexName: null,
      kbFiles: [], 
      kbUrl: [], 
      isKbRetriever: true
    };
    sessionStorage.setItem('askAsamiKbData', JSON.stringify(newAskAsamiKbData));
    sessionStorage.setItem('BLinksList', JSON.stringify([]));
    // history('/chat', {
    //   state: {
    //     startConversation: true,
    //     hidePrompts: true,
    //     sendMessage: false,
    //     fixButton: true,
    //     yesFixButton: true,
    //     askAsamiChatID: true
    //   }
    // });
   
    window.location.href = '/chat';
  }, [navigate]);

  return (
    <>
      {/* Responsive left navigation for mobile view */}
      <div>
        
        <nav className="navbar navbar-light bg-color-2 d-block d-lg-none overlay-style sticky-top " style={{ position: 'absolute' }}>
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center w-100">
              <div style={{ display: "flex" }}>
                <button className="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                  <span className="navbar-toggler-icon icon-filter" />
                </button>
                <a className="navbar-brand" onClick={() => {
                  window.location.href = "/chat";
                }}>
                  <img src="images/Default/asami-logo.svg" alt="logo" className="d-inline-block align-text-top w-100" onClick={() => {
                    window.location.href = "/chat";
                  }} />
                </a>
              </div>
            </div>
          </div>
        </nav>

        {/* Offcanvas menu for mobile view */}
        <div className="offcanvas offcanvas-start" tabIndex={-1} id="offcanvasExample" style={{ zIndex: 1052, position: 'absolute' }}>
          <div className="offcanvas-body nav-bg">
            <div className="py-2 px-2 nav-bg border-radius-12 nav-custom-style" style={{width:"72%"}}> 
              <div className="col-md-12 custom-border-bottom px-3 d-flex justify-content-between mb-3">
                <button type="button" className="btn-close text-reset icon-filter" data-bs-dismiss="offcanvas" aria-label="Close" />
              </div>
              <div className="col-md-12 my-2 d-flex flex-column align-items-center w-75">
             
                <button className="btn btn-gradient-outline cust-nav-btn border-radius-4 font-14 font-medium w-100 py-2 mb-2" onClick={() => { askAsami() }} type="button">
                  <img src="images\white-diamond.svg" onClick={() => { askAsami() }} alt="add-icon" />
                  <span className="custom-none primary-color ms-1" onClick={() => {  askAsami(); }}>Ask Asami</span>
                </button>
              </div>
              <ul className="nav nav-pills mt-4">
                <NavLink id="" />
              </ul>
              <div className="under-line-nav"></div>
              <div className="accordion" id="accordionExample">
                <div className="accordion-item border-0 bg-transparent">
                  <h2 className="accordion-header w-100 bg-transparent position-relative" id="headingTwo">
                    <button className="left-nav-acc accordion-button collapsed border-0 bg-transparent w-100 nav-link link-dark font-14 font-medium" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" style={{ "marginLeft": "-3%" }} aria-expanded="false" aria-controls="collapseTwo">
                      <img className="filter-icons" src="../images/past-convo.svg" alt="manage-execution" />
                      {leftNav === "Open" ? (<span className="ms-2 past-convo-button" style={{ color: 'inherit' }}>Past Conversations</span>) : null}
                    </button>
                  </h2>
                  {leftNav==="Open"&&(
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="custom-convo-scroll-style user-nav-scroll accordion-body bg-color-2 pe-3 left-nav-acc-body scroll-fix-header" style={{ height: role == "User" ? '40vh' : role == "Lead" ? '35vh' : "" }}>
                       {bindChat(bindChatHistory)}
                     </div>
                   </div>
                  )}
                 
                </div>
              </div>
              <NavFooter setIsDarkMode={setIsDarkMode}></NavFooter>
            </div>
          </div>
        </div>

      </div>

      {/* Left navigation for desktop view */}
      <div id="sidebar" className={`custom-left-section-width profile-margin-padding d-none d-lg-block nav-bg nav-custom-style dark-theme-helper-header border-radius-12 left-nav-scroll ${leftNav === "Close" ? "active" : ""} left-nav-fix`} style={{ position: "fixed", overflowY: "auto", overflowX: "hidden" }}>
        <div style={{ marginBottom: role == "Lead" ? "40%" : role == "User" ? "58%" : "" }} className='left-nav-top-fix'>
          <div className={` ${leftNav === "Open" ? "w-100" : "col-md-12"} custom-border-bottom px-3 position-relative mb-2`}>
            {isDarkMode ?
              (<a className="nav-light-logo" style={{ cursor: "pointer" }} ><img className="mb-2 asami-logo" style={{ cursor: "pointer", height: "60px", width: "150px" }} src="videos\Logo yy.gif" alt="logo" onClick={navigateToChat} /></a>) : (
                <a className="nav-dark-logo" style={{ cursor: "pointer" }} ><img className="mb-2 asami-logo" style={{ cursor: "pointer", height: "60px", width: "150px" }} src="videos\Logo white.gif" alt="logo" onClick={navigateToChat} /></a>)}
            <a style={{ cursor: "pointer" }}><img className="mb-2 asami-logo-navclose" style={{ cursor: "pointer", height: "60px", width: "60px", marginLeft: leftNav !== "Open" ? "-59%" : "" }} src="videos\Logo.gif" alt="logo" onClick={navigateToChat} /></a>
            <ButtonComponent id="toggle" />
          </div>
          <div className="col-md-12 my-2 d-flex flex-column align-items-center w-100">
            <ButtonComponent id="new-chat" />
            <ButtonComponent id="ask-asami" />
          </div>

          <NavLink id="" />

          <div className="accordion w-100" id="accordionExample" style={{ height: "30vh" }}>
            <div className="accordion-item border-0 bg-transparent">
              <h2 className="accordion-header mb-2 w-100 bg-transparent position-relative past-convo-button" id="headingTwo">
                <button className={`left-nav-acc ${navOpen !== "Close" ? "accordion-button" : ""} collapsed border-0 bg-transparent w-100 nav-link link-dark font-14 font-medium past-convo-button ${( activeTab =="PAST CONVERSATION" && navOpen === "Close" ) ? "active2":""}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"  onClick={() => setIsExpandedPC(isExpandedPC == 'Close' ? 'Open' : 'Close')}>
                  <img className="filter-icons past-convo-image"  style={{ filter: activeTab=="PAST CONVERSATION"&&navOpen === "Close"?"brightness(0) invert(1)":"" }}  src="../images/past-convo.svg" alt="manage-execution" onClick={() => {if (leftNav !== "Open") {navigate("/past-conversations"); setActiveTab("PAST CONVERSATION")}}} />
                  {leftNav === "Open" ? (<span className="ms-2 text-color-1 past-convo-button">Past Conversations</span>) : null}
                </button>
              </h2>
              {leftNav==="Open"&&(
                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="custom-convo-scroll-style user-nav-scroll accordion-body bg-color-2 pe-3 left-nav-acc-body scroll-fix-header" style={{ height: role == "User" ? '40vh' : role == "Lead" ? '35vh' : "20vh" }}>
                  {bindChat(bindChatHistory)}
                </div>
              </div>
              )}
              
            </div>
          </div>
        </div>
        <NavFooter setIsDarkMode={setIsDarkMode}></NavFooter>
      </div>

      {/* Share Conversation Popup and Notification Bar */}
      <div className="row justify-content-center">
        <ShareConversationPopup
          isOpen={!isSharePopupOpen}
          onClose={() => setIsSharePopupOpen(true)}
          sharePromptName={sharePromptName}
          shareDate={""}
          shareChatID={shareChatID}
          chatContent={chatContent}
          profileImage={profileImage}
          baseUrl={baseUrl}
          chatType={chatType}
          projectID={projectId}
        />
        {isCopied && (
          <div className="notification-bar">
            <span className="check-icon">✓</span>
            Copied conversation URL to Clipboard!
          </div>
        )}
      </div>
    </>
  )
}

export default HeaderComponent;