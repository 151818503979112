import React, { useState, useEffect, useRef } from 'react';
import Editor from '@monaco-editor/react';
import { Copy, Download, Check } from 'lucide-react';

const MonacoEditorComponent = ({
  editorCode = "",
  language = '',
  maxHeight = '1000px',
  readOnly = false
}) => {
    const [code, setCode] = useState<string>(() => {
        const firstNewlineIndex = editorCode.indexOf('\n');
        if (firstNewlineIndex === -1) return editorCode;
        return editorCode.slice(firstNewlineIndex + 1);
    });
    
    const [theme, setTheme] = useState('vs-light');
    const [copied, setCopied] = useState(false);
    const [downloaded, setDownloaded] = useState(false);
    const [detectedLanguage, setDetectedLanguage] = useState(language);
    const [editorHeight, setEditorHeight] = useState('auto');
    const editorRef = useRef<any>(null);
    const containerRef = useRef<any>(null);

    useEffect(() => {
        if (editorRef.current) {
            updateEditorHeight();
            editorRef.current.onDidContentSizeChange(updateEditorHeight);
        }
    }, [editorRef.current]);

    useEffect(() => {
        const detected = detectLanguage(editorCode);
        setDetectedLanguage(detected);
    }, [editorCode]);

    useEffect(() => {
        if (editorRef.current) {
            editorRef.current.setValue(editorCode);
        }
    }, [editorCode]);

    useEffect(() => {
        updateThemeFromBackground();
        const observer = new ResizeObserver(updateThemeFromBackground);
        if (containerRef.current) {
            const parent = containerRef.current.closest('.custom-dashboard-section');
            if (parent) {
                observer.observe(parent);
            }
        }
        return () => observer.disconnect();
    }, []);


    const detectLanguage = (codeContent:any) => {
        const firstWord = codeContent.split('\n')[0].trim().toLowerCase();
        const knownLanguages:any = {
            'python': 'python',
            'javascript': 'javascript',
            'typescript': 'typescript',
            'java': 'java',
            'csharp': 'csharp',
            'cpp': 'cpp',
            'go': 'go',
            'rust': 'rust',
            'swift': 'swift',
            'kotlin': 'kotlin',
            'ruby': 'ruby',
            'php': 'php',
            'scala': 'scala',
            'haskell': 'haskell',
            'lua': 'lua',
            'perl': 'perl',
            'r': 'r',
            'dart': 'dart',
            'elixir': 'elixir',
            'sql': 'sql',
            'html': 'html',
            'css': 'css',
            'puml': 'puml',
            'wsd': 'wsd'
        };

        if (knownLanguages[firstWord]) {
            return knownLanguages[firstWord];
        }

        const languagePatterns = [
            { type: 'javascript', keywords: ['function', 'const', 'let', 'var', 'import', 'export', 'class', '=>', 'return'] },
            { type: 'typescript', keywords: ['interface', 'type', 'namespace', 'declare', 'as', 'enum'] },
            { type: 'python', keywords: ['def', 'class', 'import', 'from', 'if', 'elif', 'else', 'for', 'while', 'try', 'except'] },
            { type: 'html', keywords: ['<!DOCTYPE', '<html', '<head', '<body', '<div', '<span', '<p', '<a', '<img', '<script'] },
            { type: 'css', keywords: ['{', '}', ';', 'color:', 'background:', 'margin:', 'padding:', 'font-', '@media'] },
            { type: 'java', keywords: ['public', 'class', 'static', 'void', 'int', 'String', 'import', 'package'] },
            { type: 'csharp', keywords: ['using', 'namespace', 'class', 'public', 'private', 'static', 'void', 'int', 'string'] },
            { type: 'cpp', keywords: ['#include', 'using namespace', 'int main', 'std::', 'cout', 'cin', 'vector', 'class'] },
            { type: 'go', keywords: ['package', 'import', 'func', 'var', 'const', 'type', 'struct', 'interface', 'go', 'chan'] },
            { type: 'rust', keywords: ['fn', 'let', 'mut', 'impl', 'struct', 'enum', 'trait', 'pub', 'use', 'mod'] },
            { type: 'swift', keywords: ['func', 'var', 'let', 'class', 'struct', 'enum', 'protocol', 'extension', 'guard', 'if let'] },
            { type: 'kotlin', keywords: ['fun', 'val', 'var', 'class', 'object', 'interface', 'when', 'is', 'in', 'companion'] },
            { type: 'ruby', keywords: ['def', 'class', 'module', 'attr_', 'require', 'include', 'extend', 'if', 'unless', 'end'] },
            { type: 'php', keywords: ['<?php', 'function', 'class', 'public', 'private', 'protected', 'echo', '$', '->', '=>'] },
            { type: 'scala', keywords: ['def', 'val', 'var', 'class', 'object', 'trait', 'extends', 'with', 'case', 'match'] },
            { type: 'haskell', keywords: ['module', 'import', 'data', 'type', 'class', 'instance', 'where', 'let', 'in', 'do'] },
            { type: 'lua', keywords: ['function', 'local', 'if', 'then', 'else', 'elseif', 'for', 'while', 'repeat', 'until'] },
            { type: 'perl', keywords: ['sub', 'my', 'use', 'package', 'if', 'elsif', 'unless', 'while', 'foreach', 'print'] },
            { type: 'r', keywords: ['function', '<-', 'if', 'else', 'for', 'while', 'repeat', 'in', 'library', 'data.frame'] },
            { type: 'dart', keywords: ['void', 'var', 'final', 'const', 'class', 'extends', 'implements', 'async', 'await', 'Future'] },
            { type: 'elixir', keywords: ['def', 'defmodule', 'do', 'end', 'if', 'case', 'when', 'fn', '->', 'pipe'] },
            { type: 'sql', keywords: ['SELECT', 'FROM', 'WHERE', 'INSERT', 'UPDATE', 'DELETE', 'CREATE', 'TABLE', 'ALTER', 'JOIN'] },
            { type: 'puml', keywords: ['@startuml', '@enduml', 'participant', 'as', 'if', 'else', 'while', 'for', 'repeat', 'alt', 'group'] },
            { type: 'wsd', keywords: ['@startuml', '@enduml', 'participant', 'as', 'if', 'else', 'while', 'for', 'repeat', 'alt', 'group'] },
            { type: 'markdown', keywords: ['#', '##', '###', '####', '#####', '######', '*', '_', '1.', '-', '`', '', '[', ']', '(', ')', '![', ']'] }
        ];

        function escapeRegExp(string: string) {
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        }
        
        const scores = languagePatterns.map(pattern => {
            let score = 0;
            pattern.keywords.forEach(keyword => {
                const escapedKeyword = escapeRegExp(keyword);
                const regex = new RegExp(`\\b${escapedKeyword}\\b`, 'gi');
                const matches = (codeContent.match(regex) || []).length;
                score += matches;
            });
            return { type: pattern.type, score };
        });

        const bestMatch = scores.reduce((max, current) =>
            current.score > max.score ? current : max
        , { type: 'text', score: 0 });

        return bestMatch.type;
    };

 

    const updateThemeFromBackground = () => {
        if (containerRef.current) {
            const parent = containerRef.current.closest('.custom-dashboard-section');
            if (parent) {
                const bgColor = window.getComputedStyle(parent).backgroundColor;
                const rgb = bgColor.match(/\d+/g);
                if (rgb) {
                    const brightness = (parseInt(rgb[0]) * 299 + parseInt(rgb[1]) * 587 + parseInt(rgb[2]) * 114) / 1000;
                    setTheme(brightness > 128 ? 'vs-light' : 'vs-dark');
                }
            }
        }
    };

    

    const copyToClipboard = () => {
        const editorCode = editorRef.current ? editorRef.current.getValue() : code;
        navigator.clipboard.writeText(editorCode);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    const downloadCode = () => {
        const editorCode = editorRef.current ? editorRef.current.getValue() : code;
        const element = document.createElement('a');
        const file = new Blob([editorCode], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = `code.${detectedLanguage || 'txt'}`;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);

        setDownloaded(true);
        setTimeout(() => setDownloaded(false), 2000);
    };

    const updateEditorHeight = () => {
        if (editorRef.current) {
            const contentHeight = editorRef.current.getContentHeight();
            const newHeight = Math.max(Math.min(contentHeight, parseInt(maxHeight)), 5);
            setEditorHeight(`${newHeight}px`);
        }
    };

  
    const handleEditorDidMount = (editor:any) => {
        editorRef.current = editor;
        updateEditorHeight();
    };

    return (
        <div className={`custom-dashboard-section monaco-editor-container ${theme}`} ref={containerRef}>
            <div className="toolbar">
                <div className="language">{detectedLanguage}</div>
                <div className="buttons">
                    <button onClick={copyToClipboard} title="Copy code">
                        {copied ? <Check size={18} /> : <Copy size={18} />}
                    </button>
                    <button onClick={downloadCode} title="Download code">
                        <Download size={18} />
                    </button>
                </div>
            </div>
            <Editor
                height={editorHeight}
                defaultLanguage={detectedLanguage}
                value={code}
                theme={theme}
                onChange={(value) => setCode(value || '')}
                onMount={handleEditorDidMount}
                options={{
                    readOnly: readOnly,
                    minimap: { enabled: true },
                    scrollBeyondLastLine: false,
                    fontSize: 16,
                    tabSize: 2,
                    automaticLayout: true,
                    wordWrap: 'on',
                    lineNumbers: 'on', // Enable line numbers
                    folding: false,
                    padding: { top: 8, bottom: 8 },
                }}
            />
        </div>
    );
};

export default MonacoEditorComponent;