import React, { useState, useContext, useEffect } from 'react';
const Tooltip: React.FC<{ text: string; children: React.ReactNode, moveleft?: number }> = ({ text, children, moveleft=260}) => {
  const [tooltipStyle, setTooltipStyle] = useState({});

  const handleMouseMove = (e: React.MouseEvent) => {
    const spaceRight = window.innerWidth - e.clientX;
    const spaceBottom = window.innerHeight - e.clientY;

    let left = e.clientX + 10;
    let top = e.clientY + 10;

    if (spaceRight < 250) {
      left = e.clientX - moveleft;
    }

    if (spaceBottom < 100) {
      top = e.clientY - 110;
    }

    setTooltipStyle({ left, top });
  };

  return (
    <div className="tooltip-container" onMouseMove={handleMouseMove}>
      {children}
      <div className="tooltip-text" style={tooltipStyle}>
        {text}
      </div>
    </div>
  );
};

export default Tooltip