import React from "react";

const BouncingDotsLoader: React.FC = () => {
    return (
      <div className="bouncing-dots-loader">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    );
  };
  
  export default BouncingDotsLoader;