import React, { useCallback, useRef, useEffect, useState } from 'react';
import { useMsal, useIsAuthenticated, useAccount } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';
import { AccountInfo, InteractionRequiredAuthError, PopupRequest, RedirectRequest } from "@azure/msal-browser";
import devConfig from "../config/env";
import axios from "axios";
import { isAuth } from '../Auth/AuthConfig';
import { setSessionItem } from '../Utility/encrypt-storage';

interface LoginProps {
  myProp?: any;
}

export const Login: React.FC<LoginProps> = ({ myProp }) => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const account = useAccount(accounts[0] || null);
  const [videoEnded, setVideoEnded] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.addEventListener('ended', handleVideoEnd);
      return () => video.removeEventListener('ended', handleVideoEnd);
    }
    if (videoRef.current) {
      videoRef.current.playbackRate = 1.5; // Increase speed to 1.5x
    }
  }, []);
 



  const handleVideoEnd = () => {
    setVideoEnded(true);
  };

  const checkLeadership = useCallback(async (groupId: string, accessToken: string, accountId: string) => {
    try {
      const response = await axios.get(
        `https://graph.microsoft.com/v1.0/groups/${groupId}/transitiveMembers/${accountId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.status === 200;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        // Token might be expired, try to refresh
        const newAccountResponse = await instance.acquireTokenSilent({
          account: account as AccountInfo,
          scopes: ["User.Read"],
          forceRefresh: true
        });
        // Retry the request with the new token
        const retryResponse = await axios.get(
          `https://graph.microsoft.com/v1.0/groups/${groupId}/transitiveMembers/${accountId}`,
          {
            headers: {
              Authorization: `Bearer ${newAccountResponse.accessToken}`,
            },
          }
        );
        return retryResponse.status === 200;
      }
      return false;
    }
  }, [instance, account]);

  const handleLogin = useCallback(async () => {
    let route = "/chat"; // Default route
    let accountResponse;

    try {
      if (!account) {
        accountResponse = await instance.loginPopup({
          scopes: ["https://graph.microsoft.com/User.Read"],
        });
      } else {
        try {
          accountResponse = await instance.acquireTokenSilent({
            account,
            scopes: ["User.Read"],
          });
        } catch (silentError) {
          if (silentError instanceof InteractionRequiredAuthError) {
            accountResponse = await instance.acquireTokenPopup({
              account,
              scopes: ["User.Read"],
            });
          } else {
            throw silentError;
          }
        }
      }

      accountResponse = await instance.acquireTokenSilent({
        account: account as AccountInfo,
        scopes: ["User.Read"],
      });

      const apiToken = await instance.acquireTokenSilent({
        account: account as AccountInfo,
        scopes: [devConfig?.roleConfig?.ScopeID],
      });
      setSessionItem("api-token", apiToken.accessToken);
      setSessionItem("access-token", accountResponse.accessToken);
      localStorage.setItem("api-token", apiToken.accessToken)
      localStorage.setItem("access-token", accountResponse.accessToken)

      const isInLeadershipDL = await checkLeadership(
        devConfig?.roleConfig?.LeadershipDL,
        accountResponse.accessToken,
        accountResponse.account.localAccountId
      );
      
      if (isInLeadershipDL) {
        route = "/dashboard";
      } else {
        const isInLeadershipGroup = await checkLeadership(
          devConfig?.roleConfig?.LeadershipGroup,
          accountResponse.accessToken,
          accountResponse.account.localAccountId
        );
        if (isInLeadershipGroup) {
          route = "/dashboard";
        }
      }

      if (isAuth([accountResponse.account])) {
        navigate(route);
        localStorage.setItem("login", "true");
        window.location.reload();
      }
    } catch (error) {
      console.error("Error in handleLogin:", error);
      const request: RedirectRequest = {
        scopes: ["https://graph.microsoft.com/User.Read"],
        redirectStartPage: window.location.origin + "/chat",
        redirectUri: window.location.origin + "/chat",
      };
      localStorage.setItem("login", "true");
      await instance.loginRedirect(request);
    }
  }, [instance, account, checkLeadership, navigate]);


  return (
    <div className="container-fluid" style={{backgroundColor:"white"}}>
      <div className="row">
        <video 
          ref={videoRef}
          autoPlay 
          muted 
          id="myVideo" 
          className="position-absolute top-0 start-0 end-0 bottom-0 w-100 intro-video" 
          style={{zIndex: 9, height: '100dvh', objectFit: 'cover', display: videoEnded ? 'none' : 'block'}}
        >
          <source src="videos/asami-intro.mp4" type="video/mp4" />
        </video>
        <div className="col-md-6 position-relative d-none d-lg-block" id="static-login">
          <video autoPlay muted loop id="sideVideo" className="position-absolute video-loop">
            <source src="videos/Side Anime.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="col-md-12 col-lg-6">
          <div className="w-100 d-flex justify-content-center align-items-center flex-column gap-5" style={{height: '100dvh'}}>
            <img src="videos\Logo yy.gif" style={{height:"60px",width:"150px"}} alt="asami-logo" className="d-block" />
            {videoEnded && (
              <button className="btn grad-btn font-semibold" onClick={handleLogin}>Meet Asami</button>
            )}
            <p className="position-absolute font-16 font-regular landing-page-edit" style={{bottom:"10px"}}>
              For issues or concerns kindly reach <a href="mailto:AsamiSupportCrew@avasoft.com?subject=Asami-Support-{REASON}-{USERNAME}" className="them-link text-decoration-none">AsamiSupportCrew@avasoft.com</a>
            </p>
       
          </div>
        </div>
      </div>
      <p className="position-absolute font-16 font-regular landing-page-edit" style={{right:"2%",bottom:"0px",color:"grey"}}>
              V4.0
            </p>
    </div>
  );
};