/**
 * PS_01 to PS_08
 *  Import required modules, components, and hooks for CostAnalysisTable
 * @param -
 */
import React from 'react';
import { useCost } from '../../Utility/CostAnalysisContext';
import Select from 'react-select';
import ModuleHeading from '../../Reusable Components/ModuleHeading';
import TextInput from '../../Reusable Components/TextInput';
import ButtonComponent from '../../Reusable Components/Button';
import FilterComponent from '../../Reusable Components/Filter';
import Filter from '../../Reusable Components/Filter';
import Tab from '../../Reusable Components/TabSwitch';
import NumberOfRecordsComponent from '../../Reusable Components/NoofRecords';
import ReusableTable from '../../Reusable Components/ReusableTable';
import { ModelConfig } from '../../Interfaces/LLMConfigGrid';
import { Header } from '../../Interfaces/ReusableTable';
import { useTableContext } from '../../Utility/ReusableTableContext';
import Loader from '../../Reusable Components/Loader';
/**
 * PS_09
 *  User navigates to Cost Analysis Table page and requests data visualization
 * @param {object} navOpen
 */
const CostAnalysisTable = ({ navOpen }:any) => {
  const {
    page, setPage, projectSearch, setProjectSearch, practiceSearch, setPracticeSearch,
    individualSearch, setIndividualSearch, projectSortOption, setProjectSortOption,
    projectSortValue, setProjectSortValue, practiceSortOption, setPracticeSortOption,
    practiceSortValue, setPracticeSortValue, individualSortOption, setIndividualSortOption,
    individualSortValue, setIndividualSortValue, projectDupSearch, setProjectDupSearch,
    practiceDupSearch, setPracticeDupSearch, individualDupSearch, setIndividualDupSearch,
    projectShownRecords, projectTotalRecords, practiceShownRecords, practiceTotalRecords,
    individualShownRecords, individualTotalRecords,bindProjectHistory,bindPracticeHistory,bindIndividualHistory,
 isLoad, loadmoreClick,
    optionSetter, handleSelectChange, handleApplyClick, assign_value,
    fetchProjectHistory, fetchPracticeHistory, fetchIndividualHistory,
    options
  } = useCost();
  const { data: modelConfigData, setData: setModelConfigData, sortConfig, isModalOpen, setIsModalOpen,setDetailedViewData } = useTableContext<ModelConfig>();

  const [buttonShow, setButtonShow] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(false);

  const tabData = [
    { id: 'practice', label: 'Practice', page: 'Practice' },
    { id: 'individual', label: 'Individual', page: 'Individual' }
  ];
  /**
   * PS_15
   *  Get data based on the current page state
   */
  const getPageData = () => {
    switch(page) {
      case 'Project':
        return { 
          totalRecords: projectTotalRecords, 
          shownRecords: projectShownRecords, 
          sortOption: projectSortOption,
          setSortOption: setProjectSortOption,
          sortValue: projectSortValue,
          setSortValue: setProjectSortValue
        };
      case 'Practice':
        return { 
          totalRecords: practiceTotalRecords, 
          shownRecords: practiceShownRecords, 
          sortOption: practiceSortOption,
          setSortOption: setPracticeSortOption,
          sortValue: practiceSortValue,
          setSortValue: setPracticeSortValue
        };
      case 'Individual':
        return { 
          totalRecords: individualTotalRecords, 
          shownRecords: individualShownRecords, 
          sortOption: individualSortOption,
          setSortOption: setIndividualSortOption,
          sortValue: individualSortValue,
          setSortValue: setIndividualSortValue
        };
      default:
        return {};
    }
  };

  const { totalRecords, shownRecords, sortOption, setSortOption, sortValue, setSortValue } = getPageData();
  /**
   * PS_16
   *  Handle search input and change state accordingly
   * @param {event} e
   */
  const handleSearch = (e:any) => {
    const value = e;
    if (page === "Project") setProjectDupSearch(value);
    else if (page === "Practice") setPracticeDupSearch(value);
    else setIndividualDupSearch(value);
  };
/**
   * PS_17
   *  Handle search submission logic
   */
  const handleSearchSubmit = () => {
    if (page === "Practice") setPracticeSearch(practiceDupSearch);
    else if (page === "Project") setProjectSearch(projectDupSearch);
    else setIndividualSearch(individualDupSearch);
  };
  const search=assign_value()
    /**
   * PS_18
   *  Define headers for the ReusableTable component
   */
  const headers: Header<ModelConfig>[] = [
    { key:  page === 'Individual' ? 'individualName' : page === 'Project'?'projectName':'practiceName' , label:  page === 'Individual' ? 'User Name' : `${page} Name` , sortable: true },
    { key: 'AsamiUsage', label: 'Asami Usage', sortable: true, },
    { key: 'Cost', label: ' Cost (in $)', sortable: true ,component: ({ row }) => (
      <span>{row.Cost ? parseFloat(row.Cost).toFixed(2) : "-"}</span>
    )},
  ];
  if(page=="Individual"){
    headers.splice(1, 0, { key: 'userEmailID', label: ' Email', sortable: true });
  }
    /**
   * PS_19
   *  Handle row click in ReusableTable and open modal with detailed data
   * @param {ModelConfig} row
   */
  const handleRowClick = (row: ModelConfig) => {
    setDetailedViewData(row);
    setIsModalOpen(true);
  };
  
  /**
   * PS_20 to PS_25
   *  Return the rendered component with various UI elements and handlers
   */
  return (
    <div className="container-fluid">
    <div className="row justify-content-lg-end ms-lg-3 ms-xl-0 justify-content-center">
      <div className={navOpen === "Close" ? `col-11 me-xl-4 me-lg-3` : `col-lg-9 me-md-2 cust-me-lg-4 col-11`}>

          <div className="row">
            <div className="col-md-12">
              <div className="mt-4 mb-3 d-grid d-md-flex justify-content-md-between align-items-center">
              <ModuleHeading text={"Cost Analysis"} tag={"h2"} className="font-bold font-20 mb-0 text-color-1">
                    <a href="/dashboard">
                        <img src="images-new/back-black-arrow.svg" alt="Back" className="me-2 icon-filter" />
                      </a>
              </ModuleHeading>
                {/* Search */}
                <div className="table-header d-flex align-items-center">
                  <div className="input-group transparent-grp w-225">
                  <TextInput 
                     type="text"
                     className="form-control custom-search-input text-nowrap search-bg border-0 text-color-4 focus-none font-14 font-medium pe-0 ps-3"
                     placeholder="Search"
                     value={search}
                     onChange={(value: string) => handleSearch(value)}
                     onEnterPress={handleSearchSubmit}
                    />
                    <ButtonComponent buttonType='icon' className='focus-none search-bg' onClick={handleSearchSubmit} iconSrc='images/input-search.svg' altText='input-search-icon'></ButtonComponent>
                    
                  </div>
                  {page !== "Individual" && (
                    <div className="dropdown ms-3">
                      <FilterComponent onApply={(localFilters:any)=>{handleApplyClick(localFilters)}} onCancel={() => { 
                                setButtonShow(false);
                                page === "Practice" ? handleSelectChange([]) : handleSelectChange([]);
                              }} config={{
                                dropdowns: {
                                  codegenStatus: {
                                  options: page === "Project" ? bindProjectHistory.map(value => ({
                                      value: value.projectName,
                                      label: value.projectName
                                  })):bindPracticeHistory.map(value => ({
                                    value: value.practiceName,
                                    label: value.practiceName
                                })),
                                  isMulti: true, 
                                  label: page === "Project" ? "Project" : "Practice"
                                  },
                              },
                              }}></FilterComponent>
                      
                    </div>
                  )}
                </div>
              </div>
              
              {/* Tabs */}
              <Tab activeTab={page} tabContents={{Practice: 'Practice content goes here',Individual:"Individual Goes Here"}} onTabClick={setPage }></Tab>
             

              {/* Table Content */}
              <div className="tab-content mt-2">
                <div className="tab-pane fade show active" id={page.toLowerCase()} role="tabpanel" aria-labelledby={`${page.toLowerCase()}-tab`}>
                  {isLoad ? (
                    <div style={{ textAlign: 'center', flex: 1, display: 'flex', justifyContent: 'center', marginTop: '-27%', marginLeft: '-120%' }}>
                    <Loader isLoading={isLoad} navOpening={navOpen} />
                  </div>
                  ) : (
                    <div className="col-md-12">
                      <div className="table-responsive rounded-3 transparent-grp">
                      <ReusableTable<ModelConfig>
                        headers={headers}
                        onRowClick={handleRowClick}
                      />
                        
                      </div>
                      <NumberOfRecordsComponent shownRecords={shownRecords||0} totalRecords={totalRecords||0} className="font-regular font-14 text-color-1 mt-3 mb-4" />
              {typeof totalRecords === 'number' && 
                 typeof shownRecords === 'number' && 
                 totalRecords > shownRecords && 
                 totalRecords > 10 && (
                  <div className="text-center mb-5">
                    <ButtonComponent className="btn primary-gradiant-btn text-white font-12 font-medium me-3" onClick={() => loadmoreClick(page.toLowerCase())} text="Load More"/>
                  </div>
                )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
  
};

export default CostAnalysisTable;