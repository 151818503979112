import React, { createContext, useState, useContext, useCallback, ReactNode, useEffect } from 'react';
import { getS3URL } from '../Service/API';

export interface RecordData {
  labelName: string;
  inputValueOrder: string;
  required: string;
}

export interface Record {
  record: any;
  handleFieldValues?: (labelName: string, value: string, inputValueOrder: string, required: string) => void;
}

export interface FileUploadContextType {
  isUploading: boolean, setIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
  alertMessage: string, setAlertMessage: React.Dispatch<React.SetStateAction<string>>;
  alertType: string, setAlertType: React.Dispatch<React.SetStateAction<string>>;
  folderUrl: string, setFolderUrl: React.Dispatch<React.SetStateAction<string>>;
  uploadedFileUrl: string, setUploadedFileUrl: React.Dispatch<React.SetStateAction<string>>;
  uploadSuccess: boolean, setUploadSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  showSuccessEffect: boolean, setShowSuccessEffect: React.Dispatch<React.SetStateAction<boolean>>;
  bURL: string, setBUrl: React.Dispatch<React.SetStateAction<string>>;
  BLinksList: any, setBLinksList: any;
  fileUploaded: boolean, setFileUploaded: React.Dispatch<React.SetStateAction<boolean>>;
  showInitialPreview: boolean, setShowInitialPreview: React.Dispatch<React.SetStateAction<boolean>>;
  showPreviewOnHover: boolean, setShowPreviewOnHover: React.Dispatch<React.SetStateAction<boolean>>;
  uploadMedia: (e: React.ChangeEvent<HTMLInputElement>, record?: Record, recordData?: any) => Promise<void>;
  handleUploadResult: (results: any, record: Record, recordData: any) => void;
  handleImageDocChange: (file: File) => Promise<string>;
  getS3URL: (data: any) => Promise<any>;
  recordData: any | null, setRecordData: React.Dispatch<React.SetStateAction<any | null>>;
  updateRecord: (record: Record) => void;
  fileShowIndicator: boolean, setFileShowIndicator: React.Dispatch<React.SetStateAction<boolean>>;
  toastrLevel:any;
  setToastrLevel: React.Dispatch<React.SetStateAction<any>>
  toastrMessage:any; 
  setToastrMessage: React.Dispatch<React.SetStateAction<any>>

}

 
const FileUploadContext = createContext<FileUploadContextType | undefined>(undefined);
 
interface FileUploadProviderProps { 
  children: ReactNode;
}
 
export const FileUploadProvider: React.FC<FileUploadProviderProps> = ({ children }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [BLinksList, setBLinksList] = useState([]);
  const [folderUrl, setFolderUrl] = useState("")
  const [uploadedFileUrl, setUploadedFileUrl] = useState('');
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [showSuccessEffect, setShowSuccessEffect] = useState(false);
  const [bURL, setBUrl] = useState('');
  const [fileUploaded, setFileUploaded] = useState(false);
  const [showInitialPreview, setShowInitialPreview] = useState(false);
  const [showPreviewOnHover, setShowPreviewOnHover] = useState(false);
  const [recordData, setRecordData] = useState<any | null>(null);
  const [fileShowIndicator,setFileShowIndicator] = useState(false)
  const [toastrMessage, setToastrMessage] = useState<string | null>(null);
  const [toastrLevel, setToastrLevel] = useState<'Success' | 'Failure'>();
 
  // This function handles file uploads to S3, processing multiple files and handling different file types.
  const uploadMedia = useCallback(async (e: React.ChangeEvent<HTMLInputElement>, record: any, recordData: any) => {
      setIsUploading(true);
      setAlertMessage("");
      setAlertType("");
      setShowInitialPreview(true);
      
      let files = Array.from(e.target.files ?? []);
      
      if (files.length === 0) {
          setIsUploading(false);
          return;
      }
      
      const formData = new FormData();
      let isAudioOrVideo = false;
      const fileNames: string[] = [];
      
      for (let file of files) {
          const fileName = file.name;
          fileNames.push(fileName);
          
  
          if (file.type === 'application/vnd.ms-powerpoint' || file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
              setAlertMessage(`PPT files are not allowed: ${fileName}`);
              setAlertType('error');
              setToastrMessage(`PPT files are not allowed: ${fileName}`);
              setToastrLevel('Failure');
              continue;
          }
          else if(file.type === 'application/zip' ||file.type === 'application/x-zip-compressed'){
            setAlertMessage(`Zip Files are not allowed: ${fileName}`);
              setAlertType('error');
              setToastrMessage(`Zip Files are not allowed: ${fileName}`);
              setToastrLevel('Failure');
              continue;
          }
          const supportedFormats = [
              'image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/webp',
              'audio/mpeg', 'audio/wav', 'audio/ogg',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              'application/pdf', 'text/csv', 'text/plain', 'video/mp4',
              'application/x-terraform', 'application/yaml', 'application/x-yaml', 'text/yaml',
              'application/json', 'application/zip'
          ];
          
          const supportedExtensions = [
              '.jpeg', '.jpg', '.png', '.gif', '.webp',
              '.docx', '.xlsx', '.pdf', '.csv', '.txt','.m4a', '.mp3', '.wav', '.ogg',
              '.mp4', '.tf', '.yml', '.yaml', '.json', '.zip'
          ];
          
          const fileExtension = '.' + fileName.split('.').pop()?.toLowerCase();
          
          if (!supportedExtensions.includes(fileExtension)) {
              const newFileName = fileName.replace(/\.[^/.]+$/, "") + ".txt";
              file = new File([file], newFileName, { type: 'text/plain' });
          }
          
          if (file.type.startsWith('audio/')) {
              if (isAudioOrVideo) continue;
              formData.append('files', file);
              isAudioOrVideo = true;
          } else if (file.type === "video/mp4") {
              if (file.size > 50 * 1024 * 1024) {
                  setAlertMessage(`Video file ${fileName} exceeds 50MB`);
                  setAlertType('error');
                  setToastrMessage(`Video file ${fileName} exceeds 50MB`);
                  setToastrLevel('Failure');
                  continue;
              }
              if (isAudioOrVideo) continue;
              formData.append('files', file);
              isAudioOrVideo = true;
          } else {
              formData.append('files', file);
          }
      }
    
      try {
        if(formData.has('audio') || formData.has('video') || formData.has('files')){
          const results = await getS3URL(formData);
  
          if (results.status_code === 200 && results.Success) {
              const uploadResults: any = results.ResponseData.files.map(
                  (item: string) => `${results.ResponseData.folder_url}/${item}`
              );
  
              setFolderUrl(results.ResponseData.folder_url);
              sessionStorage.setItem('BLinksList', JSON.stringify(uploadResults));
              setFileShowIndicator(true);
              setUploadSuccess(true);
              setFileUploaded(true);
              setAlertType("success");
              setAlertMessage("Files uploaded successfully");
              // setToastrMessage("Files uploaded successfully");
              // setToastrLevel('Success');
              setShowSuccessEffect(true);
  
              // Set these for compatibility with old code (using the last file)
              setUploadedFileUrl(uploadResults[uploadResults.length - 1]);
              setBUrl(uploadResults[uploadResults.length - 1]);
  
              
          } else {
              setAlertType("error");
              setAlertMessage("Upload failed");
              // setToastrMessage("Upload failed");
              // setToastrLevel('Failure');
              setUploadSuccess(false);
              setFileUploaded(false);
          }

        }
          
      } catch (error) {
          console.error('Upload error:', error);
          setAlertType("error");
          setAlertMessage("Upload failed");
          // setToastrMessage("Upload failed");
          // setToastrLevel('Failure');
          setUploadSuccess(false);
          setFileUploaded(false);
      }
  
      setIsUploading(false);
      setTimeout(() => setShowSuccessEffect(false), 2000);
      setTimeout(() => {
          setAlertMessage('');
          setShowInitialPreview(false);
          setShowPreviewOnHover(true);
      }, 3000);
  }, []);
  
 
  // Handles the result of a file upload, updating state and notifying any associated record.
  const handleUploadResult = useCallback((results: any, record: any, recordData: any) => {
    if (results.status_code === 200) {
      setUploadedFileUrl(results.BlobURL);
      setBUrl(results.BlobURL);
      setUploadSuccess(true);
      setFileUploaded(true);
      setAlertType("success");
      setAlertMessage("File uploaded successfully");
    } else {
      setAlertType("error");
      setAlertMessage("Upload failed");
      setUploadSuccess(false);
      setFileUploaded(false);
    }
  }, []);

  // Converts a file to a base64 encoded string using a FileReader.
  const handleImageDocChange = useCallback((file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result;
        if (typeof result === 'string') {
          resolve(result.split(",")[1]);
        } else {
          reject(new Error('Failed to read file'));
        }
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }, []);

  // Updates the record data state with a new record.
  const updateRecord = useCallback((record: Record) => {
    setRecordData(record.record);
  }, []);

  const contextValue: FileUploadContextType = {
    isUploading, setIsUploading,
    alertMessage, setAlertMessage,
    alertType, setAlertType,
    BLinksList, setBLinksList,
    folderUrl, setFolderUrl,
    uploadedFileUrl, setUploadedFileUrl,
    uploadSuccess, setUploadSuccess,
    showSuccessEffect, setShowSuccessEffect,
    bURL, setBUrl,
    fileUploaded, setFileUploaded,
    showInitialPreview, setShowInitialPreview,
    showPreviewOnHover, setShowPreviewOnHover,
    uploadMedia,
    handleUploadResult,
    handleImageDocChange,
    getS3URL,
    recordData, setRecordData,
    updateRecord,
    fileShowIndicator, setFileShowIndicator,
    toastrLevel, setToastrLevel,
    toastrMessage, setToastrMessage
  }; 
  
  return (
    <FileUploadContext.Provider value={contextValue}>
      {children}
    </FileUploadContext.Provider>
  );
};
 
export const useFileUpload = (): FileUploadContextType => {
  const context = useContext(FileUploadContext);
  if (!context) {
    throw new Error('useFileUpload must be used within a FileUploadProvider');
  }
  return context;
};