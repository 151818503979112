import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Toolbar from './Toolbar';
import DynamicPopup from './DynamicPopup';
import { getmddoc, updatemddoc } from '../Service/Valyria/API';
import { io } from 'socket.io-client';
import { UrlsAi } from '../Service/API-Constants';


interface MarkdownEditorProps {
  initialBase64Content?: any;
  handleResponse?:any;
  active?: any;
}

const MarkdownEditor: React.FC<MarkdownEditorProps> = ({active, handleResponse}) => {
  const [markdown, setMarkdown] = useState('');
  const [originalMarkdown, setOriginalMarkdown] = useState('');
  const [undoStack, setUndoStack] = useState<string[]>([]);
  const [redoStack, setRedoStack] = useState<string[]>([]);
  const [showLinkPopup, setShowLinkPopup] = useState(false);
  const [linkInfo, setLinkInfo] = useState({ start: 0, end: 0, text: '' });
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    let initialBase64Content:any
    const fetchInitialContent:any = async () => {
      const requestBody = 
      {
        user_id : localStorage.getItem("user_id"),
        project_id : localStorage.getItem("project_id")
      }

      const result = await getmddoc(requestBody);
      if(localStorage.getItem("reward")=='0'){
      let jsondata=result.unstructured_vdocs_data
      while(jsondata.length==0){
      return fetchInitialContent()
      }
      return jsondata
    }
    else{
      let jsondata=result.vdoc_data
      while(jsondata.length==0){
      return fetchInitialContent()
      }
      jsondata=eval(jsondata)
      const separator = "\u200B\n";
      const joinedString  =  jsondata.join(separator)
      return joinedString
    }
      
    }
    fetchInitialContent().then((initialBase64Content:any)=>{
      if (initialBase64Content) {
        try {
          const decodedContent = initialBase64Content;
          setMarkdown(sanitizeMarkdown(decodedContent));
          setOriginalMarkdown(sanitizeMarkdown(decodedContent));
          setUndoStack([decodedContent]);
        } catch (error) {
          console.error("Error decoding base64 content:", error);
          setMarkdown('');
          setOriginalMarkdown('');
        }
      }
    })

   
  }, []);

  const sanitizeMarkdown = (text: string) => {
    return text.split("\n")
      .map(line => line.replace(/[^\S\r\n]+/g, ' ').trimEnd())
      .join("\n");
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = sanitizeMarkdown(e.target.value);
    setMarkdown(newValue);
    pushToUndoStack(newValue);
    setRedoStack([]);
  };

  const pushToUndoStack = (newValue: string) => {
    setUndoStack(prevStack => [...prevStack, newValue]);
  };

  const applyStyle = (style: string) => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    let start = textarea.selectionStart;
    let end = textarea.selectionEnd;
    const fullText = markdown;
  
    while (start > 0 && /\S/.test(fullText[start - 1])) start--;
    while (end < fullText.length && /\S/.test(fullText[end])) end++;
  
    const selectedText = fullText.substring(start, end).trim();
    let newText;
    let newStart = start;
    let newEnd = end;
  
    const toggleStyle = (text: string, chars: string) => {
      if (text.startsWith(chars) && text.endsWith(chars)) {
        return { text: text.slice(chars.length, -chars.length), offset: -chars.length };
      } else {
        return { text: chars + text + chars, offset: chars.length };
      }
    };
  
    if (style.startsWith('h') && style.length === 2) {
      const level = parseInt(style[1]);
      if (level >= 1 && level <= 6) {
        const prefix = '#'.repeat(level);
        const lineStart = fullText.lastIndexOf('\n', start) + 1;
        const lineEnd = fullText.indexOf('\n', end) === -1 ? fullText.length : fullText.indexOf('\n', end);
        const currentLine = fullText.substring(lineStart, lineEnd);
        const cleanLine = currentLine.replace(/^#+\s*/, '');
        newText = fullText.substring(0, lineStart) + prefix + ' ' + cleanLine + fullText.substring(lineEnd);
        newStart = lineStart;
        newEnd = lineStart + prefix.length + 1 + cleanLine.length;
      } else {
        return;
      }
    } else {
      switch (style) {
        case 'bold': {
          const { text } = toggleStyle(selectedText, '**');
          newText = fullText.substring(0, start) + text + fullText.substring(end);
          newEnd = start + text.length;
          break;
        }
        case 'italic': {
          const { text } = toggleStyle(selectedText, '*');
          newText = fullText.substring(0, start) + text + fullText.substring(end);
          newEnd = start + text.length;
          break;
        }
        case 'orderedList': {
          const lines = selectedText.split('\n');
          const newLines = lines.map((line, index) => `${index + 1}. ${line.replace(/^\d+\.\s*/, '')}`);
          newText = fullText.substring(0, start) + newLines.join('\n') + fullText.substring(end);
          newEnd = start + newLines.join('\n').length;
          break;
        }
        case 'unorderedList': {
          const lines = selectedText.split('\n');
          const newLines = lines.map(line => `- ${line.replace(/^[-*]\s*/, '')}`);
          newText = fullText.substring(0, start) + newLines.join('\n') + fullText.substring(end);
          newEnd = start + newLines.join('\n').length;
          break;
        }
      case 'codeBlock': {
        newText = fullText.substring(0, start) + "```\n" + selectedText + "\n```" + fullText.substring(end);
        newEnd = start + selectedText.length + 4; // 4 is the length of "$\n" + "\n$"
        break;
    }
    case 'strikethrough': {
        const { text } = toggleStyle(selectedText, '~~');
        newText = fullText.substring(0, start) + text + fullText.substring(end);
        newEnd = start + text.length;
        break;
      }
      case 'link': {
        const urlRegex = /^(https?:\/\/[^\s]+)$/;
        const localURL = /^(http?:\/\/[^\s]+)$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        if (urlRegex.test(selectedText) || emailRegex.test(selectedText) || localURL.test(selectedText)) {
          newText = fullText.substring(0, start) + `[${selectedText}](${selectedText})` + fullText.substring(end);
          newEnd = start + selectedText.length * 2 + 4;
        } else {
          setLinkInfo({ start, end, text: selectedText });
          setShowLinkPopup(true);
          return;
        }
        break;
      }
      case 'horizontalRule': {
        newText = fullText.substring(0, start) + '\n---\n' + fullText.substring(end);
        newEnd = start + 5;
        break;
      }
      default:
        return;
    }
  }

  setMarkdown(sanitizeMarkdown(newText));
  pushToUndoStack(newText);
  setRedoStack([]);
  textarea.focus();
  setTimeout(() => {
    textarea.setSelectionRange(newStart, newEnd);
  }, 0);
};

const handleLinkSave = (data: { url: string }) => {
  const { start, end, text } = linkInfo;
  const fullText = markdown;
  const newText = fullText.substring(0, start) + `[${text}](${data.url})` + fullText.substring(end);
  setMarkdown(sanitizeMarkdown(newText));
  pushToUndoStack(newText);
  setRedoStack([]);
  setShowLinkPopup(false);

};

const handleUndo = () => {
  if (undoStack.length > 1) {
    const currentState = undoStack.pop();
    if (currentState) {
      setRedoStack(prevStack => [...prevStack, currentState]);
      const previousState = undoStack[undoStack.length - 1];
      setMarkdown(sanitizeMarkdown(previousState));
      setUndoStack([...undoStack]);
    }
  }
};

const handleRedo = () => {
  if (redoStack.length > 0) {
    const nextState = redoStack.pop();
    if (nextState) {
      setMarkdown(sanitizeMarkdown(nextState));
      pushToUndoStack(nextState);
      setRedoStack([...redoStack]);
    }
  }
};

const handleSave = async () => {
  let base64Content
  let payload
  if( localStorage.getItem("reward")=='0'){
  base64Content = markdown;

  payload = {
    user_id : localStorage.getItem("user_id"),
    project_id : localStorage.getItem("project_id"),
    unstructured_vdocs_data: base64Content,
    vdocs_data: ''
  };
  }
  else{
    const joinseparator = "\u200B\n";
    base64Content = markdown.split(joinseparator);
    payload = {
      user_id : localStorage.getItem("user_id"),
  project_id : localStorage.getItem("project_id"),
  vdocs_data: base64Content,
  unstructured_vdocs_data: ''
};

const socket = io(UrlsAi?.baseurl);
socket.emit('user_response', {
  question_id: localStorage.getItem('question_id'),
  message: 'FRD Completed',
  user_id: localStorage.getItem("user_id"),
  project_id: localStorage.getItem("project_id")
});

}
  try {
    const result = await updatemddoc(payload);
    active('none')
  } catch (error) {
    console.error('Error updating document:', error);
  }
};



const handleCancel = () => {
  setMarkdown(originalMarkdown);
  setUndoStack([originalMarkdown]);
  setRedoStack([]);
};

return (
  <div className="container-fluid">
          <div className="row justify-content-lg-end justify-content-center">
            <div className={"Close"!== "Close" ? "col-12 col-lg-11 me-xl-3 px-3" : "col-lg-9 col-11 me-sm-0 me-md-3 cust-me-lg-4 me-xl-4"}>
              <div className="row justify-content-center">
  <div className="markdown-editor">
    <Toolbar
      onStyleApply={applyStyle}
      canUndo={undoStack.length > 1}
      canRedo={redoStack.length > 0}
      onUndo={handleUndo}
      onRedo={handleRedo}
      onSave={handleSave}
      onCancel={handleCancel}
    />
    <div className="editor-container">
      <textarea
        ref={textareaRef}
        value={markdown}
        onChange={handleChange}
        className="editor"
      />
      <div className="preview">
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{markdown}</ReactMarkdown>
      </div>
    </div>
    {showLinkPopup && (
      <DynamicPopup
        showModal={showLinkPopup}
        onClose={() => setShowLinkPopup(false)}
        onSave={handleLinkSave}
        title="Insert Link"
        steps={[
          {
            title: "Link Details",
            fields: [
              {
                name: "url",
                label: "URL",
                type: "text",
                required: true,
                placeholder: "Enter URL",
              },
            ],
          },
        ]}
        currentStep={0}
        onNext={() => {}}
        errors=""
        loading={false}
        columns={1}
      />
    )}
  </div>
  </div>
  </div></div>
  </div>
);
};

export default MarkdownEditor;