import { useEffect, useState } from "react";
import { fetchBrowserImages } from "../../../Service/API";
import { Session } from "inspector";
import Loader from "../../../Reusable Components/Loader";

interface BrowserComponentProps {
    browserData?: string; // Assuming browserData is a string prop
}

export function BrowserComponent({ browserData }: BrowserComponentProps) {


    const [imageUrl, setImageUrl] = useState<string>(browserData ? browserData : "");
    const [isLoading, setIsLoading] = useState<boolean>(false)
    useEffect(() => {
        if (!browserData) {
            setIsLoading(true)
            let reqBody = {
                "user_id": sessionStorage.getItem("user_id"),
                "project_id": sessionStorage.getItem("project_id")
            }
            Promise.resolve(fetchBrowserImages(reqBody))
                .then((data) => {
                    setImageUrl(data.image_url);
                    setIsLoading(false)
                });
        }

    }, [])

    //   const initialInvoke = async () => {
    //     await getBrowserUrl();
    //     checkStateValue();
    //   };

    const checkStateValue = () => {
        if (localStorage.getItem('projectID') === '') {
            setImageUrl('');
        }
    };

    //   const getBrowserUrl = async () => {
    //     if (!!localStorage.getItem('projectID')) {
    //       const response = await fetchBrowserUrl({
    //         user_id: localStorage.getItem('userID') || '',
    //         project_id: localStorage.getItem('projectID') || '',
    //       });
    //       if (response.image_url !== '') {
    //         const base64Url = response.image_url;
    //         setImageUrl(base64Url);
    //       }
    //     }
    //   };

    return (
        <div className=" toggle-right-panel gap-2 ">
            {/*Browser starts here*/}
            <div className="custom-tab-content-section browser-height">
                <div className="custom-right-side-bar-section-contents browser-theme-handler browser-height">
                    <div className="custom-right-side-bar-section-title">
                        <h2 className="font-semibold font-14">Browser</h2>
                    </div>
                    <div className="custom-right-side-bar-section-details">
                        {imageUrl && (
                            <img className="right-sidebar-icons" src={`data:image/png;base64,${imageUrl}`} alt="Fetched from backend" />
                        )}
                          {
                            isLoading ? (
                                <div className="card-shimmer">
                                  <div className="shimmer-title"></div>
                                  <div className="shimmer-image"></div>
                                  <div className="shimmer-image"></div>
                                  <div className="shimmer-wrapper">
                                      <div className="shimmer"></div>
                                  </div>
                                </div>
                            ) :
                          !imageUrl&&(
                            <>
                            <div className="custom-right-side-bar-section-details norecords">
                                <img src="../images/no-records-graphics.svg" alt="graphic" width="500px" height={"250px"}/>
                                <p className="mb-0 font-medium font-14 mt-2 text-color-9" style={{"textAlign":"center"}}>No Data Found</p>
                            </div>
                          </>
                        )}
                    </div>
                </div>
            </div>
            {/*Browser Ends here*/}

        </div>

    );
}