
import React from 'react';
import ModuleHeadingProps from '../Interfaces/ModuleHeading';


const ModuleHeading: React.FC<ModuleHeadingProps> = ({ 
  text, 
  tag = 'h3', 
  className = '',
  children
}) => {
  const Tag = tag;
  
  return (
    <Tag className={className}>
        {children}
        {text}
    </Tag>
  );
};

export default ModuleHeading;