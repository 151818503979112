import { validateD2Diagram, generateD2Diagram } from "./API";

let lastValidatedCode: string = '';
let lastReplacedCode: string = '';

interface DiagramResult {
  diagramSvg: string | null;
  finalCode: string;
  error: string | null;
}

export const fetchAndValidateDiagram = async (code: string, layout: string): Promise<DiagramResult> => {
  try {
    let updatedCode: string = code;
    let codeChanged: boolean = false;

    if (code !== lastValidatedCode) {
      const validateResponse = await validateD2Diagram(code);
      updatedCode = validateResponse.updatedCode;
      lastValidatedCode = code;
      lastReplacedCode = updatedCode;
      codeChanged = updatedCode !== code;
    } else {
      updatedCode = lastReplacedCode;
    }

    // Generate the diagram
    const finalCode: string = `${updatedCode}\n\ndirection: ${layout}`;
    const diagramSvg: string = await generateD2Diagram(finalCode);

    if (!diagramSvg) {
      throw new Error("Generated diagram SVG is null or undefined");
    }

    return {
      diagramSvg,
      finalCode: codeChanged ? updatedCode : code,
      error: null
    };
  } catch (error: any) {
    console.error('Error fetching diagram:', error);
    let errorMessage: string = 'Failed to generate diagram. Please check your D2 code and try again.';
    if (error.response && error.response.data && error.response.data.error) {
      errorMessage = `Error in D2 code: ${error.response.data.error.message}`;
    } else if (error.message) {
      errorMessage = error.message;
    }
    
    return {
      diagramSvg: null,
      finalCode: code,
      error: errorMessage
    };
  }
};