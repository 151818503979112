import React, { createContext, useContext } from 'react';

// Define the type for the dropdown context
interface DropDownContextType {
    isMulti?: boolean; // Determines if the dropdown supports multi-selection
}

// Create the dropdown context
const DropDownContext = createContext<DropDownContextType | undefined>(undefined);

// Dropdown provider component
export const DropDownProvider: React.FC<{ children: React.ReactNode; isMulti?: boolean }> = ({ children, isMulti }) => {
    return (
        <DropDownContext.Provider value={{ isMulti }}>
            {children}
        </DropDownContext.Provider>
    );
};

// Custom hook to use the dropdown context
export const useDropDown = () => {
    const context = useContext(DropDownContext);
    if (context === undefined) {
        throw new Error('useDropDown must be used within a DropDownProvider');
    }
    return context;
};